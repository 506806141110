<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_content" class="app-content flex-column-fluid">
  
  <!--begin::Content container-->
  <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <div class="d-flex align-items-center">
          <h1 class="page-heading"><svg width="22" height="24" viewBox="0 0 22 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.14455 20.3909C7.83907 19.4881 8.90757 18.9571 10.0295 18.9571H14.2501C14.8912 18.9571 15.4788 18.6916 15.9062 18.2668L21.1419 12.8505C21.3022 12.6912 21.3022 12.3726 21.1419 12.2133C20.9816 12.054 20.7145 12.0009 20.5542 12.1071L15.6391 15.0276C15.4788 15.8242 14.7843 16.4614 13.9295 16.4614H9.01442C8.7473 16.4614 8.48017 16.249 8.48017 15.9304C8.48017 15.6649 8.7473 15.3993 9.01442 15.3993H13.9295C14.3035 15.3993 14.624 15.0807 14.624 14.709C14.624 14.3373 14.3035 14.0187 13.9295 14.0187H6.45002C5.38152 14.0187 4.36644 14.4966 3.72534 15.2931L1.85547 17.5234L6.66372 21.1343L7.14455 20.3909Z"
                fill="#265D91" />
              <path
                d="M6.92951 22.6211L6.44868 22.2494L1.15961 18.2668L0.144531 19.5413L5.91443 23.8956L6.92951 22.6211Z"
                fill="#265D91" />
              <path
                d="M3.61904 11.4698H17.6164C18.0438 11.4698 18.3643 11.1512 18.3643 10.7264V6.69073C15.7999 7.59346 13.1821 8.07137 10.6177 8.07137C8.05332 8.07137 5.43549 7.59346 2.87109 6.69073V10.7264C2.87109 11.1512 3.19164 11.4698 3.61904 11.4698Z"
                fill="#265D91" />
              <path
                d="M18.3643 5.36322V2.97367C18.3643 2.54886 18.0438 2.23025 17.6164 2.23025H14.2506V0.637215C14.2506 0.318607 13.9835 0 13.6095 0H7.62592C7.30537 0 6.98482 0.265506 6.98482 0.637215V2.23025H3.61904C3.19164 2.23025 2.87109 2.54886 2.87109 2.97367V5.36322C7.9999 7.27487 13.2355 7.27487 18.3643 5.36322ZM8.2136 1.22133H12.9684V2.17715H8.2136V1.22133ZM9.81635 4.56671C9.81635 4.0888 10.1903 3.66399 10.6177 3.66399C11.0451 3.66399 11.4191 4.03569 11.4191 4.56671C11.4191 5.04462 11.0451 5.46943 10.6177 5.46943C10.1903 5.46943 9.81635 5.04462 9.81635 4.56671Z"
                fill="#265D91" />
            </svg>


            Offres d’emploi : {{nameOffre}}
          </h1>

        </div>
        <!--end::Title-->
        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <a  class="text-muted"  routerLink="/">Accueil</a>
        </li>
          <!--end::Item-->
          <!--begin::Item-->

          <!--end::Item-->
          <!--begin::Item-->
         
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <li class="breadcrumb-item text-muted"><a class="text-muted" routerLink="/acceuil/offre-emploi/list-offres">Offres d'emploi</a></li>
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <li class="breadcrumb-item text-muted">Liste des candidatures</li>
          <!--end::Item-->
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->
      <!--begin::Actions-->

      <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
  </div>
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->
    <div class="card mb-5 pb-8">
      <div class="container-fluid">
        <div class="title">
          <h4>Recherche</h4>
        </div>

        <div class="row tools" [formGroup]="candidateForm" (keyup.enter)="search()">
          <div class="col-lg-3">
            <div class="position-relative  me-md-2 mb-3">
              <input type="text" formControlName="name" class="form-control form-control-solid ps-5" name="société" value=""
                placeholder="nom" />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="position-relative  me-md-2 mb-3">
              <input type="text" formControlName="email" class="form-control form-control-solid ps-5" name="nom" value=""
                placeholder="email" />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="position-relative  me-md-2 mb-3">
              <input type="number"  min="0" oninput="validity.valid||(value='0');" formControlName="score" class="form-control mb-3" placeholder="Score %"
                    aria-label="Amount (to the nearest dollar)" />
                  <!-- <span class="input-group-text">%</span> -->
            </div>
          </div>
          <div class="col-lg-3">
            <div class="position-relative  me-md-2 mb-3">
              <input type="text" placeholder="Date de début de candidature"
              formControlName="date_start_candidature"
              class="form-control form-control-solid mb-3"
              onfocus="(this.type='date')">
            </div>
          </div>
          <div class="col-lg-3">
            <div class="position-relative  me-md-2 mb-3">
              <input type="text" placeholder="Date de fin de candidature"
              formControlName="date_end_candidature"
              class="form-control form-control-solid mb-3"
              onfocus="(this.type='date')">
            </div>
          </div>
 <div class="col-lg-3 d-flex justify-content-end">
          <div class="d-flex align-items-center recherche mb-3">
            <button type="submit" (click)="search()" class="btn btn-primary  me-5"> <svg width="15" height="15" viewBox="0 0 20 20"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                  fill="white" />
              </svg>
              Rechercher</button>
          </div>

          <div class="resett-btn">
           
            <div class="reset-icon cursor-pointer"  >
             <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
             </div>
          </div>
        </div>
        </div>
       
      </div>
    </div>
    <div class="card">
      <div class="container-fluid">
        <div class="card-body pt-0">
          <!--begin::Table-->
          <div class="title">
            <h4>
              {{listCandidatures[0]?.offer_poste }} <span *ngIf="listCandidatures[0]?.offer_poste"> : </span>  Liste des candidatures </h4>
            <!-- :{{idOffre}} -->
          </div>
          <div class="table-responsive" id="kt_customers_table">
            <table class="table align-middle gy-5 mb-4" >
              <thead>
                <tr class=" text-gray-400 fw-bold text-uppercase gs-0">
                  <th class="min-w-125px"><span>Image</span></th>
                  <th class="min-w-125px"><span>Nom <i (click)="sortData('first_name')" class="fa-solid fa-sort"></i></span></th>
                  <th class="min-w-125px"><span>ANS.EXP <i (click)="sortData('years_of_experience')" class="fa-solid fa-sort"></i></span></th>
                  <th class="min-w-155px"><span>date de candidature <i (click)="sortData('candidate_job_offer_creation_date')" class="fa-solid fa-sort"></i></span></th>
                  <!-- <th class="min-w-125px"><span>CV</span></th> -->
                  <th class="min-w-125px"><span>Score ESN<i (click)="sortData('score_esn_test_candidat')" class="fa-solid fa-sort"></i></span></th>
                  <th class="min-w-125px"><span>Réponse ESN<i (click)="sortData('esn_file_test')" class="fa-solid fa-sort"></i></span></th>


                  <!-- <th class="min-w-125px"><span>Compétences <i (click)="sortData('email')" class="fa-solid fa-sort"></i></span></th> -->
                  <th class="min-w-125px"><span>ACTION</span></th>
               
                </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
                <tr *ngFor="let can of listCandidatures">
                  <td  class="wrapping">
                    <img *ngIf="can?.image_url&& can?.image_url!='avatar.png'" src="{{ url+'/User/file/gallery_users/' + can?.image_url }} " alt="" width="50px">
                    <img *ngIf="can?.image_url&& can?.image_url=='avatar.png'" class="table-img"
                     src="/assets/media/logos/logo-avatar.jpg" alt="" width="50px">
                  </td>
                  <td>
                    <a class="first-td" [routerLink]="['/acceuil/candidats/profil-candidat', {idCandidat:can.id}]" routerLinkActive="router-link-active" >{{can.first_name}}  {{can.last_name}}</a>
                  </td>
                  <td>
                    <span>{{can.years_of_experience?can.years_of_experience:'---'}}</span>
                  </td>
                  <td>
                    <span>{{!can.candidate_job_offer_creation_date ?'---':can.candidate_job_offer_creation_date  |formatDate}}</span>
                  </td>
                
                  <td >

                    <div *ngIf="can.score_esn_test_candidat!=null && can.score_esn_test_candidat!=='null'">
                      <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width.%]="can.score_esn_test_candidat"
                          [attr.aria-valuenow]="can.score_esn_test_candidat" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <span *ngIf="can.score_esn_test_candidat!=null">{{can.score_esn_test_candidat +' %'}}</span>
                    </div>


                    <span *ngIf="can.score_esn_test_candidat==='null' || !can.score_esn_test_candidat">---</span>

                  </td>
                  <td>
                    <a (click)="downloadFiles(can)" *ngIf="can.esn_file_test && can.esn_file_test!=='null'" class="cursor-pointer">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M25.4273 4.05958V21.4387C25.4273 22.515 24.9783 23.5483 24.1801 24.3092C23.382 25.0701 22.2981 25.4982 21.1692 25.4982H4.25806C3.12915 25.4982 2.04528 25.0701 1.24716 24.3092C0.449034 23.5483 0 22.515 0 21.4387V4.05958C0 2.98329 0.449034 1.94995 1.24716 1.18903C2.04528 0.428104 3.12915 0 4.25806 0H21.1692C22.2981 0 23.382 0.428104 24.1801 1.18903C24.9783 1.94995 25.4273 2.98329 25.4273 4.05958ZM11.7058 19.7678C12.1182 20.1369 12.7193 20.2295 13.2288 20.0201C13.3766 19.9584 13.5118 19.8739 13.6314 19.7678L17.8543 15.9928C18.4216 15.4868 18.4497 14.636 17.919 14.0965C17.387 13.5557 16.4959 13.5275 15.9287 14.0348L14.0762 15.6908V6.71006C14.0762 5.96927 13.4456 5.36805 12.6686 5.36805C11.8916 5.36805 11.261 5.96927 11.261 6.71006V15.6908L9.40855 14.0348C8.84127 13.5275 7.95025 13.5557 7.41817 14.0965C6.88749 14.636 6.91565 15.4868 7.48292 15.9928L11.7058 19.7678Z"
                          fill="#F58F29" />
                      </svg>
                    </a>
                    <span *ngIf="!can.esn_file_test || can.esn_file_test==='null'">---</span>
                  </td>
                  <td>
                
                    <div class="tools">
                      <div class="dropdown">
                          <button
                              class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                              type="button" id="dropdownMenuButton"
                              data-bs-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false">
                              <svg width="19" height="5" viewBox="0 0 19 5"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="2.44739" cy="2.44739"
                                      r="2.44739"
                                      transform="matrix(1 0 0 -1 0 4.89478)"
                                      fill="#265D91" />
                                  <circle cx="2.44739" cy="2.44739"
                                      r="2.44739"
                                      transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                      fill="#265D91" />
                                  <circle cx="2.44739" cy="2.44739"
                                      r="2.44739"
                                      transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                      fill="#265D91" />
                              </svg>
                          </button>
                          <div class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item cursor-pointer"
                              (click)="navigateToProfilCandidat(can.id)">Voir profil
                              </a>
                              <!-- (click)="openModal(send,can)" -->
                              <a class="dropdown-item cursor-pointer"  [routerLink]="['/acceuil/offre-emploi/dossier-candidature',can.id,can.offer_id]">voir dossier candidature </a>
                          </div>
                      </div>
                  </div>
                  </td>
                </tr>
              </tbody>
              <!--end::Table body-->
            </table>
            <div class="pagination  mt-4 mb-4" *ngIf="listCandidatures && listCandidatures.length">
              <app-pagination [data]="listCandidatures" [lengthData]="totalItems" (setPages)="getItems($event)">
              </app-pagination>
            </div>
            <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listCandidatures&&!listCandidatures.length ">
              <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
              <span class="empty-list">
               La liste est vide
           </span>
           </div>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
    </div>
    <!--end::Card-->
    <!--begin::Modals-->
    <!--begin::Modal - Customers - Add-->

    <!--end::Modal - Customers - Add-->
    <!--begin::Modal - Adjust Balance-->

    <!--end::Modal - New Card-->
    <!--end::Modals-->
  </div>
  <!--end::Content container-->
</div>
<!-- modal detail offre -->
<div class="modal fade" id="Detail_offre" tabindex="-1" aria-hidden="true">
  <!--begin::Modal dialog-->
  <div class="modal-dialog modal-dialog-centered mw-650px">
    <!--begin::Modal content-->
    <div class="modal-content">
      <!--begin::Form-->
      <form class="form" action="#" id="kt_modal_add_customer_form"
        data-kt-redirect="../../demo1/dist/apps/customers/list.html">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_add_customer_header">
          <!--begin::Modal title-->
          <h2 class="">Voir Détail Offre</h2>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div id="kt_modal_add_customer_close" data-bs-dismiss="modal"
            class="btn btn-icon btn-sm btn-active-icon-primary">
            <i class="ki-duotone ki-cross fs-1">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body py-10 px-lg-17 tools">
          <!--begin::Scroll-->
          <div class="me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true">
            <div class="row">
              <!--begin::Input group-->
              <div class="col-6 fv-row mb-7 ">
                <!--begin::Label-->
                <label class=" fs-6 mb-2">Offre</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input type="text" disabled value="Offre 1" class="form-control form-control-solid mb-3" placeholder=""
                  name="module" />
                <!--end::Input-->
              </div>
              <div class="col-6 fv-row mb-7">
                <!--begin::Label-->
                <label class=" fs-6 mb-2">Date</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input type="text" disabled value="20/03/2023" class="form-control form-control-solid mb-3"
                  placeholder="" name="module" />
                <!--end::Input-->
              </div>
              <div class="col-6 fv-row mb-7">
                <!--begin::Label-->
                <label class=" fs-6 mb-2">ESN</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input type="text" disabled value="ESN 1" class="form-control form-control-solid mb-3" placeholder=""
                  name="module" />
                <!--end::Input-->
              </div>
              <div class="col-6 fv-row mb-7">
                <!--begin::Label-->
                <label class=" fs-6 mb-2">Statut</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input value="Activé" disabled type="text" class="form-control form-control-solid mb-3" placeholder=""
                  name="module" />
                <!--end::Input-->
              </div>
              <div class="col-6 fv-row mb-7">
                <!--begin::Label-->
                <label class=" fs-6 mb-2">Disponibilité</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input disabled type="text" class="form-control form-control-solid mb-3" placeholder="immédiate"
                  name="module" />
                <!--end::Input-->
              </div>
              <div class="col-6 fv-row mb-7">
                <label class=" fs-6 mb-2">Année d'experience</label>
                <input disabled type="text" class="form-control form-control-solid mb-3" placeholder="2 ans"
                  name="module" />
              </div>
              <div class="col-6 fv-row mb-7">
                <label class=" fs-6 mb-2">TJM</label>
                <input disabled type="text" class="form-control form-control-solid mb-3" placeholder="14 €"
                  name="tjm" />
              </div>
              <div class="col-6 fv-row mb-7">
                <label class=" fs-6 mb-2">Salaire</label>
                <input disabled type="text" class="form-control form-control-solid mb-3" placeholder="40000 €"
                  name="salaire" />
              </div>
              <div class="col-6 fv-row mb-7">
                <label class=" fs-6 mb-2">Compétences</label>
                <input disabled type="text" class="form-control form-control-solid mb-3" placeholder="Compétences"
                  name="Compétences" />
              </div>
            </div>
          </div>
          <!--end::Scroll-->
        </div>
      </form>
      <!--end::Form-->
    </div>
  </div>
</div>
<div class="modal fade" id="list_candidatures" tabindex="-1" aria-hidden="true">
  <!--begin::Modal dialog-->
  <div class="modal-dialog modal-dialog-centered mw-800px">
    <!--begin::Modal content-->
    <div class="modal-content">
      <!--begin::Form-->
      <form class="form" action="#" id="kt_modal_add_customer_form"
        data-kt-redirect="../../demo1/dist/apps/customers/list.html">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_add_customer_header">
          <!--begin::Modal title-->
          <h2 class="">liste des candidatures </h2>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div id="kt_modal_add_customer_close" data-bs-dismiss="modal"
            class="btn btn-icon btn-sm btn-active-icon-primary">
            <i class="ki-duotone ki-cross fs-1">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body py-10 px-lg-17">
          <!--begin::Scroll-->
          <div class="me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true">
            <div class="table-responsive">
              <table class="table align-middle   fs-6 gy-5 mb-4" id="kt_customers_table">
                <thead>
                  <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-125px">candidat</th>
                    <th class="min-w-125px ">ESN</th>
                    <th class="min-w-125px ">Client final</th>
                    <th class="min-w-125px ">STATUT</th>
                  </tr>
                </thead>
                <tbody class=" ">
                  <tr>
                    <td>
                      <span>jean doe</span>
                    </td>
                    <td>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input disabled class="form-check-input checkbox-border" type="checkbox" value="1" />
                      </div>
                    </td>
                    <td>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input disabled checked class="form-check-input checkbox-border" type="checkbox" value="1" />
                      </div>
                    </td>
                    <td>
                      <span class="recrute"> <i class="fa-solid fa-circle mr-2"></i>Pré-selectionné</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>jean doe</span>
                    </td>
                    <td>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input disabled class="form-check-input checkbox-border" type="checkbox" value="1" />
                      </div>
                    </td>
                    <td>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input disabled checked class="form-check-input checkbox-border" type="checkbox" value="1" />
                      </div>
                    </td>
                    <td>
                      <span class="recrute"> <i class="fa-solid fa-circle mr-2"></i>Pré-selectionné</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>jean doe</span>
                    </td>
                    <td>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input disabled checked class="form-check-input checkbox-border" type="checkbox" value="1" />
                      </div>
                    </td>
                    <td>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input disabled class="form-check-input checkbox-border" type="checkbox" value="1" />
                      </div>
                    </td>
                    <td>
                      <span class="recrute"> <i class="fa-solid fa-circle mr-2"></i>Pré-selectionné</span>
                    </td>
                  </tr>


                </tbody>
                <!--end::Table body-->
              </table>
              <div class="pagination-wrapper mt-4 mb-4">
                <ul class="pagination">
                  <li class="page-item previous disabled"><a href="#" class="page-link"><i class="previous"></i></a>
                  </li>
                  <li class="page-item "><a href="#" class="page-link">1</a></li>
                  <li class="page-item active"><a href="#" class="page-link">2</a></li>
                  <li class="page-item "><a href="#" class="page-link">3</a></li>
                  <li class="page-item "><a href="#" class="page-link">4</a></li>
                  <li class="page-item "><a href="#" class="page-link">5</a></li>
                  <li class="page-item "><a href="#" class="page-link">6</a></li>
                  <li class="page-item next"><a href="#" class="page-link"><i class="next"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
          <!--end::Scroll-->
        </div>
      </form>
      <!--end::Form-->
    </div>
  </div>
</div>




<ng-template #send>
  <!--begin::Modal content-->
  <div class="modal-content" >
      <!--begin::Modal header-->
      <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div class="btn btn-sm btn-icon btn-active-color-primary" (click)="closeModal()" >
              <i class="ki-duotone ki-cross fs-1">
                  <span class="path1"></span>
                  <span class="path2"></span>
              </i>
          </div>
          <!--end::Close-->
      </div>
      <!--begin::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0"  [formGroup]="applyForm">
          <!--begin::Heading-->
          <div class="text-center mb-13">
              <!--begin::Title-->
              <h1 class="mb-3">Dossier de Candidature </h1>
          </div>
          <!--end::Heading-->
          <div class="mb-10">
              <label for="argument">Lettre de motivation</label>
              <textarea name="argument" id="argument" placeholder="Lettre de motivation" formControlName="letter" class="form-control" [readonly]="true"></textarea>
          </div>
          <div class="mb-10">
              <label for="date">Salaire</label>
              <input type="number" class="form-control style-input" formControlName="salary" placeholder="Salaire" [readonly]="true">
              </div>
          <div class="mb-10">
              <label for="TJM">TJM</label>
              <input type="number" class="form-control style-input" formControlName="tjm" placeholder="TJM" [readonly]="true">
          </div>
          <!--begin::Users-->
      
          <!--end::Users-->
          <!--begin::Notice-->
      
          <!--end::Notice-->
      </div>
      
      <div class="modal-footer">
          <!-- <a  class="annuler" (click)="closeModal()" style="cursor: pointer;">Annuler</a>
          <a  class="confirmer"  style="cursor: pointer;" (click)="applyCandidateJob(idJobs, state, postuled,'apply')"> Postuler <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.3014 6.93489L1.8289 0.125525C1.60951 0.0223123 1.36294 -0.0185918 1.11926 0.00780132C0.875591 0.0341944 0.645346 0.126744 0.456611 0.274162C0.267876 0.42158 0.128808 0.617495 0.0563695 0.838012C-0.0160691 1.05853 -0.0187478 1.29412 0.04866 1.51604L1.89651 7.60228H8.49911C8.61083 7.60228 8.71798 7.64404 8.79698 7.71838C8.87598 7.79272 8.92036 7.89355 8.92036 7.99868C8.92036 8.10382 8.87598 8.20464 8.79698 8.27899C8.71798 8.35333 8.61083 8.39509 8.49911 8.39509H1.89651L0.04866 14.4813C-0.00455989 14.6581 -0.0136708 14.8441 0.0220375 15.0248C0.0577458 15.2055 0.137309 15.3759 0.254525 15.5229C0.371741 15.6699 0.523444 15.7894 0.69781 15.8722C0.872177 15.9549 1.0645 15.9987 1.25978 16C1.45757 15.9996 1.65252 15.9557 1.82911 15.8718L16.3014 9.06248C16.5114 8.96368 16.6879 8.81182 16.8113 8.62394C16.9347 8.43605 17 8.21955 17 7.99868C17 7.77782 16.9347 7.56132 16.8113 7.37343C16.6879 7.18554 16.5114 7.03369 16.3014 6.93489Z" fill="white"/>
              </svg>
          </a>
           -->

      </div>
      <!--end::Modal body-->
  </div>
  <!--end::Modal content-->
</ng-template>