<head>
    <title>Liste salaries client final</title>
    <meta name="description" content="Liste salaries client final">
  </head>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <div id="kt_app_content_container" class="app-container container-fluid">
      <!--begin::Card-->
      <div class="card mb-10">
        <div class="card-body tools" [formGroup]="searchFormGroup" >
          <div class="title mb-3">
            <h4>Recherche</h4>
          </div>
          <div class="row mb-3">
            <div class="col-lg-3">
              <input type="text" name="id" id="id" class="form-control form-control-solid mb-3" formControlName="name"
                placeholder="Nom et prénom">
            </div>
            <div class="col-lg-3">
              <input type="text" name="poste" id="nom" formControlName="poste" class="form-control form-control-solid mb-3"
                placeholder="poste">
            </div>
            <div class="col-lg-3">
              <ng-select [items]="listprojet" class="form-control form-select" bindLabel="name" [notFoundText]="'Aucun élément trouvé'"
              [multiple]="true" formControlName="project_id" bindValue="id"
              [placeholder]="'Projet'" autoComplete>
          </ng-select>
            </div>
            <div class="col-lg-3">
              <div class="mb- search-btn d-flex justify-content-start">
                <div class="align-items-center recherche">
                  <button type="submit" class="me-5" (click)="applyFilter()">
                    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                        fill="white" />
                    </svg>
                    Rechercher</button>
                </div>
                <div class="reset-icon cursor-pointer">
                  <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                </div>
  
              </div>
            </div>
          </div>
  
        </div>
  
        <!--begin::Card header-->
  
        <!--end::Card header-->
        <!--begin::Card body-->
  
        <!--end::Card body-->
      </div>
      <div class="card">
        <div class="card-body pt-0">
          <div class="title">
            <h4>Liste des salaries</h4>
          </div>
          <!--begin::Table-->
          <div class="table-responsive">
            <table class="table align-middle   fs-6 gy-5 mb-4" id="kt_customers_table">
              <thead>
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
  
                  <th class="min-w-125px"><span>Nom <i (click)="sortData('first_name')"
                        class="fa-solid fa-sort cursor-pointer"></i></span></th>
                  <th class="min-w-125px"><span>Poste <i (click)="sortData('poste')"
                        class="fa-solid fa-sort cursor-pointer"></i></span></th>
                  <th class="min-w-125px"><span>EMAIL Personnel<i (click)="sortData('email')"
                        class="fa-solid fa-sort cursor-pointer"></i></span></th>
                  <th class="min-w-125px"><span>EMAIL Professionnel<i (click)="sortData('emailProClient')"
                        class="fa-solid fa-sort cursor-pointer"></i></span></th>
                  <th class="min-w-125px"><span>n° TEL <i (click)="sortData('phone_number')"
                        class="fa-solid fa-sort cursor-pointer"></i></span></th>
                        <th class="min-w-125px"><span>Projet<i (click)="sortData('phone_number')"
                          class="fa-solid fa-sort cursor-pointer"></i></span></th>
                  <th class="min-w-125px"><span>ACTION</span></th>
                </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
  
                <tr *ngFor="let client of listsalarie;let i =index">
  
                  <td class="wrapping">
                    <a 
                    [routerLink]="['/acceuil/candidats/profil-candidat',{idCandidat:client.id}]" routerLinkActive="router-link-active" 
                      class="first-td name m-0 cursor-pointer">{{client.last_name? client.last_name :'---'}} {{client.first_name? client.first_name :'---'}}  </a>
                  </td>
                  <td class="wrapping">
                    {{client.poste? client.poste :'---'}}
                </td>
                  <td class="wrapping">
                    <span>{{client.email?client.email:'---'}}</span>
                  </td>
                  <td class="wrapping">
                    <span>{{client.emailProClient?client.emailProClient:'---'}}</span>
                  </td>
                  <td class="wrapping">
                    <span>{{client.phone_number?client.phone_number:'---'}}</span>
                  </td>
                  <td><span>{{client.project_names? client.project_names :'---'}}</span></td>
                  <td>
                    <div class="seemore">
                      <a class="cursor-pointer" (click)="openModal(details_salarie,client)"> Voir détail</a>
                    </div>
                  </td>
           
                </tr>
              </tbody>
              <!--end::Table body-->
            </table>
            <div class="pagination mt-4" *ngIf="listsalarie && listsalarie.length">
              <app-pagination [data]="listsalarie" [currentPageList]="currentPage" [lengthData]="totalItems" [endexPageList]="endIndex"
                (setPages)="getItems($event)">
              </app-pagination>
            </div>
  
  
            <div class="alert alert-warning text-center mt-5" role="alert"
              *ngIf="listsalarie&&!listsalarie.length ">
              <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
              <span class="empty-list">
                La liste est vide
              </span>
            </div>
          </div>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Content container-->
  </div>
  <ng-template #details_salarie>
    <div class="modal-dialog-centered" id="details_salarie" tabindex="-1" aria-hidden="true">
      <!--begin::Modal dialog-->
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Form-->
        <form class="form" action="#" id="kt_modal_add_customer_form" aria-disabled="true">
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_add_customer_header">
            <!--begin::Modal title-->
            <h2 class="">Détails salarie</h2>
            <!--end::Modal title-->
            <!--begin::Close-->
            <div id="kt_modal_add_customer_close" class="btn btn-icon btn-sm btn-active-icon-primary">
              <a class="cursor-pointer" (click)="closeModal()">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path d="M14 1L1 14" stroke="#716F6F" stroke-width="1.5" stroke-linecap="round" />
                  <path d="M1 1L14 14" stroke="#716F6F" stroke-width="1.5" stroke-linecap="round" />
                </svg></a>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->
          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div class=" me-n7 pe-7 " id="">
              <div class="row ">
                <!--begin::Input group-->
                <div class="col-4 fv-row mb-7 ">
                  <label class="d-flex align-items-center tools form-label mb-2">
                    <span class="">Nom et prénom</span>
                  </label>
                  <!--end::Label-->
                  <input [disabled]="true" [value]="selectedsalarie?.name" type="text"
                    class="form-control form-control-solid" placeholder="nom" name="Nom" />
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 fw-semibold mb-2 tools">
                    <span class="">N° Tel</span>
                  </label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <!-- <input [disabled]="true" [value]="selectedsalarie?.phone_number" type="text" class="form-control form-control-solid" placeholder="+33333333333" name="email"
                     /> -->
                  <!--end::Input-->
                  <div class="wrapper" [formGroup]="phoneForm">
                    <ngx-intl-tel-input [cssClass]="'custom'" [disabled]="true" [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                      [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                            ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.France" [maxLength]="10"
                      [phoneValidation]="true" [separateDialCode]="separateDialCode"
                      [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone">
                    </ngx-intl-tel-input>
                  </div>
                </div>
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 tools fw-semibold mb-2">
                    <span class="">Email </span>
                  </label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [disabled]="true" [value]="selectedsalarie?.email" type="email"
                    class="form-control form-control-solid" placeholder="consultant1@gmail.com" name="email" />
                  <!--end::Input-->
                </div>
              </div>
  
              <div class="row">
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 tools fw-semibold mb-2">Email Professionnel</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [disabled]="true" [value]="selectedsalarie?.emailProClient" type="email"
                    class="form-control form-control-solid" placeholder="email-pro@gmail.com" name="email-pro" />
                  <!--end::Input-->
                </div>
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 tools fw-semibold mb-2">TJM(achat)</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [disabled]="true" [value]="selectedsalarie?.tjm?selectedsalarie?.tjm +' €':''" type="text"
                    class="form-control form-control-solid" placeholder="tjm" name="tjm" />
                  <!--end::Input-->
                </div>
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 tools fw-semibold mb-2">Salaire</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [disabled]="true"
                    [value]="selectedsalarie?.desired_salary?selectedsalarie?.desired_salary +' €':''" type="text"
                    class="form-control form-control-solid" placeholder="salaire" name="salaire" />
                  <!--end::Input-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
  
              </div>
          
              <div class="row">
  
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 tools fw-semibold mb-2">Date début contrat</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <div class="position-relative d-flex align-items-center">
  
                    <!--begin::Datepicker-->
                    <input [disabled]="true"
                      [value]="!selectedsalarie?.contract_start_date?'':selectedsalarie?.contract_start_date|formatDate"
                      type="text" placeholder="Date début contrat" class="form-control form-control-solid mb-3"
                      onblur="(this.type='text')">
                    <!--end::Datepicker-->
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 tools fw-semibold mb-2">LinkedIn</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [disabled]="true"
                    [value]="selectedsalarie?.linkedin_link" type="text"
                    class="form-control form-control-solid" placeholder="LinkedIn" name="linkedin_link" />
                  <!--end::Input-->
                </div>
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 tools fw-semibold mb-2">Cv</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [disabled]="true"
                    [value]="selectedsalarie?.cv" type="text"
                    class="form-control form-control-solid" placeholder="cv" name="cv" />
                  <!--end::Input-->
                </div>
              </div>
  
  
  
  
            </div>
            <!--end::Scroll-->
          </div>
        </form>
      </div>
    </div>
  </ng-template>