<span  style="color: red" *ngIf="_control && _control.errors&&(_control.dirty||_control.touched)">
  <small *ngIf="_control.errors['required'] && !['linkedin_link','cv'].includes(controlName)"> Le champ est obligatoire. </small>
  <small *ngIf="_control.errors['required'] && ['linkedin_link','cv'].includes(controlName)"> Au moins l'un des champs CV ou LinkedIn est requis. </small>
  <small *ngIf="controlName&&controlName==='email'&& !_control.errors['required']">La valeur de ce champ doit avoir une adresse email valide.</small>
  <small *ngIf="controlName&&controlName==='password'&& !_control.errors['required']">Votre mot de passe doit contenir au moins 8 caractères dont une lettre MAJ , une lettre min et un caractère spécial.</small>
  <small *ngIf="!_control.errors['required'] && _control.errors['pattern']&& _control.errors['pattern']['requiredPattern']==urlRegExp">Entrer une URL valide exemple: https://www.exemple.com ou www.exemple.com ou https://exemple.com </small>
  <small *ngIf="!_control.errors['required'] && _control.errors['pattern']&&_control.errors['pattern']['requiredPattern']==linkedCompanyRegExp">Entrer une URL valide exemple: https://www.linkedin.com/company/exemple</small>
  <small *ngIf="!_control.errors['required'] && _control.errors['pattern']&&_control.errors['pattern']['requiredPattern']==linkedInRegExp">Entrer une URL valide exemple: https://www.linkedin.com/in/exemple</small>
 
  <small *ngIf="!_control.errors['required'] &&_control.errors['pattern']&& _control.errors['pattern']['requiredPattern']==stringRegExp">Ce champ ne comprend pas des caractère numeriques</small>
  <small *ngIf="!_control.errors['required'] && _control.errors['pattern']&&_control.errors['pattern']['requiredPattern']==youTubePattern">Entrer une URL valide exemple: https://www.youtube.com/watch?v=sdsdsd</small>
  <small *ngIf="!_control.errors['required'] && _control.errors['invalidDateRange']">La date début ne doit pas être supérieure à la date fin. </small>
  <small *ngIf="!_control.errors['required'] && _control.errors['pattern']&&_control.errors['pattern']['requiredPattern']==patternIBAN">Format IBAN incorrecte exemple:FR1420041010050500013B02604 ,Les caractères doivent être en majuscules.</small>
  <small *ngIf="!_control.errors['required'] && _control.errors['pattern']&&_control.errors['pattern']['requiredPattern']==patternBIC">Format code BIC incorrecte exemple:ABCDEF12 ,Les caractères doivent être en majuscules.</small>
  <small *ngIf="controlName&&controlName==='year_of_creation'&& !_control.errors['required']">La valeur de ce champ doit avoir une date valide.</small>
  <small *ngIf="controlName&&controlName==='question_FR'&& !_control.errors['required']">La valeur de ce champ  doit contenir 255 caractères au maximum</small>

 
</span>

