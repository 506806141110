import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { PagesComponent } from './components/pages/pages.component';
import { RolesComponent } from './components/roles/roles.component';
import { DetailRoleComponent } from './components/roles/components/detail-role/detail-role.component';
import { PagesActorsComponent } from './components/pages-actors/pages-actors.component';
import { GeneralModule } from './components/general/general.module';
import { ListAbonnementComponent } from './components/list-abonnement/list-abonnement.component';
import { FieldErrorModule } from '../../shared/components/shared-field-error/field-error.module';
import { EvaluationComponent } from './components/evaluation/evaluation.component';
import { EvaluationDefaultComponent } from './components/evaluation/evaluation-default/evaluation-default.component';
import { EvaluationWegestuComponent } from './components/evaluation/evaluation-wegestu/evaluation-wegestu.component';
import { EvaluationSocieteComponent } from './components/evaluation/evaluation-societe/evaluation-societe.component';
import { ListeEvaluationComponent } from './components/evaluation/liste-evaluation/liste-evaluation.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { RatingModule } from 'ngx-bootstrap/rating';
import { RateListCompanyComponent } from './components/evaluation/rate-list-company/rate-list-company.component';
import { PipesModuleModule } from '../../core/pipes/pipes-module.module';
import { AddOfferComponent } from './components/subscription/add-subscription/add-subscription.component';
import { ListEvalWegestuComponent } from './components/evaluation/evaluation-default/list-eval-wegestu/list-eval-wegestu.component';
import { AddRoleComponent } from './components/roles/components/add-role/add-role.component';
import { DragDropModule,CdkDropList,CdkDrag } from '@angular/cdk/drag-drop';
import { EvaluationDetailsComponent } from './components/evaluation/evaluation-details/evaluation-details/evaluation-details.component';
import { QuillModule } from 'ngx-quill';
import { OffreComponent } from './components/subscription/subscription.component';
import { CalendarModule } from 'primeng/calendar';
import {SharedModule} from '../../shared/shared.module'
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
@NgModule({
  declarations: [
    PagesComponent,
    RolesComponent,
    OffreComponent,
    DetailRoleComponent,
    PagesActorsComponent,
    ListAbonnementComponent,
    EvaluationComponent,
    EvaluationDefaultComponent,
    EvaluationWegestuComponent,
    EvaluationSocieteComponent,
    ListeEvaluationComponent,
    RateListCompanyComponent,
    AddOfferComponent,
    ListEvalWegestuComponent,
    AddRoleComponent,
    EvaluationDetailsComponent,
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    GeneralModule,
    FieldErrorModule,
    NgxSpinnerModule,
    PaginationModule,
    RatingModule.forRoot(),
    PipesModuleModule,
    DragDropModule,
    CdkDropList,
    CdkDrag,
    QuillModule.forRoot(),
    CalendarModule,
    SharedModule,
    MatMenuModule,
    MatExpansionModule,
    
   
  ],
})
export class SettingsModule {}
