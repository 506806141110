import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidatService } from '../../../../core/services/candidat/candidat.service';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../core/models/messages';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import * as saveAs from 'file-saver';
import { CvType } from 'src/app/core/models/enums/cv-type.enum';

@Component({
  selector: 'app-cv-candidat',
  templateUrl: './cv-candidat.component.html',
  styleUrls: ['./cv-candidat.component.css'],
})
export class CvCandidatComponent {
  constructor(
    private spinner: NgxSpinnerService,
    private candidatService: CandidatService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  endIndex: number = 5;
  idCandidat: any;
  selectedCandidate: any;
  totalItems: any;
  skillsCandidat: any;
  cvType: string | undefined;
  private unsubscribeAll: Subject<void> = new Subject();
  /* string */
  url: string = environment.baseUrl;
  baseUrl_Company = environment.baseUrlGaleryCompany;
  cvTypeEnum = CvType

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      // Hide the spinner after some time (or after data is loaded)
      this.spinner.hide();
    }, 5000); // Adjust the delay as needed
    this.getRoutingParams();
    this.getSkills();
  }

  getRoutingParams() {

    this.route.params.subscribe(params => {
      this.idCandidat = params['idCandidat'];
      this.getCandidateById()
    });

    if (history.state && history.state.type) {
      const type = history.state.type
      if (type === this.cvTypeEnum.WEGESTU || type === this.cvTypeEnum.ESN) {
        this.cvType = type;
      }
    } 
  }
  formatPeriod(period: string): string {
    if (!period) return '';
  
    const matches = period.match(/(\d+) ans, (\d+) mois, (\d+) jours/);
    if (!matches) return period;
  
    const years = parseInt(matches[1], 10);
    const months = parseInt(matches[2], 10);
  
    if (years === 0 && months === 0) {
      return `moins d'un mois`;
    } else {
      let result = '';
  
      if (years > 0) {
        result += `${years} An${years > 1 ? 's' : ''}`;
      }
      if (months > 0) {
        if (result.length > 0) result += ', ';
        result += `${months} Mois`;
      }
  
      return result;
    }
  }
  capitalizeFirstLetter(string:any) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  } 
  getCandidateById() {
    this.spinner.show();
    this.candidatService
      .getUserById(this.idCandidat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.selectedCandidate = res.data[0];
          this.selectedCandidate.softSkills= this.selectedCandidate.softSkills.filter((skill:any) => skill !== null);
          console.log("selectedCandidate of ", this.selectedCandidate)
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  exportCvWegest(file?: File) {
    let exportSuccess = false;
    let data = this.idCandidat;
    this.candidatService.exportCvWegestCandidat(file, data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'CvWegest.pdf');
        exportSuccess = true;
        if (exportSuccess) {
          this.toastrService.success('Fichier exporté avec succès');
        }
        window.location.reload();
      },
      error: () => {
        this.spinner.hide();
        this.toastrService.error(serverError);
      },
    });
  }

  exportCvAnonymePdf(file?: File) {
    let exportSuccess = false;
    let data = this.idCandidat;
    this.candidatService.exportCvAnonymePdf(file, data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'CvWegest.pdf');
        exportSuccess = true;
        if (exportSuccess) {
          this.toastrService.success('Fichier exporté avec succès');
        }
        window.location.reload();
      },
      error: () => {
        this.spinner.hide();
        this.toastrService.error(serverError);
      },
    });
  }
  exportCvAnonymeWord(file?: File) {
    let exportSuccess = false;
    let data = this.idCandidat;
    this.candidatService.exportCvAnonymeWord(file, data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'CvWegest.docx');
        exportSuccess = true;
        if (exportSuccess) {
          this.toastrService.success('Fichier exporté avec succès');
        }
        window.location.reload();
      },
      error: () => {
        this.spinner.hide();
        this.toastrService.error(serverError);
      },
    });
  }
  navigateToProfilCandidat(idCandidat?: number) {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  getSkills() {
    this.candidatService
      .getSkillsCandidate(this.idCandidat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsCandidat = res.data.categoriesWithSubCategoriesAndSkills
            .map((category:any) => ({
              ...category,
              sub_category: category.sub_category
                .map((subCategory:any) => ({
                  ...subCategory,
                  skills: subCategory.skills.filter((skill:any) => skill.checked === '1')
                }))
                .filter((subCategory:any) => subCategory.skills.length > 0)
            }))
            .filter((category:any) => category.sub_category.length > 0);

          this.totalItems = res.data.count;
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }
  exportToPDF() {
    const element = document.getElementById('kt_app_content');

    if (element) {
      this.spinner.show();
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('export_Cv.pdf');
        this.spinner.hide();
      });
    } else {
      console.error('Element not found!');
    }
  }
  NavigateToUpdateCandidat() {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat/update',
      { idCandidat: this.idCandidat },
    ]);
  }
  exportCvWordWegest(file?: File) {
    let exportSuccess = false;
    let data = this.idCandidat;
    this.candidatService.exportCvWegestWordCandidat(file, data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'CvWegest.docx');
        exportSuccess = true;
        if (exportSuccess) {
          this.toastrService.success('Fichier exporté avec succès');
        }
        window.location.reload();
      },
      error: () => {
        this.spinner.hide();
        this.toastrService.error(serverError);
      },
    });
  }

  /**
 * Exports a CV as a file. Depending on the type, it will either export an ESN CV or an anonymous ESN CV.
 * @param {File} [file] - Optional file object to include in the export request.
 * @param {boolean} isAnonymous - Indicates whether to export an anonymous CV (true) or a regular ESN CV (false).
 */
  exportCvEsnCandidate(isAnonymous: boolean, file?: File): void {
    const exportSuccess = false;
    const data = this.idCandidat;
  
    const exportMethod = isAnonymous 
      ? this.candidatService.exportCvAnonymousESNCandidate(file, data)
      : this.candidatService.exportCvESNCandidate(file, data);
  
    exportMethod.subscribe({
      next: (res: any) => {
        this.spinner.hide();
        
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        
        const fileName = isAnonymous ? 'CvAnonymeESN.pdf' : 'CvESN.pdf';
        saveAs(blob, fileName);
  
        if (exportSuccess) {
          this.toastrService.success('Fichier exporté avec succès');
        }
      },
      error: () => {
        this.spinner.hide();
        this.toastrService.error(serverError);
      },
    });
  }
  

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
