import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatDatePipe } from './format-date.pipe';
import { HtmlsafePipe } from './htmlsafe.pipe';



@NgModule({
  declarations: [FormatDatePipe, HtmlsafePipe],
  imports: [
    CommonModule
  ],
  exports:[FormatDatePipe,HtmlsafePipe]
})
export class PipesModuleModule { }
