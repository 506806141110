<div class="details">
    <div class="title">
        <h4>Note moyenne ({{reviewCompany?.nb_avis}})</h4>
    </div>
    <div class="avis" style="display: flex;justify-content: space-between">
        <div>
        <span>Avis moyenne : </span>
         </div>
        <div>
            <div class="col-lg-2 col-6 col-sm-4" style="margin: auto;">
                <div class="stars mb-3" *ngIf="reviewCompany?.avrage_note_total">
                    <rating [titles]="[' ',' ',' ',' ',' ']" [readonly]="true"
                        class="rating-stars"  [(ngModel)]="reviewCompany.avrage_note_total.average_note_total"></rating>
                </div>
            </div>
        </div>
    </div>

    <div class="avis" style="display: flex;" *ngFor="let questionReview of reviewCompany?.data">
        <div>
        <span>{{questionReview?.question ? questionReview?.question:'---'}} </span>
    </div>
        <div >
            <div class="col-lg-2 col-6 col-sm-4" style="margin: auto;">
                <div class="stars mb-3">
                    <rating [titles]="[' ',' ',' ',' ',' ']" [readonly]="true"   [(ngModel)]="questionReview.average_note"
                        class="rating-stars"></rating>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-15 details" *ngIf="disabledMode">
   
    <div class="title">
        <h4>Avis récent ({{reviewCompany?.nb_avis}}) </h4>
    </div>
    <div class="card-body">
       
            <div class="row g-6 g-xl-9 cards">
                <!--begin::Col-->
                <div class="col-sm-6 col-12 col-xl-4" *ngFor="let review of reviewCandidat">
                    <!--begin::Card-->
                    <div class="card h-100">
                        <!--begin::Card header-->
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title mt-10">
                                <!--begin::Icon-->

                                <!--end::Icon-->
                                <!--begin::Title-->
                                <div class="head-avis mb-5 w-100">
                                    <div class="card-head w-100">
                                        <div class="avatar">
                                            <div class="avatar-img">
                                                <img class="table-img" *ngIf="!review?.image_url"
                                                    src="/assets/media/logos/logo-avatar.jpg" width="50px">
                                                <img class="table-img" *ngIf="review?.image_url"
                                                    src="{{url +'/User/file/gallery_users/' + review?.image_url}}"
                                                    width="50px" />
                                            </div>
                                            <div class="avatar-name">
                                                <a class="name cursor-pointer" (click)="navigatToDetailsCandidat(review.id)">{{review?.first_name ? review?.first_name :'---'}} {{review?.last_name? review?.last_name:'---'}}</a>
                                            </div>
                                        </div>
                                        <div class="modify">
                                            <span >Modifié le : {{review?.Date_last_update ? review?.Date_last_update :'---' | date:'dd/MM/yyyy'}}</span>
                                        </div>
                                    </div>
                                 
                                   
                                    </div>
                                    <!--end::Title-->
                            </div>
                        </div>
                        <!--end::Card header-->
                        <!--begin::Card body-->
                        <div class="card-body">
                            <div *ngFor="let question of review?.response_specific_review">
                                <div class="infos" style="display: flex;justify-content: space-between;">
                                    <div>
                                        <p class="sub-info"> {{question?.specific_review?.question ? question?.specific_review?.question :'---' }}
                                        </p>
                                    </div>
                                    <div class="stars" *ngIf="question?.stars">
                                        <div style="margin: auto;">
                                            <div class="stars" *ngIf="question?.stars">
                                                <rating [titles]="[' ',' ',' ',' ',' ']"
                                                    [readonly]="true" class="rating-stars" [(ngModel)]="question.stars"></rating>
                                            </div>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p style="color: #7E7C7C; font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 400; line-height: normal;"
                            *ngIf="review?.Comnt">Commentaire: {{review?.Comnt ? review?.Comnt :'---'}}</p>
                        </div>
                        <!--end::Card body-->
                        <!--end::Card body-->
                    </div>
                    <!--end::Card-->
                </div>
                

                        <div class="alert alert-warning text-center mt-5" role="alert"  *ngIf="!reviewCandidat?.length">
                            <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                            <span class="empty-list">
                             La liste est vide
                         </span>
                         </div>
                <!--end::Col-->
            </div>
      
    </div>
</div>