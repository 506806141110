import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/authentication/auth.service';
import { inject } from '@angular/core';
import { NetworkService } from '../../services/NetworkService';

export namespace mainGuard {
  export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => {
    const authService = inject(AuthService);
    const networkService = inject(NetworkService);
    const router = inject(Router);

    if (!networkService.getOnlineStatus()) {
      router.navigate(['/reconnect']);
      return false;
    } 
    
    if (authService.isAuthenticated()) {
      return true;
    } else {
      router.navigate(['/']);
      return false;
    }
  };

  export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivate(route, state);
}
