
<div id="kt_app_content" class="app-content flex-column-fluid">
  <div id="kt_app_content_container" class=" container-fluid">
    <!--begin::Card-->
    <div class="card mb-10" >
    
      <div class="card-body tools mt-5" [formGroup]="searchFormGroup" >
        <div class="title-search mb-3">
          <h4>Recherche</h4>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3">
            <input type="text" name="id" id="id" class="form-control form-control-solid mb-3" formControlName="name"
              placeholder="Nom et prénom">
          </div>
          <div class="col-lg-3">
            <input type="text" name="nom" id="nom" formControlName="role" class="form-control form-control-solid mb-3"
              placeholder="Role">
          </div>

          <div class="col-lg-6">
            <div class="mb-3 search-btn d-flex justify-content-end">
              <div class="align-items-center recherche">
                <button type="submit" class="me-5" (click)="applyFilter()">
                  <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                      fill="white" />
                  </svg>
                  Rechercher</button>
              </div>
              <div class="reset-icon cursor-pointer"  >
                <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card content pt-10 pb-10">
      <div class="title d-flex justify-content-between">
        <h4>Liste des intervenants</h4>
<div class="unit-add">
  <button class="btn" (click)="openModal(kt_modal_add_customer,'create')">Ajouter Intervenant</button>
</div>
      </div>
      <div class="card-body pt-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table align-middle   fs-6 gy-5 mb-4" id="kt_customers_table">
            <thead>
              <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">

                <th class="min-w-125px"><span>Nom <i (click)="sortData('first_name')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                <th class="min-w-125px"><span>RÔLE <i (click)="sortData('employee_role_id')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                <th class="min-w-125px"><span>EMAIL Personnel<i (click)="sortData('email')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                <th class="min-w-125px"><span>EMAIL Professionnel<i (click)="sortData('emailProESN')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                <th class="min-w-125px"><span>n° TEL <i (click)="sortData('phone_number')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                <th class="min-w-125px"><span>Dernière connexion <i (click)="sortData('last_connection')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                <th class="min-w-125px"><span>ACTION</span></th>
              </tr>
            </thead>
            <tbody class="fw-semibold text-gray-600">
              <tr *ngFor="let client of listIntervenant;let i =index">

                <td class="wrapping">
                  <a 
                  [routerLink]="['/acceuil/candidats/profil-candidat' , {idCandidat:client.id}]" routerLinkActive="router-link-active" 
                  class="first-td name m-0 cursor-pointer">{{client.last_name}} {{client.first_name}}  </a>
                </td>
                <td class="wrapping"><span *ngIf="listRole.length">
                  {{ getRoleName(client.employee_role_id) }}
                </span>
              </td>
              <td class="wrapping">
                <span>{{client.email?client.email:'---'}}</span>
              </td>
              <td class="wrapping">
                <span>{{client.emailProESN?client.emailProESN:'---'}}</span>
              </td>
                <td class="wrapping">
                  <span>{{client.phone_number?client.phone_number:'---'}}</span>
                </td>
                <td>   <span>{{!client.last_connection?'---':client.last_connection|formatDate}}</span></td>
                <td>
                  <div class="tools">
                    <div class="dropdown">
                      <button class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                        type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                            fill="#265D91" />
                          <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)"
                            fill="#265D91" />
                          <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)"
                            fill="#265D91" />
                        </svg>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item cursor-pointer"
                          (click)="openModal(kt_modal_add_customer,'details',client)">Voir détail</a>
                        <a class="dropdown-item cursor-pointer"
                          (click)="openModal(kt_modal_add_customer,'update',client)">Modifier</a>
                        <a class="dropdown-item cursor-pointer" (click)="swallDeleteCat(client)">Supprimer</a>

                      </div>
                    </div>
                  </div>

                </td>

              </tr>

            </tbody>
            <!--end::Table body-->
          </table>
          <div class="pagination mt-4" *ngIf="listIntervenant && listIntervenant.length">
            <app-pagination [data]="listIntervenant" [currentPageList]="currentPage" [endexPageList]="endIndex" [lengthData]="totalItems"
              (setPages)="getItems($event)">
            </app-pagination>
          </div>
        

          <div class="alert alert-warning text-center mt-5" role="alert"  *ngIf="listIntervenant&&!listIntervenant.length ">
            <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
            <span class="empty-list">
             La liste est vide
         </span>
         </div>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
  <!--begin::Modals-->
  <!--begin::Modal - Customers - Add-->
  <ng-template #kt_modal_add_customer>
    <div class="" id="kt_modal_add_customer" tabindex="-1" aria-hidden="true">
      <!--begin::Modal dialog-->
      <div class=" modal-dialog-centered ">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Form-->
          <form class="form" action="#" id="kt_modal_add_customer_form"
            data-kt-redirect="../../demo1/dist/apps/customers/list.html">
            <!--begin::Modal header-->
            <div class="modal-header" id="kt_modal_add_customer_header">
              <!--begin::Modal title-->
              <h2 class="">{{mode=='create'?'Ajouter ':mode=='update'? 'Modifier ':'Détail'}} intervenant</h2>
              <!--end::Modal title-->
              <!--begin::Close-->
              <div id="kt_modal_add_customer_close" class="btn btn-icon btn-sm btn-active-icon-primary">
                <a (click)="closeModal()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <path d="M14 1L1 14" stroke="#716F6F" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M1 1L14 14" stroke="#716F6F" stroke-width="1.5" stroke-linecap="round" />
                  </svg></a>
              </div>
              <!--end::Close-->
            </div>
            <!--end::Modal header-->
            <!--begin::Modal body-->
            <div class="modal-body py-10 px-lg-17">
              <!--begin::Scroll-->
              <div class=" me-n7 pe-7 tools" id="" [formGroup]="intervenantForm">
                <!--begin::Input group-->
                <div class="fv-row mb-7">
                  <!--begin::Label-->
                  <label [ngClass]="['create','update'].includes(mode)?'required':''" class="fs-6 fw-semibold mb-2">
                    <span class="">Email personnel</span>
                  </label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input type="email" formControlName="email"  (keyup)="filterByEmail($event)" class="form-control form-control-solid"
                    placeholder="email" name="email" value="" />
                  <!--end::Input-->
                  <app-feild-error [controlName]="'email'"
                    [control]="intervenantForm.get('email')"></app-feild-error>
                </div>
                <div class="fv-row mb-7">
                  <!--begin::Label-->
                  <label class=" fs-6 fw-semibold mb-2">
                    <span class="">Email professionnel</span>
                  </label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input type="email" formControlName="emailProESN" class="form-control form-control-solid"
                    placeholder="email" name="email" value="" />
                  <!--end::Input-->
                  <app-feild-error [controlName]="'email'" [control]="intervenantForm.get('emailProESN')"></app-feild-error>
                </div>

                <div class="fv-row mb-7">
                  <label [ngClass]="['create','update'].includes(mode)?'required':''"
                    class=" d-flex align-items-center form-label mb-2">
                    <span class="">Nom</span>
                  </label>
                  <!--end::Label-->
                  <input type="text" formControlName="first_name" class="form-control form-control-solid"
                    placeholder="Nom" name="Nom" />
                  <app-feild-error [control]="intervenantForm.get('first_name')"></app-feild-error>
                </div>
                <div class="fv-row mb-7">
                  <label [ngClass]="['create','update'].includes(mode)?'required':''"
                    class=" d-flex align-items-center form-label mb-2">
                    <span class="">Prénom</span>
                  </label>
                  <!--end::Label-->
                  <input type="text" formControlName="last_name" class="form-control form-control-solid"
                    placeholder="Prénom" name="Nom" />
                  <app-feild-error [control]="intervenantForm.get('last_name')"></app-feild-error>
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->

                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="fv-row mb-7">
                  <!--begin::Label-->
                  <label [ngClass]="['create','update'].includes(mode)?'required':''" class=" fs-6 fw-semibold mb-2">N°
                    tel</label>
                  <div class="wrapper" [formGroup]="phoneForm">
                    <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                      [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                            ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.France" [maxLength]="15"
                      [phoneValidation]="true" [separateDialCode]="separateDialCode"
                      [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone">
                    </ngx-intl-tel-input>
                  <!-- <app-feild-error [control]="phoneForm.get('phone')"></app-feild-error> -->
                    <div *ngIf="phoneForm.get('phone')?.hasError('required') && phoneForm.get('phone')?.touched && !phoneForm.get('phone')?.value" style="color: red; font-size: smaller;">
                      Le champ est obligatoire.
                    </div>
                    
                    <div *ngIf="phoneForm.controls['phone'].invalid && phoneForm.controls['phone'].touched && phoneForm.get('phone')?.value">
                      <span style="color: red; font-size: smaller;">Numéro de téléphone est invalide</span>
                    </div>
                    
                  </div>
                 
                  <!--end::Input-->
                </div>
                <div class="fv-row ">
                  <!--begin::Label-->
                  <label [ngClass]="['create','update'].includes(mode)?'required':''"
                    class=" fs-6 fw-semibold mb-2">RÔLE</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <ng-select formControlName="employee_role_id" [items]="listRole" bindLabel="name" bindValue="id"
                    [placeholder]="'Role'" autoComplete  [notFoundText]="'Aucun élément trouvé'">
                  </ng-select>
                  <app-feild-error [control]="intervenantForm.get('employee_role_id')"></app-feild-error>
                  <!--end::Input-->
                </div>

              </div>
              <!--end::Scroll-->
            </div>
            <!--end::Modal body-->
            <!--begin::Modal footer-->
            <div class="modal-footer" *ngIf="mode=='create'|| mode =='update'">
              <!--begin::Button-->
              <button type="reset" (click)="closeModal()" id="kt_modal_add_customer_cancel" data-bs-dismiss="modal"
                class="footer-btn annuler me-3">Annuler</button>
              <!--end::Button-->
              <!--begin::Button-->
              <button type="submit" [disabled]="!intervenantForm.valid || phoneForm.controls['phone'].invalid" (click)="onSubmit()"
                id="kt_modal_add_customer_submit" class="footer-btn confirmer  ">
                <span class="indicator-label">Confirmer</span>
                <span class="indicator-progress">Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </button>
              <!--end::Button-->
            </div>
            <!--end::Modal footer-->
          </form>
          <!--end::Form-->
        </div>
      </div>
    </div>
  </ng-template>

  <!--end::Modal - Customers - Add-->
  <!--begin::Modal - Adjust Balance-->

  <!--end::Modal - New Card-->
  <!--end::Modals-->
</div>
<!--end::Content container-->
