import { Component, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedEvaluation,
  confirmDelete,
  deletedEvaluation,
  existedEvaluation,
  failedDelete,
  serverError,
  updatedEvaluation,
} from '../../../../../../core/models/messages';
import { IQuestion } from '../../../../../../core/models/setting';
import { SettingsService } from '../../../../../../core/services/settings/settings.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list-eval-wegestu',
  templateUrl: './list-eval-wegestu.component.html',
  styleUrls: ['./list-eval-wegestu.component.css'],
})
export class ListEvalWegestuComponent {
  typesEvaluation = [
    { id: 1, name: 'Annuelle' },
    { id: 2, name: 'Suivi' },
  ];
  /* string */
  mode: string = 'create';
  /* numbers */
  idEvaluation!: number | undefined;
  /* modal */
  modalRef?: BsModalRef;
  /* array */
  listEvaluation: IQuestion[] = [];
  /* formGroup */
  evaluationForm: FormGroup = this.createEvaluationForm();
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private settingService: SettingsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private router: Router
  ) {}
  /* init */
  ngOnInit() {
    this.getListEvaluation();
  }
  openModal(template: TemplateRef<any>, mode: string, data?: any) {
    this.mode = mode;
    if (mode == 'update') {
      this.idEvaluation = data?.id;
      data.type = parseInt(data?.type);
      this.evaluationForm = this.createEvaluationForm(data);
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef?.hide();
    this.evaluationForm.reset();
  }
  getListEvaluation() {
    this.spinner.show();
    this.settingService
      .getListEvaluationDefault()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listEvaluation = res.data.data;
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* create add question form */
  createEvaluationForm(data?: IQuestion) {
    return this.formBuilder.group({
      name: [
        data ? data.name : '',
        [Validators.required, this.notOnlySpacesValidator()],
      ],
      type: [data ? data.type : null, [Validators.required]],
    });
  }
  notOnlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value as string;
      const isValid = value?.trim() !== '';
      return isValid ? null : { onlySpaces: true };
    };
  }
  /* confirm click either to add or to update question */
  submitData() {
    console.log('mode', this.mode);

    if (this.mode == 'create') {
      this.addQuestion();
    } else {
      this.updateQuestion();
    }
  }
  /* add question */
  addQuestion() {
    if (this.evaluationForm.valid) {
      this.spinner.show();
      this.settingService
        .addEvaluationDefault(this.evaluationForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.getListEvaluation();
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(addedEvaluation);
            }
          },
          error: (err) => {
            this.spinner.hide();
            if (err?.error) {
              if (err.error?.message && err.error?.message['name'])
                this.toastr.info(existedEvaluation);
              else this.toastr.error(serverError);
            }
          },
        });
    }
  }
  /* update question */
  updateQuestion() {
    if (this.evaluationForm.valid) {
      this.spinner.show();
      this.settingService
        .updateEvaluationDefault(this.idEvaluation, this.evaluationForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              console.log('res', res);
              this.getListEvaluation();
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(updatedEvaluation);
            }
          },
          error: (err) => {
            console.log('err', err);

            this.spinner.hide();
            if (err?.error) {
              if (
                err.error?.message &&
                err.error?.message['name'][0] ===
                  'The name has already been taken.'
              )
                this.toastr.info(existedEvaluation);
              if (err.error?.message && err.error?.message['question'])
                this.toastr.info(existedEvaluation);
              //  else this.toastr.error(serverError)
            }
          },
        });
    }
  }
  /* open delete alert */
  openDeleteAlert(evaluation: IQuestion) {
    Swal.fire({
      title: `${confirmDelete} l'evaluation sélectionnée?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteEvaluation(evaluation.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  /* delete question */
  deleteEvaluation(id: number) {
    this.spinner.show();
    this.settingService
      .deleteEvaluationDefault(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListEvaluation();
            this.spinner.hide();
            this.toastr.success(deletedEvaluation);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  navigateToListQuestions(idEvaluation: number) {
    this.router.navigate([
      '/acceuil/parametres/evaluation-default',
      { idEvaluation: idEvaluation },
    ]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
