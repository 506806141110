import { Component, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedFaq,
  confirmDelete,
  deletedFaq,
  existedFAQ,
  failedDelete,
  serverError,
  updatedFaq,
} from '../../../../../core/models/messages';
import { patternMax255 } from '../../../../../core/models/pattern';
import { IFaq } from '../../../../../core/models/setting';
import { SettingsService } from '../../../../../core/services/settings/settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-faq-esn',
  templateUrl: './faq-esn.component.html',
  styleUrls: ['./faq-esn.component.css'],
})
export class FaqComponent {
  typesCompanies = [
    { id: '1', name: 'Candidat' },
    { id: '2', name: 'ESN' },
    { id: '3', name: 'Client final' },
  ];
  offset: number = 0;
  /* string */
  mode: string = 'add';
  /* numbers */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  search: boolean = false;
  idFaq!: number | undefined;
  pagination : boolean =false;
  isLoadSpinner: boolean = true;

  /* array */
  typeFaq = [
    { id: '1', name: 'Candidat' },
    { id: '2', name: 'ESN' },
    { id: '3', name: 'Client final' },
  ];
  faqList: IFaq[] = [];
  /* formGroup */
  faqForm: FormGroup = this.createFaqForm();
  searchFormGroup: FormGroup = this.dataSearch();
  /* modal */
  modalRef?: BsModalRef;
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  ispagination: boolean = true;
  constructor(
    private settingService: SettingsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService
  ) {}

  /* init */
  ngOnInit() {
    this.getListFaq();
  }
  openModal(template: TemplateRef<any>, mode: string, data?: IFaq) {
    this.mode = mode;
    if (mode == 'update') {
      console.log('data', data);

      this.idFaq = data?.id;
      this.faqForm = this.createFaqForm(data);
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef?.hide();
    this.faqForm.reset();
  }
  /* get indexes for pagination */

  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex
      this.endIndex = event.endIndex
      this.itemsPerPage = event.itemsPerPage,
        this.currentPage = event.currentPage
      console.log('%ctuto.component.ts line:79 getItems++++', 'color: #007acc;', this.startIndex, this.endIndex, this.itemsPerPage, this.currentPage);
      if (this.searchFormGroup.dirty) {
        this.searchFAQ()

      } else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true 
        this.getListFaq()
        
      }
    }
  }
  sortData() {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.getListFaq();
  }
  getListFaq() {
    console.log('list faq');
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      sort: this.currentSortDirection,
    };
    this.spinner.show();
    this.settingService
      .getListFaq(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.isLoadSpinner = false;
            this.spinner.hide();
            if (res.data.items.data.length) {
              this.faqList = res.data.items.data.slice(0, this.endIndex);
              this.totalItems = res.data.items.total;
              this.offset = (this.currentPage - 1) * this.itemsPerPage;
              // if (this.currentPage > 1 && !res.data.items.data.length) {
              //   this.currentPage = 1
              //   this.getListFaq()
              // }
            }
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* create add faq form */
  createFaqForm(data?: IFaq) {
    return this.formBuilder.group({
      question_FR: [
        data ? data.question_FR : '',
        [
          Validators.required,
          this.notOnlySpacesValidator(),
          Validators.pattern(patternMax255),
          this.validateMax255,
        ],
      ],
      type: [data ? data.type : [], [Validators.required]],
      response: [
        data ? data.response_f_a_q.response : '',
        [
          Validators.required,
          this.notOnlySpacesValidator(),
          Validators.pattern(patternMax255),
          this.validateMax255,
        ],
      ],
    });
  }
  notOnlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value as string;
      const isValid = value?.trim() !== '';
      return isValid ? null : { onlySpaces: true };
    };
  }
  validateMax255(control: any) {
    // Validation function to check if input length exceeds 255 characters
    if (control.value && control.value.length > 255) {
      return { maxLengthExceeded: true };
    }
    return null;
  }
  /* either to add or to update faq */
  confirmSend() {
    if (this.mode == 'add') {
      this.addFaq();
    } else {
      this.updateFaq();
    }
  }
  /* add faq */
  addFaq() {
    if (this.faqForm.valid) {
      this.spinner.show();
      this.settingService
        .addFaq(this.faqForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.getListFaq();
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(addedFaq);
            }
          },
          error: (err) => {
            console.log('error', err);
            this.spinner.hide();
            if (err.error?.message?.question_FR) this.toastr.info(existedFAQ);
            else this.toastr.error(serverError);
          },
        });
    }
  }
  /* update faq */
  updateFaq() {
    this.spinner.show();
    this.settingService
      .updateFaq(this.idFaq, this.faqForm.value)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListFaq();
            this.spinner.hide();
            this.closeModal();
            this.toastr.success(updatedFaq);
          }
        },
        error: (err) => {
          this.spinner.hide();
          if (err.error?.message?.question_FR) this.toastr.info(existedFAQ);
          else this.toastr.error(serverError);
        },
      });
  }
  /* open delete alert */
  openDeleteAlert(faq: IFaq) {
    Swal.fire({
      title: `${confirmDelete} ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteFqa(faq.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  deleteFqa(id: number) {
    this.spinner.show();
    this.settingService
      .deleteFaq(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListFaq();
            this.spinner.hide();
            this.toastr.success(deletedFaq);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  dataSearch(data?: any) {
    return this.formBuilder.group({
      type: [data ? data?.type : []],
      question: [data ? data?.question : ''],
    });
  }

  searchFAQ() {
    let data = {
      type: this.searchFormGroup.value.type,
      question: this.searchFormGroup.value.question,
      page: this.currentPage,
      per_page: this.itemsPerPage,
      // search:this.searchFormGroup.value.search
    }
    this.spinner.show()
    this.settingService.searchListFAQ(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.isRecherche = true;
        console.log("is pagination",this.ispagination)
        console.log("current page",this.currentPage)
        // !res.data.items.data.length &&
        if ( this.currentPage > 1  && !this.ispagination) {
          this.currentPage = 1
           this.offset = (this.currentPage - 1) * this.itemsPerPage;
          this.searchFAQ()
        } else {
          console.log('response format:', res);
      
          this.faqList = res.data.items.data.slice(0, this.endIndex)
          this.totalItems = res.data.items.total
          this.offset = (this.currentPage - 1) * this.itemsPerPage;
          console.log('total:', this.totalItems);
          this.ispagination=true
          this.spinner.hide()
        }
      },
      error: (error) => {
        console.error('Error during FAQ search',error);
        this.spinner.hide()
   
      }
    })
  }
  transformSearch(search: string): string {
    // Remplace toutes les occurrences de '||' par 'OR'
    search = search.replace(/\|\|/g, ' OR ');

    // Remplace toutes les occurrences de '&&' par ' AND '
    search = search.replace(/&&/g, ' AND ');

    // Remplace toutes les occurrences de '!' par ' NOT '
    search = search.replace(/!/g, ' NOT ');

    search = search.replace(/\b(AND|OR|NOT)\b/g, ' $1 ');

    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        return `"${p1}"`;
      } else {
        // Si le terme n'est pas entre guillemets, échappe les guillemets simples à l'intérieur du terme
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `${escapedTerm}`;
      }
    });

    search = search.replace(/\s+/g, ' ');

    return search.trim();
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.searchFormGroup = this.dataSearch();
    if (!this.isRecherche) {
      this.spinner.hide();
      console.log('gggg');
      console.log('gggg', this.isRecherche);
    } else {
      this.spinner.hide();
      console.log('hhh');
      this.getListFaq();
      console.log('hhhh', this.isRecherche);
    }
    return (this.isRecherche = false);
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
