import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Country, IState, State } from 'country-state-city';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { serverError } from 'src/app/core/models/messages';
import { CandidatService } from 'src/app/core/services/candidat/candidat.service';
import { SortDataService } from 'src/app/core/services/sort-data/sort-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-candidature-job-request',
  templateUrl: './candidature-job-request.component.html',
  styleUrls: ['./candidature-job-request.component.css']
})
export class CandidatureJobRequestComponent {
  compt = [
    { id: 1, name: 'ASAP' },
    { id: 2, name: 'Sous 1 mois' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
  ];

  contrat = [
    { id: 1, name: 'Alternance' },
    { id: 2, name: 'Autres type de contrat' },
    { id: 3, name: 'CDD' },
    { id: 4, name: 'CDI' },
    { id: 5, name: 'Freelance' },
    { id: 6, name: 'Portage salarial' },
    { id: 7, name: 'Stage' },
  ];

  poste = [
    { id: 1, name: 'A distance' },
    { id: 2, name: 'Hybrid' },
    { id: 3, name: 'Sur site' },
  ];
  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 2, name: '1 mois' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];
  mobilities: any[] = [];
  countries: any[] = [];
  isCollapsed = true;
  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }
  modalRef?: BsModalRef;
  stateList: any[] = [];
  loadSpinnerTable: boolean = false;
  loadSpinner: boolean = false;
  idclientConnected: any;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  currentSortDirection: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  totalItems!: number;
  listcandidats :any=[];
  urlimage: string = environment.baseUrlGaleryy;
  urlcv: string = environment.baseUrlGaleryCv;
  url: string = environment.baseUrl + '/api';
  headerChecked: boolean = false;
  pagination : boolean =false;

  selectedCandidats: any = [];
  backGroundColors: string[] = [
    'blue',
    'orange',
    'bleu-clair',
    'pink',
    'rose',
    'jaune',
    'rouge',
  ];
  sortAttr = '';
  searchForm: FormGroup = this.createSearchForm();
  skillsList: { name_FR: string }[] = [];
  paysList: any[] = Country.getAllCountries().map((country) => ({
    ...country,
    name: this.translateCountryName(country.name),
  }));
  currentSortColumn: string = 'name'; // Initial column to sort by
  idoffre = this.activatedRoute.snapshot.params['id'];
  id: any;
  image_url: any;
  profile = [
    { id: 1, name: 'Oui' },
    { id: 0, name: 'Non' },
  ];
  passeport = [
    { id: 1, name: 'Oui' },
    { id: 0, name: 'Non' },
  ];
  state = [
    { id: 0, name: 'Actif' },
    { id: 1, name: 'Non Actif' },
  ];
  applyForm: FormGroup = this.createapplyForm();
  constructor(
    private formBuilder: FormBuilder,
    private candidatservice: CandidatService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private sortDataService: SortDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService
  ) {}
  ngOnInit() {
    console.log(localStorage.getItem('clientConnected'));
    console.log('bonjour wegestu esn');
    this.idclientConnected = localStorage.getItem('clientConnected');
    this.getListcandidature();
    this.getListSkills();
  }
  getListSkills() {
    this.candidatservice.getSkills().subscribe({
      next: (res) => {
        this.skillsList = res.data;
      },
      error: () => {
        this.toastrService.error(serverError);
      },
    });
  }
  sortData(item: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr = item;
    this.currentPage = 1;
    this.endIndex=5;
    // let sort={sort:this.currentSortDirection,sortAttribute:item}
    // console.log("sort",sort)
    if (this.searchForm.value != '')
      this.listcandidats = this.sortDataService.sortArray(
        this.listcandidats,
        this.sortAttr,
        this.currentSortDirection
      );
    else this.getListcandidature();
  }
  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      Afghanistan: 'Afghanistan',
      'South Africa': 'Afrique du Sud',
      Albania: 'Albanie',
      Algeria: 'Algérie',
      Germany: 'Allemagne',
      Andorra: 'Andorre',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctique',
      'Antigua and Barbuda': 'Antigua-et-Barbuda',
      'Saudi Arabia': 'Arabie saoudite',
      Argentina: 'Argentine',
      Armenia: 'Arménie',
      Aruba: 'Aruba',
      Australia: 'Australie',
      Austria: 'Autriche',
      Azerbaijan: 'Azerbaïdjan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahreïn',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbade',
      Belgium: 'Belgique',
      Belize: 'Belize',
      Benin: 'Bénin',
      Bermuda: 'Bermudes',
      Bhutan: 'Bhoutan',
      Belarus: 'Biélorussie',
      Bolivia: 'Bolivie',
      'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
      Botswana: 'Botswana',
      Brazil: 'Brésil',
      Brunei: 'Brunei',
      Bulgaria: 'Bulgarie',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      'Cape Verde': 'Cabo Verde',
      Cambodia: 'Cambodge',
      Cameroon: 'Cameroun',
      Canada: 'Canada',
      Chile: 'Chili',
      China: 'Chine',
      Cyprus: 'Chypre',
      Colombia: 'Colombie',
      Comoros: 'Comores',
      'Congo - Brazzaville': 'Congo-Brazzaville',
      'Congo - Kinshasa': 'Congo-Kinshasa',
      'North Korea': 'Corée du Nord',
      'South Korea': 'Corée du Sud',
      'Costa Rica': 'Costa Rica',
      'Ivory Coast': 'Côte d’Ivoire',
      Croatia: 'Croatie',
      Cuba: 'Cuba',
      Curaçao: 'Curaçao',
      Denmark: 'Danemark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominique',
      'Dominican Republic': 'République dominicaine',
      Ecuador: 'Équateur',
      Egypt: 'Égypte',
      'United Arab Emirates': 'Émirats arabes unis',
      Eritrea: 'Érythrée',
      Spain: 'Espagne',
      Eswatini: 'Eswatini',
      Estonia: 'Estonie',
      'United States': 'États-Unis',
      Ethiopia: 'Éthiopie',
      Fiji: 'Fidji',
      Finland: 'Finlande',
      France: 'France',
      Gabon: 'Gabon',
      Gambia: 'Gambie',
      Georgia: 'Géorgie',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Grèce',
      Grenada: 'Grenade',
      Greenland: 'Groenland',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernesey',
      Guinea: 'Guinée',
      'Guinea-Bissau': 'Guinée-Bissau',
      'Equatorial Guinea': 'Guinée équatoriale',
      Guyana: 'Guyana',
      'French Guiana': 'Guyane française',
      Haiti: 'Haïti',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Hongrie',
      Iceland: 'Islande',
      India: 'Inde',
      Indonesia: 'Indonésie',
      Iraq: 'Irak',
      Iran: 'Iran',
      Ireland: 'Irlande',
      'Isle of Man': 'Île de Man',
      Israel: 'Israël',
      Italy: 'Italie',
      Jamaica: 'Jamaïque',
      Japan: 'Japon',
      Jersey: 'Jersey',
      Jordan: 'Jordanie',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      Kuwait: 'Koweït',
      Kyrgyzstan: 'Kirghizistan',
      Laos: 'Laos',
      Latvia: 'Lettonie',
      Lebanon: 'Liban',
      Lesotho: 'Lesotho',
      Liberia: 'Libéria',
      Libya: 'Libye',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lituanie',
      Luxembourg: 'Luxembourg',
      Macao: 'Macao',
      'North Macedonia': 'Macédoine du Nord',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaisie',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malte',
      'Marshall Islands': 'Îles Marshall',
      Martinique: 'Martinique',
      Mauritania: 'Mauritanie',
      Mauritius: 'Maurice',
      Mayotte: 'Mayotte',
      Mexico: 'Mexique',
      Micronesia: 'Micronésie',
      Moldova: 'Moldavie',
      Monaco: 'Monaco',
      Mongolia: 'Mongolie',
      Montenegro: 'Monténégro',
      Montserrat: 'Montserrat',
      Morocco: 'Maroc',
      Mozambique: 'Mozambique',
      Myanmar: 'Myanmar',
      Namibia: 'Namibie',
      Nauru: 'Nauru',
      Nepal: 'Népal',
      Netherlands: 'Pays-Bas',
      'New Caledonia': 'Nouvelle-Calédonie',
      'New Zealand': 'Nouvelle-Zélande',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Île Norfolk',
      'Northern Mariana Islands': 'Îles Mariannes du Nord',
      Norway: 'Norvège',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palaos',
      Palestine: 'Palestine',
      Panama: 'Panama',
      'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
      Paraguay: 'Paraguay',
      Peru: 'Pérou',
      Philippines: 'Philippines',
      'Pitcairn Islands': 'Îles Pitcairn',
      Poland: 'Pologne',
      Portugal: 'Portugal',
      'Puerto Rico': 'Porto Rico',
      Qatar: 'Qatar',
      Romania: 'Roumanie',
      Russia: 'Russie',
      Rwanda: 'Rwanda',
      Réunion: 'Réunion',
      'Saint Barthélemy': 'Saint-Barthélemy',
      'Saint Helena': 'Sainte-Hélène',
      'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
      'Saint Lucia': 'Sainte-Lucie',
      'Saint Martin': 'Saint-Martin',
      'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
      'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
      Samoa: 'Samoa',
      'San Marino': 'Saint-Marin',
      'Sao Tome and Principe': 'Sao Tomé-et-Principe',
      Senegal: 'Sénégal',
      Serbia: 'Serbie',
      Seychelles: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapour',
      'Sint Maarten': 'Saint-Martin',
      Slovakia: 'Slovaquie',
      Slovenia: 'Slovénie',
      'Solomon Islands': 'Îles Salomon',
      Somalia: 'Somalie',
      'South Georgia and the South Sandwich Islands':
        'Géorgie du Sud-et-les Îles Sandwich du Sud',
      'South Sudan': 'Soudan du Sud',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Soudan',
      Suriname: 'Suriname',
      'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
      Swaziland: 'Eswatini',
      Sweden: 'Suède',
      Switzerland: 'Suisse',
      Syria: 'Syrie',
      Taiwan: 'Taïwan',
      Tajikistan: 'Tadjikistan',
      Tanzania: 'Tanzanie',
      Thailand: 'Thaïlande',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinité-et-Tobago',
      Tunisia: 'Tunisie',
      Turkey: 'Turquie',
      Turkmenistan: 'Turkménistan',
      Tuvalu: 'Tuvalu',
      Uganda: 'Ouganda',
      Ukraine: 'Ukraine',
      'United Kingdom': 'Royaume-Uni',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Ouzbékistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatican',
      Venezuela: 'Venezuela',
      Vietnam: 'Viêt Nam',
      'Western Sahara': 'Sahara occidental',
      Yemen: 'Yémen',
      Zambia: 'Zambie',
      Zimbabwe: 'Zimbabwe',
    };
    return translations[name] || name;
  }
  getItems(event?: any){  
    if(event && event.startIndex!=this.startIndex||this.endIndex!=event.endIndex||
      this.itemsPerPage!=event.itemsPerPage||
      this.currentPage!=event.currentPage){
    this.startIndex=event.startIndex
    this.endIndex=event.endIndex
    this.itemsPerPage=event.itemsPerPage,
    this.currentPage=event.currentPage
    if(this.searchForm.dirty) this.searchCandidature() 
    else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
      this.pagination = true
      this.getListcandidature() 
    }
  }
  }
  goToDetails(){
    this.router.navigate(['/acceuil/appel-offre/details-appel-offre', { id: this.idoffre }])
  }

  getListcandidature() {
    let dataPyload =this.sortAttr==''? { id: this.idoffre,per_page: this.itemsPerPage, page: this.currentPage}:{ ...{ id: this.idoffre,per_page: this.itemsPerPage, page: this.currentPage}, ...{sort:this.currentSortDirection,sortAttribute:this.sortAttr} }
    this.spinner.show();
    this.loadSpinnerTable = true;
    //  let dataPyload =this.sortAttr==''? { job_offer_id: this.idoffre,per_page: this.itemsPerPage, page: this.currentPage}:{ ...{ job_offer_id: this.idclientConnected,per_page: this.itemsPerPage, page: this.currentPage}, ...{sort:this.currentSortDirection,sortAttribute:this.sortAttr} }
    this.candidatservice.getListcandidatureinterne(dataPyload).subscribe({
      next: (response:any) => {
        console.log('response', response);
        if (response.data && response.data.data) {
          console.log('response.data', response.data);
          this.listcandidats = response.data.data.slice(0, this.endIndex);
          console.log(' this.listcandidats', this.listcandidats);
          this.image_url = this.listcandidats[0]?.image_url;

          console.log(' this.image_url', this.image_url);
          this.totalItems = response.data.total;
          if(this.currentPage>1 && !response.data.data.length){
            this.currentPage=1
            this.getListcandidature()
          }
          this.spinner.hide();
          this.loadSpinnerTable = false;
          console.log('list candidatures', this.listcandidats);
        }
      },
      error: (error:any) => {
        console.error('Error fetching salaries: ', error);
      },
    });
  }
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      search: [
        '',
        [Validators.pattern(/^("([^"]*"(AND|NOT|,|&&)[^"]*)*"|[^\s"']+)$/)],
      ],
      name: ['', data?.name],
      years_of_experience: ['', data?.years_of_experience],
      desired_contract: [[], data?.desired_contract],
      desired_salary: ['', data?.desired_salary],
      desired_salary_range: ['', data?.desired_salary_range],
      tjm: ['', data?.tjm],
      tjm_range: ['', data?.tjm_range],
      post_type: [[], data?.post_type],
      skillsOr: [[], data?.skillsOr],
      skillsAnd: [[], data?.skillsAnd],
      current_country: [null, data?.current_country],
      mobility: [[], data?.mobility],
      availability: [[], data?.availability],
      date_start_candidature: ['', data?.date_start_candidature],
      date_end_candidature: ['', data?.date_end_candidature],
    });
  }
  /* search */
  searchCandidature() {
    let valueForm = this.searchForm.value;
    // let search:string[]=[]
    // search=[valueForm.ID_user,valueForm.availability,valueForm.years_of_experience]
    let data = {
      // sort:this.currentSortDirection,
      // search:search,
      name: valueForm.name,
      search: this.transformSearch(valueForm?.search),
      skillsOr: valueForm.skillsOr,
      skillsAnd: valueForm.skillsAnd,
      years_of_experience: this.searchForm.value.years_of_experience,
      // experience_range:this.searchForm.value.experience_range,
      desired_contract: this.searchForm.value.desired_contract,
      desired_salary: this.searchForm.value.desired_salary,
      desired_salary_range: this.searchForm.value.desired_salary_range,
      availability: valueForm.availability,
      tjm: valueForm.tjm,
      tjm_range: valueForm.tjm_range,
      post_type: valueForm.post_type,
      current_country: valueForm.current_country
        ? valueForm.current_country
        : '',
      mobility: valueForm.city ? valueForm.mobility : '',
      date_start_candidature: valueForm.date_start_candidature,
      date_end_candidature: valueForm.date_end_candidature,
      per_page: this.itemsPerPage,
      page: this.currentPage,
    };
    console.log('data', data);
    this.candidatservice
      .searchCandidatureinterne(this.idoffre, data)
      .subscribe({
        next: (resSearch:any) => {
          if (!resSearch.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.searchCandidature();
            console.log('response search', resSearch);
          } else {
            this.listcandidats = resSearch.data.data.slice(0, this.endIndex);
            this.totalItems = resSearch.data.total;
          }
        },
        error: (error:any) => {
          console.log(error);
          this.toastrService.error(serverError);
        },
      });
  }
  transformSearch(search: string): string {
    // Remplace toutes les occurrences de '||' par 'OR'
    search = search.replace(/\|\|/g, ' OR ');

    // Remplace toutes les occurrences de '&&' par ' AND '
    search = search.replace(/&&/g, ' AND ');

    // Remplace toutes les occurrences de '!' par ' NOT '
    search = search.replace(/!/g, ' NOT ');

    // Ajoute des espaces autour des opérations logiques pour éviter des conflits avec les mots
    search = search.replace(/\b(AND|OR|NOT)\b/g, ' $1 ');

    // Remplace les termes entre guillemets doubles par les mêmes termes sans modification
    // et échappe les guillemets simples à l'intérieur des termes non compris entre guillemets
    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        return `"${p1}"`;
      } else {
        // Si le terme n'est pas entre guillemets, échappe les guillemets simples à l'intérieur du terme
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `${escapedTerm}`;
      }
    });

    // Supprime les espaces en double pour une meilleure lisibilité
    search = search.replace(/\s+/g, ' ');

    // Renvoie la chaîne de recherche transformée
    return search.trim();
  }

  isSimpleWord(value: string): boolean {
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }

  /* change country select and delete the string Governorate from state name*/
  onCountryChange(mode: string): void {
    
    this.searchForm.get('mobility')?.enable();
    this.countries = this.searchForm.get('current_country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.searchForm.get('mobility')?.value;

    this.countries.forEach((item) => {
      var after:any[]=State.getStatesOfCountry(item?.isoCode)
      if(after.length==0)
      after.push({name:"Tout le pays"})
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2:any[]=[];
      differentItemsArray2= this.mobilities.filter((item) => !differentItemsArray1.includes(item));
      this.searchForm.get('mobility')?.setValue(differentItemsArray2);
    }
   
    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if(this.countries.length==0) {
        this.searchForm.get('mobility')?.disable();
        this.searchForm.get('mobility')?.setValue(null);
        }
    
    }
  }
  reset() {
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    this.getListcandidature();
  }
/* change country select and delete the string Governorate from state name*/
onCountryChangeSearch(mode: string): void {
    
  this.searchForm.get('mobility')?.enable();
  this.countries = this.searchForm.get('current_country')?.value;
  this.countries = this.countries.map(
    (item) => this.paysList.filter((it) => it.name === item)[0]
  );
  this.stateList.length = 0;
  this.mobilities = this.searchForm.get('mobility')?.value;

  this.countries.forEach((item) => {
    var after:any[]=State.getStatesOfCountry(item?.isoCode)
    if(after.length==0)
    after.push({name:"Tout le pays"})
    this.stateList.push(after);
  });
  this.stateList = this.stateList.flat();
  for (const iterator of this.stateList) {
    iterator.name = iterator.name.replace(' Governorate', '');
  }
  this.stateList = this.stateList.map((item: any) => item.name);
  if (this.mobilities != null && this.mobilities.length > 0) {
    let differentItemsArray1: any[] = [];
    differentItemsArray1 = this.mobilities.filter(
      (item) => !this.stateList.includes(item)
    );
    var differentItemsArray2:any[]=[];
    differentItemsArray2= this.mobilities.filter((item) => !differentItemsArray1.includes(item));
    this.searchForm.get('mobility')?.setValue(differentItemsArray2);
  }
 
  if (this.stateList.length == 0) {
    this.stateList = [];
    mode == 'add';
    if(this.countries.length==0) {
      this.searchForm.get('mobility')?.disable();
      this.searchForm.get('mobility')?.setValue(null);
      }
  
  }
}
onMobilityChange(mode: string) {
  this.mobilities = this.searchForm.get('mobility')?.value;
  let paysLists: any[] = [];
  this.countries = this.searchForm.get('current_country')?.value;
  this.countries = this.countries.filter(async (item: any) => {
    paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
  });
  let stateListAfter: any[] = [];
  paysLists.forEach((item) => {
    State.getStatesOfCountry(item.isoCode).forEach(item=> {
      stateListAfter.push(item.name);    ////  }
    })
  });
  console.log('stateList in change mobilities mobilities : ', this.mobilities);
  console.log('stateList in change mobilities stateListAfter  : ', stateListAfter);
  this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
  console.log('stateList in change mobilities : ', this.stateList);
  this.mobilities = this.searchForm.get('mobility')?.value;

  if (this.countries.length != 0) {
    if (this.mobilities.length === 0 && this.stateList.length === 0) {
      this.onCountryChange('add');
    }
  }
}


createapplyForm(data?: any) {
  return this.formBuilder.group({
    commercial_argument: [data ? data.commercial_argument : ''],
    tjm_vente: [data ? data.tjm_vente : ''],
    availibility_date: [data ? data.availibility_date : ''],
  });
}
openModal(template: TemplateRef<any>, data?: any, mode?: string) {
  this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  console.log('Identifianddddt:', data);
  this.id = data?.id;

  if (data) {
    this.id = data?.id;
    this.applyForm = this.createapplyForm(data);
    this.applyForm
      .get('commercial_argument')
      ?.setValue(data.commercial_argument);
    this.applyForm.get('availibility_date')?.setValue(data.availibility_date);
    this.applyForm.get('tjm_vente')?.setValue(data.tjm_vente);
    console.log('Identifiant:', this.id);
  }
}
closeModal() {
  this.modalRef!.hide();
  this.applyForm.reset();
}
}