import { Injectable } from '@angular/core';
import { ApiResponse } from '../models/response-api';
import { CrudService } from './crud/crud.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PermissionService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get list role */
  getListRole() {
    return this._http.get<ApiResponse<any>>(this._base + `/Role/getAll`);
  }
  /* get list pages */
  getListPages() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Role/getRolesForPages`
    );
  }
  /* get list role */
  getListRoleByCompany(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/CompanyRoles/getRolesByCompany?company_id=${id}`
    );
  }
  /* get interveanant by email */
  getintervenant(data: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Employee/getConsultantByMailForAddEmployee?esn_id=${data.id}&email=${data.email}`
    );
  }

  /* get list permissions */
  getListPermissions(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Permission/getAll`
    );
  }
   /* get list permissions by offer */
   getListPermissionsByOffer(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Permission/getPermissionsByOffer?offer_id=${id}`
    );
  }
  getListPermissionsRole() {
    return this._http.get<ApiResponse<any>>(this._base + `/Permission/getAll`);
  }
  /* get list permissiions by role */
  getListPermissionsByRole(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Permission/getByRole?role_id=${id}`
    );
  }
  /* give permission  */
  assignPermission(data: { role_id: number; permission_ids: number[] }) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Permission/AssignPermissionToRole`,
      data
    );
  }
  /* retry permission  */
  retryPermission(data: { role_id: number; permission_ids: number[] }) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Permission/RemovePermissionToRole`,
      data
    );
  }
  /* add new role */
  addRole(data: any) {
    return this._http.post<ApiResponse<any>>(this._base + `/Role/add`, data);
  }
  /* delete new role */
  deleteRole(id: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/Role/delete/${id}`
    );
  }
  /* search role */
  searchRole(data: any) {
    return this._http.post<ApiResponse<any>>(this._base + `/Role/search`, data);
  }
  /* search permission */
  searchPermission(id: number, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Permission/search?role_id=${id}`,
      data
    );
  }
  /* search permission */
  getPermissionByRole(id: number | undefined) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Offer/getPermissionsAssignedToAbonnement?offer_id=${id}`
    );
  }
}
