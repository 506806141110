<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_content" class="app-content flex-column-fluid">
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
        <!--begin::Toolbar container-->
        <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <!--begin::Title-->
             <div class="d-flex align-items-center">
                <h1 class="page-heading"><svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_138_53)">
                    <path d="M8.93572 6.21891L4.29275 3.10518C4.23449 3.06604 4.16094 3.06357 4.10021 3.09527C4.03752 3.12846 4 3.19236 4 3.2627V8.92349C4 8.9864 4.03209 9.04485 4.08343 9.07854L8.7274 12.1943C8.758 12.2146 8.79601 12.226 8.83156 12.226C8.86217 12.226 8.89277 12.219 8.92042 12.2037C8.98164 12.17 9.02063 12.1061 9.02063 12.0367V6.37643C9.01915 6.31352 8.98855 6.25408 8.93572 6.21891Z" fill="#265D91"/>
                    <path d="M14.5163 16.9783L9.87331 13.8646C9.81506 13.8259 9.7415 13.8215 9.68078 13.8552C9.61957 13.8869 9.58057 13.9503 9.58057 14.0206V19.6829C9.58057 19.7458 9.61265 19.8042 9.664 19.8389L14.308 22.9541C14.3386 22.9744 14.3766 22.9853 14.4121 22.9853C14.4427 22.9853 14.4733 22.9769 14.501 22.9631C14.5622 22.9299 14.5997 22.866 14.5997 22.7961V17.1358C14.5997 17.0734 14.5691 17.013 14.5163 16.9783Z" fill="#265D91"/>
                    <path d="M25.6627 3.50144C25.6029 3.46825 25.5308 3.46973 25.4726 3.50441L20.8281 6.3358C20.7728 6.36998 20.7383 6.4314 20.7383 6.49679V12.4424C20.7383 12.5118 20.7768 12.5757 20.838 12.6069C20.8656 12.6222 20.8963 12.6301 20.9269 12.6301C20.9629 12.6301 21.0004 12.6197 21.0305 12.5989L25.675 9.48372C25.7263 9.44904 25.7584 9.39059 25.7584 9.32669V3.66589C25.7584 3.59704 25.7219 3.53463 25.6627 3.50144Z" fill="#265D91"/>
                    <path d="M14.1867 2.78963C14.1882 2.72177 14.1531 2.65737 14.0939 2.6227L9.73624 0.0265969C9.68243 -0.00708651 9.6143 -0.00807717 9.55704 0.0196621L4.69684 2.4414C4.63563 2.47261 4.59564 2.53403 4.59268 2.60189C4.58823 2.66827 4.6218 2.73415 4.67858 2.76982L9.0219 5.54275C9.0525 5.56207 9.08706 5.57248 9.1231 5.57248C9.15222 5.57248 9.18283 5.56504 9.21048 5.55018L14.0865 2.95309C14.1462 2.9199 14.1852 2.857 14.1867 2.78963Z" fill="#265D91"/>
                    <path d="M25.2596 2.99965C25.2581 2.93129 25.2211 2.86789 25.1599 2.83668L20.5707 0.379774C20.5154 0.350053 20.4488 0.350054 20.3935 0.38126L15.8487 2.85105C15.789 2.88374 15.7505 2.94665 15.7505 3.01699C15.7505 3.08485 15.7875 3.14925 15.8472 3.18243L20.4616 5.76566C20.4907 5.78101 20.5223 5.78943 20.5529 5.78943C20.586 5.78943 20.6181 5.78101 20.6472 5.76417L25.1653 3.16708C25.2255 3.13191 25.2611 3.069 25.2596 2.99965Z" fill="#265D91"/>
                    <path d="M20.0772 13.8551C20.0164 13.8215 19.9429 13.8254 19.8841 13.8645L15.2417 16.9783C15.1888 17.0135 15.1567 17.0734 15.1567 17.1358V22.7966C15.1567 22.8664 15.1957 22.9303 15.2565 22.9635C15.2846 22.9774 15.3147 22.9863 15.3453 22.9863C15.3819 22.9863 15.4189 22.9744 15.4495 22.9546L20.0939 19.8394C20.1453 19.8047 20.1774 19.7463 20.1774 19.6834V14.0216C20.1774 13.9512 20.1384 13.8873 20.0772 13.8551Z" fill="#265D91"/>
                    <path d="M19.7028 13.3603C19.7042 13.2924 19.6692 13.228 19.6099 13.1934L15.0845 10.2718C15.0306 10.2391 14.9625 10.2376 14.9057 10.2654L9.99273 13.1364C9.93201 13.1676 9.89153 13.229 9.88906 13.2969C9.88462 13.3638 9.91819 13.4292 9.97496 13.4653L14.7172 16.5815C14.7478 16.6008 14.7818 16.6108 14.8184 16.6108C14.8475 16.6108 14.8781 16.6038 14.9057 16.5885L19.6025 13.5233C19.6623 13.4911 19.7013 13.4282 19.7028 13.3603Z" fill="#265D91"/>
                    <path d="M14.6466 3.50144C14.5869 3.46825 14.5148 3.46973 14.4566 3.50441L9.66993 6.08862C9.61464 6.1228 9.58008 6.18373 9.58008 6.24961V12.1952C9.58008 12.2646 9.61859 12.3285 9.6798 12.3602C9.70745 12.375 9.73805 12.383 9.76866 12.383C9.8047 12.383 9.84173 12.3726 9.87233 12.3518L14.7538 9.68631C14.8051 9.65164 14.8372 9.59319 14.8372 9.52879L14.7429 3.66589C14.7429 3.59704 14.7069 3.53463 14.6466 3.50144Z" fill="#265D91"/>
                    <path d="M20.1305 6.18869L15.4885 3.46528C15.4303 3.42664 15.3572 3.42169 15.296 3.45537C15.2348 3.48757 15.1963 3.55098 15.1963 3.62082L15.2481 9.49263C15.2481 9.55554 15.2802 9.6135 15.3311 9.64817L19.98 12.4949C20.0106 12.5152 20.0486 12.5266 20.0841 12.5266C20.1147 12.5266 20.1453 12.5177 20.173 12.5043C20.2342 12.4706 20.2717 12.4072 20.2717 12.3369V6.46757C20.2717 6.40466 20.1834 6.22287 20.1305 6.18869Z" fill="#265D91"/>
                    </g>
                    <defs>
                    <filter id="filter0_d_138_53" x="0" y="0" width="29.7583" height="30.9863" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_138_53"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_138_53" result="shape"/>
                    </filter>
                    </defs>
                    </svg>

                    Modules
                </h1>

             </div>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted">
                        <a  class="text-muted"  routerLink="/">Accueil</a>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-400 w-5px h-2px"></span>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted"><a class="text-muted" routerLink="/acceuil/modules/list-category"> Modules</a></li>
                    <!--end::Item-->
                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-400 w-5px h-2px"></span>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted" >Détails module</li>
                    <!--end::Item-->
                </ul>
                <!--end::Breadcrumb-->
            </div>
            <!--end::Page title-->
            <!--begin::Actions-->

            <!--end::Actions-->
        </div>
        <!--end::Toolbar container-->
    </div>
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
          <div class="d-flex align-items-baseline">
             <div class="title">
                <h4>{{category&&category.name_FR}}</h4>
            </div>
            <div class="edit" *ngIf="category.name_FR">
              <button (click)="edit();openModal(myModal)" >
<svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                <g filter="url(#filter0_d_129_833)">
                  <path d="M4 16.2016V6.13715C4 5.54398 4.23707 4.9751 4.65906 4.55567C5.08106 4.13623 5.6534 3.9006 6.25019 3.9006H13.1301L10.9165 6.13715H6.25019V16.2016H16.376V11.5664L18.6262 9.36616V16.2016C18.6262 16.7948 18.3891 17.3637 17.9671 17.7831C17.5451 18.2025 16.9728 18.4382 16.376 18.4382H6.25019C5.6534 18.4382 5.08106 18.2025 4.65906 17.7831C4.23707 17.3637 4 16.7948 4 16.2016ZM20.3138 0.545776C19.8664 0.546168 19.4374 0.723151 19.1212 1.03782L11.1246 9.11455C10.8786 9.36317 10.6929 9.66438 10.5818 9.99519L9.77173 12.4135C9.76161 12.4521 9.76183 12.4928 9.77238 12.5313C9.78293 12.5699 9.80343 12.605 9.83185 12.6333C9.86028 12.6615 9.89564 12.6819 9.93443 12.6924C9.97322 12.7029 10.0141 12.7031 10.053 12.693L12.4944 11.8963C12.8273 11.7859 13.1304 11.6013 13.3805 11.3567L21.5064 3.40856C21.7422 3.17397 21.9027 2.87522 21.9677 2.55003C22.0326 2.22484 21.9992 1.88779 21.8716 1.58147C21.744 1.27514 21.5279 1.01328 21.2507 0.82894C20.9734 0.644604 20.6474 0.546068 20.3138 0.545776Z" fill="#265D91"/>
                </g>
                <defs>
                  <filter id="filter0_d_129_833" x="0" y="0.545776" width="26" height="25.8924" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_129_833"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_129_833" result="shape"/>
                  </filter>
                </defs>
              </svg>
              </button>

            </div>
          </div>

            <!--begin::Card header-->
            <div class="card-header border-0 pt-6 mb-4">
                <!--begin::Card title-->

                <div class="card-title">
                    <div class="input-group mb-3">
                        <input type="text" data-kt-customer-table-filter="search" [(ngModel)]="searchElement" 
                            class="form-control form-control-solid ps-15" placeholder="Rechercher" (keyup.enter)="applyFilter()" />
                            <div class="input-group-append">
                            <svg  width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                    fill="#7E7C7C" />
                            </svg>

                            <button  class="input-group-text cursor-pointer" id="basic-addon2"  (click)="applyFilter()" ><span>Rechercher</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!--begin::Card title-->
                <!--begin::Card toolbar-->
                <div class="card-toolbar">
                    <!--begin::Toolbar-->
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <!--begin::Filter-->

                        <!--end::Filter-->
                        <!--begin::Export-->

                        <!--end::Export-->
                        <!--begin::Add customer-->
                        <button (click)="add();openModal(myModal)" type="button" class="btn btn-primary ajouter"
                         >+ Ajouter sous Module</button>
                        <!--end::Add customer-->
                    </div>
                    <!--end::Toolbar-->
                    <!--begin::Group actions-->
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bold me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                    </div>
                    <!--end::Group actions-->
                </div>
                <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div *ngIf="!isLoadSpinner" class="card-body pt-0">
                <!--begin::Table-->
               <div class="table-responsive">
                <table class="table align-middle fs-6 gy-5" id="kt_customers_table">
                    <thead>
                        <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">

                            <th class="min-w-125px one">Icone</th>
                            <th class="min-w-125px two" >Compétence <i (click)="sortData()" class="fa-solid fa-sort cursor-pointer"></i></th>
                            <th class="min-w-125px three">Nombre de questions </th>
                            <th class="min-w-125px four">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="fw-semibold text-gray-600" *ngIf="subCatList&&subCatList.length">
                        <tr *ngFor="let subCat of subCatList">

                            <!-- <td>
                               <img *ngIf="subCat.icon&&subCat.icon!=''" src="{{ urlSubCategory + subCat.icon }} " alt="" width="30px">
                                         </td> -->
                                         <td>
                                            <img *ngIf="subCat.icon && subCat.icon !== ''" src="{{ urlSubCategory + subCat?.icon }}" alt="" width="30px" class="rounded-image">
                                            <img *ngIf="!subCat.icon || subCat.icon===''" src="/assets/media/logos/logo-avatar.jpg" alt="" width="30px">
                                         </td>
                            <td>
                                <a 
                                [routerLink]="['/acceuil/modules/question-sous-categorie' ,{id:subCat.id,idcat:idCategory,subCategory:subCat?.name_FR,category:category?.name_FR}]" routerLinkActive="router-link-active" 
                                class="text-gray-600 langage-name mb-1 cursor-pointer">{{subCat?.name_FR ? subCat?.name_FR :'---'}}</a>
                            </td>
                            <td>
                                <!--begin::Badges-->
                                <div class="badge badge-light-default">{{subCat?.nb_question?subCat?.nb_question:0}}</div>
                                <!--end::Badges-->
                            </td>
                            <td> <div class="seemore cursor-pointer">
                              <a (click)="navigateToQuestion(subCat.id,subCat?.name_FR,category.name_FR)" class="questions"> <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.2404 12.419C10.239 12.4195 10.238 12.4198 10.2373 12.42H10.222L10.2045 12.4267L5.63476 14.1952V12.9012C5.63476 12.7624 5.56571 12.6323 5.47142 12.5389C5.37708 12.4455 5.24607 12.3776 5.10695 12.3776H3.2246C2.54956 12.3776 1.95562 11.8265 1.95562 11.1224V3.20235C1.95562 2.53853 2.5516 1.94706 3.2246 1.94706H14.7326C15.4504 1.94706 16.0444 2.54049 16.0444 3.20235V11.1224C16.0444 11.8246 15.4525 12.3776 14.7326 12.3776H10.4118C10.351 12.3776 10.3055 12.3931 10.2733 12.4058C10.2655 12.4089 10.26 12.4112 10.2556 12.413C10.2496 12.4155 10.2456 12.4172 10.2404 12.419ZM14.7326 0.9H3.2246C1.97246 0.9 0.9 1.9603 0.9 3.20235V11.1224C0.9 12.408 1.97372 13.4247 3.2246 13.4247H4.57914V14.9341C4.57914 15.1158 4.6704 15.2822 4.80609 15.3845C4.94314 15.4879 5.12976 15.5286 5.31568 15.4503L10.5161 13.4247H14.7326C16.025 13.4247 17.1 12.4092 17.1 11.1224V3.20235C17.1 1.95907 16.0263 0.9 14.7326 0.9Z" fill="#265D91" stroke="#265D91" stroke-width="0.2"/>
                                <path d="M6.77546 6.3941C7.03701 6.3941 7.30327 6.18721 7.30327 5.87057C7.30327 4.95263 8.07038 4.19175 9.00006 4.19175C9.92974 4.19175 10.6969 4.95263 10.6969 5.87057C10.6969 6.82933 9.93131 7.5494 9.00006 7.5494C8.6769 7.5494 8.47226 7.81789 8.47226 8.11528V9.00469C8.47226 9.26605 8.68268 9.52822 9.00006 9.52822C9.13918 9.52822 9.27019 9.46034 9.36453 9.36693C9.45882 9.27359 9.52787 9.14345 9.52787 9.00469V8.58C10.8165 8.32713 11.7525 7.20384 11.7525 5.87057C11.7525 4.3744 10.5089 3.14469 9.00006 3.14469C7.49124 3.14469 6.24766 4.3744 6.24766 5.87057C6.24766 6.02066 6.29865 6.15336 6.39495 6.2487C6.49115 6.34394 6.6247 6.3941 6.77546 6.3941Z" fill="#265D91" stroke="#265D91" stroke-width="0.2"/>
                                <path d="M9.61325 10.4447C9.61325 10.2829 9.53176 10.1407 9.41889 10.0414C9.30615 9.94218 9.15481 9.87882 8.99989 9.87882C8.68892 9.87882 8.4293 10.1344 8.4293 10.4447C8.4293 10.7916 8.68327 11.0529 8.99989 11.0529C9.30536 11.0529 9.61325 10.8026 9.61325 10.4447Z" fill="#265D91" stroke="#265D91" stroke-width="0.2"/>
                                </svg>
                                 Questions</a>
                                 <a (click)="setFormUpdate(subCat,myModal)" class="modifier"  >
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 13.3195V4.76628C0 4.26217 0.201475 3.77872 0.560104 3.42226C0.918732 3.06581 1.40514 2.86555 1.91231 2.86555H7.75922L5.87798 4.76628H1.91231V13.3195H10.5177V9.38029L12.43 7.51045V13.3195C12.43 13.8236 12.2286 14.3071 11.8699 14.6636C11.5113 15.02 11.0249 15.2203 10.5177 15.2203H1.91231C1.40514 15.2203 0.918732 15.02 0.560104 14.6636C0.201475 14.3071 0 13.8236 0 13.3195ZM13.8643 0.0144653C13.484 0.0147981 13.1195 0.165207 12.8508 0.432625L6.05487 7.29662C5.84575 7.50791 5.68795 7.76389 5.59352 8.04503L4.90509 10.1002C4.89649 10.133 4.89668 10.1676 4.90564 10.2003C4.91461 10.2331 4.93203 10.263 4.95619 10.287C4.98034 10.311 5.01039 10.3283 5.04336 10.3372C5.07633 10.3461 5.11106 10.3463 5.14413 10.3378L7.21899 9.66064C7.50187 9.56686 7.75943 9.41001 7.97196 9.20209L14.8778 2.44739C15.0781 2.24803 15.2145 1.99414 15.2698 1.71777C15.325 1.44141 15.2966 1.15498 15.1882 0.894646C15.0797 0.634317 14.8961 0.411769 14.6604 0.255112C14.4248 0.0984541 14.1478 0.0147132 13.8643 0.0144653Z" fill="white"/>
                                        </svg>
                                    Modifier
                                 </a>
                                 <a  class="delete" (click)="deleteSubModule(subCat)">
                                  Supprimer
                               </a>
                            </div> </td>
                        </tr>

                    </tbody>
                    <!--end::Table body-->
                </table>
               
             
              <div class="alert alert-warning text-center mt-5" role="alert"  *ngIf="subCatList&&!subCatList.length">
                <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                <span class="empty-list">
                 La liste est vide
             </span>
             </div>


               </div>
                <!--end::Table-->
                <div class="pagination" *ngIf="subCatList && subCatList.length">
                    <app-pagination [data]="subCatList" [lengthData]="totalItems" [currentPageList]="currentPage" [endexPageList]="endIndex" (setPages)="getItems($event)" > </app-pagination>
                    </div>
            </div>
            <!--end::Card body-->
        </div>
    </div>
    <!--end::Content container-->
</div>

<ng-template #myModal >
<div class="modal-content" id="kt_modal_add_customer" tabindex="-1" aria-hidden="true">
            <!--begin::Modal dialog-->
                <!--begin::Modal content-->
                    <!--begin::Form-->
                    <form class="form" action="#" id="kt_modal_add_customer_form" data-kt-redirect="../../demo1/dist/apps/customers/list.html">
                        <!--begin::Modal header-->
                        <div class="modal-header" id="kt_modal_add_customer_header">
                            <!--begin::Modal title-->
                            <h2 class="">
                            {{mode=='create'?'Ajouter sous module':mode=='update'?'Modifier sous module':'Modifier module'}} </h2>
                            <!--end::Modal title-->
                            <!--begin::Close-->
                            <div id="kt_modal_add_customer_close"
                            class="btn btn-icon btn-sm btn-active-icon-primary">
                                <i class="ki-duotone ki-cross fs-1" (click)="closeModal()">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </i>
                            </div>
                            <!--end::Close-->
                        </div>
                        <!--end::Modal header-->
                        <!--begin::Modal body-->
                        <div class="modal-body py-10 px-lg-17 tools">
                            <!--begin::Scroll-->
                            <div [formGroup]="formSubCategory" class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                                <!--begin::Input group-->
                                <div class="fv-row mb-7 img-update " *ngIf="mode=='edit_module'|| mode=='update'" >
                                    <img class="cursor-pointer image-preview" *ngIf="mode=='update'&& fileName=='' || fileExtention" src="{{ urlSubCategory +icon }} " 
                                    (click)="fileInput.click()" alt="Select Image"  [style.width.px]="imageWidth" [style.height.px]="imageHeight"><br>
                                    <!-- <div class="add-module cursor-pointer">
                                        <img src="/assets/media/icons/icon-add.png"  *ngIf="fileName !== '' && !isImage()" (click)="fileInput.click()"
                                            alt=""
                                            width="30px" href="">
                                    </div> -->
                                    <!-- <img class="cursor-pointer" *ngIf="fileName !== '' && isImage()" [src]="selectedImage" alt="Select Image" width="70px" (click)="fileInput.click()"> -->
                                    <img class="cursor-pointer image-preview" *ngIf="fileName !== '' && isImage()" [src]="selectedImage" alt="Select Image" (click)="fileInput.click()" [style.width.px]="imageWidth" [style.height.px]="imageHeight">
                                    <br>
                                    <span style="color:red" *ngIf="fileError"><small>{{fileError}} </small></span>
                                    <!-- <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}} </small></span> -->
                                    
                                    <img  class="cursor-pointer" *ngIf="mode=='edit_module' && fileName==''" src="{{ urlCategory +icon }} "
                                     (click)="fileInput.click()" alt="Select Image"  width="70px" ><br>
                                    <!-- <img  class="cursor-pointer" *ngIf=" fileName!=''" [src]="selectedImage" alt="Select Image" width="70px"> -->
                                    <br>
                                    <small *ngIf="fileName==''|| fileName !== ''" class="cursor-pointer">Cliquer sur l'image pour la modifier.</small>
                                    <!-- <h6 *ngIf="fileName!=''">{{fileName}}</h6> -->
                                    <input type="file" accept="image/*,.avif"
                                    (change)="uploadFile($event)" #fileInput style="display: none"
                                     id="inputFile" >
                                 </div>
                       
                                <div class="fv-row mb-7">
                                    <!--begin::Label-->
                                    <label class="required fs-6 mb-2"> {{mode=='create'?'Nom de sous module':mode=='update'?'Nom de sous module':'Nom de module'}}  </label>
                                    <!--end::Label-->
                                    <!--begin::Input-->
                                    <input type="text" formControlName="name_FR" class="form-control form-control-solid mb-3" placeholder="Nom de sous module" name="module"  />
                                    <app-feild-error [control]="formSubCategory.get('name_FR')"></app-feild-error>
                                    <!--end::Input-->
                                </div>
                                <!--end::Input group-->
                                <!--begin::Input group-->
                                <div class="fv-row mb-7" *ngIf="mode=='create'">
                                    <!--begin::Label-->
                                    <label class=" fs-6  mb-2">Télécharger icone</label>
                                    <!--end::Label-->
                                    <!--begin::Input-->
                                   
                                    <input   #inputFile type="file" accept="image/*,.avif,.jfif"  name="cv" id="cv"
                                    (change)="uploadFile($event)" class="form-control mb-3">
                                    <!--end::Input-->
                                    <span style="color:red" *ngIf="fileError"><small>{{fileError}} </small></span>
                                    <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}} </small></span>
                                </div>
                               
                                <!--end::Input group-->
                                <!--begin::Input group-->


                            </div>
                            <!--end::Scroll-->
                        </div>
                        <!--end::Modal body-->
                        <!--begin::Modal footer-->
                        <div class="modal-footer">
                            <!--begin::Button-->
                            <button type="reset" id="kt_modal_add_customer_cancel" (click)="closeModal()" class="  me-3 footer-btn annuler">Annuler</button>
                            <!--end::Button-->
                            <!--begin::Button-->
                            <button type="submit" id="kt_modal_add_customer_submit"
                             [disabled]="!formSubCategory.valid || fileError!='' || fileExtention!=''" (click)="confirmModal()" class=" footer-btn confirmer">
                         
                             
                             
                             <span class="indicator-label">Confirmer</span>
                                <span class="indicator-progress">Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                            <!--end::Button-->
                        </div>
                        <!--end::Modal footer-->
                    </form>
                    <!--end::Form-->
        </div>
</ng-template>
<app-footer></app-footer>