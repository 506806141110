import { Component, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addmultipleQuestion,
  addQuestion,
  confirmDelete,
  existedQuestion,
  existedResponse,
  failedDelete,
  serverError,
  successDeletequestion,
  updatedQuestion,
} from '../../../../core/models/messages';
import { IQuestion } from '../../../../core/models/module';
import { ModuleService } from '../../../../core/services/modules/module.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-question-categories',
  templateUrl: './question-categories.component.html',
  styleUrls: ['./question-categories.component.css'],
})
export class QuestionCategoriesComponent {
  /* numbers */
  iscurrentresponse: boolean = false;
  state: number = 0;
  isDuplicateResponseErrorShown: boolean = false;
  pagination : boolean =false;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  maxLength = 100;
  endIndex: number = 5;
  idSubategory!: number;
  idQuestion!: number | undefined;
  currentSortDirection: number = 1;
  clickCount: number = 0;
  /* string */
  mode: string = 'create_question';
  categoryName!: string;
  subCategoryName!: string;
  searchElement: string = '';
  showFullText: any;

  /* form group */
  questionForm: FormGroup = this.createForm();
  questionAddForm: FormGroup = this.createFormAddQuation();
  idcat: any;
  /* arrays */
  questionList: IQuestion[] = [];
  /* modal */
  modalRef?: BsModalRef;
  offset: number = 0;
  /* unsubscription */
  isLoadSpinner: boolean = true;

  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryService: ModuleService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private router: Router
  ) {}
  ngOnInit() {
    this.getId();
  }
  /* get id subCategory from route */
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idSubategory = params.params['id'];
        this.subCategoryName = params.params['subCategory'];
        this.categoryName = params.params['category'];

        this.idcat = params.params['idcat'];

        this.getQuestions();
      },
    });
  }

  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') this.applyFilter();
      else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
         if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
         this.getQuestions();
        }
      }
    }
  }
  getQuestions() {
    this.spinner.show();
    let data = { per_page: this.itemsPerPage, page: this.currentPage };
    this.categoryService
      .getQuestionList(this.idSubategory, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isLoadSpinner = false;
          console.log('res', res);
          this.questionList = res.data?.data?.slice(0, this.endIndex);
          this.totalItems = res.data.total;
          this.showFullText = new Array(this.questionList.length).fill(false);
     console.log("list question",this.questionList)
     this.offset = (this.currentPage - 1) * this.itemsPerPage;
          if (this.currentPage > 1 && !res.data.data.length) {
            this.currentPage = 1;
            this.getQuestions();
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  navigateToDetails() {
    this.router.navigate([
      '/acceuil/modules/details-categorie',
      { idCat: this.idcat },
    ]);
  }

  getHref(): string {
    return `/acceuil/modules/details-categorie?idCat=${this.idcat}`;
  }
  createFormAddQuation(data?: any) {
    return this.formBuilder.group({
      subCategory: [''],
      category: [''],
      numberOfQuestions: [
        data ? data.numberOfQuestions : '',
        [Validators.required, Validators.pattern(/^(?:[1-9]|10)$/)],
      ], // Utilisation de Validators.pattern pour s'assurer que la valeur est un nombre entier positif
      sub_category_id: [''],
    });
  }

  /* create add candidat form */
  createForm(data?: any) {
    return this.formBuilder.group({
      question_FR: [
        data ? data.question_FR : '',
        [
          Validators.required,
          // this.notOnlySpacesValidator(),
          // Validators.pattern('^[a-zA-Z][a-zA-Z0-9]*$'),
          // Validators.pattern('^[a-zA-Z0-9\s\r\n]*$')
          // Validators.pattern('^(?!\\s*$).+') // Cette expression régulière vérifie qu'il y a au moins un caractère non-espace dans la chaîne
          // Validators.pattern('^(?!\\s*$)(?!.*\\d)[a-zA-Z\\s!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]*$')
          // Validators.pattern('^(?!\\s*$)[a-zA-Z0-9\\s!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]*$')
          Validators.pattern(
            '^(?!\\s*$)[a-zA-Z0-9éèàùçâêîôûäëïöüÿœæ²\\s!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]*$'
          ),
        ],
      ],
      responses: this.formBuilder.array(
        data && data.response_question?.length
          ? data.response_question?.map((item: { response_FR: string }) =>
              this.createFormResponse(item)
            )
          : [this.createFormResponse()]
      ),
      sub_category_id: [''],
      time: [
        data ? data.time : '',
        [Validators.required, Validators.pattern(/^[1-9]\d*$/)],
      ], // Utilisation de Validators.pattern pour s'assurer que la valeur est un nombre entier positif
    });
  }
  notOnlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value as string;
      const isValid = value?.trim() !== '';
      return isValid ? null : { onlySpaces: true };
    };
  }
  /* create response frm array */
  createFormResponse(data?: any) {
    return this.formBuilder.group({
      response_FR: [
        data?.response_FR ? data.response_FR : '',
        [Validators.required],
      ],
      state: [data?.state ? data.state : 0, [Validators.required]],
      id: [data?.id ? data.id : ''],
    });
  }
  // isResponseDuplicate(index: number): boolean {
  //   console.log("responses ",this.responses.controls.map(item=>item.value.response_FR))
  //  /* const currentResponse = this.normalizeSpaces(
  //     this.responses.controls[index]?.value?.response_FR
  //   );
  //   const nextResponse = this.normalizeSpaces(
  //     this.responses.controls[index + 1]?.value?.response_FR
  //   );*/

  //   const duplicates = this.responses.controls.map(item=>item.value.response_FR.trim()).filter((item, index) => this.responses.controls.map(item=>item.value.response_FR.trim()).indexOf(item) !== index);
  //   if (duplicates.length > 0) {
  //     console.log('Duplicates found:', duplicates);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  isResponseDuplicate(index?: any): boolean {
    const currentResponse = this.normalizeSpaces(
      this.responses.controls[index]?.value?.response_FR
    ).trim();

    // Par défaut, aucune réponse n'est en double
    this.isDuplicateResponseErrorShown = false;

    for (let i = 0; i < this.responses.length; i++) {
      if (i !== index) {
        const previousResponse = this.normalizeSpaces(
          this.responses.controls[i]?.value?.response_FR
        ).trim();
        if (currentResponse === previousResponse) {
          // Si une réponse en double est trouvée, définissez isDuplicateResponseErrorShown sur true
          this.isDuplicateResponseErrorShown = true;
          return true;
        }
      }
    }

    // Aucune réponse en double n'a été trouvée
    return false;
  }

  normalizeSpaces(value: string): string {
    return value ? value.replace(/\s+/g, ' ').trim() : '';
  }
  hasDuplicateResponses(): boolean {
    for (let i = 0; i < this.responses.length - 1; i++) {
      if (this.isResponseDuplicate(i)) {
        return true;
      }
    }
    return false;
  }
  /* get form array responses */
  get responses() {
    return this.questionForm.get('responses') as FormArray;
  }
  /* add new input response for diplome */
  addResponse() {
    this.responses.push(this.createFormResponse());
  }
  idResponse: number[] = [];
  /* remove input response for diplome */
  removeResponse(index: number, res: any) {
    this.idResponse.push(res?.value?.id);
    this.responses.removeAt(index);
  }
  deleteResponse() {
    this.categoryService
      .deleteResponse(this.idResponse)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: () => {
          this.getQuestions();
        },
        error: (err) => {
          if (err?.error) {
            if (err.error?.message['question_FR']) {
              this.toastr.info(existedQuestion);
            } else if (err.error?.message['response_FR']) {
              this.toastr.info(existedQuestion);
            } else {
              this.toastr.error(serverError);
            }
          }
          this.spinner.hide();
        },
      });
  }
  /* key inter number */
  keyPress(event: any) {
    let pattern = /^[\d]$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    return pattern;
  }
  // keyPressTextArea(event: any) {
  //   let pattern = /^[a-zA-Z][a-zA-Z0-9]*$/;
  //   let inputChar = String.fromCharCode(event.charCode);
  //   if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
  //     event.preventDefault();
  //   }
  //   return pattern;
  // }
  keyPressTextArea(event: any) {
    let textarea = event.target;

    // Gestion de la touche Entrée (Retour à la ligne)
    if (event.keyCode === 13) {
      event.preventDefault(); // Empêche le comportement par défaut de la touche "Entrée" (ajout de nouvelles lignes)
      let cursorPosition = textarea.selectionStart; // Obtient la position actuelle du curseur
      let textBeforeCursor = textarea.value.substring(0, cursorPosition);
      let textAfterCursor = textarea.value.substring(cursorPosition);
      textarea.value = textBeforeCursor + '\n' + textAfterCursor; // Ajoute un retour à la ligne à la position du curseur
      textarea.selectionStart = cursorPosition + 1; // Rétablit la position du curseur après l'ajout du retour à la ligne
      textarea.selectionEnd = cursorPosition + 1;
    }
    // Gestion de la touche Tabulation (Tab)
    else if (event.keyCode === 9) {
      event.preventDefault(); // Empêche le comportement par défaut de la touche "Tab" (changer de champ)
      let cursorPosition = textarea.selectionStart; // Obtient la position actuelle du curseur
      let textBeforeCursor = textarea.value.substring(0, cursorPosition);
      let textAfterCursor = textarea.value.substring(cursorPosition);
      textarea.value = textBeforeCursor + '\t' + textAfterCursor; // Ajoute une tabulation à la position du curseur
      textarea.selectionStart = cursorPosition + 1; // Rétablit la position du curseur après l'ajout de la tabulation
      textarea.selectionEnd = cursorPosition + 1;
    } else {
      let pattern = /^[a-zA-Z][a-zA-Z0-9]*$/;
      let inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }

  openModal(template: TemplateRef<any>, mode: string, data?: IQuestion) {
    this.mode = mode;
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    if (this.mode == 'edit_question') {
      this.idQuestion = data?.id;
      this.questionForm = this.createForm(data);
      console.log('this.questionForm', this.questionForm.value);
    }
  }
  openModalAdd(template: TemplateRef<any>, mode: string, data?: IQuestion) {
    this.mode = mode;
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    if (this.mode == 'edit_question') {
      this.idQuestion = data?.id;
      this.questionForm = this.createForm(data);
    }
  }
  closeModal() {
    this.modalRef!.hide();
    this.questionForm.reset();
    this.questionAddForm.reset();
    this.questionForm = this.createForm();
    this.idResponse = [];
    // this.state=0;
    const responsesArray = this.questionForm.get('responses') as FormArray;
    responsesArray.controls.forEach((control) => {
      control.get('state')?.setValue(false); // Réinitialiser l'état de chaque case à cocher
    });
  }

  closeModalupdate() {
    this.modalRef!.hide();
    // this.questionForm.reset();
    // this.questionForm = this.createForm();
    this.idResponse = [];
  }
  /* add / update question */
  confirmer() {
    if (this.mode == 'create_question') {
      this.addQuestion();
    } else {
      this.updateQuestion();
    }
  }
  /* add question with response */

  getstatevalue(event: any, i: number) {
    const newState = event.target.checked ? 1 : 0;
    console.log('state', newState);
    this.responses.at(i)?.get('state')?.setValue(newState);
  }
  addQuestion() {
    if (this.questionForm.valid) {
      /* check if a response is duplicated */
      let valueArr = this.responses.value.map(function (item: any) {
        return item.response_FR.trim().toLowerCase();
      });
      console.log('valueArr', valueArr);

      // let isDuplicate = valueArr.some(function(item:any, idx:any){
      //     return valueArr.indexOf(item.trim().toLowerCase()) != idx
      // });
      // if (isDuplicate) {
      //   this.toastr.info(existedResponse)
      // }
      // else{

      console.log('question form add', this.questionForm.value);
      this.questionForm.get('sub_category_id')?.setValue(this.idSubategory);
      let hasCorrectResponse = this.responses.controls.some(
        (
          control: AbstractControl<any, any>,
          index: number,
          array: AbstractControl<any, any>[]
        ) => {
          if (control instanceof FormGroup) {
            return control.get('state')?.value;
          }
          return false;
        }
      );

      if (!hasCorrectResponse) {
        this.toastr.error(
          'Veuillez sélectionner au moins une réponse comme étant correcte.'
        );
        return;
      }

      this.spinner.show();
      this.categoryService
        .addQuestion(this.questionForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: () => {
            this.spinner.hide();
            this.closeModal();
            this.getQuestions();
            this.toastr.success(addQuestion);
          },
          error: (err) => {
            console.log('error', err);
            if (err.status != 403) {
              if (err?.error) {
                if (err.error?.message['question_FR']) {
                  this.toastr.info(existedQuestion);
                } else if (err.error?.message['response_FR']) {
                  this.toastr.info(existedQuestion);
                } else {
                  this.toastr.error(serverError);
                }
              }
            }
            this.spinner.hide();
          },
        });
      // }
    }
  }
  /* add question with response Ai */
  addQuestionAi() {
    if (this.questionAddForm.valid) {
      this.questionAddForm.get('sub_category_id')?.setValue(this.idSubategory);
      this.questionAddForm.get('subCategory')?.setValue(this.subCategoryName);
      this.questionAddForm.get('category')?.setValue(this.categoryName);
      this.spinner.show();
      this.categoryService
        .addQuestionChatGpt(this.questionAddForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.closeModal();
            this.getQuestions();
            console.log("res",res)
            if(res.data.length<=1){
              this.toastr.success(addQuestion);
            }
          else{
          this.toastr.success(addmultipleQuestion);
          }
          },
          error: (err) => {
            if (err.status != 403) {
              if (err?.error) {
                if (err.error?.message['question_FR']) {
                  this.toastr.info(existedQuestion);
                } else if (err.error?.message['response_FR']) {
                  this.toastr.info(existedResponse);
                } else if (
                  err.error.message === 'No valid question generated.'
                ) {
                  this.toastr.error('aucun question trouvé');
                } else if (
                  err.error.message ===
                  'Invalid question data structure: missing "question" key.'
                ) {
                  this.toastr.error('aucun question trouvé');
                } else if (
                  err.error.message ===
                  'You exceeded your current quota, please check your plan and billing details. For more information on this error, read the docs: https://platform.openai.com/docs/guides/error-codes/api-errors.'
                ) {
                  this.toastr.error(
                    'Vous avez dépassé votre quota actuel, veuillez vérifier votre forfait et les détails de facturation'
                  );
                } else if (
                  err.error.message ===
                  'Not all requested questions could be generated.'
                ) {
                  this.toastr.error(
                    "les questions demandées n'ont pas pu être générées"
                  );
                } else {
                  this.toastr.error(serverError);
                }
              }
            }
            this.spinner.hide();
          },
        });
    }
  }
  /* update question */
  updateQuestion() {
    if (this.idResponse.length) this.deleteResponse();
    this.questionForm.get('sub_category_id')?.setValue(this.idSubategory);
    let hasCorrectResponse = this.responses.controls.some(
      (
        control: AbstractControl<any, any>,
        index: number,
        array: AbstractControl<any, any>[]
      ) => {
        if (control instanceof FormGroup) {
          return control.get('state')?.value;
        }
        return false;
      }
    );

    if (!hasCorrectResponse) {
      this.toastr.error(
        'Veuillez sélectionner au moins une réponse comme étant correcte.'
      );
      return;
    }
    this.spinner.show();
    console.log("form update",this.questionForm.value)
    this.categoryService
      .updateQuestion(this.idQuestion, this.questionForm.value)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.getQuestions();
          this.closeModal();
          this.toastr.success(updatedQuestion);
        },
        error: (err) => {
          console.log('error', err);
          this.spinner.hide();
          if (err.status != 403) {
            if (err?.error) {
              if (err.error?.message['question_FR']) {
                this.toastr.info(existedQuestion);
              } else if (err.error?.message['response_FR']) {
                this.toastr.info(existedResponse);
              } else {
                this.toastr.error(serverError);
              }
            }
          }
        },
      });
  }
  /* check to correcte answer */
  changeState(event: any, response: any, idQuestion: number) {
    let data = {
      state: event.target.checked ? 1 : 0,
      question_id: idQuestion,
      response_FR: response.response_FR,
    };
    this.spinner.show();
    this.categoryService
      .updateStateResponse(response.id, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: () => {
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* filter data on key up input */
  applyFilter() {
    if (this.searchElement != '' || this.clickCount) {
      this.clickCount++;
      if (this.clickCount == 1) this.currentPage = 1;
      let data = {
        search: this.searchElement.trim().toLowerCase(),
        sort: this.currentSortDirection,
        sortAttribute: 'question_FR',
        page: this.currentPage,
        per_page: this.itemsPerPage,
      };
      this.spinner.show();
      this.categoryService
        .searchQuestionSubCategory(this.idSubategory, data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if ( this.currentPage > 1) {
              this.currentPage = 1;
              this.offset = (this.currentPage - 1) * this.itemsPerPage;
              this.applyFilter();
            } else {
              this.questionList = res.data.data.slice(0, this.endIndex);
              this.totalItems = res.data.total;
            }
            this.spinner.hide();
          },
          error: () => {
            this.spinner.hide();
            this.toastr.error(serverError);
          },
        });
    }
  }
  openDeleteQuestionAlert(data: any) {
    Swal.fire({
      title: `${confirmDelete} ce question?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteQuestion(data.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }

  deleteQuestion(idCat: number) {
    this.spinner.show();
    this.categoryService
      .deleteQesion(idCat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getQuestions();
            this.spinner.hide();
            this.toastr.success(successDeletequestion);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
