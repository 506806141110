import { Component, TemplateRef, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ICountry } from 'country-state-city';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  fileTypeExtention,
  serverError,
} from '../../../../core/models/messages';
import { patternEmail, patternPassword } from '../../../../core/models/pattern';
import { CandidatService } from '../../../../core/services/candidat/candidat.service';
import { CompanyService } from '../../../../core/services/company/company.service';
import { SortDataService } from '../../../../core/services/sort-data/sort-data.service';
import { environment } from '../../../../../environments/environment';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { PaysService } from '../../../../shared/services/pays.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-client-final',
  templateUrl: './client-final.component.html',
  styleUrls: ['./client-final.component.css'],
})
export class ClientFinalComponent {
  /* string */
  url: string = environment.baseUrl + '/api'
  sortAttr: string = 'id'
  /* number */
  seeMore: boolean = false;
  pagination: boolean = false;
  currentSortDirection: number = 2;
  itemsPerPage: number = 5
  currentPage: number = 1
  totalItems!: number
  startIndex: number = 0
  endIndex: number = 5
  /* any */
  isLoadSpinner: boolean = true;

  listClientFinal: any = []
  selectedFile: File | null = null;
  fileBulkError: any
  natureEsn = [
    { id: 1, name: 'ESN' },
    { id: 2, name: 'Cabinet de recrutement' },
  ]
  etatESN = [
    { id: 1, name: 'Actif' },
    { id: 2, name: 'Non actif' },
  ]
  paysList: ICountry[] = [];
  isRecherche: boolean = false;
  dataSort = [
    { name: 'ID', code: 'ID_company' },
    { name: 'Entreprise', code: 'name' },
    { name: 'Nature', code: 'type' },
    { name: 'Admin', code: 'admin' },
    { name: 'Avis', code: 'average_note' },
    { name: 'Pays', code: 'country' },
    { name: 'Ville', code: 'city' },
    { name: 'Email', code: 'ContactEmail' },
    { name: 'N°Tel', code: 'ContactPhone' },
    { name: 'Nbre AO', code: 'nb_job_offer' },
    { name: 'Nbre d’intervenant', code: 'number_of_intervenant' },
    { name: 'Nbre de Candidatures', code: 'nb_candidatures' },
    { name: 'Abonnement', code: 'nb_candidatures' },
    { name: 'Dernière connexion', code: 'last_connection' },
    { name: 'Etat', code: 'last_connection' },
  ]

  data: any[] = [
    {
      competences: [
        'nom',
        'site_web',
        'linkedin',
        'taille_entreprise',
        'pays',
        'location',
        'domain',
        'annee_creation',
        'logo',
        'a_propos',
      ],
    },
  ];
  /* FormGroup */
  searchForm: FormGroup = this.createSearchForm();
  /* modal */
  modalRef?: BsModalRef;
  companyType = new FormControl();
  checkedEsn: boolean = false;
  checkedClient: boolean = false;
  ispagination: boolean = true;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sortDataService: SortDataService,
    private candidatService: CandidatService,
    private paysServices: PaysService,
    private elRef: ElementRef,
    private primengConfig: PrimeNGConfig
  ) { }
  ngOnInit() {
    this.paysList = this.paysServices.getAllCountries();

    this.getListElementHeader();
    // this.getBackSearch()
    this.getListClientFinal();

    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
    this.primengConfig.setTranslation({
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer'
    });
  }
  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      Afghanistan: 'Afghanistan',
      'South Africa': 'Afrique du Sud',
      Albania: 'Albanie',
      Algeria: 'Algérie',
      Germany: 'Allemagne',
      Andorra: 'Andorre',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctique',
      'Antigua and Barbuda': 'Antigua-et-Barbuda',
      'Saudi Arabia': 'Arabie saoudite',
      Argentina: 'Argentine',
      Armenia: 'Arménie',
      Aruba: 'Aruba',
      Australia: 'Australie',
      Austria: 'Autriche',
      Azerbaijan: 'Azerbaïdjan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahreïn',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbade',
      Belgium: 'Belgique',
      Belize: 'Belize',
      Benin: 'Bénin',
      Bermuda: 'Bermudes',
      Bhutan: 'Bhoutan',
      Belarus: 'Biélorussie',
      Bolivia: 'Bolivie',
      'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
      Botswana: 'Botswana',
      Brazil: 'Brésil',
      Brunei: 'Brunei',
      Bulgaria: 'Bulgarie',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      'Cape Verde': 'Cabo Verde',
      Cambodia: 'Cambodge',
      Cameroon: 'Cameroun',
      Canada: 'Canada',
      Chile: 'Chili',
      China: 'Chine',
      Cyprus: 'Chypre',
      Colombia: 'Colombie',
      Comoros: 'Comores',
      'Congo - Brazzaville': 'Congo-Brazzaville',
      'Congo - Kinshasa': 'Congo-Kinshasa',
      'North Korea': 'Corée du Nord',
      'South Korea': 'Corée du Sud',
      'Costa Rica': 'Costa Rica',
      'Ivory Coast': 'Côte d’Ivoire',
      Croatia: 'Croatie',
      Cuba: 'Cuba',
      Curaçao: 'Curaçao',
      Denmark: 'Danemark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominique',
      'Dominican Republic': 'République dominicaine',
      Ecuador: 'Équateur',
      Egypt: 'Égypte',
      'United Arab Emirates': 'Émirats arabes unis',
      Eritrea: 'Érythrée',
      Spain: 'Espagne',
      Eswatini: 'Eswatini',
      Estonia: 'Estonie',
      'United States': 'États-Unis',
      Ethiopia: 'Éthiopie',
      Fiji: 'Fidji',
      Finland: 'Finlande',
      France: 'France',
      Gabon: 'Gabon',
      Gambia: 'Gambie',
      Georgia: 'Géorgie',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Grèce',
      Grenada: 'Grenade',
      Greenland: 'Groenland',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernesey',
      Guinea: 'Guinée',
      'Guinea-Bissau': 'Guinée-Bissau',
      'Equatorial Guinea': 'Guinée équatoriale',
      Guyana: 'Guyana',
      'French Guiana': 'Guyane française',
      Haiti: 'Haïti',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Hongrie',
      Iceland: 'Islande',
      India: 'Inde',
      Indonesia: 'Indonésie',
      Iraq: 'Irak',
      Iran: 'Iran',
      Ireland: 'Irlande',
      'Isle of Man': 'Île de Man',
      Israel: 'Israël',
      Italy: 'Italie',
      Jamaica: 'Jamaïque',
      Japan: 'Japon',
      Jersey: 'Jersey',
      Jordan: 'Jordanie',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      Kuwait: 'Koweït',
      Kyrgyzstan: 'Kirghizistan',
      Laos: 'Laos',
      Latvia: 'Lettonie',
      Lebanon: 'Liban',
      Lesotho: 'Lesotho',
      Liberia: 'Libéria',
      Libya: 'Libye',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lituanie',
      Luxembourg: 'Luxembourg',
      Macao: 'Macao',
      'North Macedonia': 'Macédoine du Nord',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaisie',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malte',
      'Marshall Islands': 'Îles Marshall',
      Martinique: 'Martinique',
      Mauritania: 'Mauritanie',
      Mauritius: 'Maurice',
      Mayotte: 'Mayotte',
      Mexico: 'Mexique',
      Micronesia: 'Micronésie',
      Moldova: 'Moldavie',
      Monaco: 'Monaco',
      Mongolia: 'Mongolie',
      Montenegro: 'Monténégro',
      Montserrat: 'Montserrat',
      Morocco: 'Maroc',
      Mozambique: 'Mozambique',
      Myanmar: 'Myanmar',
      Namibia: 'Namibie',
      Nauru: 'Nauru',
      Nepal: 'Népal',
      Netherlands: 'Pays-Bas',
      'New Caledonia': 'Nouvelle-Calédonie',
      'New Zealand': 'Nouvelle-Zélande',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Île Norfolk',
      'Northern Mariana Islands': 'Îles Mariannes du Nord',
      Norway: 'Norvège',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palaos',
      Palestine: 'Palestine',
      Panama: 'Panama',
      'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
      Paraguay: 'Paraguay',
      Peru: 'Pérou',
      Philippines: 'Philippines',
      'Pitcairn Islands': 'Îles Pitcairn',
      Poland: 'Pologne',
      Portugal: 'Portugal',
      'Puerto Rico': 'Porto Rico',
      Qatar: 'Qatar',
      Romania: 'Roumanie',
      Russia: 'Russie',
      Rwanda: 'Rwanda',
      Réunion: 'Réunion',
      'Saint Barthélemy': 'Saint-Barthélemy',
      'Saint Helena': 'Sainte-Hélène',
      'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
      'Saint Lucia': 'Sainte-Lucie',
      'Saint Martin': 'Saint-Martin',
      'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
      'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
      Samoa: 'Samoa',
      'San Marino': 'Saint-Marin',
      'Sao Tome and Principe': 'Sao Tomé-et-Principe',
      Senegal: 'Sénégal',
      Serbia: 'Serbie',
      Seychelles: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapour',
      'Sint Maarten': 'Saint-Martin',
      Slovakia: 'Slovaquie',
      Slovenia: 'Slovénie',
      'Solomon Islands': 'Îles Salomon',
      Somalia: 'Somalie',
      'South Georgia and the South Sandwich Islands':
        'Géorgie du Sud-et-les Îles Sandwich du Sud',
      'South Sudan': 'Soudan du Sud',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Soudan',
      Suriname: 'Suriname',
      'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
      Swaziland: 'Eswatini',
      Sweden: 'Suède',
      Switzerland: 'Suisse',
      Syria: 'Syrie',
      Taiwan: 'Taïwan',
      Tajikistan: 'Tadjikistan',
      Tanzania: 'Tanzanie',
      Thailand: 'Thaïlande',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinité-et-Tobago',
      Tunisia: 'Tunisie',
      Turkey: 'Turquie',
      Turkmenistan: 'Turkménistan',
      Tuvalu: 'Tuvalu',
      Uganda: 'Ouganda',
      Ukraine: 'Ukraine',
      'United Kingdom': 'Royaume-Uni',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Ouzbékistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatican',
      Venezuela: 'Venezuela',
      Vietnam: 'Viêt Nam',
      'Western Sahara': 'Sahara occidental',
      Yemen: 'Yémen',
      Zambia: 'Zambie',
      Zimbabwe: 'Zimbabwe',
    };
    return translations[name] || name;
  }

  dataHeader: any = [];
  listNames: string[] = [];
  // getBackSearch(){
  //   this.spinner.show()
  //   this.companyService.searchResultsClient$.subscribe(resultsClient => {
  //     if (resultsClient && resultsClient.length > 0) {
  //       this.listClientFinal = resultsClient;
  //       this.searchForm = this.createSearchForm(this.listClientFinal)
  //        this.spinner.hide()
  //       console.log("listEsnFromNgOnInit",this.listClientFinal);
  //     } else
  //       this.getListClientFinal()
  //       this.spinner.hide()
  //   });
  // }

  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
      console.log('HEYYYYYYYYYYYY');
    }
  }

  getListElementHeader() {
    this.candidatService
      .getHeaderElements(4)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.dataHeader = res.data;
          for (const iterator of this.dataHeader) {
            if (
              (iterator.checked == 'oui' && !iterator.checked_user) ||
              (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
              (iterator.checked == 'oui' && iterator.checked_user == 'oui')
            )
              this.listNames.push(iterator.name);
          }
          this.dataHeader.map((el: any) => {
            if (
              (el.checked == 'oui' && !el.checked_user) ||
              (el.checked_user == 'oui' &&
                (el.checked == 'non' || el.checked == 'oui'))
            ) {
              return (el.checkAdmin = true);
            } else {
              return (el.checkAdmin = false);
            }
          });
        },
        error: () => {
          this.toastr.error(serverError);
        },
      });
  }
  /* create register form */
  registerForm(data: any) {
    return this.formBuilder.group({
      name: [data.name, [Validators.minLength(3)]],
      linkedin_link: [data.linkedin_link],
      website_link: [data.website_link],
      // type: [data.type, Validators.required],
      type: 4,
      email: [
        data.email,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(patternEmail),
        ],
      ],
      password: [
        data.password,
        [Validators.required, Validators.pattern(patternPassword)],
      ],
      // role_id: 4,
      idCompany: [data ? data.idCompany : ''],
    });
  }
  changeSelection(event: any, data: any, i: number) {
    this.listNames = [];
    data.checkAdmin = event.target.checked;
    for (const iterator of this.dataHeader) {
      if (iterator.checkAdmin) this.listNames.push(iterator.name);
    }
    this.changeSelectionHeadrElements(data);
  }
  changeSelectionHeadrElements(data: any) {
    let payload = {
      sub_page_id: data?.id,
    };
    this.candidatService
      .changeDelectedElementHeader(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => { },
        error: () => {
          this.toastr.error(serverError);
        },
      });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal(event: boolean) {
    if (event) {
      this.getListClientFinal();
      this.modalRef?.hide();
    }
  }
  leave(event: any) {
    this.searchForm.get('note')?.setValue(event);
  }
  handleRatingClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('bs-rating-star')) {
      // Check if the "active" class is present
      const isActive = target.classList.contains('active');
      // Toggle the "active" class
      target.classList.toggle('active', !isActive);
      this.ispagination = false;
    }
  }
  updateCompany() {
    console.log(this.companyType, 'companyType');
  }

  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchForm.dirty) {
        this.searchCompnies();
      } else if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getListClientFinal();
      }
    }
  }
  fileErr: boolean = false;
  fileExtentions: string = '';

  /*select file and add Bulk Candidates */
  onFileSelected(event: any) {
    this.fileErr = false;
    this.fileExtentions = '';
    this.selectedFile = event.target.files[0];

    if (this.selectedFile) {
      console.log('Fichier sélectionné :', this.selectedFile);
      const fileExtension = this.selectedFile.name
        .toLowerCase()
        .substr(this.selectedFile.name.lastIndexOf('.'));
      const allowedExtensions = ['.csv', '.xlsx', '.xls'];
      if (!allowedExtensions.includes(fileExtension)) {
        console.log('selectedFile', fileExtension);
        this.fileExtentions = fileTypeExtention;
        this.fileErr = true;
      }
    }
  }

  addBulkClient() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      this.spinner.show();
      this.companyService.addBulkClient(formData).subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.fileBulkError = res.export_file;
          console.log('file bluk error', this.fileBulkError);
          this.getbulkClient(this.fileBulkError);
          this.toastr.success('Fichier importé avec succès');
          this.closeModalBulk();
          this.selectedFile = null;
        },
        error: (err) => {
          this.spinner.hide();
          if (err?.error) {
            if (err.error?.message) {
              if (err.error.message === 'File contains only headers.') {
                this.toastr.error('Le fichier est vide');
              } else if (
                err.error.message ===
                'File headers do not match the expected headers.'
              ) {
                this.toastr.error(
                  'Les en-têtes de fichiers ne correspondent pas aux en-têtes attendus.'
                );
              }
              // else {
              //   this.toastr.error(serverError);
              // }
            }
            // else {
            //   this.toastr.error(serverError);
            // }
            this.closeModalBulk();
            this.selectedFile = null;
          }
        },
      });
    }
  }

  getbulkClient(file: File) {
    let exportSuccess = false;
    this.companyService.getbulkClient(file).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], { type: 'application/octet-stream' });
        saveAs(blob, this.fileBulkError);
        exportSuccess = true;
        if (exportSuccess) {
          this.toastr.success(
            'Résultat de fichier importé a été exporté avec succès'
          );
        }
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error(serverError);
      },
    });
  }

  /*download file add bulk */
  downloadCsv() {
    this.companyService.downloadcsvfileAddBulkClient().subscribe({
      next: (res: Blob) => {
        console.log("res", res);
        const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'ajout en masse admin client final.csv');
      },
      error: (err) => {
        console.log('error', err);
        // Handle the error appropriately
      },
    });
  }
  // downloadCsv() {
  //   const fileSaver = saveAs;
  //   if (Array.isArray(this.data)) {
  //     const headers = Object.keys(this.data[0]);
  //     const csvContent = this.data.map(item => {
  //       return headers.map(header => {
  //         if (Array.isArray(item[header])) {
  //           return item[header].join(';');
  //         } else if (typeof item[header] === 'object' && item[header] !== null) {
  //           return JSON.stringify(item[header]);
  //         } else {
  //           return item[header];
  //         }
  //       }).join(',');
  //     }).join('\n');

  //     fileSaver(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }), 'ajout en masse admin client final.csv');
  //   } else {
  //     console.error('Invalid data format. Expected an array.');
  //   }
  // }
  /* sort data  */
  // sortData(name:string){
  //  this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
  //  for (const iterator of this.dataSort) {    
  //   if(iterator.name==name) this.sortAttr=iterator.code
  // }
  //  if(this.searchForm.dirty) this.listClientFinal = this.sortDataService.sortArray(this.listClientFinal, name, this.currentSortDirection)
  //   else this.getListClientFinal()
  // }
  sortData(name: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;

    for (const iterator of this.dataSort) {
      if (iterator.name == name) this.sortAttr = iterator.code;
    }

    this.currentPage = 1;
    this.endIndex = 5;
    // this.endIndex=this.itemsPerPage;


    if (this.searchForm.dirty) {
      this.listClientFinal = this.sortDataService.sortArray(
        this.listClientFinal,
        name,
        this.currentSortDirection
      );
    } else {
      this.getListClientFinal();
    }


    this.getListClientFinal();
  }
  /* list list esn companies */
  getListClientFinal() {
    this.spinner.show()
    let dataPyload = this.sortAttr == '' ? { type: [3], per_page: this.itemsPerPage, page: this.currentPage } : { ...{ per_page: this.itemsPerPage, page: this.currentPage }, ...{ type: [3], sort: this.currentSortDirection, sortAttribute: this.sortAttr } }
    this.companyService.getCompaniesListClient(dataPyload).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.isLoadSpinner = false;
        this.spinner.hide()
        this.listClientFinal = res.data.data
        this.totalItems = res.data.total
      },
      error: () => {
        this.spinner.hide()

      }
    })
  }
  deleteCompany(id: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        this.companyService.deletecompany(id).subscribe((res: any) => {
          console.log("response", res);
          this.toastr.success('Entreprise a été supprimée.', 'Supprimé !', {
            closeButton: true,
            progressBar: true,
            timeOut: 5000,
            extendedTimeOut: 2000
          });
          this.searchCompniesreset();
        });
      }
    });
  }
  detailsClientFinalNavigation(id: string, idRole: string) {
    this.router.navigate(['/acceuil/entreprises/clients-finaux/details-client-final', { id: id, idRole: idRole }])
  }
  /* create add subCategory form */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      ID_company: [data ? data.ID_company : ''],
      name: [data ? data.name : ''],
      name_Intervenant: [data ? data.name_Intervenant : ''],
      name_Consult: [data ? data.name_Consult : ''],
      type: [data ? data.type : null],
      nature: [data ? data.nature : null],
      abon_NonAbon: [data ? data.abon_NonAbon : null],
      abonnement: [data ? data.abonnement : null],
      etat: [data ? data.etat : null],
      responsable_name: [data ? data.responsable_name : null],
      date_last_conx: [data ? data.date_last_conx : null],
      note: [data ? data.note : 0],
      country: [data ? data.country : null],
      search: [
        '',
        [Validators.pattern(/^("([^"]*"(AND|NOT|,|&&)[^"]*)*"|[^\s"']+)$/)],
      ],
    });
  }
  updateInputType(type: 'text' | 'date') {
    const inputElement = document.getElementById(
      'dateInput'
    ) as HTMLInputElement;
    inputElement.type = type;
    if (type == 'text') {
      if (
        this.searchForm.value.date_last_conx &&
        this.searchForm.value.date_last_conx != ''
      )
        this.searchForm
          .get('date_last_conx')
          ?.setValue(
            moment(this.searchForm.value.date_last_conx).format('DD/MM/yyyy')
          );
    }
  }
  dateFormat(value: any) {
    const [day, month, year] = value.split('/');
    return moment(`${year}-${month}-${day}`).format('YYYY-MM-DD');
  }
  /* search */
  /* search */
  transformSearch(search: string): string {
    // Remplace toutes les occurrences de '||' par 'OR'
    search = search.replace(/\|\|/g, ' OR ');

    // Remplace toutes les occurrences de '&&' par ' AND '
    search = search.replace(/&&/g, ' AND ');

    // Remplace toutes les occurrences de '!' par ' NOT '
    search = search.replace(/!/g, ' NOT ');

    search = search.replace(/\b(AND|OR|NOT)\b/g, ' $1 ');

    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        return `"${p1}"`;
      } else {
        // Si le terme n'est pas entre guillemets, échappe les guillemets simples à l'intérieur du terme
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `${escapedTerm}`;
      }
    });

    search = search.replace(/\s+/g, ' ');

    return search.trim();
  }

  isSimpleWord(value: string): boolean {
    // Utilisez une expression régulière pour vérifier si la valeur est un mot simple ou deux mots avec espace
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }
  searchCompnies() {
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      type: [3],
      // sortAttribute:'name',
      // sort:this.currentSortDirection,
      name: this.searchForm.value.name,
      note:
        this.searchForm.value.note && this.searchForm.value.note == 0
          ? 'null'
          : this.searchForm.value.note,
      ID_company: this.searchForm.value.ID_company,
      responsable_name: this.searchForm.value?.responsable_name,
      date_last_conx: this.searchForm.value.date_last_conx
        ? this.dateFormat(this.searchForm.value.date_last_conx)
        : '',
      abon_NonAbon: this.searchForm.value.abon_NonAbon,
      abonnement: this.searchForm.value.abonnement,
      country: this.searchForm.value.country,
      consultantExterne_name: this.searchForm?.value?.name_Consult,
      intervenant_name: this.searchForm?.value?.name_Intervenant,
      search: this.transformSearch(this.searchForm.value?.search),
    };
    this.spinner.show();
    if (data.note === 0) {
      console.log('note');
      data.note = '';
    }
    this.companyService
      .searchESN(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          if (!res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.searchCompnies();
          } else {
            this.listClientFinal = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
            // this.companyService.setSearchResultsClient(this.listClientFinal);
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  reset() {
    this.currentPage = 1;
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();

    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();

      this.getListClientFinal();
    }
    return (this.isRecherche = false);
  }
  searchCompniesreset() {
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      type: [3],
      // sortAttribute:'name',
      // sort:this.currentSortDirection,
      name: this.searchForm.value.name,
      note:
        this.searchForm.value.note && this.searchForm.value.note == 0
          ? 'null'
          : this.searchForm.value.note,
      ID_company: this.searchForm.value.ID_company,
      responsable_name: this.searchForm.value?.responsable_name,
      date_last_conx: this.searchForm.value.date_last_conx
        ? this.dateFormat(this.searchForm.value.date_last_conx)
        : '',
      abon_NonAbon: this.searchForm.value.abon_NonAbon,
      abonnement: this.searchForm.value.abonnement,
      country: this.searchForm.value.country,
      consultantExterne_name: this.searchForm?.value?.name_Consult,
      intervenant_name: this.searchForm?.value?.name_Intervenant,
      search: this.transformSearch(this.searchForm.value?.search),
    };
    this.spinner.show();
    if (data.note === 0) {
      console.log('note');
      data.note = '';
    }
    this.companyService
      .searchESN(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (!res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.searchCompnies();
          } else {
            this.listClientFinal = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
            // this.companyService.setSearchResultsClient(this.listClientFinal);
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  getListClientFinalreset() {
    let dataPyload =
      this.sortAttr == ''
        ? { type: [3], per_page: this.itemsPerPage, page: this.currentPage }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            type: [3],
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };
    this.companyService
      .getCompaniesListClient(dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listClientFinal = res.data.data.slice(0, this.endIndex);
          this.totalItems = res.data.total;
        },
        error: () => { },
      });
  }
  openModalBulk(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModalBulk() {
    this.selectedFile = null;
    this.modalRef!.hide();
  }
  isHalfStar(index: number, averageNote: number): boolean {
    return index + 0.5 === averageNote && !Number.isInteger(averageNote);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable.removeEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
}
