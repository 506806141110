import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CrudService } from '../crud/crud.service';
import { ApiResponse } from '../../models/response-api';
import { LocalStorageService } from '../Local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends CrudService<ApiResponse<any>, number> {
  pusher: any;
  channel: any;
  currentUser: any;
  id: any;

  messages$ = new Subject<any>();
  constructor(
    _http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    super(_http, `${environment.baseUrl}/api`);

    this.pusher = new Pusher('eb5150b9b1988b68581b', {
      cluster: 'mt1',
    });
    this.currentUser = this.localStorageService.getData('AdminInfo');
    if (this.currentUser) {
      try {
        this.id = JSON.parse(this.currentUser).id;
        console.log('id current Admin', this.id);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else {
    }
    console.log('currentUser', this.id);

    this.channel = this.pusher.subscribe('notifications.' + this.id);

    this.channel.bind('App\\Events\\NotificationEvent', (data: any) => {
      console.log('data', data);
      this.messages$.next(data);
    });
  }

  getChannel(channelName: string) {
    return this.pusher.subscribe(channelName);
  }
   getListOfNotification(per_page?:any,page?:any){
    return this._http.get<ApiResponse<any>>(this._base + `/Notification/notifications?per_page=${per_page}&page=${page}`)
  }
  readNotification(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Notification/markNotificationAsRead`,
      data
    );
  }

  disconnect() {
    this.pusher.disconnect();
  }
}
