<head>
    <title>Liste intervenants client final</title>
    <meta name="description" content="Liste intervenants client final">
  </head>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <div id="kt_app_content_container" class="app-container container-fluid">
      <!--begin::Card-->
      <div class="card mb-10">
        <div class="card-body tools" [formGroup]="searchFormGroup" >
          <div class="title mb-3">
            <h4>Recherche</h4>
          </div>
          <div class="row mb-3">
            <div class="col-lg-3">
              <input type="text" name="id" id="id" class="form-control form-control-solid mb-3" formControlName="name"
                placeholder="nom projet">
            </div>
            <!-- <div class="col-lg-3">
              <input type="text" name="nom" id="nom" formControlName="membre_name" class="form-control form-control-solid mb-3"
                placeholder="membre">
            </div> -->
            <div class="col-lg-6">
              <div class="mb-3 search-btn d-flex">
                <div class="align-items-center recherche">
                  <button type="submit" class="me-5" (click)="applyFilter()">
                    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                        fill="white" />
                    </svg>
                    Rechercher</button>
                </div>
                <div class="reset-icon cursor-pointer">
                  <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                </div>
  
              </div>
            </div>
          </div>
  
        </div>
  
        <!--begin::Card header-->
  
        <!--end::Card header-->
        <!--begin::Card body-->
  
        <!--end::Card body-->
      </div>
      <div class="card">
        <div class="card-body pt-0">
          <div class="title">
            <h4>Liste des Projets</h4>
          </div>
          <!--begin::Table-->
          <div class="table-responsive">
            <table class="table align-middle   fs-6 gy-5 mb-4" id="kt_customers_table">
              <thead>
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-125px"><span>ID</span></th>
                    <th class="min-w-125px"><span>Nom projet  <i (click)="sortData('name')" class="fa-solid fa-sort"></i></span></th>
                    <th class="min-w-125px"><span>Responsable  <i (click)="sortData('responsable_full_name')" class="fa-solid fa-sort"></i></span></th>
                    <th class="min-w-125px"><span>Salarié ou Consultant <i (click)="sortData('nbr_membres')" class="fa-solid fa-sort"></i></span></th>
                    <th class="min-w-125px"><span>Date Début <i (click)="sortData('start_date')" class="fa-solid fa-sort"></i></span></th>
                    <th class="min-w-125px"><span>Date Fin <i (click)="sortData('end_date')" class="fa-solid fa-sort"></i></span></th>
                    <th class="min-w-90px"><span>Action</span></th>
                </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
  
                <tr *ngFor="let p of listprojet;let i =index">
  
                    <td > <a class="first-td"  (click)="openModal(details_intervenant,p)">{{p.ID_project ?p.ID_project:'---' }}</a> </td>
                    <td><a class="first-td" (click)="openModal(details_intervenant,p)">{{p.name ? p.name :'---'}}</a></td>
                    <td> <span> {{p.responsable_full_name? p.responsable_full_name: '---' }}</span></td>
                    <td><span>{{p.nbr_membres ? p.nbr_membres  :'---'}}</span></td>
                    <td>
                     <span> {{!p.start_date ? '---' : p.start_date.split(' ')[0].split('-').reverse().join('/') }}</span></td>
                    <td>
                    <span>  {{!p.end_date ? '---' : p.end_date.split(' ')[0].split('-').reverse().join('/') }}    </span>
                     </td>
                  <td> 
                    <div class="seemore">
                        <a class="cursor-pointer" (click)="openModal(details_intervenant,p)"> Voir détail</a>
                      </div>
                </td>
                </tr>
              </tbody>
              <!--end::Table body-->
            </table>
            <div class="pagination mt-4" *ngIf="listprojet && listprojet.length">
              <app-pagination [data]="listprojet" [currentPageList]="currentPage" [lengthData]="totalItems" [endexPageList]="endIndex"
                (setPages)="getItems($event)">
              </app-pagination>
            </div>
  
  
            <div class="alert alert-warning text-center mt-5" role="alert"
              *ngIf="listprojet&&!listprojet.length ">
              <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
              <span class="empty-list">
                La liste est vide
              </span>
            </div>
          </div>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Content container-->
  </div>
  <ng-template #details_intervenant>
    <div class="modal-dialog-centered" id="details_intervenant" tabindex="-1" aria-hidden="true">
      <!--begin::Modal dialog-->
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Form-->
        <form class="form" action="#" id="kt_modal_add_customer_form" aria-disabled="true">
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_add_customer_header">
            <!--begin::Modal title-->
            <h2 class="">Détails Projet</h2>
            <!--end::Modal title-->
            <!--begin::Close-->
            <div id="kt_modal_add_customer_close" class="btn btn-icon btn-sm btn-active-icon-primary">
              <a class="cursor-pointer" (click)="closeModal()">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path d="M14 1L1 14" stroke="#716F6F" stroke-width="1.5" stroke-linecap="round" />
                  <path d="M1 1L14 14" stroke="#716F6F" stroke-width="1.5" stroke-linecap="round" />
                </svg></a>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->
          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div class=" me-n7 pe-7 " id="">
              <div class="row ">
                <!--begin::Input group-->
                <div class="col-4 fv-row mb-7 ">
                  <label class="d-flex align-items-center tools form-label mb-2">
                    <span class="">Nom projet</span>
                  </label>
                  <!--end::Label-->
                  <input [disabled]="true" [value]="selectedIntervenant?.name" type="text"
                    class="form-control form-control-solid" placeholder="nom" name="Nom" />
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 fw-semibold mb-2 tools">
                    <span class="">Responsable</span>
                  </label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [disabled]="true" [value]="selectedIntervenant?.responsable_full_name" type="text" class="form-control form-control-solid" placeholder="responsable" name="email"
                     />
                  <!--end::Input-->
                  
                </div>
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 tools fw-semibold mb-2">
                    <span class="">Salarié ou Consultant </span>
                  </label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [disabled]="true" [value]="selectedIntervenant?.nbr_membres" type="email"
                    class="form-control form-control-solid" placeholder="nbr_membres" name="email" />
                  <!--end::Input-->
                </div>
              </div>
  
              <div class="row">
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 tools fw-semibold mb-2">Date Début</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [disabled]="true" [value]="selectedIntervenant?.start_date" type="text"
                    class="form-control form-control-solid" placeholder="Date Début"  />
                  <!--end::Input-->
                </div>
                <div class="col-4 fv-row mb-7">
                  <!--begin::Label-->
                  <label class="fs-6 tools fw-semibold mb-2">Date Fin</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [disabled]="true" [value]="selectedIntervenant?.end_date" type="text"
                    class="form-control form-control-solid" placeholder="Date Fin"  />
                  <!--end::Input-->
                </div>
                
                <!--end::Input group-->
                <!--begin::Input group-->
  
              </div>
          
              <div class="row">
  
                <!-- <div class="col-4 fv-row mb-7">
                
                  <label class="fs-6 tools fw-semibold mb-2">Date début contrat</label>
              
                  <div class="position-relative d-flex align-items-center">
  
                 
                    <input [disabled]="true"
                      [value]="!selectedIntervenant?.contract_start_date?'':selectedIntervenant?.contract_start_date|formatDate"
                      type="text" placeholder="Date début contrat" class="form-control form-control-solid mb-3"
                      onblur="(this.type='text')">
                    
                  </div>
                 
                </div> -->
              </div>
  
  
  
  
            </div>
            <!--end::Scroll-->
          </div>
        </form>
      </div>
    </div>
  </ng-template>