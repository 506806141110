<div class="card mb-10">
    <div class="header-page">
        <div class="title">
            <h4>Abonnement actuel</h4>
        </div>
    </div>
    <!--begin::Card body-->
    <div class="card-body pt-0">
        <!--begin::Table-->
        <div class="table-responsive">
            <table class="table align-middle fs-6 gy-5 mb-4 " id="kt_customers_table">
                <thead>
                    <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                            <th class="min-w-125px"><span>Offre</span> </th>
                            <th class="min-w-125px"><span>Date début</span> </th>
                            <th class="min-w-125px"><span>Nombre d’intervenants</span> </th>
                            <th class="min-w-125px"><span>Nombre d’offre d’emploi</span></th>
                            <th class="min-w-125px"><span>Nombre de CV à télécharger</span></th>
                            <th class="min-w-125px"><span>Nombre de profils à exporter</span></th>
                            <th class="min-w-125px"><span>Status</span></th>
                            <th class="min-w-125px"><span>Action</span></th> 
                        </tr>
                </thead>
                <tbody class=" text-gray-600">
                    <tr *ngFor="let data of listAbonnement">

                        <td class="fw-bold">
                            <a class="first-td">{{data?.offer?.name?data?.offer?.name:'---'}}</a>
                        </td>

                        <td>
                            <span>{{!data?.offer?.start_date?'---':data?.offer?.start_date | formatDate}}</span>
                        </td>
                        <td>
                            <span>{{data?.offer?.nbr_intervenant?data?.offer?.nbr_intervenant:'---'}} </span>
                        </td>
                        <td>
                            <span>{{data?.offer?.nbr_job_offers?data?.offer?.nbr_job_offers:'---'}} </span>
                        </td>
                        <td>
                            <span>{{data?.offer?.nbr_cv_to_upload?data?.offer?.nbr_cv_to_upload:'---'}} </span>
                        </td>
                        <td>
                            <span>{{data?.offer?.nbr_profiles_to_export?data?.offer?.nbr_profiles_to_export:'---'}} </span>
                        </td>
                        
                        <!-- <td>
                            <a class="cursor-pointer" (click)="exportInvoice(data.facture_id)"><i class="fa-solid fa-cloud-arrow-down"
                                    style="color:#265D91;font-size: 20px;"></i></a>
                        </td> -->
                        <td>
                            <span class="badge actif" *ngIf="data.active==1"> <i
                                class="fa-solid fa-circle"></i> Activer</span>
                        <span class="badge desactif" *ngIf="data.active==2"> <i
                                class="fa-solid fa-circle"></i> Désactiver</span>
                        </td>
                        <td>
                            <button class="remplir" (click)="navigateToInvoiceHistory(data.id,data.stripe_sub_id)">Liste des factures</button>
                        </td>
                     
                    </tr>


                </tbody>
                <!--end::Table body-->
            </table>
        </div>
       
        <div class="alert alert-warning text-center mt-5" role="alert"  *ngIf="!listAbonnement?.length">
            <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
            <span class="empty-list">
             La liste est vide
         </span>
         </div>
        <div class="pagination" *ngIf="listAbonnement && listAbonnement?.length">
            <app-pagination [data]="listAbonnement" [currentPageList]="currentPage" [lengthData]="totalItems" (setPages)="getItems($event)">
            </app-pagination>
        </div>
        <!--end::Table-->
    </div>
    <!--end::Card body-->
</div>