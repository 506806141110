import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from 'rxjs';
import { serverError } from '../../../../../core/models/messages';
import { MailingService } from '../../../../../core/services/mailing/mailing.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mailing-contact',
  templateUrl: './mailing-contact.component.html',
  styleUrls: ['./mailing-contact.component.css'],
})
export class MailingContactComponent {
  MailingList: any;
  private unsubscribeAll: Subject<void> = new Subject();
  mailingForm: FormGroup = this.createForm();
  idMail!: number;
  modalRef?: BsModalRef;
  searchInputSubject = new Subject<string>();
  mode: string = 'create_mailing';
  searchElement: string = '';
  clickCount: number = 0;
  constructor(
    private activatedRoute: ActivatedRoute,
    private mailingService: MailingService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: BsModalService
  ) {}
  ngOnInit() {
    this.getListMailing();
    
  }
  getListMailing() {
    this.spinner.show();
    this.mailingService
      .getListMailing()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.MailingList = res.data.items.data;
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  createForm(mode?: string, data?: any) {
    return this.formBuilder.group({
      contactName: [data ? data.contactName : null, Validators.required],
      contactMail: [data ? data.contactMail : '', [Validators.email]],
    });
  }
  confirmer() {
    if (this.mode == 'create_mailing') {
      this.addMailling();
    } else {
      this.updateMailling();
    }
  }
  addMailling() {
    this.mailingService.addMailing(this.mailingForm.value).subscribe({
      next: (res: any) => {
        console.log('res', res);
        this.toastr.success('L’adresse mail a été ajouté avec succès.');
        this.getListMailing();
        this.closeModal();
      },
      error: (err) => {
        if (err.error?.message['contactName']) {
          this.toastr.error('Type de contact exist déjà');
        } else this.toastr.error(serverError);
      },
    });
  }
  updateMailling() {
    this.mailingService
      .updateMailing(this.mailingForm.value, this.idMail)
      .subscribe({
        next: (res: any) => {
          console.log('res', res);
          this.toastr.success('Mail modifier avec succès.');
          this.getListMailing();
          this.closeModal();
        },
        error: () => {
          this.toastr.error(serverError);
        },
      });
  }
  deleteMailing(id: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      iconColor: '#ff0000',
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.mailingService.deleteMailing(id).subscribe((res: any) => {
          console.log('responce', res);
          Swal.fire('Supprimé !', 'mail a été supprimé.', 'success');
          this.getListMailing();
        });
      }
    });
  }
  applyFilter() {
    if (this.searchElement != '' || this.clickCount) {
      this.clickCount++;
     
      const data = {
        contactName:this. searchElement.trim().toLowerCase(),
      };
      this.spinner.show();
      this.mailingService.searchListMailing(data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            console.log("res",res)
            this.MailingList = res.data.data;
            this.spinner.hide();
          },
          error: () => {
            this.spinner.hide();
            this.toastr.error(serverError);
          },
        });
    }
  }
  
  
  closeModal() {
    this.modalRef!.hide();
    this.mailingForm.reset();
    this.mailingForm = this.createForm();
  }
  openModal(template: TemplateRef<any>, mode: string, data?: any) {
    this.mailingForm = this.createForm(mode, data);
    this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
    this.mode = mode;
    this.getListMailing();
    if (mode == 'update_mailing') {
      this.idMail = data?.id;
    }
  }
}
