import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationGuard } from './core/guards/auth/auth.guard';
import { mainGuard } from './core/guards/main/main.guard';
import { NetworkStatusComponent } from './main/network-status/network-status.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [authenticationGuard.canActivate],
  },
  {
    path: 'acceuil',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./main/main.module').then((m) => m.MainModule),
        canActivate: [mainGuard.canActivate],
      },
    ],
  },

  { path: 'reconnect', component: NetworkStatusComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
