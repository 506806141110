export const environment = {
  production: true,
  baseUrlGaleryCompany:
    'https://api.test.wegestu.com/api/Company/file/gallery_company/',
  baseUrl: 'https://api.test.wegestu.com',
  baseUrlGalery: 'https://api.test.wegestu.com/api/User/file/gallery_users/',
  baseUrlGaleryy: 'https://api.test.wegestu.com/api/Company/file/gallery_company/',
  baseUrlGaleryCv: 'https://api.test.wegestu.com/api/User/file/gallery_cvs/',
  CLIENT_ID: 1,
  CLIENT_SECRET: 'X4N7aSQ6DEfMuAmndhRn4eOwhXkd0AFGd33We5hj',
};
