import { Injectable } from '@angular/core';
import { Country } from 'country-state-city';


@Injectable({
  providedIn: 'root'
})
export class PaysService {

  translations: { [key: string]: string } = {
    Afghanistan: 'Afghanistan',
    'South Africa': 'Afrique du Sud',
    Albania: 'Albanie',
    Algeria: 'Algérie',
    Germany: 'Allemagne',
    Andorra: 'Andorre',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctique',
    'Antigua and Barbuda': 'Antigua-et-Barbuda',
    'Saudi Arabia': 'Arabie saoudite',
    Argentina: 'Argentine',
    Armenia: 'Arménie',
    Aruba: 'Aruba',
    Australia: 'Australie',
    Austria: 'Autriche',
    Azerbaijan: 'Azerbaïdjan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahreïn',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbade',
    Belgium: 'Belgique',
    Belize: 'Belize',
    Benin: 'Bénin',
    Bermuda: 'Bermudes',
    Bhutan: 'Bhoutan',
    Belarus: 'Biélorussie',
    Bolivia: 'Bolivie',
    'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
    Botswana: 'Botswana',
    Brazil: 'Brésil',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgarie',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    'Cape Verde': 'Cabo Verde',
    Cambodia: 'Cambodge',
    Cameroon: 'Cameroun',
    Canada: 'Canada',
    Chile: 'Chili',
    China: 'Chine',
    Cyprus: 'Chypre',
    Colombia: 'Colombie',
    Comoros: 'Comores',
    'Congo - Brazzaville': 'Congo-Brazzaville',
    'Congo - Kinshasa': 'Congo-Kinshasa',
    'North Korea': 'Corée du Nord',
    'South Korea': 'Corée du Sud',
    'Costa Rica': 'Costa Rica',
    'Ivory Coast': 'Côte d’Ivoire',
    Croatia: 'Croatie',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Denmark: 'Danemark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominique',
    'Dominican Republic': 'République dominicaine',
    Ecuador: 'Équateur',
    Egypt: 'Égypte',
    'United Arab Emirates': 'Émirats arabes unis',
    Eritrea: 'Érythrée',
    Spain: 'Espagne',
    Eswatini: 'Eswatini',
    Estonia: 'Estonie',
    'United States': 'États-Unis',
    Ethiopia: 'Éthiopie',
    Fiji: 'Fidji',
    Finland: 'Finlande',
    France: 'France',
    Gabon: 'Gabon',
    Gambia: 'Gambie',
    Georgia: 'Géorgie',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Grèce',
    Grenada: 'Grenade',
    Greenland: 'Groenland',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernesey',
    Guinea: 'Guinée',
    'Guinea-Bissau': 'Guinée-Bissau',
    'Equatorial Guinea': 'Guinée équatoriale',
    Guyana: 'Guyana',
    'French Guiana': 'Guyane française',
    Haiti: 'Haïti',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hongrie',
    Iceland: 'Islande',
    India: 'Inde',
    Indonesia: 'Indonésie',
    Iraq: 'Irak',
    Iran: 'Iran',
    Ireland: 'Irlande',
    'Isle of Man': 'Île de Man',
    Israel: 'Israël',
    Italy: 'Italie',
    Jamaica: 'Jamaïque',
    Japan: 'Japon',
    Jersey: 'Jersey',
    Jordan: 'Jordanie',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kuwait: 'Koweït',
    Kyrgyzstan: 'Kirghizistan',
    Laos: 'Laos',
    Latvia: 'Lettonie',
    Lebanon: 'Liban',
    Lesotho: 'Lesotho',
    Liberia: 'Libéria',
    Libya: 'Libye',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lituanie',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    'North Macedonia': 'Macédoine du Nord',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaisie',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malte',
    'Marshall Islands': 'Îles Marshall',
    Martinique: 'Martinique',
    Mauritania: 'Mauritanie',
    Mauritius: 'Maurice',
    Mayotte: 'Mayotte',
    Mexico: 'Mexique',
    Micronesia: 'Micronésie',
    Moldova: 'Moldavie',
    Monaco: 'Monaco',
    Mongolia: 'Mongolie',
    Montenegro: 'Monténégro',
    Montserrat: 'Montserrat',
    Morocco: 'Maroc',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibie',
    Nauru: 'Nauru',
    Nepal: 'Népal',
    Netherlands: 'Pays-Bas',
    'New Caledonia': 'Nouvelle-Calédonie',
    'New Zealand': 'Nouvelle-Zélande',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    'Norfolk Island': 'Île Norfolk',
    'Northern Mariana Islands': 'Îles Mariannes du Nord',
    Norway: 'Norvège',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palaos',
    Palestine: 'Palestine',
    Panama: 'Panama',
    'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
    Paraguay: 'Paraguay',
    Peru: 'Pérou',
    Philippines: 'Philippines',
    'Pitcairn Islands': 'Îles Pitcairn',
    Poland: 'Pologne',
    Portugal: 'Portugal',
    'Puerto Rico': 'Porto Rico',
    Qatar: 'Qatar',
    Romania: 'Roumanie',
    Russia: 'Russie',
    Rwanda: 'Rwanda',
    Réunion: 'Réunion',
    'Saint Barthélemy': 'Saint-Barthélemy',
    'Saint Helena': 'Sainte-Hélène',
    'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
    'Saint Lucia': 'Sainte-Lucie',
    'Saint Martin': 'Saint-Martin',
    'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
    'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'Saint-Marin',
    'Sao Tome and Principe': 'Sao Tomé-et-Principe',
    Senegal: 'Sénégal',
    Serbia: 'Serbie',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapour',
    'Sint Maarten': 'Saint-Martin',
    Slovakia: 'Slovaquie',
    Slovenia: 'Slovénie',
    'Solomon Islands': 'Îles Salomon',
    Somalia: 'Somalie',
    'South Georgia and the South Sandwich Islands':
      'Géorgie du Sud-et-les Îles Sandwich du Sud',
    'South Sudan': 'Soudan du Sud',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Soudan',
    Suriname: 'Suriname',
    'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
    Swaziland: 'Eswatini',
    Sweden: 'Suède',
    Switzerland: 'Suisse',
    Syria: 'Syrie',
    Taiwan: 'Taïwan',
    Tajikistan: 'Tadjikistan',
    Tanzania: 'Tanzanie',
    Thailand: 'Thaïlande',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinité-et-Tobago',
    Tunisia: 'Tunisie',
    Turkey: 'Turquie',
    Turkmenistan: 'Turkménistan',
    Tuvalu: 'Tuvalu',
    Uganda: 'Ouganda',
    Ukraine: 'Ukraine',
    'United Kingdom': 'Royaume-Uni',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Ouzbékistan',
    Vanuatu: 'Vanuatu',
    'Vatican City': 'Vatican',
    Venezuela: 'Venezuela',
    Vietnam: 'Viêt Nam',
    'Western Sahara': 'Sahara occidental',
    Yemen: 'Yémen',
    Zambia: 'Zambie',
    Zimbabwe: 'Zimbabwe',
  };

  constructor() {}

  translateCountryName(name: string): string {
    return this.translations[name] || name;
  }

  getAllCountries() {
    return Country.getAllCountries()
      .filter((country) => this.translations.hasOwnProperty(country.name))
      .map((country) => ({
        ...country,
        name: this.translateCountryName(country.name),
      }));
  }
}
