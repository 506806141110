export class CJobOffer{
  id:string|undefined
  client_id:string|undefined
  name:string|undefined
  score: string | undefined;
  test: string | undefined;
  start_date:string|undefined
  end_date:string|undefined
  archived:string|undefined
  state:string|undefined
  desired_salary:string|undefined
  years_of_experience:string|undefined
  availability:string|undefined
  tjm:string|undefined
  skills:string|undefined
  esn_name:string|undefined
  client_final_name:string|undefined
  created_at:string|undefined
  client_final:{name:string,logo:string} |undefined
  skills_job:any
  esn_id:string|undefined
  esn:{name:string,logo:string}|undefined
  tjm_range:string|undefined
  desired_salary_range:string|undefined
  year_experience:string|undefined
  poste:string|undefined
  salaire:string|undefined
  salaire_range:string|undefined
  ID_jobOffer:string|undefined
  ID_jobRequest:string|undefined
  company_nature:string|undefined
  experience_range:string|undefined
  contract_type!:any[]
  post_type:string|undefined
  skillsAnd:string|undefined
  skillsOr:string|undefined
  country:string|undefined
  city:string|undefined
  profile_abroad:string|undefined
  passeport_talent:string|undefined
  logo:string|undefined
  salary:string|undefined
  search:string|undefined
  constructor(data?:CJobOffer){
    this.id = data && data.id ||""
    this.client_id = data && data.client_id ||""
    this.name = data && data.name ||""
    this.start_date = data && data.start_date ||""
    this.end_date = data && data.end_date ||""
    this.archived = data && data.archived ||""
    this.state = data && data.state ||""
    this.desired_salary = data && data.desired_salary ||""
    this.years_of_experience = data && data.years_of_experience ||""
    this.availability = data && data.availability ||""
    this.tjm = data && data.tjm ||""
    this.skills = data && data.skills ||""
    this.esn_name = data && data.esn_name ||""
    this.client_final_name = data && data.client_final_name ||""
    this.created_at = data && data.created_at ||""
    this.skills_job = data && data.skills_job ||[]
    this.esn_id = data && data.esn_id ||""
    this.tjm_range = data && data.tjm_range ||""
    this.desired_salary_range = data && data.desired_salary_range ||""
    this.year_experience = data && data.year_experience ||""
    this.poste = data && data.poste ||""
    this.salaire = data && data.salaire ||""
    this.salaire_range = data && data.salaire_range ||""

  }
}
export interface IRequstJob{
  id: string | undefined;
  name: string | undefined;
  score: string | undefined;
  test: string | undefined;
post_type: string | undefined;
city: string | undefined;
country : string | undefined; // Mettre à jour le type en number
availability: string | undefined;
salary: string | undefined;
years_of_experience : string | undefined;
contract_type: string | undefined;
tjm: string | undefined;
description : string ;
skills:string
start_date:string
client_final:{name:string}
esn:{name:string,skills:string}
logo:string|undefined
profile_abroad:string|undefined
passeport_talent:string|undefined
}