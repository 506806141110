import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { CandidatRoutingModule } from './candidat-routing.module';
import { ProfilCandidatComponent } from './components/profil-candidat/profil-candidat.component';
import { ListCandidatComponent } from './components/list-candidat/list-candidat.component';
import { FieldErrorModule } from '../../shared/components/shared-field-error/field-error.module';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { CustomMatPaginatorInt } from '../../core/models/paginator';
import { MatButtonModule } from '@angular/material/button';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PipesModuleModule } from '../../core/pipes/pipes-module.module';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CvCandidatComponent } from './components/cv-candidat/cv-candidat.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { RatingModule } from 'ngx-bootstrap/rating';
import { SearchFilterPipe } from './pipes/SearchFilterPipe';
import { CalendarModule } from 'primeng/calendar';
import { UpdateCandidatComponent } from './components/update-candidat/update-candidat.component';
import { QuillModule } from 'ngx-quill';
import localeFr from '@angular/common/locales/fr';

@NgModule({
  declarations: [
    ListCandidatComponent,
    ProfilCandidatComponent,
    UpdateCandidatComponent,
    ChangePasswordComponent,
    CvCandidatComponent,
    SearchFilterPipe,
  ],
  imports: [
    CommonModule,
    CandidatRoutingModule,
    FieldErrorModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,
    NgxSpinnerModule,
    PipesModuleModule,
    PaginationModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    CalendarModule,
    RatingModule.forRoot(),
    QuillModule.forRoot({
      modules:{
        syntax:true,
        toolbar: [
         ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
         ['blockquote', 'code-block'],
         [{ 'header': 1 }, { 'header': 2 }],               // custom button values
         [{ 'list': 'ordered'}, { 'list': 'bullet' }],
         [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
         [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
         [{ 'direction': 'rtl' }],                         // text direction
         [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
         [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
         [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
         [{ 'font': [] }],
         [{ 'align': [] }],
         ['clean'],                                         // remove formatting button
       ]
      }
   })

  ],
  providers:[{ provide: MatPaginatorIntl, useClass: CustomMatPaginatorInt },DatePipe, { provide: LOCALE_ID, useValue: 'fr-FR' }]
})
export class CandidatModule {
  constructor() {
    registerLocaleData(localeFr);
  }
}
