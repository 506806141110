import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedRole,
  existedRole,
  serverError,
} from '../../../../../../core/models/messages';
import { PermissionService } from '../../../../../../core/services/permission.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css'],
})
export class AddRoleComponent {
  listPermissionRole: any = [];
  listPermissions: any = [];
  nameRole: string = '';
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private permissionService: PermissionService,
    private router: Router
  ) {}
  ngOnInit() {
    this.getAllPermissions();
  }
  getAllPermissions() {
    this.spinner.show();
    this.permissionService
      .getListPermissionsRole()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          res.data.map((el: any) => {
            if (!el.name.includes('family')) {
              this.listPermissions.push(el);
            }
          });
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  permission_ids: any = [];
  getPermissionIds() {
    for (const iterator of this.listPermissionRole) {
      this.permission_ids.push(iterator.id);
    }
  }
  addRole() {
    this.getPermissionIds();
    this.spinner.show();
    let data = {
      name: this.nameRole,
      permission_ids: this.permission_ids,
    };
    this.permissionService
      .addRole(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.toastr.success(addedRole);
            this.router.navigate(['/acceuil/parametres/parametres-list-role']);
            this.clearData();
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.clearData();
          if (err?.error) {
            if (err.error?.message && err.error.message['name'])
              this.toastr.info(existedRole);
          }
        },
      });
  }
  clearData() {
    this.permission_ids = [];
    this.listPermissionRole = [];
    this.nameRole = '';
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    console.log('todo', this.listPermissionRole);
    console.log('donedonedone', this.listPermissions);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
