import { EventEmitter, Injectable } from '@angular/core';
import { ApiResponse } from '../../models/response-api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CrudService } from '../crud/crud.service';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CandidatService extends CrudService<ApiResponse<any>, number> {

  profileUpdated = new BehaviorSubject<any | null>(null);
  private _cachedProfile: any;
  private _cachedId: any;
  private searchResultsSubject = new BehaviorSubject<any[]>([]);
  searchResults$ = this.searchResultsSubject.asObservable();

  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get list candidats */
  getListCandidat(data: {
    per_page?: number;
    page?: number;
    sort?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/User/getCandidatesConsultants?per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
  }
  /* retest */
  reTest(data: { user: number[] | undefined; link_test?: string }) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Test/RetestResponse`,
      data
    );
  }
  /* retest */
  reValid(data: { candidate_ids: number[] }) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/User/ReValidate`,
      data
    );
  }
  addCandidat(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/User/UnitaryAddCandidate`,
      data
    );
  }
  setSearchResults(results: any[]) {
    this.searchResultsSubject.next(results);
  }
  searchCandidat(data: any) {
    let tabs = this.findDoubleQuotes(data.search);

    if (tabs.length <= 2) data.search = '"' + data.search + '"';
    console.log(
      'data of searchCandidat',
      'count = ' + tabs.length,
      data.search
    );
    console.log('url search', this._base + `/User/searchCandidate`, data);
    return this._http.post<ApiResponse<any>>(
      this._base + `/User/searchCandidate`,
      data
    );
  }
  downloadFile(url: string) {
    return this._http.get(url, { responseType: 'blob' });
  }
  downloadreponse(url: string) {
    return this._http.get(url, { responseType: 'blob' });
  }
  downloadFileZip(url: string): Observable<Blob> {
    return this._http.get(url, { responseType: 'blob' });
  }
  getPdfContent(pdfUrl: string): Observable<Blob> {
    return this._http.get(pdfUrl, { responseType: 'blob' });
  }
  /*get candidate  by id */
  getUserById(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/ConsultantEsn/getConsultantDetails?user_id=${id}`
    );
  }

  // getUserById(id:number){
  //   return this._http.get<ApiResponse<any>>(this._base+`/User/userProfile?user_id=${id}`);
  // }

  /*disassociate Consultant*/
  disassociateConsultant(data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + `/User/dissociateCandidateFromCompany`,
      data
    );
  }

  downloadPDF(url: string): Observable<Blob> {
    const options = { responseType: 'blob' as 'json' };
    return this._http.get<Blob>(url, options).pipe(map((res:any) => new Blob([res], { type: 'application/pdf' })));
 }
 /* get candidate skills */
 getSkillsCandidate(id:number){
  return this._http.get<ApiResponse<any>>(this._base+`/Skill/getSkillsByCandidate/${id}`);
 }
 /* update profil Candidate  */
 updateCandidate(id:number, data: any): Observable<ApiResponse<any>> {
  return this._http.post<ApiResponse<any>>(this._base +`/User/editProfileCandidat?user_id=${id}`, data).pipe(
    tap(() => {
      this._cachedProfile = null;
      this._cachedId = null;
      this.profileUpdated.next(null);
    })
  );
}
updateCandidateSkills(id:number, data: any): Observable<ApiResponse<any>> {
  return this._http.post<ApiResponse<any>>(this._base +`/User/updateUserSkills?user_id=${id}`, data);
}
 /* add skills for Candidate  */
 addSkillsForCandidate(data: any): Observable<ApiResponse<any>> {
  return this._http.post<ApiResponse<any>>(this._base +`/Skill/candidateAddSkill`, data);
}
addStarsForSkillCandidate(data: any): Observable<ApiResponse<any>> {
  return this._http.post<ApiResponse<any>>(this._base + `/User/addSkills`, data);
}

  /*delete all skills */
  deleteAllSkills(id: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/Skill/candidateDeleteAllSkills?id=${id}`
    );
  }
  /*delete on skill */

  deleteOnSkill(id: number, idSubCatgery: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base +
        `/Skill/candidateDeleteOneSkill?id=${id}&sub_category_id=${idSubCatgery}`
    );
  }
  /* update image_url Candidate  */
  updateImageUrlCandidate(data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + `/User/uploadPhoto`,
      data
    );
  }

 /* update image_url Candidate  */
 updateCVCandidate(data: any): Observable<ApiResponse<any>> {
  return this._http.post<ApiResponse<any>>(this._base +`/User/uploadCv`, data);
}

getSkills(): Observable<ApiResponse<any>> {
  return this._http.get<ApiResponse<any>>(
    this._base + `/SubCategory/getListSubCateg`
  );
}


getListcandidatureinterne(data: {
  id: number;
  per_page?: number;
  page?: number;
  sort?: number;
  sortAttribute?: string;
}) {
  let sort = '';
  if (data?.sort)
    sort = `&sort=${data.sort}&sortAttribute=${data.sortAttribute}`;
  return this._http.get<ApiResponse<any>>(
    `${this._base}/CompanyApply/getListCandidatesByJobRequest/${data.id}?per_page=${data?.per_page}&page=${data?.page}${sort}`
  );
}

searchCandidatureinterne(
  id: number,
  data: any
): Observable<ApiResponse<any>> {
  let tabs=this.findDoubleQuotes(data.search);
   
  if(tabs.length<=2)
  data.search='"' + data.search + '"';
  console.log("data of searchCandidat","count = "+tabs.length,data.search)
  console.log("url search",this._base+`/User/searchCandidate`,data)
  return this._http
    .post<ApiResponse<any>>(
      this._base +
        `/CompanyApply/searchInListCandidatesByJobRequest/${id}`,
      data
    )
    .pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return res;
        }
      })
    );
}
  /* add skills Candidate  */
  addSkillCandidate(data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + '/Skill/create',
      data
    );
  }
  /* get elment of header table list candidat */
  getHeaderElements(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/UserPageActeur/getPagesWithSubPagesForUser?role_id=2&interface=2&id=${id}`
    );
  }
  /* get elment of header table list candidat */
  changeDelectedElementHeader(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base +
        `/UserPageActeur/addRemoveSubPageToUser?role_id=2&interface=2`,
      data
    );
  }

 /*add Bulk Candidates*/
 addBulkCandidates(data:any): Observable<ApiResponse<any>> {
  return this._http.post<ApiResponse<any>>(this._base +`/User/addBulkCandidates`,data);
}
/*get bulk candidate */
getbulkCandidate(data:any){
  return this._http.get(this._base+`/User/export/${data}`, {
    responseType: 'arraybuffer',
  });
}
exportCandidat(file:any,data:any){
  let ids=''
  for (const iterator of data) {
    ids+= `users_ids[]=${iterator}&`
  }
  ids = ids.slice(0, -1);
  return this._http.get(this._base+`/User/exportExcel?${ids}`, {
    responseType: 'arraybuffer',
  });
}
exportCvWegestCandidatList( data:any){
  return this._http.get(this._base+`/User/download-cv/${data}`, {
    responseType: 'arraybuffer',
  });
}
exportCvWegestCandidat(file:any, data:any){
  return this._http.get(this._base+`/User/download-cv/${data}`, {
    responseType: 'arraybuffer',
  });
}
exportCvWegestWordCandidat(file:any, data:any){
  return this._http.get(this._base+`/User/cv/${data}`, {
    responseType: 'arraybuffer',
  });
}
exportCvAnonymePdf(file:any, data:any){
  return this._http.get(this._base+`/User/cvPdfAnonyme/${data}`, {
    responseType: 'arraybuffer',
  });
}
exportCvAnonymeWord(file:any, data:any){
  return this._http.get(this._base+`/User/cvWordAnonyme/${data}`, {
    responseType: 'arraybuffer',
  });
}

/**
 * Exports the ESN candidate's CV as a PDF.
 * Sends an HTTP GET request to fetch the candidate's CV data as a binary (array buffer) for file download.
 *
 * @param {any} file - Optional file object, not used in this implementation but included for future extensions.
 * @param {any} data - The candidate ID to be included in the request URL for fetching the CV.
 * @returns {Observable<any>} - Observable that emits the binary data (array buffer) of the CV file.
 */
exportCvESNCandidate(file: any, data: any) {
  return this._http.get(this._base + `/User/cvEsn/${data}`, {
    responseType: 'arraybuffer',
  });
}

/**
 * Exports the anonymous ESN candidate's CV as a PDF.
 * Sends an HTTP GET request to fetch the anonymous version of the candidate's CV data as a binary (array buffer) for file download.
 *
 * @param {any} file - Optional file object, not used in this implementation but included for future extensions.
 * @param {any} data - The candidate ID to be included in the request URL for fetching the anonymous CV.
 * @returns {Observable<any>} - Observable that emits the binary data (array buffer) of the anonymous CV file.
 */
exportCvAnonymousESNCandidate(file: any, data: any) {
  return this._http.get(this._base + `/User/cvEsnAnonyme/${data}`, {
    responseType: 'arraybuffer',
  });
}

downloadcsvfileAddBulkCandidat() {
  const url = `${this._base}/User/exportEmptyAddBulkCandidat`;
  console.log('url', url);
  return this._http.get(url, {
    responseType: 'blob', // Changer 'arraybuffer' en 'blob'
    // observe: 'response', // Permet d'obtenir la réponse complète incluant les en-têtes
  });
}



/******************************get profil user ****************************/
getById(id:number){
  if (id !== this._cachedId) {
    console.log("url",this._base+`/User/userProfile?user_id=${id}`,{observe:'events'})
  return this._http.get<ApiResponse<any>>(this._base+`/User/userProfile?user_id=${id}`,{observe:'events'}).pipe(
    tap((profile) => {
      this._cachedProfile = profile;
      this._cachedId = id;
    })
  );
} else {
  return new Observable(observer => {
    observer.next(this._cachedProfile);
    observer.complete();
  });
}
}

  getlisteadmin(data: {
    per_page?: number;
    page?: number;
    sort?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data.sortAttribute}`;
    return this._http.get<
      ApiResponse<any>
    >(`${this._base}/User/getUsersByRole?roles[]=1&roles[]=2&
 per_page=${data?.per_page}&page=${data?.page}${sort}`);
  }
  /* register */
  register(body: any) {
    console.log('hello', this._base + '/User/UnitaryAddAdmin', body);
    return this._http.post<ApiResponse<any>>(
      this._base + '/User/UnitaryAddAdmin',
      body
    );
  }
  findDoubleQuotes(str: string): number[] {
    const indices: number[] = [];
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '"') {
        indices.push(i);
      }
    }
    return indices;
}
setAccount(account: any): void {
  this.profileUpdated.next(account);
}

getAccount(): Observable<any | null> {
  return this.profileUpdated.asObservable();
}


 /*get job offre candidature  by id */
 getappeloffrecandidatureById(company_apply_id:number): Observable<ApiResponse<any>>{
  return this._http.get<ApiResponse<any>>(this._base+`/CompanyApply/getDetailsCandidatureJobRequest?company_apply_id=${company_apply_id}`);
}
 /*get skills appel d'offre*/
 getskillsappeloffre(job_request_id:number): Observable<ApiResponse<any>>{
  return this._http.get<ApiResponse<any>>(this._base+`/SkillsJob/getByJobRequest?job_request_id=${job_request_id}`);
}
}
