import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { serverError } from '../../../../core/models/messages';
import { TenderService } from '../../../../core/services/tender/tender.service';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-details-offer',
  templateUrl: './details-offer.component.html',
  styleUrls: ['./details-offer.component.css'],
})
export class DetailsOfferComponent {
  colorsHeader:string[] = ['#FBF2E9', '#E8F6FC', '#FDEFEE', '#FFF3D7', '#FBF2E9'];
/* number */
idRequestJob!:number
/* object */
jobRequest!:any
/* string */
url:string=environment.baseUrl+'/api'
jobSkills: any
safeDescription!: SafeHtml;
/* unsubscription */
private unsubscribeAll: Subject<void> = new Subject()
constructor(private activatedRoute:ActivatedRoute,private spinner :NgxSpinnerService,private jobRequestService:TenderService ,private toastrService:ToastrService,private router: Router,private sanitizer: DomSanitizer){}
ngOnInit() {
this.getIdRequestJob()
this.getSkillsByJobOffer()
}
 /* get request job id from route */
getIdRequestJob() {
this.activatedRoute.paramMap.subscribe({
  next: (params: any) => {
    this.idRequestJob = params.params['id']
    this.getRequestJob()
  }
})
}
/* get request job by id */
contract:any
contract1:any
getRequestJob(){
this.spinner.show()
this.jobRequestService.getJobOfferById(this.idRequestJob).subscribe({
next: (res) => {
  this.spinner.hide()
  this.jobRequest = res.data[0]
  this.updateDescription(this.jobRequest.description);
  console.log("contrat",this.jobRequest)
  this.contract=res.data[0].contract_type[0]
  this.contract1=res.data[0].contract_type[1]
  console.log("contrat",this.contract)
  console.log("contrat1",this.contract1)
},
error: () => {
  this.spinner.hide()
  this.toastrService.error(serverError)
}
})
} 

updateDescription(description: string) {
  this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(description);
}
goToListCandidature(id: string | undefined,offre:string| undefined) {
  this.router.navigate(['/acceuil/offre-emploi/candidatures', { id: id ,offre:offre}])
}

getSkillsByJobOffer() {
  this.spinner.show()
  let job_offer_id = this.idRequestJob
  this.jobRequestService.getskillsByJobOffer(job_offer_id).subscribe({
    next: (res: any) => {
      this.spinner.hide()
      this.jobSkills = res.data
      console.log("skills", this.jobSkills)
    },
    error: () => {
      this.spinner.hide()
      this.toastrService.error(serverError)
    }
  })
}

/* unsubscribe from api */
ngOnDestroy() {
this.unsubscribeAll.next();
this.unsubscribeAll.complete();
}
}
