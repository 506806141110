<div id="kt_app_content" class="app-content flex-column-fluid">
    <div id="kt_app_content_container" class="app-container container-fluid">

        <div class="card mb-5">
            <div class="container">
                <div class="title">
                    <h4>Recherche</h4>
                </div>

                <div class="d-column align-items-center tools mb-5">
                    <div class="row mb-3 pos">
                        <div class="col-lg-4 p-5">
                            <input type="text" class="form-control form-control-solid ps-5" name="société" value=""
                                placeholder="Nom ESN" />
                        </div>
                        <div class="col-lg-4 p-5">
                            <input type="text" class="form-control form-control-solid ps-5" name="nom" value=""
                                placeholder="Nom Pack" />
                        </div>
                        <div class="col-lg-4 p-5"></div>
                    </div>
                    <div class="row mb-3 pos">
                      <div class="col-lg-4 p-5 ">
                        <select class="form-select" data-control="select2" data-placeholder=""
                            data-hide-search="true">
                            <option value="Pays" selected="selected">Pays </option>
                            <option value="3">Tunisie</option>
                            <option value="1">France</option>
                        </select>
                    </div>
                    <div class="col-lg-4 p-5">
                        <input type="date" class="form-control form-control-solid ps-5" name="nom" value=""
                            placeholder="jj/mm/aaaa" />

                    </div>

                    <div class="d-flex align-items-center recherche col-lg-4 ">
                        <button type="submit" class="btn btn-primary  me-5"> <svg width="15" height="15"
                                viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                    fill="white" />
                            </svg>
                            Rechercher</button>
                    </div>
                    </div>
                </div>


            </div>
        </div>

</div>
<div id="kt_app_content_container" class="app-container container-fluid">


  <div class="card mb-5">
      <div class="container-fluid">
      <div class=" item-header mb-5">
          <div class="title">
              <h4>Paiement</h4>
          </div>
          <div class="">
              <button type="button" class="btn btn-primary ajouter" (click)="navigateToAddOffer()">+ Ajouter abonnement</button>
          </div>
      </div> <!--begin::Card body-->
      <div class="card-body pt-0">
          <!--begin::Table-->
          <div class="table-responsive-xxl">
              <table class="table align-middle   mb-4  fs-6 gy-5" id="kt_customers_table">
                  <thead>
                      <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                          <th class="min-w-125px"><span>Nom ESN</span></th>
                          <th class="min-w-125px"><span>Pack</span></th>
                          <th class="min-w-125px"><span>IBAN</span></th>
                          <th class="min-w-125px"><span>Code BIC</span> </th>
                          <th class="min-w-125px"><span>Pays</span></th>
                          <th class="min-w-125px"><span>Date de paiement</span></th>
                          <th class="min-w-125px"><span>Facture</span></th>
                          <th class="min-w-70px"><span>Actions</span></th>
                      </tr>
                  </thead>
                  <tbody class="fw-semibold text-gray-600">
                      <tr>
                          <td>
                              <a href="/" class="text-gray-800 text-hover-primary mb-1"> ESN 1</a>
                          </td>
                          <td>
                              <a href="#" class="text-gray-600 text-hover-primary mb-1">GOLD</a>
                          </td>
                          <td> FR12354689565626565265</td>
                          <td>0000</td>
                          <td>France</td>
                          <td>12/08/2023</td>
                          <td>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                  viewBox="0 0 16 17" fill="none">
                                  <path
                                      d="M2.93333 16.1172H13.0667C13.4519 16.1172 13.8333 16.0392 14.1892 15.8876C14.5451 15.7359 14.8685 15.5137 15.1408 15.2335C15.4132 14.9534 15.6293 14.6208 15.7767 14.2547C15.9241 13.8887 16 13.4963 16 13.1001V8.98581C16 8.69483 15.8876 8.41577 15.6876 8.21002C15.4875 8.00426 15.2162 7.88867 14.9333 7.88867C14.6504 7.88867 14.3791 8.00426 14.1791 8.21002C13.979 8.41577 13.8667 8.69483 13.8667 8.98581V13.1001C13.8667 13.3183 13.7824 13.5276 13.6324 13.6819C13.4823 13.8363 13.2788 13.923 13.0667 13.923H2.93333C2.72116 13.923 2.51768 13.8363 2.36765 13.6819C2.21762 13.5276 2.13333 13.3183 2.13333 13.1001V8.98581C2.13333 8.69483 2.02095 8.41577 1.82091 8.21002C1.62088 8.00426 1.34956 7.88867 1.06667 7.88867C0.783769 7.88867 0.512458 8.00426 0.31242 8.21002C0.112381 8.41577 0 8.69483 0 8.98581V13.1001C0 13.9003 0.309047 14.6677 0.859153 15.2335C1.40926 15.7994 2.15536 16.1172 2.93333 16.1172Z"
                                      fill="#265D91" />
                                  <path
                                      d="M7.99998 0C7.71708 0 7.44577 0.115591 7.24573 0.321345C7.04569 0.5271 6.93331 0.806163 6.93331 1.09714V8.0064L5.31198 6.49783C5.10228 6.30251 4.82574 6.20087 4.54318 6.21527C4.26063 6.22968 3.99521 6.35894 3.80531 6.57463C3.61542 6.79032 3.5166 7.07476 3.53061 7.36539C3.54461 7.65602 3.67028 7.92902 3.87998 8.12434L7.28531 11.2923C7.30665 11.3115 7.32798 11.3253 7.34931 11.3417C7.37065 11.3582 7.39998 11.3829 7.42665 11.4021C7.45856 11.4221 7.4915 11.4404 7.52531 11.4569L7.60531 11.4981C7.64366 11.5132 7.68284 11.526 7.72265 11.5365L7.79731 11.5584C7.93106 11.5863 8.0689 11.5863 8.20265 11.5584L8.27731 11.5365C8.31711 11.526 8.35629 11.5132 8.39465 11.4981L8.47465 11.4569C8.50846 11.4405 8.5414 11.4221 8.57331 11.4021C8.59998 11.3829 8.62398 11.3637 8.65065 11.3417C8.67731 11.3198 8.69331 11.3115 8.71465 11.2923L12.12 8.12434C12.2238 8.02763 12.3081 7.91083 12.3681 7.78061C12.428 7.65039 12.4624 7.5093 12.4694 7.36539C12.4763 7.22149 12.4556 7.07759 12.4085 6.94191C12.3613 6.80623 12.2887 6.68143 12.1946 6.57463C12.1006 6.46783 11.9871 6.38113 11.8605 6.31947C11.7339 6.25781 11.5967 6.2224 11.4568 6.21527C11.3169 6.20814 11.177 6.22942 11.0451 6.2779C10.9131 6.32639 10.7918 6.40112 10.688 6.49783L9.06665 8.0064V1.09714C9.06665 0.806163 8.95427 0.5271 8.75423 0.321345C8.55419 0.115591 8.28288 0 7.99998 0Z"
                                      fill="#265D91" />
                              </svg>

                              <!--end::Menu-->
                          </td>



                          <td class="">
                              <div class="tools">
                                  <div class="dropdown">
                                      <button
                                          class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                          type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                          aria-haspopup="true" aria-expanded="false">
                                          <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                  transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                              <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                  transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                  fill="#265D91" />
                                              <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                  transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                  fill="#265D91" />
                                          </svg>
                                      </button>
                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <a href="" class="dropdown-item">Modifier</a>
                                          <a href="" class="dropdown-item"> Archiver</a>
                                      </div>
                                  </div>
                              </div>
                          </td>
                      </tr>
                      <tr>
                        <td>
                            <a href="/" class="text-gray-800 text-hover-primary mb-1"> ESN 1</a>
                        </td>
                        <td>
                            <a href="#" class="text-gray-600 text-hover-primary mb-1">GOLD</a>
                        </td>
                        <td> FR12354689565626565265</td>
                        <td>0000</td>
                        <td>France</td>
                        <td>12/08/2023</td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                viewBox="0 0 16 17" fill="none">
                                <path
                                    d="M2.93333 16.1172H13.0667C13.4519 16.1172 13.8333 16.0392 14.1892 15.8876C14.5451 15.7359 14.8685 15.5137 15.1408 15.2335C15.4132 14.9534 15.6293 14.6208 15.7767 14.2547C15.9241 13.8887 16 13.4963 16 13.1001V8.98581C16 8.69483 15.8876 8.41577 15.6876 8.21002C15.4875 8.00426 15.2162 7.88867 14.9333 7.88867C14.6504 7.88867 14.3791 8.00426 14.1791 8.21002C13.979 8.41577 13.8667 8.69483 13.8667 8.98581V13.1001C13.8667 13.3183 13.7824 13.5276 13.6324 13.6819C13.4823 13.8363 13.2788 13.923 13.0667 13.923H2.93333C2.72116 13.923 2.51768 13.8363 2.36765 13.6819C2.21762 13.5276 2.13333 13.3183 2.13333 13.1001V8.98581C2.13333 8.69483 2.02095 8.41577 1.82091 8.21002C1.62088 8.00426 1.34956 7.88867 1.06667 7.88867C0.783769 7.88867 0.512458 8.00426 0.31242 8.21002C0.112381 8.41577 0 8.69483 0 8.98581V13.1001C0 13.9003 0.309047 14.6677 0.859153 15.2335C1.40926 15.7994 2.15536 16.1172 2.93333 16.1172Z"
                                    fill="#265D91" />
                                <path
                                    d="M7.99998 0C7.71708 0 7.44577 0.115591 7.24573 0.321345C7.04569 0.5271 6.93331 0.806163 6.93331 1.09714V8.0064L5.31198 6.49783C5.10228 6.30251 4.82574 6.20087 4.54318 6.21527C4.26063 6.22968 3.99521 6.35894 3.80531 6.57463C3.61542 6.79032 3.5166 7.07476 3.53061 7.36539C3.54461 7.65602 3.67028 7.92902 3.87998 8.12434L7.28531 11.2923C7.30665 11.3115 7.32798 11.3253 7.34931 11.3417C7.37065 11.3582 7.39998 11.3829 7.42665 11.4021C7.45856 11.4221 7.4915 11.4404 7.52531 11.4569L7.60531 11.4981C7.64366 11.5132 7.68284 11.526 7.72265 11.5365L7.79731 11.5584C7.93106 11.5863 8.0689 11.5863 8.20265 11.5584L8.27731 11.5365C8.31711 11.526 8.35629 11.5132 8.39465 11.4981L8.47465 11.4569C8.50846 11.4405 8.5414 11.4221 8.57331 11.4021C8.59998 11.3829 8.62398 11.3637 8.65065 11.3417C8.67731 11.3198 8.69331 11.3115 8.71465 11.2923L12.12 8.12434C12.2238 8.02763 12.3081 7.91083 12.3681 7.78061C12.428 7.65039 12.4624 7.5093 12.4694 7.36539C12.4763 7.22149 12.4556 7.07759 12.4085 6.94191C12.3613 6.80623 12.2887 6.68143 12.1946 6.57463C12.1006 6.46783 11.9871 6.38113 11.8605 6.31947C11.7339 6.25781 11.5967 6.2224 11.4568 6.21527C11.3169 6.20814 11.177 6.22942 11.0451 6.2779C10.9131 6.32639 10.7918 6.40112 10.688 6.49783L9.06665 8.0064V1.09714C9.06665 0.806163 8.95427 0.5271 8.75423 0.321345C8.55419 0.115591 8.28288 0 7.99998 0Z"
                                    fill="#265D91" />
                            </svg>

                            <!--end::Menu-->
                        </td>



                        <td class="">
                            <div class="tools">
                                <div class="dropdown">
                                    <button
                                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                        type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                fill="#265D91" />
                                        </svg>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a href="" class="dropdown-item">Modifier</a>
                                        <a href="" class="dropdown-item"> Archiver</a>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="/" class="text-gray-800 text-hover-primary mb-1"> ESN 1</a>
                        </td>
                        <td>
                            <a href="#" class="text-gray-600 text-hover-primary mb-1">GOLD</a>
                        </td>
                        <td> FR12354689565626565265</td>
                        <td>0000</td>
                        <td>France</td>
                        <td>12/08/2023</td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                viewBox="0 0 16 17" fill="none">
                                <path
                                    d="M2.93333 16.1172H13.0667C13.4519 16.1172 13.8333 16.0392 14.1892 15.8876C14.5451 15.7359 14.8685 15.5137 15.1408 15.2335C15.4132 14.9534 15.6293 14.6208 15.7767 14.2547C15.9241 13.8887 16 13.4963 16 13.1001V8.98581C16 8.69483 15.8876 8.41577 15.6876 8.21002C15.4875 8.00426 15.2162 7.88867 14.9333 7.88867C14.6504 7.88867 14.3791 8.00426 14.1791 8.21002C13.979 8.41577 13.8667 8.69483 13.8667 8.98581V13.1001C13.8667 13.3183 13.7824 13.5276 13.6324 13.6819C13.4823 13.8363 13.2788 13.923 13.0667 13.923H2.93333C2.72116 13.923 2.51768 13.8363 2.36765 13.6819C2.21762 13.5276 2.13333 13.3183 2.13333 13.1001V8.98581C2.13333 8.69483 2.02095 8.41577 1.82091 8.21002C1.62088 8.00426 1.34956 7.88867 1.06667 7.88867C0.783769 7.88867 0.512458 8.00426 0.31242 8.21002C0.112381 8.41577 0 8.69483 0 8.98581V13.1001C0 13.9003 0.309047 14.6677 0.859153 15.2335C1.40926 15.7994 2.15536 16.1172 2.93333 16.1172Z"
                                    fill="#265D91" />
                                <path
                                    d="M7.99998 0C7.71708 0 7.44577 0.115591 7.24573 0.321345C7.04569 0.5271 6.93331 0.806163 6.93331 1.09714V8.0064L5.31198 6.49783C5.10228 6.30251 4.82574 6.20087 4.54318 6.21527C4.26063 6.22968 3.99521 6.35894 3.80531 6.57463C3.61542 6.79032 3.5166 7.07476 3.53061 7.36539C3.54461 7.65602 3.67028 7.92902 3.87998 8.12434L7.28531 11.2923C7.30665 11.3115 7.32798 11.3253 7.34931 11.3417C7.37065 11.3582 7.39998 11.3829 7.42665 11.4021C7.45856 11.4221 7.4915 11.4404 7.52531 11.4569L7.60531 11.4981C7.64366 11.5132 7.68284 11.526 7.72265 11.5365L7.79731 11.5584C7.93106 11.5863 8.0689 11.5863 8.20265 11.5584L8.27731 11.5365C8.31711 11.526 8.35629 11.5132 8.39465 11.4981L8.47465 11.4569C8.50846 11.4405 8.5414 11.4221 8.57331 11.4021C8.59998 11.3829 8.62398 11.3637 8.65065 11.3417C8.67731 11.3198 8.69331 11.3115 8.71465 11.2923L12.12 8.12434C12.2238 8.02763 12.3081 7.91083 12.3681 7.78061C12.428 7.65039 12.4624 7.5093 12.4694 7.36539C12.4763 7.22149 12.4556 7.07759 12.4085 6.94191C12.3613 6.80623 12.2887 6.68143 12.1946 6.57463C12.1006 6.46783 11.9871 6.38113 11.8605 6.31947C11.7339 6.25781 11.5967 6.2224 11.4568 6.21527C11.3169 6.20814 11.177 6.22942 11.0451 6.2779C10.9131 6.32639 10.7918 6.40112 10.688 6.49783L9.06665 8.0064V1.09714C9.06665 0.806163 8.95427 0.5271 8.75423 0.321345C8.55419 0.115591 8.28288 0 7.99998 0Z"
                                    fill="#265D91" />
                            </svg>

                            <!--end::Menu-->
                        </td>



                        <td class="">
                            <div class="tools">
                                <div class="dropdown">
                                    <button
                                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                        type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                fill="#265D91" />
                                        </svg>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a href="" class="dropdown-item">Modifier</a>
                                        <a href="" class="dropdown-item"> Archiver</a>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="/" class="text-gray-800 text-hover-primary mb-1"> ESN 1</a>
                        </td>
                        <td>
                            <a href="#" class="text-gray-600 text-hover-primary mb-1">GOLD</a>
                        </td>
                        <td> FR12354689565626565265</td>
                        <td>0000</td>
                        <td>France</td>
                        <td>12/08/2023</td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                viewBox="0 0 16 17" fill="none">
                                <path
                                    d="M2.93333 16.1172H13.0667C13.4519 16.1172 13.8333 16.0392 14.1892 15.8876C14.5451 15.7359 14.8685 15.5137 15.1408 15.2335C15.4132 14.9534 15.6293 14.6208 15.7767 14.2547C15.9241 13.8887 16 13.4963 16 13.1001V8.98581C16 8.69483 15.8876 8.41577 15.6876 8.21002C15.4875 8.00426 15.2162 7.88867 14.9333 7.88867C14.6504 7.88867 14.3791 8.00426 14.1791 8.21002C13.979 8.41577 13.8667 8.69483 13.8667 8.98581V13.1001C13.8667 13.3183 13.7824 13.5276 13.6324 13.6819C13.4823 13.8363 13.2788 13.923 13.0667 13.923H2.93333C2.72116 13.923 2.51768 13.8363 2.36765 13.6819C2.21762 13.5276 2.13333 13.3183 2.13333 13.1001V8.98581C2.13333 8.69483 2.02095 8.41577 1.82091 8.21002C1.62088 8.00426 1.34956 7.88867 1.06667 7.88867C0.783769 7.88867 0.512458 8.00426 0.31242 8.21002C0.112381 8.41577 0 8.69483 0 8.98581V13.1001C0 13.9003 0.309047 14.6677 0.859153 15.2335C1.40926 15.7994 2.15536 16.1172 2.93333 16.1172Z"
                                    fill="#265D91" />
                                <path
                                    d="M7.99998 0C7.71708 0 7.44577 0.115591 7.24573 0.321345C7.04569 0.5271 6.93331 0.806163 6.93331 1.09714V8.0064L5.31198 6.49783C5.10228 6.30251 4.82574 6.20087 4.54318 6.21527C4.26063 6.22968 3.99521 6.35894 3.80531 6.57463C3.61542 6.79032 3.5166 7.07476 3.53061 7.36539C3.54461 7.65602 3.67028 7.92902 3.87998 8.12434L7.28531 11.2923C7.30665 11.3115 7.32798 11.3253 7.34931 11.3417C7.37065 11.3582 7.39998 11.3829 7.42665 11.4021C7.45856 11.4221 7.4915 11.4404 7.52531 11.4569L7.60531 11.4981C7.64366 11.5132 7.68284 11.526 7.72265 11.5365L7.79731 11.5584C7.93106 11.5863 8.0689 11.5863 8.20265 11.5584L8.27731 11.5365C8.31711 11.526 8.35629 11.5132 8.39465 11.4981L8.47465 11.4569C8.50846 11.4405 8.5414 11.4221 8.57331 11.4021C8.59998 11.3829 8.62398 11.3637 8.65065 11.3417C8.67731 11.3198 8.69331 11.3115 8.71465 11.2923L12.12 8.12434C12.2238 8.02763 12.3081 7.91083 12.3681 7.78061C12.428 7.65039 12.4624 7.5093 12.4694 7.36539C12.4763 7.22149 12.4556 7.07759 12.4085 6.94191C12.3613 6.80623 12.2887 6.68143 12.1946 6.57463C12.1006 6.46783 11.9871 6.38113 11.8605 6.31947C11.7339 6.25781 11.5967 6.2224 11.4568 6.21527C11.3169 6.20814 11.177 6.22942 11.0451 6.2779C10.9131 6.32639 10.7918 6.40112 10.688 6.49783L9.06665 8.0064V1.09714C9.06665 0.806163 8.95427 0.5271 8.75423 0.321345C8.55419 0.115591 8.28288 0 7.99998 0Z"
                                    fill="#265D91" />
                            </svg>

                            <!--end::Menu-->
                        </td>



                        <td class="">
                            <div class="tools">
                                <div class="dropdown">
                                    <button
                                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                        type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                fill="#265D91" />
                                        </svg>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a href="" class="dropdown-item">Modifier</a>
                                        <a href="" class="dropdown-item"> Archiver</a>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="/" class="text-gray-800 text-hover-primary mb-1"> ESN 1</a>
                        </td>
                        <td>
                            <a href="#" class="text-gray-600 text-hover-primary mb-1">GOLD</a>
                        </td>
                        <td> FR12354689565626565265</td>
                        <td>0000</td>
                        <td>France</td>
                        <td>12/08/2023</td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                viewBox="0 0 16 17" fill="none">
                                <path
                                    d="M2.93333 16.1172H13.0667C13.4519 16.1172 13.8333 16.0392 14.1892 15.8876C14.5451 15.7359 14.8685 15.5137 15.1408 15.2335C15.4132 14.9534 15.6293 14.6208 15.7767 14.2547C15.9241 13.8887 16 13.4963 16 13.1001V8.98581C16 8.69483 15.8876 8.41577 15.6876 8.21002C15.4875 8.00426 15.2162 7.88867 14.9333 7.88867C14.6504 7.88867 14.3791 8.00426 14.1791 8.21002C13.979 8.41577 13.8667 8.69483 13.8667 8.98581V13.1001C13.8667 13.3183 13.7824 13.5276 13.6324 13.6819C13.4823 13.8363 13.2788 13.923 13.0667 13.923H2.93333C2.72116 13.923 2.51768 13.8363 2.36765 13.6819C2.21762 13.5276 2.13333 13.3183 2.13333 13.1001V8.98581C2.13333 8.69483 2.02095 8.41577 1.82091 8.21002C1.62088 8.00426 1.34956 7.88867 1.06667 7.88867C0.783769 7.88867 0.512458 8.00426 0.31242 8.21002C0.112381 8.41577 0 8.69483 0 8.98581V13.1001C0 13.9003 0.309047 14.6677 0.859153 15.2335C1.40926 15.7994 2.15536 16.1172 2.93333 16.1172Z"
                                    fill="#265D91" />
                                <path
                                    d="M7.99998 0C7.71708 0 7.44577 0.115591 7.24573 0.321345C7.04569 0.5271 6.93331 0.806163 6.93331 1.09714V8.0064L5.31198 6.49783C5.10228 6.30251 4.82574 6.20087 4.54318 6.21527C4.26063 6.22968 3.99521 6.35894 3.80531 6.57463C3.61542 6.79032 3.5166 7.07476 3.53061 7.36539C3.54461 7.65602 3.67028 7.92902 3.87998 8.12434L7.28531 11.2923C7.30665 11.3115 7.32798 11.3253 7.34931 11.3417C7.37065 11.3582 7.39998 11.3829 7.42665 11.4021C7.45856 11.4221 7.4915 11.4404 7.52531 11.4569L7.60531 11.4981C7.64366 11.5132 7.68284 11.526 7.72265 11.5365L7.79731 11.5584C7.93106 11.5863 8.0689 11.5863 8.20265 11.5584L8.27731 11.5365C8.31711 11.526 8.35629 11.5132 8.39465 11.4981L8.47465 11.4569C8.50846 11.4405 8.5414 11.4221 8.57331 11.4021C8.59998 11.3829 8.62398 11.3637 8.65065 11.3417C8.67731 11.3198 8.69331 11.3115 8.71465 11.2923L12.12 8.12434C12.2238 8.02763 12.3081 7.91083 12.3681 7.78061C12.428 7.65039 12.4624 7.5093 12.4694 7.36539C12.4763 7.22149 12.4556 7.07759 12.4085 6.94191C12.3613 6.80623 12.2887 6.68143 12.1946 6.57463C12.1006 6.46783 11.9871 6.38113 11.8605 6.31947C11.7339 6.25781 11.5967 6.2224 11.4568 6.21527C11.3169 6.20814 11.177 6.22942 11.0451 6.2779C10.9131 6.32639 10.7918 6.40112 10.688 6.49783L9.06665 8.0064V1.09714C9.06665 0.806163 8.95427 0.5271 8.75423 0.321345C8.55419 0.115591 8.28288 0 7.99998 0Z"
                                    fill="#265D91" />
                            </svg>

                            <!--end::Menu-->
                        </td>



                        <td class="">
                            <div class="tools">
                                <div class="dropdown">
                                    <button
                                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                        type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                fill="#265D91" />
                                        </svg>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a href="" class="dropdown-item">Modifier</a>
                                        <a href="" class="dropdown-item"> Archiver</a>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>

                  </tbody>
              </table>
          </div>
          <!--end::Table-->
      </div>
      <!--end::Card body-->
      </div>



  </div>
</div>
  <div class="modal fade" id="kt_customers_export_modal" tabindex="-1" aria-hidden="true">
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
          <!--begin::Modal content-->
          <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold">Ajouter candidat</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div id="kt_customers_export_close" data-bs-dismiss="modal"
                  class="btn btn-icon btn-sm btn-active-icon-primary">
                      <i class="ki-duotone ki-cross fs-1">
                          <span class="path1"></span>
                          <span class="path2"></span>
                      </i>
                  </div>
                  <!--end::Close-->
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                  <!--begin::Form-->
                  <form id="kt_customers_export_form" class="form" action="#">
                      <!--begin::Input group-->
                      <div class="fv-row mb-5">
                          <!--begin::Label-->
                          <div class="mb-5">
                              <label for="Nom complet" class="required form-label">Nom complet</label>
                              <input type="text" class="form-control   mb-3" placeholder="Nom complet" />
                          </div>
                          <div class="mb-5">
                              <label for="Email" class="required form-label">Email</label>
                              <input type="email" class="form-control   mb-3" placeholder="Email" />
                          </div>
                          <div class="mb-5">
                              <label for="N° Tel" class="required form-label">N° Tel</label>
                              <input type="tel" class="form-control   mb-3" placeholder="N° Tel" />
                          </div>
                          <div class="mb-5">
                              <label for="Pays" class="required form-label">Pays</label>
                              <select data-control="select2" data-placeholder="Select a format"
                                  data-hide-search="true" name="format" class="form-select style mb-3">
                                  <option value="excell">Pays</option>
                                  <option value="pdf">Tunisie</option>
                                  <option value="cvs">Egypt</option>
                                  <option value="zip">France</option>
                              </select>
                          </div>
                          <div class="mb-5">
                              <label for="Mobilité" class="required form-label">Mobilité</label>
                              <select data-control="select2" data-placeholder="Select a format"
                                  data-hide-search="true" name="format" class="form-select mb-3">
                                  <option value="excell">Mobilité</option>
                                  <option value="pdf">Tunisie</option>
                                  <option value="cvs">Egypt</option>
                                  <option value="zip">France</option>
                              </select>
                          </div>
                          <div class="mb-5">
                              <label for="Compétences" class="required form-label">Compétences</label>
                              <select data-control="select2" data-placeholder="Select a format"
                                  data-hide-search="true" name="format" class="form-select mb-3">
                                  <option value="excell">JAVA</option>
                                  <option value="pdf">FLUTTER</option>
                                  <option value="cvs">ANGULAR</option>
                                  <option value="zip">LARAVEL</option>
                              </select>
                          </div>
                          <div class="mb-5">
                              <label for="cv" class="required form-label">Importer CV</label>
                              <input type="file" name="cv" id="cv" class="form-control mb-3">
                          </div>
                          <!--end::Input-->
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->

                      <!--end::Input group-->
                      <!--begin::Row-->

                      <!--end::Row-->
                      <!--begin::Actions-->
                      <div class="text-center">
                          <button type="reset" id="kt_customers_export_cancel"
                              class="btn btn-light me-3">Reset</button>
                          <button type="submit" id="kt_customers_export_submit" class="btn btn-primary">
                              <span class="indicator-label">Confirmer</span>
                              <span class="indicator-progress">Please wait...
                                  <span
                                      class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                          </button>
                      </div>
                      <!--end::Actions-->
                  </form>
                  <!--end::Form-->
              </div>
              <!--end::Modal body-->
          </div>
          <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
  </div>







        <div class="modal fade" id="add_abonnement" tabindex="-1" aria-hidden="true">
            <!--begin::Modal dialog-->
            <div class="modal-dialog modal-dialog-centered mw-650px">
                <!--begin::Modal content-->
                <div class="modal-content">
                    <!--begin::Modal header-->
                    <div class="modal-header">
                        <!--begin::Modal title-->
                        <h2>Ajouter abonnement</h2>
                        <!--end::Modal title-->
                        <!--begin::Close-->
                        <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal">
                            <i class="ki-duotone ki-cross fs-1">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </div>
                        <!--end::Close-->
                    </div>
                    <!--end::Modal header-->
                    <!--begin::Modal body-->
                    <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                        <!--begin::Form-->
                        <form id="kt_modal_new_card_form" class="form tools" action="#">
                            <!--begin::Input group-->
                            <div class="d-flex flex-column mb-7 fv-row ">
                                <!--begin::Label-->
                                <label class="d-flex align-items-center form-label mb-2">
                                    <span class="">Pack</span>

                                </label>
                                <!--end::Label-->
                                <input type="text" class="form-control form-control-solid" placeholder="Silver"
                                    name="Nom société" value="" />
                            </div>
                            <div class="d-flex flex-column mb-7 fv-row">
                                <!--begin::Label-->
                                <label class="d-flex align-items-center  form-label mb-2">
                                    <span class="">NOM DE COMPTE BANCAIRE</span>

                                </label>
                                <!--end::Label-->
                                <input type="email" class="form-control form-control-solid" placeholder="France"
                                    name="Email" value="" />
                            </div>
                            <div class="d-flex flex-column mb-7 fv-row">
                                <!--begin::Label-->
                                <label class="d-flex align-items-center  form-label mb-2">
                                    <span class="">Date de paiement</span>

                                </label>
                                <!--end::Label-->
                                <input type="date" class="form-control form-control-solid" placeholder="jj/mm/aaaa"
                                    name="Linkedin" value="" />
                            </div>
                            <!--end::Input group-->
                            <!--begin::Input group-->

                            <!--end::Input group-->
                            <!--begin::Input group-->

                            <!--end::Input group-->
                            <!--begin::Input group-->

                            <!--end::Input group-->
                            <!--begin::Actions-->
                            <div class="text-center pt-15">
                                <button type="reset" id="kt_modal_new_card_cancel" data-bs-dismiss="modal"
                                    class="footer-btn annuler me-3">Annuler</button>
                                <button type="submit" id="kt_modal_new_card_submit" class="footer-btn confirmer">
                                    <span class="indicator-label">Confirmer</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                            <!--end::Actions-->
                        </form>
                        <!--end::Form-->
                    </div>
                    <!--end::Modal body-->
                </div>
                <!--end::Modal content-->
            </div>
            <!--end::Modal dialog-->
        </div>


</div>
