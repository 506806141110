import { AuthService } from '../services/authentication/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  Subject,
  catchError,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
  permissionDenied,
  serverError,
  sessionExpired,
} from '../models/messages';
import { Router } from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  AUTH_HEADER: string = 'authorization';
  refreshTokenInProgress: boolean = false;
  tokenRefreshedSource = new Subject<boolean>();
  tokenRefreshed = this.tokenRefreshedSource.asObservable();
  pendingRequests: HttpRequest<any>[] = [];

  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,

  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.has('Accept')) {
      request = request.clone({
        headers: request.headers
          .set('Content-Security-Policy', "default-src 'self'; script-src 'self' 'unsafe-inline' https://trusted.cdn.com; style-src 'self' 'unsafe-inline'; img-src 'self' data:; font-src 'self' data:")
          .set('X-Content-Type-Options', 'nosniff')
          .set('Strict-Transport-Security', 'max-age=31536000; includeSubDomains; preload')
          .set('X-Frame-Options', 'DENY')
          .set('X-XSS-Protection', '1; mode=block')
          .set('Referrer-Policy', 'strict-origin-when-cross-origin')
          .set('Permissions-Policy', 'geolocation=(self), microphone=()')
      });
    }

    request = this.addAuthHeader(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
       
    
        if (error.status === 401) {
          return this.handle401Error(request, next);
        } else if (error.status === 500) {
          this.toastrService.error(serverError);
        } else if (error.status === 403) {
          if (error.error.message === 'session expirée') {
            this.toastrService.info(sessionExpired);
            this.authService.logOut();
          } else if (error.error?.message === 'This action is unauthorized.') {
            this.toastrService.info(permissionDenied);
          }
        } 

        return throwError(() => error);
      })
    );
  }

  addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.getToken();
    if (token) {
      return request.clone({
        headers: request.headers.set(this.AUTH_HEADER, `Bearer ${token}`),
      });
    }
    return request;
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      return this.authService.refreshAccessToken({
        refresh_token: this.authService.getRefreshToken(),
      }).pipe(
        tap((resp) => {
          this.authService.setCookies('token', resp.refresh_token['access_token']);
          this.authService.setCookies('refresh_token', resp.refresh_token['refresh_token']);
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next(true);
        }),
        switchMap(() => {
          this.pendingRequests.forEach((pendingRequest) => {
            next.handle(this.addAuthHeader(pendingRequest)).subscribe();
          });
          this.pendingRequests = [];
          return next.handle(this.addAuthHeader(request));
        }),
        catchError((error) => {
          this.authService.logOut();
          return throwError(() => error);
        })
      );
    } else {
      // Queue the request while refresh token is in progress
      return new Observable((observer) => {
        this.tokenRefreshed.subscribe({
          next: (isTokenRefreshed) => {
            if (isTokenRefreshed) {
              this.pendingRequests.push(request);
              this.pendingRequests.forEach((pendingRequest) => {
                next.handle(this.addAuthHeader(pendingRequest)).subscribe(
                  (res) => observer.next(res),
                  (err) => observer.error(err),
                  () => observer.complete()
                );
              });
              this.pendingRequests = [];
            }
          },
          error: (err) => observer.error(err),
        });
      });
    }
  }
  status: any;
  checkNetworkStatus() {
    this.status = navigator.onLine ? 'true' : 'false';
    localStorage.setItem('status', this.status);

    window.addEventListener('online', () => {
      this.status = 'true';
      localStorage.setItem('status', 'true');
      this.toastrService.success('Connexion réseau rétablie');
    });

    window.addEventListener('offline', () => {
      this.status = 'false';
      localStorage.setItem('status', 'false');
      this.toastrService.error('Connexion réseau perdue');
      this.router.navigate(['/no-internet']);
    });
  }
}

