import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListAppelComponent } from './components/list-appel/list-appel.component';
import { TenderRoutingModule } from './tender-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FieldErrorModule } from '../../shared/components/shared-field-error/field-error.module';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { PipesModuleModule } from '../../core/pipes/pipes-module.module';

import { DetailJobRequestComponent } from './components/detail-job-request/detail-job-request.component';
import { FormsModule } from '@angular/forms';
import { GetbaremeComponent } from './components/get-bareme/getbareme.component';
import { ContactManagerComponent } from './components/contact-manager/contact-manager.component';
import { CandidatureJobRequestComponent } from './components/candidature-job-request/candidature-job-request.component';
import { DossierCandidatureComponent } from './components/dossier-candidature/dossier-candidature.component';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [
    ListAppelComponent,
    DetailJobRequestComponent,
    GetbaremeComponent,
    ContactManagerComponent,
    CandidatureJobRequestComponent,
    DossierCandidatureComponent,
  ],
  imports: [
    CommonModule,
    TenderRoutingModule,
    NgxSpinnerModule,
    FieldErrorModule,
    PaginationModule,
    PipesModuleModule,
    FormsModule,
    CalendarModule
  ],
})
export class TenderModule {}
