import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListAppelComponent } from './components/list-appel/list-appel.component';
import { DetailJobRequestComponent } from './components/detail-job-request/detail-job-request.component';
import { GetbaremeComponent } from './components/get-bareme/getbareme.component';
import { ContactManagerComponent } from './components/contact-manager/contact-manager.component';
import { CandidatureJobRequestComponent } from './components/candidature-job-request/candidature-job-request.component';
import { DossierCandidatureComponent } from './components/dossier-candidature/dossier-candidature.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list-appel-offre',
    pathMatch: 'full',
  },
  {
    path:'list-appel-offre',
    component:ListAppelComponent,
  },
  {
    path:'details-appel-offre',
    component:DetailJobRequestComponent,
  },
  {
    path:'candidature-appel-offre/:id',
    component:CandidatureJobRequestComponent,
  },
  {
    path:'dossier-candidature/:id/:id_offer',
    component:DossierCandidatureComponent,
  },

  {
    path:'contact-manager',
    component:ContactManagerComponent,
  },
  {
    path:'get-bareme/:id',
    component:GetbaremeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TenderRoutingModule { }
