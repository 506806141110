import { Component, TemplateRef, ElementRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Country, ICountry, IState, State } from 'country-state-city';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ISort } from '../../core/models/candidat';
import {
  emailExist,
  emailadmin,
  existedAdmin,
  fileTypeExtention,
  maxSizeFile,
  serverError,
} from '../../core/models/messages';
import { patternEmail } from '../../core/models/pattern';
import { LocalStorageService } from '../../core/services/Local-storage/local-storage.service';
import { CandidatService } from '../../core/services/candidat/candidat.service';
import { environment } from '../../../environments/environment';
export function notOnlyWhitespace(
  control: AbstractControl
): ValidationErrors | null {
  if (control.value && control.value.trim() === '') {
    return { notOnlyWhitespace: true };
  }
  return null;
}

@Component({
  selector: 'app-list-admin',
  templateUrl: './list-admin.component.html',
  styleUrls: ['./list-admin.component.css'],
})
export class ListAdminComponent {
  role = [
    { id: '2', name: 'Admin' },
    { id: '1', name: 'Super admin' },
  ];
  fileError: string = '';
  fileExtention: string = '';
  fileName: string = '';
  file: any;
  selectedImage: any;
  isLoadSpinner: boolean = true;

  url: string = environment.baseUrl + '/api';
  formData = new FormData();
  userForm: FormGroup = this.createUserForm();
  modalRef?: BsModalRef;
  id: any;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  selectedCountryCode: string = 'FR';
  isImageChanged: boolean = false;
  pagination: boolean = false;

  profil_id: any;
  profil_image: any;
  last_name: any;
  first_name: any;
  sortAttr: string = '';
  user: any;
  totalItems!: number;
  roleuser: any;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentUser: any;
  currentSortDirection: number = 2;
  submitted = false;

  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private candidatService: CandidatService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private elRef: ElementRef
  ) { }
  ngOnInit() {
    this.getListAdmin();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }

  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
    }
  }


  createUserForm(data?: any) {
    return this.formBuilder.group({
      first_name: [
        data ? data.first_name : '',
        [Validators.required, notOnlyWhitespace],
      ],
      last_name: [
        data ? data.last_name : '',
        [Validators.required, notOnlyWhitespace],
      ],
      email: [
        data ? data.email : '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(patternEmail),
        ],
      ],
      phone_number: [data ? data.phone_number : '', [Validators.required]],
      image_url: [data ? data.image_url : '', [Validators.required]],
      role_user_id: [data ? data.role_user_id : [], [Validators.required]],
    });
  }
  handleKeyPress(event: any) {
    const allowedCharacters = /^[0-9+]+$/;
    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  }

  uploadFile(event: any): void {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    this.file = file;
    this.fileName = this.file.name;
    const allowedExtensions = [
      '.jpeg',
      '.jpg',
      '.png',
      '.svg',
      '.gif',
      '.jfif',
      '.avif',
    ];
    const fileExtension = file.name
      .toLowerCase()
      .substr(file.name.lastIndexOf('.'));
    if (file) {
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileExtention = fileTypeExtention;
        this.toastrService.error(
          "Le format du fichier n'est pas accepté.Veuillez choisir une photo"
        );
      } else if (file.size > maxSize) {
        this.fileError = maxSizeFile;
      } else {
        this.fileError = '';
        this.fileExtention = '';
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  // uploadFile(event: any): void {
  //   // Reset error messages
  //   this.fileExtention = '';
  //   this.fileError = '';

  //   const fileInput = event.target;
  //   const file = fileInput.files[0];
  //   const maxSize = 2 * 1024 * 1024;
  //   const allowedExtensions = ['.jpeg', '.jpg', '.png', '.git'];

  //   // Check if the file input is empty (user canceled selection)
  //   if (!file) {
  //     // Clear file input and related variables
  //     fileInput.value = '';
  //     this.file = null;
  //     this.fileName = '';
  //     this.selectedImage = null;
  //     return;
  //   }

  //   // Set file and file name
  //   this.file = file;
  //   this.fileName = this.file.name;

  //   const fileExtension = file.name.toLowerCase().substr(file.name.lastIndexOf('.'));

  //   if (!allowedExtensions.includes(fileExtension)) {
  //     this.fileExtention = 'Format fichier non compatible !';
  //     // Clear file input and related variables
  //     // fileInput.value = '';
  //     // this.file = null;
  //     // this.fileName = '';
  //     // this.selectedImage = null;
  //   } else if (file.size > maxSize) {
  //     this.fileError = 'File size exceeds the maximum allowed size (2MB).';
  //     // Clear file input and related variables
  //     fileInput.value = '';
  //     this.file = null;
  //     this.fileName = '';
  //     this.selectedImage = null;
  //   } else {
  //     // Reset other error messages
  //     this.fileError = '';

  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       this.selectedImage = reader.result;
  //     };
  //     reader.readAsDataURL(file);
  //   }

  //   // Set the file in the form group
  //   this.userForm.get('image_url')?.setValue(this.file);

  // }

  clearImage() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this.fileName = '';
    this.selectedImage = null;
    this.file = null;
    this.fileExtention = '';
    if (
      !this.userForm.get('image_url')?.value ||
      this.userForm.get('image_url')?.value === 'avatar.png'
    ) {
      this.userForm.get('image_url')?.setValue('avatar.png');
    }
  }

  // shouldDisplayUploadedLogo(): boolean {
  //   return !this.fileName && this.userForm.get('image_url')?.value?.image_url !== 'avatar.png';
  // }

  // shouldDisplaySelectedImage(): boolean {
  //   return !!this.fileName;
  // }

  // shouldDisplayPlaceholderImage(): boolean {
  //   return !this.selectedImage && (!this.userForm.get('image_url')?.value || this.fileName === '');
  // }
  // // Ajoutez ces méthodes dans votre composant TypeScript
  // shouldShowImage(): boolean {
  //   return (!this.fileName || this.fileName === '') && this.userForm.get('image_url')?.value?.image_url !== 'avatar.png';
  // }

  // getImageSource(): string {
  //   const imageUrl = this.userForm.get('image_url')?.value;
  //   return imageUrl ? `${this.url}/User/file/gallery_users/${imageUrl}` : '';
  // }
  ///////////////////////////////////////////////////////////////////////
  stateList!: IState[];
  paysList: ICountry[] = Country.getAllCountries().map((country) => ({
    ...country,
    name: this.translateCountryName(country.name),
  }));
  onCountryChange(event: any): void {
    if (event) {
      this.stateList = State.getStatesOfCountry(event.isoCode);
      for (const iterator of this.stateList) {
        iterator.name = iterator.name.replace(' Governorate', '');
      }
    } else {
      this.stateList = [];
      this.userForm.get('city')?.setValue(null);
    }
  }

  /*  add new company */
  // addAdmin(){
  //   if (!this.validPhoneNumber) {
  //     // Display an error message or handle the case when the phone number is invalid.
  //     return;
  //   }
  //   // this.setValidatorRequirements()
  //   console.log("value of form groupe ", this.userForm.value)
  //     const formData = new FormData();
  //       formData.append("first_name", this.userForm.value.first_name)
  //       formData.append("last_name", this.userForm.value.last_name)
  //       formData.append("email", this.userForm.value.email)
  //      formData.append("image_url", this.file)

  //       console.log("log",this.file)

  //       formData.append("phone_number", this.userForm.value.phone_number )
  //       const role_user_ids = this.userForm.value.role_user_id;
  //       for (const role_user_id of role_user_ids) {
  //         formData.append('role_user_id[]', role_user_id);
  //       }

  //     this.spinner.show()
  //     this.candidatService.register(formData).pipe(takeUntil(this.unsubscribeAll)).subscribe({
  //       next:()=> {
  //        this.toastrService.success(addedadmin)
  //        this.closeModal()

  //        this.spinner.hide()
  //        this.getListAdmin()
  //       },
  //       error:(err)=> {
  //         this.spinner.hide()
  //         console.log("err",err)
  //         if(err.error?.data?.phone_number) this.toastrService.info(existedAdmin)
  //         if(err.error?.data?.phone_number) this.toastrService.info(existedAdmin)
  //         // else if(err.error?.message?.linkedin_link && !err.error?.message?.website_link) this.toastr.info(existedLinkedIn)
  //         else if(err.error?.data?.email) this.toastrService.info(emailadmin)
  //         // else if(err.error?.message?.website_link && err.error?.message?.linkedin_link) this.toastr.info(existedWebSiteLinkedIn)
  //       else this.toastrService.error(serverError)
  //       },
  //     })

  // }
  addAdmin() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    } else {
      if (!this.validPhoneNumber) {
        return;
      }

      const formData = new FormData();
      formData.append('first_name', this.userForm.value.first_name);
      formData.append('last_name', this.userForm.value.last_name);
      formData.append('email', this.userForm.value.email);
      formData.append('image_url', this.file);
      formData.append('phone_number', this.userForm.value.phone_number);

      const role_user_ids = this.userForm.value.role_user_id;
      for (const role_user_id of role_user_ids) {
        formData.append('role_user_id[]', role_user_id);
      }

      this.spinner.show();
      this.candidatService
        .register(formData)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: () => {
            this.spinner.hide();
            this.getListAdmin();

            const selectedRole = this.userForm.value.role_user_id.includes('1')
              ? 'Super admin'
              : 'Admin';
            const successMessage = `${selectedRole} a été ajoutée avec succès.`;
            this.toastrService.success(successMessage);

            this.closeModal();
          },
          error: (err) => {
            this.spinner.hide();
            console.log('err', err);
            if (err.error?.data?.phone_number)
              this.toastrService.info(existedAdmin);
            if (err.error?.data?.phone_number)
              this.toastrService.info(existedAdmin);
            else if (err.error?.data?.email)
              this.toastrService.info(emailadmin);
            else this.toastrService.error(serverError);
          },
        });
    }
  }

  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      Afghanistan: 'Afghanistan',
      'South Africa': 'Afrique du Sud',
      Albania: 'Albanie',
      Algeria: 'Algérie',
      Germany: 'Allemagne',
      Andorra: 'Andorre',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctique',
      'Antigua and Barbuda': 'Antigua-et-Barbuda',
      'Saudi Arabia': 'Arabie saoudite',
      Argentina: 'Argentine',
      Armenia: 'Arménie',
      Aruba: 'Aruba',
      Australia: 'Australie',
      Austria: 'Autriche',
      Azerbaijan: 'Azerbaïdjan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahreïn',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbade',
      Belgium: 'Belgique',
      Belize: 'Belize',
      Benin: 'Bénin',
      Bermuda: 'Bermudes',
      Bhutan: 'Bhoutan',
      Belarus: 'Biélorussie',
      Bolivia: 'Bolivie',
      'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
      Botswana: 'Botswana',
      Brazil: 'Brésil',
      Brunei: 'Brunei',
      Bulgaria: 'Bulgarie',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      'Cape Verde': 'Cabo Verde',
      Cambodia: 'Cambodge',
      Cameroon: 'Cameroun',
      Canada: 'Canada',
      Chile: 'Chili',
      China: 'Chine',
      Cyprus: 'Chypre',
      Colombia: 'Colombie',
      Comoros: 'Comores',
      'Congo - Brazzaville': 'Congo-Brazzaville',
      'Congo - Kinshasa': 'Congo-Kinshasa',
      'North Korea': 'Corée du Nord',
      'South Korea': 'Corée du Sud',
      'Costa Rica': 'Costa Rica',
      'Ivory Coast': 'Côte d’Ivoire',
      Croatia: 'Croatie',
      Cuba: 'Cuba',
      Curaçao: 'Curaçao',
      Denmark: 'Danemark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominique',
      'Dominican Republic': 'République dominicaine',
      Ecuador: 'Équateur',
      Egypt: 'Égypte',
      'United Arab Emirates': 'Émirats arabes unis',
      Eritrea: 'Érythrée',
      Spain: 'Espagne',
      Eswatini: 'Eswatini',
      Estonia: 'Estonie',
      'United States': 'États-Unis',
      Ethiopia: 'Éthiopie',
      Fiji: 'Fidji',
      Finland: 'Finlande',
      France: 'France',
      Gabon: 'Gabon',
      Gambia: 'Gambie',
      Georgia: 'Géorgie',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Grèce',
      Grenada: 'Grenade',
      Greenland: 'Groenland',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernesey',
      Guinea: 'Guinée',
      'Guinea-Bissau': 'Guinée-Bissau',
      'Equatorial Guinea': 'Guinée équatoriale',
      Guyana: 'Guyana',
      'French Guiana': 'Guyane française',
      Haiti: 'Haïti',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Hongrie',
      Iceland: 'Islande',
      India: 'Inde',
      Indonesia: 'Indonésie',
      Iraq: 'Irak',
      Iran: 'Iran',
      Ireland: 'Irlande',
      'Isle of Man': 'Île de Man',
      Israel: 'Israël',
      Italy: 'Italie',
      Jamaica: 'Jamaïque',
      Japan: 'Japon',
      Jersey: 'Jersey',
      Jordan: 'Jordanie',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      Kuwait: 'Koweït',
      Kyrgyzstan: 'Kirghizistan',
      Laos: 'Laos',
      Latvia: 'Lettonie',
      Lebanon: 'Liban',
      Lesotho: 'Lesotho',
      Liberia: 'Libéria',
      Libya: 'Libye',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lituanie',
      Luxembourg: 'Luxembourg',
      Macao: 'Macao',
      'North Macedonia': 'Macédoine du Nord',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaisie',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malte',
      'Marshall Islands': 'Îles Marshall',
      Martinique: 'Martinique',
      Mauritania: 'Mauritanie',
      Mauritius: 'Maurice',
      Mayotte: 'Mayotte',
      Mexico: 'Mexique',
      Micronesia: 'Micronésie',
      Moldova: 'Moldavie',
      Monaco: 'Monaco',
      Mongolia: 'Mongolie',
      Montenegro: 'Monténégro',
      Montserrat: 'Montserrat',
      Morocco: 'Maroc',
      Mozambique: 'Mozambique',
      Myanmar: 'Myanmar',
      Namibia: 'Namibie',
      Nauru: 'Nauru',
      Nepal: 'Népal',
      Netherlands: 'Pays-Bas',
      'New Caledonia': 'Nouvelle-Calédonie',
      'New Zealand': 'Nouvelle-Zélande',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Île Norfolk',
      'Northern Mariana Islands': 'Îles Mariannes du Nord',
      Norway: 'Norvège',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palaos',
      Palestine: 'Palestine',
      Panama: 'Panama',
      'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
      Paraguay: 'Paraguay',
      Peru: 'Pérou',
      Philippines: 'Philippines',
      'Pitcairn Islands': 'Îles Pitcairn',
      Poland: 'Pologne',
      Portugal: 'Portugal',
      'Puerto Rico': 'Porto Rico',
      Qatar: 'Qatar',
      Romania: 'Roumanie',
      Russia: 'Russie',
      Rwanda: 'Rwanda',
      Réunion: 'Réunion',
      'Saint Barthélemy': 'Saint-Barthélemy',
      'Saint Helena': 'Sainte-Hélène',
      'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
      'Saint Lucia': 'Sainte-Lucie',
      'Saint Martin': 'Saint-Martin',
      'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
      'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
      Samoa: 'Samoa',
      'San Marino': 'Saint-Marin',
      'Sao Tome and Principe': 'Sao Tomé-et-Principe',
      Senegal: 'Sénégal',
      Serbia: 'Serbie',
      Seychelles: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapour',
      'Sint Maarten': 'Saint-Martin',
      Slovakia: 'Slovaquie',
      Slovenia: 'Slovénie',
      'Solomon Islands': 'Îles Salomon',
      Somalia: 'Somalie',
      'South Georgia and the South Sandwich Islands':
        'Géorgie du Sud-et-les Îles Sandwich du Sud',
      'South Sudan': 'Soudan du Sud',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Soudan',
      Suriname: 'Suriname',
      'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
      Swaziland: 'Eswatini',
      Sweden: 'Suède',
      Switzerland: 'Suisse',
      Syria: 'Syrie',
      Taiwan: 'Taïwan',
      Tajikistan: 'Tadjikistan',
      Tanzania: 'Tanzanie',
      Thailand: 'Thaïlande',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinité-et-Tobago',
      Tunisia: 'Tunisie',
      Turkey: 'Turquie',
      Turkmenistan: 'Turkménistan',
      Tuvalu: 'Tuvalu',
      Uganda: 'Ouganda',
      Ukraine: 'Ukraine',
      'United Kingdom': 'Royaume-Uni',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Ouzbékistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatican',
      Venezuela: 'Venezuela',
      Vietnam: 'Viêt Nam',
      'Western Sahara': 'Sahara occidental',
      Yemen: 'Yémen',
      Zambia: 'Zambie',
      Zimbabwe: 'Zimbabwe',
    };
    return translations[name] || name;
  }

  resetimagelogo() {
    const inputFile = document.getElementById('image_url') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = '';
    }
    this.file = null;
    this.fileExtention = '';
    this.fileError = '';
    this.fileName = '';
    this.userForm.get('image_url')?.setValue('');
  }
  isImage(): boolean {
    const allowedExtensions = [
      '.jpeg',
      '.jpg',
      '.png',
      '.svg',
      '.gif',
      '.jfif',
      '.avif',
    ];
    const fileExtension = (this.fileName || '')
      .toLowerCase()
      .substr((this.fileName || '').lastIndexOf('.'));
    return allowedExtensions.includes(fileExtension);
  }
  closeModal() {
    this.modalRef?.hide();
    this.userForm.reset();
    this.formData = new FormData();
    this.file = null;
    this.selectedImage = null;
    this.fileName = '';
    this.fileError = '';
    this.fileExtention = '';
    this.submitted = false;
  }

  openModal(template: TemplateRef<any>, data?: any) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    this.userForm = this.createUserForm(data);
    if (data) {
      this.id = data.id;
      // Ajoutez cette ligne
      this.userForm = this.createUserForm(data);
      console.log('openmodaluser', this.userForm);
      this.userForm.get('first_name')?.setValue(data.first_name);
      console.log('fisttttttttt', this.first_name);
    }
  }
  validPhoneNumber: boolean = false;
  SearchCountryField = SearchCountryField;
  onInputChange(data: any) {
    const phoneNumber = data.phoneNumber;
    this.validPhoneNumber = data.isNumberValid;
    console.log(
      '%cregister.component.ts line:137 isNumberValid ',
      'color: #007acc;',
      data.isNumberValid
    );
    this.userForm.get('phone_number')?.setValue(phoneNumber);
  }

  listadmin: any = [];
  /* get list admin */
  getListAdmin(sort?: ISort) {
    this.spinner.show();
    let data =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };
    this.candidatService
      .getlisteadmin(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.isLoadSpinner = false;
          console.log('admin', res);
          this.spinner.hide();
          this.listadmin = res.data.data;
          console.log('liste', this.listadmin);
          this.totalItems = res.data.total;
          console.log('total admin', this.totalItems);
          // this.userForm.get('image_url')?.setValue(data.image_url)
          console.log('image', this.userForm.value.image_url);
          if (this.file) this.formData.append('image_url', this.file);
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      event &&
      (event.startIndex !== this.startIndex ||
        this.endIndex !== event.endIndex ||
        this.itemsPerPage !== event.itemsPerPage ||
        this.currentPage !== event.currentPage)
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      this.itemsPerPage = event.itemsPerPage;
      this.currentPage = event.currentPage;
      if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getListAdmin();
      }
    }
  }

  sortData(item: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.currentPage = 1;
    this.endIndex = 5;
    let sort = { sort: this.currentSortDirection, sortAttribute: item }
    this.sortAttr = item
    console.log("sort", sort)

    this.getListAdmin(sort);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////

  openModal1(template: TemplateRef<any>, data?: any) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    this.userForm = this.createUserForm(data);
    if (data) {
      this.id = data.id;
      // Ajoutez cette ligne
      this.userForm = this.createUserForm(data);
      console.log('openmodaluser', this.userForm);
      this.userForm.get('first_name')?.setValue(data.first_name);
      console.log('fisttttttttt', this.first_name);

      this.userForm.get('image_url')?.setValue(data.image_url);
      console.log('image', this.userForm.value.image_url);
      if (this.file) this.formData.append('image_url', this.file);
    }
  }

  onSubmit() {
    console.log('this.userid', this.id);

    this.formData.append('first_name', this.userForm.get('first_name')?.value);
    console.log('fisttttttttt', this.first_name);
    this.formData.append('last_name', this.userForm.get('last_name')?.value);
    this.formData.append(
      'phone_number',
      this.userForm.get('phone_number')?.value
    );

    this.formData.append('email', this.userForm.get('email')?.value);
    if (this.file) {
      this.formData.append('image_url', this.file);
    }
    this.userForm.get('image_url')?.setValue(this.file);
    this.spinner.show();
    this.id = this.id;
    console.log('iddddddddddd', this.id);
    this.candidatService
      .updateCandidate(this.id, this.formData)
      .pipe()
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.formData = new FormData();
          if (res.status == 200) {
            const selectedRole = this.userForm.value.role_user_id.includes('1')
              ? 'Super admin'
              : 'Admin';
            const successMessage = `${selectedRole} a été modifié avec succès..`;
            this.toastrService.success(successMessage);

            this.fileName = '';
            this.getListAdmin();
            this.closeModal();
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.formData = new FormData();
          if (err?.error) {
            if (err.error?.message['email'])
              this.toastrService.error(emailExist);
            else this.toastrService.error(serverError);
          }
        },
      });
  }

  ngOnDestroy() {
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));

  }


}
