import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModulesRoutingModule } from './modules-routing.module';
import { QuestionCategoriesComponent } from './components/question-categories/question-categories.component';
import { ListCategoriesComponent } from './components/list-categories/list-categories.component';
import { DetailsCategoriesComponent } from './components/details-categories/details-categories.component';
import { FieldErrorModule } from '../../shared/components/shared-field-error/field-error.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';

@NgModule({
  declarations: [
    QuestionCategoriesComponent,
    ListCategoriesComponent,
    DetailsCategoriesComponent,
  ],
  imports: [
    CommonModule,
    ModulesRoutingModule,
    FieldErrorModule,
    NgxSpinnerModule,
    PaginationModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ModulesModule {}
