import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';
import { FieldErrorModule } from '../shared-field-error/field-error.module';



@NgModule({
  declarations: [PaginationComponent],
  imports: [
    CommonModule,
    FieldErrorModule
  ],
  exports:[PaginationComponent]
})
export class PaginationModule { }
