<div id="kt_app_toolbar" class="app-toolbar full-screen-spinner py-3 py-lg-6">
  <ngx-spinner class="custom-spinner"
  bdColor="rgba(255,255,255,1)"
    size="medium"
    color="#fff"
    type="ball-atom"
    [fullScreen]="true"
  >
  <span class="loader">
    <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
  </span>
  </ngx-spinner>
  <!--begin::Toolbar container-->
  <div
    id="kt_app_toolbar_container"
    class="app-container container-fluid d-flex flex-stack"
  >
    <!--begin::Page title-->
    <div
      class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
    >
      <!--begin::Title-->
      <div class="d-flex align-items-center">
        <h1 class="page-heading">
          <svg
            width="36"
            height="38"
            viewBox="0 0 36 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_373_934)">
              <path
                d="M4.60489 6.04201H8.77521C9.1081 6.04201 9.38048 5.76959 9.38048 5.43673V1.26638C9.38048 1.02433 9.23522 0.800305 9.00522 0.709573C8.78127 0.612708 8.521 0.667162 8.34549 0.836657L4.18119 5.00701C4.00564 5.18249 3.95118 5.44279 4.04801 5.66674C4.13882 5.89675 4.36276 6.04201 4.60489 6.04201Z"
                fill="#265D91"
              />
              <path
                d="M12.2618 21.9129V18.9894C12.2618 17.9881 13.0763 17.1736 14.0776 17.1736H14.8236L14.2934 16.6434C13.9505 16.3017 13.7614 15.846 13.7614 15.3607C13.7614 14.8754 13.9505 14.4197 14.2946 14.0769L16.3634 12.008C16.6719 11.68 17.1472 11.4778 17.6431 11.4778C18.1301 11.4778 18.587 11.6664 18.9287 12.008L19.4589 12.5383V11.7923C19.4589 10.791 20.2734 9.97647 21.2747 9.97647H23.3385V1.26677C23.3385 0.933843 23.0722 0.661499 22.7332 0.661499H10.486C10.5522 0.851977 10.5914 1.05502 10.5914 1.26677V5.43691C10.5914 6.43821 9.77686 7.25273 8.77556 7.25273H4.60542C4.39378 7.25273 4.19081 7.21343 4 7.14648V25.6473C4 25.9802 4.27234 26.2526 4.60528 26.2526H13.9076C13.8127 26.0304 13.7614 25.7902 13.7614 25.5416C13.7614 25.0563 13.9505 24.6006 14.2946 24.2578L14.823 23.7287H14.0776C13.0763 23.7287 12.2618 22.9142 12.2618 21.9129ZM10.6599 21.5943H7.63357C7.29902 21.5943 7.0283 21.3236 7.0283 20.989C7.0283 20.6545 7.29902 20.3838 7.63357 20.3838H10.6599C10.9945 20.3838 11.2652 20.6545 11.2652 20.989C11.2652 21.3236 10.9945 21.5943 10.6599 21.5943ZM10.6599 17.9627H7.63357C7.29902 17.9627 7.0283 17.692 7.0283 17.3574C7.0283 17.0228 7.29902 16.7521 7.63357 16.7521H10.6599C10.9945 16.7521 11.2652 17.0228 11.2652 17.3574C11.2652 17.692 10.9945 17.9627 10.6599 17.9627ZM10.6599 14.331H7.63357C7.29902 14.331 7.0283 14.0603 7.0283 13.7257C7.0283 13.3912 7.29902 13.1205 7.63357 13.1205H10.6599C10.9945 13.1205 11.2652 13.3912 11.2652 13.7257C11.2652 14.0603 10.9945 14.331 10.6599 14.331ZM10.6599 10.6994H7.63357C7.29902 10.6994 7.0283 10.4287 7.0283 10.0941C7.0283 9.75954 7.29902 9.48882 7.63357 9.48882H10.6599C10.9945 9.48882 11.2652 9.75954 11.2652 10.0941C11.2652 10.4287 10.9945 10.6994 10.6599 10.6994Z"
                fill="#265D91"
              />
              <path
                d="M31.3948 18.384H29.0706C28.9617 18.0572 28.8285 17.7364 28.6772 17.4337L30.3175 15.7874C30.5596 15.5514 30.5596 15.17 30.3175 14.934L28.2535 12.8639C28.0174 12.6279 27.6361 12.6279 27.4 12.8639L25.7537 14.5103C25.445 14.3529 25.1303 14.2258 24.8034 14.1168V11.7926C24.8034 11.4537 24.531 11.1873 24.1982 11.1873H21.2747C20.9418 11.1873 20.6694 11.4537 20.6694 11.7926V14.1168C20.3426 14.2258 20.0278 14.3529 19.7191 14.5103L18.0728 12.8639C17.9578 12.7489 17.8064 12.6884 17.643 12.6884C17.4856 12.6884 17.3283 12.7489 17.2193 12.8639L15.1493 14.934C14.9132 15.17 14.9132 15.5514 15.1493 15.7874L16.7956 17.4337C16.6382 17.7364 16.5111 18.0572 16.4022 18.384H14.0779C13.739 18.384 13.4727 18.6564 13.4727 18.9893V21.9128C13.4727 22.2457 13.739 22.5181 14.0779 22.5181H16.4022C16.5111 22.8449 16.6382 23.1597 16.7956 23.4684L15.1493 25.1147C14.9132 25.3508 14.9132 25.7321 15.1493 25.9682L17.2193 28.0321C17.4554 28.2742 17.8367 28.2742 18.0728 28.0321L19.7191 26.3918C20.0278 26.5431 20.3426 26.6763 20.6694 26.7853V29.1095C20.6694 29.4424 20.9418 29.7148 21.2747 29.7148H24.1982C24.531 29.7148 24.8034 29.4424 24.8034 29.1095V26.7853C25.1303 26.6763 25.445 26.5431 25.7537 26.3918L27.4 28.0382C27.509 28.1471 27.6664 28.2137 27.8237 28.2137C27.9871 28.2137 28.1385 28.1471 28.2535 28.0382L30.3175 25.9682C30.5596 25.7321 30.5596 25.3508 30.3175 25.1147L28.6772 23.4684C28.8285 23.1597 28.9617 22.8449 29.0706 22.5181H31.3948C31.7278 22.5181 32.0001 22.2457 32.0001 21.9128V18.9893C32.0001 18.6564 31.7278 18.384 31.3948 18.384ZM22.7334 24.3097C20.6088 24.3097 18.8778 22.5786 18.8778 20.448C18.8778 18.3235 20.6088 16.5925 22.7334 16.5925C24.8639 16.5925 26.595 18.3235 26.595 20.448C26.595 22.5786 24.8639 24.3097 22.7334 24.3097Z"
                fill="#265D91"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_373_934"
                x="0"
                y="0.661499"
                width="36"
                height="37.0533"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_373_934"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_373_934"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>

          CV {{ selectedCandidate?.first_name }}
          {{ selectedCandidate?.last_name }}
        </h1>

        <!--begin::Menu-->
      </div>
      <!--end::Title-->
      <!--begin::Breadcrumb-->
      <ul
        class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1"
      >
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a class="text-muted" routerLink="/">Accueil</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-400 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a class="text-muted" routerLink="/acceuil/candidats/list-candidat"
            >Liste des candidats</a
          >
        </li>
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-400 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">Cv candidat</li>
        <!--end::Item-->
      </ul>
      <!--end::Breadcrumb-->
    </div>
    <!--end::Page title-->
    <!--begin::Actions-->

    <!--end::Actions-->
  </div>
  <!--end::Toolbar container-->
</div>
<div class="row mt-10 app-container container-fluid">
  <div class="actions-profil">
    <a class="modifier" (click)="NavigateToUpdateCandidat()"
      ><i class="fa fa-refresh updateIcon" aria-hidden="true"></i> Modifier</a
    >
    <button
      class="download dropdown-toggle telecherger"
      type="button"
      id="dropdownMenuButton"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.70019 11.2752C7.13239 11.6339 7.56459 11.4187 7.70866 11.2752L12.0306 6.9709C12.3188 6.68394 12.3188 6.25351 12.0306 5.96655C11.7425 5.6796 11.3103 5.6796 11.0222 5.96655L7.92476 9.05133V0.729598C7.92476 0.299163 7.63663 0.012207 7.20443 0.012207C6.77223 0.012207 6.4841 0.299163 6.4841 0.729598V9.05133L3.38667 5.96655C3.09854 5.6796 2.66634 5.6796 2.37821 5.96655C2.09008 6.25351 2.09008 6.68394 2.37821 6.9709L6.70019 11.2752Z"
          fill="white"
        />
        <path
          d="M13.6863 11.4905C13.2541 11.4905 12.966 11.7774 12.966 12.2079V12.9253H1.44066V12.2079C1.44066 11.7774 1.15253 11.4905 0.720331 11.4905C0.288132 11.4905 0 11.7774 0 12.2079V13.6427C0 14.0731 0.288132 14.36 0.720331 14.36H13.6863C14.1185 14.36 14.4066 14.0731 14.4066 13.6427V12.2079C14.4066 11.7774 14.1185 11.4905 13.6863 11.4905Z"
          fill="white"
        />
      </svg>

      Télécharger
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" (click)="cvType === cvTypeEnum.WEGESTU ? exportCvWegest() : exportCvEsnCandidate(false)">PDF</a>
      <a class="dropdown-item" (click)="cvType === cvTypeEnum.WEGESTU ? exportCvAnonymePdf() : exportCvEsnCandidate(true)">PDF Anonyme</a>
      <!-- <a class="dropdown-item" (click)="exportCvWordWegest()">Word</a> -->
      <!-- <a class="dropdown-item" (click)="exportCvAnonymeWord()">Word Anonyme</a> -->
    </div>
    <a (click)="navigateToProfilCandidat(idCandidat)" class="annuler">
      <i class="fa-solid fa-xmark iconeXmark" style="color: #fc0909"></i>
      Annuler</a
    >
  </div>
</div>
<div id="kt_app_content" class="app-content flex-column-fluid pt-10">
  <!--begin::Content container-->
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Layout-->
    <body>
      <!--begin::Layout-->

      <div class="content-wrapper">
        <div class="row header" style="padding-bottom: 15px">
          <div class="logo-wegestu">
            <img
              *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo"
              src="/assets/media/logos/logo-wegestu-color.png"
              alt=""
              width="150px"
            />
            <img
              *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo && selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo!=='avatar.png'"
              src="{{
                baseUrl_Company +
                  selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo
              }}"
              alt=""
              width="100px"
            />
          </div>
          <div class="col-lg-4">
            <div class="photo-profil">
              <img
                *ngIf="
                  selectedCandidate?.image_url &&
                  selectedCandidate?.image_url != 'avatar.png' &&
                  selectedCandidate?.image_url != 'undefined'
                "
                src="{{
                  url +
                    '/api/User/file/gallery_users/' +
                    selectedCandidate?.image_url
                }} "
                alt=""
              />
              <img
                *ngIf="
                  !selectedCandidate?.image_url ||
                  (selectedCandidate?.image_url &&
                    (selectedCandidate?.image_url == 'avatar.png' ||
                      selectedCandidate?.image_url == 'undefined'))
                "
                class="table-img"
                src="/assets/media/logos/logo-avatar.jpg"
                alt=""
                width="75px"
              />
            </div>
          </div>
          <div class="col-lg-8">
            <div class="infos" style="margin-left: 25px">
              <div class="name-candidat">
                <h1>
                  {{ selectedCandidate?.first_name }}
                  {{ selectedCandidate?.last_name }}
                </h1>
              </div>
              <div
                class="poste"
                *ngIf="selectedCandidate?.desired_workstation?.length"
              >
                <p>
                  {{
                    selectedCandidate?.desired_workstation &&
                    selectedCandidate?.desired_workstation != "null"
                      ? selectedCandidate.desired_workstation
                      : "---"
                  }}
                </p>
              </div>
              <div
                class="contact"
                style="display: inline; margin: 0; padding: 0"
              >
                <ul>
                  <li *ngIf="selectedCandidate?.email" style="display: inline-block">
                    <div class="email" style="margin-right: 15px">
                      <img
                        src="/assets/media/icons/env.png"
                        alt=""
                        style="margin-right: 5px"
                      />
                      <span>
                        {{
                          selectedCandidate?.email
                            ? selectedCandidate.email
                            : "---"
                        }}</span
                      >
                    </div>
                  </li>
                  <li *ngIf="selectedCandidate?.phone_number" style="display: inline-block">
                    <div class="tel">
                      <img src="/assets/media/icons/tel.png" alt="" />
                      <span
                        >{{
                          selectedCandidate?.phone_number
                            ? selectedCandidate?.phone_number
                            : "---"
                        }}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="plus">
                <ul>
                  <li *ngIf="selectedCandidate?.linkedin_link?.length">
                    <div class="linkedin">
                      <img
                        src="/assets/media/icons/in.png"
                        alt=""
                        style="margin-right: 10px"
                      />

                      <a
                        href="{{ selectedCandidate?.linkedin_link }}"
                        target="_blank"
                      >
                        <span>{{
                          selectedCandidate?.linkedin_link
                            ? selectedCandidate.linkedin_link
                            : "---"
                        }}</span></a
                      >
                    </div>
                  </li>
                  <li *ngIf="selectedCandidate?.mobility?.length">
                    <div class="mobilite">
                      <img
                        src="/assets/media/icons/mob.png"
                        alt=""
                        style="margin-right: 5px"
                        s
                      />
                      <ng-container *ngFor="let item of selectedCandidate?.mobility; let last = last">
                        <span class="mobilite">{{ item }}</span>
                        <span *ngIf="!last">, </span>
                    </ng-container>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          class="propos"
          style="margin-top: 20px"
          *ngIf="selectedCandidate?.savoir_faire?.length"
        >
          <div class="title">
            <h4>
              <img src="/assets/media/icons/propos.png" alt="" />
              À propos
            </h4>
          </div>
          <div class="sep"></div>
          <p [innerHTML]="selectedCandidate?.savoir_faire">
           
          </p>
        </div>

        <div
          class="competences"
          style="margin-bottom: 10px"
          *ngIf="skillsCandidat?.length"
        >
          <div class="title espace">
            <h4>
              <img src="../public/images/compt.png" alt="" />
              Compétences
            </h4>
          </div>
          <div class="sep"></div>
          <div class="skills" style="padding-top: 10px">
            <table *ngFor="let skill of skillsCandidat">
              <tbody>
                <tr style="padding: 10px 0">
                  <td style="vertical-align: top; text-align: left">
                    <div class="title-skill" style="font-size: 12px">
                      <span>{{ skill?.name_FR }}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 100%">
                    <div class="subskills-grid">
                      <div
                        class="rating-module-cell"
                        *ngFor="let subSkill of skill.sub_category"
                      >
                        <div
                          class="rating-module"
                          style="font-size: 8px; font-weight: 600"
                        >
                          <div
                            class="module"
                            style="
                              width: 150px;
                              white-space: normal;
                              word-wrap: break-word;
                            "
                          >
                            <span>{{ subSkill?.name_FR }}</span>
                          </div>
                          <div class="rating">
                            <div class="stars">
                              <rating
                                [titles]="[' ', ' ', ' ', ' ', ' ']"
                                [(ngModel)]="subSkill.skills[0].stars"
                                [readonly]="true"
                                class="rating-stars"
                              ></rating>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="title-skill" style="font-size:12px" *ngIf="selectedCandidate?.skills_text?.length">
              <span>Autres Compétences : <span style="color:#265D91">{{selectedCandidate.skills_text}}</span> </span>
          </div>
          </div>
        </div>

        <div
          class="jobs"
          style="margin-bottom: 15px"
          *ngIf="selectedCandidate?.prof_experience?.length"
        >
          <div class="title">
            <h4>
              <img src="/assets/media/icons/jobs.png" alt="" />
              expériences professionnelles
            </h4>
          </div>
          <div class="sep"></div>
          <div class="content2 pb-5" id="info-bloc">
            <div class="compt">
              <table
                class="table align-middle gy-5 w-100"
                *ngFor="let item of selectedCandidate?.prof_experience"
              >
                <thead>
                  <tr>
                    <th
                      class="min-w-125px"
                      colspan="3"
                      style="text-align: left; color: black; font-size: 16px"
                    >
                    {{capitalizeFirstLetter(item?.poste)}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="item?.start_date">
                    <td><span class="attribut">Période</span></td>
                    <td>
                      <span style="color: #2C2C2C; font-size: 12px; font-weight: 400;">

                        du {{item?.start_date | date:'MM/yyyy'}}
                      <span *ngIf="item.current_position =='0'"> au {{ item?.end_date | date : "MM/yyyy" }}</span> 
                  </span>
                  <span style="color: #2C2C2C; font-size: 12px; font-weight: 400;" *ngIf="item.current_position =='1'">
                     Jusqu'à présent
                  </span>
                  <!-- <span> ({{item.period}})</span> -->
                  <span>({{ formatPeriod(item.period) }})</span>

                  </td>
                  </tr>

                  <tr *ngIf="item?.client">
                    <td><span class="attribut">Client</span></td>
                    <td>
                      <span
                        style="
                          color: #2c2c2c;
                          font-size: 12px;
                          font-weight: 400;
                        "
                        >{{ item?.client }}</span
                      >
                    </td>
                  </tr>

                  <tr *ngIf="item?.project">
                    <td><span class="attribut">Projet</span></td>
                    <td>
                      <span
                        style="
                          color: #2c2c2c;
                          font-size: 12px;
                          font-weight: 400;
                        "
                        >{{ item?.project ? item?.project : "---" }}</span
                      >
                    </td>
                  </tr>
                  <tr *ngIf="item?.description">
                    <td><span class="attribut">Descriptif</span></td>
                    <td>
                      <span
                        style="
                          color: #2c2c2c;
                          font-size: 12px;
                          font-weight: 400;
                        "
                        [innerHTML]="item?.description"></span
                      >
                    </td>
                  </tr>
                  <tr *ngIf="item?.method">
                    <td><span class="attribut">Méthode</span></td>
                    <td>
                      <span
                        style="
                          color: #2c2c2c;
                          font-size: 12px;
                          font-weight: 400;
                        "
                        >{{ item?.method }}</span
                      >
                    </td>
                  </tr>
                  <tr *ngIf="item?.tasks">
                    <td><span class="attribut">Tâches</span></td>
                    <td>
                      <ul>
                        <li *ngFor="let task of item?.tasks.split('\n')">
                          <span
                            style="
                              color: #2c2c2c;
                              font-size: 12px;
                              font-weight: 400;
                            "
                            >{{ task.trim() }}</span
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr *ngIf="item?.technologies?.length">
                    <td><span class="attribut">Outils & technologies</span></td>
                    <td>
                      <span
                        style="
                          color: #2c2c2c;
                          font-size: 12px;
                          font-weight: 400;
                        "
                        >{{ item?.technologies[0] }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          class="formations"
          style="margin-bottom: 15px"
          *ngIf="selectedCandidate?.diplome?.length"
        >
          <div class="title">
            <h4>
              <img src="/assets/media/icons/formations.png" alt="" />
              Diplômes
            </h4>
          </div>
          <div class="sep"></div>
          <div
            class="formation"
            *ngFor="let item of selectedCandidate?.diplome"
          >
            <div class="diplome">
              <span style="color: #265d91; font-size: 12px; font-weight: 500">{{
                item?.date | date : "yyyy"
              }}</span>
              <span style="color: #2c2c2c; font-size: 12px; font-weight: 500">
                {{ item?.name }}
              </span>
            </div>
            <div class="uni">
              <span style="margin-left: 25px; color: #265d91; font-size: 12px">
                {{ item?.ecole }}
              </span>
            </div>
          </div>
        </div>

        <div
          class="certifications"
          style="margin-bottom: 15px"
          *ngIf="selectedCandidate?.certificat?.length"
        >
          <div class="title">
            <h4>
              <img src="/assets/media/icons/certifs.png" alt="" />
              Certifications
            </h4>
          </div>
          <div class="sep"></div>
          <div
            class="certification"
            *ngFor="let item of selectedCandidate.certificat"
          >
            <div class="date">
              <span style="color: #265d91; font-size: 12px; font-weight: 500">{{
                item?.date | date : "yyyy"
              }}</span>
              <span style="color: #2c2c2c; font-size: 12px">
                {{ item?.name }}
              </span>
            </div>
          </div>
        </div>

        <div
          class="languages"
          style="margin-bottom: 15px"
          *ngIf="selectedCandidate?.langue_user?.length"
        >
          <div class="title">
            <h4>
              <img src="assets/media/icons/langues.png" alt="" />
              langue
            </h4>
          </div>
          <div class="sep"></div>
          <div class="contact-info table-alignement">
            <table class="table align-middle gy-5">
              <tbody>
                <tr>
                  <td style="width: 100%">
                    <div class="languages-grid">
                      <div
                        class="language-item"
                        *ngFor="let item of selectedCandidate?.langue_user"
                      >
                        <div
                          style="
                            color: #2c2c2c;
                            font-size: 12px;
                            text-align: left;
                            margin-right: 10px;
                            width: 100px;
                          "
                        >
                          {{ item?.name }}
                        </div>
                        <div class="value">
                          <div class="stars">
                            <rating
                              [titles]="[' ', ' ', ' ', ' ', ' ']"
                              [(ngModel)]="item.stars"
                              [readonly]="true"
                              class="rating-stars"
                            ></rating>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="languages" style="margin-bottom: 15px;" *ngIf="selectedCandidate?.softSkills?.length>0">
          <div class="title">
              <h4>
                  <img src="/assets/media/icons/compt.png" alt="">
                  Soft Skills</h4>
          </div>
          <div class="sep"></div>
          <div class="soft">
            <ul>
                <li *ngFor="let item of selectedCandidate?.softSkills">
                    <span>{{item}}</span>
                </li>
              
            </ul>
           
         </div>
</div>

      </div>
    </body>

    <!--end::Content container-->
  </div>
</div>
