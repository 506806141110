import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICountry } from 'country-state-city';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  confirmDelete,
  createAccount,
  deletedAccount,
  existedCodeBic,
  existedIBAN,
  existedName,
  failedDelete,
  serverError,
  updatedAccount,
} from '../../../../../core/models/messages';
import { IAccountBank } from '../../../../../core/models/setting';
import { BankAccountService } from '../../../../../core/services/bank-account/bank-account.service';
import { PaysService } from '../../../../../shared/services/pays.service';
import Swal from 'sweetalert2';
declare var Stripe: any;
@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.css'],
})
export class BankAccountsComponent {
  /* numbers */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  idAccount!: number;
  pagination : boolean =false;

  /* string */
  mode: string = 'create_account';
  searchValue: string = '';
  /* array */
  pays: { id: number; name: string }[] = [
    { id: 1, name: 'Tunisie' },
    { id: 1, name: 'France' },
  ];
  liastAccounts: any;

  paysList: ICountry[] = [];

  /* formGroup */
  addAccountForm: FormGroup = this.createAccountForm();
  /* modal */
  modalRef?: BsModalRef;
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private bankAccountService: BankAccountService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private paysServices: PaysService
  ) {}
  /* init */
  account = {
    account_holder_name: '',
    company_name: '',
    company_tax_id: '',
    country: 'FR',
    currency: 'eur',
    routing_number: '',
    account_number: '',
  };
  message!: string;
  stripe: any;
  ngOnInit(): void {
    this.paysList = this.paysServices.getAllCountries();
    this.getListAccounts();
    this.stripe = Stripe(
      'pk_live_51OlbLsEOYgTbI3nHWsp8UYb7kowU1jHywB6sOtJYjBO0b4cd7qK8QMElyXJvMH0YPj0tGGg9udTjiWHXEU0W7yu9004RDXAxfH'
    );
  }
  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      Afghanistan: 'Afghanistan',
      'South Africa': 'Afrique du Sud',
      Albania: 'Albanie',
      Algeria: 'Algérie',
      Germany: 'Allemagne',
      Andorra: 'Andorre',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctique',
      'Antigua and Barbuda': 'Antigua-et-Barbuda',
      'Saudi Arabia': 'Arabie saoudite',
      Argentina: 'Argentine',
      Armenia: 'Arménie',
      Aruba: 'Aruba',
      Australia: 'Australie',
      Austria: 'Autriche',
      Azerbaijan: 'Azerbaïdjan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahreïn',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbade',
      Belgium: 'Belgique',
      Belize: 'Belize',
      Benin: 'Bénin',
      Bermuda: 'Bermudes',
      Bhutan: 'Bhoutan',
      Belarus: 'Biélorussie',
      Bolivia: 'Bolivie',
      'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
      Botswana: 'Botswana',
      Brazil: 'Brésil',
      Brunei: 'Brunei',
      Bulgaria: 'Bulgarie',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      'Cape Verde': 'Cabo Verde',
      Cambodia: 'Cambodge',
      Cameroon: 'Cameroun',
      Canada: 'Canada',
      Chile: 'Chili',
      China: 'Chine',
      Cyprus: 'Chypre',
      Colombia: 'Colombie',
      Comoros: 'Comores',
      'Congo - Brazzaville': 'Congo-Brazzaville',
      'Congo - Kinshasa': 'Congo-Kinshasa',
      'North Korea': 'Corée du Nord',
      'South Korea': 'Corée du Sud',
      'Costa Rica': 'Costa Rica',
      'Ivory Coast': 'Côte d’Ivoire',
      Croatia: 'Croatie',
      Cuba: 'Cuba',
      Curaçao: 'Curaçao',
      Denmark: 'Danemark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominique',
      'Dominican Republic': 'République dominicaine',
      Ecuador: 'Équateur',
      Egypt: 'Égypte',
      'United Arab Emirates': 'Émirats arabes unis',
      Eritrea: 'Érythrée',
      Spain: 'Espagne',
      Eswatini: 'Eswatini',
      Estonia: 'Estonie',
      'United States': 'États-Unis',
      Ethiopia: 'Éthiopie',
      Fiji: 'Fidji',
      Finland: 'Finlande',
      France: 'France',
      Gabon: 'Gabon',
      Gambia: 'Gambie',
      Georgia: 'Géorgie',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Grèce',
      Grenada: 'Grenade',
      Greenland: 'Groenland',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernesey',
      Guinea: 'Guinée',
      'Guinea-Bissau': 'Guinée-Bissau',
      'Equatorial Guinea': 'Guinée équatoriale',
      Guyana: 'Guyana',
      'French Guiana': 'Guyane française',
      Haiti: 'Haïti',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Hongrie',
      Iceland: 'Islande',
      India: 'Inde',
      Indonesia: 'Indonésie',
      Iraq: 'Irak',
      Iran: 'Iran',
      Ireland: 'Irlande',
      'Isle of Man': 'Île de Man',
      Israel: 'Israël',
      Italy: 'Italie',
      Jamaica: 'Jamaïque',
      Japan: 'Japon',
      Jersey: 'Jersey',
      Jordan: 'Jordanie',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      Kuwait: 'Koweït',
      Kyrgyzstan: 'Kirghizistan',
      Laos: 'Laos',
      Latvia: 'Lettonie',
      Lebanon: 'Liban',
      Lesotho: 'Lesotho',
      Liberia: 'Libéria',
      Libya: 'Libye',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lituanie',
      Luxembourg: 'Luxembourg',
      Macao: 'Macao',
      'North Macedonia': 'Macédoine du Nord',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaisie',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malte',
      'Marshall Islands': 'Îles Marshall',
      Martinique: 'Martinique',
      Mauritania: 'Mauritanie',
      Mauritius: 'Maurice',
      Mayotte: 'Mayotte',
      Mexico: 'Mexique',
      Micronesia: 'Micronésie',
      Moldova: 'Moldavie',
      Monaco: 'Monaco',
      Mongolia: 'Mongolie',
      Montenegro: 'Monténégro',
      Montserrat: 'Montserrat',
      Morocco: 'Maroc',
      Mozambique: 'Mozambique',
      Myanmar: 'Myanmar',
      Namibia: 'Namibie',
      Nauru: 'Nauru',
      Nepal: 'Népal',
      Netherlands: 'Pays-Bas',
      'New Caledonia': 'Nouvelle-Calédonie',
      'New Zealand': 'Nouvelle-Zélande',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Île Norfolk',
      'Northern Mariana Islands': 'Îles Mariannes du Nord',
      Norway: 'Norvège',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palaos',
      Palestine: 'Palestine',
      Panama: 'Panama',
      'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
      Paraguay: 'Paraguay',
      Peru: 'Pérou',
      Philippines: 'Philippines',
      'Pitcairn Islands': 'Îles Pitcairn',
      Poland: 'Pologne',
      Portugal: 'Portugal',
      'Puerto Rico': 'Porto Rico',
      Qatar: 'Qatar',
      Romania: 'Roumanie',
      Russia: 'Russie',
      Rwanda: 'Rwanda',
      Réunion: 'Réunion',
      'Saint Barthélemy': 'Saint-Barthélemy',
      'Saint Helena': 'Sainte-Hélène',
      'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
      'Saint Lucia': 'Sainte-Lucie',
      'Saint Martin': 'Saint-Martin',
      'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
      'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
      Samoa: 'Samoa',
      'San Marino': 'Saint-Marin',
      'Sao Tome and Principe': 'Sao Tomé-et-Principe',
      Senegal: 'Sénégal',
      Serbia: 'Serbie',
      Seychelles: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapour',
      'Sint Maarten': 'Saint-Martin',
      Slovakia: 'Slovaquie',
      Slovenia: 'Slovénie',
      'Solomon Islands': 'Îles Salomon',
      Somalia: 'Somalie',
      'South Georgia and the South Sandwich Islands':
        'Géorgie du Sud-et-les Îles Sandwich du Sud',
      'South Sudan': 'Soudan du Sud',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Soudan',
      Suriname: 'Suriname',
      'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
      Swaziland: 'Eswatini',
      Sweden: 'Suède',
      Switzerland: 'Suisse',
      Syria: 'Syrie',
      Taiwan: 'Taïwan',
      Tajikistan: 'Tadjikistan',
      Tanzania: 'Tanzanie',
      Thailand: 'Thaïlande',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinité-et-Tobago',
      Tunisia: 'Tunisie',
      Turkey: 'Turquie',
      Turkmenistan: 'Turkménistan',
      Tuvalu: 'Tuvalu',
      Uganda: 'Ouganda',
      Ukraine: 'Ukraine',
      'United Kingdom': 'Royaume-Uni',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Ouzbékistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatican',
      Venezuela: 'Venezuela',
      Vietnam: 'Viêt Nam',
      'Western Sahara': 'Sahara occidental',
      Yemen: 'Yémen',
      Zambia: 'Zambie',
      Zimbabwe: 'Zimbabwe',
    };
    return translations[name] || name;
  }

  async onSubmit() {
    if (this.addAccountForm.invalid) {
      return;
    }

    const formValue = this.addAccountForm.value;

    try {
      const accountTokenResponse = await this.stripe.createToken('account', {
        business_type: 'company',
        company: {
          name: formValue.company_name,
          tax_id: formValue.company_tax_id,
        },
        tos_shown_and_accepted: true,
      });

      if (accountTokenResponse.error) {
        this.message = accountTokenResponse.error.message;
        return;
      }
      this.spinner.show();
      this.bankAccountService
        .addCompanyBankAccount({
          bank_account_token: accountTokenResponse.token.id,
        })
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.toastr.success(createAccount);
              this.getListAccounts();
              this.closeModal();
              this.spinner.hide();
              this.addAccountForm.reset();
            }
          },
          error: (err) => {
            this.spinner.hide();
            this.nameExists = false;
          },
        });
    } catch (error: any) {
      this.message = 'Failed to add company account: ' + error.message;
    }
  }

  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
         if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
          this.getListAccounts();
        }
    }
  }
  getListAccounts() {
    // let data ={per_page: this.itemsPerPage, page: this.currentPage}
    this.spinner.show();
    this.bankAccountService
      .getAccountsList()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.liastAccounts = res.data;
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* convert iban and code bic to stars */
  convertToStars(number: any) {
    // let number = 123456789;
    let numberString = number.toString();
    let lastFourDigits = numberString.slice(-4);
    let maskedNumber = '*****' + lastFourDigits;
    return maskedNumber;
  }

  /* create add subCategory form */
  createAccountForm(data?: IAccountBank) {
    return this.formBuilder.group({
      account_holder_name: [
        data?.account_holder_name || '',
        [Validators.required],
      ],
      company_name: [data?.company_name || '', [Validators.required]],
      company_tax_id: [data?.company_tax_id || '', [Validators.required]],
      country: [data?.country || 'FR', [Validators.required]],
      currency: [data?.currency || 'eur', [Validators.required]],
      routing_number: [data?.routing_number || '', [Validators.required]],
      account_number: [data?.account_number || '', [Validators.required]],
    });
  }
  // createAccountForm(data?: IAccountBank) {
  //   return this.formBuilder.group({
  //     name: [data?data.name:"", [Validators.required]],
  //     iban: [data?data.iban:"", [Validators.required,Validators.pattern(patternIBAN)]],
  //     codeBic: [data?data.codeBic:"", [Validators.required,Validators.pattern(patternBIC)]],
  //     tva: [data?data.tva:"", [Validators.required]],
  //     country: [data?data.country:"", [Validators.required]],

  //   })
  // }
  submitData() {
    if (this.mode == 'create_account') {
      this.addAccount();
    } else {
      this.updateAccount();
    }
  }
  nameExists: boolean = false;

  addAccount() {
    this.spinner.show();
    this.bankAccountService
      .addAccount(this.addAccountForm.value)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.toastr.success(createAccount);
            this.getListAccounts();
            this.closeModal();
            this.spinner.hide();
            this.addAccountForm.reset();
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.nameExists = false;
          if (err.error) {
            if (err.error.message && err.error.message['name']) {
              this.toastr.info(existedName);
            } else {
              // Handle other error conditions
              if (err.error.message && err.error.message['iban'])
                this.toastr.info(existedIBAN);
              else if (err.error.message && err.error.message['codeBic'])
                this.toastr.info(existedCodeBic);
              else this.toastr.error(serverError);
            }
          }
          // if(err.error){
          //    if(err.error.message&&err.error.message['iban']) this.toastr.info(existedIBAN)
          //   else if(err.error.message&&err.error.message['codeBic']) this.toastr.info(existedCodeBic)
          //   else if(err.error.message&&err.error.message['name']) this.toastr.info(existedName)
          // else this.toastr.error(serverError)
          // }
        },
      });
  }

  updateAccount() {
    this.spinner.show();
    this.bankAccountService
      .updateAccount(this.idAccount, this.addAccountForm.value)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.toastr.success(updatedAccount);
            this.getListAccounts();
            this.closeModal();
            this.spinner.hide();
            this.addAccountForm.reset();
          }
        },
        error: (err) => {
          this.spinner.hide();
          if (err.error) {
            if (err.error.message && err.error.message['iban'])
              this.toastr.info(existedIBAN);
            else if (err.error.message && err.error.message['codeBic'])
              this.toastr.info(existedCodeBic);
            else if (err.error.message && err.error.message['name'])
              this.toastr.info(existedName);
            else this.toastr.error(serverError);
          }
        },
      });
  }
  /* open delete alert */
  openDeleteAlert(account: IAccountBank) {
    Swal.fire({
      title: `${confirmDelete} ce compte?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteAccount(account.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  /* delete account */
  deleteAccount(id: number) {
    this.spinner.show();
    this.bankAccountService
      .deleteAccount(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.getListAccounts();
            this.toastr.success(deletedAccount);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  openModal(template: TemplateRef<any>, mode: string, account?: IAccountBank) {
    this.mode = mode;
    if (account) {
      this.idAccount = account.id;
      this.addAccountForm = this.createAccountForm(account);
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef!.hide();
    this.addAccountForm.reset();
  }
  /* key inter number */
  keyPress(event: any) {
    let pattern = /^[\d]$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    return pattern;
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
