import { Component } from '@angular/core';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoeaderComponent {
  showSpinner: boolean = false;
  constructor(private LoaderService: LoaderService) { }

  
  ngOnInit(): void {
    this.LoaderService.spinnerState$.subscribe((isVisible: boolean) => {
      this.showSpinner = isVisible;
    });
  }
}
