import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../../../core/models/messages';
import { SettingsService } from '../../../../../../core/services/settings/settings.service';

@Component({
  selector: 'app-evaluation-details',
  templateUrl: './evaluation-details.component.html',
  styleUrls: ['./evaluation-details.component.css'],
})
export class EvaluationDetailsComponent {
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  listCandidatReview: any;
  type: any;
  idDonneur: any;

  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private settingService: SettingsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.type = +params['type'];
      this.idDonneur = +params['idDonneur'];
    });
    this.getCandidatReview();
  }

  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
    }
  }

  getCandidatReview() {
    this.spinner.show();
    let data = { per_page: this.itemsPerPage, page: this.currentPage };
    this.settingService
      .getCandidatReviewById(this.type, this.idDonneur, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listCandidatReview = res;
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
