import { Component, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedQuestion,
  confirmDelete,
  deletedQuestion,
  existedEvaluation,
  failedDelete,
  serverError,
  updatedQuestion,
} from '../../../../../core/models/messages';
import { IQuestion } from '../../../../../core/models/setting';

import { SettingsService } from '../../../../../core/services/settings/settings.service';
import { environment } from '../../../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-evaluation-societe',
  templateUrl: './evaluation-societe.component.html',
  styleUrls: ['./evaluation-societe.component.css'],
})
export class EvaluationSocieteComponent {
  /* number */
  currentSortDirection: number = 2;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  typeCompany: number = 2;
  /* string */
  url: string = environment.baseUrl + '/api/Company/file/gallery_company/';
  /*  */
  listCompanies: any = [];
  idQuestion!: number | undefined;
  idCompany!: number;
  mode: string = 'create';

  /* modal */
  modalRef?: BsModalRef;
  /* array */
  listQuestion: IQuestion[] = [];
  listComments: any = [];
  /* formGroup */
  questionForm: FormGroup = this.createQuestionForm();
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();

  previousQuestionValue: string = ''; // Stocker la valeur précédente de la question
  disableConfirmButton: boolean = true; // Indicateur pour désactiver le bouton "Confirmer"
  pagination : boolean =false;


  constructor(
    private settingService: SettingsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {}
  /* init */
  ngOnInit() {
    this.getListCompanies();
    this.getListQuestion();
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
         if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
          this.getListCompanies();
        }
    }
  }
  getListCompanies() {
    this.spinner.show();
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      sort: this.currentSortDirection,
      type: this.typeCompany,
    };
    this.settingService
      .getListCompany(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.totalItems = res.data.total;
            this.listCompanies = res.data.data.slice(0, this.endIndex);
            this.spinner.hide();
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* open delete alert */
  openDeleteAlert(question: IQuestion) {
    Swal.fire({
      title: `${confirmDelete} cette question ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteQuestion(question.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  /* delete question */
  deleteQuestion(id: number) {
    this.spinner.show();
    this.settingService
      .deleteQuestionCompany(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListQuestion();
            this.spinner.hide();
            this.toastr.success(deletedQuestion);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* add question */
  addQuestion() {
    this.questionForm.get('company_id')?.setValue(this.idCompany);
    if (this.questionForm.valid) {
      this.spinner.show();
      this.settingService
        .addQuestionCompany(this.questionForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.getListQuestion();
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(addedQuestion);
              window.location.reload();
            }
          },
          error: (err) => {
            console.log('error', err);
            this.spinner.hide();
            if (err?.error) {
              if (err.error?.message['question'])
                this.toastr.info(existedEvaluation);
              if (
                err.error.data.question[0] ===
                'The question has already been taken.'
              )
                this.toastr.info(existedEvaluation);
              else this.toastr.error(serverError);
            }
          },
        });
    }
  }

  checkQuestionChange() {
    console.log('disableConfirmButton', this.disableConfirmButton);
    const currentQuestionValue = this.questionForm.controls['question'].value;
    if (currentQuestionValue === this.previousQuestionValue) {
      this.disableConfirmButton = true; // Désactiver le bouton si la question reste la même
    } else {
      this.disableConfirmButton = false; // Activer le bouton si la question change
    }

    console.log('disableConfirmButton', this.disableConfirmButton);
  }

  getListQuestion() {
    let data = {
      company_id: this.idCompany,
    };
    this.spinner.show();
    this.settingService
      .getSpecificReview(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.totalItems = res.data.total;
            this.listQuestion = res.data.data;
            if (this.currentPage > 1 && !res.data.length) {
              this.currentPage = 1;
              this.getListQuestion();
            }
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* update question */
  updateQuestion() {
    this.questionForm.get('company_id')?.setValue(this.idCompany);
    if (this.questionForm.valid) {
      this.spinner.show();
      this.settingService
        .updateQuestionCompany(this.idQuestion, this.questionForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.getListQuestion();
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(updatedQuestion);
              this.disableConfirmButton = true;
            }
          },
          error: (err) => {
            console.log('error', err);
            this.spinner.hide();
            if (err?.error) {
              if (err.error?.message['question'])
                this.toastr.info(existedEvaluation);
              if (
                err.error.data.question[0] ===
                'The question has already been taken.'
              )
                this.toastr.info(existedEvaluation);
              else this.toastr.error(serverError);
            }
          },
        });
    }
  }
  submitData() {
    if (this.mode == 'create') {
      this.addQuestion();
    } else {
      this.updateQuestion();
    }
  }
  /* create add question form */
  createQuestionForm(data?: IQuestion) {
    return this.formBuilder.group({
      question: [
        data ? data.question : '',
        [Validators.required, this.notOnlySpacesValidator()],
      ],
      company_id: [''],
    });
  }
  closeModal() {
    this.modalRef?.hide();
    this.questionForm.reset();
    this.disableConfirmButton = true;
  }
  notOnlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value as string;
      const isValid = value?.trim() !== '';
      return isValid ? null : { onlySpaces: true };
    };
  }

  showEvaluateCompany(idCompany: number, type: string) {
    this.router.navigate([
      '/acceuil/parametres/list-avis-societe',
      { id: idCompany, type: type },
    ]);
  }
  openModal(template: TemplateRef<any>, mode: string, data?: IQuestion) {
    this.mode = mode;
    if (mode == 'update') {
      this.idQuestion = data?.id;
      this.questionForm = this.createQuestionForm(data);
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
