<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card headeer-->
        
          <div class="card-body ">
          <div class="action-grid mb-10">
            <div class="title">
                <h4>Historique des abonnements</h4>
               </div>
             
          </div>
          <div class="container ">
            <div class="row mb-3">
                <div class="card-title">
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        data-kt-customer-table-filter="search"
                        [(ngModel)]="searchElement" (keyup.enter)="applyFilter()"
                        class="form-control form-control-solid ps-15"
                        placeholder="Rechercher"
                      />
                      
                      <div class="input-group-append">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                            fill="#7E7C7C"
                          />
                        </svg>
          
                        <button
                          class="input-group-text cursor-pointer"
                          id="basic-addon2"
                          (click)="applyFilter()"
                        >
                          <span>Rechercher</span>
                        </button>
                      </div>
                    </div>
                  </div>
                <div class="col-lg-3 ">
                      
                          </div> 
                          <div class="col-lg-3 ">
                      
                          </div> 
                <div class="col-lg-3">
                 
                   
                </div>
               
            </div>
         
            <div class="table-responsive mt-15">
                <table class="table align-middle fs-6 gy-5 style-table" id="list_cra">
                    <!-- 
                      <thead>
                        <th class="min-w-125px">
                            <span>ID Facture
                            </span>
                        </th>
                        <th class="min-w-125px">
                            <span>Email client</span>
                        </th>
                        <th class="min-w-125px">
                           <span> Type</span>
                        </th>
                        <th class="min-w-125px">
                            <span>Status</span>
                        </th>
                        <th class="min-w-125px">
                            <span>Actions</span>
                        </th>
                    </thead>
                    -->
                  
                    <thead>
                        <th class="min-w-125px">
                            <span>Nom Pack</span>
                        </th>
                        <th class="min-w-125px">
                            <span>Date D’abonnement</span>
                        </th>
                        <th class="min-w-125px">
                           <span> Prix(HT)</span>
                        </th>
                        <th class="min-w-125px">
                            <span>Paiement </span>
                        </th>
                        <th class="min-w-125px">
                            <span>Status</span>
                        </th>
                        <!-- <th class="bg2 min-w-125px">
                            Facture
                        </th> -->
                        <!-- <th class="bg4 min-w-125px">
                            Historique
                        </th> -->
                        <th class="min-w-125px">
                            <span>Actions</span>
                        </th>
                        
                    </thead>

                 <tbody>
                    <tr *ngFor="let abonnement of listAbonnement ">
                        <!-- 
                         <td (click)="navigateToDetails(abonnement.id)">
                            <span class="first-td">{{abonnement.id}}</span>
                        </td>
                    <td>
                        <span> {{abonnement.offer.customer_email}} </span>
                    </td>
                    <td>
                        <span>{{abonnement.billing_reason}}</span>
                    </td>
                        -->
                        <!-- (click)="navigateToDetails(abonnement.id)" -->
                        <td>
                            <span class="first-td">{{abonnement.offer?.name ? abonnement.offer?.name:'---'}}</span>
                        </td>
                    <td>
                        <span> {{abonnement.offer?.start_date ? abonnement.offer?.start_date:'---'}} </span>
                    </td>
                    <td>
                        <span>{{abonnement?.total_price ? abonnement?.total_price:'---'}}$</span>
                    </td>

                    <td>
                        <span class="badge actif" *ngIf="abonnement.state==2"> <i
                            class="fa-solid fa-circle"></i> Payer</span>
                    <span class="badge desactif" *ngIf="abonnement.state==1"> <i
                            class="fa-solid fa-circle"></i> Non Payer</span>
                    </td>
                    
                    <td>
                        <span class="badge actif" *ngIf="abonnement.active==1"> <i
                            class="fa-solid fa-circle"></i> Activer</span>
                    <span class="badge desactif" *ngIf="abonnement.active==2"> <i
                            class="fa-solid fa-circle"></i> Désactiver</span>
                    </td>
                    <!-- <td>
                        <a class="cursor-pointer" (click)="exportInvoice(abonnement.facture_id)"><i class="fa-solid fa-cloud-arrow-down" style="color:#265D91;font-size: 20px;"></i></a>
                    </td> -->
                    <td>
                        <!-- <a href="#" class="factures" routerLink="/invoices">historique Factures</a> -->

                        <div class="tools">
                            <div class="dropdown">
                                <button
                                    class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                    type="button" id="dropdownMenuButton"
                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <svg width="19" height="5" viewBox="0 0 19 5"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2.44739" cy="2.44739" r="2.44739"
                                            transform="matrix(1 0 0 -1 0 4.89478)"
                                            fill="#265D91" />
                                        <circle cx="2.44739" cy="2.44739" r="2.44739"
                                            transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                            fill="#265D91" />
                                        <circle cx="2.44739" cy="2.44739" r="2.44739"
                                            transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                            fill="#265D91" />
                                    </svg>
                                </button>
                                <div class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton">
                                    <!-- <a class="dropdown-item"  (click)="navigateToDetails(abonnement.id)">Voir Détail</a> -->
                                    <a class="dropdown-item"  (click)="navigateToAddOffer('edit',abonnement.offer_id)">Modifier</a>
                                    <a class="dropdown-item"  (click)="navigateToInvoiceHistory(abonnement.id,abonnement.stripe_sub_id)">Historique de facture</a>
                                </div>
                            </div>
                          </div>
                    </td>
                    </tr>
                 </tbody>
    
                </table>
             </div>
          
             <div class="alert alert-warning text-center mt-5" role="alert"
             *ngIf="listAbonnement && !listAbonnement.length">
             <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
             <span class="empty-list">
                 La liste est vide
             </span>
         </div>
         <div class="pagination" *ngIf="listAbonnement && listAbonnement?.length">
            <app-pagination [data]="listAbonnement" [currentPageList]="currentPage" [lengthData]="totalItems" (setPages)="getItems($event)">
            </app-pagination>
        </div>
          </div>
        
           
          </div>
        </div>
     
    </div>
    <!--end::Content container-->
</div>

