<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div class="app-main flex-column flex-row-fluid" id="kt_app_main">
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
      <!--begin::Toolbar-->
      <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
        <!--begin::Toolbar container-->
        <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
          <!--begin::Page title-->
          <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
          <div class="title">
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_61_103)">
              <path d="M22.2955 13.5729C22.2955 16.065 21.3318 18.4551 19.6162 20.2171C17.9006 21.9793 15.5738 22.9693 13.1478 22.9693C10.7216 22.9693 8.39481 21.9793 6.67936 20.2171C4.96379 18.4549 4 16.0649 4 13.5729C4 11.0809 4.96379 8.69083 6.67936 6.92877C8.39494 5.16659 10.7217 4.17661 13.1478 4.17661C13.4175 4.17661 13.676 4.28654 13.8665 4.4823C14.0571 4.67806 14.1643 4.94371 14.1643 5.22059V12.5288H21.2792C21.5487 12.5288 21.8073 12.6389 21.9979 12.8347C22.1885 13.0304 22.2955 13.2959 22.2955 13.5729Z" fill="#265D91"/>
              <path d="M17.4169 0C17.1532 0 16.9005 0.107665 16.7141 0.299071C16.5278 0.490478 16.423 0.750091 16.423 1.02095V9.18778V9.18761C16.423 9.45849 16.5278 9.71808 16.7141 9.90949C16.9005 10.1009 17.1532 10.2086 17.4169 10.2086H25.3677H25.3675C25.6312 10.2086 25.884 10.1009 26.0703 9.90949C26.2567 9.71808 26.3615 9.45847 26.3615 9.18761C26.3615 6.75095 25.4191 4.41404 23.7417 2.69116C22.0643 0.968146 19.7892 0.000174737 17.4171 0.000174737L17.4169 0Z" fill="#265D91"/>
              </g>
              <defs>
              <filter id="filter0_d_61_103" x="0" y="0" width="30.3615" height="30.9693" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_61_103"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_61_103" result="shape"/>
              </filter>
              </defs>
              </svg>
  
            <h1 class="page-heading d-flex flex-column justify-content-center my-0">
              Tableau de Bord</h1>
          </div>
            <!--end::Title-->
          </div>
          <!--end::Page title-->
          <!--begin::Actions-->
  
          <!--end::Actions-->
        </div>
        <!--end::Toolbar container-->
      </div>
      <!--end::Toolbar-->
      <!--begin::Content-->
      <div id="kt_app_content" class="app-content flex-column-fluid">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container container-fluid">
         
          <!--begin::Row-->
          <div class="row g-5 g-xl-10 mb-5 mb-xl-10">
            <!--begin::Col-->
            <div class="col-xl-12 mb-xl-10">
  
             <!--begin::Chart widget 20-->
             <div class="card card-flush">
               <!--begin::Header-->
               <div class="card-header py-5">
                 <!--begin::Title-->
                 <h3 class="card-title">Analytique des Clients finaux</h3>
                 <!--end::Title-->
                 <!--begin::Toolbar-->
                 <div class="card-toolbar">
                   
                 </div>
                 <!--end::Toolbar-->
               </div>
               <!--end::Header-->
               <!--begin::Card body-->
               <div class="card-body d-flex justify-content-between flex-column pb-0 px-0 pt-1">
                 <!--begin::Items-->
                 <div class="d-flex justify-content-between">
                 <div class="d-flex flex-wrap d-grid gap-5 px-9 mb-5">
                   <!--begin::Item-->
                   <div class="me-md-2">
                     <!--begin::Description-->
                     <!--end::Description-->
                   </div>
                   <!--end::Item-->
                   <!--begin::Item-->
  
                   <!--end::Item-->
                   <!--begin::Item-->
  
                   <!--end::Item-->
                 </div>
                 <div class="d-flex" [formGroup]="searchForm">
                  <mat-form-field>
                    <mat-label>Date début - Date fin</mat-label>
                    <mat-date-range-input [rangePicker]="picker" (keyup.enter)="searchClientOverTime()">
                      <input matStartDate placeholder="Start date" formControlName="start_date">
                      <input matEndDate placeholder="End date" formControlName="end_date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                </div>
                 </div>
              
                 <!--end::Items-->
                 <!--begin::Chart-->
                 <div id="charts" class="min-h-auto ps-4 pe-6"
                   style="height: 300px">
                  <apx-chart
                  [series]="chartOptionsLine?.series"
                  [chart]="chartOptionsLine?.chart"
                  [dataLabels]="chartOptionsLine?.dataLabels"
                  [plotOptions]="chartOptionsLine?.plotOptions"
                  [xaxis]="chartOptionsLine?.xaxis"
                  ></apx-chart>
                 </div>
                 <!--end::Chart-->
               </div>
               <!--end::Card body-->
             </div>
             <!--end::Chart widget 20-->
           </div>
           <!--end::Col-->
           <!--begin::Col-->
  
           <!--end::Col-->
  
         </div>
         <!--end::Row-->
           <!--begin::Row-->
           <div class="row g-5 g-xl-10 pb-15 mb-xl-10">
            <!--begin::Col-->
            <div class="col-xl-12 mb-xl-10">
  
              <!--begin::Chart widget 20-->
              <div class="card card-flush">
                <!--begin::Header-->
                <div class="card-header py-5">
                  <!--begin::Title-->
                  <h3 class="card-title">Inscription des Clients finaux </h3>
                  <!--end::Title-->
                  <!--begin::Toolbar-->
                  <div class="card-toolbar">
                  </div>
                  <!--end::Toolbar-->
                </div>
                <!--end::Header-->
                <!--begin::Card body-->
                <div class="card-body d-flex justify-content-between flex-column pb-0 px-0 pt-1">
                  <!--begin::Items-->
                  <div class="d-flex justify-content-between">
                    <div class="d-flex flex-wrap d-grid gap-5 px-9 mb-5">
                      <!--begin::Item-->
                      <div class="me-md-2">
                        <div class="list mb-2">
                        </div>
                        <!--begin::Statistics-->
                        <div class="list mb-2">
                        </div>
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
  
                      <!--end::Item-->
                      <!--begin::Item-->
  
                      <!--end::Item-->
                    </div>
                    <!-- <div class="d-flex">
                      <div class="d-flex align-items-center m-3">
                        <input type="text" placeholder="Sélectionner date début"
                          class="form-control form-control-solid mb-3" onblur="(this.type='text')">
                      </div>
                      <div class="d-flex align-items-center">
                        <input type="text" placeholder="Sélectionner date fin"
                          class="form-control form-control-solid mb-3" onblur="(this.type='text')">
                      </div>
                    </div> -->
                  </div>
                  <!-- <div class="d-flex justify-content-center ">
                      <div class="badge bg-blue mr-3"><span></span>
                      </div>
                      <span class="key-chart" >Crédit</span>
                      <div class="badge bg-light-blue "><span></span>
                      </div>
                    <span class="key-chart">Revenu</span>  
                      
                    </div>-->
                  <!--end::Items-->
                  <!--begin::Chart-->
                  <div id="charts" class="min-h-auto ps-4 pe-6" style="height: 300px">
                    <apx-chart [series]="chartOptionsbar?.series" [chart]="chartOptionsbar?.chart"
                      [dataLabels]="chartOptionsbar?.dataLabels" [plotOptions]="chartOptionsbar?.plotOptions"
                      [xaxis]="chartOptionsbar?.xaxis"></apx-chart>
                  </div>
                  <!--end::Chart-->
                </div>
                <!--end::Card body-->
              </div>
              <!--end::Chart widget 20-->
            </div>
            <!--end::Col-->
            <!--begin::Col-->
  
            <!--end::Col-->
  
          </div>
          <!--end::Row-->
            <div class="row g-5 g-xl-10 pb-15 mb-xl-10">
                <!--begin::Col-->
                <div class="col-xl-12 mb-xl-10">
    
                 <!--begin::Chart widget 20-->
                 <div class="card card-flush">
                   <!--begin::Header-->
                   <div class="card-header py-5">
                     <!--begin::Title-->
                     <h3 class="card-title ">Statistique d'inscription</h3>
                     <!--end::Title-->
                     <!--begin::Toolbar-->
                     <div class="card-toolbar">
                       <!--begin::Daterangepicker(defined in src/js/layout/app.js)-->
                       
                       <!--end::Daterangepicker-->
                     </div>
                     <!--end::Toolbar-->
                   </div>
                   <!--end::Header-->
                   <!--begin::Card body-->
                   <div class="card-body d-flex align-items-end pt-0">
                    <!--begin::Wrapper-->
                    <div class="d-flex align-items-center flex-wrap">
                        <!--begin::Chart-->
                        <div class="d-flex me-7 me-xxl-10">
                          <div id="chart">
                            <apx-chart
                              [series]="chartOptionsPie?.series"
                              [chart]="chartOptionsPie?.chart"
                              [labels]="chartOptionsPie?.labels"
                              [fill]="chartOptionsPie?.fill"
                              [dataLabels]="chartOptionsPie?.dataLabels"
                              [responsive]="chartOptionsPie?.responsive"
                            ></apx-chart>
                        </div>
                        </div>
                        <!--end::Chart-->
                        <!--begin::Labels-->
                        
                        <!--end::Labels-->
                    </div>
                    <!--end::Wrapper-->
                </div>
    
                   <!--end::Card body-->
                 </div>
                 <!--end::Chart widget 20-->
               </div>
               <!--end::Col-->
               <!--begin::Col-->
    
               <!--end::Col-->
    
             </div>
         
        </div>
        <!--end::Content container-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
    <!--begin::Footer-->
    <!--end::Footer-->
  </div>
  