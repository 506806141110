import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedQuestion,
  confirmDelete,
  deletedQuestion,
  existedEvaluation,
  failedDelete,
  serverError,
  updatedQuestion,
} from '../../../../../../core/models/messages';
import { IQuestion } from '../../../../../../core/models/setting';
import { SettingsService } from '../../../../../../core/services/settings/settings.service';
import { environment } from '../../../../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-avis-esn',
  templateUrl: './avis-esn.component.html',
  styleUrls: ['./avis-esn.component.css'],
})
export class AvisEsnComponent {
  typesCompany = [
    { name: 'Candidat', id: 1 },
    { name: 'Entreprise', id: 2 },
  ];
  /* string */
  mode: string = 'create';
  typeCompany!: string;
  url: string = environment.baseUrl + '/api/Company/file/gallery_company/';
  /* numbers */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;             
  pagination : boolean =false;      
  idQuestion!: number | undefined;
  idCompany!: number;
  max: number = 5;
  value: number = 3;
  totalComments!: number;
  /* modal */
  modalRef?: BsModalRef;
  /* array */
  listQuestion: IQuestion[] = [];
  listComments: any = [];
  /* formGroup */
  questionForm: FormGroup = this.createQuestionForm();
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private settingService: SettingsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  /* init */
  ngOnInit() {
    this.getIdCompany();
  }
  /* get id company from route */
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        console.log('paramsparamsparamsparamsparamsparams', params);

        this.idCompany = params.params['id'];
        this.typeCompany = params.params['type'];
        this.getListQuestion();
        this.getListComment();
      },
    });
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
         if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
          this.getListQuestion();
        } 
    }
  }
  openModal(template: TemplateRef<any>, mode: string, data?: IQuestion) {
    this.mode = mode;
    if (mode == 'update') {
      this.idQuestion = data?.id;
      this.questionForm = this.createQuestionForm(data);
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef?.hide();
  }
  getListQuestion() {
    let data = {
      company_id: this.idCompany,
    };
    this.spinner.show();
    this.settingService
      .getSpecificReview(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.totalItems = res.data.total;
            this.listQuestion = res.data.data;
            if (this.currentPage > 1 && !res.data.length) {
              this.currentPage = 1;
              this.getListQuestion();
            }
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  getListComment() {
    this.spinner.show();
    let data = { per_page: this.itemsPerPage, page: this.currentPage };
    this.settingService
      .getSpecificReviewComments(this.idCompany, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.totalComments = res.data.total;
            this.listComments = res.data.data;
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* create add question form */
  createQuestionForm(data?: IQuestion) {
    return this.formBuilder.group({
      question: [data ? data.question : '', [Validators.required]],
      company_id: [''],
    });
  }
  /* confirm click either to add or to update question */
  submitData() {
    if (this.mode == 'create') {
      this.addQuestion();
    } else {
      this.updateQuestion();
    }
  }
  /* add question */
  addQuestion() {
    this.questionForm.get('company_id')?.setValue(this.idCompany);
    if (this.questionForm.valid) {
      this.spinner.show();
      this.settingService
        .addQuestionCompany(this.questionForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.getListQuestion();
              this.closeModal();
              this.questionForm.reset();
              this.spinner.hide();
              this.toastr.success(addedQuestion);
            }
          },
          error: (err) => {
            this.spinner.hide();
            if (err?.error) {
              if (err.error?.message['question'])
                this.toastr.info(existedEvaluation);
              else this.toastr.error(serverError);
            }
          },
        });
    }
  }
  /* update question */
  updateQuestion() {
    this.questionForm.get('company_id')?.setValue(this.idCompany);
    if (this.questionForm.valid) {
      this.spinner.show();
      this.settingService
        .updateQuestionCompany(this.idQuestion, this.questionForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.getListQuestion();
              this.closeModal();
              this.questionForm.reset();
              this.spinner.hide();
              this.toastr.success(updatedQuestion);
            }
          },
          error: (err) => {
            this.spinner.hide();
            if (err?.error) {
              if (err.error?.message['question'])
                this.toastr.info(existedEvaluation);
              else this.toastr.error(serverError);
            }
          },
        });
    }
  }
  /* open delete alert */
  openDeleteAlert(question: IQuestion) {
    Swal.fire({
      title: `${confirmDelete} cette question ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteQuestion(question.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  /* delete question */
  deleteQuestion(id: number) {
    this.spinner.show();
    this.settingService
      .deleteQuestionCompany(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListQuestion();
            this.spinner.hide();
            this.toastr.success(deletedQuestion);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  changeTab(index: number) {
    this.idCompany = index;
    this.getListQuestion();
  }
  navigateToListComment() {
    /* routerLink="/acceuil/parametres/evaluation-wegestu" */
    this.router.navigate([
      '/acceuil/parametres/evaluation-wegestu',
      { id: this.idCompany, type: true },
    ]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
