
<div id="kt_app_content" class="app-content flex-column-fluid">
    <div id="kt_app_content_container" class="container-fluid">
        <!--begin::Card-->
        <div class="card mb-10" >
            <div   class="card-body tools" [formGroup]="searchFormGroup">
                <div class="title mb-3">
                  <h4>Recherche</h4>
                </div>
                <div class="row mb-3">
                  <div class="col-lg-3">
                    <input type="text" name="id" id="id" class="form-control form-control-solid mb-3"
                      formControlName="name" placeholder="Nom et prénom">
                  </div>
                  <div class="col-lg-3">
                    <input type="text" name="nom" id="nom"
                      class="form-control form-control-solid mb-3" placeholder="Manager" formControlName="manager_name">
                  </div>
                  <div class="col-lg-3">
                    <input type="text" name="nom" id="nom" formControlName="client_name"
                      class="form-control form-control-solid mb-3" placeholder="Client">
                  </div>
                  <div class="col-lg-3">
                    <div class="mb-3 search-btn d-flex justify-content-end">
                    <div class="align-items-center recherche">
                      <button type="submit" class="me-5" (click)="applyFilter()">
                        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                            fill="white" />
                        </svg>
                        Rechercher</button>
                    </div>
                    <div class="reset-icon cursor-pointer"  >
                        <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                        </div>
      
                  </div>
                  </div>
                </div>
                
              </div>
            
             <!--end::Card header-->
            <!--begin::Card body-->
           
            <!--end::Card body-->
        </div>
        <div class="card content">
            <div class="card-body pt-0">
                <div class="title">
                    <h4>Liste des Consultants</h4>
                </div>
                <!--begin::Table-->
               <div class="table-responsive">
                <table class="table align-middle   fs-6 gy-5 mb-4" id="kt_customers_table">
                    <thead>
                        <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">

                            <th class="min-w-125px"><span>nom et prenom <i (click)="sortData('first_name')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                            <th class="min-w-125px"><span>Manager  <i (click)="sortData('first_name')" class="fa-solid fa-sort cursor-pointer"></i></span> </th>
                            <th class="min-w-125px"><span>Client <i (click)="sortData('client_final_name')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                            <th class="min-w-125px"><span>EMAIL Personnel <i (click)="sortData('email')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                            <th class="min-w-125px"><span>EMAIL Professionnel <i (click)="sortData('emailProESN')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                            <th class="min-w-125px"><span>N° TEL <i (click)="sortData('phone_number')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                            <th class="min-w-125px"><span>Dernière connexion <i (click)="sortData('last_connection')" class="fa-solid fa-sort cursor-pointer"></i></span></th>

                            <th class="min-w-125px"><span>ACTION</span></th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <tr *ngFor="let consult of listConsultant ">

                            <td >
                                <a class="first-td name m-0 cursor-pointer" 
                                  [routerLink]="['/acceuil/entreprises/esn/details-consultant-esn',{id:consult.uuid}]" routerLinkActive="router-link-active" 
                                >{{consult.last_name}} {{consult.first_name}}</a>
                              </td>
                              <td><span>{{consult?.consultant_e_s_n_manager_full_name?consult?.consultant_e_s_n_manager_full_name:'---'}}</span></td>
                              <td >
                                <span>{{consult?.client_final_name?consult.client_final_name:'---'}}</span>
                              </td>
                              <td>
                                <span>{{consult.email?consult.email:'---'}}</span>
                              </td>
                              <td>
                                <span>{{consult.emailProESN?consult.emailProESN:'---'}}</span>
                              </td>
                              <td>
                                <span>{{consult.phone_number?consult.phone_number:'---'}}</span>
                              </td>
                                
                                <td>
                                  <span>
                                    {{ !consult.last_connection ? '---' : (cleanLastConnection(consult.last_connection) | formatDate) }}
                                  </span>
                                </td>
                                
                            <td>
                               <div class="tools">
                                <div class="dropdown">
                                    <button
                                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                        type="button" id="dropdownMenuButton"
                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <svg width="19" height="5" viewBox="0 0 19 5"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 0 4.89478)"
                                                fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                fill="#265D91" />
                                        </svg>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item cursor-pointer" (click)="goToDetails(consult.uuid)">Voir détails</a>
                                        <a class="dropdown-item cursor-pointer" (click)="navigateToProfilCandidat(consult.uuid)" >Dossier de Compétences</a>
                                        <a class="dropdown-item cursor-pointer" (click)="dissocierconsultant(consult.uuid)">Dissocier consultant</a>

                                    </div>
                                </div>
                               </div>
                            </td>
                        </tr>
                        
                    </tbody>
                    <!--end::Table body-->
                </table>
                <div class="pagination mt-4" *ngIf="listConsultant && listConsultant.length">
                    <app-pagination [data]="listConsultant" [lengthData]="totalItems" [currentPageList]="currentPage" [endexPageList]="endIndex" (setPages)="getItems($event)">
                    </app-pagination>
                  </div>
                 
                  <div class="alert alert-warning text-center mt-5" role="alert"  *ngIf="listConsultant&&!listConsultant.length ">
                    <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                    <span class="empty-list">
                     La liste est vide
                 </span>
                 </div>
               </div>
                <!--end::Table-->
            </div>
        </div>
    </div>
    <!--end::Content container-->
</div>
<script src="./assets/js/custom/apps/ecommerce/customers/listing/listing.js"></script>
		<script src="./assets/js/custom/apps/ecommerce/customers/listing/add.js"></script>
		<script src="./assets/js/custom/apps/ecommerce/customers/listing/export.js"></script>
