import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../models/response-api';
import { CrudService } from '../crud/crud.service';
import { environment } from '../../../../environments/environment';
import { CLogin, CPassoword } from '../../models/login';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CrudService<ApiResponse<any>, number> {
  constructor(
    private cookieService: CookieService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    _http: HttpClient
  ) {
    super(_http, `${environment.baseUrl}/api`);
  }
  /* get token from cookies */
  getToken() {
    if (this.cookieService.get('token')) return this.cookieService.get('token');
  }
  /* get refresh token  */
  getRefreshToken() {
    if (this.cookieService.get('refresh_token'))
      return this.cookieService.get('refresh_token'); // set key
  }
  /* check if token is valid, not expired */
  isAuthenticated() {
    const token = this.getToken();
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      return false;
    }
  }
  /* redirect to login page  */
  goToLogin() {
    this.clearCookies();
    localStorage.clear();
    this.router.navigate(['/auth']);
  }
  /* clear cookies */
  clearCookies() {
    this.cookieService.deleteAll('/');
    let cookies = document.cookie.split(';');
    for (let value of cookies) {
      const eqPos = value.indexOf('=');
      const name = eqPos > -1 ? value.substr(0, eqPos) : value;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
  /* logout and redirect to login page */
  logOut() {
    const auth = this.getToken();
    if (auth != '') {
      this.disconnect().subscribe({
        next: () => {
          this.goToLogin();
        },
        error: () => {
          this.goToLogin();
        },
      });
    }
  }
  /*  */
  disconnect(): Observable<any> {
    const token = this.getToken();  

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`  
    });
    return this._http.post<ApiResponse<any>>(this._base + '/auth/logout', {}, { headers });
  }
  /* set cookies */
  setCookies(key: string, value: string, expireTime?: Date) {
    this.cookieService.delete(key);
    this.cookieService.set(
      key,
      value,
      expireTime,
      '/',
      undefined,
      true,
      'Strict'
    );
  }
  /**Refresh Access Token  */
  refreshAccessToken( refreshToken?: any): Observable<any> {
    const  formData = new FormData(); 
    formData.append('refresh_token', refreshToken.refresh_token);
    return this._http.post<ApiResponse<any>>(
      this._base + '/auth/refreshToken',
      formData
    );
  }
  /*  */
  login(body: CLogin): Observable<ApiResponse<CLogin>> {
    return this._http.post<ApiResponse<CLogin>>(
      this._base + '/auth/login',
      body
    );
  }
  /*  */
  resetMail(body: {
    email: string;
  }): Observable<
    ApiResponse<{ status: number; data: { User: { email: string } } }>
  > {
    return this._http.post<
      ApiResponse<{ status: number; data: { User: { email: string } } }>
    >(this._base + '/password/forgotPassword', body);
  }
  /*  */
  restCode(body: {
    code: string;
    email: string;
  }): Observable<ApiResponse<{ code: string; email: string }>> {
    return this._http.post<ApiResponse<{ code: string; email: string }>>(
      this._base + '/password/verifCodeForgetPassword',
      body
    );
  }
  /*  */
  restPassword(body: CPassoword): Observable<ApiResponse<CPassoword>> {
    return this._http.post<ApiResponse<CPassoword>>(
      this._base + '/password/resetPassword',
      body
    );
  }
  /*  */
  changePassword(body: any): Observable<ApiResponse<CPassoword>> {
    return this._http.post<ApiResponse<CPassoword>>(
      this._base + '/password/changePassword',
      body
    );
  }
  /* update profil admin */
  updateCandidate(id: number, data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + `/User/editProfile?user_id=${id}`,
      data
    );
  }
}
