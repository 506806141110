import { Component, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedCra,
  confirmDelete,
  deletedCra,
  existedCra,
  failedDelete,
  serverError,
  updatedCra,
} from '../../../../../core/models/messages';
import { ICra } from '../../../../../core/models/setting';
import { LineActivityCraService } from '../../../../../core/services/line-activity-cra/line-activity-cra.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-activitie-cra',
  templateUrl: './activitie-cra.component.html',
  styleUrls: ['./activitie-cra.component.css'],
})
export class ActivitieCraComponent {
  /* numbers */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  idCra!: number;
  clickCount: number = 0;
  /* string */
  mode: string = 'addActivity';
  searchValue: string = '';
  searchElement: string = '';
  pagination : boolean =false;

  /* array */
  listCra: ICra[] = [];
  familyTypes = [
    { id: '1', name: 'Production ' },
    { id: '2', name: 'Absence' },
    { id: '3', name: 'Interne' },
  ];
  isLoadSpinner: boolean = true;

  /* formGroup */
  createLineCra: FormGroup = this.createLineCraForm();
  /* modal */
  modalRef?: BsModalRef;
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activityCra: LineActivityCraService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {}
  /* init */
  ngOnInit() {
    this.getListAccounts();
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') this.applyFilter();
      else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
        this.getListAccounts();
      }
    }
  }
  getListAccounts() {
    let data = { per_page: this.itemsPerPage, page: this.currentPage };
    this.spinner.show();
    this.activityCra
      .getListCra(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.isLoadSpinner = false;
            this.spinner.hide();
            this.listCra = res.data.items.data.slice(0, this.endIndex);
            this.totalItems = res.data.items.total;
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* create add subCategory form */
  createLineCraForm(data?: ICra) {
    return this.formBuilder.group({
      name_FR: [
        data ? data.name_FR : '',
        [Validators.required],
        this.maxLengthValidator(50),
      ],

      family: [
        data
          ? data.family == 'Absence'
            ? '2'
            : data.family == 'Interne'
            ? '3'
            : '1'
          : null,
        [Validators.required],
      ],
    });
  }
  maxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        const value = control.value as string;
        console.log('Valeur du champ :', value);
        if (value && value.length > maxLength) {
          resolve({ maxLengthExceeded: true });
        } else {
          resolve(null);
        }
      });
    };
  }

  submitData() {
    if (this.mode == 'addActivity') {
      this.addAccount();
    } else {
      this.updateAccount();
    }
  }
  addAccount() {
    this.spinner.show();
    this.activityCra
      .addCra(this.createLineCra.value)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.toastr.success(addedCra);
            this.getListAccounts();
            this.closeModal();
            this.spinner.hide();
            this.createLineCra.reset();
          }
        },
        error: (err) => {
          this.spinner.hide();
          if (err.error) {
            if (err.error.message && err.error.message['name_FR'])
              this.toastr.info(existedCra);
            else this.toastr.error(serverError);
          }
        },
      });
  }
  updateAccount() {
    this.spinner.show();
    this.activityCra
      .updateCra(this.idCra, this.createLineCra.value)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.toastr.success(updatedCra);
            this.getListAccounts();
            this.closeModal();
            this.spinner.hide();
            this.createLineCra.reset();
          }
        },
        error: (err) => {
          this.spinner.hide();
          if (err.error) {
            if (err.error.message && err.error.message['name_FR'])
              this.toastr.info(existedCra);
            else this.toastr.error(serverError);
          }
        },
      });
  }
  /* open delete alert */
  openDeleteAlert(cra: ICra) {
    Swal.fire({
      title: `${confirmDelete} cette activité ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteAccount(cra.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  /* delete cra */
  deleteAccount(id: number) {
    this.spinner.show();
    this.activityCra
      .deleteCra(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.getListAccounts();
            this.toastr.success(deletedCra);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* filter data on key up input */
  applyFilter() {
    if (this.searchElement != '' || this.clickCount) {
      this.clickCount++;
      if (this.clickCount == 1) this.currentPage = 1;
      let data = {
        searchName: this.searchElement.trim().toLowerCase(),
        page: this.currentPage,
        per_page: this.itemsPerPage,
      };
      this.spinner.show();
      this.activityCra
        .searchCra(data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (!res.data.items.data.length && this.currentPage > 1) {
              this.currentPage = 1;
              this.applyFilter();
            } else {
              this.listCra = res.data.items.data.slice(0, this.endIndex);
              this.totalItems = res.data.items.total;
            }
            this.spinner.hide();
          },
          error: () => {
            this.spinner.hide();
            this.toastr.error(serverError);
          },
        });
    }
  }
  openModal(template: TemplateRef<any>, mode: string, cra?: ICra) {
    this.mode = mode;
    if (cra) {
      this.idCra = cra.id;
      this.createLineCra = this.createLineCraForm(cra);
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef!.hide();
    this.createLineCra.reset();
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
