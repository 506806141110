import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailConsultComponent } from './components/detail-consult/detail-consult.component';
import { ListConsultComponent } from './components/list-consult/list-consult.component';
import { ListInterventComponent } from './components/list-intervent/list-intervent.component';
import { OffreEmploiComponent } from './components/offre-emploi/offre-emploi.component';
import { ProfileEsnComponent } from './components/profile-esn/profile-esn.component';
import { ListEsnComponent } from './list-esn.component';
import { ListEsnRoutingModule } from './list-esn-routing.module';
import { FieldErrorModule } from './../../../../shared/components/shared-field-error/field-error.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationModule } from './../../../../shared/components/pagination/pagination.module';
import { PipesModuleModule } from '../../../../core/pipes/pipes-module.module';
import { UnitaryAddCompanyComponent } from '../unitary-add-company/unitary-add-company.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { AvisEsnComponent } from './components/avis-esn/avis-esn.component';
import { PermissionEsnComponent } from './components/permission-esn/permission-esn.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PaymentComponent } from './components/payment/payment.component';
import { ActualOfferComponent } from './components/actual-offer/actual-offer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileEsnReviewComponent } from './components/profile-esn-review/profile-esn-review/profile-esn-review.component';
import { AppelOffreComponent } from '../client-final/components/appel-offre/appel-offre.component';
import { CalendarModule } from 'primeng/calendar';
import { HistoriqueabonnementComponent } from './components/historiqueabonnement/historiqueabonnement.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
@NgModule({
  declarations: [
    ListEsnComponent,
    ProfileEsnComponent,
    OffreEmploiComponent,
    ListInterventComponent,
    ListConsultComponent,
    DetailConsultComponent,
    AvisEsnComponent,
    PermissionEsnComponent,
    PaymentComponent,
    ActualOfferComponent,
    ProfileEsnReviewComponent,
    HistoriqueabonnementComponent,
    InvoicesComponent,
  ],
  imports: [
    CommonModule,
    ListEsnRoutingModule,
    FieldErrorModule,
    NgxSpinnerModule,
    PaginationModule,
    PipesModuleModule,
    FormsModule,
    ReactiveFormsModule,
    UnitaryAddCompanyComponent,
    RatingModule.forRoot(),
    DragDropModule,
    CalendarModule,  
  ]
})
export class ListEsnModule {}
