import { Component } from '@angular/core';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent {

//   ngOnInit() {
//     this.pusherService.bind('new-message', (data: any) => {
//       this.messages.push(data.message);
//     });
//     this.chatService.messageSent.subscribe(() => {
//       this.loadMessagesEmitter(this.currentConversationUserId); 
//     });

//     this.loadMessagesEmitter(this.currentConversationUserId);
//     this.loadMessagesReciever(this.currentConversationUserId)
//     this.getUsers()
//     this.getUser()
    
//   }
// getUser(){
//   this.currentUser = this.localStorageService.getData('UserInfo');
//   this.first_name = JSON.parse(this.currentUser).first_name
//   this.last_name = JSON.parse(this.currentUser).last_name
//   this.profil_image = JSON.parse(this.currentUser).image_url
  
// }

//   getFirstLetter(user: any): string {
//     if (user?.first_name && user.first_name.length > 0) {
//       return user.first_name[0];
//     } else {
//       return ''; 
//     }
//   }
//   loadMessagesEmitter(userId?: any) {
//     this.spinner.show();
//     this.chatService.getMessagesEmitter(userId).subscribe((messages) => {
//       this.messages = messages?.data.map((message:any) => {
//         message.elapsedTime = this.formatElapsedTime(message.created_at);
//         return message;
//       });
//       this.spinner.hide();

//     });
//   }
//   loadMessagesReciever(userId?: any) {
//     this.chatService.getMessagesReciever(userId).subscribe((messagesReciveds) => {
//       this.messagesReciveds = messagesReciveds?.data.map((message:any) => {
//         message.elapsedTime = this.formatElapsedTime(message.created_at);
//         return message;
//       });
      
//     });
//   }

//   sendMessage() {
//     if (this.newMessage.trim() !== '') {
//       this.chatService.sendMessage(this.currentConversationUserId, this.newMessage).subscribe(() => {
//         this.newMessage = ''; 
//       });
//     }
//   }
//   formatElapsedTime(created_at: string): string {
//     const currentTime = moment();
//     const messageTime = moment(created_at);
//     const duration = moment.duration(currentTime.diff(messageTime));
  
//     if (duration.asMinutes() <= 59) {
//       const minutes = Math.floor(duration.asMinutes());
//       if (minutes < 1) {
//         return 'Moins d\'une minute';
//       } else if (minutes === 1) {
//         return '1 minute';
//       } else {
//         return `${minutes} minutes`;
//       }
//     } else if (duration.asHours() <= 23) {
//       const hours = Math.floor(duration.asHours());
//       if (hours < 1) {
//         return 'Moins d\'une heure';
//       } else if (hours === 1) {
//         return '1 heure';
//       } else {
//         return `${hours} heures`;
//       }
//     } else if (duration.asDays() <= 6) {
//       const days = Math.floor(duration.asDays());
//       if (days < 1) {
//         return 'Moins d\'un jour';
//       } else if (days === 1) {
//         return '1 jour';
//       } else {
//         return `${days} jours`;
//       }
//     } else {
//       const weeks = Math.floor(duration.asDays() / 7);
//       if (weeks < 1) {
//         return 'Moins d\'une semaine';
//       } else if (weeks === 1) {
//         return '1 semaine';
//       } else {
//         return `${weeks} semaines`;
//       }
//     }
//   }
//     getUsers() {
//       this.chatService.getListUsers().pipe(takeUntil(this.unsubscribeAll)).subscribe({
//         next: (res) => {
//           this.users = res.data.data
//           this.totalUsers = res.data.count
//         },
//         error: (err) => {
//           console.log('error', err);
//         }
//       })
//     }
//     startConversation(userId: any) {
//       this.userIds = this.users.map((user:any) => user.id);
//       console.log("userIdsuserIdsuserIds",this.userIds)
//       this.currentConversationUserId = userId;
//       this.loadMessagesEmitter(userId); 
//       this.loadMessagesReciever(userId); 
//       this.getCurrentUser()

//     }
//     getCurrentUser() {
//       const currentUser = this.users.find((user:any) => user.id === this.currentConversationUserId);
//       console.log("currentUser",currentUser)
//       return currentUser;
      
//     }

//     applyFilter(event: Event) {
//       const filterValue = (event.target as HTMLInputElement).value;
//       let data = {
//         // per_page: this.itemsPerPage,
//         // page: this.currentPage,    
//         search: filterValue.trim().toLowerCase(),

//       }
  
//       this.spinner.show()
  
//       console.log('%centreprises.component.ts line:203 data search', 'color: #007acc;', data);
  
//       this.chatService.searchListUser(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
//         next: (res) => {
  
//           this.users = res.data.data
//           this.spinner.hide()
//         },
//         error: () => {
//           this.spinner.hide()
//         }
//       })
//     }
//     deleteConversation(receiverId: any): void {
//       const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cette Conversation ?');
//       if (confirmDelete) {
//         this.chatService.deleteConversation(receiverId).subscribe(
//           (response) => {
//             console.log('Conversation deleted successfully', response);
//             this.messages= this.messages.filter((message:any) => message.id === receiverId)
//           },
//           (error) => {
//             console.error('Error deleting Conversation', error);
//           }
//         );
//       }
//     }
//   ngOnDestroy() {
//     this.pusherService.disconnect();
//     this.unsubscribeAll.next();
//     this.unsubscribeAll.complete();
//   }
}
