import { Component, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedEvaluation,
  confirmDelete,
  deletedEvaluation,
  existedEvaluation,
  failedDelete,
  serverError,
  updatedEvaluation,
} from '../../../../../../core/models/messages';
import { IQuestion } from '../../../../../../core/models/setting';
import { SettingsService } from '../../../../../../core/services/settings/settings.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-list-eval-wegestu',
  templateUrl: './list-eval-wegestu.component.html',
  styleUrls: ['./list-eval-wegestu.component.css'],
})
export class ListEvalWegestuComponent {
  typesEvaluation = [
    { id: 'Annuelle', name: 'Annuelle' },
    { id: 'Suivi', name: 'Suivi' },
  ];
  idEval: any;
  /* string */
  mode: string = 'create';
  totalItems!: number;
  itemsPerPage: number = 5;
  // currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  // totalElements!: number;
  ispagination: boolean = true;
  checkedBtn:boolean = false;
  pageSizeOptions = [5, 10, 20];
  pageSize = 5;
  currentPage = 0;
  totalElements = 0
  /* numbers */
  idEvaluation!: number | undefined;
  /* modal */
  modalRef?: BsModalRef;
  /* array */
  listEvaluation: IQuestion[] = [];
  /* formGroup */
  evaluationForm: FormGroup = this.createEvaluationForm();
  searchForm: FormGroup = this.createSearchForm();
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private settingService: SettingsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private router: Router,
    private datePipe: DatePipe,
  ) {
  }
  /* init */
  ngOnInit() {
    this.getListEvaluation();
  }
  openModal(template: TemplateRef<any>, mode: string, data?: any) {
    this.mode = mode;
    if (mode == 'update') {
      this.idEvaluation = data?.id;
      this.evaluationForm = this.createEvaluationForm(data);  
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef?.hide();
    this.evaluationForm.reset();
  }
  formatDate(date: Date | string): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Mois de 01 à 12
    return `${year}-${month}`;
  }
  /* search form */
  /* create add subCategory form */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      name: [data ? data.name : ''],
      typeEval: [data ? data.typeEval : null],
    eval_date:  [data ? this.formatDate(data.eval_date) : '' ],
    });
  }
  isRecherche: boolean = false;
  reset() {
    this.spinner.hide();
    this.currentPage = 0;
    this.spinner.hide();
    this.searchForm.reset();
    this.getListEvaluation(0);
  }
  getListEvaluation(pageNumber : number = this.currentPage) {
     this.spinner.show();
    let formattedDate = this.datePipe.transform(this.searchForm.value.eval_date, 'yyyy-MM-dd');
    let data = {
      date: formattedDate,
      type: this.searchForm.value.typeEval,
      name: this.searchForm.value.name,
      per_page: this.pageSize,
      page: pageNumber + 1
    };
    this.settingService
      .getListEvaluationDefault(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res?.status === 200) {
            this.spinner.hide();
            this.listEvaluation = res.data?.data;
            this.totalElements = res.data.total;
          } else {
            this.spinner.hide();
            this.toastr.error("Unexpected API response.");
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.toastr.error("Failed to load evaluations. Please try again.");
          console.error(err);
        },
      });
  }
  /* create add question form */
  createEvaluationForm(data?: IQuestion) {
    return this.formBuilder.group({
      name: [
        data ? data.name : '',
        [Validators.required, this.notOnlySpacesValidator()],
      ],
      type: [data && data.type,Validators.required],
      checkboxGestion: [false]
    });
  }
  notOnlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value as string;
      const isValid = value?.trim() !== '';
      return isValid ? null : { onlySpaces: true };
    };
  }
  submitData() {
    if (this.mode == 'create') {
      this.addQuestion();
    } else {
      this.updateQuestion();
    }
  }
  onCheckboxChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.checkedBtn = true
    }else {
      this.checkedBtn = false
    }
  }
  /* add question */
  addQuestion() {
    if (this.evaluationForm.valid) {
      this.spinner.show();
      this.settingService
        .addEvaluationDefault(this.evaluationForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              if(this.checkedBtn) {
               this.router.navigate([
                '/acceuil/parametres/evaluation-default',
                { idEvaluation: res.data.id },
              ]);
              }else {
                this.getListEvaluation();
              }
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(addedEvaluation);
            }
          },
          error: (err) => {
            this.spinner.hide();
            if (err?.error) {
              this.toastr.error('Évaluation existe déja.');
            }
          },
        });
    }
  }
  CreateQuestion() {
    this.settingService
        .createQuestion(this.idEval,this.evaluationForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            //console.log('ressss',res)
          },
          error: (err) => {
            this.spinner.hide();
          },
        });
    
  }
  /* update question */
  updateQuestion() {
    if (this.evaluationForm.valid) {
      this.spinner.show();
      this.settingService
        .updateEvaluationDefault(this.idEvaluation, this.evaluationForm.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              if(this.checkedBtn) {
                this.router.navigate([
                 '/acceuil/parametres/evaluation-default',
                 { idEvaluation: res.data.id },
               ]);
              }
              else {
                this.getListEvaluation();
              }
                this.closeModal();
                this.spinner.hide();
                this.toastr.success(updatedEvaluation);
            }
          },
          error: (err) => {
            this.spinner.hide();
            if (err?.error) {
              this.toastr.error('Évaluation existe déja.');
            }
          },
        });
    }
  }
  /* open delete alert */
  openDeleteAlert(evaluation: IQuestion) {
    Swal.fire({
      title: `${confirmDelete} l'evaluation sélectionnée?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteEvaluation(evaluation.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  /* delete question */
  deleteEvaluation(id: number) {
    this.spinner.show();
    this.settingService
      .deleteEvaluationDefault(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListEvaluation();
            this.spinner.hide();
            this.toastr.success(deletedEvaluation);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  navigateToListQuestions(idEvaluation: number,pageNumber : number = this.currentPage): void {
    let data = {
      per_page: this.pageSize,
      page: pageNumber + 1,
    };
    this.spinner.show();
    this.settingService
      .listQuestion(idEvaluation)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('Réponse de listQuestion', res);
          this.idEval = idEvaluation;
          console.log('this.idEval',this.idEval)
          if (res?.status === 200) {
            this.spinner.hide();
            this.listEvaluation = res.data?.data;
            this.totalElements = res.data.total;
            this.router.navigate([
              '/acceuil/parametres/evaluation-default',
              { idEvaluation: idEvaluation },
            ]);
          } else {
            this.spinner.hide();
            this.toastr.error("Unexpected API response.");
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.toastr.error("Failed to load evaluations. Please try again.");
          console.error('Erreur API :', err);
        },
      });
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
  
  onPageChange(e: PageEvent): void {
    this.pageSize = e.pageSize;
    console.log('this.pageSize',this.pageSize)
    this.currentPage = e.pageIndex;
    console.log('this.currentPage',this.currentPage)
    this.getListEvaluation();
}
}
