import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListCandidatComponent } from './components/list-candidat/list-candidat.component';
import { ProfilCandidatComponent } from './components/profil-candidat/profil-candidat.component';
import { UpdateCandidatComponent } from './components/update-candidat/update-candidat.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CvCandidatComponent } from './components/cv-candidat/cv-candidat.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list-candidat',
    pathMatch: 'full',
  },
  {
    path:'list-candidat',
    component:ListCandidatComponent
  },
  {
    path:'profil-candidat',
    component:ProfilCandidatComponent
  },
  {
    path:'profil-candidat/update',
    component:UpdateCandidatComponent
  },
  {
    path:'change-mot-de-passe',
    component:ChangePasswordComponent
  },
  {
    path:'Cv-candidat',
    component:CvCandidatComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CandidatRoutingModule { }
