<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_content" class="app-content flex-column-fluid">

  <!--begin::Content container-->
  <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <div class="d-flex align-items-center">
          <h1 class="page-heading"><svg width="22" height="24" viewBox="0 0 22 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.14455 20.3909C7.83907 19.4881 8.90757 18.9571 10.0295 18.9571H14.2501C14.8912 18.9571 15.4788 18.6916 15.9062 18.2668L21.1419 12.8505C21.3022 12.6912 21.3022 12.3726 21.1419 12.2133C20.9816 12.054 20.7145 12.0009 20.5542 12.1071L15.6391 15.0276C15.4788 15.8242 14.7843 16.4614 13.9295 16.4614H9.01442C8.7473 16.4614 8.48017 16.249 8.48017 15.9304C8.48017 15.6649 8.7473 15.3993 9.01442 15.3993H13.9295C14.3035 15.3993 14.624 15.0807 14.624 14.709C14.624 14.3373 14.3035 14.0187 13.9295 14.0187H6.45002C5.38152 14.0187 4.36644 14.4966 3.72534 15.2931L1.85547 17.5234L6.66372 21.1343L7.14455 20.3909Z"
                fill="#265D91" />
              <path
                d="M6.92951 22.6211L6.44868 22.2494L1.15961 18.2668L0.144531 19.5413L5.91443 23.8956L6.92951 22.6211Z"
                fill="#265D91" />
              <path
                d="M3.61904 11.4698H17.6164C18.0438 11.4698 18.3643 11.1512 18.3643 10.7264V6.69073C15.7999 7.59346 13.1821 8.07137 10.6177 8.07137C8.05332 8.07137 5.43549 7.59346 2.87109 6.69073V10.7264C2.87109 11.1512 3.19164 11.4698 3.61904 11.4698Z"
                fill="#265D91" />
              <path
                d="M18.3643 5.36322V2.97367C18.3643 2.54886 18.0438 2.23025 17.6164 2.23025H14.2506V0.637215C14.2506 0.318607 13.9835 0 13.6095 0H7.62592C7.30537 0 6.98482 0.265506 6.98482 0.637215V2.23025H3.61904C3.19164 2.23025 2.87109 2.54886 2.87109 2.97367V5.36322C7.9999 7.27487 13.2355 7.27487 18.3643 5.36322ZM8.2136 1.22133H12.9684V2.17715H8.2136V1.22133ZM9.81635 4.56671C9.81635 4.0888 10.1903 3.66399 10.6177 3.66399C11.0451 3.66399 11.4191 4.03569 11.4191 4.56671C11.4191 5.04462 11.0451 5.46943 10.6177 5.46943C10.1903 5.46943 9.81635 5.04462 9.81635 4.56671Z"
                fill="#265D91" />
            </svg>


            Offres d’emploi
          </h1>

        </div>
        <!--end::Title-->
        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <a  class="text-muted"  routerLink="/">Accueil</a>
        </li>
          <!--end::Item-->
          <!--begin::Item-->

          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <li class="breadcrumb-item text-muted">Offres d’emploi</li>

          <!--end::Item-->
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->
      <!--begin::Actions-->

      <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
  </div>
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->
    <div class="card mb-10 pb-8">
      <div class="container-fluid">
        <div class="title">
          <h4>Recherche</h4>
        </div>
        <div [formGroup]="jobOfferForm"> 
        <div class="row mb-5">
          <div class="col-lg-6">
            <div class="input-group search-libre">
              <span class="input-group-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                      fill="none">
                      <path
                          d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                          fill="#7E7C7C" />
                  </svg>
              </span>
  
              <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid ps-5"
              placeholder="Rechercher" formControlName="search"  (keyup.enter)="searchOffer()"
             />
                  <div class="tooltip-container tooltip-info">
                    <div class="tooltip-trigger"><i class="fa-solid fa-circle-info" style="font-size: 20px;"></i></div>
                    <div class="tooltip-content">
                      <div class="content">
                        <h4>Opérateur AND :</h4>
                        <p>Utilisez l'opérateur AND pour spécifier que les résultats doivent inclure plusieurs termes.
                        </p>
                        <p>Exemple : software AND engineer renverra des profils qui mentionnent à la fois "software" et "engineer".
                        </p>
                        <h4>Opérateur OR :</h4>
                        <p>Utilisez l'opérateur OR pour spécifier que les résultats peuvent inclure l'un des termes.
                        </p>
                        <p>Exemple : sales OR marketing renverra des profils qui mentionnent soit "sales" soit "marketing".
                        </p>
                        <h4>Opérateur NOT :</h4>
                        <p>Utilisez l'opérateur NOT pour exclure des termes spécifiques des résultats.
                        </p>
                        <p>Exemple : developer NOT manager renverra des profils qui mentionnent "developer" mais pas "manager".
                        </p>
                        <h4>Guillemets pour une expression exacte :</h4>
                        <p>Utilisez des guillemets pour rechercher une expression exacte.
                        </p>
                        <p>Exemple : "product manager" renverra des profils qui mentionnent exactement "product manager".
                        </p>
                        <h4>Parenthèses pour grouper des opérations :</h4>
                        <p>Utilisez des parenthèses pour définir l'ordre d'évaluation des opérations.
                        </p>
                        <p>Exemple : (sales OR marketing) AND manager renverra des profils qui mentionnent "sales" ou "marketing" et "manager".
                        </p>
                        <h4>Recherche standard :</h4>
                        <p>Si aucun opérateur n'est spécifié dans la recherche, le texte sera interprété comme s'il était entre guillemets.
                        </p>
                        <p>Exemple : La recherche product manager sera traitée comme "product manager", suivant la règle 4.
                        </p>
                      </div>
                    </div>
                </div>
          </div>
          <!-- <div class="text-danger mt-2"
          *ngIf="jobOfferForm.get('search')?.hasError('pattern') && jobOfferForm.get('search')?.touched && isSimpleWord(jobOfferForm.get('search')?.value)">
          Le modèle de recherche est invalide. Utilisez le format correct (par exemple "hello" OR "hello").
        </div> -->
           </div>
        </div>
        <div class="row tools" >
         <div class="row">
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative   mb-3">
              <input type="text" class="form-control form-control-solid ps-5" formControlName="ID_jobOffer"
                name="société" value="" placeholder="ID" />
            </div>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative   mb-3">
              <input type="text" class="form-control form-control-solid ps-5" formControlName="name" name="société"
                value="" placeholder="Poste" />
            </div>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative   mb-3">
              <input type="text" class="form-control form-control-solid ps-5" formControlName="esn_name" name="société"
                value="" placeholder="Nom entreprise" />
            </div>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative   mb-3">
              <ng-select formControlName="company_nature" (change)="search=false;" [items]="typesCompanies" bindLabel="name" bindValue="id"
                [placeholder]="'Nature d\'entreprise'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
              </ng-select>
            </div>
          </div>
         </div>
         <div class="row" *ngIf="seeMore">
          <div class="col-lg-6 col-xl-3">
            <div class="combined-input mb-3">
              <input type="number" name="number1" min="0" formControlName="years_of_experience"
                (keypress)="keyPress($event)" class="nb1" placeholder="Années d’expériences" id="tjm">

              <input type="number" min="0" formControlName="experience_range" (keypress)="keyPress($event)"
                name="number2" class="nb2" value="1" id="marge_tjm">
              <div class="plusetmois">
                <i class="fa-solid fa-plus-minus"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative   mb-3">
              <ng-select formControlName="contract_type" [items]="contratRequest" bindLabel="label" [multiple]="true"
                bindValue="id" [placeholder]="'Type de contrat'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
              </ng-select>
            </div>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="combined-input  mb-3">
              <input type="number" min="0" name="number1" formControlName="desired_salary" (change)="search=false;alreadyConverted=false" class="nb1 "
                placeholder="Salaire" id="Salaire">
              <div class="euro">
                <span class="input-group-text">€</span>
              </div>
              <input type="number" min="0" (keypress)="keyPress($event)" formControlName="desired_salary_range" (change)="alreadyConverted=false" (input)="onSalaryRangeInput($event)"
                      name="number2" class="nb2" value="1" id="marge_tjm">
              <div class="icon">
                <span class="input-group-text">K€</span>
              </div>
              <div class="plusetmois">
                <i class="fa-solid fa-plus-minus">&nbsp;&nbsp;&nbsp;&nbsp;</i>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="combined-input  mb-3">
              <input type="number" min="0" name="number1" class="nb1" formControlName="tjm" placeholder="TJM" id="tjm">
              <div class="euro">
                <span class="input-group-text">€</span>
              </div>
              <input type="number" min="0" name="number2" formControlName="tjm_range" class="nb2" value="1"
                id="marge_tjm">
              <div class="icon">
                <span class="input-group-text">€</span>
              </div>
              <div class="plusetmois">
                <i class="fa-solid fa-plus-minus"> &nbsp;&nbsp;&nbsp;&nbsp; </i>
              </div>
            </div>

          </div>
         </div>
         <div class="row" *ngIf="seeMore">
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative   mb-3">
              <!-- <input type="text" class="form-control form-control-solid ps-5" formControlName="post_type" name="nom"
                value="" placeholder="Type de poste" /> -->
                <ng-select formControlName="post_type" [items]="postType" bindLabel="name" [multiple]="true"
                        bindValue="id" [placeholder]="'Type de poste'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                      </ng-select>
            </div>
          </div>
          <div class="col-lg-6 col-xl-3  mb-3">
            <ng-select formControlName="skillsAnd" [items]="skillsList" bindLabel="name_FR" bindValue="name_FR"
              [multiple]="true" [placeholder]="'Compétences (Et)'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
            </ng-select>
          </div>
          <div class="col-lg-6 col-xl-3  mb-3">
            <ng-select formControlName="skillsOr" [items]="skillsList" bindLabel="name_FR" bindValue="name_FR"
              [multiple]="true" [placeholder]="'Compétences (Ou)'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
            </ng-select>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative   mb-3">
              <ng-select formControlName="availability" [items]="disponibilite" [multiple]="true" bindLabel="name"
                bindValue="id" [placeholder]="'Disponibilité'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
              </ng-select>
            </div>
          </div>
         </div>
        <div class="row" *ngIf="seeMore">
          <div class="col-lg-6 col-xl-3 mb-3">
            <ng-select (change)="onCountryChange($event)" [items]="paysList" [multiple]="true" formControlName="country"
              bindLabel="name" bindValue="name" [placeholder]="'Pays'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
            </ng-select>

          </div>
          <div class="col-lg-6 col-xl-3  mb-3">
            <ng-select [items]="stateList" (change)="onMobilityChange($event)" bindLabel="name" formControlName="city" bindValue="name" [multiple]="true"
              [placeholder]="'Ville'" autoComplete [notFoundText]="'Aucun élément trouvé'">
            </ng-select>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative  calenderDate mb-3">
            

                <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy"  placeholder="Date de début de publication"
                inputId="yearpicker"
                   formControlName="start_date" class="form-control" [maxDate]="today" 
                  ></p-calendar>
            </div>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative calenderDate  mb-3">

                <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy" 
                placeholder="Date de fin de publication" inputId="yearpicker"  [minDate]="jobOfferForm?.get('start_date')?.value"
               formControlName="end_date"  class="form-control"
                  ></p-calendar>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="seeMore">
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative   mb-3">
                <ng-select formControlName="profile_abroad" [items]="profile" bindLabel="name" bindValue="id"
                [placeholder]="'Profil Étrangers'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
              </ng-select>
            </div>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative   mb-3">
      
                <ng-select formControlName="passeport_talent" [items]="Statepassport" bindLabel="name" bindValue="id"
                [placeholder]="'Passeport Talent'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
              </ng-select>
            </div>
          </div>
          <div class="col-lg-6 col-xl-3">
            <div class="position-relative   mb-3">
              <ng-select formControlName="state" [items]="StateOffre" bindLabel="name" bindValue="id"
                [placeholder]="'Statut'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
              </ng-select>
            </div>
          </div>
             <div class="col-lg-3"></div>
        </div>
      <div class="row">
        <div class="voir-plus mb-5">
          <a class="voir-plus-btn" (click)="seeMore=!seeMore">Voir {{!seeMore?'plus':'moins'}} de critères de recherche</a>
        </div>
      </div>
          <div class="col-lg-3"></div>
          <div class="col-lg-3"></div>
          <div class="col-lg-3"></div>
          <div class="col-lg-3 ">
            <div class="mb-3 search-btn d-flex justify-content-end">
              <div class="align-items-center recherche">
                <button type="submit" class="me-5" (click)="search=true;searchOffer()" >
                  <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                      fill="white" />
                  </svg>
                  Rechercher</button>
              </div>
              <div class="resett-btn">
           
                <div class="reset-icon cursor-pointer"  >
                 <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                 </div>
              </div>
  
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div *ngIf="!isLoadSpinner" class="card">
      <div class="container-fluid">
        <div class="card-body pt-0">
          <!--begin::Table-->
          <div class="title">
            <h4>Liste des Offres d’emplois</h4>
          </div>
          <div class="table-responsive mb-5" id="kt_customers_table">
            <table *ngIf="listJobOffer.length" class="table align-middle gy-5 mb-4" >
              <thead>

                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <ng-container *ngFor="let item of listNames ;let i =index">
                    <!-- [ngClass]="i==0?'one':i==listNames.length?'four':i % 2 === 0?'two':'three'" -->
                    <th  class="min-w-175px"
                      *ngIf="item!='checkbox'" [ngClass]="{'th-fixed-2': item === 'Poste', 'th-fixed': item === 'Nom entreprise'}">
                      <span>{{item}}</span>
                      <i *ngIf="!['Liste de candidatures','Logo entreprise','Compétences','Nature'].includes(item)" (click)="sortData(item)"
                        class="fa-solid fa-sort cursor-pointer"></i>
                    </th>

                  </ng-container>
                  <th *ngIf="listNames.length" class="min-w-90px"><span>ACTIONS</span></th>
                  <th class="min-w-70px">
                    <div class="tools-column">
                       <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                          fill="none">
                          <g filter="url(#filter0_d_588_1699)">
                            <rect x="4" width="20" height="20" rx="4" fill="#63C3E8" fill-opacity="0.12"
                              shape-rendering="crispEdges" />
                          </g>
                          <path
                            d="M17.5 10.6H14.6V13.5C14.6 13.8314 14.3314 14.1 14 14.1C13.6686 14.1 13.4 13.8314 13.4 13.5V10.6H10.5C10.1686 10.6 9.9 10.3314 9.9 10C9.9 9.66863 10.1686 9.4 10.5 9.4H13.4V6.5C13.4 6.16863 13.6686 5.9 14 5.9C14.3314 5.9 14.6 6.16863 14.6 6.5V9.4H17.5C17.8314 9.4 18.1 9.66863 18.1 10C18.1 10.3314 17.8314 10.6 17.5 10.6Z"
                            fill="#265D91" stroke="#265D91" stroke-width="0.2" />
                          <defs>
                            <filter id="filter0_d_588_1699" x="0" y="0" width="28" height="28"
                              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix" />
                              <feColorMatrix in="SourceAlpha" type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix type="matrix"
                                values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.13 0" />
                              <feBlend mode="normal" in2="BackgroundImageFix"
                                result="effect1_dropShadow_588_1699" />
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_588_1699"
                                result="shape" />
                            </filter>
                          </defs>
                        </svg>
                      </button>
                     <div class="list-actions">
                      <div class="dropdown-menu drop-height" aria-labelledby="dropdownMenuButton">
                        <div class="d-flex checkbox mb-2 menu" *ngFor="let item of dataHeader;let i =index">
                          <div class="form-check form-check-sm form-check-custom form-check-solid"  *ngIf="item.name!='checkbox' && item.name!='Actions' ">
                         
                            <input class="form-check-input checkbox-border cursor-pointer"
                            [checked]="(item.checked=='oui'&&!item.checked_user)||item.checked_user=='oui'&&(item.checked=='non'||item.checked=='oui')"
                              (change)="changeSelection($event,item,i)" type="checkbox" value="1" [disabled]="item.name==='Nom entreprise' || item.name==='Logo entreprise'"/>
                            <a class="dropdown-item" >{{item.name}}</a>
                          </div>
                        </div>
                      </div>
                     </div>
                    </div>
                    </div>
                   
                  </th>
                </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
                <tr *ngFor="let offre of listJobOffer ;let i =index">
                  <td *ngIf="listNames?.includes('ID')"><a 
                    [routerLink]="['/acceuil/offre-emploi/details-offer', { id: offre.id }]"
                    class="first-td">{{offre.ID_jobOffer?offre.ID_jobOffer:'---'}} </a></td>
                  <td *ngIf="listNames?.includes('Logo entreprise')">
                    <img *ngIf="offre?.esn?.logo&&offre?.esn?.logo!='avatar.png'&&offre?.esn?.logo!='undefined'"
                      class="table-img" src="{{url+'/Company/file/gallery_company/' + offre?.esn?.logo}}" alt=""
                      width="50px"  (click)="goToDetails(offre.id)">
                    <img
                      *ngIf="!offre?.esn?.logo||offre?.esn?.logo&&(offre?.esn?.logo=='avatar.png'||offre?.esn?.logo=='undefined')"
                      class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt="" width="50px"  (click)="goToDetails(offre.id)">

                  </td>
                  <td *ngIf="listNames?.includes('Nom entreprise')" class="wrapping td-fixed"><span>{{offre.esn?.name?offre.esn?.name:'---'}}
                    </span></td>
                  <td *ngIf="listNames?.includes('Poste')" class="wrapping td-fixed-2">
                    <a  [routerLink]="['/acceuil/offre-emploi/details-offer', { id: offre.id }]"><span class="first-td poste-wrap">{{offre.name?offre.name:'---'}}</span></a>
                  </td>
                  <td *ngIf="listNames?.includes('Nature')">
                    <span class="first-td">
                      <div *ngIf="offre?.esn?.type === '4'; else elseBlock">
                      <p class="badge vertremote">Cabinet de Recrutement</p>
                    </div>
                    
                    <ng-template #elseBlock>
                      <p class="badge remote">ESN</p>
                    </ng-template></span>
                  </td>
                  <td *ngIf="listNames?.includes('Date de publication')">
                    <span>{{!offre.start_date?'---':offre.start_date | formatDate}}</span>
                  </td>
                  <td *ngIf="listNames?.includes('Années d’expériences')">
                    <span>{{!offre.years_of_experience?'---':offre.years_of_experience }}</span>
                  </td>
                  <td *ngIf="listNames?.includes('Type de contrat')">
                    <!-- <span class="badge" *ngFor="let contrat of offre.contract_type">
                      {{contratRequest[contrat]?contratRequest[contrat].label:''}}</span> -->
                      <!-- <div *ngFor="let contractType of offre.contract_type">
                        <span class="color-blue">{{ contratRequest[contractType]}}</span>
                        
                      </div> -->
                      <div *ngFor="let contractType of offre.contract_type">
                        <span class="color-blue">{{ contratTypeOptions[contractType] }}</span>
                      </div>
                      <span *ngIf="offre.contract_type.length==0">---</span>
                  </td>
                  <td *ngIf="listNames?.includes('Salaire')">
                    <span>{{!offre.salary || offre.salary==="null" ?'---':offre.salary }}</span>
                  </td>
                  <td *ngIf="listNames?.includes('TJM')">
                    <span>{{!offre.tjm || offre.tjm==="null"?'---':offre.tjm }}</span>
                  </td>
                  <td *ngIf="listNames?.includes('Type de poste')">
                    <span *ngIf="offre.post_type=='1'"
                    >A distance</span>
                    <span *ngIf="offre.post_type=='2'"
                    >Hybride</span>
                    <span *ngIf="offre.post_type=='3'"
                    >Sur site</span>
                    <span *ngIf="offre.post_type===null">---</span>
                  </td>
                  <ng-container *ngIf="listNames?.includes('Compétences')">
                    <td >
                      <div class="skills cle" *ngIf="offre.skills_job?.length">
                      
                       <div class="skill" *ngFor="let skill of offre.skills_job.slice(0,2)">
                        <span class="key-word">
                          <div class="badge three">{{skill?.sub_category?.name_FR?skill?.sub_category?.name_FR:'---'}}</div>
                        </span>
                       </div>
                       <span *ngIf="offre.skills_job?.length&&offre.skills_job?.length>2"><svg width="8" height="8"
                        viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.34144 4.86849H4.51344V7.76649H3.02944V4.86849H0.201438V3.52449H3.02944V0.626487H4.51344V3.52449H7.34144V4.86849Z"
                          fill="#ACA7A7" />
                      </svg>
                    </span>
                      </div>
                      <div class="skills cle" *ngIf="!offre.skills_job?.length">
                        <span class="key-word">
                          <div class="badge three ">---</div>
                        </span>
                       </div>
                    </td>

                  </ng-container>
                  <!-- (click)="goToListCandidature(offre.id,offre.name)" -->
                  <td *ngIf="listNames?.includes('Liste de candidatures')">
                    <a class="name"  [routerLink]="['/acceuil/offre-emploi/candidatures',offre.id]">Liste des candidatures
                    </a>
                  </td>
                  <td *ngIf="listNames?.includes('Pays')">
                    <span>{{!offre.country || offre.country==='null' ?'---':offre.country }}</span>
                  </td>
                  <td *ngIf="listNames?.includes('Ville')">
                    <span *ngIf="offre.city">{{offre.city }}</span>
                    <span *ngIf="offre.city?.length==0  || offre.city===null">---</span>

                  </td>
                  <td *ngIf="listNames?.includes('Statut')">
                    <div [ngClass]="offre.state?'badge-red':'badge-green'" class="badge">{{!offre.state?'Activé':'Désactivé'}}
                    </div>
                  </td>


                  <td *ngIf="listNames.length">
                  
                  <div class="tools">
                    <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                            fill="#265D91" />
                          <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)"
                            fill="#265D91" />
                          <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)"
                            fill="#265D91" />
                        </svg>
                      </button>
                     <div class="dropdown-table">
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      
                        <a class="dropdown-item"  style="cursor: pointer;" (click)="goToDetails(offre.id)">Voir détail</a>
                        <a class="dropdown-item"  style="cursor: pointer;" routerLink="/acceuil/offre-emploi/contact-manager">Contacter manager</a>
                        <a class="dropdown-item"  style="cursor: pointer;" [routerLink]="['/acceuil/offre-emploi/candidatures',offre.id]">Liste des candidatures</a>
                        <a class="dropdown-item"  style="cursor: pointer;" [routerLink]="['/acceuil/offre-emploi/get-bareme',offre.id]"   [ngClass]="{ 'disabled-link': offre?.score === 'no' }">Voir Barème</a>
                        <a class="dropdown-item"  style="cursor: pointer;" [routerLink]="['/acceuil/offre-emploi/get-test',offre.id]" [ngClass]="{ 'disabled-link': offre?.test === 'no' }">Voir Test</a>
                        <a *ngIf="offre.state=='1'" class="dropdown-item" style="cursor: pointer;"  (click)="activerjoboffer(offre.id)">Activer</a>
                        <a *ngIf="offre.state=='0'" class="dropdown-item" style="cursor: pointer;" (click)="desactiverjoboffer(offre.id)">Désactiver</a>


                                    </div>
                     </div>
                                  </div>
                  </div>
                   
                  </td>
                </tr>
              </tbody>
              <!--end::Table body-->
            </table>
          </div>
     
          <div class="pagination" *ngIf="listJobOffer && listJobOffer.length">
            <app-pagination [data]="listJobOffer" [lengthData]="totalItems" [currentPageList]="currentPage" [endexPageList]="endIndex" (setPages)="getItems($event)"> </app-pagination>
          </div>
     

          <div class="alert alert-warning text-center mt-5" role="alert"  *ngIf="!listJobOffer.length ">
            <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
            <span class="empty-list">
             La liste est vide
         </span>
         </div>


          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
    </div>
    <!--end::Card-->
    <!--begin::Modals-->
    <!--begin::Modal - Customers - Add-->

    <!--end::Modal - Customers - Add-->
    <!--begin::Modal - Adjust Balance-->

    <!--end::Modal - New Card-->
    <!--end::Modals-->
  </div>
  <!--end::Content container-->
</div>
<!-- modal detail offre -->


