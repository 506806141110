import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { LocalStorageService } from '../../core/services/Local-storage/local-storage.service';
import { AuthService } from '../../core/services/authentication/auth.service';
import { CandidatService } from '../../core/services/candidat/candidat.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  roleuser: any;
  currentUser: any;
  userData: any;
  profil_id: any;
  profil_image: any;
  last_name: any;
  first_name: any;
  id: any;
  role_user_id: any;
  image_url: any;
  url: string = environment.baseUrl + '/api';

  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  base_url_img = environment.baseUrlGalery;

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private spinner: NgxSpinnerService,
    private candidatService: CandidatService,
    private router: Router
  ) {
    this.currentUser = this.localStorageService.getData('AdminInfo');

    if (this.currentUser) {
      try {
        this.id = JSON.parse(this.currentUser).uuid;
      } catch (error) {
      }
    }
  }
  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.spinner.show();
    this.candidatService
      .getById(this.id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();
          if (res?.body?.data) {
            this.userData = res.body?.data;
            this.first_name = res.body.data.first_name || '';
            console.log('first_name Admin', this.first_name);

            this.last_name = res.body.data.last_name || '';
            this.image_url = res.body.data.image_url || '';
            this.roleuser = res.body.data.role_user_id
              ? res.body.data.role_user_id[0]
              : null;
            console.log('role user sidebar', this.roleuser);
          } else {
            console.error('Response data is undefined');
          }
          this.spinner.hide();
        },
        error: (err) => {
          console.error('Error fetching user data', err);
          this.spinner.hide();
        },
      });
    }
    isLinkActive(): boolean {
      return this.router.url.startsWith('/acceuil/parametres/evaluation-default');
  }
}
