import { Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  CSearchCandidate,
  ISort,
} from '../../../../../../core/models/candidat';
import { serverError } from '../../../../../../core/models/messages';
import { CompanyService } from '../../../../../../core/services/company/company.service';
import { SortDataService } from '../../../../../../core/services/sort-data/sort-data.service';
import { environment } from '../../../../../../../environments/environment';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list-consultant',
  templateUrl: './list-consultant.component.html',
  styleUrls: ['./list-consultant.component.css'],
})
export class ListConsultantComponent {
  /* string */
  mode: string = 'create';
  url: string = environment.baseUrl + '/api';
  searchElement: string = '';
  sortAttr: string = '';
  /* number */
  idCompany!: any;
  currentSortDirection: number = 2;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  clickCount: number = 0;
  pagination : boolean =false;

  /* any */
  listConsultant: any = [];
  selectedConsult: any;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  separateDialCode: boolean = false;
  selectedCountryCode: string = 'FR'
   /* formGroup */
   phoneForm!: FormGroup
   /* form group */
searchFormGroup: FormGroup = this.createSearchForm(new CSearchCandidate())
/* modal */
modalRef?: BsModalRef;
 /* unsubscription */
 private unsubscribeAll: Subject<void> = new Subject();
 constructor( private companyService: CompanyService,private router:Router,
   private spinner: NgxSpinnerService, private toastr: ToastrService,private activatedRoute:ActivatedRoute,private sortDataService: SortDataService,private modalService: BsModalService,private formBuilder:FormBuilder
 ) { }
ngOnInit(){
 this.getIdCompany()
 this.createPhoneForm()
}
createPhoneForm(){
  this.phoneForm = new FormGroup({
    phone: new FormControl(undefined),
  });
}
openModal(template: TemplateRef<any>,data:any) {
  this.modalRef = this.modalService.show(template,{backdrop: 'static'});
  if(data) {
    this.selectedConsult=data
    this.selectedConsult.fullName=`${this.selectedConsult.first_name} ${this.selectedConsult.last_name}`
    this.phoneForm.get('phone')?.setValue(data.phone_number)
    this.phoneForm.disable()
  }
}
closeModal() {
  this.modalRef?.hide();
}
/* get company id from route */
getIdCompany() {
  this.activatedRoute.paramMap.subscribe({
    next: (params: any) => {
      this.idCompany = params.params['id']
      this.getListConsultant()
    }
  })
}
/* get indexes for pagination */
getItems(event?: any){  
  if(event && event.startIndex!=this.startIndex||this.endIndex!=event.endIndex||
    this.itemsPerPage!=event.itemsPerPage||
    this.currentPage!=event.currentPage){
  this.startIndex=event.startIndex
  this.endIndex=event.endIndex
  this.itemsPerPage=event.itemsPerPage,
  this.currentPage=event.currentPage
  if(this.searchElement !='') this.applyFilter() 
  else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
    this.pagination = true
   this.getListConsultant();
  }
}
}
/* sort data  */
sortData(sortAttribute:string){
  this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
  this.sortAttr=sortAttribute
  this.currentPage = 1;
  this.endIndex=5;
  if(this.searchElement !='') this.listConsultant = this.sortDataService.sortArray(this.listConsultant, sortAttribute, this.currentSortDirection)
  else this.getListConsultant()
}


/* list list esn companies */
getListConsultant(sort?:ISort){
  this.spinner.show()
  let dataPyload =this.sortAttr==''? {per_page: this.itemsPerPage, page: this.currentPage}:{ ...{per_page: this.itemsPerPage, page: this.currentPage}, ...{sort:this.currentSortDirection,sortAttribute:this.sortAttr} }
this.companyService.getConsultantClientFinal(this.idCompany,dataPyload).pipe(takeUntil(this.unsubscribeAll)).subscribe({
 next:(res:any)=>{
   this.spinner.hide()
   this.listConsultant=res.data.data
   this.totalItems=res.data.total
   if(this.currentPage>1 && !res.data.data.length){
    this.currentPage=1
    this.getListConsultant()
  }
 },
 error:()=>{
   this.spinner.hide()
this.toastr.error(serverError)

 }
})
}

  /* search consultant on input */
  applyFilter(event?: Event) {
    let data = {
      name: this.searchFormGroup.value.name,
      project_name: this.searchFormGroup.value.project_name,
      type: 3,
    };
    this.spinner.show();
    this.companyService
      .searchConsultant(this.idCompany, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          if (!res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.applyFilter();
          } else {
            this.listConsultant = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* create search form */
  createSearchForm(data?: CSearchCandidate) {
    return this.formBuilder.group({
      name: ['', data?.name],
      project_name: ['', data?.project_name],
    });
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.searchFormGroup = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getListConsultant();
    }
    return (this.isRecherche = false);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  dissocierconsultant(id: any) {
    console.log('idcandidate', id);
    console.log('idclient', this.idCompany);
    let formdata = new FormData();
    formdata.append('clientFinal_id', this.idCompany);
    formdata.append('candidate_id', id);
    console.log('formdata', formdata);
    Swal.fire({
      title: 'Voulez-vous vraiment dissocier ce consultant ?',
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, dissocier !',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.companyService
          .dissocierconsultantclientfinal(formdata)
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe({
            next: (res: any) => {
              console.log('response', res);
              this.getListConsultant();
            },
            error: () => {
              this.spinner.hide();
            },
          });
      }
    });
  }

  goToDetails(id: number) {
    this.router.navigate([
      '/acceuil/entreprises/esn/details-consultant-esn',
      { id: id },
    ]);
  }
}
