import { Injectable } from '@angular/core';
import { CrudService } from '../crud/crud.service';
import { ApiResponse } from '../../models/response-api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MailingService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}`);
  }

  getListMailing() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/api/MailingAdmin/getAll`
    );
  }

  /*delete mailing*/
  deleteMailing(MailId: string) {
    const url = `${this._base}/api/MailingAdmin/delete/${MailId}`;
    return this._http.delete(url);
  }
  /*add mailing */
  addMailing(data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + `/api/MailingAdmin/add`,
      data
    );
  }
  /*update mailing */
  updateMailing(data: any, idMail: number): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + `/api/MailingAdmin/update/${idMail}`,
      data
    );
  }
  /*serach mailing */
  searchListMailing(data: any) {
    return this._http.post<any>(this._base + `/api/MailingAdmin/search`, data);
  }
}
