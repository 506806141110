import {
  ChangeDetectorRef,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedSubCategorie,
  confirmDelete,
  existedCategory,
  existedSubCategory,
  failedDelete,
  serverError,
  successDeletesouscategori,
  updatedCategorie,
  updatedSubCategorie,
} from '../../../../core/models/messages';
import { CSubCategory } from '../../../../core/models/module';
import { ModuleService } from '../../../../core/services/modules/module.service';
import { SortDataService } from '../../../../core/services/sort-data/sort-data.service';
import { environment } from '../../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-details-categories',
  templateUrl: './details-categories.component.html',
  styleUrls: ['./details-categories.component.css'],
})
export class DetailsCategoriesComponent {
  file!: any;
  fileName: string = '';
  selectedImage: any;
  /* form group */
formSubCategory: FormGroup = this.createForm(new CSubCategory())
/* form data */
formData = new FormData();
/* number */
idSubCategory!:number
idCategory!:number
itemsPerPage:number=5
currentPage:number=1
startIndex:number=0
endIndex:number=5
totalItems!:number
currentSortDirection: number = 2;
pagination : boolean =false;

clickCount:number=0
/* array */
subCatList:any=[]
listSubCategory:any=[]
/* string */
mode!:string
urlSubCategory: string =environment.baseUrl+'/api/SubCategory/file/gallerySubCategories/'
urlCategory: string =environment.baseUrl+'/api/Category/file/galleryCategories/'
icon!:string
fileError:string=''
fileExtention:string=''
searchElement:string=''
sortAttr:string=''
/* object */
category!:{name_FR:string,icon:string,id:any}
/* ViewChild */
@ViewChild('fileInput') fileInput: any;
/* modal */
modalRef?: BsModalRef;
isLoadSpinner: boolean = true;

/* unsubscription */
private unsubscribeAll: Subject<void> = new Subject();
constructor(private activatedRoute:ActivatedRoute ,private moduleService:ModuleService,private toastr:ToastrService,
  private spinner: NgxSpinnerService,private formBuilder:FormBuilder,private cdRef: ChangeDetectorRef,
 private modalService: BsModalService, private router:Router,private sortDataService: SortDataService){}
ngOnInit(){
  this.getId()
}
ngAfterViewInit(): void {
  this.cdRef.detectChanges();
}
openModal(template: TemplateRef<any>) {
  this.modalRef = this.modalService.show(template,{backdrop: 'static'});
}
closeModal() {
  this.modalRef!.hide();
  this.fileError=''
  this.fileExtention=''
  this.formSubCategory.reset()
  this.fileName=''
  this.formData= new FormData()
}

  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') this.applyFilter();
      else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
      this.getListSubCtaegoryById();
      }
    }
  }

  /* get id subCategory from route */
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCategory = params.params['idCat'];
        this.getListSubCtaegoryById();
        this.getCategory();
      },
    });
  }
  /* get lis sub cat by id category */
  getListSubCtaegoryById() {
    let data =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage }
        : {
            ...{ per_page: this.itemsPerPage, page: this.currentPage },
            ...{
              sort: this.currentSortDirection,
              sortAttribute: this.sortAttr,
            },
          };
    this.spinner.show();
    this.moduleService
      .getSubCatId(this.idCategory, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isLoadSpinner = false;
          this.spinner.hide();
          this.subCatList = res.data.data.slice(0, this.endIndex);
          console.log('subcat', this.subCatList);
          // this.listSubCategory=res.data.data
          this.totalItems = res.data.total;
        },
        error: (error) => {
          console.log('error', error);
          this.spinner.hide();
          // this.toastr.error(serverError)
        },
      });
  }
  sortData() {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr = 'name_FR';
    this.currentPage = 1;
    this.endIndex=5;
    if (this.searchElement != '')
      this.subCatList = this.sortDataService.sortArray(
        this.subCatList,
        'name_FR',
        this.currentSortDirection
      );
    else this.getListSubCtaegoryById();
  }
  /* get subCategory list from category by  id */
  getCategory() {
    this.spinner.show();
    this.moduleService
      .getCatId(this.idCategory, this.currentSortDirection)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.category = res.data;
          }
          this.spinner.hide();
        },
        error: (err) => {
          console.log('error category by id', err);
          this.spinner.hide();
          // this.toastr.error(serverError)
        },
      });
  }
  deleteSubModule(data: { name_FR: string; id: number }) {
    Swal.fire({
      title: `${confirmDelete} ${data.name_FR}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteSubCat(data.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  deleteSubCat(idCat: number) {
    this.spinner.show();
    this.moduleService
      .deleteSubCategory(idCat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListSubCtaegoryById();
            this.spinner.hide();
            this.toastr.success(successDeletesouscategori);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* filter data on key up input */
  applyFilter() {
    if (this.searchElement != '' || this.clickCount) {
      this.clickCount++;
      if (this.clickCount == 1) this.currentPage = 1;
      let data = {
        search: this.searchElement.trim().toLowerCase(),
        sort: this.currentSortDirection,
        sortAttribute: 'name_FR',
        page: this.currentPage,
        per_page: this.itemsPerPage,
      };
      this.spinner.show();
      this.moduleService
        .searchSubCategory(this.idCategory, data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (!res.data.data.length && this.currentPage > 1) {
              this.currentPage = 1;
              this.applyFilter();
            } else {
              console.log('endIndex', this.endIndex);

              this.subCatList = res.data.data.slice(0, this.endIndex);
              this.totalItems = res.data.total;
            }
            this.spinner.hide();
          },
          error: () => {
            this.toastr.error(serverError);
            this.spinner.hide();
          },
        });
    }
  }
  /* create add subCategory form */
  createForm(data?: any) {
    return this.formBuilder.group({
      name_FR: [
        data ? data.name_FR : '',
        [Validators.required, this.notOnlySpacesValidator()],
      ],
      category_id: [data ? data.category_id : ''],
      icon: [data ? data.icon : ''],
    });
  }
  notOnlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value as string;
      const isValid = value?.trim() !== '';
      return isValid ? null : { onlySpaces: true };
    };
  }
  confirmModal() {
    switch (this.mode) {
      case 'create':
        this.addSubCat();
        break;
      case 'update':
        this.updateSubCat();
        break;

      default:
        this.updateCategory();
        break;
    }
  }
  /* upload file */
  // uploadFile(event: any): void {
  //   const file = event.target.files[0];
  //   this.file = file
  //   const maxSize = 2 * 1024 * 1024;
  //   this.fileName = this.file.name
  //   const allowedExtensions = ['.jpeg', '.jpg', '.png'];
  //   const fileExtension = file.name.toLowerCase().substr(file.name.lastIndexOf('.'));
  //   if (file) {
  //     if (!allowedExtensions.includes(fileExtension)) {
  //       this.fileExtention=fileTypeExtention
  //     }else if(file.size > maxSize){
  //       this.fileError=maxSizeFile
  //     }
  //     else{
  //       this.fileError=''
  //       this.fileExtention=''
  //      const reader = new FileReader();
  //     reader.onload = () => {
  //       this.selectedImage = reader.result;
  //     };
  //     reader.readAsDataURL(file);
  //     }
  //     this.formSubCategory.get('icon')?.setValue(file)
  //   }
  // }
  uploadFile(event: any): void {
    // Reset error messages
    this.fileExtention = '';
    this.fileError = '';

    const fileInput = event.target;
    const file = fileInput.files[0];
    const maxSize = 2 * 1024 * 1024;
    const allowedExtensions = [
      '.jpeg',
      '.jpg',
      '.png',
      '.gif',
      '.jfif',
      '.avif',
    ];

    // Check if the file input is empty (user canceled selection)
    if (!file) {
      // Clear file input and related variables
      fileInput.value = '';
      this.file = null;
      this.fileName = '';
      this.selectedImage = null;
      return;
    }

    // Set file and file name
    this.file = file;
    this.fileName = this.file.name;

    const fileExtension = file.name
      .toLowerCase()
      .substr(file.name.lastIndexOf('.'));

    if (!allowedExtensions.includes(fileExtension)) {
      this.fileExtention = 'Format fichier non compatible !';
      this.toastr.error(
        "Le format du fichier n'est pas accepté.Veuillez choisir une photo"
      );

      // Clear file input and related variables
      // fileInput.value = '';
      // this.file = null;
      // this.fileName = '';
      // this.selectedImage = null;
    } else if (file.size > maxSize) {
      this.fileError = 'File size exceeds the maximum allowed size (2MB).';
      // Clear file input and related variables
      fileInput.value = '';
      this.file = null;
      this.fileName = '';
      this.selectedImage = null;
    } else {
      // Reset other error messages
      this.fileError = '';

      const reader = new FileReader();
      reader.onload = () => {
        this.selectedImage = reader.result;
      };
      reader.readAsDataURL(file);
    }

  // Set the file in the form group
  this.formSubCategory.get('icon')?.setValue(this.file);

}
// isImage(): boolean {
//   const allowedExtensions = ['.jpeg', '.jpg', '.png'];
//   const fileExtension = this.fileName.toLowerCase().substr(this.fileName.lastIndexOf('.'));
//   return allowedExtensions.includes(fileExtension);
// }
isImage(): boolean {
  const allowedExtensions = ['.jpeg', '.jpg', '.png' , '.gif','.jfif','.avif'];
  const fileExtension = (this.fileName || '').toLowerCase().substr((this.fileName || '').lastIndexOf('.'));
  return allowedExtensions.includes(fileExtension);
}
imageWidth: number = 100;
imageHeight: number = 100;
/*  */
setFormUpdate(subCategory:{id:number,icon:string,name_FR:string},template:TemplateRef<any>){
  this.fileName=''
  this.mode='update';
  this.idSubCategory=subCategory.id
  this.icon=subCategory.icon
  this.formSubCategory.get('name_FR')?.setValue(subCategory.name_FR)
  this.formSubCategory.get('icon')?.setValue(subCategory.icon)
  this.openModal(template)
}
/* set form data */
setFormData() {
  this.formData.append("name_FR", this.formSubCategory.value.name_FR);
  this.formData.append("category_id", this.category.id);
   if(this.file) this.formData.append("icon", this.file )
}
/* add subCategory */
addSubCat(){
  this.setFormData()
  this.spinner.show()
  this.moduleService.addSubCategory(this.formData).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      if (res.status == 200) {
        this.getListSubCtaegoryById()
        this.spinner.hide()
        this.toastr.success(addedSubCategorie)
        this.closeModal()
        this.file=null
      }
    },
    error: (err) => {
      console.log("error",err)
      this.formData= new FormData()
      this.closeModal()
      this.file=null
      this.spinner.hide()
   if(err.error&&(err.error.message['name_FR']||err.error.message==='Validation error'))
      this.toastr.info(existedSubCategory)
    }
  })
}
/* update subCategory */
updateSubCat(){
  this.setFormData()
  this.spinner.show()
  this.moduleService.updateSubCategory(this.idSubCategory,this.formData).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      if (res.status == 200) {
        this.getListSubCtaegoryById()
        this.spinner.hide()
        this.toastr.success(updatedSubCategorie)
        this.closeModal()
        this.file=null
      }
    },
    error: (err) => {
      this.formData= new FormData()
      this.file=null
      this.closeModal()
      this.spinner.hide()
      if(err.error.status ==400 && err.error.message==='Validation error'){

        this.toastr.info(existedSubCategory)
      }else{
        this.toastr.error(serverError)
      }
    }
  })
}
add(){
  this.fileName=''
  this.mode='create'
}
edit(){
  this.fileName=''
  this.mode='edit_module'
  this.formSubCategory.get('name_FR')?.setValue(this.category.name_FR)
  this.formSubCategory.get('icon')?.setValue(this.category.icon)
  this.icon=this.category.icon


}
/* update category */
updateCategory(){
  this.setFormData()
  this.spinner.show()
  this.moduleService.updateCategory(this.idCategory,this.formData).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      if (res.status == 200) {
        this.spinner.hide()
        this.getListSubCtaegoryById()
        this.getCategory()
        this.toastr.success(updatedCategorie)
        this.closeModal()
        this.file=null
      }
    },
    error: (err) => {
      this.formData= new FormData()
      this.file=null
      this.closeModal()
      this.spinner.hide()
      if(err.error.status ==400 && err.error.message==='Validation error'){

        this.toastr.info(existedCategory)
      }else{
        this.toastr.error(serverError)
      }
    }
  })
}
 /* go to question by sub-category */
 navigateToQuestion(id: number,subCategory:string,category:string) {
  console.log("idCategory",this.idCategory)
  this.router.navigate(['/acceuil/modules/question-sous-categorie', {id: id, idcat : this.idCategory, subCategory:subCategory,category:category}])
}

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
