import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private onlineStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);
  public onlineStatus$: Observable<boolean> = this.onlineStatusSubject.asObservable();

  constructor(private ngZone: NgZone, private router: Router) {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  }

  private updateOnlineStatus = () => {
    this.ngZone.run(() => {
      const isOnline = navigator.onLine;
      this.onlineStatusSubject.next(isOnline);

      if (!isOnline) {
        this.router.navigate(['/reconnect']);
      } else {
        // Redirige vers la page précédente après reconnexion
        if (this.router.url === '/reconnect') {
          this.router.navigate(['/']); // Redirige vers la page d'accueil ou toute autre page appropriée
        }
      }
    });
  }

  public getOnlineStatus(): boolean {
    return this.onlineStatusSubject.value;
  }
}
