import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'newApp0407';
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
      if (e.key === 'F12') {
          return false;
      }
      if (e.ctrlKey && e.shiftKey && e.key === "I") {
          return false;
      }
      if (e.ctrlKey && e.shiftKey && e.key === "C") {
          return false;
      }
      if (e.ctrlKey && e.shiftKey && e.key === "J") {
          return false;
      }
      if (e.ctrlKey && e.key == "U") {
          return false;
      }
      return true;
  }

}
