import { Component, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexNonAxisChartSeries
} from "ng-apexcharts";
// import { Color, ScaleType } from 'src/app/core/models/chart';

export type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  /* test ngx-charts */
  // public legendPosition: LegendPosition = LegendPosition.Below;
  /* bar & spie graph */
  // saleData = [
  //   { name: "Mobiles", value: 105000 },
  //   { name: "Laptop", value: 55000 },
  //   { name: "AC", value: 15000 },
  //   { name: "Headset", value: 150000 },
  //   { name: "Fridge", value: 20000 },



  // ];

  //  colorSets: Color =
  //   {
  //     name: 'blueBackground',
  //     selectable: true,
  //     group: ScaleType.Ordinal,
  //     domain: [
  //       '#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'
  //     ]
  //   }
/* linear chart */
// linearChartData=[
//   {
//     "name": "Gnana Prakasam",
//     "series": [
//       {
//         "name": "2016",
//         "value": "4000"
//       },
//       {
//         "name": "2017",
//         "value": "4500"
//       },
//       {
//         "name": "2018",
//         "value": "10000"
//       },
//       {
//         "name": "2019",
//         "value": "15000"
//       }
//     ],
//   },


// ];
/* linear graph */
// public view = [700, 400];
  // public showXAxis = true;
  // public showYAxis = true;
  // public gradient = false;
  // public showLegend = true;
  // public showXAxisLabel = true;
  // public xAxisLabel!: "Years";
  // public showYAxisLabel = true;
  // public yAxisLabel!: "Salary";
  // public graphDataChart!: any[];
  // public colorScheme = {
  //   domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  // };


/* ********************************** apexChart ********************************* */
@ViewChild("chart") chart!: ChartComponent;
public chartOptions!:any;
ngOnInit(){
  this.chartOptions = {
  series: [
    {
      name: "basic",
      data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
    }
  ],
  chart: {
    type: "bar",
    height: 350
  },
  plotOptions: {
    bar: {
      horizontal: false
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: [
      "South Korea",
      "Canada",
      "United Kingdom",
      "Netherlands",
      "Italy",
      "France",
      "Japan",
      "United States",
      "China",
      "Germany"
    ]
  }
};
}






}
