<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
        <!--begin::Toolbar container-->
        <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <!--begin::Title-->
                <div class="d-flex align-items-center">
                    <h1 class="page-heading">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_245_4467)">
                                <path
                                    d="M27.1568 9.88124C27.1568 9.68159 27.0768 9.49013 26.9342 9.34896C26.7917 9.20779 26.5984 9.12848 26.3968 9.12848H18.5005C18.2989 9.12848 18.1056 9.20779 17.9631 9.34896C17.8205 9.49013 17.7405 9.68159 17.7405 9.88124V21.4587H16.375V22.9642H28.3196V21.4587H27.1568V9.88124ZM19.2605 10.634H25.6368V21.4587H19.2605V10.634Z"
                                    fill="#265D91" />
                                <path d="M21.2153 11.3993H19.6953V13.6726H21.2153V11.3993Z" fill="#265D91" />
                                <path d="M23.2387 11.3993H21.7188V13.6726H23.2387V11.3993Z" fill="#265D91" />
                                <path d="M25.2622 11.3993H23.7422V13.6726H25.2622V11.3993Z" fill="#265D91" />
                                <path d="M21.2153 14.9097H19.6953V17.183H21.2153V14.9097Z" fill="#265D91" />
                                <path d="M23.2387 14.9097H21.7188V17.183H23.2387V14.9097Z" fill="#265D91" />
                                <path d="M25.2622 14.9097H23.7422V17.183H25.2622V14.9097Z" fill="#265D91" />
                                <path d="M21.2153 18.4175H19.6953V20.6909H21.2153V18.4175Z" fill="#265D91" />
                                <path d="M23.2387 18.4175H21.7188V20.6909H23.2387V18.4175Z" fill="#265D91" />
                                <path d="M25.2622 18.4175H23.7422V20.6909H25.2622V18.4175Z" fill="#265D91" />
                                <path
                                    d="M14.7818 1.88695C14.7818 1.6873 14.7018 1.49583 14.5592 1.35466C14.4167 1.21349 14.2234 1.13419 14.0218 1.13419H6.12546C5.9239 1.13419 5.73059 1.21349 5.58806 1.35466C5.44553 1.49583 5.36546 1.6873 5.36546 1.88695V13.4644H4V14.9699H15.9446V13.4644H14.7818V1.88695ZM6.88546 2.63971H13.2618V13.4644H6.88546V2.63971Z"
                                    fill="#265D91" />
                                <path d="M8.84031 3.40747H7.32031V5.68081H8.84031V3.40747Z" fill="#265D91" />
                                <path d="M10.8637 3.40747H9.34375V5.68081H10.8637V3.40747Z" fill="#265D91" />
                                <path d="M12.8911 3.40747H11.3711V5.68081H12.8911V3.40747Z" fill="#265D91" />
                                <path d="M8.84031 6.91537H7.32031V9.18871H8.84031V6.91537Z" fill="#265D91" />
                                <path d="M10.8637 6.91537H9.34375V9.18871H10.8637V6.91537Z" fill="#265D91" />
                                <path d="M12.8911 6.91537H11.3711V9.18871H12.8911V6.91537Z" fill="#265D91" />
                                <path d="M8.84031 10.4232H7.32031V12.6966H8.84031V10.4232Z" fill="#265D91" />
                                <path d="M10.8637 10.4232H9.34375V12.6966H10.8637V10.4232Z" fill="#265D91" />
                                <path d="M12.8911 10.4232H11.3711V12.6966H12.8911V10.4232Z" fill="#265D91" />
                                <path
                                    d="M25.155 6.58414H26.675V2.35363H21.6514V0L17.1016 3.07126L21.6616 6.11994V3.86166H25.1651L25.155 6.58414ZM19.8072 3.07126L20.1264 2.85547V3.28454L19.8072 3.07126Z"
                                    fill="#265D91" />
                                <path
                                    d="M7.16843 17.5142H5.64844V21.7447H10.672V24.0983L15.2219 21.0271L10.6619 17.9784V20.2367H7.1583L7.16843 17.5142ZM12.5163 21.0271L12.1971 21.2429V20.8188L12.5163 21.0271Z"
                                    fill="#265D91" />
                            </g>
                            <defs>
                                <filter id="filter0_d_245_4467" x="0" y="0" width="32.3203" height="32.0983"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix"
                                        result="effect1_dropShadow_245_4467" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_245_4467"
                                        result="shape" />
                                </filter>
                            </defs>
                        </svg>

                        Entreprises
                    </h1>

                </div>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted">
                        <a class="text-muted" routerLink="/">Accueil</a>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-400 w-5px h-2px"></span>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted"> <a class="text-muted"
                            routerLink="/acceuil/entreprises/list-entreprise">Entreprises</a></li>

                    <li  class="breadcrumb-item">
                        <span class="bullet bg-gray-400 w-5px h-2px"></span>
                    <li *ngIf="type!=1" class="breadcrumb-item text-muted"> <a class="text-muted"
                            routerLink="/acceuil/entreprises/esn/list-esn">Liste des ESN</a></li>
                    <li *ngIf="type!=1" class="breadcrumb-item">
                        <span class="bullet bg-gray-400 w-5px h-2px"></span>
                    </li>
                    <li *ngIf="type!=1" class="breadcrumb-item text-muted">Détails ESN</li>
                    <li *ngIf="type==1" class="breadcrumb-item text-muted">Détails Entreprise</li>
                    <!--end::Item-->
                </ul>
                <!--end::Breadcrumb-->
            </div>
            <!--end::Page title-->
            <!--begin::Actions-->

            <!--end::Actions-->
        </div>
        <!--end::Toolbar container-->
    </div>
    <div   id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->

        <div  *ngIf="type!=1" class="card mb-10 p-10 pt-10">
            <ul class="nav nav-tabs nav-line-tabs">
                <li class="nav-item">
                    <a class="nav-link active loula" (click)="tabulation=10" data-bs-toggle="tab" (contextmenu)="onRightClick($event)"
                        href="#kt_tab_pane_10">Détail esn</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" (click)="tabulation=1" data-bs-toggle="tab" (contextmenu)="onRightClick($event)" href="#kt_tab_pane_1">Abonnement
                        actuel</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" (click)="tabulation=2" data-bs-toggle="tab" (contextmenu)="onRightClick($event)" href="#kt_tab_pane_2">Historique des
                        abonnements</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="tabulation=3" data-bs-toggle="tab" (contextmenu)="onRightClick($event)" href="#kt_tab_pane_3">Liste des
                        consultants </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="tabulation=4" data-bs-toggle="tab" (contextmenu)="onRightClick($event)" href="#kt_tab_pane_4">Liste des
                        intervenants</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="tabulation=5" data-bs-toggle="tab" (contextmenu)="onRightClick($event)" href="#kt_tab_pane_5">Liste offres
                        d'emplois</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="tabulation=6" data-bs-toggle="tab" (contextmenu)="onRightClick($event)"
                        href="#kt_tab_pane_6">Permissions</a>
                </li>
               
                <!-- <li class="nav-item">
                    <a class="nav-link" (click)="tabulation=8" data-bs-toggle="tab" href="#kt_tab_pane_8">Paiement</a>
                </li> -->

            </ul>
        </div>
        <div class="mb-10 pt-10">


            <!--begin::Card header-->

            <!--end::Card header-->
            <!--begin::Card body-->

            <!--end::Card body-->





            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade " id="kt_tab_pane_1" role="tabpanel">
                    <app-actual-offer *ngIf="tabulation==1"> </app-actual-offer>

                </div>

                <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
             
            <app-historiqueabonnement *ngIf="tabulation==2"> </app-historiqueabonnement>
    
                </div>
                <div class="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                    <app-list-consult *ngIf="tabulation==3"></app-list-consult>

                </div>
                <div class="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
                    <app-list-intervent *ngIf="tabulation==4"></app-list-intervent>
                </div>
                <div class="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                    <app-offre-emploi *ngIf="tabulation==5"></app-offre-emploi>
                </div>
                <div class="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
                    <app-permission-esn *ngIf="tabulation==6"></app-permission-esn>
                </div>
               
                <div class="tab-pane fade" id="kt_tab_pane_8" role="tabpanel">
                    <app-payment *ngIf="tabulation==8"></app-payment>
                </div>
                <div *ngIf="nameEsn" class="tab-pane fade active show" id="kt_tab_pane_10" role="tabpanel"
                    [formGroup]="companyDetailsForm">
                    <div class="header-page">
                        <div class="title">
                            <!-- <h4>ESN {{nameEsn}}</h4> -->
                        </div>
                        <div class="edit">
                            <a class="cursor-pointer" (click)="enableDisableMode()">
                                <svg *ngIf="disabledMode" width="26" height="26" viewBox="0 0 26 26" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_216_615)">
                                        <path
                                            d="M4 15.6558V5.59137C4 4.9982 4.23707 4.42933 4.65906 4.00989C5.08106 3.59046 5.6534 3.35482 6.25019 3.35482H13.1301L10.9165 5.59137H6.25019V15.6558H16.376V11.0206L18.6262 8.82039V15.6558C18.6262 16.249 18.3891 16.8179 17.9671 17.2373C17.5451 17.6567 16.9728 17.8924 16.376 17.8924H6.25019C5.6534 17.8924 5.08106 17.6567 4.65906 17.2373C4.23707 16.8179 4 16.249 4 15.6558ZM20.3138 0C19.8664 0.000391512 19.4374 0.177375 19.1212 0.492041L11.1246 8.56878C10.8786 8.8174 10.6929 9.11861 10.5818 9.44942L9.77173 11.8677C9.76161 11.9064 9.76183 11.947 9.77238 11.9855C9.78293 12.0241 9.80343 12.0592 9.83185 12.0875C9.86028 12.1157 9.89564 12.1361 9.93443 12.1466C9.97322 12.1571 10.0141 12.1573 10.053 12.1473L12.4944 11.3505C12.8273 11.2401 13.1304 11.0556 13.3805 10.8109L21.5064 2.86278C21.7422 2.6282 21.9027 2.32944 21.9677 2.00425C22.0326 1.67906 21.9992 1.34202 21.8716 1.03569C21.744 0.729368 21.5279 0.4675 21.2507 0.283164C20.9734 0.098828 20.6474 0.000291618 20.3138 0Z"
                                            fill="#265D91" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_216_615" x="0" y="0" width="26" height="25.8924"
                                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                result="effect1_dropShadow_216_615" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_216_615"
                                                result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                                <svg *ngIf="!disabledMode" xmlns="http://www.w3.org/2000/svg" width="26" height="26"
                                    viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                    <style>
                                        svg {
                                            fill: #306596
                                        }
                                    </style>
                                    <path
                                        d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" />
                                </svg>

                            </a>
                        </div>
                    </div>
                    <div class="card-body pt-0" *ngIf="!disabledMode">
                        <!--begin::Table-->
                        <div class="container">
                            <div class="row mb-5">
                                <div class="section_img">
                                    <div class="photo_img mb-5">
                                        <input id="fileInput" type="file" (change)="uploadFile($event)" hidden
                                        accept=".png, .jpg, .jpeg, .jfif,.avif" class="select-profile-picture" #fileInput />
                                        <!-- <img *ngIf="(!fileName||fileName=='') && companyDetailsForm.get('logo')?.value?.logo!='avatar.png'"
                                            src="{{ url+'/Company/file/gallery_company/'+ companyDetailsForm.get('logo')?.value }}"
                                            class="img-profile" alt="" />
                                        <img *ngIf=" fileName!=''" [src]="selectedImage" class="img-profile" alt="" />

                                        <img *ngIf="!selectedImage&&!companyDetailsForm.get('logo')?.value "
                                            class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt="" width="75px"> -->
                                            <img *ngIf="!selectedImage&&!companyDetailsForm.get('logo')?.value "
                                            class="img-profile"  src="{{ url+'/Company/file/gallery_company/'+ 'avatar.png' }}" alt="" width="75px"> 
                                        <img *ngIf="(!fileName||fileName=='') && companyDetailsForm.get('logo')?.value?.image_url!='avatar.png' &&companyDetailsForm.get('logo')?.value|| fileExtention"
                                            src="{{ url+'/Company/file/gallery_company/'+ companyDetailsForm.get('logo')?.value }}"
                                            class="img-profile" alt="" />
                                        <img *ngIf=" fileName!=''&& isImage() && !fileError" [src]="selectedImage" class="img-profile"
                                            alt="" />
                                        <!-- <img src="" *ngIf="fileName!=''&& !isImage()" class="img-profile" alt=""
                                         width="30px" href="" /> -->
                                        <!-- <img src="/assets/icons/icon-tooltip.png" *ngIf="fileName!=''&& !isImage()" class="img-profile" alt=""
                                        width="30px" href="" /> -->
                                        <!-- <span style="color:red" *ngIf="fileError"><small>{{fileError}} </small></span> -->
                                        <!-- <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}}
                                            </small></span> -->
                                    </div>

                                    <div class="btns">
                                        <button [disabled]="disabledMode" class="changer btn_photo cursor-pointer"
                                            (click)="fileInput.click()">Changer le logo</button>

                                        <!-- [disabled]="!isImageChanged"  -->
                                        <button [disabled]="companyDetailsForm.get('logo')?.value==='avatar.png'  && !isImageChanged" class="supprimer btn_photo cursor-pointer"
                                      
                                            (click)="clearImage()">

                                            <svg width="17" height="19" viewBox="0 0 17 19" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14.9851 4.17508H15.6809C15.8655 4.17508 16.0425 4.10177 16.173 3.97127C16.3035 3.84078 16.3768 3.66379 16.3768 3.47924C16.3768 3.29469 16.3035 3.11769 16.173 2.9872C16.0425 2.8567 15.8655 2.78339 15.6809 2.78339H12.2017V2.08754C12.2 1.5344 11.9796 1.00438 11.5884 0.613252C11.1973 0.222119 10.6673 0.001652 10.1141 0H5.93906C5.38591 0.001652 4.8559 0.222119 4.46477 0.613252C4.07363 1.00438 3.85317 1.5344 3.85151 2.08754V2.78339H0.695847C0.511297 2.78339 0.334305 2.8567 0.203809 2.9872C0.0733121 3.11769 0 3.29469 0 3.47924C0 3.66379 0.0733121 3.84078 0.203809 3.97127C0.334305 4.10177 0.511297 4.17508 0.695847 4.17508H1.06813V14.6128C1.06813 15.5355 1.43469 16.4205 2.08717 17.073C2.73965 17.7255 3.62461 18.092 4.54736 18.092H11.5058C12.4286 18.092 13.3135 17.7255 13.966 17.073C14.6185 16.4205 14.9851 15.5355 14.9851 14.6128V4.17508ZM6.6349 13.2211C6.6349 13.4056 6.56159 13.5826 6.43109 13.7131C6.3006 13.8436 6.12361 13.9169 5.93906 13.9169C5.75451 13.9169 5.57751 13.8436 5.44702 13.7131C5.31652 13.5826 5.24321 13.4056 5.24321 13.2211V7.65432C5.24321 7.46977 5.31652 7.29278 5.44702 7.16228C5.57751 7.03178 5.75451 6.95847 5.93906 6.95847C6.12361 6.95847 6.3006 7.03178 6.43109 7.16228C6.56159 7.29278 6.6349 7.46977 6.6349 7.65432V13.2211ZM10.81 13.2211C10.81 13.4056 10.7367 13.5826 10.6062 13.7131C10.4757 13.8436 10.2987 13.9169 10.1141 13.9169C9.92959 13.9169 9.7526 13.8436 9.6221 13.7131C9.4916 13.5826 9.41829 13.4056 9.41829 13.2211V7.65432C9.41829 7.46977 9.4916 7.29278 9.6221 7.16228C9.7526 7.03178 9.92959 6.95847 10.1141 6.95847C10.2987 6.95847 10.4757 7.03178 10.6062 7.16228C10.7367 7.29278 10.81 7.46977 10.81 7.65432V13.2211ZM10.81 2.78339H5.24321V2.08754C5.24321 1.90299 5.31652 1.726 5.44702 1.5955C5.57751 1.46501 5.75451 1.39169 5.93906 1.39169H10.1141C10.2987 1.39169 10.4757 1.46501 10.6062 1.5955C10.7367 1.726 10.81 1.90299 10.81 2.08754V2.78339Z"
                                                    fill="#EE6352" />
                                            </svg>
                                            Supprimer
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <label for="Client" class=" form-label">Nom Entreprise</label>
                                        <input type="text" class="form-control" placeholder="esn"
                                            formControlName="name" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <label for="Tèl" class=" form-label">Téléphone de contact</label>
                                        <form #f="ngForm" [formGroup]="phoneForm">
                                            <div class="wrapper">
                                                <ngx-intl-tel-input [cssClass]="'custom'" [disabled]="true"
                                                    [preferredCountries]="preferredCountries"
                                                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true" [searchCountryField]="[
                                                      SearchCountryField.Iso2,
                                                      SearchCountryField.Name
                                                    ]" [selectFirstCountry]="false"
                                                    [selectedCountryISO]="CountryISO.France" [maxLength]="10"
                                                    [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                    [numberFormat]="PhoneNumberFormat.National" name="phone"
                                                    formControlName="phone">
                                                </ngx-intl-tel-input>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <label for="Nom" class=" form-label">Nom Admin </label>
                                        <input type="text" [value]="responsable?.user?.first_name" [disabled]="true"
                                            class="form-control" placeholder="Nom Responsable" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <label for="Prénom" class=" form-label">Prénom Admin</label>
                                        <input type="text" [value]="responsable?.user?.last_name" [disabled]="true"
                                            class="form-control" placeholder="Prénom Responsable" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <label for="Email" class=" form-label">Mail Admin</label>
                                        <input *ngIf="dataEsn?.type==='3'" type="email"
                                            [value]="responsable?.user?.emailProClient" [disabled]="true"
                                            class="form-control" placeholder="Email Responsable" />
                                        <input *ngIf="dataEsn?.type==='2'  || dataEsn?.type==='4'" type="email"
                                            [value]="responsable?.user?.emailProESN" [disabled]="true"
                                            class="form-control" placeholder="Email Responsable" />
                                            <input *ngIf="dataEsn?.type==='1'" type="email"
                                            [value]="responsable?.user?.emailProESN" [disabled]="true"
                                            class="form-control" placeholder="Email Responsable" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <label for="Email" class=" form-label">Mail contact</label>
                                        <input type="email" [value]="email" [disabled]="true" class="form-control"
                                            placeholder="Email Responsable" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <label for="about" class=" form-label">À propos</label>
                                        <textarea type="about"   class="form-control"
                                            placeholder="À propos" formControlName="about"> </textarea>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <div class="position-relative me-md-2">
                                            <label for="pays" class=" form-label">Pays</label>
                                            <ng-select [disabled]="disabledMode" formControlName="country"
                                                (change)="onCountryChange($event)" [notFoundText]="'Aucun élément trouvé'" [items]="paysList" bindLabel="name"
                                                bindValue="name" [placeholder]="'Pays'" autoComplete>
                                            </ng-select>
                                            <app-feild-error
                                                [control]="companyDetailsForm.get('country')"></app-feild-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <div class="position-relative me-md-2">
                                            <label for="ville" class=" form-label">Ville</label>
                                            <ng-select formControlName="city" [items]="stateList" bindLabel="name"
                                                bindValue="name" [placeholder]="'Ville'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                                            </ng-select>
                                            <app-feild-error
                                                [control]="companyDetailsForm.get('city')"></app-feild-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <div class="position-relative me-md-2">
                                            <label for="ville" class=" form-label">Nombre d’intervenants</label>
                                            <input [disabled]="true" formControlName="number_of_intervenant"
                                                type="number" name="nbr" id="nbr" class="form-control"
                                                placeholder="Nombre d’intervenants">
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <div class="position-relative me-md-2">
                                            <label for="Siret" class=" form-label">Siret</label>
                                            <input [disabled]="disabledMode" formControlName="siret" type="text"
                                                name="nbr" id="Siret" class="form-control" placeholder="Siret">
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                      <div class="position-relative me-md-2">
                                        <label for="création" class="form-label">Année de création</label>
                                        <p-calendar 
                                        [style]="{'border': 'none','margin-top': '-6px'}"  
                                        view="year" 
                                        dateFormat="yy" 
                                        placeholder="Année" 
                                        inputId="yearpicker" 
                                        formControlName="year_of_creation"
                                        (ngModelChange)="selectDate(); calculateExperience(); isValidDate(companyDetailsForm.get('year_of_creation')?.value)" 
                                        (input)="onYearInput($event)" 
                                        (keydown)="limitYearLength($event); handleKeyDown($event)" 
                                        class="form-control">
                                    </p-calendar>
                                    
                                    <!-- Affiche le message d'erreur uniquement si le champ n'est pas vide et si une condition d'erreur est remplie -->
                                    <div class="desclaimer mt-4 mb-5" *ngIf="!datevalid && companyDetailsForm.get('year_of_creation')?.touched && companyDetailsForm.get('year_of_creation')?.value">
                                        <span class="error-message">Veuillez saisir une année à partir de 1900.</span>
                                    </div>
                                    
                                        <div class="desclaimer mt-4 mb-5" *ngIf="isChosenDateValid && companyDetailsForm.get('year_of_creation')?.value">
                                          <span class="error-message">L'année sélectionnée est dans le futur, il faut choisir une autre année.</span>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  </div>
                                  
                                  
                                  
                                  
                                  
                                  
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <div class="position-relative me-md-2">
                                            <label for="Adresse" class=" form-label">Adresse</label>
                                            <input [disabled]="disabledMode" formControlName="address" type="text"
                                                name="nbr" id="Adresse" class="form-control" placeholder="Adresse">
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <div class="position-relative me-md-2">
                                            <label for="ville" class=" form-label">Nombre des salariés</label>
                                            <ng-select formControlName="number_of_salaries" [disabled]="true"
                                                [items]="sizeCompany" bindLabel="name" bindValue="name"
                                                [placeholder]="'Nombre des salariés'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                                            </ng-select>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <div class="position-relative me-md-2">
                                            <label for="création" class=" form-label">Linkedin</label>
                                            <!-- <div class="combined-input-linkedin mb-5"> -->
                                                <!-- <input type="text" name="linkedin"
                                                    class="form-control linkedin1 input-placeholder"
                                                    placeholder="https://www.linkedin.com/company/" id="linkedin" disabled /> -->
                                                    <input type="text" name="linkedin" class="form-control form-control-solid" id="linkedin"
                                                    placeholder=" https://www.linkedin.com/company/"  formControlName="linkedin_link" (input)="setValidatorRequirements()" />
                                            <!-- </div> -->
                                            <app-feild-error class="mt-3"
                                                [control]="companyDetailsForm.get('linkedin_link')"></app-feild-error>
                                        </div>
                                    </div>
                                    <div class="mt-5">
                                        <span class="error mt-5"> <svg width="16" height="16" viewBox="0 0 16 16"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_379_198)">
                                                    <path
                                                        d="M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346629 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87104 0 6.41775 0 8C0.00229405 10.121 0.845886 12.1545 2.34568 13.6543C3.84547 15.1541 5.87897 15.9977 8 16ZM8 3.33334C8.19778 3.33334 8.39112 3.39199 8.55557 3.50187C8.72002 3.61175 8.84819 3.76793 8.92388 3.95065C8.99957 4.13338 9.01937 4.33445 8.98079 4.52843C8.9422 4.72241 8.84696 4.90059 8.70711 5.04044C8.56726 5.1803 8.38907 5.27554 8.19509 5.31412C8.00111 5.35271 7.80004 5.3329 7.61732 5.25722C7.43459 5.18153 7.27841 5.05336 7.16853 4.88891C7.05865 4.72446 7 4.53112 7 4.33334C7 4.06812 7.10536 3.81377 7.29289 3.62623C7.48043 3.43869 7.73478 3.33334 8 3.33334ZM7.33333 6.66667H8C8.35362 6.66667 8.69276 6.80715 8.94281 7.0572C9.19286 7.30724 9.33333 7.64638 9.33333 8V12C9.33333 12.1768 9.2631 12.3464 9.13807 12.4714C9.01305 12.5964 8.84348 12.6667 8.66667 12.6667C8.48986 12.6667 8.32029 12.5964 8.19526 12.4714C8.07024 12.3464 8 12.1768 8 12V8H7.33333C7.15652 8 6.98695 7.92977 6.86193 7.80474C6.73691 7.67972 6.66667 7.51015 6.66667 7.33334C6.66667 7.15653 6.73691 6.98696 6.86193 6.86193C6.98695 6.73691 7.15652 6.66667 7.33333 6.66667Z"
                                                        fill="#2b5d8f" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_379_198">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            Au moins l'un des champs Site web ou LinkedIn est requis.</span>
                                    </div>

                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-10">
                                        <div class="position-relative me-md-2">
                                            <label for="création" class=" form-label">Site web</label>
                                            <input [disabled]="disabledMode" formControlName="website_link" type="text"
                                                (input)="setValidatorRequirements()" name="nbr" id="création"
                                                class="form-control" placeholder="https://www.exemple.com ou www.exemple.com ou https://exemple.com">
                                            <app-feild-error class="mt-3"
                                                [control]="companyDetailsForm.get('website_link')"></app-feild-error>

                                        </div>
                                    </div>

                                </div>
                               
                                <div class="save-btn mt-5">
                                    <button
                                        [disabled]="disabledMode || isChosenDateValid || !datevalid || !companyDetailsForm.valid|| fileError!='' || fileExtention!=''"
                                        (click)="modifier()" class="btn save">Enregistrer</button>
                                </div>
                            </div>
                        </div>
                        <!--end::Table-->
                    </div>

                    <div class="row" *ngIf="disabledMode">
                        <div class="col-lg-4 no-padding">
                            <div class="info-gen">
                                <!-- <div class="edit">
                                <a class="cursor-pointer" (click)="enableDisableMode()">
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_216_615)">
                                            <path
                                                d="M4 15.6558V5.59137C4 4.9982 4.23707 4.42933 4.65906 4.00989C5.08106 3.59046 5.6534 3.35482 6.25019 3.35482H13.1301L10.9165 5.59137H6.25019V15.6558H16.376V11.0206L18.6262 8.82039V15.6558C18.6262 16.249 18.3891 16.8179 17.9671 17.2373C17.5451 17.6567 16.9728 17.8924 16.376 17.8924H6.25019C5.6534 17.8924 5.08106 17.6567 4.65906 17.2373C4.23707 16.8179 4 16.249 4 15.6558ZM20.3138 0C19.8664 0.000391512 19.4374 0.177375 19.1212 0.492041L11.1246 8.56878C10.8786 8.8174 10.6929 9.11861 10.5818 9.44942L9.77173 11.8677C9.76161 11.9064 9.76183 11.947 9.77238 11.9855C9.78293 12.0241 9.80343 12.0592 9.83185 12.0875C9.86028 12.1157 9.89564 12.1361 9.93443 12.1466C9.97322 12.1571 10.0141 12.1573 10.053 12.1473L12.4944 11.3505C12.8273 11.2401 13.1304 11.0556 13.3805 10.8109L21.5064 2.86278C21.7422 2.6282 21.9027 2.32944 21.9677 2.00425C22.0326 1.67906 21.9992 1.34202 21.8716 1.03569C21.744 0.729368 21.5279 0.4675 21.2507 0.283164C20.9734 0.098828 20.6474 0.000291618 20.3138 0Z"
                                                fill="#265D91" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_216_615" x="0" y="0" width="26" height="25.8924"
                                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_216_615" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_216_615"
                                                    result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                </a>
                            </div> -->
                                <div class="text-center title mb-5">
                                    <div class="contact" id="title-bloc">Informations générales</div>
                                  
                                </div>
                                <div class="row mb-5">
                                    <div class="section_img">
                                        <!-- <div class="photo_img "> -->
                                        <!-- <input id="fileInput" type="file" (change)="uploadFile($event)" hidden
                                                accept=".png, .jpg, .jpeg" class="select-profile-picture" #fileInput />
                                            <img *ngIf="(!fileName||fileName=='') && companyDetailsForm.get('logo')?.value?.logo!='avatar.png'"
                                                src="{{ url+'/Company/file/gallery_company/'+ companyDetailsForm.get('logo')?.value }}"
                                                class="img-profile" alt="" />
                                            <img *ngIf=" fileName!=''" [src]="selectedImage" class="img-profile"
                                                alt="" />

                                            <img *ngIf="!selectedImage&&!companyDetailsForm.get('logo')?.value "
                                                class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt="" width="75px"> -->
                                        <!-- <div class="tools">
                                                <div class="dropdown">
                                                    <button
                                                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                        type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                                            <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <button [disabled]="disabledMode" class="dropdown-item changer btn_photo cursor-pointer"
                                                            (click)="fileInput.click()">Changer logo</button>
                                                            <button [disabled]="disabledMode" class="dropdown-item supprimer btn_photo cursor-pointer"
                                                            (click)="this.fileName = ''">
                
                                                            Supprimer
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> -->
                                        <!-- </div> -->
                                        <div class="photo_img ">
                                            <input id="fileInput" type="file" (change)="uploadFile($event)" hidden
                                            accept=".png, .jpg, .jpeg, .jfif,.avif" class="select-profile-picture" #fileInput />
                                            <!-- <img *ngIf="(!fileName||fileName=='') && companyDetailsForm.get('logo')?.value?.logo!='avatar.png'"
                                                src="{{ url+'/Company/file/gallery_company/'+ companyDetailsForm.get('logo')?.value }}"
                                                class="img-profile" alt="" />
                                            <img *ngIf=" fileName!=''" [src]="selectedImage" class="img-profile" alt="" />
    -->
                                            <img *ngIf="!selectedImage&&!companyDetailsForm.get('logo')?.value "
                                            class="img-profile"  src="{{ url+'/Company/file/gallery_company/'+ 'avatar.png' }}" alt="" width="75px"> 
                                            <img *ngIf="(!fileName||fileName=='') && companyDetailsForm.get('logo')?.value?.image_url!='avatar.png'  && companyDetailsForm.get('logo')?.value!==null"
                                                src="{{ url+'/Company/file/gallery_company/'+ companyDetailsForm.get('logo')?.value }}"
                                                class="img-profile" alt="" />
                                            <img *ngIf=" fileName!=''&& isImage()" [src]="selectedImage"
                                                class="img-profile" alt="" />
                                            <!-- <img src="" *ngIf="fileName!=''&& !isImage()" class="img-profile" alt=""
                                             width="30px" href="" /> -->
                                            <!-- <img src="/assets/icons/icon-tooltip.png" *ngIf="fileName!=''&& !isImage()" class="img-profile" alt=""
                                            width="30px" href="" /> -->

                                        </div>

                                    </div>
                                </div>
                                <div class="details-esn">
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg width="20" height="20" viewBox="0 0 41 40" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_2093_1775)">
                                                    <path
                                                        d="M34.7507 13.1212C34.7507 12.8561 34.6444 12.6019 34.4551 12.4144C34.2659 12.227 34.0092 12.1216 33.7415 12.1216H23.256C22.9883 12.1216 22.7316 12.227 22.5424 12.4144C22.3531 12.6019 22.2468 12.8561 22.2468 13.1212V28.4948H20.4336V30.494H36.2948V28.4948H34.7507V13.1212ZM24.2652 14.1208H32.7323V28.4948H24.2652V14.1208Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 15.137H24.8398V18.1558H26.8582V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 15.137H27.5293V18.1558H29.5477V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 15.137H30.2148V18.1558H32.2332V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 19.7985H24.8398V22.8173H26.8582V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 19.7985H27.5293V22.8173H29.5477V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 19.7985H30.2148V22.8173H32.2332V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 24.4565H24.8398V27.4752H26.8582V24.4565Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 24.4565H27.5293V27.4752H29.5477V24.4565Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 24.4565H30.2148V27.4752H32.2332V24.4565Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M18.3171 2.50569C18.3171 2.24058 18.2108 1.98633 18.0215 1.79888C17.8323 1.61142 17.5756 1.5061 17.3079 1.5061H6.82238C6.55473 1.5061 6.29804 1.61142 6.10877 1.79888C5.91951 1.98633 5.81319 2.24058 5.81319 2.50569V17.8793H4V19.8785H19.8612V17.8793H18.3171V2.50569ZM7.83158 3.50527H16.2987V17.8793H7.83158V3.50527Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 4.52478H8.40625V7.54352H10.4246V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 4.52478H11.0977V7.54352H13.116V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 4.52478H13.7852V7.54352H15.8035V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 9.18292H8.40625V12.2017H10.4246V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 9.18292H11.0977V12.2017H13.116V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 9.18292H13.7852V12.2017H15.8035V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 13.8409H8.40625V16.8596H10.4246V13.8409Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 13.8409H11.0977V16.8596H13.116V13.8409Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 13.8409H13.7852V16.8596H15.8035V13.8409Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M32.0886 8.74303H34.107V3.12537H27.4362V0L21.3945 4.0783L27.4497 8.12662V5.12787H32.1021L32.0886 8.74303ZM24.9873 4.0783L25.4111 3.79176V4.36152L24.9873 4.0783Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M8.20394 23.257H6.18555V28.8747H12.8563V32L18.898 27.9217L12.8429 23.8734V26.8722H8.19048L8.20394 23.257ZM15.3053 27.9217L14.8814 28.2083V27.6452L15.3053 27.9217Z"
                                                        fill="#265D91" />
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d_2093_1775" x="0" y="0" width="40.2949"
                                                        height="40.0001" filterUnits="userSpaceOnUse"
                                                        color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha" />
                                                        <feOffset dy="4" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_2093_1775" />
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                            in2="effect1_dropShadow_2093_1775" result="shape" />
                                                    </filter>
                                                </defs>
                                            </svg>
                                            Nom Entreprise
                                        </div>
                                        <div class="text-gray-600"> {{nameEsn ? nameEsn:'---'}}</div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg width="20" height="20" viewBox="0 0 22 15" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M2.22768 0.317383H19.773C20.3839 0.317383 20.941 0.567059 21.3459 0.97049C21.7501 1.37392 22 1.92829 22 2.53696V12.2962C22 12.907 21.7494 13.462 21.3459 13.8641L21.3247 13.8838C20.9219 14.2746 20.374 14.5158 19.7723 14.5158H2.22697C1.61537 14.5158 1.05827 14.2661 0.654783 13.8634C0.250587 13.462 0 12.907 0 12.2962V2.53696C0 1.92829 0.250587 1.37322 0.655491 0.969785C1.06039 0.567059 1.61678 0.318088 2.22768 0.318088V0.317383ZM2.67718 2.19489L2.29847 2.78946L11.0004 8.30419L19.7015 2.78946L19.3228 2.19489L10.9996 7.46912L2.67647 2.19489H2.67718Z"
                                                    fill="#265D91" />
                                            </svg>
                                            Mail de contact
                                        </div>
                                        <div class="text-gray-600">{{dataEsn?.ContactEmail ?
                                            dataEsn?.ContactEmail:'---'}}</div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg width="20" height="20" viewBox="0 0 16 19" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M15.4765 15.2659C15.0935 14.106 14.4041 13.1154 13.4296 12.3235C13.0541 12.0166 12.6813 11.8364 12.2907 11.773C12.139 11.7484 11.9833 11.742 11.8277 11.7532C11.3119 11.793 10.7972 11.947 10.2547 12.2246C10.0838 12.3131 9.8872 12.3393 9.67121 12.3044C9.40779 12.2616 9.13791 12.1253 8.96659 11.9486C7.72269 10.6648 6.71735 9.27018 5.89276 7.68598C5.73111 7.3741 5.66457 6.74322 6.03852 6.37848C6.47511 5.95353 6.78481 5.51525 6.9863 5.03881C7.20252 4.52538 7.1917 3.97034 6.95291 3.3436C6.50942 2.16826 5.78707 1.20206 4.80682 0.470746C4.48928 0.233563 4.14941 0.0841746 3.79549 0.0264877C3.20463 -0.0695805 2.60962 0.0968151 2.07517 0.507059C1.58401 0.883517 1.13729 1.29422 0.617576 1.79479C0.141384 2.25306 0.0755277 2.86096 0.0170399 3.39669L0.015428 3.40795C-0.0460533 4.09031 0.0757578 4.92873 0.389151 5.97169C0.918995 7.73654 1.79654 9.47312 3.07337 11.2812C3.33565 11.653 4.3852 13.1085 4.65531 13.4749C5.96806 15.2572 7.33953 16.6399 8.84778 17.7007C9.73937 18.3284 10.4972 18.7102 11.1647 18.8683L11.176 18.8703C11.3137 18.9002 11.4613 18.9317 11.6059 18.9551C11.9626 19.0133 12.4305 19.0491 12.8887 18.8108C13.5296 18.4776 14.0617 18.185 14.5736 17.8405C15.4735 17.2372 15.8106 16.2744 15.4765 15.2659ZM3.82934 3.49391C3.14936 2.55092 2.94995 1.5337 3.384 1.22205C3.81806 0.910178 4.72116 1.42201 5.40114 2.36499C6.08112 3.30798 6.28053 4.3252 5.84671 4.63684C5.41242 4.94872 4.50932 4.43689 3.82934 3.49391ZM14.3991 16.5771C13.965 16.889 13.0619 16.3772 12.3819 15.4342C11.7019 14.4912 11.5025 13.474 11.9363 13.1623C12.3702 12.8507 13.2735 13.3623 13.9535 14.3053C14.6337 15.2482 14.8331 16.2655 14.3991 16.5771Z"
                                                    fill="#265D91" />
                                            </svg>
                                            Téléphone de contact
                                        </div>
                                        <div class="text-gray-600">{{dataEsn?.ContactPhone ?
                                            dataEsn?.ContactPhone:'---'}}</div>
                                    </div>
                                    <div class="flex-space " *ngIf="companyDetailsForm.get('website_link')?.value">
                                        <div class="contact-info-name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                viewBox="0 0 23 21" fill="none">
                                                <path
                                                    d="M11.597 20.6646C5.28805 20.6646 0.195312 16.0811 0.195312 10.4031C0.195312 4.72506 5.28805 0.141602 11.597 0.141602C17.9059 0.141602 22.9986 4.72506 22.9986 10.4031C22.9986 16.0811 17.9059 20.6646 11.597 20.6646ZM11.597 1.5098C6.12417 1.5098 1.71553 5.47757 1.71553 10.4031C1.71553 15.3286 6.12417 19.2964 11.597 19.2964C17.0697 19.2964 21.4784 15.3286 21.4784 10.4031C21.4784 5.47757 17.0697 1.5098 11.597 1.5098Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M17.5254 12.9342C17.6774 12.1133 17.6774 11.2924 17.6774 10.4031C17.6774 9.51375 17.6014 8.69283 17.5254 7.87192C17.3733 6.77736 17.0693 5.75121 16.7652 4.79347C15.6251 1.98867 13.8008 0.141602 11.5965 0.141602C9.01213 0.141602 6.88382 2.67277 5.97169 6.3669C5.89568 6.70895 5.81967 6.98259 5.74366 7.32464C5.59164 8.28238 5.51562 9.30852 5.51562 10.4031C5.51562 11.2924 5.59164 12.1133 5.66765 12.9342C5.81967 14.0288 6.12371 15.055 6.42776 16.0127C7.49191 18.8175 9.39218 20.6646 11.5205 20.6646C13.6488 20.6646 15.5491 18.8175 16.6132 16.0127C16.0812 16.1495 15.4731 16.2179 14.865 16.3547C14.1049 18.2018 12.8127 19.2964 11.5965 19.2964C10.3803 19.2964 9.08814 18.2018 8.25202 16.3547C7.87197 15.5338 7.56792 14.5077 7.33989 13.4131C7.11186 12.4554 7.03584 11.4976 7.03584 10.4031C7.03584 9.30852 7.11186 8.28238 7.33989 7.39305C7.4159 7.051 7.49191 6.77736 7.56792 6.43531V6.3669C7.64393 6.02485 7.71994 5.75121 7.87197 5.47757C7.94798 5.27234 8.02399 5.06711 8.1 4.93029C8.1 4.93029 8.1 4.93029 8.1 4.86188L8.25202 4.58824C8.25202 4.58824 8.25202 4.51983 8.32803 4.45142C9.08814 2.60436 10.3803 1.5098 11.5965 1.5098C12.8127 1.5098 14.1049 2.60436 14.941 4.45142C15.321 5.27234 15.6251 6.29849 15.8531 7.39305C16.0812 8.35079 16.1572 9.30852 16.1572 10.4031C16.1572 11.4976 16.0812 12.4554 15.9291 13.4131C16.4612 13.2763 16.9933 13.1395 17.5254 12.9342Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M17.8299 5.82011C16.8417 5.47807 15.6255 5.27284 14.4094 5.13602C13.4972 4.9992 12.5851 4.93079 11.597 4.93079C10.6088 4.93079 9.69668 4.9992 8.78455 5.06761C8.63253 5.47806 8.4045 5.95693 8.25247 6.50421C9.31663 6.36739 10.3808 6.29898 11.597 6.29898C12.8131 6.29898 13.8773 6.36739 14.9414 6.50421C16.1576 6.70944 17.2978 6.98308 18.2099 7.32513C20.2622 8.14605 21.4784 9.24061 21.4784 10.3352C21.4784 11.4297 20.2622 12.5927 18.2099 13.3452C17.2978 13.6872 16.1576 13.9609 14.9414 14.1661C13.8773 14.4398 12.8131 14.5082 11.597 14.5082C10.3808 14.5082 9.31663 14.4398 8.25247 14.2345C7.0363 14.0977 5.89613 13.8241 4.984 13.4136C2.93171 12.6611 1.71553 11.4981 1.71553 10.4036C1.71553 9.30902 2.93171 8.14605 4.984 7.39354C5.06001 6.84626 5.21204 6.29898 5.36406 5.82011C2.24761 6.77785 0.195312 8.41969 0.195312 10.4036C0.195312 12.3875 2.24761 14.0293 5.36406 14.987C6.3522 15.3291 7.56838 15.5343 8.78455 15.6711C9.69668 15.808 10.6088 15.808 11.597 15.808C12.5851 15.808 13.4972 15.7395 14.4094 15.6711C15.6255 15.5343 16.8417 15.2607 17.8299 14.987C20.9463 14.0293 22.9986 12.3191 22.9986 10.4036C22.9986 8.4881 20.9463 6.77785 17.8299 5.82011Z"
                                                    fill="#265D91" />
                                            </svg> Site web
                                        </div>
                                        <div class="text-gray-600">
                                            <a [href]="companyDetailsForm.get('website_link')?.value" target="_blank">
                                                <i class="fa-solid fa-arrow-up-right-from-square"
                                                    style="font-size: 20px;color: #265D91;"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg width="20" height="20" viewBox="0 0 17 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14.2669 17.8389H2.84767C1.3399 17.8389 0.113281 16.5429 0.113281 14.9497V2.88923C0.113281 1.296 1.3399 0 2.84767 0H14.2669C15.7742 0 17.0008 1.296 17.0008 2.88923V14.9502C17.0008 16.5434 15.7742 17.8389 14.2669 17.8389ZM5.81961 14.7475H5.8231V7.05277H3.5621V14.7475H5.81918H5.81961ZM4.69108 6.00185C4.86298 6.00209 5.03325 5.96642 5.19211 5.89687C5.35098 5.82733 5.49532 5.72528 5.61688 5.59657C5.73844 5.46787 5.83482 5.31503 5.9005 5.14682C5.96618 4.97861 5.99987 4.79833 5.99964 4.61631C5.99895 4.24906 5.86086 3.89707 5.61561 3.63739C5.37035 3.37771 5.03792 3.2315 4.69108 3.23077C4.34406 3.23089 4.01129 3.37691 3.76591 3.63672C3.52053 3.89653 3.38263 4.24888 3.38251 4.61631C3.38263 4.98374 3.52053 5.33608 3.76591 5.5959C4.01129 5.85571 4.34406 6.00172 4.69108 6.00185ZM14.2799 14.748V10.5295C14.2799 8.45677 13.8549 6.86215 11.5708 6.86215C10.4728 6.86215 9.73572 7.49908 9.43277 8.10369H9.40226V7.05277H7.23715V14.7475H9.49423V10.9398C9.49423 9.93554 9.67425 8.964 10.8499 8.964C12.0059 8.964 12.0229 10.1123 12.0229 11.0045V14.7475H14.2799V14.748Z"
                                                    fill="#265D91" />
                                            </svg>

                                            Profil LinkedIn
                                        </div>
                                        <div class="text-gray-600" style="text-align: right;">
                                            <a target="_blank" [href]="companyDetailsForm.get('linkedin_link')?.value"
                                                *ngIf="companyDetailsForm.get('linkedin_link')?.value">
                                                <svg width="20" height="20" viewBox="0 0 30 30" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_440_1123)">
                                                        <path
                                                            d="M27.1437 0.754012C22.7604 0.741712 6.5931 0.762412 2.8563 0.754012C1.6935 0.754012 0.75 1.66871 0.75 2.79521V27.2095C0.75 28.3363 1.6935 29.2501 2.8563 29.2501H27.1437C28.3071 29.2501 29.25 28.3363 29.25 27.2092V2.79521C29.25 1.66871 28.3071 0.754012 27.1437 0.754012Z"
                                                            fill="#0077B5" />
                                                        <path
                                                            d="M16.0356 24.6015V17.4378C15.9939 16.1214 16.7808 14.8929 18.3642 14.8377C19.9071 14.8377 20.5245 16.014 20.5245 17.7387V24.6012H24.7887V17.2455C24.7887 13.305 22.6851 11.4714 19.8792 11.4714C17.5788 11.4714 16.5684 12.7569 16.0071 13.6326L16.0356 11.7726H11.7714C11.8269 12.9762 11.7711 24.6015 11.7711 24.6015H16.0356ZM9.41074 19.0383V11.7723H5.14594V24.6012H9.41104C9.41014 23.2542 9.41254 20.3664 9.41074 19.0383ZM7.27924 10.0212C8.76604 10.0212 9.69184 9.03598 9.69184 7.80508C9.67954 4.83598 4.89064 4.86898 4.89454 7.80478C4.89124 9.04528 5.84254 10.0365 7.27924 10.0212Z"
                                                            fill="#F1F1F1" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_440_1123">
                                                            <rect width="30" height="30" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                            <a class="link-linkedin" tooltip="Linkedin"
                                                *ngIf="!companyDetailsForm.get('linkedin_link')?.value">---</a>
                                        </div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                viewBox="0 0 41 40" fill="none">
                                                <g filter="url(#filter0_d_2093_1775)">
                                                    <path
                                                        d="M34.7507 13.1212C34.7507 12.8561 34.6444 12.6019 34.4551 12.4144C34.2659 12.227 34.0092 12.1216 33.7415 12.1216H23.256C22.9883 12.1216 22.7316 12.227 22.5424 12.4144C22.3531 12.6019 22.2468 12.8561 22.2468 13.1212V28.4948H20.4336V30.494H36.2948V28.4948H34.7507V13.1212ZM24.2652 14.1208H32.7323V28.4948H24.2652V14.1208Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 15.137H24.8398V18.1558H26.8582V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 15.137H27.5293V18.1558H29.5477V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 15.137H30.2148V18.1558H32.2332V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 19.7985H24.8398V22.8173H26.8582V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 19.7985H27.5293V22.8173H29.5477V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 19.7985H30.2148V22.8173H32.2332V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 24.4565H24.8398V27.4752H26.8582V24.4565Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 24.4565H27.5293V27.4752H29.5477V24.4565Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 24.4565H30.2148V27.4752H32.2332V24.4565Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M18.3171 2.50569C18.3171 2.24058 18.2108 1.98633 18.0215 1.79888C17.8323 1.61142 17.5756 1.5061 17.3079 1.5061H6.82238C6.55473 1.5061 6.29804 1.61142 6.10877 1.79888C5.91951 1.98633 5.81319 2.24058 5.81319 2.50569V17.8793H4V19.8785H19.8612V17.8793H18.3171V2.50569ZM7.83158 3.50527H16.2987V17.8793H7.83158V3.50527Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 4.52478H8.40625V7.54352H10.4246V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 4.52478H11.0977V7.54352H13.116V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 4.52478H13.7852V7.54352H15.8035V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 9.18292H8.40625V12.2017H10.4246V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 9.18292H11.0977V12.2017H13.116V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 9.18292H13.7852V12.2017H15.8035V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 13.8409H8.40625V16.8596H10.4246V13.8409Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 13.8409H11.0977V16.8596H13.116V13.8409Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 13.8409H13.7852V16.8596H15.8035V13.8409Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M32.0886 8.74303H34.107V3.12537H27.4362V0L21.3945 4.0783L27.4497 8.12662V5.12787H32.1021L32.0886 8.74303ZM24.9873 4.0783L25.4111 3.79176V4.36152L24.9873 4.0783Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M8.20394 23.257H6.18555V28.8747H12.8563V32L18.898 27.9217L12.8429 23.8734V26.8722H8.19048L8.20394 23.257ZM15.3053 27.9217L14.8814 28.2083V27.6452L15.3053 27.9217Z"
                                                        fill="#265D91" />
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d_2093_1775" x="0" y="0" width="40.2949"
                                                        height="40.0001" filterUnits="userSpaceOnUse"
                                                        color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha" />
                                                        <feOffset dy="4" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_2093_1775" />
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                            in2="effect1_dropShadow_2093_1775" result="shape" />
                                                    </filter>
                                                </defs>
                                            </svg> Type
                                        </div>
                                        <div class="text-gray-600">
                                            <span  *ngIf="dataEsn.type!=='1'" class="wrapping" [ngClass]="dataEsn.type=='2'?
                                            'esn-type':dataEsn.type=='3'?'client-type':'centre-type'">
                                                                                {{dataEsn.type=='2'?
                                                                                'ESN':dataEsn.type=='3'?'Client Final':'Cabinet de Recrutement'}}
                                                                            </span>
                                                                            <span *ngIf="dataEsn.type=='1'">---</span> </div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg width="20" height="20" viewBox="0 0 22 25" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11 4.48615V0.996923H1V0.498462C1 0.224308 0.775 0 0.5 0C0.225 0 0 0.224308 0 0.498462V24.4246C0 24.6988 0.225 24.9231 0.5 24.9231C0.775 24.9231 1 24.6988 1 24.4246V10.9662H5.5H11V4.48615ZM3.855 6.83391C3.76 6.92862 3.635 6.97846 3.5 6.97846C3.365 6.97846 3.24 6.92363 3.145 6.83391C3.05 6.7392 3 6.61458 3 6.48C3 6.34542 3.055 6.2208 3.145 6.12609C3.235 6.03138 3.365 5.98154 3.5 5.98154C3.635 5.98154 3.76 6.03637 3.855 6.12609C3.95 6.21582 4 6.34542 4 6.48C4 6.61458 3.945 6.7392 3.855 6.83391ZM3.855 3.84314C3.76 3.93785 3.635 3.98769 3.5 3.98769C3.365 3.98769 3.24 3.93286 3.145 3.84314C3.05 3.75342 3 3.61883 3 3.48923C3 3.35963 3.055 3.23003 3.145 3.13532C3.235 3.04062 3.365 2.99077 3.5 2.99077C3.635 2.99077 3.76 3.0456 3.855 3.13532C3.95 3.22505 4 3.35465 4 3.48923C4 3.62382 3.945 3.74843 3.855 3.84314Z"
                                                    fill="#265D91" />
                                                <path d="M6 11.963V14.5201L9.85 11.963H6Z" fill="#265D91" />
                                                <path
                                                    d="M12.0004 4.98469V11.4647V11.4697C12.0004 11.4846 12.0004 11.4946 12.0004 11.5096C12.0004 11.5145 12.0004 11.5145 12.0004 11.5145C12.0004 11.5295 11.9954 11.5444 11.9954 11.5594C11.9954 11.5644 11.9904 11.5694 11.9904 11.5744C11.9904 11.5843 11.9854 11.5893 11.9854 11.5993C11.9854 11.6043 11.9804 11.6142 11.9804 11.6192C11.9754 11.6292 11.9754 11.6342 11.9704 11.6441C11.9654 11.6491 11.9654 11.6591 11.9604 11.6641C11.9554 11.674 11.9504 11.679 11.9504 11.689C11.9504 11.694 11.9454 11.699 11.9454 11.704C11.9404 11.7189 11.9304 11.7289 11.9254 11.7388C11.9254 11.7438 11.9204 11.7438 11.9154 11.7488C11.9104 11.7588 11.9054 11.7638 11.8954 11.7737C11.8904 11.7787 11.8904 11.7837 11.8854 11.7837C11.8804 11.7937 11.8704 11.7987 11.8654 11.8036C11.8604 11.8086 11.8604 11.8086 11.8554 11.8136C11.8504 11.8186 11.8404 11.8286 11.8354 11.8336C11.8304 11.8385 11.8254 11.8385 11.8254 11.8435C11.8154 11.8485 11.8104 11.8585 11.8004 11.8635C11.7954 11.8684 11.7954 11.8684 11.7904 11.8734L7.15039 14.9539H22.0004V4.98469H12.0004ZM19.8554 12.8155C19.7604 12.9102 19.6354 12.9601 19.5004 12.9601C19.3654 12.9601 19.2404 12.9052 19.1454 12.8155C19.0504 12.7208 19.0004 12.5962 19.0004 12.4616C19.0004 12.327 19.0554 12.2024 19.1454 12.1077C19.2404 12.013 19.3654 11.9632 19.5004 11.9632C19.6354 11.9632 19.7604 12.018 19.8554 12.1077C19.9504 12.2024 20.0004 12.327 20.0004 12.4616C20.0004 12.5962 19.9454 12.7208 19.8554 12.8155ZM19.8554 9.82475C19.7604 9.91946 19.6354 9.96931 19.5004 9.96931C19.3654 9.96931 19.2404 9.91448 19.1454 9.82475C19.0504 9.73503 19.0004 9.60543 19.0004 9.47085C19.0004 9.33626 19.0554 9.21165 19.1454 9.11694C19.2404 9.02223 19.3654 8.97238 19.5004 8.97238C19.6354 8.97238 19.7604 9.02721 19.8554 9.11694C19.9504 9.21165 20.0004 9.33626 20.0004 9.47085C20.0004 9.60543 19.9454 9.73005 19.8554 9.82475Z"
                                                    fill="#265D91" />
                                            </svg>
                                            Pays
                                        </div>
                                        <div class="text-gray-600"> {{dataEsn?.country && dataEsn?.country !== 'null' ?
                                            dataEsn?.country:'---'}}
                                        </div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg width="20" height="20" viewBox="0 0 22 25" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11 4.48615V0.996923H1V0.498462C1 0.224308 0.775 0 0.5 0C0.225 0 0 0.224308 0 0.498462V24.4246C0 24.6988 0.225 24.9231 0.5 24.9231C0.775 24.9231 1 24.6988 1 24.4246V10.9662H5.5H11V4.48615ZM3.855 6.83391C3.76 6.92862 3.635 6.97846 3.5 6.97846C3.365 6.97846 3.24 6.92363 3.145 6.83391C3.05 6.7392 3 6.61458 3 6.48C3 6.34542 3.055 6.2208 3.145 6.12609C3.235 6.03138 3.365 5.98154 3.5 5.98154C3.635 5.98154 3.76 6.03637 3.855 6.12609C3.95 6.21582 4 6.34542 4 6.48C4 6.61458 3.945 6.7392 3.855 6.83391ZM3.855 3.84314C3.76 3.93785 3.635 3.98769 3.5 3.98769C3.365 3.98769 3.24 3.93286 3.145 3.84314C3.05 3.75342 3 3.61883 3 3.48923C3 3.35963 3.055 3.23003 3.145 3.13532C3.235 3.04062 3.365 2.99077 3.5 2.99077C3.635 2.99077 3.76 3.0456 3.855 3.13532C3.95 3.22505 4 3.35465 4 3.48923C4 3.62382 3.945 3.74843 3.855 3.84314Z"
                                                    fill="#265D91" />
                                                <path d="M6 11.963V14.5201L9.85 11.963H6Z" fill="#265D91" />
                                                <path
                                                    d="M12.0004 4.98469V11.4647V11.4697C12.0004 11.4846 12.0004 11.4946 12.0004 11.5096C12.0004 11.5145 12.0004 11.5145 12.0004 11.5145C12.0004 11.5295 11.9954 11.5444 11.9954 11.5594C11.9954 11.5644 11.9904 11.5694 11.9904 11.5744C11.9904 11.5843 11.9854 11.5893 11.9854 11.5993C11.9854 11.6043 11.9804 11.6142 11.9804 11.6192C11.9754 11.6292 11.9754 11.6342 11.9704 11.6441C11.9654 11.6491 11.9654 11.6591 11.9604 11.6641C11.9554 11.674 11.9504 11.679 11.9504 11.689C11.9504 11.694 11.9454 11.699 11.9454 11.704C11.9404 11.7189 11.9304 11.7289 11.9254 11.7388C11.9254 11.7438 11.9204 11.7438 11.9154 11.7488C11.9104 11.7588 11.9054 11.7638 11.8954 11.7737C11.8904 11.7787 11.8904 11.7837 11.8854 11.7837C11.8804 11.7937 11.8704 11.7987 11.8654 11.8036C11.8604 11.8086 11.8604 11.8086 11.8554 11.8136C11.8504 11.8186 11.8404 11.8286 11.8354 11.8336C11.8304 11.8385 11.8254 11.8385 11.8254 11.8435C11.8154 11.8485 11.8104 11.8585 11.8004 11.8635C11.7954 11.8684 11.7954 11.8684 11.7904 11.8734L7.15039 14.9539H22.0004V4.98469H12.0004ZM19.8554 12.8155C19.7604 12.9102 19.6354 12.9601 19.5004 12.9601C19.3654 12.9601 19.2404 12.9052 19.1454 12.8155C19.0504 12.7208 19.0004 12.5962 19.0004 12.4616C19.0004 12.327 19.0554 12.2024 19.1454 12.1077C19.2404 12.013 19.3654 11.9632 19.5004 11.9632C19.6354 11.9632 19.7604 12.018 19.8554 12.1077C19.9504 12.2024 20.0004 12.327 20.0004 12.4616C20.0004 12.5962 19.9454 12.7208 19.8554 12.8155ZM19.8554 9.82475C19.7604 9.91946 19.6354 9.96931 19.5004 9.96931C19.3654 9.96931 19.2404 9.91448 19.1454 9.82475C19.0504 9.73503 19.0004 9.60543 19.0004 9.47085C19.0004 9.33626 19.0554 9.21165 19.1454 9.11694C19.2404 9.02223 19.3654 8.97238 19.5004 8.97238C19.6354 8.97238 19.7604 9.02721 19.8554 9.11694C19.9504 9.21165 20.0004 9.33626 20.0004 9.47085C20.0004 9.60543 19.9454 9.73005 19.8554 9.82475Z"
                                                    fill="#265D91" />
                                            </svg> Ville
                                        </div>
                                        <div class="text-gray-600"> {{dataEsn?.city && dataEsn?.city !== 'null' ?
                                            dataEsn?.city:'---'}}
                                        </div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                viewBox="0 0 62 96" fill="none">
                                                <path
                                                    d="M14.7032 65.4218L18.7388 71.6769C12.6854 73.2912 7.74178 75.9143 7.74178 79.3446C7.74178 85.0954 21.5637 88.7274 31.1483 88.7274C40.7329 88.7274 54.5549 85.0954 54.5549 79.3446C54.5549 75.9143 49.6112 73.1903 43.5578 71.6769L47.5934 65.4218C56.1691 68.2467 61.6172 73.3921 61.6172 79.4455C61.6172 88.8283 48.5015 95.8906 31.1483 95.8906C13.7952 95.8906 0.679462 88.8283 0.679462 79.4455C0.679462 73.3921 6.12753 68.2467 14.7032 65.4218Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M34.4792 75.3089C32.865 77.7303 29.3338 77.7303 27.7196 75.3089L6.5326 42.4187C4.01035 38.0804 2.59789 33.0359 2.79967 27.5878C3.20323 13.0596 15.0074 0.851918 29.5356 0.0447952C45.8798 -0.863217 59.5 12.1516 59.5 28.2941C59.5 33.4395 58.0875 38.2822 55.7671 42.4187L34.4792 75.3089ZM31.1498 14.4721C23.4822 14.4721 17.3279 20.7273 17.3279 28.2941C17.3279 35.9617 23.4822 42.116 31.1498 42.116C38.8175 42.116 44.9718 35.8608 44.9718 28.2941C44.9718 20.6264 38.8175 14.4721 31.1498 14.4721Z"
                                                    fill="#265D91" />
                                            </svg> Adresse
                                        </div>
                                        <div class="text-gray-600"> {{dataEsn?.address && dataEsn?.address !== 'null' ?
                                            dataEsn?.address:'---' }}
                                        </div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                viewBox="0 0 41 40" fill="none">
                                                <g filter="url(#filter0_d_2093_1775)">
                                                    <path
                                                        d="M34.7507 13.1212C34.7507 12.8561 34.6444 12.6019 34.4551 12.4144C34.2659 12.227 34.0092 12.1216 33.7415 12.1216H23.256C22.9883 12.1216 22.7316 12.227 22.5424 12.4144C22.3531 12.6019 22.2468 12.8561 22.2468 13.1212V28.4948H20.4336V30.494H36.2948V28.4948H34.7507V13.1212ZM24.2652 14.1208H32.7323V28.4948H24.2652V14.1208Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 15.137H24.8398V18.1558H26.8582V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 15.137H27.5293V18.1558H29.5477V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 15.137H30.2148V18.1558H32.2332V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 19.7985H24.8398V22.8173H26.8582V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 19.7985H27.5293V22.8173H29.5477V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 19.7985H30.2148V22.8173H32.2332V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 24.4565H24.8398V27.4752H26.8582V24.4565Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 24.4565H27.5293V27.4752H29.5477V24.4565Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 24.4565H30.2148V27.4752H32.2332V24.4565Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M18.3171 2.50569C18.3171 2.24058 18.2108 1.98633 18.0215 1.79888C17.8323 1.61142 17.5756 1.5061 17.3079 1.5061H6.82238C6.55473 1.5061 6.29804 1.61142 6.10877 1.79888C5.91951 1.98633 5.81319 2.24058 5.81319 2.50569V17.8793H4V19.8785H19.8612V17.8793H18.3171V2.50569ZM7.83158 3.50527H16.2987V17.8793H7.83158V3.50527Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 4.52478H8.40625V7.54352H10.4246V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 4.52478H11.0977V7.54352H13.116V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 4.52478H13.7852V7.54352H15.8035V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 9.18292H8.40625V12.2017H10.4246V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 9.18292H11.0977V12.2017H13.116V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 9.18292H13.7852V12.2017H15.8035V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 13.8409H8.40625V16.8596H10.4246V13.8409Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 13.8409H11.0977V16.8596H13.116V13.8409Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 13.8409H13.7852V16.8596H15.8035V13.8409Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M32.0886 8.74303H34.107V3.12537H27.4362V0L21.3945 4.0783L27.4497 8.12662V5.12787H32.1021L32.0886 8.74303ZM24.9873 4.0783L25.4111 3.79176V4.36152L24.9873 4.0783Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M8.20394 23.257H6.18555V28.8747H12.8563V32L18.898 27.9217L12.8429 23.8734V26.8722H8.19048L8.20394 23.257ZM15.3053 27.9217L14.8814 28.2083V27.6452L15.3053 27.9217Z"
                                                        fill="#265D91" />
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d_2093_1775" x="0" y="0" width="40.2949"
                                                        height="40.0001" filterUnits="userSpaceOnUse"
                                                        color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha" />
                                                        <feOffset dy="4" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_2093_1775" />
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                            in2="effect1_dropShadow_2093_1775" result="shape" />
                                                    </filter>
                                                </defs>
                                            </svg> Siret
                                        </div>
                                        <div class="text-gray-600"> {{dataEsn?.siret && dataEsn?.siret !== 'null' ?
                                            dataEsn?.siret:'---'}}</div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                viewBox="0 0 41 40" fill="none">
                                                <g filter="url(#filter0_d_2093_1775)">
                                                    <path
                                                        d="M34.7507 13.1212C34.7507 12.8561 34.6444 12.6019 34.4551 12.4144C34.2659 12.227 34.0092 12.1216 33.7415 12.1216H23.256C22.9883 12.1216 22.7316 12.227 22.5424 12.4144C22.3531 12.6019 22.2468 12.8561 22.2468 13.1212V28.4948H20.4336V30.494H36.2948V28.4948H34.7507V13.1212ZM24.2652 14.1208H32.7323V28.4948H24.2652V14.1208Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 15.137H24.8398V18.1558H26.8582V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 15.137H27.5293V18.1558H29.5477V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 15.137H30.2148V18.1558H32.2332V15.137Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 19.7985H24.8398V22.8173H26.8582V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 19.7985H27.5293V22.8173H29.5477V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 19.7985H30.2148V22.8173H32.2332V19.7985Z"
                                                        fill="#265D91" />
                                                    <path d="M26.8582 24.4565H24.8398V27.4752H26.8582V24.4565Z"
                                                        fill="#265D91" />
                                                    <path d="M29.5477 24.4565H27.5293V27.4752H29.5477V24.4565Z"
                                                        fill="#265D91" />
                                                    <path d="M32.2332 24.4565H30.2148V27.4752H32.2332V24.4565Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M18.3171 2.50569C18.3171 2.24058 18.2108 1.98633 18.0215 1.79888C17.8323 1.61142 17.5756 1.5061 17.3079 1.5061H6.82238C6.55473 1.5061 6.29804 1.61142 6.10877 1.79888C5.91951 1.98633 5.81319 2.24058 5.81319 2.50569V17.8793H4V19.8785H19.8612V17.8793H18.3171V2.50569ZM7.83158 3.50527H16.2987V17.8793H7.83158V3.50527Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 4.52478H8.40625V7.54352H10.4246V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 4.52478H11.0977V7.54352H13.116V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 4.52478H13.7852V7.54352H15.8035V4.52478Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 9.18292H8.40625V12.2017H10.4246V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 9.18292H11.0977V12.2017H13.116V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 9.18292H13.7852V12.2017H15.8035V9.18292Z"
                                                        fill="#265D91" />
                                                    <path d="M10.4246 13.8409H8.40625V16.8596H10.4246V13.8409Z"
                                                        fill="#265D91" />
                                                    <path d="M13.116 13.8409H11.0977V16.8596H13.116V13.8409Z"
                                                        fill="#265D91" />
                                                    <path d="M15.8035 13.8409H13.7852V16.8596H15.8035V13.8409Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M32.0886 8.74303H34.107V3.12537H27.4362V0L21.3945 4.0783L27.4497 8.12662V5.12787H32.1021L32.0886 8.74303ZM24.9873 4.0783L25.4111 3.79176V4.36152L24.9873 4.0783Z"
                                                        fill="#265D91" />
                                                    <path
                                                        d="M8.20394 23.257H6.18555V28.8747H12.8563V32L18.898 27.9217L12.8429 23.8734V26.8722H8.19048L8.20394 23.257ZM15.3053 27.9217L14.8814 28.2083V27.6452L15.3053 27.9217Z"
                                                        fill="#265D91" />
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d_2093_1775" x="0" y="0" width="40.2949"
                                                        height="40.0001" filterUnits="userSpaceOnUse"
                                                        color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha" />
                                                        <feOffset dy="4" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_2093_1775" />
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                            in2="effect1_dropShadow_2093_1775" result="shape" />
                                                    </filter>
                                                </defs>
                                            </svg> Année de création
                                        </div>
                                        <div class="text-gray-600">   {{ getYearFromDate(dataEsn?.year_of_creation) }}
                                        </div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg width="20" height="20" viewBox="0 0 25 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M15.7587 8.53108C15.7587 8.82967 15.7162 9.14532 15.6396 9.47803C15.4183 10.3909 14.9076 11.3719 14.184 12.0288C14.167 12.0459 14.15 12.0715 14.1245 12.08C13.6223 12.5151 13.0264 12.7966 12.354 12.7966C11.6815 12.7966 11.0857 12.5151 10.5835 12.08C10.558 12.0715 10.5409 12.0459 10.5239 12.0288C9.80041 11.3719 9.2897 10.3909 9.06839 9.47803C8.99178 9.14532 8.94922 8.82967 8.94922 8.53108C8.94922 6.64571 10.4729 5.11865 12.354 5.11865C14.2351 5.11865 15.7587 6.64571 15.7587 8.53108Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M17.4604 17.9153V22.1808C17.4604 23.1192 16.6943 23.887 15.758 23.887H8.94848C8.01217 23.887 7.24609 23.1192 7.24609 22.1808V17.9153C7.24609 16.0384 8.45478 14.4431 10.1401 13.8715C10.3104 13.8203 10.4806 13.7692 10.6509 13.735C10.9232 13.6753 11.2126 13.6497 11.502 13.6497H13.2044C13.4938 13.6497 13.7832 13.6753 14.0556 13.735C14.2259 13.7692 14.3961 13.8203 14.5663 13.8715C16.2517 14.4431 17.4604 16.0384 17.4604 17.9153Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M22.5686 3.41243C22.5686 5.11011 21.2067 7.67796 19.1638 7.67796C18.3637 7.67796 17.6657 7.28553 17.121 6.70542C16.8912 6.09118 16.5507 5.54519 16.1081 5.07598C15.8867 4.5044 15.7676 3.92429 15.7676 3.41243C15.7676 1.52706 17.2912 0 19.1723 0C21.0535 0 22.5771 1.52706 22.5771 3.41243H22.5686Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M24.2705 12.7965V17.0621C24.2705 18.0005 23.5044 18.7683 22.5681 18.7683H19.1634V17.9152C19.1634 15.6203 17.861 13.6241 15.9629 12.6259C16.9077 11.4486 17.4355 9.90451 17.4525 8.61632C17.7334 8.56513 18.0228 8.53101 18.3122 8.53101H20.0146C22.3638 8.53101 24.2705 10.442 24.2705 12.7965Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M8.94945 3.41243C8.94945 3.92429 8.83029 4.5044 8.60898 5.06745C8.16636 5.54519 7.82588 6.09118 7.59606 6.70542C7.0513 7.28553 6.35332 7.67796 5.5532 7.67796C3.51034 7.67796 2.14844 5.11011 2.14844 3.41243C2.14844 1.52706 3.67207 0 5.5532 0C7.43433 0 8.95796 1.52706 8.95796 3.41243H8.94945Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M7.25554 8.61632C7.27256 9.90451 7.8003 11.4486 8.74512 12.6259C6.84697 13.6241 5.54465 15.6203 5.54465 17.9152V18.7683H2.13988C1.20357 18.7683 0.4375 18.0005 0.4375 17.0621V12.7965C0.4375 10.442 2.34417 8.53101 4.69345 8.53101H6.39584C6.68524 8.53101 6.97465 8.56513 7.25554 8.61632Z"
                                                    fill="#265D91" />
                                            </svg>
                                            Taille
                                        </div>
                                        <div class="text-gray-600"> {{dataEsn?.size_company ?
                                            dataEsn?.size_company:'---' }}</div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg width="20" height="20" viewBox="0 0 24 23" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.73647 8.09902C8.26136 6.24589 6.59846 4.86792 4.60298 4.86792C2.6075 4.86792 0.944596 6.24589 0.469482 8.09902C0.374459 8.47915 0.659528 8.85928 1.08713 8.85928H8.16633C8.54643 8.85928 8.83149 8.47915 8.73647 8.09902Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M4.60227 3.91757C5.6781 3.91757 6.55023 3.04535 6.55023 1.9694C6.55023 0.893463 5.6781 0.0212402 4.60227 0.0212402C3.52643 0.0212402 2.6543 0.893463 2.6543 1.9694C2.6543 3.04535 3.52643 3.91757 4.60227 3.91757Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M23.2755 8.09902C22.8004 6.24589 21.1375 4.86792 19.142 4.86792C17.1466 4.86792 15.4361 6.24589 15.0085 8.09902C14.9135 8.47915 15.1986 8.85928 15.6262 8.85928H22.7054C23.038 8.85928 23.3706 8.47915 23.2755 8.09902Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M19.1413 3.91757C20.2172 3.91757 21.0893 3.04535 21.0893 1.9694C21.0893 0.893463 20.2172 0.0212402 19.1413 0.0212402C18.0655 0.0212402 17.1934 0.893463 17.1934 1.9694C17.1934 3.04535 18.0655 3.91757 19.1413 3.91757Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M11.8722 17.2697C9.06904 17.2697 6.74098 19.1703 6.12333 21.7837C5.98079 22.3064 6.4084 22.829 6.97853 22.829H16.8134C17.3835 22.829 17.7636 22.3064 17.6686 21.7837C17.0034 19.1703 14.6754 17.2697 11.8722 17.2697Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M11.8722 15.9392C13.3679 15.9392 14.5804 14.7266 14.5804 13.2308C14.5804 11.7349 13.3679 10.5223 11.8722 10.5223C10.3765 10.5223 9.16406 11.7349 9.16406 13.2308C9.16406 14.7266 10.3765 15.9392 11.8722 15.9392Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M6.69389 18.315C6.83642 17.9349 6.64638 17.5547 6.26628 17.4122C5.0785 16.9845 4.12827 16.1292 3.55813 14.9889C2.988 13.8485 2.94048 12.5655 3.32058 11.3776C3.46311 10.9975 3.27306 10.6174 2.89297 10.4748C2.51288 10.3323 2.13279 10.5223 1.99026 10.9025C0.897493 14.1336 2.6079 17.6022 5.79117 18.7426C5.88619 18.7902 6.40882 18.9802 6.69389 18.315Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M20.8522 10.4748C20.4721 10.6174 20.282 10.9975 20.4246 11.3776C20.8522 12.5655 20.7572 13.8485 20.187 14.9889C19.6169 16.1292 18.6666 16.9845 17.4789 17.4122C17.0988 17.5547 16.8612 17.9349 17.0513 18.315C17.3363 18.9802 17.859 18.7902 17.954 18.7426C21.1848 17.6498 22.8477 14.1336 21.7549 10.9025C21.6124 10.5699 21.2323 10.3323 20.8522 10.4748Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M8.3092 4.15514C9.25943 3.20482 10.4947 2.68214 11.8726 2.68214C13.2029 2.68214 14.4857 3.20482 15.4359 4.15514C15.5784 4.29769 16.0536 4.53527 16.4337 4.15514C16.7187 3.87004 16.7187 3.4424 16.4337 3.1573C15.1984 1.92188 13.583 1.25665 11.8726 1.25665C10.1621 1.25665 8.54675 1.92188 7.31146 3.1573C7.02639 3.4424 7.02639 3.87004 7.31146 4.15514C7.59653 4.44024 8.02413 4.44024 8.3092 4.15514Z"
                                                    fill="#265D91" />
                                            </svg>
                                            Domaine
                                        </div>
                                        <div class="text-gray-600"> {{dataEsn?.domain ? dataEsn?.domain:'---' }}</div>
                                    </div>
                                    <div class="flex-space ">
                                        <div class="contact-info-name">
                                            <svg width="20" height="20" viewBox="0 0 18 17" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14.608 1.62H13.596V0H12.276V1.62H4.752V0H3.432V1.62H2.42C1.77818 1.62 1.16264 1.87033 0.708802 2.31591C0.254964 2.7615 0 3.36585 0 3.996V14.5584C0 15.1886 0.254964 15.7929 0.708802 16.2385C1.16264 16.6841 1.77818 16.9344 2.42 16.9344H14.608C15.2498 16.9344 15.8654 16.6841 16.3192 16.2385C16.773 15.7929 17.028 15.1886 17.028 14.5584V3.996C17.028 3.36585 16.773 2.7615 16.3192 2.31591C15.8654 1.87033 15.2498 1.62 14.608 1.62ZM2.442 2.916H3.454V4.32H4.774V2.916H12.276V4.32H13.596V2.916H14.608C14.8997 2.916 15.1795 3.02979 15.3858 3.23232C15.5921 3.43486 15.708 3.70957 15.708 3.996V5.3136H1.32V3.996C1.32 3.70957 1.43589 3.43486 1.64218 3.23232C1.84847 3.02979 2.12826 2.916 2.42 2.916H2.442ZM14.608 15.6384H2.42C2.12826 15.6384 1.84847 15.5246 1.64218 15.3221C1.43589 15.1195 1.32 14.8448 1.32 14.5584V6.6096H15.708V14.5584C15.708 14.7002 15.6795 14.8407 15.6243 14.9717C15.569 15.1027 15.488 15.2218 15.3858 15.3221C15.2837 15.4224 15.1624 15.5019 15.029 15.5562C14.8955 15.6105 14.7525 15.6384 14.608 15.6384Z"
                                                    fill="#265D91" />
                                                <path d="M9.96794 7.86243H7.08594V10.6899H9.96794V7.86243Z"
                                                    fill="#265D91" />
                                                <path d="M5.87419 7.86243H2.99219V10.6899H5.87419V7.86243Z"
                                                    fill="#265D91" />
                                                <path d="M14.0578 7.86243H11.1758V10.6899H14.0578V7.86243Z"
                                                    fill="#265D91" />
                                                <path d="M9.96794 11.5344H7.08594V14.3619H9.96794V11.5344Z"
                                                    fill="#265D91" />
                                                <path d="M5.87419 11.5344H2.99219V14.3619H5.87419V11.5344Z"
                                                    fill="#265D91" />
                                                <path d="M14.0578 11.5344H11.1758V14.3619H14.0578V11.5344Z"
                                                    fill="#265D91" />
                                            </svg>
                                            Periode d'essai
                                        </div>
                                        <div class="text-gray-600">---</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 no-padding">
                            <div class="propos">
                               
                                <div class="title mb-5">
                                    <div class="contact" id="title-bloc" >À propos</div>
                                  
                                </div>
                                <div class="content">
                                    <span style="white-space: pre-line;">{{dataEsn?.about && dataEsn?.about!= 'undefined' && dataEsn?.about!= 'null' ? dataEsn?.about:'---'}}</span>
                                </div>
                            </div>
                            <div class="details">
                               
                                <div class="title mb-5">
                                    <div class="contact" id="title-bloc">Détails Techniques</div>
                                  
                                </div>
                                <div class="flex-space">
                                    <div class="detail" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Tooltip on top">
                                        Nom Admin</div>
                                    <div class="text-gray-600">
                                        {{dataEsn?.employee[0]?.user.last_name ?
                                        dataEsn?.employee[0]?.user.last_name:'---'}}
                                        {{dataEsn?.employee[0]?.user.first_name ?
                                        dataEsn?.employee[0]?.user.first_name:'---'}}
                                    </div>
                                </div>

                                <div class="flex-space">
                                    <div class="detail" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Tooltip on top">
                                        Mail Admin</div>
                                    <div class="text-gray-600"
                                        *ngIf="dataEsn?.type ===  '2' || dataEsn?.type ===  '4' ">
                                        {{dataEsn?.employee[0]?.user.emailProESN ?
                                        dataEsn?.employee[0]?.user.emailProESN:'---'}}
                                    </div>
                                    <div class="text-gray-600" *ngIf="dataEsn?.type ===  '3'">
                                        {{dataEsn?.employee[0]?.user.emailProClient ?
                                        dataEsn?.employee[0]?.user.emailProClient:'---'}}
                                    </div>
                                </div>

                                <div class="flex-space">
                                    <div class="detail" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Tooltip on top">
                                        Numéro Admin</div>
                                    <div class="text-gray-600">
                                        {{dataEsn?.employee[0]?.user.phone_number ?
                                        dataEsn?.employee[0]?.user.phone_number:'---'}}
                                    </div>
                                </div>

                                <div class="flex-space">
                                    <div class="detail" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Tooltip on top">
                                        Nombre des intervenants</div>
                                    <div class="text-gray-600">
                                        {{dataEsn?.number_of_intervenant ? dataEsn?.number_of_intervenant:'---'}}
                                    </div>
                                </div>

                                <div class="flex-space">
                                    <div class="detail" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Tooltip on top">
                                        Nombre des consultants</div>
                                    <div class="text-gray-600">
                                        {{dataEsn?.number_of_consultant ? dataEsn?.number_of_consultant:'---'}}
                                    </div>
                                </div>

                                <div class="flex-space">
                                    <div class="detail" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Tooltip on top">
                                        Nombre des Cra Validés</div>
                                    <div class="text-gray-600">
                                        {{dataEsn?.nb_cra ? dataEsn?.nb_cra:'---'}}
                                    </div>
                                </div>

                                <div class="flex-space">
                                    <div class="detail" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Tooltip on top">
                                        Nombre des offre d'emplois publiés</div>
                                    <div class="text-gray-600">
                                        {{dataEsn?.nb_job_offer ? dataEsn?.nb_job_offer:'---'}}
                                    </div>
                                </div>

                                <div class="flex-space">
                                    <div class="detail" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Tooltip on top">
                                        Abonnement</div>
                                    <div class="text-gray-600">
                                        {{dataEsn?.abonnements ? dataEsn?.abonnements:'---'}}
                                    </div>
                                </div>

                                <div class="flex-space">
                                    <div class="detail" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Tooltip on top">
                                        Date de premier abonnement</div>
                                    <div class="text-gray-600" *ngIf="dataEsn?.abonnement_start_date">
                                        {{dataEsn?.abonnement_start_date | date: 'dd/MM/yyyy' }}
                                    </div>
                                    <div class="text-gray-600" *ngIf="!dataEsn?.abonnement_start_date">
                                        ---
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div *ngIf="disabledMode">
                        <app-profile-esn-review></app-profile-esn-review>
                    </div>
                </div>
            </div>








        </div>
    </div>
</div>

<div class="modal fade" id="kt_modal_add_customer" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
            <!--begin::Form-->
            <form class="form" action="#" id="kt_modal_add_customer_form"
                data-kt-redirect="../../demo1/dist/apps/customers/list.html">
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <!--begin::Modal title-->
                    <h2 class="">Détail intervenant</h2>
                    <!--end::Modal title-->
                    <!--begin::Close-->
                    <div id="kt_modal_add_customer_close" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path d="M14 1L1 14" stroke="#716F6F" stroke-width="1.5" stroke-linecap="round" />
                            <path d="M1 1L14 14" stroke="#716F6F" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                    </div>
                    <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                    <!--begin::Scroll-->
                    <div class=" me-n7 pe-7 tools" id="">
                        <!--begin::Input group-->
                        <div class="row">
                            <div class="col-6 fv-row mb-7">
                                <label class="d-flex align-items-center form-label mb-2">
                                    <span class="">Nom intervenant</span>
                                </label>
                                <!--end::Label-->
                                <input [disabled]="true" type="text" class="form-control form-control-solid"
                                    placeholder="nom" name="Nom" />
                            </div>
                            <!--end::Input group-->
                            <!--begin::Input group-->
                            <div class="col-6 fv-row mb-7">
                                <!--begin::Label-->
                                <label class="fs-6 fw-semibold mb-2">
                                    <span class="">Email professionnel</span>
                                </label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <input [disabled]="true" type="email" class="form-control form-control-solid"
                                    placeholder="Intervenant1@gmail.com" name="email" value="" />
                                <!--end::Input-->
                            </div>
                            <div class="col-6 fv-row mb-7">
                                <!--begin::Label-->
                                <label class="fs-6 fw-semibold mb-2">
                                    <span class="">Email personnel</span>
                                </label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <input [disabled]="true" type="email" class="form-control form-control-solid"
                                    placeholder="Intervenant1@gmail.com" name="email" value="" />
                                <!--end::Input-->
                            </div>
                            <!--end::Input group-->
                            <!--begin::Input group-->
                            <div class="col-6 fv-row mb-7">
                                <!--begin::Label-->
                                <label class="fs-6 fw-semibold mb-2">N° tel</label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <input [disabled]="true" type="text" class="form-control form-control-solid"
                                    placeholder="*********" name="description" />
                                <!--end::Input-->
                            </div>
                            <div class="col-6 fv-row ">
                                <!--begin::Label-->
                                <label class="fs-6 fw-semibold mb-2">Role</label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <select [disabled]="true" class="form-select" data-control="select2"
                                    data-placeholder="In Progress" data-hide-search="true">
                                    <option value="1">Commercial</option>
                                    <option value="2" selected="selected">Commercial</option>
                                    <option value="3">commercial</option>
                                </select>
                                <!--end::Input-->
                            </div>
                            <div class="col-6 fv-row mb-7">
                                <!--begin::Label-->
                                <label class="fs-6 fw-semibold mb-2">Salaire</label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <input [disabled]="true" type="text" class="form-control form-control-solid"
                                    placeholder="40000€" name="salire" />
                                <!--end::Input-->
                            </div>
                            <div class="col-6 fv-row mb-7">
                                <!--begin::Label-->
                                <label class="fs-6 fw-semibold mb-2">TJM</label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <input [disabled]="true" type="text" class="form-control form-control-solid"
                                    placeholder="15€" name="tjm" />
                                <!--end::Input-->
                            </div>
                            <div class="col-6 fv-row mb-7">
                                <!--begin::Label-->
                                <label class="fs-6 fw-semibold mb-2">Date début contrat</label>
                                <input [disabled]="true" type="date" class="form-control">
                                <!--end::Input-->
                            </div>
                            <div class="col-6 fv-row mb-7">
                                <!--begin::Label-->
                                <label class="fs-6 fw-semibold mb-2">Date début mission</label>
                                <input [disabled]="true" type="date" class="form-control">
                                <!--end::Input-->
                            </div>
                            <div class="col-6 fv-row mb-7">
                                <!--begin::Label-->
                                <label class="fs-6 fw-semibold mb-2">Date fin mission</label>
                                <input [disabled]="true" type="date" class="form-control">
                                <!--end::Input-->
                            </div>
                        </div>


                    </div>
                    <!--end::Scroll-->
                </div>
            </form>
            <!--end::Form-->
        </div>
    </div>
</div>