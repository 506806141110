import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  existednamefamille,
  serverError,
} from '../../../../core/models/messages';
import { SettingsService } from '../../../../core/services/settings/settings.service';
import { SortDataService } from '../../../../core/services/sort-data/sort-data.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css'],
})
export class EvaluationComponent {
  formchapitre: FormGroup = this.createchapitreForm();
  listchapitre: any = [];
  esn_id!: string | null;
  currentSortDirection: number = 2;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortColumn: string = 'name';
  pagination : boolean =false;
  modalRef?: BsModalRef;
  searchForm: FormGroup = this.createSearchForm();
  id!: number;
  idch!: string;
  sortAttr: string = '';
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private Familleservice: SettingsService,
    private spinner: NgxSpinnerService,
  
  ) {}
  ngOnInit() {
    this.getListchapitre();
    console.log('liste', this.getListchapitre());
  }
  /* sort data  */
  sortData(columnName: string) {
    let sort = {
      sort: this.currentSortDirection,
      sortAttribute: this.currentSortColumn,
    };

    if (this.currentSortColumn === columnName) {
      this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1; // Toggle sort direction
    } else {
      this.currentSortColumn = columnName; // Change the sort column
      this.currentSortDirection = 1; // Reset sort direction to ascending
    }

    this.getListchapitre(sort);
  }

  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchForm.dirty) {
        this.applyFilter();
      } else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
        this.getListchapitre();
      }
    }
  }
  createchapitreForm(data?: any) {
    return this.formBuilder.group({
      name: [data && data.name, [Validators.required]],
    });
  }

  createChapitre() {
    if (this.formchapitre.valid) {
      this.spinner.show();
      this.Familleservice.createfamilleevaluation(this.formchapitre.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.getListchapitre();
            this.toastr.success("Sujet d'évaluation a été ajouté avec succès.");
            this.closeModal();
            this.formchapitre.reset();
          },
          error: (err) => {
            if (err.error.status==422) {
              this.toastr.error(existednamefamille);
            }else {
              this.toastr.error(
                'Erreur du serveur lors de la création du chapitre.'
              );
            }
            this.spinner.hide();
          },
        });
    }
  }
  /* list list esn companies */
  getListchapitre(sort?: any) {
    this.spinner.show();
    let dataPyload = !sort
      ? { per_page: this.itemsPerPage, page: this.currentPage }
      : { ...{ per_page: this.itemsPerPage, page: this.currentPage }, ...sort };
    this.Familleservice.listFamille(dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.listchapitre = res.data.items.data;
          this.totalItems = res.data.items.total;

          console.log('loggggglist', this.listchapitre);
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  deletechapitre(id: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.Familleservice.deletefamille(id).subscribe((res: any) => {
          console.log('responce', res);
          this.toastr.success("Sujet d'évaluation  a été supprimé.");
          this.getListchapitre();
        });
      }
    });
  }

  closeModal() {
    this.modalRef?.hide();
    this.formchapitre.reset();
  }

  /**modifier chapitre evaluation  */

  openModal(template: TemplateRef<any>, data?: any, mode?: string) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });

    if (data) {
      this.id = data.id;
      // Ajoutez cette ligne
      this.formchapitre = this.createchapitreForm(data);
    }
  }

  updateIntervenant() {
    if (this.formchapitre.valid) {
      this.spinner.show();
      this.formchapitre.get('id')?.setValue(this.id);

      this.Familleservice.updateFamille(this.id, this.formchapitre.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.getListchapitre();
            this.toastr.success('La famille a été modifiée avec succès.');
            this.closeModal();
            this.formchapitre.reset();
          },
          error: (err) => {
            if (err.error && err.error.message && err.error.message.name) {
              // const errorMessage = err.error.message.name[0];
              // this.toastr.info(errorMessage);
              this.toastr.info(existednamefamille);
            } else {
              this.toastr.error(
                'Erreur du serveur lors de la modification de la famille.'
              );
            }
            this.spinner.hide();
          },
        });
    }
  }

  /* search chapitre on input */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      name: [data ? data.name : ''],
    });
  }

  /* search intervenant on input */
  applyFilter() {
    let data = {
      search: this.searchForm.value.name,
    };
    this.spinner.show();
    this.Familleservice.searchfamille(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (!res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.applyFilter();
          } else {
            this.listchapitre = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
            this.spinner.hide();
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }

  reset() {
    this.currentPage = 1;
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    this.applyFilter();
  }
}
