import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../models/response-api';
import { CrudService } from '../crud/crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get list bank accounts */
  // getAccountsList(data?:{per_page?:number,page?:number,sort?:number,sortAttribute?:string}){
  //   let sort=''
  // if(data?.sort) sort=`&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`
  //   return this._http.get<ApiResponse<any>>(this._base+`/BankAccount/getAll?per_page=${data?.per_page}&page=${data?.page}`);
  // }
  getAccountsList() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Payment/listAccounts`
    );
  }
  /* get list bank accounts without pagination */
  getAccounts() {
    return this._http.get<ApiResponse<any>>(this._base + `/BankAccount/getAll`);
  }
  /* add bank accounts */
  addAccount(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/BankAccount/add`,
      data
    );
  }
  /* update bank accounts */
  updateAccount(idAccount: number, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/BankAccount/update/${idAccount}`,
      data
    );
  }
  /* delete bank accounts */
  deleteAccount(idAccount: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/BankAccount/delete/${idAccount}`
    );
  }

  addCompanyBankAccount(data: any): Observable<any> {
    return this._http.post(`${this._base}/Payment/stripe/addBankAccount`, data);
  }
}
