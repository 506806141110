import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedOffer,
  existedOffer,
  serverError,
  updatedOffer,
} from '../../../../../core/models/messages';
import { IOffer } from '../../../../../core/models/setting';
import { PermissionService } from '../../../../../core/services/permission.service';
import { SettingsService } from '../../../../../core/services/settings/settings.service';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.css'],
})
export class AddOfferComponent {
  /* string */
  mode: string = 'create';
  searchValue: string = '';
  showSkills: string = 'form';
  /* arrays */
  listPermissionsRoles: any = [];
  eventsDropped: boolean = false;

  /* numbers */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  idOffer!: number | undefined;
  tab = 1;
  /* array */
  companyType = [
    { id: '1', name: 'ESN' },
    // { id: "2", name: 'Client final' },
    // { id: "3", name: 'Candidat' },
  ];
  listPermissions: any = [];
  listPermissionOffer: any[] = [];
  /* modal */
  modalRef?: BsModalRef;
  /* formGroup */
  formOffer: FormGroup = this.createFormOffer();
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private settingService: SettingsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private router: Router
  ) {}
  ngOnInit() {
    this.getIdOffre();
    this.getListPermissions();
  }
  clearData() {
    this.listPermissions.length = 0;
    this.listPermissionOffer.length = 0;
    this.getListPermissions();
  }
  getListPermissions() {
    this.spinner.show();
    if (this.mode == 'create'){
      this.permissionService
      .getListPermissions(this.idOffer)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listPermissions = [];
            this.listPermissionsRoles = [];

            console.log('listPermissions of ', res.data);
            // Objet pour regrouper les objets par attribut "Title"
            const objetsParTitre: any = {};

            // Boucle à travers les objets pour les regrouper par "Title"
            res.data.forEach((objet: any) => {
              const titre = objet.Title;
              if (!objetsParTitre[titre]) {
                objetsParTitre[titre] = []; // Crée un tableau s'il n'existe pas encore pour ce titre
              }
              objetsParTitre[titre].push(objet); 
            // Ajoute l'objet au tableau correspondant à ce titre
            });
            console.log('listPermissions objetsParName ',objetsParTitre);

            // Affichage du résultat

            for (const titre in objetsParTitre) {
              if (titre != 'null') {
                this.listPermissionsRoles.push({
                  titre: titre,
                  children: objetsParTitre[titre].filter(
                    (el: any) => el.has_permission === 'oui'
                  ),
                });
                this.listPermissions.push({
                  titre: titre,
                  children: objetsParTitre[titre].filter(
                    (el: any) => el.has_permission === 'non'
                  ),
                });
              }
            }

            console.log('listPermissionsRoles : ', this.listPermissionsRoles);
            console.log('listPermissions : ', this.listPermissions);

            /*   res.data.map((el: { has_permission: string }) => {
               if (el.has_permission == 'non') {
                 this.listPermissions.push(el)
               }
             })
             for (const iterator of res.data) {
               if (iterator.has_permission == 'oui') {
                 this.listPermissionsRoles.push(iterator)
               }
             }*/
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
    }else{
      this.permissionService
      .getListPermissionsByOffer(this.idOffer)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listPermissions = [];
            this.listPermissionsRoles = [];

            console.log('listPermissions of ', res.data);
            // Objet pour regrouper les objets par attribut "Title"
            const objetsParTitre: any = {};

            // Boucle à travers les objets pour les regrouper par "Title"
            res.data.forEach((objet: any) => {
              const titre = objet.Title;
              if (!objetsParTitre[titre]) {
                objetsParTitre[titre] = []; // Crée un tableau s'il n'existe pas encore pour ce titre
              }
              objetsParTitre[titre].push(objet); 
            // Ajoute l'objet au tableau correspondant à ce titre
            });
            console.log('listPermissions objetsParName ',objetsParTitre);

            // Affichage du résultat

            for (const titre in objetsParTitre) {
              if (titre != 'null') {
                this.listPermissionsRoles.push({
                  titre: titre,
                  children: objetsParTitre[titre].filter(
                    (el: any) => el.has_permission === 'oui'
                  ),
                });
                this.listPermissions.push({
                  titre: titre,
                  children: objetsParTitre[titre].filter(
                    (el: any) => el.has_permission === 'non'
                  ),
                });
              }
            }

            console.log('listPermissionsRoles : ', this.listPermissionsRoles);
            console.log('listPermissions : ', this.listPermissions);

            /*   res.data.map((el: { has_permission: string }) => {
               if (el.has_permission == 'non') {
                 this.listPermissions.push(el)
               }
             })
             for (const iterator of res.data) {
               if (iterator.has_permission == 'oui') {
                 this.listPermissionsRoles.push(iterator)
               }
             }*/
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
    }
 
  }
  /* get id offre and mode from route */
  getIdOffre() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.mode = params.params['mode'];
        this.idOffer = params.params['id'];
        if (this.idOffer) this.getOffer();
      },
    });
  }
  dataIDSS: any = [];

  listIds: any = [];
  families: any = [];

  getConnectedListsRoles(currentListId: any): any[] {
    console.log(this.listPermissionsRoles, currentListId);
    return this.listPermissionsRoles
      .filter((list: any) => list.titre !== currentListId) // Exclude the current list
      .map((list: any) => `list-role-${list.titre}`); // Get the IDs of the remaining lists
  }

  getConnectedListsPers(currentListId: any): any[] {
    return this.listPermissions
      .filter((list: any) => list.titre !== currentListId) // Exclude the current list
      .map((list: any) => `list-per-${list.titre}`); // Get the IDs of the remaining lists
  }
  search(nameKey: any, myArray: any[]) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].titre === nameKey) {
        return myArray[i];
      }
    }
  }
  onDrop(event: CdkDragDrop<any[]>, parents: any[]) {
    //   this.listIds = []
    console.log("parents : ",parents[event.currentIndex],event.previousContainer,event.container,event.item.dropContainer);

    if (event.previousContainer === event.container) {
      //moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      if (event.container === event.item.dropContainer) {
        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    } else {
      // Transfer item from one list to another
      const previousContainer = event.previousContainer.data;
      const container = event.container.data;
      const previousIndex = event.previousIndex;
      const currentIndex = event.currentIndex;
      const item = previousContainer[previousIndex];

      if (item?.children != undefined) {
        console.log('parents of item.. : ', item);
        if (event.previousContainer === event.item.dropContainer) {
          transferArrayItem(
            previousContainer,
            container,
            previousIndex,
            currentIndex
          );
        } else {
          previousContainer.splice(previousIndex, 1);
          container.splice(currentIndex, 0, item);
        }

        this.eventsDropped = true;

        item.children.forEach((item: any) => {
          if (item && item.has_permission !== undefined) {
            if (item.has_permission == 'non') {
              item.has_permission = 'oui';
            } else {
              item.has_permission = 'non';
            }
            this.listIds.push(item);
          } else {
            console.warn('Item or has_permission is undefined', item);
          }
        });
      } else {
        console.log('parents of item : ', item);
        console.log(this.search(item?.Title, container));

        this.search(item?.Title, container)?.children.push(item);
        var object = {
          titre: item?.Title,
          children: this.search(item?.Title, container)?.children,
        };
        console.log('obj : ', object);

        if (event.previousContainer === event.item.dropContainer) {
          transferArrayItem(
            previousContainer,
            container,
            previousIndex,
            currentIndex
          );
        } else {
          previousContainer.splice(previousIndex, 1);
          container.splice(currentIndex, 0, object);
        }

        this.eventsDropped = true;
        if (item && item.has_permission !== undefined) {
        if (item?.has_permission == 'non') {
          item.has_permission = 'oui';
          this.listIds.push(item);
        } else {
          item.has_permission = 'non';
          this.listIds.push(item);
        }
      }
      }
    }
  }

  onChildDrop(event: CdkDragDrop<any[]>, children: any[]) {
    console.log('childrens : ', children[event.previousIndex]);

    this.listIds = [];
    if (event.previousContainer === event.container) {
      moveItemInArray(children, event.previousIndex, event.currentIndex);
    } else {
      // Transfer child between parent's children logic
      const child = event.previousContainer.data[event.previousIndex];
      event.previousContainer.data.splice(event.previousIndex, 1);
      children.splice(event.currentIndex, 0, child);
    }
    this.eventsDropped = true;
    children[event.previousIndex].has_permission = 'oui';
    if (children[event.previousIndex].has_permission == 'non') {
      //  this.listIds.push(item)
      console.log(children[event.previousIndex].Title);
      console.log(this.listPermissions);
      console.log(
        this.search(children[event.previousIndex].Title, this.listPermissions)
      );
    } else {
      console.log(children[event.previousIndex].Title);
      console.log(this.listPermissionsRoles);
      console.log(
        this.search(
          children[event.previousIndex].Title,
          this.listPermissionsRoles
        )
      );
    }
    /*
      this.listPermissionsRoles.map((el: any) => {
        el.children.forEach((item: any) => {
          if (item.has_permission == 'non') {
            this.listIds.push(item)
          }
        })
      })
      this.listPermissions.map((el: any) => {
        el.children.forEach((item: any) => {
  
          if (item.has_permission == 'oui') {
            this.listIds.push(item)
          }
        })
      })*/
  }
  /* get offer by id*/
  getOffer() {
    this.spinner.show();
    this.settingService
      .getOffer(this.idOffer)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.setFormData(this.mode, res.data);
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* open modal */
  setFormData(mode: string, data?: IOffer) {
    this.mode = mode;
    if (mode != 'create') {
      this.idOffer = data?.id;
      this.formOffer = this.createFormOffer(data);
    }
    if (mode == 'detail') {
      this.formOffer
        .get('annual_price')
        ?.setValue(this.formOffer.value.annual_price + ' €');
      this.formOffer
        .get('monthly_price')
        ?.setValue(this.formOffer.value.monthly_price + ' €');
      this.formOffer.disable();
    }
  }
  /* close modal */
  closeModal() {
    this.modalRef?.hide();
  }
  /* create offer form */
  createFormOffer(data?: IOffer) {
    const today = new Date().toISOString().split('T')[0];
    return this.formBuilder.group({
      name: [data ? data.name : '', [Validators.required]],
      // start_date: [data ? data.start_date : '', [Validators.required]],
      start_date: [{ 
        value: data && data.start_date ? data.start_date : today, 
        disabled: true 
      }],      end_date: [data ? data.end_date : ''],
      // trial_period: [data ? data.trial_period : null, [Validators.required]],
      // price: [data ? data.price : "", [Validators.required]],
      dedicated_to: [data ? data.dedicated_to : null, [Validators.required]],
      nbr_intervenant: [
        data ? data.nbr_intervenant : '',
        [Validators.required],
      ],
      nbr_consultant: [data ? data.nbr_consultant : '', [Validators.required]],
      permission_ids: [data ? data.permission_ids : null],
      // bank_account_id: [data ? data.bank_account_id : null, [Validators.required]],
      // nbr_cv_to_upload: [
      //   data ? data.nbr_cv_to_upload : '',
      //   [Validators.required],
      // ],
      nbr_profiles_to_export: [
        data ? data.nbr_profiles_to_export : '',
        [Validators.required],
      ],
      // nbr_job_offers: [data ? data.nbr_job_offers : '', [Validators.required]],
      annual_price: [data ? data.annual_price : '', [Validators.required]],
      monthly_price: [data ? data.monthly_price : '', [Validators.required]],
    });
  }
  onStartDateChange(): void {
    const startDate = this.formOffer.get('start_date')?.value;
    const endDate = this.formOffer.get('end_date')?.value;
    this.checkDateValidity(startDate, endDate);
  }
  isDateGreaterThanToday: boolean = false;
  onEndDateChange(): void {
    const startDate = this.formOffer.get('start_date')?.value;
    const endDate = this.formOffer.get('end_date')?.value;
    this.checkDateValidity(startDate, endDate);
    const selectedDateObject = new Date(endDate);
    console.log('selectedDateObject', selectedDateObject);

    const today = new Date();
    console.log('today', today);
    this.isDateGreaterThanToday = selectedDateObject < today;
    console.log('isDateGreaterThanToday', this.isDateGreaterThanToday);
  }
  checkDateValidity(startDate: Date, endDate: Date): void {
    if (startDate && endDate && startDate > endDate) {
      this.formOffer.get('start_date')?.setErrors({ invalidDateRange: true });
    } else {
      this.formOffer.get('start_date')?.setErrors(null);
    }
  }
  /* send data either to add or to update */
  submitData() {
    if (this.mode == 'create'){
      this.addOffer()
    } else this.updateOffer();
  }
  permission_ids: number[] = [];
  getPermissionIds() {
    for (const iterator of this.listPermissionOffer) {
      this.permission_ids.push(iterator.id);
    }
    this.formOffer.get('permission_ids')?.setValue(this.permission_ids);
    // this.formOffer.get('permission_ids')?.setValue(this.listIds);
  
  }
  /* add offer */
  addOffer() {
    this.getPermissionIds();

    // if (this.formOffer.valid) {
      this.formOffer
        .get('start_date')
        ?.setValue(
          this.formOffer.value.start_date
            ? moment(this.formOffer.value.start_date).format('YYYY-MM-DD')
            : ''
        );
      this.formOffer
        .get('end_date')
        ?.setValue(
          this.formOffer.value.end_date
            ? moment(this.formOffer.value.end_date).format('YYYY-MM-DD')
            : ''
        );
    this.listIds.map((el: any) => this.dataIDSS.push(el.id));
    this.formOffer.get('permission_ids')?.setValue(this.dataIDSS);

      this.spinner.show();
      this.settingService
        .addOffer(this.formOffer.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(addedOffer);
              this.formOffer.reset();
              this.permission_ids = [];
              this.router.navigate([
                '/acceuil/parametres/parametre-abonnement',
              ]);
            }
          },
          error: (err) => {
            if (err.error) {
              if (err.error?.message) {
                if (err.error.message['name']) this.toastr.info(existedOffer);
                else this.toastr.error(serverError);
              }
            }
            this.spinner.hide();
          },
        });
    // }
  }
  /* update offer */
  updateOffer() {
    if (this.formOffer.valid) {
      this.formOffer
        .get('start_date')
        ?.setValue(
          this.formOffer.value.start_date
            ? moment(this.formOffer.value.start_date).format('YYYY-MM-DD')
            : ''
        );
      this.formOffer
        .get('end_date')
        ?.setValue(
          this.formOffer.value.end_date
            ? moment(this.formOffer.value.end_date).format('YYYY-MM-DD')
            : ''
        );
        this.listIds.map((el: any) => this.dataIDSS.push(el.id));
    this.formOffer.get('permission_ids')?.setValue(this.dataIDSS);
      this.spinner.show();
      this.settingService
        .updateOffer(this.idOffer, this.formOffer.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(updatedOffer);
              this.formOffer.reset();
              this.router.navigate([
                '/acceuil/parametres/parametre-abonnement',
              ]);
            }
          },
          error: (err) => {
            if (err.error) {
              if (err.error?.message) {
                if (err.error.message['name']) this.toastr.info(existedOffer);
                else this.toastr.error(serverError);
              }
            }
            this.spinner.hide();
          },
        });
    }
  }
  nextTab() {
    this.showSkills = 'permission';
  }
  /* key inter number */
  keyPress(event: any) {
    let pattern = /^[\d]$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    return pattern;
  }
  blockCtrlV(event: any) {
    if (event.ctrlKey && (event.key === 'v' || event.key === 'c' || event.key === 'x')) {
      event.preventDefault();
    }
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Empêche le menu contextuel par défaut
  }
}
