import { ChangeDetectorRef, Component, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Country, ICountry, IState, State } from 'country-state-city';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountryISO } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import {
  emailExist,
  fileTypeExtention,
  maxSizeFile,
  phoneNumberExist,
  serverError,
  successEditProfile,
  successEditProfileadmin,
} from '../../core/models/messages';
import { patternEmail } from '../../core/models/pattern';
import { LocalStorageService } from '../../core/services/Local-storage/local-storage.service';
import { CandidatService } from '../../core/services/candidat/candidat.service';
import { environment } from '../../../environments/environment';
import { PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
export function notOnlyWhitespace(
  control: AbstractControl
): ValidationErrors | null {
  if (control.value && control.value.trim() === '') {
    return { notOnlyWhitespace: true };
  }
  return null;
}
@Component({
  selector: 'app-profil-admin',
  templateUrl: './profil-admin.component.html',
  styleUrls: ['./profil-admin.component.css'],
})
export class ProfilAdminComponent {
  currentUser: any;
  id: any;
  profil_id: any;
  profil_image: any;
  last_name: any;
  first_name: any;
  selectedUser: any;
  email: any;
  city: any;
  phone: any;
  disabledMode: boolean = true;
  roleuser: any;
  image_url: any;
  fileExtention: string = '';
  roles = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Super admin' },
  ];
  file: any;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  separateDialCode: boolean = false;
  selectedImage: any;
  selectedImageTest: boolean = false;
  fileName: string = '';
  user: any;
  url: string = environment.baseUrl + '/api';
  formData = new FormData();
  isImageChanged: boolean = false;
  selectedCountryCode: string = 'FR'
  account:any

  userForm: FormGroup = this.createUserForm()
  private profileUpdatedSubject = new BehaviorSubject<boolean>(false);
  profileUpdated$ = this.profileUpdatedSubject.asObservable();
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private candidatService: CandidatService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService
  ) {}
  ngOnInit() {
    this.currentUser = this.localStorageService.getData('AdminInfo');
    this.id = JSON.parse(this.currentUser).uuid;
    this.getuserById()
    this.account = this.candidatService.getAccount()
  }
  createUserForm(data?: any) {
    return this.formBuilder.group({
      first_name: [
        data ? data.first_name : '',
        [Validators.required, notOnlyWhitespace],
      ],
      last_name: [
        data ? data.last_name : '',
        [Validators.required, notOnlyWhitespace],
      ],
      // email: [data ? data.email : '', [Validators.email]],
      email: [
        data ? data.email : '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(patternEmail),
        ],
      ],
      phone_number: [data ? data.phone_number : '', [Validators.required]],
      image_url: [data ? data.image_url : ''],
      city: [data ? data.city : ''],
      role_user_id: [data ? data.role_user_id : ''],
    });
  }

  getuserById() {
    this.spinner.show();
    
    this.candidatService.getUserById(this.id).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res:any) => {
        this.spinner.hide()
        this.currentUser = res.data[0]
        this.first_name = res.data[0]?.first_name
        this.last_name = res.data[0]?.last_name
        this.email = res.data[0]?.email
        this.phone = res.data[0]?.phone_number
        this.city = res.data[0]?.city
        this.image_url = res.data[0]?.image_url
        this.roleuser = res.data[0]?.role_user_id[0]
        this.userForm = this.createUserForm(res.data[0]);
        console.log("role user", this.roleuser)
        this.spinner.hide()
      },
      error: (error:any) => {
        console.log("errr ",error)

        this.spinner.hide()
      },
    })
  }

  getImageSource(): string {
    const imageUrl = this.userForm.get('image_url')?.value;
    return imageUrl ? `${this.url}/User/file/gallery_users/${imageUrl}` : '';
  }

  validPhoneNumber: boolean = false;
  onInputChange(data: any) {
    const phoneNumber = data.phoneNumber;
    this.validPhoneNumber = data.isNumberValid;
    console.log(
      '%cregister.component.ts line:137 isNumberValid ',
      'color: #007acc;',
      data.isNumberValid
    );
    this.userForm.get('phone_number')?.setValue(phoneNumber);
  }
  handleKeyPress(event: any) {
    const allowedCharacters = /^[0-9+]+$/;

    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  }

  uploadFile(event: any): void {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    this.file = file;
    this.fileName = this.file.name;
    const allowedExtensions = [
      '.jpeg',
      '.jpg',
      '.png',
      '.gif',
      '.jfif',
      '.avif',
    ];
    const fileExtension = file.name
      .toLowerCase()
      .substr(file.name.lastIndexOf('.'));
    if (file) {
      this.isImageChanged = true;
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileExtention = fileTypeExtention;
        this.toastrService.error(
          "Le format du fichier n'est pas accepté.Veuillez choisir une photo"
        );
      } else if (file.size > maxSize) {
        this.fileError = maxSizeFile;
        this.toastrService.error(maxSizeFile);
      } else {
        this.fileError = '';
        this.fileExtention = '';
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(file);
      }
console.log("isImageChanged",this.isImageChanged)
    }
  }
  // uploadFile(event: any) {
  //   this.file = event.target.files[0];
  //   const maxSize = 2 * 1024 * 1024;
  //   this.fileName = this.file.name;
  //   if (this.file) {
  //     const allowedExtensions = ['.jpeg', '.jpg', '.png','.svg','.gif'];
  //     const fileExtension = this.file.name
  //       .toLowerCase()
  //       .substr(this.file.name.lastIndexOf('.'));
  //     if (!allowedExtensions.includes(fileExtension)) {
  //       this.fileExtention = fileTypeExtention;
  //     } else if (this.file.size > maxSize) {
  //       this.fileError = maxSizeFile;
  //     } else {
  //       this.fileError = '';
  //       this.fileExtention = '';
  //     }
  //   }
  //   this.userForm.markAsDirty();
  // }
  clearImage() {
    this.fileName = '';
    this.selectedImage = null;
    this.fileExtention = '';
    this.file = null;
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      // fileInput.value = ''; // Clear the file input value
      // this.userForm.get('image_url')?.setValue('avatar.png');
      this.image_url='avatar.png'
    }
    this.isImageChanged=false;
  }

  stateList!: IState[];
  paysList: ICountry[] = Country.getAllCountries().map((country) => ({
    ...country,
    name: this.translateCountryName(country.name),
  }));

  capitalizeFirstLetter(string: any) {
    if (!string) return string;

    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  onCountryChange(event: any): void {
    if (event) {
      this.stateList = State.getStatesOfCountry(event.isoCode);
      for (const iterator of this.stateList) {
        iterator.name = iterator.name.replace(' Governorate', '');
      }
    } else {
      this.stateList = [];
      this.userForm.get('city')?.setValue(null);
    }
  }

  modalRef?: BsModalRef;
  phoneForm: FormGroup = this.createFormPhone();
  createFormPhone(data?: any) {
    return this.formBuilder.group({
      phone: [data && data.phone, [Validators.required]],
    });
  }

  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      Afghanistan: 'Afghanistan',
      'South Africa': 'Afrique du Sud',
      Albania: 'Albanie',
      Algeria: 'Algérie',
      Germany: 'Allemagne',
      Andorra: 'Andorre',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctique',
      'Antigua and Barbuda': 'Antigua-et-Barbuda',
      'Saudi Arabia': 'Arabie saoudite',
      Argentina: 'Argentine',
      Armenia: 'Arménie',
      Aruba: 'Aruba',
      Australia: 'Australie',
      Austria: 'Autriche',
      Azerbaijan: 'Azerbaïdjan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahreïn',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbade',
      Belgium: 'Belgique',
      Belize: 'Belize',
      Benin: 'Bénin',
      Bermuda: 'Bermudes',
      Bhutan: 'Bhoutan',
      Belarus: 'Biélorussie',
      Bolivia: 'Bolivie',
      'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
      Botswana: 'Botswana',
      Brazil: 'Brésil',
      Brunei: 'Brunei',
      Bulgaria: 'Bulgarie',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      'Cape Verde': 'Cabo Verde',
      Cambodia: 'Cambodge',
      Cameroon: 'Cameroun',
      Canada: 'Canada',
      Chile: 'Chili',
      China: 'Chine',
      Cyprus: 'Chypre',
      Colombia: 'Colombie',
      Comoros: 'Comores',
      'Congo - Brazzaville': 'Congo-Brazzaville',
      'Congo - Kinshasa': 'Congo-Kinshasa',
      'North Korea': 'Corée du Nord',
      'South Korea': 'Corée du Sud',
      'Costa Rica': 'Costa Rica',
      'Ivory Coast': 'Côte d’Ivoire',
      Croatia: 'Croatie',
      Cuba: 'Cuba',
      Curaçao: 'Curaçao',
      Denmark: 'Danemark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominique',
      'Dominican Republic': 'République dominicaine',
      Ecuador: 'Équateur',
      Egypt: 'Égypte',
      'United Arab Emirates': 'Émirats arabes unis',
      Eritrea: 'Érythrée',
      Spain: 'Espagne',
      Eswatini: 'Eswatini',
      Estonia: 'Estonie',
      'United States': 'États-Unis',
      Ethiopia: 'Éthiopie',
      Fiji: 'Fidji',
      Finland: 'Finlande',
      France: 'France',
      Gabon: 'Gabon',
      Gambia: 'Gambie',
      Georgia: 'Géorgie',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Grèce',
      Grenada: 'Grenade',
      Greenland: 'Groenland',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernesey',
      Guinea: 'Guinée',
      'Guinea-Bissau': 'Guinée-Bissau',
      'Equatorial Guinea': 'Guinée équatoriale',
      Guyana: 'Guyana',
      'French Guiana': 'Guyane française',
      Haiti: 'Haïti',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Hongrie',
      Iceland: 'Islande',
      India: 'Inde',
      Indonesia: 'Indonésie',
      Iraq: 'Irak',
      Iran: 'Iran',
      Ireland: 'Irlande',
      'Isle of Man': 'Île de Man',
      Israel: 'Israël',
      Italy: 'Italie',
      Jamaica: 'Jamaïque',
      Japan: 'Japon',
      Jersey: 'Jersey',
      Jordan: 'Jordanie',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      Kuwait: 'Koweït',
      Kyrgyzstan: 'Kirghizistan',
      Laos: 'Laos',
      Latvia: 'Lettonie',
      Lebanon: 'Liban',
      Lesotho: 'Lesotho',
      Liberia: 'Libéria',
      Libya: 'Libye',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lituanie',
      Luxembourg: 'Luxembourg',
      Macao: 'Macao',
      'North Macedonia': 'Macédoine du Nord',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaisie',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malte',
      'Marshall Islands': 'Îles Marshall',
      Martinique: 'Martinique',
      Mauritania: 'Mauritanie',
      Mauritius: 'Maurice',
      Mayotte: 'Mayotte',
      Mexico: 'Mexique',
      Micronesia: 'Micronésie',
      Moldova: 'Moldavie',
      Monaco: 'Monaco',
      Mongolia: 'Mongolie',
      Montenegro: 'Monténégro',
      Montserrat: 'Montserrat',
      Morocco: 'Maroc',
      Mozambique: 'Mozambique',
      Myanmar: 'Myanmar',
      Namibia: 'Namibie',
      Nauru: 'Nauru',
      Nepal: 'Népal',
      Netherlands: 'Pays-Bas',
      'New Caledonia': 'Nouvelle-Calédonie',
      'New Zealand': 'Nouvelle-Zélande',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Île Norfolk',
      'Northern Mariana Islands': 'Îles Mariannes du Nord',
      Norway: 'Norvège',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palaos',
      Palestine: 'Palestine',
      Panama: 'Panama',
      'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
      Paraguay: 'Paraguay',
      Peru: 'Pérou',
      Philippines: 'Philippines',
      'Pitcairn Islands': 'Îles Pitcairn',
      Poland: 'Pologne',
      Portugal: 'Portugal',
      'Puerto Rico': 'Porto Rico',
      Qatar: 'Qatar',
      Romania: 'Roumanie',
      Russia: 'Russie',
      Rwanda: 'Rwanda',
      Réunion: 'Réunion',
      'Saint Barthélemy': 'Saint-Barthélemy',
      'Saint Helena': 'Sainte-Hélène',
      'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
      'Saint Lucia': 'Sainte-Lucie',
      'Saint Martin': 'Saint-Martin',
      'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
      'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
      Samoa: 'Samoa',
      'San Marino': 'Saint-Marin',
      'Sao Tome and Principe': 'Sao Tomé-et-Principe',
      Senegal: 'Sénégal',
      Serbia: 'Serbie',
      Seychelles: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapour',
      'Sint Maarten': 'Saint-Martin',
      Slovakia: 'Slovaquie',
      Slovenia: 'Slovénie',
      'Solomon Islands': 'Îles Salomon',
      Somalia: 'Somalie',
      'South Georgia and the South Sandwich Islands':
        'Géorgie du Sud-et-les Îles Sandwich du Sud',
      'South Sudan': 'Soudan du Sud',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Soudan',
      Suriname: 'Suriname',
      'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
      Swaziland: 'Eswatini',
      Sweden: 'Suède',
      Switzerland: 'Suisse',
      Syria: 'Syrie',
      Taiwan: 'Taïwan',
      Tajikistan: 'Tadjikistan',
      Tanzania: 'Tanzanie',
      Thailand: 'Thaïlande',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinité-et-Tobago',
      Tunisia: 'Tunisie',
      Turkey: 'Turquie',
      Turkmenistan: 'Turkménistan',
      Tuvalu: 'Tuvalu',
      Uganda: 'Ouganda',
      Ukraine: 'Ukraine',
      'United Kingdom': 'Royaume-Uni',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Ouzbékistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatican',
      Venezuela: 'Venezuela',
      Vietnam: 'Viêt Nam',
      'Western Sahara': 'Sahara occidental',
      Yemen: 'Yémen',
      Zambia: 'Zambie',
      Zimbabwe: 'Zimbabwe',
    };
    return translations[name] || name;
  }

  openModal(template: TemplateRef<any>, data?: any) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    this.userForm = this.createUserForm(data);
    if (data) {
      this.id = data.id;
      // Ajoutez cette ligne
      this.userForm = this.createUserForm(data);
      console.log('openmodaluser', this.userForm);
      this.userForm.get('first_name')?.setValue(data.first_name);
      console.log('fisttttttttt', this.first_name);
      this.userForm.get('city')?.setValue(data.city);
      console.log('cityyyyyyyyyyyyyyyy', this.userForm.value.city);
      this.userForm.get('image_url')?.setValue(data.image_url);
      this.image_url=data.image_url
      console.log('image', this.userForm.value.image_url);
      this.phoneForm
        .get('phone')
        ?.setValue(this.userForm.get('phone_number')?.value);
      if (this.file) this.formData.append('image_url', this.file);
    }
  }

  fileError: string = '';
  closeModal() {
    this.modalRef?.hide();
    // this.userForm.reset();
    this.formData = new FormData();
    this.file = null;
    this.fileError = '';
    this.fileName = '';
    this.fileExtention = '';
  }

  onSubmit() {

    const phone = this.phoneForm.get('phone')?.value;
    this.userForm.get('phone_number')?.setValue(phone?.internationalNumber);
    this.formData.append('first_name', this.userForm.get('first_name')?.value);
    this.formData.append('last_name', this.userForm.get('last_name')?.value);
    this.formData.append(
      'phone_number',
      this.userForm.get('phone_number')?.value
    );
    const cityValue = this.userForm.get('city')?.value;
    this.formData.append('city', cityValue !== null ? cityValue : '');
    this.formData.append('email', this.userForm.get('email')?.value);
    // this.userForm.get('image_url')?.setValue(this.file);
    console.log("file",this.file)
    if (this.file) {
      this.formData.append('image_url', this.file);
    }
    else{
      console.log("image_url", this.image_url)
      this.formData.append('image_url', this.image_url);
     }
    this.spinner.show()
    this.id = this.currentUser?.id
    console.log("formData is : ", this.formData.get("phone_number"))

    this.candidatService.updateCandidate(this.id, this.formData).pipe().subscribe({
      next: (res) => {
        this.spinner.hide()
        this.formData = new FormData()
        if (res.status == 200) {
          this.toastrService.success(successEditProfileadmin)
          this. getuserById()
          this.profileUpdatedSubject.next(true); // Mettre à jour le BehaviorSubject
          this.fileName = ''
          this.candidatService.setAccount(this.formData);
          this.getuserById()
          this.closeModal()
          // this.subscribeToProfileUpdates();
          this.spinner.hide()
    
        }
      },
      error: (err) => {
        console.log("error of ",err)
        this.spinner.hide()
        // this.formData = new FormData()
        if (err?.error) {
          if (err.error?.message['email']) this.toastrService.error(emailExist)
          else if (err.error?.message['phone_number']) this.toastrService.error(phoneNumberExist)
          else this.toastrService.error(serverError)
        }
      }
    })
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
