import { Injectable } from '@angular/core';
import { CrudService } from '../crud/crud.service';
import { ApiResponse } from '../../models/response-api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LineActivityCraService extends CrudService<
  ApiResponse<any>,
  number
> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get list activities cra */
  getListCra(data?: {
    per_page?: number;
    page?: number;
    sort?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/TypeOfCra/getAll?per_page=${data?.per_page}&page=${data?.page}`
    );
  }
  /* add activity cra  */
  addCra(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/TypeOfCra/add`,
      data
    );
  }
  /* update activity cra */
  updateCra(idCra: number, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/TypeOfCra/update/${idCra}`,
      data
    );
  }
  /* delete activity cra */
  deleteCra(idCra: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/TypeOfCra/delete/${idCra}`
    );
  }
  /* search activity cra */
  searchCra(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/TypeOfCra/search`,
      data
    );
  }
}
