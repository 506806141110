import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from './crud/crud.service';
import { ApiResponse } from '../models/response-api';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PageActorService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get list of pages by role */
  listPageActor(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Page/getPagesWithSubPages?interface=${id}&role_id=${id}`
    );
  }
  /* change Sub Page State */
  changeSubPageState(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/PageActeur/addRemoveSubPageToActeur`,
      data
    );
  }
}
