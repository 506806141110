<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
        <!--begin::Toolbar container-->
        <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <!--begin::Title-->
             <div class="d-flex align-items-center">
                <h1 class="page-heading"><svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_138_53)">
                    <path d="M8.93572 6.21891L4.29275 3.10518C4.23449 3.06604 4.16094 3.06357 4.10021 3.09527C4.03752 3.12846 4 3.19236 4 3.2627V8.92349C4 8.9864 4.03209 9.04485 4.08343 9.07854L8.7274 12.1943C8.758 12.2146 8.79601 12.226 8.83156 12.226C8.86217 12.226 8.89277 12.219 8.92042 12.2037C8.98164 12.17 9.02063 12.1061 9.02063 12.0367V6.37643C9.01915 6.31352 8.98855 6.25408 8.93572 6.21891Z" fill="#265D91"/>
                    <path d="M14.5163 16.9783L9.87331 13.8646C9.81506 13.8259 9.7415 13.8215 9.68078 13.8552C9.61957 13.8869 9.58057 13.9503 9.58057 14.0206V19.6829C9.58057 19.7458 9.61265 19.8042 9.664 19.8389L14.308 22.9541C14.3386 22.9744 14.3766 22.9853 14.4121 22.9853C14.4427 22.9853 14.4733 22.9769 14.501 22.9631C14.5622 22.9299 14.5997 22.866 14.5997 22.7961V17.1358C14.5997 17.0734 14.5691 17.013 14.5163 16.9783Z" fill="#265D91"/>
                    <path d="M25.6627 3.50144C25.6029 3.46825 25.5308 3.46973 25.4726 3.50441L20.8281 6.3358C20.7728 6.36998 20.7383 6.4314 20.7383 6.49679V12.4424C20.7383 12.5118 20.7768 12.5757 20.838 12.6069C20.8656 12.6222 20.8963 12.6301 20.9269 12.6301C20.9629 12.6301 21.0004 12.6197 21.0305 12.5989L25.675 9.48372C25.7263 9.44904 25.7584 9.39059 25.7584 9.32669V3.66589C25.7584 3.59704 25.7219 3.53463 25.6627 3.50144Z" fill="#265D91"/>
                    <path d="M14.1867 2.78963C14.1882 2.72177 14.1531 2.65737 14.0939 2.6227L9.73624 0.0265969C9.68243 -0.00708651 9.6143 -0.00807717 9.55704 0.0196621L4.69684 2.4414C4.63563 2.47261 4.59564 2.53403 4.59268 2.60189C4.58823 2.66827 4.6218 2.73415 4.67858 2.76982L9.0219 5.54275C9.0525 5.56207 9.08706 5.57248 9.1231 5.57248C9.15222 5.57248 9.18283 5.56504 9.21048 5.55018L14.0865 2.95309C14.1462 2.9199 14.1852 2.857 14.1867 2.78963Z" fill="#265D91"/>
                    <path d="M25.2596 2.99965C25.2581 2.93129 25.2211 2.86789 25.1599 2.83668L20.5707 0.379774C20.5154 0.350053 20.4488 0.350054 20.3935 0.38126L15.8487 2.85105C15.789 2.88374 15.7505 2.94665 15.7505 3.01699C15.7505 3.08485 15.7875 3.14925 15.8472 3.18243L20.4616 5.76566C20.4907 5.78101 20.5223 5.78943 20.5529 5.78943C20.586 5.78943 20.6181 5.78101 20.6472 5.76417L25.1653 3.16708C25.2255 3.13191 25.2611 3.069 25.2596 2.99965Z" fill="#265D91"/>
                    <path d="M20.0772 13.8551C20.0164 13.8215 19.9429 13.8254 19.8841 13.8645L15.2417 16.9783C15.1888 17.0135 15.1567 17.0734 15.1567 17.1358V22.7966C15.1567 22.8664 15.1957 22.9303 15.2565 22.9635C15.2846 22.9774 15.3147 22.9863 15.3453 22.9863C15.3819 22.9863 15.4189 22.9744 15.4495 22.9546L20.0939 19.8394C20.1453 19.8047 20.1774 19.7463 20.1774 19.6834V14.0216C20.1774 13.9512 20.1384 13.8873 20.0772 13.8551Z" fill="#265D91"/>
                    <path d="M19.7028 13.3603C19.7042 13.2924 19.6692 13.228 19.6099 13.1934L15.0845 10.2718C15.0306 10.2391 14.9625 10.2376 14.9057 10.2654L9.99273 13.1364C9.93201 13.1676 9.89153 13.229 9.88906 13.2969C9.88462 13.3638 9.91819 13.4292 9.97496 13.4653L14.7172 16.5815C14.7478 16.6008 14.7818 16.6108 14.8184 16.6108C14.8475 16.6108 14.8781 16.6038 14.9057 16.5885L19.6025 13.5233C19.6623 13.4911 19.7013 13.4282 19.7028 13.3603Z" fill="#265D91"/>
                    <path d="M14.6466 3.50144C14.5869 3.46825 14.5148 3.46973 14.4566 3.50441L9.66993 6.08862C9.61464 6.1228 9.58008 6.18373 9.58008 6.24961V12.1952C9.58008 12.2646 9.61859 12.3285 9.6798 12.3602C9.70745 12.375 9.73805 12.383 9.76866 12.383C9.8047 12.383 9.84173 12.3726 9.87233 12.3518L14.7538 9.68631C14.8051 9.65164 14.8372 9.59319 14.8372 9.52879L14.7429 3.66589C14.7429 3.59704 14.7069 3.53463 14.6466 3.50144Z" fill="#265D91"/>
                    <path d="M20.1305 6.18869L15.4885 3.46528C15.4303 3.42664 15.3572 3.42169 15.296 3.45537C15.2348 3.48757 15.1963 3.55098 15.1963 3.62082L15.2481 9.49263C15.2481 9.55554 15.2802 9.6135 15.3311 9.64817L19.98 12.4949C20.0106 12.5152 20.0486 12.5266 20.0841 12.5266C20.1147 12.5266 20.1453 12.5177 20.173 12.5043C20.2342 12.4706 20.2717 12.4072 20.2717 12.3369V6.46757C20.2717 6.40466 20.1834 6.22287 20.1305 6.18869Z" fill="#265D91"/>
                    </g>
                    <defs>
                    <filter id="filter0_d_138_53" x="0" y="0" width="29.7583" height="30.9863" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_138_53"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_138_53" result="shape"/>
                    </filter>
                    </defs>
                    </svg>

                     Entreprises
                </h1>

             </div>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted">
                        <a  class="text-muted"  routerLink="/">Accueil</a>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-400 w-5px h-2px"></span>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted"> <a routerLink="/acceuil/entreprises/list-entreprise" class="text-muted">Entreprises</a></li>
                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-400 w-5px h-2px"></span>
                    </li>
                    <li class="breadcrumb-item text-muted"><a routerLink="/acceuil/entreprises/esn/list-esn" class="text-muted">ESN</a></li>
                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        <li class="breadcrumb-item text-muted">Détail consultant</li>

                    <!--end::Item-->
                </ul>
                <!--end::Breadcrumb-->
            </div>
            <!--end::Page title-->
            <!--begin::Actions-->

            <!--end::Actions-->
        </div>
        <!--end::Toolbar container-->
    </div>
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card mb-10">
           <div class="header-page">
            <div class="title">
                <h4>Consultant: {{consultName}}</h4>
            </div>
            <div class="edit cursor-pointer">
              <a data-bs-toggle="tooltip" data-bs-placement="top" title="Voir dossier des compétences" (click)="navigateToProfile()">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="29" viewBox="0 0 32 29" fill="none">
                  <g filter="url(#filter0_d_126_262)">
                    <path d="M21.795 7.11531C19.8257 7.11531 18.2199 5.51738 18.2199 3.55766C18.2199 1.59793 19.8257 0 21.795 0C23.7643 0 25.3701 1.59793 25.3701 3.55766C25.3701 5.51738 23.7643 7.11531 21.795 7.11531ZM16.3112 4.58274C16.3112 2.05017 14.2509 0.0301492 11.7363 0.0301492C9.19127 0.0301492 7.16135 2.08032 7.16135 4.58274C7.16135 7.11531 9.22157 9.13533 11.7363 9.13533C14.2812 9.13533 16.3112 7.08516 16.3112 4.58274ZM27.0667 15.9491C27.5212 15.9491 27.8848 15.5572 27.8242 15.105C27.3394 11.6076 25.8245 8.1404 21.795 8.1404C19.5227 8.1404 18.0381 9.25593 17.0989 10.8539C17.7654 11.4267 18.3714 12.09 18.8561 12.904C19.4015 13.7784 19.8863 14.8035 20.2498 15.9793H27.0667V15.9491ZM4.01041 19.2053C3.91952 19.8686 4.43457 20.5017 5.13142 20.5017H18.3411C19.0379 20.5017 19.553 19.8987 19.4621 19.2053C18.7956 14.8035 16.8565 10.4619 11.7363 10.4619C6.61599 10.4619 4.67695 14.8035 4.01041 19.2053Z" fill="#265D91"/>
                  </g>
                  <defs>
                    <filter id="filter0_d_126_262" x="0" y="0" width="31.8311" height="28.5017" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="out"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_126_262"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_126_262" result="shape"/>
                    </filter>
                  </defs>
                </svg>
              </a>
              <!-- <a (click)="enableDisableMode()">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_216_615)">
                    <path d="M4 15.6558V5.59137C4 4.9982 4.23707 4.42933 4.65906 4.00989C5.08106 3.59046 5.6534 3.35482 6.25019 3.35482H13.1301L10.9165 5.59137H6.25019V15.6558H16.376V11.0206L18.6262 8.82039V15.6558C18.6262 16.249 18.3891 16.8179 17.9671 17.2373C17.5451 17.6567 16.9728 17.8924 16.376 17.8924H6.25019C5.6534 17.8924 5.08106 17.6567 4.65906 17.2373C4.23707 16.8179 4 16.249 4 15.6558ZM20.3138 0C19.8664 0.000391512 19.4374 0.177375 19.1212 0.492041L11.1246 8.56878C10.8786 8.8174 10.6929 9.11861 10.5818 9.44942L9.77173 11.8677C9.76161 11.9064 9.76183 11.947 9.77238 11.9855C9.78293 12.0241 9.80343 12.0592 9.83185 12.0875C9.86028 12.1157 9.89564 12.1361 9.93443 12.1466C9.97322 12.1571 10.0141 12.1573 10.053 12.1473L12.4944 11.3505C12.8273 11.2401 13.1304 11.0556 13.3805 10.8109L21.5064 2.86278C21.7422 2.6282 21.9027 2.32944 21.9677 2.00425C22.0326 1.67906 21.9992 1.34202 21.8716 1.03569C21.744 0.729368 21.5279 0.4675 21.2507 0.283164C20.9734 0.098828 20.6474 0.000291618 20.3138 0Z" fill="#265D91"/>
                    </g>
                    <defs>
                    <filter id="filter0_d_216_615" x="0" y="0" width="26" height="25.8924" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_216_615"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_216_615" result="shape"/>
                    </filter>
                    </defs>
                    </svg>
                  </a> -->

            </div>
           </div>
            <!--begin::Card header-->

            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0" [formGroup]="esnForm">
                <!--begin::Table-->
              <div class="container search-bar">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="id" class="required form-label">ID</label>
                            <input formControlName="ID_user" [readonly]="true" type="text" class="form-control no-cursor" placeholder="55625145" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="id" class="required form-label">Nom </label>
                            <input [readonly]="true" formControlName="first_name" type="text" class="form-control no-cursor" placeholder="nom"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="id" class="required form-label">Prénom</label>
                            <input [readonly]="true" formControlName="last_name" type="text" class="form-control no-cursor" placeholder="prénom"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="id" class=" form-label">Email Personnel</label>
                            <input [readonly]="true" formControlName="email" type="text" class="form-control no-cursor" 
                            placeholder="email personnel"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="id" class=" form-label">Email Professionnel</label>
                            <input [readonly]="true" type="text" formControlName="emailProESN"
                             class="form-control no-cursor" placeholder="email professionnel"/>
                             <app-feild-error class="mt-3"[controlName]="'email'" [control]="esnForm.get('emailProESN')"></app-feild-error>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="esn" class=" form-label">ESN</label>
                            <input [readonly]="true" formControlName="ESN_name" type="text" class="form-control no-cursor" placeholder="ESN"/>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="responsable" class="form-label">Responsable</label>
                            <input [readonly]="true" type="text" class="form-control" 
                            placeholder="responsable"/>
                        </div>
                    </div> -->
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="client" class=" form-label">Client</label>
                            <input [readonly]="true" type="text" class="form-control no-cursor" formControlName="client_name" placeholder="client"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="date" class=" form-label">Date début contrat</label>
                            <input [readonly]="true" formControlName="contract_start_date" type="date" class="form-control no-cursor">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="date" class="form-label">Date début mission</label>
                            <input [readonly]="true" type="date" formControlName="mission_start_date" class="form-control no-cursor">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="date" class=" form-label">Date fin mission</label>
                            <input [readonly]="true" formControlName="mission_end_date" type="date" class="form-control no-cursor">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-10">
                            <label for="cv" class="form-label">
                                CV 
                                 <!-- <span class="downloadCv" (click)="downloadFile()" *ngIf="(selectedCandidate?.cv && selectedCandidate?.cv !== 'null')"><i class="fa-solid fa-link" style="color:#265D91;"></i> {{selectedCandidate?.cv}} </span> -->
                              </label>
                            <!-- <input  formControlName="cv" #inputFile type="file"  name="cv" id="cv"
                            (change)="uploadFile($event)" class="form-control cv mb-3" > -->
                            <input  [readonly]="true" formControlName="cv" #inputFile type="text"  name="cv" id="cv"
                            (change)="uploadFile($event)" class="form-control no-cursor" placeholder="cv">
                            <span style="color:red" *ngIf="fileError"><small>{{fileError}} </small></span>
                            <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}} </small></span>
                        </div>
                    </div>
                    <div class="col-lg-6 ">
                      <div class="position-relative mb-10">
                        <label for="mobilite" class=" form-label">Pays</label>
                        <ng-select (change)="onCountryChange($event)" [multiple]="true" [items]="paysList"
                        formControlName="destination_country" id="pays_destinataire"
                        bindLabel="name" bindValue="name" [placeholder]="'Pays'"
                        autoComplete [notFoundText]="'Aucun élément trouvé'"  [readonly]="true">
                    </ng-select>
                    </div>
                      </div>
                    <div class="col-lg-6 ">
                      <div class="position-relative mb-10">
                        <label for="mobilite" class=" form-label">Mobilité</label>
                         <ng-select #state [items]="stateList" [multiple]="true" id="mobilite"
                         formControlName="mobility" bindLabel="name" bindValue="name"
                         [placeholder]="'Mobilité'" [notFoundText]="'Aucun élément trouvé'" [readonly]="true">
                     </ng-select>
                    </div>
                      </div>
                    <div class="col-lg-6">
                        <div class="position-relative mb-10">
                            <label for="salaire" class=" form-label" >Salaire</label>
                           <input [readonly]="true" type="text" (keypress)="keyPress($event)" formControlName="desired_salary" name="salaire" id="salire" class="form-control no-cursor" placeholder="2300 euros" readonly>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="position-relative mb-10">
                            <label for="tjm" class=" form-label">TJM (achat)</label>
                           <input [readonly]="true" type="text" (keypress)="keyPress($event)" formControlName="tjm" name="tjm" id="tjmachat" class="form-control no-cursor" placeholder="TJM achat" readonly>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="position-relative mb-10">
                            <label for="tjm" class=" form-label">TJM (vente)</label>
                           <input [readonly]="true" type="text" (keypress)="keyPress($event)" formControlName="tjm" name="tjm" id="tjm" class="form-control no-cursor" placeholder="TJM vente" readonly>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="position-relative mb-10">
                            <label for="mobilite" class="form-label">Comptétences</label>
                            <!-- bindLabel="name_FR" bindValue="id" [items]="skillsList" -->
                            <ng-select #state  [multiple]="true" id="mobilite"
                         formControlName="skills" 
                         [placeholder]="'Compétences'" [notFoundText]="'Aucun élément trouvé'" [readonly]="true"
                         >
                     </ng-select>
                        </div>
                    </div>
                    <!-- <div class="save-btn mt-5 confirmer">
                        <button  class="btn save" [disabled]="fileExtention || fileError || !esnForm.valid" (click)="updateCunsultant()">Consultant:</button>
                    </div> -->
                </div>
              </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>


        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Customers - Add-->

        <!--end::Modal - Customers - Add-->
        <!--begin::Modal - Adjust Balance-->

        <!--end::Modal - New Card-->
        <!--end::Modals-->
    </div>
    <!--end::Content container-->
</div>
