import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  CSearchCandidate,
  ISort,
} from '../../../../../../core/models/candidat';
import {
  addedIntervenant,
  associatedIntervenant,
  associatedIntervenant1,
  associatedIntervenant2,
  confirmDelete,
  emailExist,
  emailExistpro,
  existedIntervenant,
  existedManager,
  failedDelete,
  personalEmailExist,
  phoneNumberExist,
  serverError,
  successDelete,
  updatedIntervenant,
} from '../../../../../../core/models/messages';
import { patternEmail } from '../../../../../../core/models/pattern';
import { CompanyService } from '../../../../../../core/services/company/company.service';
import { PermissionService } from '../../../../../../core/services/permission.service';
import { SortDataService } from '../../../../../../core/services/sort-data/sort-data.service';
import { environment } from '../../../../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-intervent',
  templateUrl: './list-intervent.component.html',
  styleUrls: ['./list-intervent.component.css'],
})
export class ListInterventComponent {
  /* string */
  sortAttr: string = '';
  mode: string = 'create';
  intervenantId!: string;
  searchElement: string = '';
  url: string = environment.baseUrl + '/api';
  /* formGroup */
  phoneForm: FormGroup = this.createFormPhone();
  intervenantForm: FormGroup = this.createIntervenantForm();
  /* form group */
  searchFormGroup: FormGroup = this.createSearchForm(new CSearchCandidate());
  /* number */
  idCompany!: number;
  currentSortDirection: number = 2;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  clickCount: number = 0;
  idUser!: number;
  /* any */
  listRole: { id: number; name: string }[] = [];
  listIntervenant: any = [];
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  separateDialCode: boolean = false;
  pagination : boolean =false;

  exist: boolean = false;
  /* modal */
  modalRef?: BsModalRef;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private sortDataService: SortDataService,
    private permissionService: PermissionService,
    private router: Router
  ) {}
  ngOnInit() {
    this.getIdCompany();
    this.getListRole();
  }
  /* create search form */
  createIntervenantForm(data?: any) {
    return this.formBuilder.group({
      first_name: [data && data.first_name, [Validators.required]],
      last_name: [data && data.last_name, [Validators.required]],
      email: [
        data && data.email,
        [Validators.required, Validators.pattern(patternEmail)],
      ],
      emailProESN: [
        data && data.emailProESN,
        [Validators.pattern(patternEmail)],
      ],
      phone_number: [data ? data.phone_number : ''],
      employee_role_id: [data && data.employee_role_id, [Validators.required]],
      company_id: [''],
      employee_id: [''],
    });
  }
  disableFormFields() {
    if (this.intervenantForm) {
      this.intervenantForm.get('first_name')?.disable();
      this.intervenantForm.get('last_name')?.disable();
      this.intervenantForm.get('phone_number')?.disable();
      // this.intervenantForm.get('email')?.disable();
    }
  }
  disableFormFieldsupdate() {
    if (this.intervenantForm) {
      this.intervenantForm.get('first_name')?.disable();
      this.intervenantForm.get('last_name')?.disable();
      // this.intervenantForm.get('phone_number')?.disable();
      // this.intervenantForm.get('email')?.disable();
    }
  }
  enableFormFields() {
    if (this.intervenantForm) {
      this.intervenantForm.get('first_name')?.enable();
      this.intervenantForm.get('last_name')?.enable();
      this.intervenantForm.get('phone_number')?.enable();
      this.intervenantForm.get('email')?.enable();
    }
  }

  getListRole() {
    this.permissionService
      .getListRoleByCompany(this.idCompany)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listRole = res.data;
        },
        error: () => {},
      });
  }
  private isFilterByEmailSubmission: boolean = false;

filterByEmail(event:any){
  console.log('searchEmail',event.target.value);
  console.log('idCompany',this.idCompany);
  let data={id:this.idCompany,email:event.target.value}
  this.isFilterByEmailSubmission = true;
  console.log("data",data)
  if(data.email==="" && this.intervenantForm.get('email')!.valid){
    this.intervenantForm.get('first_name')?.setValue('')
     this.intervenantForm.get('last_name')?.setValue('')
     this.intervenantForm.get('emailProESN')?.setValue('')
     this.phoneForm.get('phone')?.setValue('')
  }
  this.permissionService.getintervenant(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next:(res)=>{
      console.log("response",res)
     if(res.status==200){
       this.intervenantForm= this.createIntervenantForm(res.data[0])
      this.phoneForm.get('phone')?.setValue(this.intervenantForm.get('phone_number')?.value)
      this.idUser=res.data[0].id
     console.log('this.intervenantForm',this.intervenantForm);
     console.log('this.phoneForm',this.phoneForm);
     console.log('idUser',this.idUser);
     this.exist=true
     console.log("exist",this.exist)
     if (this.phoneForm.get('phone')) {
      this.phoneForm.get('phone')?.disable();
    }
     this.disableFormFields();
     }
    
    },
    error:(error)=>{
      console.log(error)
      if(error.error.message==="User not found" ){
        this.exist=false
        console.log("existe",this.exist)
        this.intervenantForm.get('first_name')?.enable();
        this.intervenantForm.get('last_name')?.enable();
        // this.intervenantForm.get('first_name')?.setValue('')
        // this.intervenantForm.get('last_name')?.setValue('')
        this.intervenantForm.get('emailProESN')?.enable();
        // this.intervenantForm.get('emailProESN')?.setValue('')

        this.phoneForm.get('phone')?.enable();
        // this.phoneForm.get('phone')?.setValue('')


      }
    }
  })
}
createFormPhone(data?:any){
  return this.formBuilder.group({
    // phone: new FormControl('', [Validators.required]),
    phone: [ data && data.phone,[Validators.required] ],
  });
}
/* get company id from route */
getIdCompany() {
  this.activatedRoute.paramMap.subscribe({
    next: (params: any) => {
      this.idCompany = params.params['id']
      this.getListIntervenant()
    }
  })
}
/* get indexes for pagination */
getItems(event?: any){    
 if(event && event.startIndex!=this.startIndex||this.endIndex!=event.endIndex||
  this.itemsPerPage!=event.itemsPerPage||
  this.currentPage!=event.currentPage) {
  this.startIndex=event.startIndex
  this.endIndex=event.endIndex
  this.itemsPerPage=event.itemsPerPage,
  this.currentPage=event.currentPage
  if(this.searchElement !='') this.applyFilter() 
  else   if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
    this.pagination = true
    this.getListIntervenant() 
  }
}
}
/* sort data  */
sortData(sortAttribute:string){
  this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
  this.sortAttr=sortAttribute
  this.currentPage = 1;
  this.endIndex=5;
  if(this.searchElement !='') this.listIntervenant = this.sortDataService.sortArray(this.listIntervenant, sortAttribute, this.currentSortDirection)
  else this.getListIntervenant()
  
}
/* list list esn companies */
getListIntervenant(sort?:ISort){
 this.spinner.show()
   let dataPyload =this.sortAttr==''? {per_page: this.itemsPerPage, page: this.currentPage,type:2}:{ ...{per_page: this.itemsPerPage, page: this.currentPage}, ...{sort:this.currentSortDirection,sortAttribute:this.sortAttr,type:2} }
this.companyService.getIntervenantList(this.idCompany,dataPyload).pipe(takeUntil(this.unsubscribeAll)).subscribe({
 next:(res)=>{
   this.spinner.hide()
   this.searchElement=''
   if(!res.data.data.length && this.currentPage>1){
    this.currentPage=1
    this.getListIntervenant()
   }else{
    this.listIntervenant=res.data.data
   this.totalItems=res.data.total
   console.log("list intervenant", this.listIntervenant)
   }
 },
 error:()=>{
   this.spinner.hide()

 }
})
}
/* delete intervenant pop up confirm or cancel */
swallDeleteCat(intervenant: { first_name: string,last_name: string, id: number }) {
  Swal.fire({
    title: `${confirmDelete} l'intervenant ${intervenant.first_name} ${intervenant.last_name}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Supprimer',
    cancelButtonText: 'Annuler'
  }).then((result) => {

    if (result.isConfirmed) {
      this.deleteIntervenant(intervenant.id)
    } else if (result.isDismissed) {
      Swal.fire(failedDelete, '', 'error')
    }
  })
}
/* delete intervenant */
deleteIntervenant(id:number){
    this.spinner.show()
    this.companyService.deleteIntervenant(id,this.idCompany).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        if (res.status == 200) {
          this.getListIntervenant()
          this.spinner.hide()
          this.toastr.success(successDelete)
        }
      },
      error: () => {
        this.spinner.hide()
        this.toastr.error(serverError)
      }
    })
}
openModal(template: TemplateRef<any>,mode:string,intervenant?:any) {
  this.mode=mode
  if(mode=='update' || mode =='details'){
    this.intervenantId=intervenant.id
    this.intervenantForm=this.createIntervenantForm(intervenant)
    this.phoneForm.get('phone')?.setValue(this.intervenantForm.get('phone_number')?.value)
 this.disableFormFieldsupdate()
 if (this.phoneForm.get('phone')) {
  this.phoneForm.get('phone')?.disable();
}
  }
  if(mode =='details'){
    this.intervenantForm.disable()
    this.phoneForm.disable()
  }
  if(mode =='create'){
    this.phoneForm.enable()
    this.intervenantForm.reset();
  }
  this.modalRef = this.modalService.show(template,{backdrop: 'static'});
}
closeModal() {
  this.modalRef?.hide();
  this.intervenantForm.reset()
  this.phoneForm.reset()
  this.enableFormFields()
  this.isFilterByEmailSubmission = false;
}

  /* add / update intervenant */
  onSubmit() {
    if (this.mode == 'create') {
      this.addInterveanant();
    } else {
      this.updateIntervenant();
    }
  }

  validPhoneNumber: boolean = false;

  onInputChange(data: any) {
    const phoneNumber = data.phoneNumber;
    this.validPhoneNumber = data.isNumberValid;
    console.log(
      '%cregister.component.ts line:137 isNumberValid ',
      'color: #007acc;',
      data.isNumberValid
    );
    this.intervenantForm.get('phone_number')?.setValue(phoneNumber);
  }
  handleKeyPress(event: any) {
    const allowedCharacters = /^[0-9+]+$/;

    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  }

  addInterveanant() {
    const phone = this.phoneForm.get('phone')?.value;
    this.intervenantForm
      .get('phone_number')
      ?.setValue(phone?.internationalNumber);
    this.intervenantForm.get('company_id')?.setValue(this.idCompany);
    this.spinner.show();

    let payloadData;
    console.log('exist', this.exist);
    if (this.exist) {
      console.log('exist if', this.exist);
      payloadData = {
        user_id: this.idUser,
        emailProESN: this.intervenantForm.value.emailProESN || '',
        employee_role_id: this.intervenantForm.value.employee_role_id || '',
        company_id: this.idCompany,
      };
    } else {
      console.log('exist else', this.exist);
      payloadData = this.intervenantForm.value;
    }

    this.intervenantForm.get('email')?.enable();
    if (this.phoneForm.get('phone')) {
      this.phoneForm.get('phone')?.enable();
    }
    this.enableFormFields();
    console.log('data', payloadData);
    this.companyService
      .createIntervenant(payloadData)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.closeModal();
          this.spinner.hide();
          this.getListIntervenant();
          this.toastr.success(addedIntervenant);
          this.closeModal();
          this.intervenantForm.reset();
        },
        error: (err) => {
          console.log('erreuemploy', err);
          this.spinner.hide();
          let errorMessage = '';

          if (err?.error?.message) {
            const emailErrorMessage = err.error.message.email;

            if (
              emailErrorMessage &&
              emailErrorMessage[0] === 'The email has already been taken.'
            ) {
              console.log('Dans la condition');
              errorMessage = associatedIntervenant1;
            } else if (err.error.message['emailProESN']) {
              errorMessage = emailExistpro;
            } else if (err.error.message === 'email already exsist') {
              errorMessage = personalEmailExist;
            } else if (err.error.message['email']) {
              errorMessage = personalEmailExist;
            } else if (err.error.message['phone_number']) {
              errorMessage = phoneNumberExist;
            } else if (err.error.message['name']) {
              errorMessage = existedIntervenant;
            } else if (
              err.error.message.includes('Company already has a responsable')
            ) {
              errorMessage = existedManager;
            } else if (
              err.error.message.includes(
                'employee already associated to another company.'
              )
            ) {
              console.log('Dans la condition');
              errorMessage = associatedIntervenant;
            } else if (
              err.error.message.includes(
                'employee already associated to this company.'
              )
            ) {
              console.log('Dans la conditionfvwdvgf');
              errorMessage = associatedIntervenant2;
            }

            // Show Toastr error message
            if (errorMessage) {
              this.toastr.info(errorMessage);
            }
          }
        },
      });
  }
  // addInterveanant() {
  //   const phone = this.phoneForm.get('phone')?.value;
  //   this.intervenantForm.get('phone_number')?.setValue(phone?.internationalNumber);
  //   this.intervenantForm.get('company_id')?.setValue(this.idCompany);
  //   this.spinner.show();

  //   let payloadData;
  //   if (this.idUser) {
  //     payloadData = {
  //       user_id: this.idUser,
  //       emailProESN: this.intervenantForm.value.emailProESN || '',
  //       employee_role_id: this.intervenantForm.value.employee_role_id || '',
  //       company_id: this.idCompany
  //     };
  //   } else {
  //     payloadData = this.intervenantForm.value;
  //   }

  //   this.intervenantForm.get('email')?.enable();
  //   if (this.phoneForm.get('phone')) {
  //     this.phoneForm.get('phone')?.enable();
  //   }
  //   this.enableFormFields();
  //   this.companyService.createIntervenant(payloadData).pipe(takeUntil(this.unsubscribeAll)).subscribe({
  //     next: (res) => {
  //       this.closeModal();
  //       this.spinner.hide();
  //       this.getListIntervenant();
  //       this.toastr.success(addedIntervenant);
  //       this.closeModal();
  //       this.intervenantForm.reset();
  //     },
  //     error: (err) => {
  //       console.log("erreuemploy", err);
  //       this.spinner.hide();
  //       let errorMessage = '';

  //       if (err?.error?.message) {
  //         const emailErrorMessage = err.error.message.email;

  //         if (emailErrorMessage && emailErrorMessage[0] === "The email has already been taken.") {
  //           console.log("Dans la condition");
  //           errorMessage = associatedIntervenant1;
  //         } else if (err.error.message['emailProESN']) {
  //           errorMessage = emailExistpro;
  //         } else if (err.error.message==="email already exsist") {
  //           errorMessage = personalEmailExist;
  //         } else if (err.error.message['email']) {
  //           errorMessage = personalEmailExist;
  //         } else if (err.error.message['phone_number']) {
  //           errorMessage = phoneNumberExist;
  //         } else if (err.error.message['name']) {
  //           errorMessage = existedIntervenant;
  //         } else if (err.error.message.includes("Company already has a responsable")) {
  //           errorMessage = existedManager;
  //         } else if (err.error.message.includes('employee already associated to another company.')) {
  //           console.log("Dans la condition");
  //           errorMessage = associatedIntervenant;
  //         } else if (err.error.message.includes('employee already associated to this company.')) {
  //           console.log("Dans la conditionfvwdvgf");
  //           errorMessage = associatedIntervenant2;
  //         }

  //         // Show Toastr error message
  //         if (errorMessage) {
  //           this.toastr.info(errorMessage);
  //         }
  //       }
  //     }
  //   });
  // }

  updateIntervenant() {
    this.intervenantForm.get('employee_id')?.setValue(this.intervenantId);
    const phone = this.phoneForm.get('phone')?.value;
    this.intervenantForm
      .get('phone_number')
      ?.setValue(phone?.internationalNumber);

    if (this.intervenantForm.value.email == '')
      delete this.intervenantForm.value.email;
    this.spinner.show();

    this.companyService
      .updateIntervenant(this.idCompany, this.intervenantForm.value)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.closeModal();
          this.spinner.hide();

          this.getListIntervenant();
          this.toastr.success(updatedIntervenant);
          this.closeModal();
          this.intervenantForm.reset();
        },
        error: (err) => {
          console.log('erreuemploy', err);
          this.spinner.hide();
          let errorMessage = '';

          if (err?.error?.message) {
            const emailErrorMessage = err.error.message.email;

            if (
              emailErrorMessage &&
              emailErrorMessage[0] === 'The email has already been taken.'
            ) {
              console.log('Dans la condition');
              errorMessage = associatedIntervenant1;
            } else if (err.error.message['emailProESN']) {
              errorMessage = emailExist;
            } else if (err.error.message['email']) {
              errorMessage = personalEmailExist;
            } else if (err.error.message['phone_number']) {
              errorMessage = phoneNumberExist;
            } else if (err.error.message['name']) {
              errorMessage = existedIntervenant;
            } else if (
              err.error.message.includes('Company already has a responsable')
            ) {
              errorMessage = existedManager;
            } else if (
              err.error.message.includes(
                'employee already associated to another company.'
              )
            ) {
              console.log('Dans la condition');
              errorMessage = associatedIntervenant;
            } else if (
              err.error.message.includes(
                'employee already associated to this company.'
              )
            ) {
              console.log('Dans la conditionfvwdvgf');
              errorMessage = associatedIntervenant2;
            }

            // Show Toastr error message
            if (errorMessage) {
              this.toastr.info(errorMessage);
            }
          }
        },
      });
  }
  /* create search form */
  createSearchForm(data?: CSearchCandidate) {
    return this.formBuilder.group({
      name: ['', data?.name],
      role: ['', data?.role],
    });
  }
  /* search intervenant on input */
  applyFilter() {
    let data = {
      name: this.searchFormGroup.value.name,
      role: this.searchFormGroup.value.role,
    };
    this.spinner.show();
    this.companyService
      .searchInterveanant(this.idCompany, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          if (res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.applyFilter();
          } else {
            this.listIntervenant = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  getRoleName(employeeRoleId: number): string {
    const role = this.listRole.find((role) => role.id === employeeRoleId);
    return role ? role.name : '---';
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.searchFormGroup = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();

      this.getListIntervenant();
    }
    return (this.isRecherche = false);
  }
  /* get phone number */
  changePhoneNumber(event: any) {
    if (event.phoneNumber)
      this.intervenantForm.get('phone_number')?.setValue(event.phoneNumber);
  }
  navigateToProfilCandidat(idCandidat: number) {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
