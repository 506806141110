import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Country, ICountry, State } from 'country-state-city';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { listContracts } from '../../../../../../core/models/candidat';
import {
  checkRequiredInputs,
  fileTypeExtention,
  maxSizeFile,
  serverError,
  successEditProfile,
} from '../../../../../../core/models/messages';
import { CandidatService } from '../../../../../../core/services/candidat/candidat.service';
import { CompanyService } from '../../../../../../core/services/company/company.service';
import { TenderService } from '../../../../../../core/services/tender/tender.service';
import { patternEmail } from '../../../../../../core/models/pattern';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-detail-consult',
  templateUrl: './detail-consult.component.html',
  styleUrls: ['./detail-consult.component.css'],
})
export class DetailConsultComponent {
  /* array */
  Pays = [
    { id: 1, name: 'Tunisie' },
    { id: 2, name: 'France' },
    { id: 3, name: 'Algerie' },
  ];
  isReadonly: boolean = true;
  listCantrat = listContracts;
  url: string = environment.baseUrl + '/api';
  paysList: ICountry[] = Country.getAllCountries().map((country) => ({
    ...country,
    name: this.translateCountryName(country.name),
  }));
  stateList!: any[];
  selectedCandidate:any
  skillsList: { name_FR: string }[] = [];
  /* boolean */
  disabled: boolean = true;
  /* number */
  idEsn!: number;
  idConsultant!: number;
  /* string */
  consultName!: string;
  /* form group */
  esnForm: FormGroup = this.createESNForm();
  /* formData */
  formData = new FormData();
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private candidatService: CandidatService,
    private jobRequestService: TenderService,
    private router: Router
  ) {}
  ngOnInit() {
    this.getIdESN();
    this.getListSkills();
  }

  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      Afghanistan: 'Afghanistan',
      'South Africa': 'Afrique du Sud',
      Albania: 'Albanie',
      Algeria: 'Algérie',
      Germany: 'Allemagne',
      Andorra: 'Andorre',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctique',
      'Antigua and Barbuda': 'Antigua-et-Barbuda',
      'Saudi Arabia': 'Arabie saoudite',
      Argentina: 'Argentine',
      Armenia: 'Arménie',
      Aruba: 'Aruba',
      Australia: 'Australie',
      Austria: 'Autriche',
      Azerbaijan: 'Azerbaïdjan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahreïn',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbade',
      Belgium: 'Belgique',
      Belize: 'Belize',
      Benin: 'Bénin',
      Bermuda: 'Bermudes',
      Bhutan: 'Bhoutan',
      Belarus: 'Biélorussie',
      Bolivia: 'Bolivie',
      'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
      Botswana: 'Botswana',
      Brazil: 'Brésil',
      Brunei: 'Brunei',
      Bulgaria: 'Bulgarie',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      'Cape Verde': 'Cabo Verde',
      Cambodia: 'Cambodge',
      Cameroon: 'Cameroun',
      Canada: 'Canada',
      Chile: 'Chili',
      China: 'Chine',
      Cyprus: 'Chypre',
      Colombia: 'Colombie',
      Comoros: 'Comores',
      'Congo - Brazzaville': 'Congo-Brazzaville',
      'Congo - Kinshasa': 'Congo-Kinshasa',
      'North Korea': 'Corée du Nord',
      'South Korea': 'Corée du Sud',
      'Costa Rica': 'Costa Rica',
      'Ivory Coast': 'Côte d’Ivoire',
      Croatia: 'Croatie',
      Cuba: 'Cuba',
      Curaçao: 'Curaçao',
      Denmark: 'Danemark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominique',
      'Dominican Republic': 'République dominicaine',
      Ecuador: 'Équateur',
      Egypt: 'Égypte',
      'United Arab Emirates': 'Émirats arabes unis',
      Eritrea: 'Érythrée',
      Spain: 'Espagne',
      Eswatini: 'Eswatini',
      Estonia: 'Estonie',
      'United States': 'États-Unis',
      Ethiopia: 'Éthiopie',
      Fiji: 'Fidji',
      Finland: 'Finlande',
      France: 'France',
      Gabon: 'Gabon',
      Gambia: 'Gambie',
      Georgia: 'Géorgie',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Grèce',
      Grenada: 'Grenade',
      Greenland: 'Groenland',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernesey',
      Guinea: 'Guinée',
      'Guinea-Bissau': 'Guinée-Bissau',
      'Equatorial Guinea': 'Guinée équatoriale',
      Guyana: 'Guyana',
      'French Guiana': 'Guyane française',
      Haiti: 'Haïti',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Hongrie',
      Iceland: 'Islande',
      India: 'Inde',
      Indonesia: 'Indonésie',
      Iraq: 'Irak',
      Iran: 'Iran',
      Ireland: 'Irlande',
      'Isle of Man': 'Île de Man',
      Israel: 'Israël',
      Italy: 'Italie',
      Jamaica: 'Jamaïque',
      Japan: 'Japon',
      Jersey: 'Jersey',
      Jordan: 'Jordanie',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      Kuwait: 'Koweït',
      Kyrgyzstan: 'Kirghizistan',
      Laos: 'Laos',
      Latvia: 'Lettonie',
      Lebanon: 'Liban',
      Lesotho: 'Lesotho',
      Liberia: 'Libéria',
      Libya: 'Libye',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lituanie',
      Luxembourg: 'Luxembourg',
      Macao: 'Macao',
      'North Macedonia': 'Macédoine du Nord',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaisie',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malte',
      'Marshall Islands': 'Îles Marshall',
      Martinique: 'Martinique',
      Mauritania: 'Mauritanie',
      Mauritius: 'Maurice',
      Mayotte: 'Mayotte',
      Mexico: 'Mexique',
      Micronesia: 'Micronésie',
      Moldova: 'Moldavie',
      Monaco: 'Monaco',
      Mongolia: 'Mongolie',
      Montenegro: 'Monténégro',
      Montserrat: 'Montserrat',
      Morocco: 'Maroc',
      Mozambique: 'Mozambique',
      Myanmar: 'Myanmar',
      Namibia: 'Namibie',
      Nauru: 'Nauru',
      Nepal: 'Népal',
      Netherlands: 'Pays-Bas',
      'New Caledonia': 'Nouvelle-Calédonie',
      'New Zealand': 'Nouvelle-Zélande',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Île Norfolk',
      'Northern Mariana Islands': 'Îles Mariannes du Nord',
      Norway: 'Norvège',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palaos',
      Palestine: 'Palestine',
      Panama: 'Panama',
      'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
      Paraguay: 'Paraguay',
      Peru: 'Pérou',
      Philippines: 'Philippines',
      'Pitcairn Islands': 'Îles Pitcairn',
      Poland: 'Pologne',
      Portugal: 'Portugal',
      'Puerto Rico': 'Porto Rico',
      Qatar: 'Qatar',
      Romania: 'Roumanie',
      Russia: 'Russie',
      Rwanda: 'Rwanda',
      Réunion: 'Réunion',
      'Saint Barthélemy': 'Saint-Barthélemy',
      'Saint Helena': 'Sainte-Hélène',
      'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
      'Saint Lucia': 'Sainte-Lucie',
      'Saint Martin': 'Saint-Martin',
      'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
      'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
      Samoa: 'Samoa',
      'San Marino': 'Saint-Marin',
      'Sao Tome and Principe': 'Sao Tomé-et-Principe',
      Senegal: 'Sénégal',
      Serbia: 'Serbie',
      Seychelles: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapour',
      'Sint Maarten': 'Saint-Martin',
      Slovakia: 'Slovaquie',
      Slovenia: 'Slovénie',
      'Solomon Islands': 'Îles Salomon',
      Somalia: 'Somalie',
      'South Georgia and the South Sandwich Islands':
        'Géorgie du Sud-et-les Îles Sandwich du Sud',
      'South Sudan': 'Soudan du Sud',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Soudan',
      Suriname: 'Suriname',
      'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
      Swaziland: 'Eswatini',
      Sweden: 'Suède',
      Switzerland: 'Suisse',
      Syria: 'Syrie',
      Taiwan: 'Taïwan',
      Tajikistan: 'Tadjikistan',
      Tanzania: 'Tanzanie',
      Thailand: 'Thaïlande',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinité-et-Tobago',
      Tunisia: 'Tunisie',
      Turkey: 'Turquie',
      Turkmenistan: 'Turkménistan',
      Tuvalu: 'Tuvalu',
      Uganda: 'Ouganda',
      Ukraine: 'Ukraine',
      'United Kingdom': 'Royaume-Uni',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Ouzbékistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatican',
      Venezuela: 'Venezuela',
      Vietnam: 'Viêt Nam',
      'Western Sahara': 'Sahara occidental',
      Yemen: 'Yémen',
      Zambia: 'Zambie',
      Zimbabwe: 'Zimbabwe',
    };
    return translations[name] || name;
  }
  /* get esn id from route */
  getIdESN() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idEsn = params.params['id'];
        this.getIntervenantDetails();
      },
    });
  }
  getListSkills() {
    this.jobRequestService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
          // console.log("list skills",this.skillsList)
        },
        error: () => {
          this.toastr.error(serverError);
        },
      });
  }
  /* get details esn */
  getIntervenantDetails(): void {
    this.spinner.show();
    this.candidatService
      .getUserById(this.idEsn)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();

          if (
            typeof res.data[0].mobility == 'string' &&
            res.data[0].mobility == 'null'
          ) {
            res.data[0].mobility = [];
          } else {
            // if (res.data[0].mobility.length) {
            //   res.data[0].mobility = JSON.parse(res.data[0].mobility);
            // }
          }
          console.log("details consultant",res.data[0])
          this.selectedCandidate=res.data[0]
          this.idConsultant = res.data[0].id;
          this.consultName = `${res.data[0].last_name} ${res.data[0].first_name}`;
          this.esnForm = this.createESNForm(res.data[0]);
          // this.esnForm.get('mission_start_date')?.setValue(this.esnForm.value.mission_start_date?moment(this.esnForm.value.mission_start_date):'')
          // this.esnForm.get('mission_end_date')?.setValue(this.esnForm.get('mission_end_date')?.value?moment(this.esnForm.get('mission_end_date')?.value):'')
          // this.esnForm.get('contract_start_date')?.setValue(this.esnForm.get('contract_start_date')?.value?moment(this.esnForm.get('contract_start_date')?.value):'')

          const skills = res.data[0].skills_user.split(',').map((skill:any) => skill.trim()); // Convertir la chaîne en tableau

          // Mettre à jour le champ 'skills' dans le formulaire avec le tableau de compétences
          this.esnForm.get('skills')?.setValue(skills);
          this.getDesirectContract(res.data[0].desired_contract);
          this.esnForm.disable();
          // this.selectedAvailability = this.selectedCandidate.availability
          // this.phone_number = this.esnForm.get('phone_number')?.value
          // this.phoneForm.get('phone')?.setValue(this.esnForm.get('phone_number')?.value)
        },
        error: (err: Error) => {
          this.spinner.hide();
        },
      });
  }
  /* create add candidat form */
  createESNForm(data?: any) {
    return this.formBuilder.group({
      first_name: [data ? data.first_name : ''],
      last_name: [data ? data.last_name : ''],
      email: [data ? data.email : ''],
      emailProESN: [
        data && data.emailProESN != 'null' ? data.emailProESN : '',
        [Validators.pattern(patternEmail)],
      ],
      ESN_name: [data ? data.ESN_name : ''],
      // esn: [data ? data.esn : ""], ////responsable
      client_name: [data ? data.client_name : ''],
      contract_start_date: [data ? data.contract_start_date : ''],
      mission_start_date: [data ? data.mission_start_date : ''],
      mission_end_date: [data ? data.mission_end_date : ''],
      cv: [data ? data.cv : ''],
      destination_country: [data ? data.destination_country : null],
      mobility: [data ? data.mobility : ''],
      desired_salary: [data ? data.desired_salary : ''],
      tjm: [data ? data.tjm : ''],
      skills: [data ? data.skills : ''],
      ID_user: [data ? data.ID_user : ''],
    });
  }
  file!: any;
  fileName!: string;
  fileExtention!: string;
  fileError!: string;
  /* upload cv / image */
  uploadFile(event: any) {
    this.file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    this.fileName = this.file.name;
    if (this.file) {
      const allowedExtensions = ['.pdf', '.docx'];
      const fileExtension = this.file.name
        .toLowerCase()
        .substr(this.file.name.lastIndexOf('.'));
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileExtention = fileTypeExtention;
      } else if (this.file.size > maxSize) {
        this.fileError = maxSizeFile;
      } else {
        this.fileError = '';
        this.fileExtention = '';
      }
    }
    this.esnForm.markAsDirty();
  }
  /* change country select and delete the string Governorate from state name*/
  onCountryChange(event: any) {
    this.stateList = [];
    if (event.length) {
      for (const iterator of event) {
        this.stateList.push(State.getStatesOfCountry(iterator.isoCode));
      }
      this.stateList = this.stateList.flat();
      for (const iterator of this.stateList) {
        iterator.name = iterator.name.replace(' Governorate', '');
      }
    } else {
      this.stateList = [];
    }
  }
  getDesirectContract(contract: any) {
    if (contract.length) {
      this.listCantrat.forEach((option) => {
        if (contract.includes(option.value)) {
          option.checked = true;
        }
      });
    }
  }

  updateCunsultant() {
    console.log('esnForm', this.esnForm);
    this.esnForm.markAllAsTouched();
    this.esnForm.markAsDirty();
    if (this.esnForm.valid) {
      let mobility = this.esnForm.get('mobility')?.value;
      for (const mob of mobility) {
        this.formData.append('mobility[]', mob);
      }
      this.formData.append('first_name', this.esnForm.get('first_name')?.value);
      this.formData.append('last_name', this.esnForm.get('last_name')?.value);
      this.formData.append(
        'emailProESN',
        this.esnForm.get('emailProESN')?.value
      );
      this.formData.append('email', this.esnForm.get('email')?.value);
      this.formData.append(
        'contract_start_date',
        this.esnForm.get('contract_start_date')?.value
      );
      this.formData.append(
        'mission_start_date',
        this.esnForm.get('mission_start_date')?.value
      );
      this.formData.append(
        'mission_end_date',
        this.esnForm.get('mission_end_date')?.value
      );
      this.formData.append('cv', this.esnForm.get('cv')?.value);
      this.formData.append(
        'destination_country',
        this.esnForm.get('destination_country')?.value
      );
      this.formData.append('mobility', this.esnForm.get('mobility')?.value);
      this.formData.append(
        'desired_salary',
        this.esnForm.get('desired_salary')?.value
      );
      this.formData.append('tjm', this.esnForm.get('tjm')?.value);
      this.formData.append('skills', this.esnForm.get('skills')?.value);

      this.spinner.show();
      this.candidatService
        .updateCandidate(this.idConsultant, this.formData)
        .pipe()
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.formData = new FormData();
            if (res.status == 200) {
              this.toastr.success(successEditProfile);
            }
          },
          error: () => {
            this.spinner.hide();
            this.formData = new FormData();
            this.toastr.error(serverError);
          },
        });
    } else {
      this.toastr.error(checkRequiredInputs);
    }
  }
  navigateToProfile() {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: this.idConsultant },
    ]);
  }
  /* key inter number */
  keyPress(event: any) {
    let pattern = /^[\d]$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    return pattern;
  }
  enableDisableMode() {
    this.disabled = !this.disabled;
    this.esnForm.enable();
    this.esnForm.get('ID_user')?.disable();
    this.esnForm.get('email')?.disable();
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
  downloadFile(typeFile?: string) {
    if (typeFile && typeFile != '')
      this.selectedCandidate.file_test =
        this.selectedCandidate.file_test.split('/')[1];
    this.spinner.show();
    const fileUrl = !typeFile
      ? `${this.url}/User/file/gallery_cvs/${this.selectedCandidate.cv}`
      : `${this.url}/ResponseCandidate/GetFile/${this.selectedCandidate.file_test}`;
    this.candidatService.downloadPDF(fileUrl).subscribe((blob) => {
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = !typeFile
        ? `${this.selectedCandidate.ID_user}_${
            this.selectedCandidate.first_name
          }_${
            this.selectedCandidate.last_name
          }_CV.${this.selectedCandidate!.cv.split('.').pop()}`
        : `${this.selectedCandidate.ID_user}_${
            this.selectedCandidate.first_name
          }_${
            this.selectedCandidate.last_name
          }_QCM.${this.selectedCandidate!.file_test.split('.').pop()}`;
      document.body.appendChild(a);
      a.click();
      this.spinner.hide();
    });
  }
}
