import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../core/models/messages';
import { CandidatService } from '../../../../core/services/candidat/candidat.service';
import { JobOfferService } from '../../../../core/services/job-offer/job-offer.service';
import { SortDataService } from '../../../../core/services/sort-data/sort-data.service';
import { TenderService } from '../../../../core/services/tender/tender.service';

@Component({
  selector: 'app-voir-test',
  templateUrl: './voir-test.component.html',
  styleUrls: ['./voir-test.component.css'],
})
export class VoirTestComponent {
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  pagination : boolean =false;

  private unsubscribeAll: Subject<void> = new Subject();
  test: any;
  id = this.activeroute.snapshot.params['id'];
  constructor(
    private jobOfferService: JobOfferService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private jobRequestService: TenderService,
    private sortDataService: SortDataService,
    private candidatService: CandidatService,
    private activeroute: ActivatedRoute
  ) {}
  ngOnInit() {
    this.gettest();
  }

  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
         if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
          this.gettest();
        }
      //  if (this.searchFormGroup.dirty) {
      //   this.searchFAQ()
      //  } else {
      //     this.getListFaq()
      //  }
    }
  }
  gettest() {
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      id: this.id,
    };
    this.spinner.show();
    this.jobOfferService
      .gettest(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('response', res);
          this.spinner.hide();
          this.test = res.data.data.slice(0, this.endIndex);
          this.totalItems = res.data.total;
          if (this.currentPage > 1 && !res.data.items.data.length) {
            this.currentPage = 1;
            this.gettest();
          }
          console.log('res of test', this.test);
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }
}
