import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as saveAs from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize, Subject, takeUntil } from 'rxjs';
import { serverError } from 'src/app/core/models/messages';
import { LocalStorageService } from 'src/app/core/services/Local-storage/local-storage.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent {
  invoiceId: any;
  listInvoice: any;
  searchElement: string = '';
  private unsubscribeAll: Subject<void> = new Subject();
  currentUser: any;
  stripe_id: any;
  subscription_id: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private settingService: SettingsService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getStripeId();
    this.getId();
    this.getListInvoice();
  }
  getStripeId() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    if (this.currentUser) {
      try {
        const parsedUser = JSON.parse(this.currentUser);
        this.stripe_id = parsedUser.stripe_id;
        console.log('stripe_id', this.stripe_id);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        this.stripe_id = null;
      }
    } else {
      console.warn('No user info found in local storage');
      this.stripe_id = null;
    }
  }
  
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.invoiceId = params.params['abonnementId'];
        this.subscription_id = params.params['subscription_id'];
      },
    });
  }

  getListInvoice() {
    let dataPayload = this.subscription_id;
    this.spinner.show();
    this.settingService
      .getAllDetailsOfInvoice(dataPayload)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.listInvoice = res.data;
        },
        (error) => {
          this.spinner.hide();
          console.error('Error fetching detailsOffer:', error);
        }
      );
  }

  downloadInvoice(invoiceId: any, file?: File) {
    let exportSuccess = false;
    let data = invoiceId;
    this.settingService.exportInvoice(file, data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'Facture.pdf');
        exportSuccess = true;
        if (exportSuccess) {
          this.toastr.success('Fichier exporté avec succès');
        }
        ;
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error(serverError);
      },
    });
  }

  applyFilter() {
    let data = {
      search: this.searchElement?.trim().toLowerCase(),
    };
    this.spinner.show();
    this.settingService
      .searchInvoice(data, this.subscription_id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listInvoice = res.data.data;
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
}
