import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  pusher: any;
  channel: any;
 messages$ = new Subject<any>();
 constructor() {
   this.pusher = new Pusher('eb5150b9b1988b68581b', {
     cluster: 'mt1'
     
   })

   this.channel = this.pusher.subscribe('chat');

   this.channel.bind('App\\Events\\ChatEvent', (data: any) => {
     console.log('dataaaaaaa',data);
       this.messages$.next(data);
   });
 }

 disconnect() {
   this.pusher.disconnect();
 }
 

}
