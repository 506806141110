import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListEsnComponent } from './list-esn.component';
import { ProfileEsnComponent } from './components/profile-esn/profile-esn.component';
import { OffreEmploiComponent } from './components/offre-emploi/offre-emploi.component';
import { ListInterventComponent } from './components/list-intervent/list-intervent.component';
import { ListConsultComponent } from './components/list-consult/list-consult.component';
import { DetailConsultComponent } from './components/detail-consult/detail-consult.component';
import { PaymentComponent } from './components/payment/payment.component';
import { InvoicesComponent } from './components/invoices/invoices.component';

const routes: Routes = [
    {
      path: '',
      redirectTo: 'list-esn',
      pathMatch: 'full',
    },
   {
    path:'list-esn',
    component:ListEsnComponent,
  },
    {
    path:'profil-esn',
    component:ProfileEsnComponent,
  },
  {
    path:'factures',
    component:InvoicesComponent,
  },
  {
    path:'offre-emploi-esn',
    component:OffreEmploiComponent,
  },
  {
    path:'list-intervenant-esn',
    component:ListInterventComponent,
  },
  {
    path:'list-consult-esn',
    component:ListConsultComponent,
  },
  {
    path:'details-consultant-esn',
    component:DetailConsultComponent,
  },

  {
      path:'paiement',
      component:PaymentComponent,
    },
    
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ListEsnRoutingModule { }
