import { Component, TemplateRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IState, State } from 'country-state-city';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { fileTypeExtention, maxSizeFile, serverError, updatedCompany } from 'src/app/core/models/messages';
import { linkedInRegExp, urlRegExp } from 'src/app/core/models/pattern';
import { CompanyService } from 'src/app/core/services/company/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detailsociete-review',
  templateUrl: './detailsociete-review.component.html',
  styleUrls: ['./detailsociete-review.component.css']
})
export class DetailsocieteReviewComponent {
  
  
  stateList!:IState[]
  sizeCompany=[
    {id:1,name:'20 - 50'},
    {id:2,name:'50 - 100'},
    {id:3,name:'100 - 200'},
    {id:4,name:'>200'},
  ]
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  separateDialCode: boolean = false;
  selectedCountryCode: string = 'FR'
   /*  */
   file:any
   selectedImage:any
  /* number */
  tabulation:number=10
  /* boolean */
  disabledMode:boolean=true
  /* object */
  esnDetails:any
  responsable:any
  reviewCandidat:any
  reviewCompany:any
  modalRef?: BsModalRef;
  /* string */
  idEsn!: string
  nameEsn!: string
  dataEsn:any
  fileExtention:string=''
  fileError:string=''
  url:string=environment.baseUrl+'/api'
  fileName!:string
   /* formData */
   formData = new FormData()
   totalItems!: number
   itemsPerPage: number = 20
   currentPage: number = 1
   startIndex: number = 0
   endIndex: number = 5
    /* formGroup */
    phoneForm!: FormGroup
    starsFormCompany!: FormGroup
    starsForm!: FormGroup
    companyDetailsForm: FormGroup = this.createCompanyDetailsForm()
    listCandidatReviewDetails:any
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(private activatedRoute: ActivatedRoute, private companyService: CompanyService,
    private spinner: NgxSpinnerService, private toastr: ToastrService, private formBuilder: FormBuilder,private router:Router,private modalService: BsModalService
  ) { 
    this.starsForm = this.formBuilder.group({
      stars: new FormControl(),
    });
  }
  ngOnInit() {
    this.getId()
    this.phoneForm = new FormGroup({
      phone: new FormControl(undefined, [Validators.required]),
    });
    this.getReviewCandidat()
    this.getReviewCompany()
  }
  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      "Afghanistan": "Afghanistan",
      "South Africa": "Afrique du Sud",
      "Albania": "Albanie",
      "Algeria": "Algérie",
      "Germany": "Allemagne",
      "Andorra": "Andorre",
      "Angola": "Angola",
      "Anguilla": "Anguilla",
      "Antarctica": "Antarctique",
      "Antigua and Barbuda": "Antigua-et-Barbuda",
      "Saudi Arabia": "Arabie saoudite",
      "Argentina": "Argentine",
      "Armenia": "Arménie",
      "Aruba": "Aruba",
      "Australia": "Australie",
      "Austria": "Autriche",
      "Azerbaijan": "Azerbaïdjan",
      "Bahamas": "Bahamas",
      "Bahrain": "Bahreïn",
      "Bangladesh": "Bangladesh",
      "Barbados": "Barbade",
      "Belgium": "Belgique",
      "Belize": "Belize",
      "Benin": "Bénin",
      "Bermuda": "Bermudes",
      "Bhutan": "Bhoutan",
      "Belarus": "Biélorussie",
      "Bolivia": "Bolivie",
      "Bosnia and Herzegovina": "Bosnie-Herzégovine",
      "Botswana": "Botswana",
      "Brazil": "Brésil",
      "Brunei": "Brunei",
      "Bulgaria": "Bulgarie",
      "Burkina Faso": "Burkina Faso",
      "Burundi": "Burundi",
      "Cape Verde": "Cabo Verde",
      "Cambodia": "Cambodge",
      "Cameroon": "Cameroun",
      "Canada": "Canada",
      "Chile": "Chili",
      "China": "Chine",
      "Cyprus": "Chypre",
      "Colombia": "Colombie",
      "Comoros": "Comores",
      "Congo - Brazzaville": "Congo-Brazzaville",
      "Congo - Kinshasa": "Congo-Kinshasa",
      "North Korea": "Corée du Nord",
      "South Korea": "Corée du Sud",
      "Costa Rica": "Costa Rica",
      "Ivory Coast": "Côte d’Ivoire",
      "Croatia": "Croatie",
      "Cuba": "Cuba",
      "Curaçao": "Curaçao",
      "Denmark": "Danemark",
      "Djibouti": "Djibouti",
      "Dominica": "Dominique",
      "Dominican Republic": "République dominicaine",
      "Ecuador": "Équateur",
      "Egypt": "Égypte",
      "United Arab Emirates": "Émirats arabes unis",
      "Eritrea": "Érythrée",
      "Spain": "Espagne",
      "Eswatini": "Eswatini",
      "Estonia": "Estonie",
      "United States": "États-Unis",
      "Ethiopia": "Éthiopie",
      "Fiji": "Fidji",
      "Finland": "Finlande",
      "France": "France",
      "Gabon": "Gabon",
      "Gambia": "Gambie",
      "Georgia": "Géorgie",
      "Ghana": "Ghana",
      "Gibraltar": "Gibraltar",
      "Greece": "Grèce",
      "Grenada": "Grenade",
      "Greenland": "Groenland",
      "Guadeloupe": "Guadeloupe",
      "Guam": "Guam",
      "Guatemala": "Guatemala",
      "Guernsey": "Guernesey",
      "Guinea": "Guinée",
      "Guinea-Bissau": "Guinée-Bissau",
      "Equatorial Guinea": "Guinée équatoriale",
      "Guyana": "Guyana",
      "French Guiana": "Guyane française",
      "Haiti": "Haïti",
      "Honduras": "Honduras",
      "Hong Kong": "Hong Kong",
      "Hungary": "Hongrie",
      "Iceland": "Islande",
      "India": "Inde",
      "Indonesia": "Indonésie",
      "Iraq": "Irak",
      "Iran": "Iran",
      "Ireland": "Irlande",
      "Isle of Man": "Île de Man",
      "Israel": "Israël",
      "Italy": "Italie",
      "Jamaica": "Jamaïque",
      "Japan": "Japon",
      "Jersey": "Jersey",
      "Jordan": "Jordanie",
      "Kazakhstan": "Kazakhstan",
      "Kenya": "Kenya",
      "Kiribati": "Kiribati",
      "Kuwait": "Koweït",
      "Kyrgyzstan": "Kirghizistan",
      "Laos": "Laos",
      "Latvia": "Lettonie",
      "Lebanon": "Liban",
      "Lesotho": "Lesotho",
      "Liberia": "Libéria",
      "Libya": "Libye",
      "Liechtenstein": "Liechtenstein",
      "Lithuania": "Lituanie",
      "Luxembourg": "Luxembourg",
      "Macao": "Macao",
      "North Macedonia": "Macédoine du Nord",
      "Madagascar": "Madagascar",
      "Malawi": "Malawi",
      "Malaysia": "Malaisie",
      "Maldives": "Maldives",
      "Mali": "Mali",
      "Malta": "Malte",
      "Marshall Islands": "Îles Marshall",
      "Martinique": "Martinique",
      "Mauritania": "Mauritanie",
      "Mauritius": "Maurice",
      "Mayotte": "Mayotte",
      "Mexico": "Mexique",
      "Micronesia": "Micronésie",
      "Moldova": "Moldavie",
      "Monaco": "Monaco",
      "Mongolia": "Mongolie",
      "Montenegro": "Monténégro",
      "Montserrat": "Montserrat",
      "Morocco": "Maroc",
      "Mozambique": "Mozambique",
      "Myanmar": "Myanmar",
      "Namibia": "Namibie",
      "Nauru": "Nauru",
      "Nepal": "Népal",
      "Netherlands": "Pays-Bas",
      "New Caledonia": "Nouvelle-Calédonie",
      "New Zealand": "Nouvelle-Zélande",
      "Nicaragua": "Nicaragua",
      "Niger": "Niger",
      "Nigeria": "Nigeria",
      "Niue": "Niue",
      "Norfolk Island": "Île Norfolk",
      "Northern Mariana Islands": "Îles Mariannes du Nord",
      "Norway": "Norvège",
      "Oman": "Oman",
      "Pakistan": "Pakistan",
      "Palau": "Palaos",
      "Palestine": "Palestine",
      "Panama": "Panama",
      "Papua New Guinea": "Papouasie-Nouvelle-Guinée",
      "Paraguay": "Paraguay",
      "Peru": "Pérou",
      "Philippines": "Philippines",
      "Pitcairn Islands": "Îles Pitcairn",
      "Poland": "Pologne",
      "Portugal": "Portugal",
      "Puerto Rico": "Porto Rico",
      "Qatar": "Qatar",
      "Romania": "Roumanie",
      "Russia": "Russie",
      "Rwanda": "Rwanda",
      "Réunion": "Réunion",
      "Saint Barthélemy": "Saint-Barthélemy",
      "Saint Helena": "Sainte-Hélène",
      "Saint Kitts and Nevis": "Saint-Kitts-et-Nevis",
      "Saint Lucia": "Sainte-Lucie",
      "Saint Martin": "Saint-Martin",
      "Saint Pierre and Miquelon": "Saint-Pierre-et-Miquelon",
      "Saint Vincent and the Grenadines": "Saint-Vincent-et-les-Grenadines",
      "Samoa": "Samoa",
      "San Marino": "Saint-Marin",
      "Sao Tome and Principe": "Sao Tomé-et-Principe",
      "Senegal": "Sénégal",
      "Serbia": "Serbie",
      "Seychelles": "Seychelles",
      "Sierra Leone": "Sierra Leone",
      "Singapore": "Singapour",
      "Sint Maarten": "Saint-Martin",
      "Slovakia": "Slovaquie",
      "Slovenia": "Slovénie",
      "Solomon Islands": "Îles Salomon",
      "Somalia": "Somalie",
      "South Georgia and the South Sandwich Islands": "Géorgie du Sud-et-les Îles Sandwich du Sud",
      "South Sudan": "Soudan du Sud",
      "Sri Lanka": "Sri Lanka",
      "Sudan": "Soudan",
      "Suriname": "Suriname",
      "Svalbard and Jan Mayen": "Svalbard et Jan Mayen",
      "Swaziland": "Eswatini",
      "Sweden": "Suède",
      "Switzerland": "Suisse",
      "Syria": "Syrie",
      "Taiwan": "Taïwan",
      "Tajikistan": "Tadjikistan",
      "Tanzania": "Tanzanie",
      "Thailand": "Thaïlande",
      "Timor-Leste": "Timor-Leste",
      "Togo": "Togo",
      "Tokelau": "Tokelau",
      "Tonga": "Tonga",
      "Trinidad and Tobago": "Trinité-et-Tobago",
      "Tunisia": "Tunisie",
      "Turkey": "Turquie",
      "Turkmenistan": "Turkménistan",
      "Tuvalu": "Tuvalu",
      "Uganda": "Ouganda",
      "Ukraine": "Ukraine",
      "United Kingdom": "Royaume-Uni",
      "Uruguay": "Uruguay",
      "Uzbekistan": "Ouzbékistan",
      "Vanuatu": "Vanuatu",
      "Vatican City": "Vatican",
      "Venezuela": "Venezuela",
      "Vietnam": "Viêt Nam",
      "Western Sahara": "Sahara occidental",
      "Yemen": "Yémen",
      "Zambia": "Zambie",
      "Zimbabwe": "Zimbabwe"
    };
    return translations[name] || name;
  } 
  
  /* get id esn from route */
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idEsn = params.params['id']
        this.getEsnById()
      }
    })
  }
  /* change country select and delete the string Governorate from state name*/
onCountryChange(event:any): void {
  if(event){
    this.stateList=State.getStatesOfCountry(event.isoCode)
      for (const iterator of this.stateList) {
         iterator.name=iterator.name.replace(" Governorate","")
      }
      }else{
        this.stateList=[]
        this.companyDetailsForm.get('city')?.setValue(null)
      }
}
  /* get esn details */
  getEsnById() {
    this.spinner.show()
    this.companyService.getEsn(this.idEsn).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.spinner.hide()
        this.dataEsn=res.data[0]
        this.nameEsn=res.data[0].name
        this.companyDetailsForm=this.createCompanyDetailsForm(res.data[0])
        this.companyDetailsForm.get('country')?.setValue(res.data[0]?.country=='null'?null:res.data[0].country)
        this.companyDetailsForm.get('city')?.setValue(res.data[0]?.city=='null'?null:res.data[0].city)
        this.phoneForm.get('phone')?.setValue(this.companyDetailsForm.get('phone_number')?.value)
        this.phoneForm.disable()
        this.responsable=res?.data[0]?.employee?.length ? res?.data[0]?.employee[0]:null
        this.companyDetailsForm.disable(); 
      },
      error: () => {
        this.spinner.hide()
      },
    })
  }
  navigatToDetailsCandidat(idCandidat: any) {
    this.router.navigate(['/acceuil/candidats/profil-candidat',{idCandidat:idCandidat}]);
  }
/*get  candidat */
getReviewCandidat() {
  this.spinner.show()
  this.companyService.getReviewCandidat(this.idEsn).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      this.spinner.hide()
      this.reviewCandidat=res.data.data
      this.reviewCandidat = this.reviewCandidat;
      console.log("review candidat",this.reviewCandidat)
      this.starsForm.get('stars')?.setValue(this.reviewCandidat)
    },
    error: () => {
      this.spinner.hide()
    },
  })
}
openModalDetails(template: TemplateRef<any>,idDonneur:any) {
  this.modalRef = this.modalService.show(template,{backdrop: 'static'});
  console.log("idDonneur",idDonneur)
  this.getCandidatReviewDetails(idDonneur)
}
closeModalDetails() {
  this.modalRef!.hide();
}

getCandidatReviewDetails(idDonneur?:any) {
  this.spinner.show()
  this.companyService.getCandidatReviewById(idDonneur, this.idEsn).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      if (res.status == 200) {
        this.spinner.hide()
        this.listCandidatReviewDetails = res
        console.log("listCandidatReviewDetails",this.listCandidatReviewDetails)
      }
    },
    error: () => {
      this.spinner.hide()
      this.toastr.error(serverError)
    }
  })
}
/*get review candidat */
getReviewCompany() {
  this.spinner.show()
  this.companyService.getReviewCompany(this.idEsn).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      this.spinner.hide()
      this.reviewCompany=res
 
    },
    error: () => {
      this.spinner.hide()
    },
  })
}
get averageNoteTotal(): number {
  return this.reviewCompany?.avrage_note_total?.average_note_total ?? 0;
}

set averageNoteTotal(value: number) {
  if (this.reviewCompany && this.reviewCompany.avrage_note_total) {
    this.reviewCompany.avrage_note_total.average_note_total = value;
  }
}
  enableDisableMode(){
    this.disabledMode=!this.disabledMode
    this.companyDetailsForm.enable()
    this.companyDetailsForm.get('number_of_intervenant')?.disable()
    this.companyDetailsForm.get('number_of_salaries')?.disable()
  }
  /* create add subCategory form */
  createCompanyDetailsForm(data?: any) {
    return this.formBuilder.group({
      id: [data?data.id:""],
      name: [data?data.name:"", [this.notOnlySpacesValidator()]],
      type: [data?data.type:null],
      linkedin_link: [data?data.linkedin_link:"", [Validators.pattern(linkedInRegExp)]],
      website_link: [data?data.website_link:"", [Validators.pattern(urlRegExp)]],
      logo: [data?data.logo:""],
      country: [data?data.country:""],
      city: [data?data.city:""],
      number_of_intervenant: [data?data.number_of_intervenant:""],
      siret: [data&&data.siret!='null'?data.siret:"",[this.notOnlySpacesValidator()]],
      year_of_creation: [data&&data.year_of_creation!='null'?data.year_of_creation:""],
      address: [data&&data.address!='null'?data.address:""],
      number_of_salaries: [data?data.number_of_salaries:""],
      esn: [data?data.esn:""],
    })
  }
  notOnlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string | null | undefined = control?.value as string;
      
      // Check if the value is a string before calling trim
      const isValid = typeof value === 'string' && value.trim() !== '';
      
      return isValid ? null : { onlySpaces: true };
    };
  }
  updateInputType(type: 'text' | 'date') {
    const inputElement = document.getElementById('dateInput') as HTMLInputElement;
    inputElement.type = type;
    if (type == 'text') {
      if (this.companyDetailsForm.value.year_of_creation&&this.companyDetailsForm.value.year_of_creation != '') this.companyDetailsForm.get('year_of_creation')?.setValue(moment(this.companyDetailsForm.value.year_of_creation).format('DD/MM/yyyy'));
    }
  }
   /* calculate experience */
   show_experience: boolean = false
   show_error: boolean = false
   experienceText!: string
   isChosenDateValid!: boolean;
  selectDate(){
    const today = new Date();
    const chosen = new Date(this.companyDetailsForm.value.year_of_creation);
    this.isChosenDateValid = chosen > today;
  }
    calculateExperience() {
     // Current date
     let currentDate:any = new Date();
   
     // Start date
     let startDateObj:any = new Date(this.companyDetailsForm.value.year_of_creation);
     // Calculate the difference in milliseconds
     let timeDifference = currentDate - startDateObj;
     if(timeDifference>0)this.show_experience = true
     else this.show_experience = false
     
       // Convert the difference to years and months
       let years = Math.floor(timeDifference / (365.25 * 24 * 60 * 60 * 1000));
       let months = Math.floor((timeDifference % (365.25 * 24 * 60 * 60 * 1000)) / (30.44 * 24 * 60 * 60 * 1000));
       this.experienceText=years>0&&months>0? `${years} ans et ${months} mois` : years==0?`${months} mois`:months==0?`${years} ans`:''
   }
    /* upload file */
    uploadFile(event: any): void {
      const file = event.target.files[0];
      console.log('file',file);
      
      const maxSize = 2 * 1024 * 1024;
      this.file = file
      this.fileName = this.file.name
      const allowedExtensions = ['.jpeg', '.jpg', '.png','.svg','.gif'];
      const fileExtension = file.name.toLowerCase().substr(file.name.lastIndexOf('.'));
      if (file) {
        if (!allowedExtensions.includes(fileExtension)) {
          this.fileExtention=fileTypeExtention
        }else if(file.size > maxSize){
          this.fileError=maxSizeFile
        }
        else{
          this.fileError=''
          this.fileExtention=''
         const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(file); 
        }
        
      }
    }
    shouldDisplayUploadedLogo(): boolean {
      return !this.fileName && this.companyDetailsForm.get('logo')?.value?.logo !== 'avatar.png';
    }
    
    shouldDisplaySelectedImage(): boolean {
      return !!this.fileName;
    }
    
    shouldDisplayPlaceholderImage(): boolean {
      return !this.selectedImage && (!this.companyDetailsForm.get('logo')?.value || this.fileName === '');
    }
    
    clearImage(): void {
      // Réinitialisez la valeur du champ de fichier côté Angular
      this.companyDetailsForm.get('logo')?.setValue('avatar.png');
    
      // Réinitialisez la valeur du champ de fichier côté client
      const fileInput = document.getElementById('fileInput') as HTMLInputElement;
      fileInput.value = '';  // Efface la valeur du champ de fichier
    
      // Réinitialisez les autres propriétés ou effectuez d'autres actions si nécessaire
      this.fileName = '';
      this.selectedImage = null;
    }
    setValidatorRequirements(){
      if(!this.companyDetailsForm.get('linkedin_link')?.value|| this.companyDetailsForm.get('linkedin_link')?.value==''){
      this.companyDetailsForm.get('website_link')?.setValidators([Validators.required,Validators.pattern(urlRegExp)])
      this.companyDetailsForm.get('website_link')!.updateValueAndValidity();
      this.companyDetailsForm.get('linkedin_link')?.clearValidators()
      this.companyDetailsForm.get('linkedin_link')?.setValidators(Validators.pattern(linkedInRegExp))
      this.companyDetailsForm.get('linkedin_link')?.updateValueAndValidity()
      }
      if (this.companyDetailsForm.get('website_link')?.value=='') {
      this.companyDetailsForm.get('linkedin_link')?.setValidators([Validators.required,Validators.pattern(linkedInRegExp)])
      this.companyDetailsForm.get('linkedin_link')!.updateValueAndValidity();
      this.companyDetailsForm.get('website_link')?.clearValidators()
      this.companyDetailsForm.get('website_link')?.setValidators(Validators.pattern(urlRegExp))
      this.companyDetailsForm.get('website_link')?.updateValueAndValidity()
      }
    }
    
    modifier() {
      this.setValidatorRequirements();
      this.companyDetailsForm.markAllAsTouched();
      this.companyDetailsForm.markAsDirty();
      
      this.formData.append('name', this.companyDetailsForm.get('name')?.value);
      
      if (this.companyDetailsForm.value?.linkedin_link) {
        this.formData.append('linkedin_link', this.companyDetailsForm.value.linkedin_link);
      }
    
      if (this.companyDetailsForm.value?.website_link) {
        this.formData.append('website_link', this.companyDetailsForm.value.website_link);
      }
    
      if (this.file) {
        this.formData.append('logo', this.file);
      } else {
        this.formData.append('file_name', this.companyDetailsForm.get('logo')?.value);

      }
    
      this.formData.append('country', this.companyDetailsForm.get('country')?.value);
      this.formData.append('city', this.companyDetailsForm.get('city')?.value);
    
      if (this.companyDetailsForm.value?.siret) {
        this.formData.append('siret', this.companyDetailsForm.get('siret')?.value);
      }
    
      this.formData.append('year_of_creation', this.companyDetailsForm.get('year_of_creation')?.value ?? '');
    
      const addressValue = this.companyDetailsForm.get('address')?.value;
      this.formData.append('address', (addressValue !== null && addressValue !== undefined) ? addressValue : '');
    
      this.spinner.show();
    
      this.companyService.updateCompany(this.companyDetailsForm.get('id')?.value, this.formData)
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.formData = new FormData();
            this.file = null;
    
            if (res.status == 200) {
              this.toastr.success(updatedCompany);
            }
          },
          error: () => {
            this.spinner.hide();
            this.formData = new FormData();
            this.file = null;
            this.toastr.error(serverError);
          }
        });
    }
    
    
    
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }


}
