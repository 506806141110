<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
  <p style="color: white"> Chargement... </p>
</ngx-spinner>
<div id="kt_app_content" class="app-content flex-column-fluid">
  <!--begin::Content container-->
  <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <div class="d-flex align-items-center">
          <h1 class="page-heading">
            <svg width="32" height="29" viewBox="0 0 32 29" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_82_1744)">
              <path
                d="M21.795 7.11531C19.8257 7.11531 18.2199 5.51738 18.2199 3.55766C18.2199 1.59793 19.8257 0 21.795 0C23.7643 0 25.3701 1.59793 25.3701 3.55766C25.3701 5.51738 23.7643 7.11531 21.795 7.11531ZM16.3112 4.58274C16.3112 2.05017 14.2509 0.0301492 11.7363 0.0301492C9.19127 0.0301492 7.16135 2.08032 7.16135 4.58274C7.16135 7.11531 9.22157 9.13533 11.7363 9.13533C14.2812 9.13533 16.3112 7.08516 16.3112 4.58274ZM27.0667 15.9491C27.5212 15.9491 27.8848 15.5572 27.8242 15.105C27.3394 11.6076 25.8245 8.1404 21.795 8.1404C19.5227 8.1404 18.0381 9.25593 17.0989 10.8539C17.7654 11.4267 18.3714 12.09 18.8561 12.904C19.4015 13.7784 19.8863 14.8035 20.2498 15.9793H27.0667V15.9491ZM4.01041 19.2053C3.91952 19.8686 4.43457 20.5017 5.13142 20.5017H18.3411C19.0379 20.5017 19.553 19.8987 19.4621 19.2053C18.7956 14.8035 16.8565 10.4619 11.7363 10.4619C6.61599 10.4619 4.67695 14.8035 4.01041 19.2053Z"
                fill="#265D91" />
            </g>
            <defs>
              <filter id="filter0_d_82_1744" x="0" y="0" width="31.8308" height="28.5017"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_82_1744" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_82_1744" result="shape" />
              </filter>
            </defs>
          </svg>

            Liste Admins
          </h1>
          <div class="tools">
            <div class="dropdown">
              <button class="masse-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_588_1703)">
                    <circle cx="20" cy="18" r="13" fill="white" />
                  </g>
                  <path d="M19.6667 13V23" stroke="#265D91" stroke-width="2" stroke-linecap="round" />
                  <path d="M15 18.3333H25" stroke="#265D91" stroke-width="2" stroke-linecap="round" />
                  <defs>
                    <filter id="filter0_d_588_1703" x="0" y="0" width="42" height="42" filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha" />
                      <feOffset dx="1" dy="3" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_588_1703" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_588_1703" result="shape" />
                    </filter>
                  </defs>
                </svg>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" data-bs-toggle="modal" (click)="openModal(ajouter, currentUser)"
                  style="cursor: pointer;">Ajout Admin</a>

              </div>
            </div>
          </div>
        </div>
        <!--end::Title-->
        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <a class="text-muted" routerLink="/">Accueil</a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->

          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <li class="breadcrumb-item text-muted">Paramètres</li>
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <li class="breadcrumb-item text-muted">Liste des admins</li>

          <!--end::Item-->
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->
      <!--begin::Actions-->

      <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
  </div>
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->

    <div class="card">
      <div class="container-fluid">
        <div class="d-flex flex-wrap flex-stack pt-10 pb-8 title">
          <!--begin::Heading-->
          <h4 class="my-2">Liste des Admins({{this.totalItems}})</h4>
          <!--end::Heading-->
          <!--begin::Controls-->

          <!--end::Controls-->
        </div>


        <!--begin::Tab pane-->

        <!--end::Tab pane-->
        <!--begin::Tab pane-->

        <div *ngIf="!isLoadSpinner" class="card-flush">
          <div class="card-body pt-3">
            <!--begin::Table-->

            <div class="table-responsive" id="kt_customers_table">
              <table id="kt_profile_overview_table" class="table align-middle align-middle fw-bold">
                <thead class="fs-7 text-gray-400 text-uppercase">
                  <tr>
                    <th class="min-w-90px">ID <i (click)="sortData('ID')" class="fa-solid fa-sort"></i> </th>
                    <th class="min-w-90px">Image</th>
                    <th class="min-w-90px">Nom <i (click)="sortData('first_name')" class="fa-solid fa-sort"></i> </th>
                    <th class="min-w-90px">Prénom <i (click)="sortData('last_name')" class="fa-solid fa-sort"></i> </th>
                    <th class="min-w-90px">Email<i (click)="sortData('email')" class="fa-solid fa-sort"></i> </th>
                    <th class="min-w-90px">Téléphone</th>
                    <th class="min-w-90px">Role<i (click)="sortData('role_user_id')" class="fa-solid fa-sort"></i> </th>
                    <th class="min-w-90px"><span>Dernière connexion <i (click)="sortData('last_connection')" class="fa-solid fa-sort cursor-pointer"></i></span></th>

                    <th class="min-w-90px"><span>ACTIONS</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let admin of listadmin ;let i=index">
                    <td><span>{{admin.ID_user ? admin.ID_user : '---' }}</span></td>
                    <td> <img *ngIf="admin.image_url " class="image-admin"
                        src="{{ url+'/User/file/gallery_users/' + admin.image_url }}" alt="" width="50px">
                      <img *ngIf="!admin.image_url  || admin.image_url === '' || admin.image_url === 'undefined' "
                        class="image-admin" src="/assets/media/logos/logo-avatar.jpg" alt="" width="50">
                    </td>
                   
                    <td><span>{{ admin.last_name ? admin.last_name : '---' }}</span></td>
                    <td><span>{{admin.first_name ? admin.first_name : '---' }}</span></td>
                    <td><span>{{admin.email? admin.email : '---' }}</span></td>
                    <td><span>{{admin.phone_number? admin.phone_number : '---' }}</span></td>
                    <td>
                      <span *ngIf="admin.role_user_id==1">Super Admin</span>
                      <span *ngIf="admin.role_user_id==2"> Admin</span>
                      <span *ngIf="admin.role_user_id==null">---</span>
                    <td>  
                       <span> {{ !admin.last_connection ? '---' :admin.last_connection.split('
                        ')[0].split('-').reverse().join('/') }}
                      </span>
                      </td>

                    <td>
                      <div class="tools">
                        <div class="dropdown">
                          <button
                            class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                            type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                                fill="#265D91" />
                              <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                fill="#265D91" />
                              <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                fill="#265D91" />
                            </svg>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <ng-container>
                              <a class="dropdown-item cursor-pointer" data-bs-toggle="modal"
                                (click)="openModal(detail,admin)">Voir profil</a>
                              <a class="dropdown-item cursor-pointer" data-bs-toggle="modal"
                                (click)="openModal(modifier,admin)">Modifier</a>

                            </ng-container>

                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              
            </div>
            <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="!listadmin.length">
              <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
              <span class="empty-list">
                La liste est vide
              </span>
            </div>
            <div class="pagination" *ngIf="listadmin && listadmin.length">
              <app-pagination [data]="listadmin" [lengthData]="totalItems" [currentPageList]="currentPage" [endexPageList]="endIndex" (setPages)="getItems($event)">
              </app-pagination>
            </div>
          </div>


          <!--end::Table-->
        </div>


        <!--end::Tab pane-->

      </div>

    </div>


    <!--begin Modal deatils or add company  -->


  </div>

  <!--end::Content container-->
</div>
<ng-template #ajouter>
  <div class="modal-dialog-centered" id="modifier" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <!--begin::Modal content-->
    <div class="modal-content" [formGroup]="userForm">
      <div class="modal-header">
        <h3 class="modal-title">Ajout Administrateur</h3>

        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close"
          (click)="closeModal()">
          <i class="ki-duotone ki-cross fs-1"><span class="path1"></span><span class="path2"></span></i>
        </div>
        <!--end::Close-->
      </div>

      <div class="modal-body">
        <div class="w-100" id="reseticone">
          <div class="w-100">
            <div class="labels">
              <label for="cv" class="required form-label">Importer Image </label>
              <button *ngIf="fileName!=''" class="more-label deletePhoto" (click)="resetimagelogo()">
                <img src="assets/media/icons/icon-delete.png" alt="" width="30px" class="cursor-pointer">
              </button>
              
            </div>
          </div>
          <!--end::Label-->
          <input formControlName="image_url" #inputFile type="file" name="logo" id="logo" (change)="uploadFile($event)"
            class="form-control mb-3">

          <span style="color:red" *ngIf="fileError"><small>{{fileError}} </small></span>
          <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}} </small></span>
          <div
          *ngIf="userForm.get('image_url')?.hasError('required') && (userForm.get('image_url')?.touched || submitted)"
          class="text-danger">
          Le champ "Image" est obligatoire.
        </div>

        </div>
        <div class="mb-5">
          <label for="name" class="required form-label">Prénom</label>
          <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="Prénom"
            formControlName="first_name">
            <div
            *ngIf="userForm.get('first_name')?.hasError('required') && (userForm.get('first_name')?.touched || submitted)"
            class="text-danger">
            Le champ "Prénom" est obligatoire.
          </div>
        
        </div>
        <div class="mb-5">
          <label for="name" class="required form-label">Nom</label>
          <input type="text" name="nom" id="nom" class="form-control" formControlName="last_name" placeholder="Nom">
          <div
            *ngIf="userForm.get('last_name')?.hasError('required') && (userForm.get('last_name')?.touched || submitted) "
            class="text-danger">
            Le champ "Nom" est obligatoire.
          </div>
        </div>
     
        <div class="mb-5">
          <label for="email" class="required form-label">Email</label>
          <input type="email" name="email" id="email" class="form-control" placeholder="Email" formControlName="email">
          <div *ngIf="userForm.get('email')?.hasError('required') && (userForm.get('email')?.touched || submitted)"
            class="text-danger">
            Le champ "email" est obligatoire.
          </div>
          <div *ngIf="userForm.get('email')?.hasError('pattern') && (userForm.get('email')?.touched || submitted)"
            class="text-danger">
            Le format l’adresse mail n'est pas valide.
          </div>
        </div>
        <div class="mb-5">
          <label for="Tel" class="required form-label">Téléphone</label>


          <intl-telephone-input #phoneInput [preferredCountries]="[CountryISO.France]" [enablePlaceholder]="true"
            [searchCountryFlag]="true" [separateDialCode]="false" [enableAutoCountrySelect]="true"
            [searchCountryField]="SearchCountryField.All" [phoneValidation]="true"
            [numberFormat]="PhoneNumberFormat.International" [inputId]="'phone_number'"
            (onChange)="onInputChange($event)" (keypress)="handleKeyPress($event)">
          </intl-telephone-input>
          <div
            *ngIf="userForm.get('phone_number')?.hasError('required') && (userForm.get('phone_number')?.touched || submitted)"
            class="text-danger">
            Le champ "Téléphone" est obligatoire.
          </div>

        </div>

        <div class="mb-5">
          <label for="Tel" class="required form-label">Rôle</label>
          <ng-select formControlName="role_user_id" [items]="role" bindLabel="name" bindValue="id"
            [placeholder]=" 'Choisir le rôle :'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
          </ng-select>
          <div
          *ngIf="userForm.get('role_user_id')?.hasError('required') && (userForm.get('role_user_id')?.touched || submitted)"
          class="text-danger">
          Le champ "Role" est obligatoire.
        </div>
        </div>
      </div>

      <div class="modal-footer">
        <a class="annuler" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">Annuler</a>
   
       <button (click)="addAdmin()" [disabled]="!userForm.valid  || fileError!='' || fileExtention!=''||!validPhoneNumber" 
               type="submit" id="kt_modal_new_card_submit" class="footer-btn confirmer">
          <span class="indicator-label">Confirmer</span>
          <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
        </button>
      </div>
    </div>

    <!--end::Modal dialog-->
  </div>


</ng-template>

<!--end::Modal - Customers - Add-->
<!--begin::Modal - Adjust Balance-->
<div class="modal fade" tabindex="-1" id="modify_admin">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Modifier Profil</h3>

        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
          <i class="ki-duotone ki-cross fs-1"><span class="path1"></span><span class="path2"></span></i>
        </div>
        <!--end::Close-->
      </div>

      <div class="modal-body">
        <div class="mb-5">
          <label for="masse">Votre Photo</label>
          <input type="file" name="masse" id="masse" class="form-control">
        </div>
        <div class="mb-5">
          <label for="name">Prénom</label>
          <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="Prénom admin">
        </div>
        <div class="mb-5">
          <label for="name">Nom</label>
          <input type="text" name="nom" id="nom" class="form-control" placeholder="Nom admin">
        </div>
    
        <div class="mb-5">
          <label for="email">Email</label>
          <input type="email" name="email" id="email" class="form-control" placeholder="email admin" readonly>
        </div>
        <div class="mb-5">
          <label for="Tel">Téléphone</label>
          <input type="tel" name="tel" id="tel" class="form-control" placeholder="Téléphone admin">
        </div>
        <div class="mb-5">
          <!-- <ng-select [items]="roles" bindLabel="name_FR" bindValue="name_FR" >
                  </ng-select> -->
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="annuler" data-bs-dismiss="modal" aria-label="Close">Annuler</button>
        <button type="button" class="confirmer">Confirmer</button>
      </div>
    </div>
  </div>
</div>
<ng-template #modifier>
  <div class="modal-dialog-centered" id="modifier" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <!--begin::Modal content-->
    <div class="modal-content" [formGroup]="userForm">
      <div class="modal-header">
        <h3 class="modal-title">Modifier Profil</h3>

        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close"
          (click)="closeModal()">
          <i class="ki-duotone ki-cross fs-1"><span class="path1"></span><span class="path2"></span></i>
        </div>
        <!--end::Close-->
      </div>

      <div class="modal-body">
        <div class="row mb-5">
          <div class="section_img">
            <div class="photo_img ">
              <input id="fileInput" type="file" (change)="uploadFile($event)" hidden accept="image/*" class="select-profile-picture" #fileInput />
              
              <!-- Display uploaded or selected image -->
              <img *ngIf="fileName && isImage()" [src]="selectedImage" class="img-profile" alt="" />
              
              <!-- Display profile image from userForm if no new image is uploaded -->
              <img *ngIf="!fileName && userForm.get('image_url')?.value && userForm.get('image_url')?.value !== 'avatar.png' || fileExtention"
                src="{{ url + '/User/file/gallery_users/' + userForm.get('image_url')?.value }}" class="img-profile" alt="" />
              
              <!-- Display default avatar if no image is uploaded or selected -->
              <img *ngIf="!fileName && (!userForm.get('image_url')?.value || userForm.get('image_url')?.value === 'avatar.png')"
                src="/assets/media/logos/logo-avatar.jpg" class="img-profile" alt="" />
            </div>
        
            <div class="btns">
              <button class="changer btn_photo cursor-pointer" (click)="fileInput.click()">Changer la photo</button>
              <button class="supprimer btn_photo cursor-pointer" (click)="clearImage()">
                <!-- SVG for delete icon -->
                <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.9851 4.17508H15.6809C15.8655 4.17508 16.0425 4.10177 16.173 3.97127C16.3035 3.84078 16.3768 3.66379 16.3768 3.47924C16.3768 3.29469 16.3035 3.11769 16.173 2.9872C16.0425 2.8567 15.8655 2.78339 15.6809 2.78339H12.2017V2.08754C12.2 1.5344 11.9796 1.00438 11.5884 0.613252C11.1973 0.222119 10.6673 0.001652 10.1141 0H5.93906C5.38591 0.001652 4.8559 0.222119 4.46477 0.613252C4.07363 1.00438 3.85317 1.5344 3.85151 2.08754V2.78339H0.695847C0.511297 2.78339 0.334305 2.8567 0.203809 2.9872C0.0733121 3.11769 0 3.29469 0 3.47924C0 3.66379 0.0733121 3.84078 0.203809 3.97127C0.334305 4.10177 0.511297 4.17508 0.695847 4.17508H1.06813V14.6128C1.06813 15.5355 1.43469 16.4205 2.08717 17.073C2.73965 17.7255 3.62461 18.092 4.54736 18.092H11.5058C12.4286 18.092 13.3135 17.7255 13.966 17.073C14.6185 16.4205 14.9851 15.5355 14.9851 14.6128V4.17508ZM6.6349 13.2211C6.6349 13.4056 6.56159 13.5826 6.43109 13.7131C6.3006 13.8436 6.12361 13.9169 5.93906 13.9169C5.75451 13.9169 5.57751 13.8436 5.44702 13.7131C5.31652 13.5826 5.24321 13.4056 5.24321 13.2211V7.65432C5.24321 7.46977 5.31652 7.29278 5.44702 7.16228C5.57751 7.03178 5.75451 6.95847 5.93906 6.95847C6.12361 6.95847 6.3006 7.03178 6.43109 7.16228C6.56159 7.29278 6.6349 7.46977 6.6349 7.65432V13.2211ZM10.81 13.2211C10.81 13.4056 10.7367 13.5826 10.6062 13.7131C10.4757 13.8436 10.2987 13.9169 10.1141 13.9169C9.92959 13.9169 9.7526 13.8436 9.6221 13.7131C9.4916 13.5826 9.41829 13.4056 9.41829 13.2211V7.65432C9.41829 7.46977 9.4916 7.29278 9.6221 7.16228C9.7526 7.03178 9.92959 6.95847 10.1141 6.95847C10.2987 6.95847 10.4757 7.03178 10.6062 7.16228C10.7367 7.29278 10.81 7.46977 10.81 7.65432V13.2211ZM10.81 2.78339H5.24321V2.08754C5.24321 1.90299 5.31652 1.726 5.44702 1.5955C5.57751 1.46501 5.75451 1.39169 5.93906 1.39169H10.1141C10.2987 1.39169 10.4757 1.46501 10.6062 1.5955C10.7367 1.726 10.81 1.90299 10.81 2.08754V2.78339Z" fill="#EE6352" />
                </svg>
                Supprimer
              </button>
            </div>
          </div>
          <span style="color:red" *ngIf="fileError"><small>{{fileError}} </small></span>
          <!-- <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}} </small></span> -->
        </div>
        
        <div class="mb-5">
        
          <label for="name" class="required form-label">Prénom</label>
          <!-- <input type="text" name="nom" id="nom" class="form-control" [(ngModel)]="userForm.value.first_name"> -->
          <!-- <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="{{first_name}}" formControlName="first_name"> -->
          <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="{{ first_name }}"
            formControlName="first_name">
          <div *ngIf="userForm.get('first_name')?.hasError('required') && userForm.get('first_name')?.touched"
            class="text-danger">
            Le champ "Prénom" est obligatoire.
          </div>

        </div>
        <div class="mb-5">
          <label for="name" class="required form-label">Nom</label>
          <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="{{last_name}}"
            formControlName="last_name">
          <div *ngIf="userForm.get('last_name')?.hasError('required') && userForm.get('last_name')?.touched"
            class="text-danger"> 
            Le champ "Nom" est obligatoire.
          </div>
        </div>
        <div class="mb-5">
          <label for="email" class="required form-label">Email</label>

          <input type="email" name="email" id="email" class="form-control" placeholder="email" formControlName="email"
            readonly>
          <div *ngIf="userForm.get('email')?.hasError('required') && userForm.get('email')?.touched"
            class="text-danger">
            Le champ "email" est obligatoire.
          </div>
          <div *ngIf="userForm.get('email')?.hasError('pattern') && userForm.get('email')?.touched" class="text-danger">
            Le format l’adresse mail n'est pas valide.
          </div>
        </div>

        <div class="mb-5">
          <label for="Tel">Téléphone</label>
          <input type="tel" name="tel" id="tel" class="form-control" placeholder="phone_number"
            formControlName="phone_number" readonly>
          <div *ngIf="userForm.get('phone_number')?.hasError('required') && userForm.get('phone_number')?.touched"
            class="text-danger">
            Le champ "Téléphone" est obligatoire.
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="annuler" data-bs-dismiss="modal" aria-label="Close"
          (click)="closeModal()">Annuler</button>
        <button [disabled]="userForm.invalid || fileError!='' || fileExtention!=''" type="button" class="confirmer"
          (click)="onSubmit()">
          Confirmer
        </button>


      </div>
    </div>

    <!--end::Modal dialog-->
  </div>


</ng-template>
<ng-template #detail>
  <div class="modal-dialog-centered" id="modifier" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <!--begin::Modal content-->
    <div class="modal-content" [formGroup]="userForm">
      <div class="modal-header">
        <h3 class="modal-title">Détail Profil</h3>

        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close"
          (click)="closeModal()">
          <i class="ki-duotone ki-cross fs-1"><span class="path1"></span><span class="path2"></span></i>
        </div>
        <!--end::Close-->
      </div>

      <div class="modal-body">
        <div class="row mb-5">
          <div class="section_img">
            <div class="photo_img ">
              <!-- <input id="fileInput" type="file" (change)="uploadFile($event)" hidden
                          accept=".png, .jpg, .jpeg" class="select-profile-picture" #fileInput /> -->
              <img *ngIf="(!fileName||fileName=='') && userForm.get('image_url')?.value?.image_url!='avatar.png'"
                src="{{ url+'/User/file/gallery_users/'+ userForm.get('image_url')?.value }}" class="img-profile"
                alt="" />
              <img *ngIf=" fileName!=''" [src]="selectedImage" class="img-profile" alt="" />

              <!-- <img *ngIf="!selectedImage&&!userForm.get('image_url')?.value "
                          class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt="" width="75px"> -->

            </div>


          </div>
        </div>
        <div class="mb-5">
          <label for="name">Prénom</label>
          <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="{{ first_name }}"
          formControlName="first_name" readonly>
          <div *ngIf="userForm.get('first_name')?.hasError('required') && userForm.get('first_name')?.touched"
            class="text-danger">
      
            Le champ "Prénom" est obligatoire.
          </div>
        </div>
        <div class="mb-5">
          <label for="name">Nom</label>
          <!-- <input type="text" name="nom" id="nom" class="form-control" [(ngModel)]="userForm.value.first_name"> -->
          <!-- <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="{{first_name}}" formControlName="first_name"> -->
          <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="{{last_name}}"
          formControlName="last_name" readonly>
          <div *ngIf="userForm.get('last_name')?.hasError('required') && userForm.get('last_name')?.touched"
          class="text-danger">
            Le champ "Nom" est obligatoire.
          </div>

        </div>
    
        <div class="mb-5">
          <label for="email">Email</label>

          <input type="email" name="email" id="email" class="form-control" placeholder="email" formControlName="email"
            readonly>
          <div *ngIf="userForm.get('email')?.hasError('required') && userForm.get('email')?.touched"
            class="text-danger">
            Le champ "email" est obligatoire.
          </div>
          <div *ngIf="userForm.get('email')?.hasError('pattern') && userForm.get('email')?.touched" class="text-danger">
            Le format l’adresse mail n'est pas valide.
          </div>
        </div>

        <div class="mb-5">
          <label for="Tel">Téléphone</label>
          <input type="tel" name="tel" id="tel" class="form-control" placeholder="phone_number"
            formControlName="phone_number" readonly>
          <div *ngIf="userForm.get('phone_number')?.hasError('required') && userForm.get('phone_number')?.touched"
            class="text-danger">
            Le champ "Téléphone" est obligatoire.
          </div>
        </div>

      </div>

      <!-- <div class="modal-footer">
              <button type="button" class="annuler" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">Annuler</button>
              <button class="confirmer"  (click)="onSubmit()">Confirmer</button>
          </div> -->
    </div>

    <!--end::Modal dialog-->
  </div>


</ng-template>
<div class="modal fade" tabindex="-1" id="add_admin">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Ajout Admin/Super Admin</h3>

        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
          <i class="ki-duotone ki-cross fs-1"><span class="path1"></span><span class="path2"></span></i>
        </div>
        <!--end::Close-->
      </div>

      <div class="modal-body">
       
        <div class="mb-5">
          <label for="name">Prénom</label>
          <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="Prénom admin">
        </div>
        <div class="mb-5">
          <label for="name">Nom</label>
          <input type="text" name="nom" id="nom" class="form-control" placeholder="Nom admin">
        </div>
        <div class="mb-5">
          <label for="email">Email</label>
          <input type="email" name="email" id="email" class="form-control" placeholder="email admin">
        </div>

        <div class="w-100">
          <div class="labels">
            <label for="cv">Importer Logo </label>
            <button *ngIf="fileName!=''" class="more-label deletePhoto">
              <img src="assets/media/icons/icon-delete.png" alt="" width="30px" class="cursor-pointer">
            </button>
          </div>
          <!--end::Label-->
          <input formControlName="logo" #inputFile type="file" name="logo" id="logo" class="form-control mb-3">

          <span style="color:red" *ngIf="fileError"><small>{{fileError}} </small></span>
          <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}} </small></span>
        </div>
        <div class="mb-5">
          <label for="Tel">Rôle</label>
          <!-- <ng-select [items]="roles" bindLabel="name_FR" bindValue="name_FR" >
                </ng-select> -->
        </div>


      </div>

      <div class="modal-footer">
        <button type="button" class="annuler" data-bs-dismiss="modal" (click)="closeModal()">Annuler</button>
        <button type="button" class="confirmer">Confirmer</button>
      </div>
    </div>
  </div>
</div>