import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  SuccessAssign,
  serverError,
} from '../../../../../../core/models/messages';
import { PermissionService } from '../../../../../../core/services/permission.service';

@Component({
  selector: 'app-detail-role',
  templateUrl: './detail-role.component.html',
  styleUrls: ['./detail-role.component.css'],
})
export class DetailRoleComponent {
  /* string */
  searchValue: string = '';
  searchElement: string = '';
  /* numbers */
  idRole!: number;
  /* arrays */
  listPermissionsRoles: any = [];
  listPermissions: any = [];
  eventsDropped: boolean = false;
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private permissionService: PermissionService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    this.getIdRole();
    this.getListPermissions();
  }
  clearData() {
    this.getListPermissions();
  }

  listIds: any = [];
  families: any = [];

  getConnectedListsRoles(currentListId: any): any[] {
    console.log(this.listPermissionsRoles, currentListId);
    return this.listPermissionsRoles
      .filter((list: any) => list.titre !== currentListId) // Exclude the current list
      .map((list: any) => `list-role-${list.titre}`); // Get the IDs of the remaining lists
  }

  getConnectedListsPers(currentListId: any): any[] {
    return this.listPermissions
      .filter((list: any) => list.titre !== currentListId) // Exclude the current list
      .map((list: any) => `list-per-${list.titre}`); // Get the IDs of the remaining lists
  }

  onDrop(event: CdkDragDrop<any[]>, parents: any[]) {
    //   this.listIds = []
    //console.log("parents : ",parents[event.currentIndex],event.previousIndex,event.currentIndex);

    if (event.previousContainer === event.container) {
      //moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      if (event.container === event.item.dropContainer) {
        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    } else {
      // Transfer item from one list to another
      const previousContainer = event.previousContainer.data;
      const container = event.container.data;
      const previousIndex = event.previousIndex;
      const currentIndex = event.currentIndex;
      const item = previousContainer[previousIndex];

      if (item.children != undefined) {
        console.log('parents of item : ', item);
        if (event.previousContainer === event.item.dropContainer) {
          transferArrayItem(
            previousContainer,
            container,
            previousIndex,
            currentIndex
          );
        } else {
          previousContainer.splice(previousIndex, 1);
          container.splice(currentIndex, 0, item);
        }

        this.eventsDropped = true;

        item.children.forEach((item: any) => {
          if (item.has_permission == 'non') {
            item.has_permission = 'oui';
            this.listIds.push(item);
          } else {
            item.has_permission = 'non';
            this.listIds.push(item);
          }
        });
      } else {
        console.log('parents of item : ', item);
        console.log(this.search(item.Title, container));

        this.search(item.Title, container).children.push(item);
        var object = {
          titre: item.Title,
          children: this.search(item.Title, container).children,
        };
        console.log('obj : ', object);

        if (event.previousContainer === event.item.dropContainer) {
          transferArrayItem(
            previousContainer,
            container,
            previousIndex,
            currentIndex
          );
        } else {
          previousContainer.splice(previousIndex, 1);
          container.splice(currentIndex, 0, object);
        }

        this.eventsDropped = true;

        if (item.has_permission == 'non') {
          item.has_permission = 'oui';
          this.listIds.push(item);
        } else {
          item.has_permission = 'non';
          this.listIds.push(item);
        }
      }
    }
  }

  onChildDrop(event: CdkDragDrop<any[]>, children: any[]) {
    console.log('childrens : ', children[event.previousIndex]);

    this.listIds = [];
    if (event.previousContainer === event.container) {
      moveItemInArray(children, event.previousIndex, event.currentIndex);
    } else {
      // Transfer child between parent's children logic
      const child = event.previousContainer.data[event.previousIndex];
      event.previousContainer.data.splice(event.previousIndex, 1);
      children.splice(event.currentIndex, 0, child);
    }
    this.eventsDropped = true;
    children[event.previousIndex].has_permission = 'oui';
    if (children[event.previousIndex].has_permission == 'non') {
      //  this.listIds.push(item)
      console.log(children[event.previousIndex].Title);
      console.log(this.listPermissions);
      console.log(
        this.search(children[event.previousIndex].Title, this.listPermissions)
      );
    } else {
      console.log(children[event.previousIndex].Title);
      console.log(this.listPermissionsRoles);
      console.log(
        this.search(
          children[event.previousIndex].Title,
          this.listPermissionsRoles
        )
      );
    }
    /*
        this.listPermissionsRoles.map((el: any) => {
          el.children.forEach((item: any) => {
            if (item.has_permission == 'non') {
              this.listIds.push(item)
            }
          })
        })
        this.listPermissions.map((el: any) => {
          el.children.forEach((item: any) => {
    
            if (item.has_permission == 'oui') {
              this.listIds.push(item)
            }
          })
        })*/
  }

  search(nameKey: any, myArray: any[]) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].titre === nameKey) {
        return myArray[i];
      }
    }
  }

  /* get indexes for pagination */
  getIdRole() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idRole = params.params['idRole'];
      },
    });
  }

  getListPermissions() {
    this.spinner.show();
    this.permissionService
      .getListPermissions(this.idRole)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listPermissions = [];
            this.listPermissionsRoles = [];

            console.log('listPermissions of ', res.data);
            // Objet pour regrouper les objets par attribut "Title"
            const objetsParTitre: any = {};

            // Boucle à travers les objets pour les regrouper par "Title"
            res.data.forEach((objet: any) => {
              const titre = objet.Title;
              if (!objetsParTitre[titre]) {
                objetsParTitre[titre] = []; // Crée un tableau s'il n'existe pas encore pour ce titre
              }
              objetsParTitre[titre].push(objet); // Ajoute l'objet au tableau correspondant à ce titre
            });

            // Affichage du résultat

            for (const titre in objetsParTitre) {
              if (titre != 'null') {
                this.listPermissionsRoles.push({
                  titre: titre,
                  children: objetsParTitre[titre].filter(
                    (el: any) => el.has_permission == 'oui'
                  ),
                });
                this.listPermissions.push({
                  titre: titre,
                  children: objetsParTitre[titre].filter(
                    (el: any) => el.has_permission == 'non'
                  ),
                });
              }
            }

            console.log('listPermissionsRoles : ', this.listPermissionsRoles);
            console.log('listPermissions : ', this.listPermissions);

            /*   res.data.map((el: { has_permission: string }) => {
               if (el.has_permission == 'non') {
                 this.listPermissions.push(el)
               }
             })
             for (const iterator of res.data) {
               if (iterator.has_permission == 'oui') {
                 this.listPermissionsRoles.push(iterator)
               }
             }*/
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }

  /* filter data on key up input */
  applyFilter(event?: Event) {
    this.listPermissions = [];
    this.listPermissionsRoles = [];
    // if (this.searchElement != '') {
    let data = {
      search: this.searchElement?.trim().toLowerCase(),
    };
    this.spinner.show();
    this.permissionService
      .searchPermission(this.idRole, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('listPermissions of ', res.data);
          // Objet pour regrouper les objets par attribut "Title"
          const objetsParTitre: any = {};

          // Boucle à travers les objets pour les regrouper par "Title"
          res.data.forEach((objet: any) => {
            const titre = objet.Title;
            if (!objetsParTitre[titre]) {
              objetsParTitre[titre] = []; // Crée un tableau s'il n'existe pas encore pour ce titre
            }
            objetsParTitre[titre].push(objet); // Ajoute l'objet au tableau correspondant à ce titre
          });

          // Affichage du résultat
          for (const titre in objetsParTitre) {
            if (titre != 'null') {
              this.listPermissionsRoles.push({
                titre: titre,
                children: objetsParTitre[titre].filter(
                  (el: any) => el.has_permission == 'oui'
                ),
              });
              this.listPermissions.push({
                titre: titre,
                children: objetsParTitre[titre].filter(
                  (el: any) => el.has_permission == 'non'
                ),
              });
            }
          }
          /*  res.data.map((el: { has_permission: string }) => {
            if (el.has_permission == 'non') {
              this.listPermissions.push(el)
            }
          })
          this.listPermissionsRoles = []
          for (const iterator of res.data) {
            if (iterator.has_permission == 'oui') {
              this.listPermissionsRoles.push(iterator)
            }
          }*/
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  checkedPermissions: any = [];
  checkPermission(event: any, permission: any) {
    permission.has_permission = event.target.checked ? 'oui' : 'non';
    if (!this.checkedPermissions?.includes(permission.id))
      this.checkedPermissions.push(permission.id);
  }
  dataIDSS: any = [];
  assignPermissions() {
    this.spinner.show();

    console.log('listIds ', this.listIds);
    this.listIds.map((el: any) => this.dataIDSS.push(el.id));
    let data = {
      role_id: this.idRole,
      permission_ids: this.dataIDSS,
    };
    console.log('dataIDSS', this.dataIDSS);

    this.permissionService
      .assignPermission(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.toastr.success(SuccessAssign);
            this.getListPermissions();
            this.dataIDSS = [];
            console.log('dataIDSS', this.dataIDSS);
            this.eventsDropped = false;
            this.listIds.length = 0;
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  removePermissions(permission: any) {
    this.spinner.show();
    let data = {
      role_id: this.idRole,
      permission_ids: [permission.id],
    };
    this.permissionService
      .retryPermission(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
