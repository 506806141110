import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { NotificationService } from '../../core/services/notification/notification.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent {
  notifications:any
  unreadNotificationCount: number = 0;
  base_url_img = environment.baseUrlGalery
  base_url_img_company = environment.baseUrlGaleryCompany
  itemsPerPage: number = 5
  currentPage: number = 1
  startIndex: number = 0
  endIndex: number = 5
  totalItems:any;
  pagination : boolean =false;

  private unsubscribeAll: Subject<void> = new Subject();

  notificationsSubscription!:Subscription
  constructor(private notificationService: NotificationService,
              private changeDetectorRef: ChangeDetectorRef,
             private spinner: NgxSpinnerService,
             private router:Router
            ){}

  ngOnInit(){
    this.notificationsSubscription = this.notificationService.messages$.subscribe((notification) => {
      console.log("notification",notification)
      this.notifications = this.notifications.concat(notification);
      console.log("notifications2",this.notifications)
      this.changeDetectorRef.detectChanges(); 
    });
    this.getNotification()
  }
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex
      this.endIndex = event.endIndex
      this.itemsPerPage = event.itemsPerPage,
        this.currentPage = event.currentPage
         if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
        this.getNotification()
        }
    }
  }
  getNotification() {
    this.notificationService.getListOfNotification(this.itemsPerPage, this.currentPage).subscribe(
      {
        next: (res) => {
          this.notifications = res.data.data.data;
          this.totalItems = res.data.data.total;
          console.log('user notification ********** ', this.notifications);
  
          this.unreadNotificationCount = this.notifications.filter((notification:any) => notification.read === 0).length;
        },
        error: (e) => {
          console.log('get notification error ===== ', e);
        }
      }
    );
  }
  readNotification(id: any,index:number) {
    this.spinner.show();
    let data = { "id": id }
    this.notificationService.readNotification(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.getNotification()
        this.notifications[index].read = true;
        this.spinner.hide();
      },
      error: (err: any) => {
        this.spinner.hide();

      }
    });
  }
  notificationRedirection(notification:any,index:number){
    this.readNotification(notification.id,index)
    if(notification.state=='4'){
      this.router.navigate(['/acceuil/general']);
    }else if (notification.state=='5'){
      this.router.navigate(['/acceuil/general']);
    }
    else if (notification.state=='16'){
      this.router.navigate(['/acceuil/candidats/list-candidat']);
    }else
    this.router.navigate(['/acceuil/notifications']);

  }
}