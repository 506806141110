import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListSocieteComponent } from './compnents/list-societe/list-societe.component';
import { DetailsocieteComponent } from './compnents/list-societe/detailsociete/detailsociete/detailsociete.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list-entreprise',
    pathMatch: 'full',
  },
  {
    path:'Detailsociete',
  component:DetailsocieteComponent,
},
  {
    path:'list-entreprise',
  component:ListSocieteComponent,
},

  {
    path: 'esn',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./compnents/list-esn/list-esn.module').then((m) => m.ListEsnModule),
      },

    ],
  },
  {
    path: 'clients-finaux',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./compnents/client-final/client-final.module').then((m) => m.ClientFinalModule),
      },

    ],
  },

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompaniesRoutingModule { }
