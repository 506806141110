<div class="pagi">
   <!-- Select items per page -->
<div class="page-number">
  <span *ngIf="!iscategoriequestion"> Éléments par page : </span>
  <span *ngIf="iscategoriequestion"> Nombre de questions par page : </span>
   <select id="itemsPerPage" [(ngModel)]="pageSize" (change)="onPageSizeChange()">
    <option value="5">5</option>
    <option value="10">10</option>
    <option value="20">20</option>
    <!-- Add more options as needed -->
  </select>
</div>
<!--  -->
<div class="nextprev">
  <button class="pagination-btn" title="Première page" [disabled]="currentPage === 1" (click)="goToFirstPage()">
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 1L2 7L8 13" [attr.stroke]="currentPage !== 1 ? '#265D91' : '#ACA7A7'" stroke-width="2" stroke-linecap="round" />
      <path d="M19 1L13 7L19 13" [attr.stroke]="currentPage !== 1 ? '#265D91' : '#ACA7A7'" stroke-width="2" stroke-linecap="round" />
    </svg>
  </button>
  <button class="pagination-btn" title="Page précédente" [disabled]="currentPage === 1" (click)="prevPage()">
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
      <path d="M8 1L2 7L8 13" [attr.stroke]="currentPage !== 1 ? '#265D91' : '#ACA7A7'" stroke-width="2" stroke-linecap="round" />
    </svg>
  </button>
  <span>{{ calculateStartItem() }} - {{ endItem }} de {{ dataLength }}</span>
  <button class="pagination-btn" title="Page Suivante" [disabled]="endItem >= dataLength" (click)="nextPage()">
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
      <path d="M1 1L7 7L1 13" [attr.stroke]="endItem < dataLength ? '#265D91' : '#ACA7A7'" stroke-width="2" stroke-linecap="round" />
    </svg>
  </button>
  <button class="pagination-btn" title="Dernière page" [disabled]="endItem >= dataLength" (click)="goToLastPage()">
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L7 7L1 13" [attr.stroke]="endItem < dataLength ? '#265D91' : '#ACA7A7'" stroke-width="2" stroke-linecap="round" />
      <path d="M12 1L18 7L12 13" [attr.stroke]="endItem < dataLength ? '#265D91' : '#ACA7A7'" stroke-width="2" stroke-linecap="round" />
    </svg>
  </button>
</div>
</div>
