import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { Successpage, serverError } from '../../../../core/models/messages';
import { PageActorService } from '../../../../core/services/page-actor.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css'],
})
export class PagesComponent {
  idActor!: number;
  subPageIds: number[] = [];
  /* array */
  listPages: any;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private pageActorService: PageActorService
  ) {}

  ngOnInit() {
    this.getIdActor();
  }
  /* get actor id from route */
  getIdActor() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idActor = params.params['role']
        console.log("idactor",this.idActor)
        this.getListPagesActor()
      }
    })
  }
  /* get list  pages by actor */
  getListPagesActor(){
  
  this.spinner.show()
  this.pageActorService.listPageActor(this.idActor).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      if (res.status == 200) {
        this.spinner.hide()
        this.listPages = res.data
      }
    },
    error: () => {
      this.spinner.hide()
      this.toastr.error(serverError)
    }
  })
}
changeSelection(subPage:any){
      if(!this.subPageIds.includes(subPage.id)){
        this.subPageIds.push(subPage.id)
    }else{
      const indexToRemove = this.subPageIds.indexOf(subPage.id);
      if (indexToRemove !== -1) {
        this.subPageIds.splice(indexToRemove, 1);
      }
    }
  }
  changeSubPageState() {
    this.spinner.show();
    let data = {
      role_id: this.idActor,
      sub_page_id: this.subPageIds,
    };
    this.pageActorService
      .changeSubPageState(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            // this.listPages = res.data
            this.toastr.success(Successpage);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
