import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CandidatService } from 'src/app/core/services/candidat/candidat.service';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(
    private candidateService: CandidatService,
    private toastrService: ToastrService
  ) {}

  exportCVEsnCandidate(
    selectedCandidate: { ids: any[]; last_name: string | null; first_name: string | null} | null,
    type: number,
    idCompany: number | null
  ): Observable<any> {
    return new Observable((observer) => {
      this.candidateService.exportCVEsn(selectedCandidate?.ids[0], type, idCompany).subscribe({
        next: (res: any) => {
          const blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          let name = `${selectedCandidate?.last_name}-${selectedCandidate?.first_name}-CvESN`;
          if (type === 2) {
            name = name.concat('-Anonyme');
          }
          name = name.concat('.pdf');

          saveAs(blob, name);
          this.toastrService.success('CV téléchargé avec succès');
          observer.next(res);
          observer.complete();
        },
        error: (err) => {
          this.toastrService.error('Une erreur est survenue lors du téléchargement.');
          observer.error(err);
        },
      });
    });
  }

  exportCVWegestuCandidate(selectedCandidate: { ids: any[]; last_name: string | null; first_name: string | null }, type: number): Observable<any> {
    return new Observable((observer) => {
      this.candidateService
        .exportCVWegesttu(selectedCandidate.ids, type)
        .subscribe({
          next: (res: any) => {
            const isSingleFile = selectedCandidate.ids.length === 1;
            const fileType = isSingleFile
              ? 'application/pdf'
              : 'application/zip';
            const extension = isSingleFile ? '.pdf' : '.zip';
            let name = isSingleFile ? `Cv-WeGestU` : `CVs-WeGestU`;
            if (type === 2) {
              name = name.concat('-Anonyme');
            }
            name = name.concat(extension);
            const blob = new Blob([res], { type: fileType });
            saveAs(blob, name);

            this.toastrService.success(
              isSingleFile
                ? 'CV téléchargé avec succès'
                : 'Fichiers compressés téléchargés avec succès'
            );
            observer.next(res);
            observer.complete();
          },
          error: (err) => {
            if (err.status == '403') {
              this.toastrService.error(
                'Limite de téléchargement atteinte. Vous ne pouvez plus télécharger de fichiers ce mois-ci.'
              );
              observer.error(err);
            } else {
              this.toastrService.error(
                'Une erreur est survenue lors du téléchargement.'
              );
              observer.error(err);
            }
          },
        });
    });
  }

  exportCVCandidate(selectedCandidate: { fileNames: any[]; last_name: string | null; first_name: string | null }): Observable<any> {
    return new Observable((observer) => {
      this.candidateService.exportCVCandidate(selectedCandidate.fileNames).subscribe({
        next: (res: any) => {
          const isSingleFile = selectedCandidate.fileNames.length === 1;
          const fileType = isSingleFile ? 'application/pdf' : 'application/zip';
          const extension = isSingleFile ? '.pdf' : '.zip';
          let name = isSingleFile && selectedCandidate?.last_name && selectedCandidate?.first_name? 
          `${selectedCandidate?.last_name}-${selectedCandidate?.first_name}-Cv` : `CVs-${new Date().toISOString().split('T')[0]}`;
          name = name.concat(extension);
          const blob = new Blob([res], { type: fileType });
          saveAs(blob, name);

          this.toastrService.success(
            isSingleFile
              ? 'CV téléchargé avec succès'
              : 'Fichiers compressés téléchargés avec succès'
          );
          observer.next(res);
          observer.complete();
        },
        error: (err) => {
          this.toastrService.error('Une erreur est survenue lors du téléchargement.');
          observer.error(err);
        },
      });
    });
  }
}
