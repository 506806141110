import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import { PaginatorCustomPIntl } from './helpers/paginationCustomIntel';

const modules = [MatPaginatorModule]
@NgModule({
  declarations: [],
  imports: [CommonModule, modules],
  exports: [ modules],
  providers: [{provide: MatPaginatorIntl, useClass: PaginatorCustomPIntl}],
})
export class SharedModule { }
