import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { listContracts } from '../../../../core/models/candidat';
import { serverError } from '../../../../core/models/messages';
import { CandidatService } from '../../../../core/services/candidat/candidat.service';
import { environment } from '../../../../../environments/environment';

import { jsPDF } from 'jspdf';
import * as saveAs from 'file-saver';
import html2canvas from 'html2canvas';
import { CvType } from 'src/app/core/models/enums/cv-type.enum';
@Component({
  selector: 'app-profil-candidat',
  templateUrl: './profil-candidat.component.html',
  styleUrls: ['./profil-candidat.component.css'],
})
export class ProfilCandidatComponent {
  /* string */
  url: string = environment.baseUrl;
  readonly ESN_CONSULTANT_ROLE  = "4"
  /* number */
  idCandidat!: number;
  /* number */
  totalItems: number = 10  
  itemsPerPage: number = 5
  currentPage: number = 1
  startIndex: number = 0
  endIndex: number = this.itemsPerPage;
  pagination : boolean =false;

  // linkedinPrefix = 'https://www.linkedin.com/in/';
  baseUrl_Company = environment.baseUrlGaleryCompany;

  /* object */
  selectedCandidate!: any;
  /* array */
  contratRequest = listContracts;
  skills: any = [];
  skillsCv:any
  colorsHeader: string[] = [
    '#FBF2E9',
    '#E8F6FC',
    '#FDEFEE',
    '#FFF3D7',
  ];
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();

  cvTypeEnum  = CvType
  constructor(
    private activatedRoute: ActivatedRoute,
    private candidatService: CandidatService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {}
  ngOnInit() {
    this.getIdCandidat();
    this.getSkills();
  }
 
  /* get pagination items */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      this.itemsPerPage = event.itemsPerPage;
      this.currentPage = event.currentPage;
      if (this.endIndex > this.totalItems) {
        this.endIndex = this.totalItems;
      }
     else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
      this.pagination = true
      this.getSkills();}
    }
  }
  

  /* get candidate id from route */
  getIdCandidat() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCandidat = params.params['idCandidat'];
        this.getCandidateById();
      },
    });
  }
  shouldDisplayCountry(destination_country: any[]): boolean {
    return destination_country && destination_country.some((c) => c !== null);
  }
  shouldDisplayMobility(mobility: any[]): boolean {
    return mobility && mobility.some((m) => m !== null);
  }

  exportToPDF() {
    const element = document.getElementById('kt_app_content');

    if (element) {
      this.spinner.show();
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('export_Cv.pdf');
        this.spinner.hide();
      });
    } else {
      console.error('Element not found!');
    }
  }

  getCandidateById(): void {
    this.spinner.show();
    this.candidatService
      .getUserById(this.idCandidat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.selectedCandidate = res.data[0];
          this.selectedCandidate.softSkills= this.selectedCandidate.softSkills.filter((skill:any) => skill !== null);
          console.log('selectedCandidate : ', this.selectedCandidate);
          // Ensure that desired_contract is an array
          this.selectedCandidate.desired_contract =
          this.selectedCandidate.desired_contract || [];
          // Iterate over desired_contract and map to labels
          this.selectedCandidate.desired_contract =
            this.selectedCandidate.desired_contract.map((contractId: any) => {
              return this.contratRequest[contractId - 1]?.label || '';
            });

          // Ensure other properties are not 'null'
          this.selectedCandidate.current_country =
            this.selectedCandidate.current_country || '';
          this.selectedCandidate.linkedin_link =
            this.selectedCandidate.linkedin_link || '';
          this.selectedCandidate.date_first_experience =
            this.selectedCandidate.date_first_experience || '';
          this.selectedCandidate.desired_salary =
            this.selectedCandidate.desired_salary || '';
          this.selectedCandidate.destination_country =
            this.selectedCandidate.destination_country || '';

          console.log('this.selectedCandidate', this.selectedCandidate);
        },
        error: (err: Error) => {
          this.spinner.hide();
        },
      });
  }
  cleanDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    return dateString.replace('admin', '').trim();
  }
  getFormattedDate(): string {
    if (!this.selectedCandidate?.last_connection && this.selectedCandidate?.created_at) {
      return this.cleanDate(this.selectedCandidate?.created_at);
    }
    return this.cleanDate(this.selectedCandidate?.last_connection);
  }
  parseData(name: string) {
    if (
      typeof this.selectedCandidate[name] == 'string' &&
      this.selectedCandidate[name] == 'null'
    ) {
      this.selectedCandidate[name] = [];
    } else {
      if (this.selectedCandidate[name].length)
        this.selectedCandidate[name] = JSON.parse(this.selectedCandidate[name]);
    }
  }


  /* open pdf in new target blank */
  OpenPDF(typeFile?: string) {
    this.spinner.show();
    const fileUrl = !typeFile
      ? `${this.url}/api/User/file/gallery_cvs/${this.selectedCandidate.cv}`
      : `${this.url}/api/ResponseCandidate/GetFile/${this.selectedCandidate.file_test}`;
    this.candidatService.downloadPDF(fileUrl).subscribe((blob) => {
      const fileURL = URL.createObjectURL(blob);
      this.spinner.hide();
      window.open(fileURL, '_blank');
    });
  }
  /* download file */
  downloadFile(typeFile?: string) {
    this.spinner.show();
    const fileUrl = !typeFile
      ? `${this.url}/api/User/file/gallery_cvs/${this.selectedCandidate.cv}`
      : `${this.url}/api/ResponseCandidate/GetFile/${this.selectedCandidate.file_test}`;
    this.candidatService.downloadPDF(fileUrl).subscribe((blob) => {
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = !typeFile
        ? `${this.selectedCandidate.ID_user}_${
            this.selectedCandidate.first_name
          }_${
            this.selectedCandidate.last_name
          }_CV.${this.selectedCandidate!.cv.split('.').pop()}`
        : `${this.selectedCandidate.ID_user}_${
            this.selectedCandidate.first_name
          }_${
            this.selectedCandidate.last_name
          }_QCM.${this.selectedCandidate!.file_test.split('.').pop()}`;
      document.body.appendChild(a);
      a.click();
      this.spinner.hide();
    });
  }

  // downloadFile(typeFile?: string) {
  //   if (typeFile && typeFile != '')
  //     this.selectedCandidate.file_test =
  //       this.selectedCandidate.file_test.split('/')[1];
  //   this.spinner.show();
  //   const fileUrl = !typeFile
  //     ? `${this.url}/api/User/file/gallery_cvs/${this.selectedCandidate.cv}`
  //     : `${this.url}/api/ResponseCandidate/GetFile/${this.selectedCandidate.file_test}`;
  //   this.candidatService.downloadPDF(fileUrl).subscribe((blob) => {
  //     const fileURL = URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = fileURL;
  //     a.download = !typeFile
  //       ? `${this.selectedCandidate.ID_user}_${
  //           this.selectedCandidate.first_name
  //         }_${
  //           this.selectedCandidate.last_name
  //         }_CV.${this.selectedCandidate!.cv.split('.').pop()}`
  //       : `${this.selectedCandidate.ID_user}_${
  //           this.selectedCandidate.first_name
  //         }_${
  //           this.selectedCandidate.last_name
  //         }_QCM.${this.selectedCandidate!.file_test.split('.').pop()}`;
  //     document.body.appendChild(a);
  //     a.click();
  //     this.spinner.hide();
  //   });
  // }
  /* get candidate skills */
  isLastPagee: boolean = false;
  hasPagination: boolean = false;
  isEmptyList: boolean = false;
  formatPeriod(period: string): string {
    if (!period) return '';
  
    const matches = period.match(/(\d+) ans, (\d+) mois, (\d+) jours/);
    if (!matches) return period;
  
    const years = parseInt(matches[1], 10);
    const months = parseInt(matches[2], 10);
  
    if (years === 0 && months === 0) {
      return `moins d'un mois`;
    } else {
      let result = '';
  
      if (years > 0) {
        result += `${years} An${years > 1 ? 's' : ''}`;
      }
      if (months > 0) {
        if (result.length > 0) result += ', ';
        result += `${months} Mois`;
      }
  
      return result;
    }
  }
  getSkills() {
    this.candidatService
      .getSkillsCandidate(this.idCandidat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skills = res.data.categoriesWithSubCategoriesAndSkills.slice(this.startIndex, this.endIndex);
          this.skillsCv = res.data.categoriesWithSubCategoriesAndSkills.slice(this.startIndex, this.endIndex)
          .map((category:any) => ({
            ...category,
            sub_category: category.sub_category
              .map((subCategory:any) => ({
                ...subCategory,
                skills: subCategory.skills.filter((skill:any) => skill.checked === '1')
              }))
              .filter((subCategory:any) => subCategory.skills.length > 0)
          }))
          .filter((category:any) => category.sub_category.length > 0);
          const skillsTextLength = this.selectedCandidate?.skills_text?.length ? 1 : 0;
          this.totalItems = res.data.count + skillsTextLength;
          this.hasPagination = this.totalItems > this.itemsPerPage;
          this.isLastPagee = (this.endIndex >= this.totalItems);
          this.isEmptyList =this.skills?.length===0 && this.selectedCandidate?.skills_text.length === 0;
        },
        error: () => {
          this.toastr.error(serverError);
        },
      });
  }

  capitalizeFirstLetter(string:any) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  } 
  

  exportCvWegest(file?: File) {
    let exportSuccess = false;
    let data = this.idCandidat;
    this.candidatService.exportCvWegestCandidat(file, data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'CvWegest.pdf');
        exportSuccess = true;
        if (exportSuccess) {
          this.toastr.success('Fichier exporté avec succès');
        }
        window.location.reload();
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error(serverError);
      },
    });
  }
  exportCvWordWegest(file?: File) {
    let exportSuccess = false;
    let data = this.idCandidat;
    this.candidatService.exportCvWegestWordCandidat(file, data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'CvWegest.docx');
        exportSuccess = true;
        if (exportSuccess) {
          this.toastr.success('Fichier exporté avec succès');
        }
        window.location.reload();
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error(serverError);
      },
    });
  }
  exportCvAnonymePdf(file?: File) {
    let exportSuccess = false;
    let data = this.idCandidat;
    this.candidatService.exportCvAnonymePdf(file, data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'CvWegest.pdf');
        exportSuccess = true;
        if (exportSuccess) {
          this.toastr.success('Fichier exporté avec succès');
        }
        window.location.reload();
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error(serverError);
      },
    });
  }
  exportCvAnonymeWord(file?: File) {
    let exportSuccess = false;
    let data = this.idCandidat;
    this.candidatService.exportCvAnonymeWord(file, data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'CvWegest.docx');
        exportSuccess = true;
        if (exportSuccess) {
          this.toastr.success('Fichier exporté avec succès');
        }
        window.location.reload();
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error(serverError);
      },
    });
  }

  updateCandidate() {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat/update',
      { idCandidat: this.selectedCandidate.id },
    ]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Empêche le menu contextuel par défaut
  }

  /**
 * Exports a CV as a file. Depending on the type, it will either export an ESN CV or an anonymous ESN CV.
 * @param {File} [file] - Optional file object to include in the export request.
 * @param {boolean} isAnonymous - Indicates whether to export an anonymous CV (true) or a regular ESN CV (false).
 */
exportCvEsnCandidate(isAnonymous: boolean, file?: File): void {
  const exportSuccess = false;
  const data = this.idCandidat;

  const exportMethod = isAnonymous 
    ? this.candidatService.exportCvAnonymousESNCandidate(file, data)
    : this.candidatService.exportCvESNCandidate(file, data);

  exportMethod.subscribe({
    next: (res: any) => {
      this.spinner.hide();
      
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      
      const fileName = isAnonymous ? 'CvAnonymeESN.pdf' : 'CvESN.pdf';
      saveAs(blob, fileName);

      if (exportSuccess) {
        this.toastr.success('Fichier exporté avec succès');
      }
    },
    error: () => {
      this.spinner.hide();
      this.toastr.error(serverError);
    },
  });
}

  /**
 * Checks if a candidate is an ESN consultant
 * 
 * @param roles - candidate's roles
 * @returns boolean - Returns true if the candidate is an ESN consultant
 */
    checksEsnConsultant(roles: string[]): boolean {
      return roles.includes(this.ESN_CONSULTANT_ROLE);
}

}
