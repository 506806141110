
<div id="kt_app_content_container" class="container-fluid">
    <!--begin::Card-->
    <div class="card mb-15 pb-10">
        <div class="title">
            <h4 class="">Gestion des permissions</h4>
        </div>
        <!--begin::Card header-->
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-0">
            <!--begin::Table-->
            <div class="content" id="add_edit_details_offer" tabindex="-1" aria-hidden="true">
                <!--begin::Modal dialog-->


                <div class="content">

                    <div class="permissions">
                        <div class="d-flex justify-content-center">
                            <div class="cardd">

                                <div class="example-container">
                                    <h2 class="mb-5">Les permissions accordées({{listPermissionsRoles.length}})</h2>
                                    <div cdkDropList #listOne="cdkDropList" [cdkDropListData]="listPermissionsRoles"
                                        [cdkDropListConnectedTo]="[listTwo]" class="4 example-list "
                                        (cdkDropListDropped)="onDrop($event,listPermissionsRoles)">
                                        <div *ngFor="let item of listPermissionsRoles" cdkDrag>


                                            <div *ngIf="item.titre" class="example-box family-box"> {{item.titre}}</div>
                                            <div cdkDropList #childListOne="cdkDropList"
                                                [cdkDropListData]="item.children" class="nested-drag-list"
                                                [cdkDropListConnectedTo]="[listTwo]"
                                                (cdkDropListDropped)="onChildDrop($event,item.children)">
                                                <div *ngFor="let childItem of item.children"
                                                    class="nested-drag-item">
                                                    <div class="example-box" cdkDrag>{{childItem.name}}</div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="cardd">
                                <div class="example-container">
                                    <h2 class="mb-5">Les permissions disponibles({{listPermissions.length}})</h2>
                                    <div cdkDropList #listTwo="cdkDropList" [cdkDropListData]="listPermissions"
                                        [cdkDropListConnectedTo]="[listOne]" class="example-list"
                                        (cdkDropListDropped)="onDrop($event,listPermissions)">
                                        <div *ngFor="let item of listPermissions" cdkDrag>
                                            <div *ngIf="item.titre" class="example-box family-box"> {{item.titre}}</div>
                                            <div cdkDropList #childListTwo="cdkDropList"
                                                [cdkDropListData]="item.children" class="nested-drag-list"
                                                [cdkDropListConnectedTo]="[listOne]"
                                                (cdkDropListDropped)="onChildDrop($event,item.children)">
                                                <div *ngFor="let childItem of item.children"
                                                    class="nested-drag-item">
                                                    <div class="example-box" cdkDrag>{{childItem.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       
                    </div>
                    <div class="footer-wrapper mt-10">
                        <!--begin::Button-->
                        <button type="reset" (click)="clearData()" data-bs-dismiss="modal" aria-label="Close"
                            id="kt_modal_add_customer_cancel" class="footer-btn annuler mb-3">Annuler</button>
                        <!--end::Button-->
                        <!--begin::Button-->
                        <button type="submit" [disabled]="!eventsDropped" (click)="assignPermissions()"
                            id="kt_modal_add_customer_submit" class="footer-btn confirmer mb-3">
                            <span class="indicator-label">Confirmer </span>
                            <span class="indicator-progress">Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                        <!--end::Button-->
                    </div>

                </div>
                <!--begin::Modal content-->
                <!-- <div class="modal-content"> -->
                <!--begin::Form-->

                <!--end::Form-->
                <!-- </div> -->

            </div>


            <!--end::Table-->
        </div>
        <!--end::Card body-->
    </div>
    <!--end::Card-->
    <!--begin::Modals-->
    <!--begin::Modal - Customers - Add-->

    <!--end::Modal - Customers - Add-->
    <!--begin::Modal - Adjust Balance-->

    <!--end::Modal - New Card-->
    <!--end::Modals-->
</div>