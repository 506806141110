import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-wegestu',
  templateUrl: './contact-wegestu.component.html',
  styleUrls: ['./contact-wegestu.component.css']
})
export class ContactWegestuComponent {

}
