import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientFinalComponent } from './client-final.component';
import { ListIntervenantComponent } from './components/list-intervenant/list-intervenant.component';
import { ListConsultantComponent } from './components/list-consultant/list-consultant.component';
import { DetailsClientComponent } from './components/details-client/details-client.component';
import { AppelOffreComponent } from './components/appel-offre/appel-offre.component';
import { ListsalariesComponent } from './components/listsalaries/listsalaries.component';
import { ListprojetsComponent } from './components/listprojets/listprojets.component';

const routes: Routes = [
    {
      path: '',
      redirectTo: '',
      pathMatch: 'full',
    },
  {
    path:'',
    component:ClientFinalComponent,
  },
  {
    path:'list-intervenant-client-final',
    component:ListIntervenantComponent,
  },
  {
    path:'list-consultant-client-final',
    component:ListConsultantComponent,
  },
  {
    path:'details-client-final',
    component:DetailsClientComponent,
  },
  {
    path:'appel-offre',
    component:AppelOffreComponent,
  },
  {
    path:'list-salarie-client-final',
    component:ListsalariesComponent,
  },
  {
    path:'list-projet-client-final',
    component:ListprojetsComponent,
  },

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientFinalRoutingModule { }
