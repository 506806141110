import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedCompany,
  existedCompany,
  existedLinkedIn,
  existedWebSite,
  existedWebSiteLinkedIn,
} from '../../../../core/models/messages';
import {
  linkedCompanyRegExp,
  urlRegExp,
} from './../../../../core/models/pattern';
import { CompanyService } from './../../../../core/services/company/company.service';
import { FieldErrorModule } from './../../../../shared/components/shared-field-error/field-error.module';
import { environment } from './../../../../../environments/environment';

@Component({
  selector: 'app-unitary-add-company',
  templateUrl: './unitary-add-company.component.html',
  styleUrls: ['./unitary-add-company.component.css'],
  standalone: true,
  imports: [CommonModule, NgxSpinnerModule, FieldErrorModule],
})
export class UnitaryAddCompanyComponent {
  @Input() typeCompany!: string;
  @Output() closeModalUnitaryAdd: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  file: any;
  selectedImage: any;
  /* string */
  mode: string = 'create';
  fileError: string = '';
  fileExtention: string = '';
  fileName: string = '';
  url: string = environment.baseUrl + '/api';
  /* arrays */
  typesCompanies = [
    { id: 2, name: 'ESN' },
    { id: 4, name: 'Cabinet de Recrutement' },
  ];
  /* formGroup */
  companyForm: FormGroup = this.createCompanyForm();
  websiteLinkValue: string = '';
  /* form data */
  formData = new FormData();
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private companyService: CompanyService,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit() {
    if (this.typeCompany == 'esn') {
      this.companyForm.get('type')?.setValidators(Validators.required);
      this.companyForm.get('type')?.updateValueAndValidity();
    }
  }
  /* create add subCategory form */
  createCompanyForm(data?: any) {
    return this.formBuilder.group({
      name: [data ? data.name : '', [Validators.required]],
      type: [data ? data.type : null],
      linkedin_link: [
        data ? data.linkedin_link : '',
        [Validators.pattern(linkedCompanyRegExp)],
      ],
      website_link: [
        data ? data.website_link : '',
        [Validators.pattern(urlRegExp)],
      ],
      logo: [data ? data.logo : ''],
    });
  }

  private extractLinkedInUsername(url: string): string {
    const prefix = 'https://www.linkedin.com/in/';
    if (url.startsWith(prefix)) {
      return '/' + url.substring(prefix.length);
    }
    return url;
  }
  closeModal() {
    this.closeModalUnitaryAdd.emit(true);
    this.companyForm.reset();
    this.formData = new FormData();
    this.file = null;
    this.fileError = '';
    this.fileExtention = '';
  }
  /* upload file */
  uploadFile(event: any): void {
    // Reset error messages
    this.fileExtention = '';
    this.fileError = '';

    const fileInput = event.target;
    const file = fileInput.files[0];
    const maxSize = 2 * 1024 * 1024;
    const allowedExtensions = [
      '.jpeg',
      '.jpg',
      '.png',
      '.gif',
      '.jfif',
      '.avif',
    ];

    // Check if the file input is empty (user canceled selection)
    if (!file) {
      // Clear file input and related variables
      fileInput.value = '';
      this.file = null;
      this.fileName = '';
      this.selectedImage = null;
      return;
    }

    // Set file and file name
    this.file = file;
    this.fileName = this.file.name;

    const fileExtension = file.name
      .toLowerCase()
      .substr(file.name.lastIndexOf('.'));

    if (!allowedExtensions.includes(fileExtension)) {
      this.fileExtention = 'Format fichier non compatible !';
      // Clear file input and related variables
      // fileInput.value = '';
      // this.file = null;
      // this.fileName = '';
      // this.selectedImage = null;
    } else if (file.size > maxSize) {
      this.fileError = 'File size exceeds the maximum allowed size (2MB).';
      // Clear file input and related variables
      fileInput.value = '';
      this.file = null;
      this.fileName = '';
      this.selectedImage = null;
    } else {
      // Reset other error messages
      this.fileError = '';

      const reader = new FileReader();
      reader.onload = () => {
        this.selectedImage = reader.result;
      };
      reader.readAsDataURL(file);
    }

    // Set the file in the form group
    this.companyForm.get('logo')?.setValue(this.file);
  }

  resetImage() {
    const inputFile = document.getElementById('logo') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = '';
    }
    this.file = null;
    this.fileExtention = '';
    this.fileError = '';
    this.fileName = '';
  }

  /* Custom validator function : requirement CV or Linkedin  */
  setValidatorRequirements() {
    if (
      !this.companyForm.get('linkedin_link')?.value ||
      this.companyForm.get('linkedin_link')?.value == ''
    ) {
      this.companyForm
        .get('website_link')
        ?.setValidators([Validators.required, Validators.pattern(urlRegExp)]);
      this.companyForm.get('website_link')!.updateValueAndValidity();
      this.companyForm.get('linkedin_link')?.clearValidators();
      this.companyForm.get('linkedin_link')?.updateValueAndValidity();
    }
    if (this.companyForm.get('website_link')?.value == '') {
      this.companyForm
        .get('linkedin_link')
        ?.setValidators([
          Validators.required,
          Validators.pattern(linkedCompanyRegExp),
        ]);
      this.companyForm.get('linkedin_link')!.updateValueAndValidity();
      this.companyForm.get('website_link')?.clearValidators();
      this.companyForm
        .get('website_link')
        ?.setValidators(Validators.pattern(urlRegExp));
      this.companyForm.get('website_link')?.updateValueAndValidity();
    }
  }
  /* set validation for multiple form control */
  setValidations(name: string) {
    this.companyForm.get(name)?.setValidators(Validators.required);
    this.companyForm.get(name)!.updateValueAndValidity();
  }
  /* clearValidators */
  clearValidators(name: string) {
    this.companyForm.get(name)?.clearValidators();
    this.companyForm.get(name)?.updateValueAndValidity();
  }
  /*  add new company */
  addCompany() {
    this.setValidatorRequirements();
    if (this.companyForm.valid) {
      this.setFormData();
      this.spinner.show();
      this.companyService
        .addCompany(this.formData)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: () => {
            this.toastr.success(addedCompany);
            this.closeModal();
            this.spinner.hide();
          },
          error: (err) => {
            this.spinner.hide();
            if (err.error?.message?.name) this.toastr.error(existedCompany);
            else if (
              err.error?.message?.linkedin_link &&
              !err.error?.message?.website_link
            )
              this.toastr.error(existedLinkedIn);
            else if (
              err.error?.message?.website_link &&
              !err.error?.message?.linkedin_link
            )
              this.toastr.error(existedWebSite);
            else if (
              err.error?.message?.website_link &&
              err.error?.message?.linkedin_link
            )
              this.toastr.error(existedWebSiteLinkedIn);
            this.closeModal();
            // else this.toastr.error(serverError)
          },
        });
    }
  }
  /* set form data */
  setFormData() {
    this.typeCompany == 'esn'
      ? this.companyForm.value.type
      : (this.companyForm.value.type = 3);
    this.formData.append('name', this.companyForm.value.name);
    this.formData.append('type', this.companyForm.value.type);
    if (this.companyForm.value.linkedin_link)
      this.formData.append(
        'linkedin_link',
        this.companyForm.value.linkedin_link
      );
    if (this.companyForm.value.website_link)
      this.formData.append('website_link', this.companyForm.value.website_link);
    if (this.file) this.formData.append('logo', this.file);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
