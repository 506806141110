import { Component, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { IStatic } from '../../../../core/models/dasboard';
import { DashboardService } from '../../../../core/services/dashboard/dashboard.service';
@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css'],
})
export class GeneralComponent {
  @ViewChild('chart') chart!: ChartComponent;

  chartOptionsLine!: any;
  chartOptionsPie!: any;
  chartOptionsbar!: any;
  statisticConnectionOverTime: any;
  statisticOverTime: any;
  statisticUpdateOverTime: any;
  statisticRegistrationsOverTime: any;
  /* objects */
  statistic!: IStatic;
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private toastrService: ToastrService,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit() {
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');

    this.chartOptionsLine = {
      series: [
        {
          name: 'Gold',
          data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
        },
        {
          name: 'Silver',
          data: [100, 250, 300, 350, 400, 450, 500, 550, 600, 650],
        },
        {
          name: 'Platinum',
          data: [0, 50, 10, 200, 20, 600, 700, 800, 1200, 1600],
        },
      ],
      chart: {
        type: 'line',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      xaxis: {
        categories: [
          'South Korea',
          'Canada',
          'United Kingdom',
          'Netherlands',
          'Italy',
          'France',
          'Japan',
          'United States',
          'China',
          'Germany',
        ],
      },
    };
    this.chartOptionsbar = {
      series: [
        {
          name: 'Gold',
          data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
        },
        {
          name: 'Silver',
          data: [100, 250, 300, 350, 400, 450, 500, 550, 600, 650],
        },
        {
          name: 'Platinum',
          data: [0, 50, 10, 200, 20, 600, 700, 800, 1200, 1600],
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      xaxis: {
        categories: [
          'South Korea',
          'Canada',
          'United Kingdom',
          'Netherlands',
          'Italy',
          'France',
          'Japan',
          'United States',
          'China',
          'Germany',
        ],
      },
    };
    this.chartOptionsPie = {
      series: [44, 55, 100],
      chart: {
        width: 380,
        type: 'donut',
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
      },
      legend: {
        // formatter: function(val:any, opts:any) {
        //   console.log('val',val);
        //   return val
        // }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
    this.getGeneralStatic();
  }
  nb_ESN_active: any;
  getGeneralStatic() {
    this.spinner.show();
    this.dashboardService
      .getGeneralStatic()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.statistic = res.data;
            this.nb_ESN_active = res.data.nb_ESN_active;
          }
        },
        error: (err) => {
          this.spinner.hide();
        },
      });
  }
}
