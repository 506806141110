import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { JobOfferService } from 'src/app/core/services/job-offer/job-offer.service';

@Component({
  selector: 'app-dossiercandidature',
  templateUrl: './dossiercandidature.component.html',
  styleUrls: ['./dossiercandidature.component.css']
})
export class DossiercandidatureComponent {
  id = this.activatedRoute.snapshot.params['id'];
  idoffer = this.activatedRoute.snapshot.params['id_offer'];
  candidaturedetails:any

  constructor(
    private activatedRoute: ActivatedRoute,
    private jobOfferService: JobOfferService,
    private spinner: NgxSpinnerService,
 
  ) {}
  ngOnInit() {
    this.getcandidaturebyid();

  }

  /* get job offer details */
  getcandidaturebyid() {
    this.spinner.show();
    this.jobOfferService.getcandidatureById(this.id,this.idoffer).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        console.log('res detail job offer', res);
        this.candidaturedetails = res.data[0];
       
        console.log('detail job offer', this.candidaturedetails);
      },
      error: () => {},
    });
  }
}
