import { Component, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { SettingsService } from '../../../../../core/services/settings/settings.service';
import {
  addedQuestion,
  confirmDelete,
  deletedQuestion,
  existedEvaluation,
  failedDelete,
  serverError,
  updatedQuestion,
} from '../../../../../core/models/messages';
import { IQuestion } from '../../../../../../app/core/models/setting';
import Swal from 'sweetalert2';
import { environment } from '../../../../../../environments/environment';
import { Router } from '@angular/router';
import { CandidatService } from 'src/app/core/services/candidat/candidat.service';

@Component({
  selector: 'app-liste-evaluation',
  templateUrl: './liste-evaluation.component.html',
  styleUrls: ['./liste-evaluation.component.css'],
})
export class ListeEvaluationComponent {
  typesCompany = [
    { name: 'Candidat', id: 1 },
    { name: 'Entreprise', id: 2 },
  ];
  /* string */
  mode: string = 'create';
  // url: string =environment.baseUrl+'/api/Company/file/gallery_company/'
  url: string = environment.baseUrl + '/api';

 /* numbers */
 itemsPerPage: number = 5
 currentPage: number = 1
 itemsPerPage1: number = 5
 currentPage1: number = 1
 itemsPerPage2: number = 5
 currentPage2: number = 1
 itemsPerPage3: number = 5
 currentPage3: number = 1
 pagination : boolean =false;

 isLoadSpinner: boolean = true;

 totalItems!: number
 totalItems1!: number
 totalItems2!: number
 totalItems3!: number

 startIndex1:number=0
 endIndex1:number=5
 review:any;
 startIndex2:number=0
 endIndex2:number=5

 startIndex3:number=0
 endIndex3:number=5

startIndex:number=0
endIndex:number=5
idQuestion!:number | undefined
idCompany:number=1
max: number = 5;
   value: number = 3;
   totalComments!: number 
/* modal */
modalRef?: BsModalRef;
/* array */
listQuestion:IQuestion[]=[]
listQuestionCompany:IQuestion[]=[]
listQuestionCandidat:IQuestion[]=[]

listComments:any=[]
/*any */
data:any
listCandidatReview:any
listCandidatReviewByCandidat:any
listCandidatReviewByCompany:any

listCandidatReviewDetails:any=[]
/* formGroup */
questionForm: FormGroup = this.createQuestionForm()
isTouched: boolean = false;
// editor text
editorContent: string = '<p>Hello World!</p>';
isModified: boolean = false;
/* subscriprion */
private unsubscribeAll: Subject<void> = new Subject();
constructor(private settingService: SettingsService, private toastr: ToastrService,
  private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
  private modalService: BsModalService,private router:Router,private candidatService:CandidatService) { }
  toolbarOptions = [
    ['bold', 'italic', 'underline'], // toggled buttons
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    ['clean'], // remove formatting button
  ];

/* init */
ngOnInit() {
  this.questionForm.valueChanges.subscribe(() => {
    this.questionForm.markAsTouched();
  });
  // this.getListQuestion()
  // this.getCandidatReview()
  // this.getCandidatReviewDetails()

  this.getListQuestionCandidat()
  this.getuserReviewCandidat()

}
// onFieldChange() {
//   this.questionForm.markAsDirty();
// }

  /* get indexes for pagination */
 getItems(event?: any) {
  if (event && event.startIndex != this.startIndex || this.endIndex != event.endIndex ||
    this.itemsPerPage != event.itemsPerPage ||
    this.currentPage != event.currentPage) {
    this.startIndex = event.startIndex
    this.endIndex = event.endIndex
    this.itemsPerPage = event.itemsPerPage,
    this.currentPage = event.currentPage
     if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
      this.pagination = true
      this.getListQuestionCandidat()
    }
  }
}
getItems1(event?: any) {
  if (event && event.startIndex != this.startIndex1 || this.endIndex1 != event.endIndex ||
    this.itemsPerPage1 != event.itemsPerPage ||
    this.currentPage1 != event.currentPage) {
    this.startIndex1 = event.startIndex
    this.endIndex1 = event.endIndex
    this.itemsPerPage1 = event.itemsPerPage,
    this.currentPage1 = event.currentPage
     this.getListQuestionCompany()
  }
}
getItems2(event?: any) {
  if (event && event.startIndex != this.startIndex2 || this.endIndex2 != event.endIndex ||
    this.itemsPerPage2 != event.itemsPerPage ||
    this.currentPage2 != event.currentPage) {
    this.startIndex2 = event.startIndex
    this.endIndex2 = event.endIndex
    this.itemsPerPage2 = event.itemsPerPage,
    this.currentPage2 = event.currentPage
     this.getuserReviewCandidat()
  }
}
getItems3(event?: any) {
  if (event && event.startIndex != this.startIndex3 || this.endIndex3 != event.endIndex ||
    this.itemsPerPage3 != event.itemsPerPage ||
    this.currentPage3 != event.currentPage) {
    this.startIndex3 = event.startIndex
    this.endIndex3 = event.endIndex
    this.itemsPerPage3 = event.itemsPerPage,
    this.currentPage3 = event.currentPage
     this.getuserReviewCompany()
  }
}

 openModal(template: TemplateRef<any>, mode: string, data?: IQuestion) {
  this.mode = mode
  if (mode == 'update') {
    this.idQuestion = data?.id
    this.questionForm = this.createQuestionForm(data)
  }
  this.modalRef = this.modalService.show(template, { backdrop: 'static' });
}
closeModal() {
  this.modalRef?.hide();
  this.questionForm.reset()
}
 getListQuestion() {
  this.spinner.show()
  let data = {per_page: this.itemsPerPage, page: this.currentPage}
  this.settingService.getListQuestion(this.idCompany,data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      if (res.status == 200) {
        this.spinner.hide()
        this.data=res
        console.log("data",res.data)
        this.totalItems = res.data.total
        this.listQuestion = res?.data?.data
        if(this.currentPage>1 && !res.data.data.length){
          this.currentPage=1
          this.getListQuestion()
        } 
      }
    },
    error: () => {
      this.spinner.hide()
      this.toastr.error(serverError)
    }
  })
}
getListQuestionCandidat() {
  this.spinner.show()
  let data = {per_page: this.itemsPerPage, page: this.currentPage}
  this.settingService.getListQuestioncandidat(this.idCompany,data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      if (res.status == 200) {
        this.isLoadSpinner = false;
        this.spinner.hide()
        this.data=res
        console.log("data",res.data)
        this.totalItems = res.data.total
        this.listQuestionCandidat = res?.data?.data
        console.log("list question candidat", this.listQuestionCandidat)
        if(this.currentPage>1 && !res.data.data.length){
          this.currentPage=1
          this.getListQuestionCandidat()
        } 
      }
    },
    error: () => {
      this.spinner.hide()
      this.toastr.error(serverError)
    }
  })
}

getListQuestionCompany() {
  this.spinner.show()
  let data = {per_page: this.itemsPerPage1, page: this.currentPage1}
  this.settingService.getListQuestioncompany(this.idCompany,data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      if (res.status == 200) {
        this.spinner.hide()
        this.data=res
        console.log("data",res.data)
        this.totalItems1 = res.data.total
        this.listQuestionCompany = res?.data?.data
        console.log("list question company", this.listQuestionCompany)
        if(this.currentPage1>1 && !res.data.data.length){
          this.currentPage1=1
          this.getListQuestionCompany()
        } 
      }
    },
    error: () => {
      this.spinner.hide()
      this.toastr.error(serverError)
    }
  })
}

getuserReviewCandidat() {
  this.spinner.show();
  let data = { per_page: this.itemsPerPage2, page: this.currentPage2 };
  console.log("data",data)
  this.settingService.getListusersReviewCandidat(this.idCompany, data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      if (res.status == 200) {
        this.spinner.hide();
        console.log("response", res);
        this.totalItems2 = res.data.total;
        this.listCandidatReviewByCandidat = res.data?.data
        console.log("listCandidatReview by candidat", this.listCandidatReviewByCandidat);
        console.log("length listCandidatReview", this.listCandidatReviewByCandidat.length);
        console.log("total", res.data.total);
        if (res.data.current_page > 1) {
          // this.currentPage1=1
          // Convertir la deuxième data de la réponse en tableau JSON
          let secondData = Object.values(res.data.data);
          this.listCandidatReviewByCandidat = secondData;
          console.log("list after update candidat", this.listCandidatReviewByCandidat);
        }
      }
    },
    error: () => {
      this.spinner.hide();
      this.toastr.error(serverError);
    }
  });
}
getuserReviewCompany() {
  this.spinner.show();
  let data = { per_page: this.itemsPerPage3, page: this.currentPage3 };
  console.log("data",data)
  this.settingService.getListusersReviewCompany(this.idCompany, data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      console.log("responseeeee",res)
      if (res.status == 200) {
        this.spinner.hide();
        console.log("response", res);
        this.totalItems3 = res.data.total;
        this.listCandidatReviewByCompany = res.data.data
        console.log("listCandidatReview by company", this.listCandidatReviewByCompany);
        console.log("length listCandidatReview", this.listCandidatReviewByCompany.length);
        console.log("total", res.data.total);
        if (res.data.current_page > 1) {
          // this.currentPage1=1
          // Convertir la deuxième data de la réponse en tableau JSON
          let secondData = Object.values(res.data.data);
          this.listCandidatReviewByCompany = secondData;
          console.log("list after update company", this.listCandidatReviewByCompany);
        }
      }
    },
    error: () => {
      this.spinner.hide();
      this.toastr.error(serverError);
    }
  });
}


getCandidatReview() {
  this.spinner.show();
  let data = { per_page: this.itemsPerPage1, page: this.currentPage1 };
  console.log("data",data)
  this.settingService.getListCandidatReview(this.idCompany, data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: (res) => {
      if (res.status == 200) {
        this.spinner.hide();
        console.log("response", res);
        this.totalItems1 = res.data.total;
        this.listCandidatReview = res.data.data
        console.log("listCandidatReview", this.listCandidatReview);
        console.log("length listCandidatReview", this.listCandidatReview.length);
        console.log("total", res.data.total);
        if (res.data.current_page > 1) {
          // this.currentPage1=1
          // Convertir la deuxième data de la réponse en tableau JSON
          let secondData = Object.values(res.data.data);
          this.listCandidatReview = secondData;
          console.log("list after update", this.listCandidatReview);
        }
      }
    },
    error: () => {
      this.spinner.hide();
      this.toastr.error(serverError);
    }
  });
}


detailsReviewNavigation(id: any, type:any) {
  this.router.navigate(['/acceuil/parametres/detail-avis-wegestu', { type:type , idDonneur: id  }])
}
//  getListComment() {
//   this.spinner.show()
//   let data = {per_page: this.itemsPerPage, page: this.currentPage}
//   this.settingService.getListComment(this.idCompany,data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
//     next: (res) => {
//       if (res.status == 200) {
//         this.spinner.hide()
//         this.totalComments=res.data.total
//         this.listComments = res.data.data
//       }
//     },
//     error: () => {
//       this.spinner.hide()
//       this.toastr.error(serverError)
//     }
//   })
// }
/* create add question form */
createQuestionForm(data?: IQuestion) {
  return this.formBuilder.group({
    question_FR: [data ? data.question_FR : "", [Validators.required,this.notOnlySpacesValidator()]],
    type: [''],
  })
 
}
notOnlySpacesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value as string;
    const isValid = value?.trim() !== '';
    return isValid ? null : { onlySpaces: true };
  };
}
/* confirm click either to add or to update question */
submitData(){
  if (this.mode == 'create') {
    this.addQuestion()
  } else {
    this.updateQuestion()
  }
}
/* add question */

  addQuestion() {
    this.questionForm.get('type')?.setValue(this.idCompany);
    if (this.questionForm.valid) {
      this.spinner.show();
      this.settingService.addQuestion(this.questionForm.value).pipe(takeUntil(this.unsubscribeAll)).subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListQuestionCandidat()
            this.getListQuestionCompany()

            this.closeModal()
            this.spinner.hide()
            this.toastr.success(addedQuestion)
          }
        },
        error: (err) => {
          this.spinner.hide();
          if (err?.error) {
            if (err.error?.message && err.error?.message?.question_FR?.[0] === 'The question_FR has already been taken.') {
              this.toastr.info(existedEvaluation);
            } else if (err.error.message?.question_FR?.[0] === 'The question  f r must not be greater than 4000 characters.') {
              this.toastr.error('La question ne doit pas dépasser 4000 caractères.');
            } else {
              this.toastr.error(serverError);
            }
          }
        }
      })
    }
  }
  /* update question */
  updateQuestion() {
    this.questionForm.get('type')?.setValue(this.idCompany);
    if (this.questionForm.valid) {
      this.spinner.show();
      this.settingService.updateQuestion(this.idQuestion, this.questionForm.value).pipe(takeUntil(this.unsubscribeAll)).subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListQuestionCandidat()
            this.getListQuestionCompany()

            this.closeModal()
            this.spinner.hide()
            this.toastr.success(updatedQuestion)
            
          }
        },
        error: (err) => {
          this.spinner.hide();
          if (err?.error) {
            if (err.error?.message && (err.error?.message?.question_FR?.[0] === 'The question  f r has already been taken.' || err.error?.message?.question_FR?.[1] === 'he question_FR has already been taken.')) {
              this.toastr.info(existedEvaluation);
            }
            if (err.error.message?.question_FR?.[0] === 'The question  f r must not be greater than 4000 characters.') {
              this.toastr.error('La question ne doit pas dépasser 4000 caractères.');
            } else {
              this.toastr.error(serverError);
            }
          }
        }
      });
    }
  }
  navigatToDetailsCandidat(idCandidat: any) {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  navigatToDetailsCompany(id: any) {
    this.router.navigate(['/acceuil/entreprises/esn/profil-esn', { id: id }]);
  }
  /* open delete alert */
  openDeleteAlert(question: IQuestion) {
    Swal.fire({
      title: `${confirmDelete} ce question?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteQuestion(question.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }

/* delete question */
deleteQuestion(id:number){
this.spinner.show()
this.settingService.deleteQuestion(id).pipe(takeUntil(this.unsubscribeAll)).subscribe({
  next: (res) => {
    if (res.status == 200) {
      this.getListQuestionCompany()
      this.getListQuestionCandidat()

      this.spinner.hide()
      this.toastr.success(deletedQuestion)
    }
  },
  error: () => {
    this.spinner.hide()
    this.toastr.error(serverError)
  }
})
}
changeTab2(index:number){
  this.idCompany=index
  this.itemsPerPage1 = 5
  this.currentPage1 = 1
  this.itemsPerPage3= 5
  this.currentPage3= 1
  this.getListQuestionCompany()
  this.getuserReviewCompany()
}

changeTab1(index:number){
  this.idCompany=index
  this.itemsPerPage= 5
  this.currentPage= 1
  this.itemsPerPage2= 5
  this.currentPage2= 1
   this.getListQuestionCandidat()
   this.getuserReviewCandidat()
}
showComments(){
  this.router.navigate(['/acceuil/parametres/evaluation-wegestu',{id:this.idCompany}])
}

  openModalDetails(template: TemplateRef<any>, idDonneur: any,review:any,idCompany:any) {

    console.log('idDonneur', idDonneur);
    console.log("review ",review);


    this.modalRef = this.modalService.show(template, { backdrop: 'static' });


    this.review=review;
   
  
    this.getCandidatReviewDetails(idDonneur);
  }

  openModalDetailsCompany(template: TemplateRef<any>, idDonneur: any,review:any,idCompany:any) {

    console.log('idDonneur', idDonneur);
    console.log("review ",review);


    this.modalRef = this.modalService.show(template, { backdrop: 'static' });


    this.review=review;
   
  
    this.getCompanyReviewDetails(idDonneur);
  } 

  closeModalDetails() {
    this.modalRef!.hide();
  }
  getCandidatReviewDetails(idDonneur?: any) {
    this.spinner.show();
    let data = { per_page: this.itemsPerPage, page: this.currentPage };
    this.settingService
      .getCandidatReviewById(this.idCompany, idDonneur, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listCandidatReviewDetails = res;
            console.log(
              'ReviewDetails',
              this.listCandidatReviewDetails
            );
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  getCompanyReviewDetails(idDonneur?: any) {
    this.spinner.show();
    let data = { per_page: this.itemsPerPage, page: this.currentPage };
    this.settingService
      .getCandidatReviewById(this.idCompany, idDonneur, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listCandidatReviewDetails = res;
            console.log(
              'listCandidatReviewDetails',
              this.listCandidatReviewDetails
            );
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  } 
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Empêche le menu contextuel par défaut
  }
}
