import { Injectable } from '@angular/core';
import { CrudService } from '../crud/crud.service';
import { ApiResponse } from '../../models/response-api';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TenderService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get list appel d'offre */
  getListRequestOffer(data?: {
    per_page?: number;
    page?: number;
    sort?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`;
    console.log(
      'urlll',
      this._base +
        `/JobRequest/getByClientFinal?per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/JobRequest/getByClientFinal?per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
  }
  /* search */
  findDoubleQuotes(str: string): number[] {
    const indices: number[] = [];
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '"') {
        indices.push(i);
      }
    }
    return indices;
  }
  searchRequestOffer(data: any) {
    let tabs = this.findDoubleQuotes(data.search);

    if (tabs.length <= 2) data.search = '"' + data.search + '"';
    console.log(
      'data of searchCandidat',
      'count = ' + tabs.length,
      data.search
    );
    return this._http.post<ApiResponse<any>>(
      this._base + `/JobRequest/search`,
      data
    );
  }
  /* get skills */
  getSkills() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/SubCategory/getListSubCateg`
    );
  }
  /* get details resuest job */
  getRequestJobById(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/JobRequest/getById/${id}`
    );
  }
  /* get details job offer */
  getJobOfferById(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/JobOffer/getById/${id}`
    );
  }
  getskillsByJobOffer(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/SkillsJob/getByJobOffer?job_offer_id=${id}`
    );
  }
  getskillsByJobRequest(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/SkillsJob/getByJobRequest?job_request_id=${id}`
    );
  }

  /* Activer */
  activedesactiveJobrequest(jobRequest_id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/JobRequest/ActivateDesactivate?jobRequest_id=${jobRequest_id}`
    );
  }

  getBarem(job_request_id: number) {
    console.log(
      'url',
      this._base +
        `/ScoreJob/getScoreJobRequestDetails?job_request_id=${job_request_id}`
    );
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ScoreJob/getScoreJobRequestDetails?job_request_id=${job_request_id}`
    );
  }

  getSkillsJobs() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/SubCategory/getListSubCateg`
    );
  }

  getcandidatureById(user_id : any,job_request_id:any): Observable<ApiResponse<any>> {
    return this._http.get<ApiResponse<any>>(
      this._base + `/CompanyApply/getDetailsCandidatureJobRequest?user_id=${user_id}&job_request_id=${job_request_id}`
    );
  }
}
