import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ChartComponent } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { DashboardService } from '.././../../../core/services/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard-client-final',
  templateUrl: './dashboard-client-final.component.html',
  styleUrls: ['./dashboard-client-final.component.css'],
})
export class DashboardClientFinalComponent {
  @ViewChild('chart') chart!: ChartComponent;

  chartOptionsLine!: any;
  chartOptionsPie!: any;
  chartOptionsbar!: any;
  statisticRegistrationsOverTime: any;
  statisticCandidatesByCountry: any;
  statisticregistrationCandidats: any;
  statisticAddedByAdmin: any;
  private unsubscribeAll: Subject<void> = new Subject();
  candidatOverTime: any;
  searchForm: FormGroup = this.RegistrationOverTimeForm();

  constructor(
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.getClientOverTime();
    this.getClientByCountry();
    this.getAddedClientByAdmin();
    this.getRegistrationClient();
  }

  getClientOverTime(): void {
    this.dashboardService.getClientOverTime().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.candidatOverTime = res.data;
          this.updateChartLine();
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  updateChartLine(): void {
    // const months = [
    //   "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    //   "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    // ];
    const months = this.candidatOverTime.registrations.map(
      (item: any) => item.month
    );
    const hasMonthData = Object.values(this.candidatOverTime).some(
      (item: any) => item.some((data: any) => data.month)
    );
    const years = this.candidatOverTime.registrations.map(
      (item: any) => item.year
    );
    const hasYearData = Object.values(this.candidatOverTime).some((item: any) =>
      item.some((data: any) => data.year)
    );
    const days = this.candidatOverTime.registrations.map(
      (item: any) => item.day
    );
    const hasDayData = Object.values(this.candidatOverTime).some((item: any) =>
      item.some((data: any) => data.day)
    );

    let data;
    if (hasYearData) {
      data = years;
    } else if (hasDayData) {
      data = days;
    } else {
      data = months;
    }

    let registrationData,
      projectsData,
      connexData,
      updatesData,
      jobRequestsData,
      employeesData,
      consultantsData;

    if (hasYearData) {
      registrationData = this.getDataForYears(
        this.candidatOverTime.registrations,
        years
      );
      projectsData = this.getDataForYears(this.candidatOverTime.project, years);
      connexData = this.getDataForYears(this.candidatOverTime.connex, years);
      updatesData = this.getDataForYears(this.candidatOverTime.updates, years);
      jobRequestsData = this.getDataForYears(
        this.candidatOverTime.jobRequests,
        years
      );
      employeesData = this.getDataForYears(
        this.candidatOverTime.employees,
        years
      );
      consultantsData = this.getDataForYears(
        this.candidatOverTime.consultants,
        years
      );
    } else if (hasDayData) {
      registrationData = this.getDataForDays(
        this.candidatOverTime.registrations,
        days
      );
      projectsData = this.getDataForDays(this.candidatOverTime.project, days);
      connexData = this.getDataForDays(this.candidatOverTime.connex, days);
      updatesData = this.getDataForDays(this.candidatOverTime.updates, days);
      jobRequestsData = this.getDataForDays(
        this.candidatOverTime.jobRequests,
        days
      );
      employeesData = this.getDataForDays(
        this.candidatOverTime.employees,
        days
      );
      consultantsData = this.getDataForDays(
        this.candidatOverTime.consultants,
        days
      );
    } else {
      registrationData = this.getDataForMonths(
        this.candidatOverTime.registrations,
        months
      );
      projectsData = this.getDataForMonths(
        this.candidatOverTime.project,
        months
      );
      connexData = this.getDataForMonths(this.candidatOverTime.connex, months);
      updatesData = this.getDataForMonths(
        this.candidatOverTime.updates,
        months
      );
      jobRequestsData = this.getDataForMonths(
        this.candidatOverTime.jobRequests,
        months
      );
      employeesData = this.getDataForMonths(
        this.candidatOverTime.employees,
        months
      );
      consultantsData = this.getDataForMonths(
        this.candidatOverTime.consultants,
        months
      );
    }

    // const registrationData = hasYearData ? this.getDataForYears(this.candidatOverTime.registrations, years) : this.getDataForMonths(this.candidatOverTime.registrations, months);
    // const projectsData = hasYearData ? this.getDataForYears(this.candidatOverTime.project, years) : this.getDataForMonths(this.candidatOverTime.project, months);
    // const connexData = hasYearData ? this.getDataForYears(this.candidatOverTime.connex, years) : this.getDataForMonths(this.candidatOverTime.connex, months);
    // const updatesData = hasYearData ? this.getDataForYears(this.candidatOverTime.updates, years) : this.getDataForMonths(this.candidatOverTime.updates, months);
    // const jobRequestsData = hasYearData ? this.getDataForYears(this.candidatOverTime.jobRequests, years) : this.getDataForMonths(this.candidatOverTime.jobRequests, months);
    // const employeesData = hasYearData ? this.getDataForYears(this.candidatOverTime.employees, years) : this.getDataForMonths(this.candidatOverTime.employees, months);
    // const consultantsData = hasYearData ? this.getDataForYears(this.candidatOverTime.consultants, years) : this.getDataForMonths(this.candidatOverTime.consultants, months);

    this.chartOptionsLine = {
      series: [
        { name: 'Inscription total', data: registrationData },
        { name: 'projet', data: projectsData },
        { name: 'Connexion', data: connexData },
        { name: 'MAJ', data: updatesData },
        { name: 'publication des appels d’offre', data: jobRequestsData },
        { name: 'Intervenant', data: employeesData },
        { name: 'Consultant externe', data: consultantsData },
      ],
      chart: {
        type: 'line',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: data,
      },
    };
  }

  getDataForDays(dataArray: any[], days: string[]): number[] {
    const result = [];
    for (const day of days) {
      const dataForDay = dataArray.find((item) => item.day === day);
      result.push(dataForDay ? dataForDay.total || 0 : 0);
    }
    return result;
  }

  getYearOrMonth() {
    const hasYearData = Object.values(this.candidatOverTime).some((item: any) =>
      item.some((data: any) => data.year)
    );
    if (hasYearData) {
      const years = this.candidatOverTime.registrations.map(
        (item: any) => item.year
      );
      this.getDataForYears(this.candidatOverTime.registrations, years);
    } else {
      const months = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ];
      this.getDataForMonths(this.candidatOverTime.registrations, months);
    }
  }

  getDataForYears(dataArray: any[], years: number[]): number[] {
    const result = [];
    for (const year of years) {
      const dataForYear = dataArray.find((item) => item.year === year);
      result.push(dataForYear ? dataForYear.total || 0 : 0);
    }
    return result;
  }

  getDataForMonths(dataArray: any[], months: string[]): number[] {
    const result = [];
    for (const month of months) {
      const dataForMonth = dataArray?.find(
        (item) =>
          item.month ===
          `2024-${months.indexOf(month) + 1 < 10 ? '0' : ''}${
            months.indexOf(month) + 1
          }`
      );
      result.push(dataForMonth ? dataForMonth.total || 0 : 0);
    }
    return result;
  }

  RegistrationOverTimeForm(data?: any) {
    return this.formBuilder.group({
      start_date: ['', data?.start_date],
      end_date: ['', data?.end_date],
    });
  }

  searchClientOverTime() {
    this.dashboardService
      .searchClientOverTime(this.searchForm.value)
      .subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            this.candidatOverTime = res.data;
            this.updateChartLine();
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  updateChartBar() {
    let countries: string[] = [];
    let totals: number[] = [];

    this.statisticCandidatesByCountry.forEach((item: any) => {
      countries.push(item.country);
      totals.push(item.total);
    });

    this.chartOptionsbar = {
      series: [
        // {
        //   name: "Canal Candidat",
        //   data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
        // },
        {
          name: 'Candidat par pays',
          data: totals,
        },
        // {
        //   name: "Ajouté par l’admin sans connexion",
        //   data: [0, 50, 10, 200, 20, 600, 700, 800, 1200, 1600]
        // },
        // {
        //   name: "Ajouté par l’admin avec connexion",
        //   data: [0, 50, 10, 200, 20, 600, 700, 800, 1200, 1600]
        // },
        // {
        //   name: "Inscription",
        //   data: [0, 50, 10, 200, 20, 600, 700, 800, 1200, 1600]
        // }
      ],
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: countries,
      },
    };
  }
  updateInputType(type: 'text' | 'date', nameControl: string) {
    const inputElement =
      nameControl == 'end_date'
        ? (document.getElementById('dateInputEnd') as HTMLInputElement)
        : (document.getElementById('dateInput') as HTMLInputElement);
    inputElement.type = type;
    if (type == 'text') {
      if (
        this.searchForm.value[nameControl] &&
        this.searchForm.value[nameControl] != ''
      )
        this.searchForm
          .get(nameControl)
          ?.setValue(
            moment(this.searchForm.value[nameControl]).format('DD/MM/yyyy')
          );
    }
  }

  getClientByCountry() {
    this.spinner.show();
    this.dashboardService
      .getClientByCountry()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.statisticCandidatesByCountry = res.data;
            this.updateChartBar();
          }
        },
        error: (err) => {
          this.spinner.hide();
        },
      });
  }
  updateChartPie() {
    let seriesData: number[] = [];
    let labels: string[] = [];
    if (
      this.statisticAddedByAdmin &&
      this.statisticAddedByAdmin.without_connection
    ) {
      seriesData.push(this.statisticAddedByAdmin.without_connection[0].total);
      labels.push('Sans connexion');
    }
    if (
      this.statisticAddedByAdmin &&
      this.statisticAddedByAdmin.with_connection
    ) {
      seriesData.push(this.statisticAddedByAdmin.with_connection[0].total);
      labels.push('Avec connexion');
    }
    if (
      this.statisticregistrationCandidats &&
      this.statisticregistrationCandidats.data
    ) {
      seriesData.push(this.statisticregistrationCandidats.data[0].total);
      labels.push('Inscription');
    }
    const total = seriesData.reduce((acc, curr) => acc + curr, 0);
    const formattedLabels = labels.map((label, index) => {
      const percentage = ((seriesData[index] / total) * 100).toFixed(2);
      return `${label} : ${percentage}%`;
    });
    this.chartOptionsPie = {
      series: seriesData,
      labels: formattedLabels,
      chart: {
        width: 380,
        type: 'donut',
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
      },
      legend: {
        // formatter: function(val, opts) {
        // Vous pouvez personnaliser la légende ici si nécessaire
        //   return val;
        // }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }
  getAddedClientByAdmin() {
    this.spinner.show();
    this.dashboardService
      .getAddedClientByAdmin()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.statisticAddedByAdmin = res;
            this.updateChartPie();
          }
        },
        error: (err) => {
          this.spinner.hide();
        },
      });
  }
  getRegistrationClient() {
    this.spinner.show();
    this.dashboardService
      .getRegistrationClient()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.statisticregistrationCandidats = res;
            this.updateChartPie();
          }
        },
        error: (err) => {
          this.spinner.hide();
        },
      });
  }
}
