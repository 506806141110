import { Component, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../../../core/models/messages';
import { CompanyService } from '../../../../../../core/services/company/company.service';
import { environment } from '../../../../../../../environments/environment';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import {
  CSearchCandidate,
  ISort,
} from '../../../../../../core/models/candidat';
import { SortDataService } from '../../../../../../core/services/sort-data/sort-data.service';
import { PermissionService } from '../../../../../../core/services/permission.service';

@Component({
  selector: 'app-listsalaries',
  templateUrl: './listsalaries.component.html',
  styleUrls: ['./listsalaries.component.css'],
})
export class ListsalariesComponent {
  selectedsalarie: any = {};
  /* string */
  searchElement: string = '';
  mode: string = 'create';
  url: string = environment.baseUrl + '/api';
  sortAttr: string = '';
  /* number */
  idCompany!: number;
  currentSortDirection: number = 2;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  clickCount: number = 0;
  pagination : boolean =false;

  /* any */
  listsalarie: any = [];
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  separateDialCode: boolean = false;
  selectedCountryCode: string = 'FR';
  listRole: { id: number; name: string }[] = [];
  /* formGroup */
  phoneForm!: FormGroup;
  /* modal */
  modalRef?: BsModalRef;
  /* form group */
  searchFormGroup: FormGroup = this.createSearchForm(new CSearchCandidate());
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  idclientConnected: any;
  @Input() clientid!: any;
  listprojet: { id: number; name: string }[] = [];
  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private sortDataService: SortDataService,
    private permissionService: PermissionService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}
  ngOnInit() {
    console.log('clientid', this.clientid);
    this.getIdCompany();
    this.getListRole();
    this.getListprojet();
    this.phoneForm = new FormGroup({
      phone: new FormControl(undefined),
    });
  }
  getListRole() {
    this.permissionService
      .getListRoleByCompany(this.idCompany)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listRole = res.data;
        },
        error: () => {},
      });
  }
  /* get company id from route */
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCompany = params.params['id'];
        this.getListsalarie();
      },
    });
  }
  openModal(template: TemplateRef<any>, data: any) {
    this.selectedsalarie = data;
    this.selectedsalarie.name = `${this.selectedsalarie?.first_name} ${this.selectedsalarie?.last_name}`;
    console.log('selectedsalarie', this.selectedsalarie);
    this.phoneForm.get('phone')?.setValue(data.phone_number);
    this.phoneForm.disable();
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef!.hide();
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') this.applyFilter();
      else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
        this.getListsalarie();
      }
    }
  }
  /* sort data  */
  sortData(sortAttribute: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr=sortAttribute
    this.currentPage = 1;
    this.endIndex=5;
    if(this.searchElement !='') this.listsalarie = this.sortDataService.sortArray(this.listsalarie, sortAttribute, this.currentSortDirection)
    else this.getListsalarie()
  }
  /* list list esn companies */
  getListsalarie(sort?:ISort){
    console.log("sort att",this.sortAttr)
   this.spinner.show()
   let dataPyload = this.sortAttr == '' ? { clientFinal_id: this.clientid, per_page: this.itemsPerPage, page: this.currentPage } : { ...{ clientFinal_id: this.clientid, per_page: this.itemsPerPage, page: this.currentPage }, ...{ sort: this.currentSortDirection, sortAttribute: this.sortAttr } }
  this.companyService.getListsalarie(dataPyload).pipe(takeUntil(this.unsubscribeAll)).subscribe({
   next:(res:any)=>{
     this.spinner.hide()
     this.listsalarie=res.data.data
     this.totalItems=res.data.total
     if(this.currentPage>1 && !res.data.data.length){
      this.currentPage=1
      this.getListsalarie()
    }
     console.log("list salarie",this.listsalarie)
   },
   error:()=>{
     this.spinner.hide()
   }
  })
  }
  getListprojet() {
    this.companyService.getprojets(this.clientid).subscribe({
      next: (response) => {
        console.log('response projet', response);

        this.listprojet = response.data;
        console.log('list projettttttttttttttttt', this.listprojet);
      },
      error: (error) => {
        console.error('Error fetching projet: ', error);
      },
    });
  }
  /* search salarie on input */
  applyFilter() {
    let data = {
      clientFinal_id: this.clientid,
      name: this.searchFormGroup.value.name,
      poste: this.searchFormGroup.value.poste,
      project_id: this.searchFormGroup.value.project_id
        ? this.searchFormGroup.value.project_id.toString()
        : '',
      per_page: this.itemsPerPage,
      page: this.currentPage,
    };
    console.log('data search', data);
    this.spinner.show();
    this.companyService
      .searchsalarie(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          console.log('search res', res);
          if (!res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.applyFilter();
            this.spinner.hide();
          } else {
            this.listsalarie = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
            this.spinner.hide();
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* create search form */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      name: ['', data?.name],
      poste: ['', data?.poste],
      project_id: ['', data?.project_id],
    });
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.searchFormGroup = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getListsalarie();
    }
    return (this.isRecherche = false);
  }
  getRoleName(employeeRoleId: number): string {
    const role = this.listRole.find((role) => role.id === employeeRoleId);
    return role ? role.name : '---';
  }
  navigateToProfilCandidat(idCandidat: number) {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
