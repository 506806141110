import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { JobOfferService } from '../../../../core/services/job-offer/job-offer.service';

@Component({
  selector: 'app-addbareme',
  templateUrl: './addbareme.component.html',
  styleUrls: ['./addbareme.component.css'],
})
export class AddbaremeComponent {
  BaremeForm: FormGroup = this.createBaremeForm();
  formData = new FormData();
  jobId: any;
  selectedBarem!: number;
  skillsList: { name_FR: string }[] = [];
  private unsubscribeAll: Subject<void> = new Subject();
  disponibilite = [
    { id: 1, name: 'Immédiate', value: '1' },
    { id: 2, name: '1 mois', value: '2' },
    { id: 3, name: '2 mois', value: '3' },
    { id: 4, name: '3 mois', value: '4' },
    { id: 5, name: '4 mois', value: '5' },
    { id: 6, name: '5 mois', value: '6' },
  ];
  languages = [
    { id: 1, name: 'Abkhaze' },
    { id: 2, name: 'Afrikaans' },
    { id: 3, name: 'Albanais' },
    { id: 4, name: 'Allemand' },
    { id: 5, name: 'Amharique' },
    { id: 6, name: 'Anglais' },
    { id: 7, name: 'Arabe' },
    { id: 8, name: 'Arménien' },
    { id: 9, name: 'Assamais' },
    { id: 10, name: 'Aymara' },
    { id: 11, name: 'Azéri' },
    { id: 12, name: 'Basque' },
    { id: 13, name: 'Bengali' },
    { id: 14, name: 'Biélorusse' },
    { id: 15, name: 'Birman' },
    { id: 16, name: 'Bosniaque' },
    { id: 17, name: 'Bulgare' },
    { id: 18, name: 'Catalan' },
    { id: 19, name: 'Cebuano' },
    { id: 20, name: 'Chewa' },
    { id: 21, name: 'Chichewa' },
    { id: 22, name: 'Chinois' },
    { id: 23, name: 'Coréen' },
    { id: 24, name: 'Corse' },
    { id: 25, name: 'Croate' },
    { id: 26, name: 'Danois' },
    { id: 27, name: 'Dzongkha' },
    { id: 28, name: 'Espagnol' },
    { id: 29, name: 'Estonien' },
    { id: 30, name: 'Finnois' },
    { id: 31, name: 'Flamand' },
    { id: 32, name: 'Français' },
    { id: 33, name: 'Frison' },
    { id: 34, name: 'Gaélique' },
    { id: 35, name: 'Galicien' },
    { id: 36, name: 'Géorgien' },
    { id: 37, name: 'Grec' },
    { id: 38, name: 'Groenlandais' },
    { id: 39, name: 'Gujarati' },
    { id: 40, name: 'Haoussa' },
    { id: 41, name: 'Hébreu' },
    { id: 42, name: 'Hindi' },
    { id: 43, name: 'Hmong' },
    { id: 44, name: 'Hongrois' },
    { id: 45, name: 'Ibo' },
    { id: 46, name: 'Islandais' },
    { id: 47, name: 'Indonésien' },
    { id: 48, name: 'Inuktitut' },
    { id: 49, name: 'Italien' },
    { id: 50, name: 'Japonais' },
    { id: 51, name: 'Javanais' },
    { id: 52, name: 'Kannada' },
    { id: 53, name: 'Kashmiri' },
    { id: 54, name: 'Kazakh' },
    { id: 55, name: 'Khmer' },
    { id: 56, name: 'Kinyarwanda' },
    { id: 57, name: 'Kirghize' },
    { id: 58, name: 'Kirundi' },
    { id: 59, name: 'Kurde' },
    { id: 60, name: 'Laotien' },
    { id: 61, name: 'Latin' },
    { id: 62, name: 'Letton' },
    { id: 63, name: 'Lituanien' },
    { id: 64, name: 'Luxembourgeois' },
    { id: 65, name: 'Macédonien' },
    { id: 66, name: 'Malais' },
    { id: 67, name: 'Malayalam' },
    { id: 68, name: 'Malgache' },
    { id: 69, name: 'Maltais' },
    { id: 70, name: 'Maori' },
    { id: 71, name: 'Marathi' },
    { id: 72, name: 'Mongol' },
    { id: 73, name: 'Néerlandais' },
    { id: 74, name: 'Népalais' },
    { id: 75, name: 'Norvégien' },
    { id: 76, name: 'Ouzbek' },
    { id: 77, name: 'Pachtou' },
    { id: 78, name: 'Papiamento' },
    { id: 79, name: 'Pashto' },
    { id: 80, name: 'Persan (farsi)' },
    { id: 81, name: 'Polonais' },
    { id: 82, name: 'Portugais' },
    { id: 83, name: 'Punjabi' },
    { id: 84, name: 'Quechua' },
    { id: 85, name: 'Roumain' },
    { id: 86, name: 'Russe' },
    { id: 87, name: 'Somali' },
    { id: 88, name: 'Samoan' },
    { id: 89, name: 'Sanskrit' },
    { id: 90, name: 'Serbe' },
    { id: 91, name: 'Sesotho' },
    { id: 92, name: 'Shona' },
    { id: 93, name: 'Sindhi' },
    { id: 94, name: 'Slovaque' },
    { id: 95, name: 'Slovène' },
    { id: 96, name: 'Sotho' },
    { id: 97, name: 'Soundanais' },
    { id: 98, name: 'Suédois' },
    { id: 99, name: 'Swahili' },
    { id: 100, name: 'Tadjik' },
  ];
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private el: ElementRef,
    private router: Router,
    private jobOfferService: JobOfferService
  ) {}

  ngOnInit() {
    this.getListSkills();
    this.getId();
    this.getBarem();
  }

  /* get id  from route */
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.jobId = params.params['jobId'];
      },
    });
  }
  getBarem() {
    console.log('baremeeeee');
    this.spinner.show();
    console.log('this.jobId', this.jobId);
    this.jobOfferService
      .getBarem(this.jobId)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          console.log('response get bareme', res);
          this.selectedBarem = res.data;
          console.log('get bareme', this.selectedBarem);
          this.BaremeForm = this.createBaremeForm(this.selectedBarem);
          this.BaremeForm.get('test_qcm_score')?.setValue(
            res.data.test_qcm_score[0]
              ? res.data.test_qcm_score[0].test_qcm_score
              : ''
          );
          this.spinner.hide();
        },
        error: (error: any) => {
          console.log(error);
          this.spinner.hide();
        },
      });
  }
  createFormLangues(data?: any) {
    return this.formBuilder.group({
      language: [data ? data.language : null],
      coef: [data ? String(data.coef) : null],
    });
  }
  createFormSkills(data?: any) {
    return this.formBuilder.group({
      skill_job_id: [data ? data.skill_job_id : null],
      coef: [data ? String(data.coef) : null],
    });
  }
  createFormAvailability(data?: any) {
    return this.formBuilder.group({
      availability: [data ? data.availability : null],
      coef: [data ? String(data.coef) : null],
    });
  }
  createFormExperience(data?: any) {
    return this.formBuilder.group({
      year_experience: [data ? String(data.year_experience) : null],
      coef: [data ? String(data.coef) : null],
    });
  }

  addSkills() {
    const skills = this.BaremeForm.get('skill_score') as FormArray;
    skills.push(this.createFormSkills());
  }
  addLangues() {
    const language_score = this.BaremeForm.get('language_score') as FormArray;
    language_score.push(this.createFormLangues());
  }
  addAvailability() {
    const availibility_score = this.BaremeForm.get(
      'availibility_score'
    ) as FormArray;
    availibility_score.push(this.createFormAvailability());
  }
  addExperiences() {
    const experience_score = this.BaremeForm.get(
      'experience_score'
    ) as FormArray;
    experience_score.push(this.createFormExperience());
  }

  get skillsControls(): AbstractControl<any, any>[] {
    return (this.BaremeForm.get('skill_score') as FormArray)?.controls || [];
  }
  get availibility_scoreControls(): AbstractControl<any, any>[] {
    return (
      (this.BaremeForm.get('availibility_score') as FormArray)?.controls || []
    );
  }
  get experiencesControls(): AbstractControl<any, any>[] {
    return (
      (this.BaremeForm.get('experience_score') as FormArray)?.controls || []
    );
  }
  get langueControls(): AbstractControl<any, any>[] {
    return (this.BaremeForm.get('language_score') as FormArray)?.controls || [];
  }
  removescoreQCM(index: number) {
    const ScoreQCM = this.BaremeForm.get('test_qcm_score') as FormArray;
    ScoreQCM.removeAt(index);
  }
  removeSkills(index: number) {
    const skills = this.BaremeForm.get('skill_score') as FormArray;
    skills.removeAt(index);
  }
  removeAvailability(index: number) {
    const availibility_score = this.BaremeForm.get(
      'availibility_score'
    ) as FormArray;
    availibility_score.removeAt(index);
  }
  removeExperiences(index: number) {
    const experience_score = this.BaremeForm.get(
      'experience_score'
    ) as FormArray;
    experience_score.removeAt(index);
  }
  removeLangue(index: number) {
    const language_score = this.BaremeForm.get('language_score') as FormArray;
    language_score.removeAt(index);
  }
  createBaremeForm(data?: any) {
    return this.formBuilder.group({
      job_offer_id: [data ? data.job_offer_id : ''],
      test_qcm_score: [data ? data.test_qcm_score : ''],
      skill_score: this.formBuilder.array(
        Array.isArray(data?.skill_score) && data?.skill_score.length
          ? data.skill_score.map((item: any) => this.createFormSkills(item))
          : [this.createFormSkills()]
      ),
      availibility_score: this.formBuilder.array(
        Array.isArray(data?.availibility_score) &&
          data?.availibility_score.length
          ? data.availibility_score.map((item: any) =>
              this.createFormAvailability(item)
            )
          : [this.createFormAvailability()]
      ),
      experience_score: this.formBuilder.array(
        Array.isArray(data?.experience_score) && data?.experience_score.length
          ? data.experience_score.map((item: any) =>
              this.createFormExperience(item)
            )
          : [this.createFormExperience()]
      ),
      language_score: this.formBuilder.array(
        Array.isArray(data?.language_score) && data?.language_score.length
          ? data.language_score.map((item: any) => this.createFormLangues(item))
          : [this.createFormLangues()]
      ),
    });
  }
  // Helper function to convert coef values to strings
  convertCoefToString(data: any): any {
    if (data.skill_score) {
      data.skill_score = data.skill_score.map((item: any) => ({
        ...item,
        coef: String(item.coef),
      }));
    }

    if (data.availibility_score) {
      data.availibility_score = data.availibility_score.map((item: any) => ({
        ...item,
        coef: String(item.coef),
      }));
    }

    if (data.experience_score) {
      data.experience_score = data.experience_score.map((item: any) => ({
        year_experience: String(item.year_experience),
        coef: String(item.coef),
      }));
    }

    if (data.language_score) {
      data.language_score = data.language_score.map((item: any) => ({
        ...item,
        coef: String(item.coef),
      }));
    }

    return data;
  }

  getListSkills() {
    this.jobOfferService
      .getSkillsJobs()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('res skills', res);
          this.skillsList = res.data;
          console.log('list skills', this.skillsList);
        },
        error: () => {},
      });
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
