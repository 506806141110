import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../../core/models/messages';
import { SettingsService } from '../../../../../core/services/settings/settings.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-evaluation-wegestu',
  templateUrl: './evaluation-wegestu.component.html',
  styleUrls: ['./evaluation-wegestu.component.css'],
})
export class EvaluationWegestuComponent {
  /* string */
  url: string = environment.baseUrl + '/api/User/file/gallery_users/';
  /* number */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  totalComments!: number;
  idCompany!: number;
  /* array */
  listComments: any = [];
  /* boolean */
  typeCompany: boolean = false;
  pagination : boolean =false;

  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private settingService: SettingsService,
    private toastr: ToastrService,

    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {}
  /* init */
  ngOnInit() {
    this.getIdCompany();
  }
  /* get id company from route */
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCompany = params.params['id'];
        this.typeCompany = params.params['type'];
        if (this.typeCompany) {
          this.getListCommentFromCompany();
        } else {
          this.getListComment();
        }
      },
    });
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.typeCompany) {
        this.getListCommentFromCompany();
      } else   if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true 
        this.getListComment();
        
      }
    }
  }
  getListComment() {
    this.spinner.show();
    let data = { per_page: this.itemsPerPage, page: this.currentPage };
    this.settingService
      .getListComment(this.idCompany, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.totalComments = res.data.total;
            this.listComments = res.data.data.slice(0, this.endIndex);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  getListCommentFromCompany() {
    this.spinner.show();
    let data = { per_page: this.itemsPerPage, page: this.currentPage };
    this.settingService
      .getSpecificReviewComments(this.idCompany, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('resssssss', res);

          if (res.status == 200) {
            this.spinner.hide();
            this.totalComments = res.data.total;
            this.listComments = res.data.data.slice(0, this.endIndex);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
