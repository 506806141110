
<div id="kt_app_content" class="app-content flex-column-fluid">
    <div id="kt_app_content_container" class="container-fluid">
      <div class="card mb-10" >
        <div   class="card-body tools" >
            <div class="title mb-3">
              <h4>Recherche</h4>
            </div>
            <div class="row mb-3" [formGroup]="searchFormGroup" >
              <div class="col-lg-3">
                <input type="text" name="id" id="id" class="form-control form-control-solid mb-3"
                  formControlName="name" placeholder="Poste">
              </div>
              <div class="col-lg-3">
                <input type="text" id="dateInput" placeholder="Date" formControlName="start_date"
                class="form-control form-control-solid mb-3" 
                 (focus)="updateInputType('date')"
                >
              </div>
              <div class="col-lg-3">
                <!-- <input type="text" name="nom" id="nom" formControlName="state"
                  class="form-control form-control-solid mb-3" placeholder="Statut"> -->
                  <ng-select formControlName="state"    [items]="StateOffre" bindLabel="name"   class="form-control form-control-solid mb-3"
                  bindValue="id" [placeholder]="'Statut'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                </ng-select>
              </div>
              <div class="col-lg-3">
                <div class="mb-3 search-btn d-flex justify-content-end">
                <div class="align-items-center recherche">
                  <button type="submit" class="me-5" (click)="searchElement=true;applyFilter()">
                    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                        fill="white" />
                    </svg>
                    Rechercher</button>
                </div>
                <div class="reset-icon cursor-pointer"  >
                    <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                    </div>
  
              </div>
              </div>
            </div>
            
          </div>
        
         <!--end::Card header-->
        <!--begin::Card body-->
       
        <!--end::Card body-->
    </div>
        <!--begin::Card-->
        <div class="cardd">
            <div class="card-body pt-10 pb-10 card">
              <div class="title">
                <h4>Offres d’emploi</h4>
            </div>
                <!--begin::Table-->
               <div class="table-responsive" id="kt_customers_table">
                <table class="table align-middle gy-5 mb-4" >
                    <thead>
                        <tr class="text-start text-uppercase gs-0">

                            <th class="min-w-125px"><span>ID <i (click)="sortData('ID_jobOffer')" class="fa-solid fa-sort cursor-pointer"></i></span></th>

                            <th class="min-w-125px"><span>Poste <i (click)="sortData('name')" class="fa-solid fa-sort cursor-pointer"></i></span></th>

                            <th class="min-w-125px"><span>date <i (click)="sortData('created_at')" class="fa-solid fa-sort cursor-pointer"></i></span></th>

                            <th class="min-w-125px"><span>STATUT <i (click)="sortData('state')" class="fa-solid fa-sort cursor-pointer"></i></span></th>

                            <th class="min-w-125px"><span>ACTION</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let offer of listJobOffer">
                            <td>
                                <a class="first-td" 
                                  [routerLink]="['/acceuil/offre-emploi/details-offer',{id:offer.id}]" routerLinkActive="router-link-active" 
                                >{{offer.ID_jobOffer?offer.ID_jobOffer:'---'}}</a> </td>
                           
                           
                             <td>
                              <span>{{offer.name?offer.name:'---'}}</span> 
                            </td> 
                            
                            <td>
                               <span>{{!offer.created_at?'---':offer.created_at | formatDate}}</span>
                            </td>
                            <td>
                              <span><div [ngClass]="offer.state?'badge-red':'badge-green'" class="badge ">{{offer.state?'Désactivé':'Activé'}}</div></span>
                            </td>
                            <td>
                               <!-- <div class="seemore">
                               <a 
                               (click)="goToDetails(offer.id)">Voir détail</a>
                               </div>  -->
                               <div class="dropdown">
                                <button
                                  class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                  type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                  aria-expanded="false">
                                  <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                                      fill="#265D91" />
                                    <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                      fill="#265D91" />
                                    <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                      fill="#265D91" />
                                  </svg>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                
                                    <a class="dropdown-item"  style="cursor: pointer;"  (click)="goToDetails(offer.id)">Voir détail</a>
                                    <a *ngIf="offer.state=='1'" class="dropdown-item" style="cursor: pointer;" (click)="activerjoboffer(offer.id)">Activer</a>
                                    <a *ngIf="offer.state=='0'" class="dropdown-item" style="cursor: pointer;" (click)="desactiverjoboffer(offer.id)">Désactiver</a>
          
          
                                                </div>
                                            </div>
                          </td> 
                        </tr>
                    </tbody>
                    <!--end::Table body-->
                </table>
                <div class="pagination mt-4" *ngIf="listJobOffer && listJobOffer.length">
                    <app-pagination [data]="listJobOffer" [lengthData]="totalItems" [currentPageList]="currentPage" [endexPageList]="endIndex" (setPages)="getItems($event)">
                    </app-pagination>
                  </div>
                

                  <div class="alert alert-warning text-center mt-5" role="alert"  *ngIf="listJobOffer&&!listJobOffer.length ">
                    <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                    <span class="empty-list">
                     La liste est vide
                 </span>
                 </div>


               </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Customers - Add-->

        <!--end::Modal - Customers - Add-->
        <!--begin::Modal - Adjust Balance-->

        <!--end::Modal - New Card-->
        <!--end::Modals-->
    </div>
    <!--end::Content container-->
</div>
<ng-template #Detail_offre_esn>
   <div class="" id="" tabindex="-1" aria-hidden="true">
  <!--begin::Modal dialog-->
  <div class=" modal-dialog-centered ">
      <!--begin::Modal content-->
      <div class="modal-content">
          <!--begin::Form-->
          <form class="form" action="#" id="kt_modal_add_customer_form" data-kt-redirect="../../demo1/dist/apps/customers/list.html">
              <!--begin::Modal header-->
              <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="">Voir Détail Offre ESN</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div id="kt_modal_add_customer_close" data-bs-dismiss="modal"
                   class="btn btn-icon btn-sm btn-active-icon-primary">
                      <i class="ki-duotone ki-cross fs-1" (click)="closeModal()">
                          <span class="path1"></span>
                          <span class="path2"></span>
                      </i>
                  </div>
                  <!--end::Close-->
                </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body py-10 px-lg-17 tools">
                  <!--begin::Scroll-->
                  <div class="me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" >
                    <div class="row">
                      <!--begin::Input group-->
                      <div class="col-6 fv-row mb-7 ">
                          <!--begin::Label-->
                          <label class=" fs-6 mb-2">Offre</label>
                          <!--end::Label-->
                          <!--begin::Input-->
                          <input type="text" disabled [value]="selectedOffer.name" class="form-control form-control-solid mb-3" placeholder="" name="module"  />
                          <!--end::Input-->
                      </div>
                      <div class="col-6 fv-row mb-7">
                          <!--begin::Label-->
                          <label class=" fs-6 mb-2">Date</label>
                          <!--end::Label-->
                          <!--begin::Input-->
                          <input type="text" disabled [value]="!selectedOffer.created_at?'':selectedOffer.created_at| formatDate" class="form-control form-control-solid mb-3" placeholder="" name="module"  />
                          <!--end::Input-->
                      </div>
                      <div class="col-6 fv-row mb-7">
                          <!--begin::Label-->
                          <label class=" fs-6 mb-2">ESN</label>
                          <!--end::Label-->
                          <!--begin::Input-->
                          <input type="text" disabled value="ESN 1" class="form-control form-control-solid mb-3" placeholder="" name="module"  />
                          <!--end::Input-->
                      </div>
                      <div class="col-6 fv-row mb-7">
                          <!--begin::Label-->
                          <label class=" fs-6 mb-2">Statut</label>
                          <!--end::Label-->
                          <!--begin::Input-->
                          <input  disabled type="text" [value]="selectedOffer.state?'Activé':'Désactivé'" class="form-control form-control-solid mb-3" placeholder=""
                           name="module"
                           />
                          <!--end::Input-->
                      </div>
                      <div class="col-6 fv-row mb-7">
                          <!--begin::Label-->
                          <label class=" fs-6 mb-2">Disponibilité</label>
                          <!--end::Label-->
                          <!--begin::Input-->
                          <input  disabled type="text" [value]="selectedOffer.availability=='1'?'Immédiate'
                          :selectedOffer.availability=='2'?'1 mois'
                          :selectedOffer.availability=='3'?'2 mois'
                          :selectedOffer.availability=='4'?'3 mois'
                          :selectedOffer.availability=='5'?'4 mois':'5 mois'" class="form-control form-control-solid mb-3"
                          placeholder="immédiate" name="module"
                           />
                          <!--end::Input-->
                      </div>
                      <div class="col-6 fv-row mb-7">
                          <label class=" fs-6 mb-2">Année d'experience</label>
                          <input  disabled type="text" class="form-control form-control-solid mb-3"
                          placeholder="2 ans" [value]="selectedOffer.years_of_experience" name="module"/>
                      </div>
                      <div class="col-6 fv-row mb-7">
                          <label class=" fs-6 mb-2">TJM</label>
                          <input  disabled type="text" class="form-control form-control-solid mb-3"
                          placeholder="14 €" [value]="selectedOffer.tjm?selectedOffer.tjm+' €':''" name="tjm"/>
                      </div>
                      <div class="col-6 fv-row mb-7">
                          <label class=" fs-6 mb-2">Salaire</label>
                          <input  disabled type="text" class="form-control form-control-solid mb-3"
                          placeholder="40000 €" [value]="selectedOffer.salary?selectedOffer.salary+' €':''" name="salaire"/>
                      </div>
                      <div class="col-6 fv-row mb-7">
                          <label class=" fs-6 mb-2">Compétences</label>
                          <input  disabled type="text" class="form-control form-control-solid mb-3"
                          placeholder="Compétences" name="Compétences"/>
                      </div>
                    </div>
                  </div>
                  <!--end::Scroll-->
              </div>
          </form>
          <!--end::Form-->
      </div>
  </div>
</div> 
</ng-template>

