import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICountry, IState, State } from 'country-state-city';
import * as moment from 'moment';
import { CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { SearchCountryField } from 'ngx-intl-telephone-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { fileTypeExtention, maxSizeFile, serverError, updatedCompany } from 'src/app/core/models/messages';
import { linkedCompanyRegExp, urlRegExp } from 'src/app/core/models/pattern';
import { CompanyService } from 'src/app/core/services/company/company.service';
import { PaysService } from 'src/app/shared/services/pays.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detailsociete',
  templateUrl: './detailsociete.component.html',
  styleUrls: ['./detailsociete.component.css']
})
export class DetailsocieteComponent {
  paysList: ICountry[] =[]; 
  stateList!: IState[]
  sizeCompany = [
    { id: 1, name: '20 - 50' },
    { id: 2, name: '50 - 100' },
    { id: 3, name: '100 - 200' },
    { id: 4, name: '>200' },
  ]
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  separateDialCode: boolean = false;
  selectedCountryCode: string = 'FR'
  /*  */
  file: any
  selectedImage: any
  /* number */
  tabulation: number = 10
  /* boolean */
  disabledMode: boolean = true
  /* object */
  esnDetails: any
  responsable: any
  reviewCandidat: any
  reviewCompany: any
  datevalid:boolean=true
  /* string */
  idEsn!: string
  nameEsn!: string
  dataEsn: any
  fileExtention: string = ''
  fileError: string = ''
  url: string = environment.baseUrl + '/api'
  fileName!: string
  /* formData */
  formData = new FormData()
  /* formGroup */
  phoneForm!: FormGroup
  starsFormCompany!: FormGroup
  starsForm!: FormGroup
  countryIsoCodes:any = {
    "Afghanistan": "AF",
    "Afrique du Sud": "ZA",
    "Albanie": "AL",
    "Algérie": "DZ",
    "Allemagne": "DE",
    "Andorre": "AD",
    "Angola": "AO",
    "Anguilla": "AI",
    "Antarctique": "AQ",
    "Antigua-et-Barbuda": "AG",
    "Arabie saoudite": "SA",
    "Argentine": "AR",
    "Arménie": "AM",
    "Aruba": "AW",
    "Australie": "AU",
    "Autriche": "AT",
    "Azerbaïdjan": "AZ",
    "Bahamas": "BS",
    "Bahreïn": "BH",
    "Bangladesh": "BD",
    "Barbade": "BB",
    "Belgique": "BE",
    "Belize": "BZ",
    "Bénin": "BJ",
    "Bermudes": "BM",
    "Bhoutan": "BT",
    "Biélorussie": "BY",
    "Bolivie": "BO",
    "Bosnie-Herzégovine": "BA",
    "Botswana": "BW",
    "Brésil": "BR",
    "Brunei": "BN",
    "Bulgarie": "BG",
    "Burkina Faso": "BF",
    "Burundi": "BI",
    "Cabo Verde": "CV",
    "Cambodge": "KH",
    "Cameroun": "CM",
    "Canada": "CA",
    "Chili": "CL",
    "Chine": "CN",
    "Chypre": "CY",
    "Colombie": "CO",
    "Comores": "KM",
    "Congo-Brazzaville": "CG",
    "Congo-Kinshasa": "CD",
    "Corée du Nord": "KP",
    "Corée du Sud": "KR",
    "Costa Rica": "CR",
    "Côte d’Ivoire": "CI",
    "Croatie": "HR",
    "Cuba": "CU",
    "Curaçao": "CW",
    "Danemark": "DK",
    "Djibouti": "DJ",
    "Dominique": "DM",
    "République dominicaine": "DO",
    "Équateur": "EC",
    "Égypte": "EG",
    "Émirats arabes unis": "AE",
    "Érythrée": "ER",
    "Espagne": "ES",
    "Eswatini": "SZ",
    "Estonie": "EE",
    "États-Unis": "US",
    "Éthiopie": "ET",
    "Fidji": "FJ",
    "Finlande": "FI",
    "France": "FR",
    "Gabon": "GA",
    "Gambie": "GM",
    "Géorgie": "GE",
    "Ghana": "GH",
    "Gibraltar": "GI",
    "Grèce": "GR",
    "Grenade": "GD",
    "Groenland": "GL",
    "Guadeloupe": "GP",
    "Guam": "GU",
    "Guatemala": "GT",
    "Guernesey": "GG",
    "Guinée": "GN",
    "Guinée-Bissau": "GW",
    "Guinée équatoriale": "GQ",
    "Guyana": "GY",
    "Guyane française": "GF",
    "Haïti": "HT",
    "Honduras": "HN",
    "Hong Kong": "HK",
    "Hongrie": "HU",
    "Islande": "IS",
    "Inde": "IN",
    "Indonésie": "ID",
    "Irak": "IQ",
    "Iran": "IR",
    "Irlande": "IE",
    "Île de Man": "IM",
    "Israël": "IL",
    "Italie": "IT",
    "Jamaïque": "JM",
    "Japon": "JP",
    "Jersey": "JE",
    "Jordanie": "JO",
    "Kazakhstan": "KZ",
    "Kenya": "KE",
    "Kiribati": "KI",
    "Koweït": "KW",
    "Kirghizistan": "KG",
    "Laos": "LA",
    "Lettonie": "LV",
    "Liban": "LB",
    "Lesotho": "LS",
    "Libéria": "LR",
    "Libye": "LY",
    "Liechtenstein": "LI",
    "Lituanie": "LT",
    "Luxembourg": "LU",
    "Macao": "MO",
    "Macédoine du Nord": "MK",
    "Madagascar": "MG",
    "Malawi": "MW",
    "Malaisie": "MY",
    "Maldives": "MV",
    "Mali": "ML",
    "Malte": "MT",
    "Îles Marshall": "MH",
    "Martinique": "MQ",
    "Mauritanie": "MR",
    "Maurice": "MU",
    "Mayotte": "YT",
    "Mexique": "MX",
    "Micronésie": "FM",
    "Moldavie": "MD",
    "Monaco": "MC",
    "Mongolie": "MN",
    "Monténégro": "ME",
    "Montserrat": "MS",
    "Maroc": "MA",
    "Mozambique": "MZ",
    "Myanmar": "MM",
    "Namibie": "NA",
    "Nauru": "NR",
    "Népal": "NP",
    "Pays-Bas": "NL",
    "Nouvelle-Calédonie": "NC",
    "Nouvelle-Zélande": "NZ",
    "Nicaragua": "NI",
    "Niger": "NE",
    "Nigeria": "NG",
    "Niue": "NU",
    "Île Norfolk": "NF",
    "Îles Mariannes du Nord": "MP",
    "Norvège": "NO",
    "Oman": "OM",
    "Pakistan": "PK",
    "Palaos": "PW",
    "Palestine": "PS",
    "Panama": "PA",
    "Papouasie-Nouvelle-Guinée": "PG",
    "Paraguay": "PY",
    "Pérou": "PE",
    "Philippines": "PH",
    "Îles Pitcairn": "PN",
    "Pologne": "PL",
    "Portugal": "PT",
    "Porto Rico": "PR",
    "Qatar": "QA",
    "Roumanie": "RO",
    "Russie": "RU",
    "Rwanda": "RW",
    "Réunion": "RE",
    "Saint-Barthélemy": "BL",
    "Sainte-Hélène": "SH",
    "Saint-Kitts-et-Nevis": "KN",
    "Sainte-Lucie": "LC",
    "Saint-Martin": "MF",
    "Saint-Pierre-et-Miquelon": "PM",
    "Saint-Vincent-et-les-Grenadines": "VC",
    "Samoa": "WS",
    "Saint-Marin": "SM",
    "Sao Tomé-et-Principe": "ST",
    "Sénégal": "SN",
    "Serbie": "RS",
    "Seychelles": "SC",
    "Sierra Leone": "SL",
    "Singapour": "SG",
    // "Saint-Martin": "SX",
    "Slovaquie": "SK",
    "Slovénie": "SI",
    "Îles Salomon": "SB",
    "Somalie": "SO",
    "Géorgie du Sud-et-les Îles Sandwich du Sud": "GS",
    "Soudan du Sud": "SS",
    "Sri Lanka": "LK",
    "Soudan": "SD",
    "Suriname": "SR",
    "Svalbard et Jan Mayen": "SJ",
    "Suède": "SE",
    "Suisse": "CH",
    "Syrie": "SY",
    "Taïwan": "TW",
    "Tadjikistan": "TJ",
    "Tanzanie": "TZ",
    "Thaïlande": "TH",
    "Timor-Leste": "TL",
    "Togo": "TG",
    "Tokelau": "TK",
    "Tonga": "TO",
    "Trinité-et-Tobago": "TT",
    "Tunisie": "TN",
    "Turquie": "TR",
    "Turkménistan": "TM",
    "Tuvalu": "TV",
    "Ouganda": "UG",
    "Ukraine": "UA",
    "Royaume-Uni": "GB",
    "Uruguay": "UY",
    "Ouzbékistan": "UZ",
    "Vanuatu": "VU",
    "Vatican": "VA",
    "Venezuela": "VE",
    "Viêt Nam": "VN",
    "Sahara occidental": "EH",
    "Yémen": "YE",
    "Zambie": "ZM",
    "Zimbabwe": "ZW",
  };
  linkedinPrefix = 'https://www.linkedin.com/company/';
  companyDetailsForm: FormGroup = this.createCompanyDetailsForm()
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(private activatedRoute: ActivatedRoute, private companyService: CompanyService,
    private spinner: NgxSpinnerService, private toastr: ToastrService, private formBuilder: FormBuilder, private paysServices:PaysService
  ) {
    this.starsForm = this.formBuilder.group({
      stars: new FormControl(),
    });
  }
  ngOnInit() {
    this.paysList=this.paysServices.getAllCountries();
    this.getId()
    this.phoneForm = new FormGroup({
      phone: new FormControl(undefined, [Validators.required]),
    });
    this.getReviewCandidat()
    this.getReviewCompany()
  }

  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      "Afghanistan": "Afghanistan",
      "South Africa": "Afrique du Sud",
      "Albania": "Albanie",
      "Algeria": "Algérie",
      "Germany": "Allemagne",
      "Andorra": "Andorre",
      "Angola": "Angola",
      "Anguilla": "Anguilla",
      "Antarctica": "Antarctique",
      "Antigua and Barbuda": "Antigua-et-Barbuda",
      "Saudi Arabia": "Arabie saoudite",
      "Argentina": "Argentine",
      "Armenia": "Arménie",
      "Aruba": "Aruba",
      "Australia": "Australie",
      "Austria": "Autriche",
      "Azerbaijan": "Azerbaïdjan",
      "Bahamas": "Bahamas",
      "Bahrain": "Bahreïn",
      "Bangladesh": "Bangladesh",
      "Barbados": "Barbade",
      "Belgium": "Belgique",
      "Belize": "Belize",
      "Benin": "Bénin",
      "Bermuda": "Bermudes",
      "Bhutan": "Bhoutan",
      "Belarus": "Biélorussie",
      "Bolivia": "Bolivie",
      "Bosnia and Herzegovina": "Bosnie-Herzégovine",
      "Botswana": "Botswana",
      "Brazil": "Brésil",
      "Brunei": "Brunei",
      "Bulgaria": "Bulgarie",
      "Burkina Faso": "Burkina Faso",
      "Burundi": "Burundi",
      "Cape Verde": "Cabo Verde",
      "Cambodia": "Cambodge",
      "Cameroon": "Cameroun",
      "Canada": "Canada",
      "Chile": "Chili",
      "China": "Chine",
      "Cyprus": "Chypre",
      "Colombia": "Colombie",
      "Comoros": "Comores",
      "Congo - Brazzaville": "Congo-Brazzaville",
      "Congo - Kinshasa": "Congo-Kinshasa",
      "North Korea": "Corée du Nord",
      "South Korea": "Corée du Sud",
      "Costa Rica": "Costa Rica",
      "Ivory Coast": "Côte d’Ivoire",
      "Croatia": "Croatie",
      "Cuba": "Cuba",
      "Curaçao": "Curaçao",
      "Denmark": "Danemark",
      "Djibouti": "Djibouti",
      "Dominica": "Dominique",
      "Dominican Republic": "République dominicaine",
      "Ecuador": "Équateur",
      "Egypt": "Égypte",
      "United Arab Emirates": "Émirats arabes unis",
      "Eritrea": "Érythrée",
      "Spain": "Espagne",
      "Eswatini": "Eswatini",
      "Estonia": "Estonie",
      "United States": "États-Unis",
      "Ethiopia": "Éthiopie",
      "Fiji": "Fidji",
      "Finland": "Finlande",
      "France": "France",
      "Gabon": "Gabon",
      "Gambia": "Gambie",
      "Georgia": "Géorgie",
      "Ghana": "Ghana",
      "Gibraltar": "Gibraltar",
      "Greece": "Grèce",
      "Grenada": "Grenade",
      "Greenland": "Groenland",
      "Guadeloupe": "Guadeloupe",
      "Guam": "Guam",
      "Guatemala": "Guatemala",
      "Guernsey": "Guernesey",
      "Guinea": "Guinée",
      "Guinea-Bissau": "Guinée-Bissau",
      "Equatorial Guinea": "Guinée équatoriale",
      "Guyana": "Guyana",
      "French Guiana": "Guyane française",
      "Haiti": "Haïti",
      "Honduras": "Honduras",
      "Hong Kong": "Hong Kong",
      "Hungary": "Hongrie",
      "Iceland": "Islande",
      "India": "Inde",
      "Indonesia": "Indonésie",
      "Iraq": "Irak",
      "Iran": "Iran",
      "Ireland": "Irlande",
      "Isle of Man": "Île de Man",
      "Israel": "Israël",
      "Italy": "Italie",
      "Jamaica": "Jamaïque",
      "Japan": "Japon",
      "Jersey": "Jersey",
      "Jordan": "Jordanie",
      "Kazakhstan": "Kazakhstan",
      "Kenya": "Kenya",
      "Kiribati": "Kiribati",
      "Kuwait": "Koweït",
      "Kyrgyzstan": "Kirghizistan",
      "Laos": "Laos",
      "Latvia": "Lettonie",
      "Lebanon": "Liban",
      "Lesotho": "Lesotho",
      "Liberia": "Libéria",
      "Libya": "Libye",
      "Liechtenstein": "Liechtenstein",
      "Lithuania": "Lituanie",
      "Luxembourg": "Luxembourg",
      "Macao": "Macao",
      "North Macedonia": "Macédoine du Nord",
      "Madagascar": "Madagascar",
      "Malawi": "Malawi",
      "Malaysia": "Malaisie",
      "Maldives": "Maldives",
      "Mali": "Mali",
      "Malta": "Malte",
      "Marshall Islands": "Îles Marshall",
      "Martinique": "Martinique",
      "Mauritania": "Mauritanie",
      "Mauritius": "Maurice",
      "Mayotte": "Mayotte",
      "Mexico": "Mexique",
      "Micronesia": "Micronésie",
      "Moldova": "Moldavie",
      "Monaco": "Monaco",
      "Mongolia": "Mongolie",
      "Montenegro": "Monténégro",
      "Montserrat": "Montserrat",
      "Morocco": "Maroc",
      "Mozambique": "Mozambique",
      "Myanmar": "Myanmar",
      "Namibia": "Namibie",
      "Nauru": "Nauru",
      "Nepal": "Népal",
      "Netherlands": "Pays-Bas",
      "New Caledonia": "Nouvelle-Calédonie",
      "New Zealand": "Nouvelle-Zélande",
      "Nicaragua": "Nicaragua",
      "Niger": "Niger",
      "Nigeria": "Nigeria",
      "Niue": "Niue",
      "Norfolk Island": "Île Norfolk",
      "Northern Mariana Islands": "Îles Mariannes du Nord",
      "Norway": "Norvège",
      "Oman": "Oman",
      "Pakistan": "Pakistan",
      "Palau": "Palaos",
      "Palestine": "Palestine",
      "Panama": "Panama",
      "Papua New Guinea": "Papouasie-Nouvelle-Guinée",
      "Paraguay": "Paraguay",
      "Peru": "Pérou",
      "Philippines": "Philippines",
      "Pitcairn Islands": "Îles Pitcairn",
      "Poland": "Pologne",
      "Portugal": "Portugal",
      "Puerto Rico": "Porto Rico",
      "Qatar": "Qatar",
      "Romania": "Roumanie",
      "Russia": "Russie",
      "Rwanda": "Rwanda",
      "Réunion": "Réunion",
      "Saint Barthélemy": "Saint-Barthélemy",
      "Saint Helena": "Sainte-Hélène",
      "Saint Kitts and Nevis": "Saint-Kitts-et-Nevis",
      "Saint Lucia": "Sainte-Lucie",
      "Saint Martin": "Saint-Martin",
      "Saint Pierre and Miquelon": "Saint-Pierre-et-Miquelon",
      "Saint Vincent and the Grenadines": "Saint-Vincent-et-les-Grenadines",
      "Samoa": "Samoa",
      "San Marino": "Saint-Marin",
      "Sao Tome and Principe": "Sao Tomé-et-Principe",
      "Senegal": "Sénégal",
      "Serbia": "Serbie",
      "Seychelles": "Seychelles",
      "Sierra Leone": "Sierra Leone",
      "Singapore": "Singapour",
      "Sint Maarten": "Saint-Martin",
      "Slovakia": "Slovaquie",
      "Slovenia": "Slovénie",
      "Solomon Islands": "Îles Salomon",
      "Somalia": "Somalie",
      "South Georgia and the South Sandwich Islands": "Géorgie du Sud-et-les Îles Sandwich du Sud",
      "South Sudan": "Soudan du Sud",
      "Sri Lanka": "Sri Lanka",
      "Sudan": "Soudan",
      "Suriname": "Suriname",
      "Svalbard and Jan Mayen": "Svalbard et Jan Mayen",
      "Swaziland": "Eswatini",
      "Sweden": "Suède",
      "Switzerland": "Suisse",
      "Syria": "Syrie",
      "Taiwan": "Taïwan",
      "Tajikistan": "Tadjikistan",
      "Tanzania": "Tanzanie",
      "Thailand": "Thaïlande",
      "Timor-Leste": "Timor-Leste",
      "Togo": "Togo",
      "Tokelau": "Tokelau",
      "Tonga": "Tonga",
      "Trinidad and Tobago": "Trinité-et-Tobago",
      "Tunisia": "Tunisie",
      "Turkey": "Turquie",
      "Turkmenistan": "Turkménistan",
      "Tuvalu": "Tuvalu",
      "Uganda": "Ouganda",
      "Ukraine": "Ukraine",
      "United Kingdom": "Royaume-Uni",
      "Uruguay": "Uruguay",
      "Uzbekistan": "Ouzbékistan",
      "Vanuatu": "Vanuatu",
      "Vatican City": "Vatican",
      "Venezuela": "Venezuela",
      "Vietnam": "Viêt Nam",
      "Western Sahara": "Sahara occidental",
      "Yemen": "Yémen",
      "Zambia": "Zambie",
      "Zimbabwe": "Zimbabwe"
    };
    return translations[name] || name;
  }
  /* get id esn from route */
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idEsn = params.params['id']
        this.getEsnById()
      }
    })
  }
  /* change country select and delete the string Governorate from state name*/
  onCountryChange(event: any): void {
    if (event) {
      this.stateList = State.getStatesOfCountry(event.isoCode)
      for (const iterator of this.stateList) {
        iterator.name = iterator.name.replace(" Governorate", "")
      }
    } else {
      this.stateList = []
      this.companyDetailsForm.get('city')?.setValue(null)
    }
  }
  email: any
  /* get esn details */
  type:any
  getEsnById() {
    this.spinner.show()
    this.companyService.getEsn(this.idEsn).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.type=res.data[0].type
        this.spinner.hide()
        this.dataEsn = res.data[0]
        this.nameEsn = res.data[0].name
        this.email = res.data[0].ContactEmail
     
        this.companyDetailsForm = this.createCompanyDetailsForm(res.data[0])
        console.log("detail esn",res.data[0] )
        this.companyDetailsForm.get('country')?.setValue(res.data[0]?.country == 'null' ? null : res.data[0].country)
        this.companyDetailsForm.get('city')?.setValue(res.data[0]?.city == 'null' ? null : res.data[0].city)

          // Initialisation du pays dans le formulaire
          const companyData = res.data[0];
          const countryValue = companyData.country || null;
          console.log("countryvalue",countryValue)
          if (countryValue) {
         //  let isoCode= countryValue.isoCode
         let isoCode:any = this.getIsoCodeFromCountryName(countryValue);
           // this.stateList = State.getStatesOfCountry(iscode);
           console.log("iscode",isoCode)
           this.stateList = State.getStatesOfCountry(isoCode);
           console.log("state listtt",this.stateList)
           // Modification des noms de villes si nécessaire
           for (const state of this.stateList) {
             state.name = state.name.replace(" Governorate", "");
           }
         } else {
           this.stateList = [];
         }


        this.phoneForm.get('phone')?.setValue(this.companyDetailsForm.get('phone_number')?.value)
        this.phoneForm.disable()
        this.responsable = res?.data[0]?.employee?.length ? res?.data[0]?.employee[0] : null
        this.companyDetailsForm.disable();
      },
      error: () => {
        this.spinner.hide()
      },
    })
  }

  
  getIsoCodeFromCountryName(countryName: string): string | null {
    return this.countryIsoCodes[countryName] || null;
  }
  /*get  candidat */
  getReviewCandidat() {
    this.spinner.show()
    this.companyService.getReviewCandidat(this.idEsn).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.spinner.hide()
        this.reviewCandidat = res.data.data
        this.starsForm.get('stars')?.setValue(this.reviewCandidat)


      },
      error: () => {
        this.spinner.hide()
      },
    })
  }

  /*get review candidat */
  getReviewCompany() {
    this.spinner.show()
    this.companyService.getReviewCompany(this.idEsn).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.spinner.hide()
        this.reviewCompany = res

      },
      error: () => {
        this.spinner.hide()
      },
    })
  }
  enableDisableMode() {
    this.disabledMode = !this.disabledMode
    this.companyDetailsForm.enable()
    this.companyDetailsForm.get('number_of_intervenant')?.disable()
    this.companyDetailsForm.get('number_of_salaries')?.disable()
  }
  onYearInput1(event: any) {
    const inputYear = event.target.value;

    if (inputYear === '' || inputYear.length === 4) {
      this.datevalid = true;
    } else {
      this.datevalid = false;
    }

  }
  /* create add subCategory form */
  createCompanyDetailsForm(data?: any) {
    const form = this.formBuilder.group({
      id: [data ? data.id : ""],
      name: [data ? data.name : "", [this.notOnlySpacesValidator()]],
      type: [data ? data.type : null],
      linkedin_link: [data ? data.linkedin_link : ""],
      website_link: [data ? data.website_link : "", [Validators.pattern(urlRegExp)]],
      logo: [data ? data.logo : ""],
      country: [data ? data.country : ""],
      city: [data ? data.city : ""],
      number_of_intervenant: [data ? data.number_of_intervenant : ""],
      siret: [data ? data.siret : ""],
      year_of_creation: [data ? new Date(data.year_of_creation).getFullYear().toString() : ''],
      address: [data && data.address != 'null' ? data.address : ""],
      number_of_salaries: [data ? data.number_of_salaries : ""],
      esn: [data ? data.esn : ""],
      about: [data ? data.about : ""],
    })
      this.companyDetailsForm = form;
      this.setValidatorRequirements();
    
      return form;

   
  }
  notOnlySpacesValidator(): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string | null | undefined = control?.value as string;

      // Check if the value is a string before calling trim
      const isValid = typeof value === 'string' && value.trim() !== '';

      return isValid ? null : { onlySpaces: true };
    };
  }
  updateInputType(type: 'text' | 'date') {
    const inputElement = document.getElementById('dateInput') as HTMLInputElement;
    inputElement.type = type;
    if (type == 'text') {
      if (this.companyDetailsForm.value.year_of_creation && this.companyDetailsForm.value.year_of_creation != '') this.companyDetailsForm.get('year_of_creation')?.setValue(moment(this.companyDetailsForm.value.year_of_creation).format('DD/MM/yyyy'));
    }
  }
  /* calculate experience */
  show_experience: boolean = false
  show_error: boolean = false
  experienceText!: string
  isChosenDateValid!: boolean;

  isValidDate(dateString: any): boolean {
    let datex;
    if (dateString instanceof Date) {
      datex = dateString;
    } else {
      datex = new Date(dateString.value); // Récupérer l'objet Date à partir de la chaîne
    }
  
    if (!datex || !(datex instanceof Date) || isNaN(datex.getTime())) {
      console.log("Date invalide");
      this.datevalid = false;
      return false;
    }
  
    const year = datex.getFullYear();
    if (year < 1900 || year > 2100) {
      console.log("Année hors de la plage acceptable");
      this.datevalid = false;
      return false;
    }
  
    console.log("date valide");
    this.datevalid = true;
    return true;
  }
  
  selectDate() {
    const today = new Date();
    const chosenDate = new Date(this.companyDetailsForm.value.year_of_creation);
    const chosenYear = chosenDate.getFullYear();
    const todayYear = today.getFullYear();
    this.isChosenDateValid = chosenYear > todayYear;
    console.log("chosenYear:", chosenYear); // Vérifiez la valeur de chosenYear
    console.log("isChosenDateValid:", this.isChosenDateValid); // Vérifiez la validité de la date
  }
  getYearFromDate(date: string | Date): string {
    if (!date) return '---';
    const year = new Date(date).getFullYear();
    return isNaN(year) ? '---' : year.toString();
  }
  calculateExperience() {
    const today = new Date();
    const chosenDate = new Date(this.companyDetailsForm.value.year_of_creation);
    const currentYear = today.getFullYear();
    const startYear = chosenDate.getFullYear();
    console.log("startYear:", startYear); // Vérifiez la valeur de startYear
  
    if (isNaN(startYear) || startYear > currentYear) {
      this.show_experience = false;
      this.experienceText = '';
      return;
    }
  
    const years = currentYear - startYear;
    this.show_experience = years >= 0;
    this.experienceText = `${years} ans`;
  }
  
  modifier() {
    this.setValidatorRequirements();
    this.companyDetailsForm.markAllAsTouched();
    this.companyDetailsForm.markAsDirty();
    this.formData.append('name', this.companyDetailsForm.get('name')?.value);
    this.formData.append('about', this.companyDetailsForm.get('about')?.value);

  
      this.formData.append('linkedin_link', this.companyDetailsForm.value.linkedin_link);
    
    // if (this.companyDetailsForm.value?.website_link) {
    //   this.formData.append('website_link', this.companyDetailsForm.value.website_link);
    // }
    this.formData.append('website_link', this.companyDetailsForm.value.website_link?? '');
    if (this.file) {
      this.formData.append('logo', this.file);
    }else {
      this.formData.append('file_name', this.companyDetailsForm.get('logo')?.value);

    }
    
    this.formData.append('country', this.companyDetailsForm.get('country')?.value ?? '');
    this.formData.append('city', this.companyDetailsForm.get('city')?.value ?? '');
    if (this.companyDetailsForm.value?.siret) {
      this.formData.append('siret', this.companyDetailsForm.get('siret')?.value);
    }
    this.formData.append('year_of_creation', this.companyDetailsForm.get('year_of_creation')?.value ?? '');
    const addressValue = this.companyDetailsForm.get('address')?.value;
    this.formData.append('address', (addressValue !== null && addressValue !== undefined) ? addressValue : '');
    this.spinner.show();
    this.companyService.updateCompany(this.companyDetailsForm.get('id')?.value, this.formData)
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.formData = new FormData();
          this.file = null;

          if (res.status == 200) {
            this.toastr.success(updatedCompany);
            window.location.reload();
          }

        },
        error: (err) => {
          if(err?.error){
            if(err.error?.data['name']){
              this.toastr.error("Nom d'entreprise existe déja")
            }else if(err.error?.data['linkedin_link']){
              this.toastr.error("linkedin existe déja")
            }
            else if (err.error.data['website_link']) {
              this.toastr.error("Site web existe déjà");
            }
            else 
            this.toastr.error(serverError);
          }
          this.spinner.hide();
          this.formData = new FormData();
          this.file = null;
         
        }
      });
  }
  /* upload file */
  isImageChanged: boolean = false;
  uploadFile(event: any): void {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    this.file = file
    this.fileName = this.file.name
    const allowedExtensions = ['.jpeg', '.jpg', '.png' , '.gif','.jfif','.avif'];
    const fileExtension = file.name.toLowerCase().substr(file.name.lastIndexOf('.'));
    if (file) {

      if (!allowedExtensions.includes(fileExtension)) {
        this.fileExtention = fileTypeExtention
        this.toastr.error("Le format du fichier n'est pas accepté.Veuillez choisir une photo")
      } else if (file.size > maxSize) {
        this.fileError = maxSizeFile
      }
      else {
        this.fileError = ''
        this.fileExtention = ''
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(file);
      }
    }
  }
  isImage(): boolean {
    const allowedExtensions = ['.jpeg', '.jpg', '.png' , '.gif','.jfif','.avif'];
    const fileExtension = (this.fileName || '').toLowerCase().substr((this.fileName || '').lastIndexOf('.'));
    return allowedExtensions.includes(fileExtension);
  }
  clearImage() {
    this.fileName = '';
    this.fileExtention = ''
    this.fileError = ''
    this.selectedImage = null;
    this.file = null;
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Clear the file input value
    }

    this.companyDetailsForm.get('logo')?.setValue("avatar.png")
  }
  setValidatorRequirements() {
    const linkedinControl = this.companyDetailsForm.get('linkedin_link');
    const websiteControl = this.companyDetailsForm.get('website_link');
  
    if (!linkedinControl?.value || linkedinControl.value === '') {
      websiteControl?.setValidators([Validators.required, Validators.pattern(urlRegExp)]);
      linkedinControl?.clearValidators();
    } else {
      websiteControl?.setValidators([Validators.pattern(urlRegExp)]);
      linkedinControl?.setValidators([Validators.pattern(linkedCompanyRegExp)]);
    }
  
    if (!websiteControl?.value || websiteControl.value === '') {
      linkedinControl?.setValidators([Validators.required, Validators.pattern(linkedCompanyRegExp)]);
      websiteControl?.clearValidators();
    }
  
    websiteControl?.updateValueAndValidity();
    linkedinControl?.updateValueAndValidity();
  }
  



  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }


  

}
