import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CCreateCandidate,
  CSearchCandidate,
  ICandidat,
  headerColumns,
  months,
  statusCandidate,
  listContracts,
  headerColumns2,
} from '../../../../core/models/candidat';
import { CandidatService } from '../../../../core/services/candidat/candidat.service';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  EmptyCV,
  createdCandidate,
  emailExist,
  emailSended,
  emptyFileTest,
  fileTypeExtention,
  iscvvalide,
  linkdinExist,
  maxSizeFile,
  noCvSelected,
  noTestFileSelected,
  obligationSelect,
  phoneNumberExist,
  phoneNumberInvalid,
  serverError,
} from '../../../../core/models/messages';
import { ICountry, State } from 'country-state-city';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { linkedInRegExp, patternEmail } from '../../../../core/models/pattern';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { TenderService } from '../../../../core/services/tender/tender.service';
import { SortDataService } from '../../../../core/services/sort-data/sort-data.service';
import * as XLSX from 'xlsx';
import { DatePipe, formatDate } from '@angular/common';
import { PaysService } from '../../../../shared/services/pays.service';
import { PrimeNGConfig } from 'primeng/api';
@Component({
  selector: 'app-list-candidat',
  templateUrl: './list-candidat.component.html',
  styleUrls: ['./list-candidat.component.css'],
})
export class ListCandidatComponent implements OnInit {
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  file: any = '';
  /* form data */
  formData = new FormData();
  mobilities: any[] = [];
  countries: any[] = [];
  /* formGroup */
  searchFormGroup: FormGroup = this.createSearchForm(new CSearchCandidate());
  addCandidateFormGroup: FormGroup = this.createAddForm(new CCreateCandidate());
  addBulk!: FormGroup;
  isSelectDisabled: boolean = false; // Set it to true to disable the select by default

  /* boolean */
  seeMore: boolean = false;
  loadSpinnerTable: boolean = false;
  loadSpinner: boolean = false;
  experience: boolean = false;
  headerChecked: boolean = false;
  search: boolean = false;
  pagination: boolean = false;
  /* array */
  listCandidats: ICandidat[] = [];
  headerColumns: { checked: boolean; name: string; code: string }[] =
    headerColumns;
  selectedCandidats: any = [];

  stateList: any[] = [];
  statut = statusCandidate;
  listMonths = months;
  yearsList: number[] = [];
  /* string */
  typeDate: string = 'text';
  fileExtention: string = '';
  fileError: string = '';
  fileName: string = '';
  url: string = environment.baseUrl + '/api';
  currentSortDirection: number = 2;
  fileBulkError: any;

  /* number */
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  selectedCountryCode: string = 'FR';
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  skillsList: { name_FR: string }[] = [];
  dataHeader: any = [];
  listNames: string[] = [];
  sortAttr: string = '';
  fromSelection: boolean = false;
  fileExtentions: string = '';
  afterMobilty: any[] = [];
  linkedinPrefix = 'https://www.linkedin.com/in/';
  validPhoneNumber: boolean = false;
  /* examples of data to the selectors */
  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 2, name: '1 mois' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];
  data: any[] = [
    {
      competences: [
        'nom',
        'prenom',
        'pays',
        'num',
        'email',
        'poste',
        'contrat',
        'diplomes',
        'certificats',
        'salaire',
        'tjm',
        'mois_premiere_experience',
        'annee_premiere_experience',
        'linkedin',
        'cv',
        'photo',
        'competences',
      ],
    },
  ];
  contratRequest = listContracts;

  selectedFile: File | null = null;
  /* modal */
  modalRef?: BsModalRef;
  paysList: ICountry[] = [];
  isLoadSpinner: boolean = true;

  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private candidatService: CandidatService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: BsModalService,
    private jobRequestService: TenderService,
    private sortDataService: SortDataService,
    private paysServices: PaysService,
    private elRef: ElementRef,
    private primengConfig: PrimeNGConfig,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {

    this.paysList = this.paysServices.getAllCountries();
    this.searchFormGroup.get('mobility')?.disable();
    this.getListElementHeader();
    this.getYearsList();
    this.getListSkills();
    this.getListCandidats();
    this.primengConfig.setTranslation({
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer'
    });
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
    this.primengConfig.setTranslation({
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer'
    });
    // const initialDate = this.addCandidateFormGroup.value.date_first_experience;
    // if (initialDate) {
    //   this.addCandidateFormGroup.patchValue({ date_first_experience: this.formatDate(initialDate) });
    // }
    // this.candidatService.searchResults$.subscribe(results => {
    //   if (results && results.length > 0) {
    //     this.listCandidats = results;
    //     console.log("listCandidate",this.listCandidats);
    //   }else this.getListCandidats()
    // });

  }

  // updateInputType(type: 'date') {
  //   const inputElement = document.getElementById(
  //     'dateInput'
  //   ) as HTMLInputElement;
  //   inputElement.type = type;

  //   if (type == 'date') {
  //     const dateLastUpdateValue = this.searchFormGroup.value.date_last_update;

  //     if (dateLastUpdateValue !== '') {
  //       const parsedDate = moment(dateLastUpdateValue, 'DD/MM/yyyy', true); 
  //       if (parsedDate.isValid()) {
  //         const formattedDate = parsedDate.format('DD/MM/yyyy'); 
  //         this.searchFormGroup.get('date_last_update')?.setValue(formattedDate);
  //         console.log('dateLastUpdateValue', formattedDate);
  //       } else {
  //       }
  //     } else {

  //     }
  //   }
  // }

  getListElementHeader() {
    this.candidatService
      .getHeaderElements(1)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.dataHeader = res.data;
          for (const iterator of this.dataHeader) {
            if (
              (iterator.checked == 'oui' && !iterator.checked_user) ||
              (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
              (iterator.checked == 'oui' && iterator.checked_user == 'oui')
            )
              this.listNames.push(iterator.name);
          }
          this.dataHeader.map((el: any) => {
            if (
              (el.checked == 'oui' && !el.checked_user) ||
              (el.checked_user == 'oui' &&
                (el.checked == 'non' || el.checked == 'oui'))
            ) {
              return (el.checkAdmin = true);
            } else {
              return (el.checkAdmin = false);
            }
          });
        },
        error: () => { },
      });
  }
  getListSkills() {
    this.jobRequestService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => { },
      });
  }
  openContextMenu(event: MouseEvent, data: any) {
    event.preventDefault();
    const profileLink = `/acceuil/candidats/profil-candidat?idCandidat=${data.id}`;
    window.open(profileLink);
  }

  getYearsList() {
    const currentYear = new Date().getFullYear();
    const startYear = 1990;
    for (let year = currentYear; year >= startYear; year--) {
      this.yearsList.push(year);
    }
  }
  /* get pagination items */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchFormGroup.dirty) {
        if (this.search) this.searchCandidate();
      } else if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getListCandidats();
      }
      this.handleCheck();
    }
  }

  fileErr: boolean = false;
  /*select file and add Bulk Candidates */
  onFileSelected(event: any) {
    this.fileErr = false;
    this.fileExtentions = '';
    this.selectedFile = event.target.files[0];

    if (this.selectedFile) {
      console.log('Fichier sélectionné :', this.selectedFile);
      const fileExtension = this.selectedFile.name
        .toLowerCase()
        .substr(this.selectedFile.name.lastIndexOf('.'));
      const allowedExtensions = ['.csv', '.xlsx', '.xls'];
      if (!allowedExtensions.includes(fileExtension)) {
        console.log('selectedFile', fileExtension);
        this.fileExtentions = fileTypeExtention;
        this.fileErr = true;
      }
    }
  }

  getFileExtension(filename: string): string {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  }
  formAddBulk() {
    this.addBulk = this.formBuilder.group({
      file: [null, [Validators.required]],
    });
  }

  addBulkCandidates() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      this.spinner.show();
      this.candidatService.addBulkCandidates(formData).subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.fileBulkError = res.export_file;
          console.log('file bluk error', this.fileBulkError);
          this.getbulkCandidate(this.fileBulkError);
          this.toastr.success('Fichier importé avec succès');
          this.closeModal();
          this.selectedFile = null;
        },
        error: (err) => {
          console.log("error", err)
          this.spinner.hide();
          if (err?.error) {
            if (err.error?.message) {
              if (err.error.message === 'File contains only headers.') {
                this.toastr.error('Le fichier est vide');
              } else if (
                err.error.message ===
                'File headers do not match the expected headers.'
              ) {
                this.toastr.error(
                  'Les en-têtes de fichiers ne correspondent pas aux en-têtes attendus.'
                );
              } else {
                this.toastr.error(serverError);
              }
            } else {
              this.toastr.error(serverError);
            }
            this.closeModal();
            this.selectedFile = null;
          }
        },
      });
    }
  }
  getbulkCandidate(file: File) {
    let exportSuccess = false;
    this.candidatService.getbulkCandidate(file).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], { type: 'application/octet-stream' });
        saveAs(blob, this.fileBulkError);
        exportSuccess = true;
        if (exportSuccess) {
          this.toastr.success(
            'Résultat de fichier importé a été exporté avec succès'
          );
        }
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error(serverError);
      },
    });
  }
  idsUsers: any[] = [];
  exportCandidats(file?: File) {
    if (this.selectedCandidats.length) {
      for (const iterator of this.selectedCandidats) {
        this.idsUsers.push(iterator.id);
      }
      let exportSuccess = false;
      let data = this.idsUsers;
      console.log(this.idsUsers);
      this.candidatService.exportCandidat(file, data).subscribe({
        next: (res: any) => {
          this.spinner.hide();

          const blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, 'RH_Profils.xlsx');
          exportSuccess = true;
          if (exportSuccess) {
            this.toastr.success('Fichier exporté avec succès');
          }
          this.headerChecked = false;
          this.idsUsers = []
          window.location.reload();
          this.searchCandidate()
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
    } else {
      this.spinner.hide();
      this.toastr.info(obligationSelect);
    }
  }

  exportToExcel(): void {
    this.showGlobalSpinner();
    const fileName = 'RH_Profils.xlsx';
    if (this.selectedCandidats.length) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        this.selectedCandidats
      );
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'selectedCandidats');
      XLSX.writeFile(wb, fileName);

      console.log('Candidats sélectionnés :', this.selectedCandidats);
      this.selectedCandidats.map((el: ICandidat) => {
        el.checked = false;
      });
      this.hideGlobalSpinner();
      window.location.reload();
      this.selectedCandidats = [];
    } else {
      this.hideGlobalSpinner();
      this.toastr.info(obligationSelect);
    }
  }

  /*download file add bulk */
  downloadCsv() {
    this.candidatService.downloadcsvfileAddBulkCandidat().subscribe({
      next: (res: Blob) => {
        console.log("res", res);
        const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'ajout en masse admin candidat.csv');
      },
      error: (err) => {
        console.log('error', err);
        // Handle the error appropriately
      },
    });
  }
  // downloadCsv() {
  //   this.candidatService.downloadcsvfileAddBulkCandidat().subscribe({
  //     next: (response: HttpResponse<Blob>) => {
  //       console.log("response",response)
  //       const contentDisposition = response.headers.get('content-disposition');
  //       const fileName = this.getFileNameFromContentDisposition(contentDisposition);
  //       const blob = new Blob([response.body!], { type: 'text/csv;charset=utf-8;' });
  //       saveAs(blob, fileName);
  //     },
  //     error: (err) => {
  //       console.log('error', err);
  //       // Handle the error appropriately
  //     },
  //   });
  // }

  // private getFileNameFromContentDisposition(contentDisposition: string | null): string {
  //   if (!contentDisposition) {
  //     return 'file.csv';
  //   }
  //   const matches = /filename="([^"]+)"/.exec(contentDisposition);
  //   return matches != null && matches[1] ? matches[1] : 'file.csv';
  // }




  // downloadCsv() {
  //   const fileSaver = saveAs;
  //   if (Array.isArray(this.data)) {
  //     const headers = Object.keys(this.data[0]);
  //     const csvContent = this.data
  //       .map((item) => {
  //         return headers
  //           .map((header) => {
  //             if (Array.isArray(item[header])) {
  //               return item[header].join(';');
  //             } else if (
  //               typeof item[header] === 'object' &&
  //               item[header] !== null
  //             ) {
  //               return JSON.stringify(item[header]);
  //             } else {
  //               return item[header];
  //             }
  //           })
  //           .join(',');
  //       })
  //       .join('\n');

  //     fileSaver(
  //       new Blob([csvContent], { type: 'text/csv;charset=utf-8' }),
  //       'ajout en masse admin candidat.csv'
  //     );
  //   } else {
  //     console.error('Invalid data format. Expected an array.');
  //   }
  // }

  // sortData(item: string) {
  //   for (const iterator of headerColumns2) {
  //     if (item == iterator.name) item = iterator.code;
  //   }
  //   this.sortAttr = item;
  //   this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;

  //   if (this.searchFormGroup.dirty)
  //     this.listCandidats = this.sortDataService.sortArray(
  //       this.listCandidats,
  //       item,
  //       this.currentSortDirection
  //     );
  //   else this.getListCandidats();
  // }
  sortData(item: string) {
    for (const iterator of headerColumns2) {
      if (item == iterator.name) item = iterator.code;
    }
    this.sortAttr = item;
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.currentPage = 1;
    this.endIndex = 5;
    // this.endIndex=this.itemsPerPage;


    if (this.searchFormGroup.dirty) {
      this.listCandidats = this.sortDataService.sortArray(
        this.listCandidats,
        item,
        this.currentSortDirection
      );
    } else {
      this.getListCandidats();
    }

    // Make sure to fetch the first page of sorted data
    this.getListCandidats();
  }
  openModal(template: TemplateRef<any>) {
    this.addCandidateFormGroup.reset();
    this.diplome.clear();
    this.diplome.push(this.createFormDiplome());
    this.certificat.clear();
    this.certificat.push(this.createFormCertificate());

    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    this.addCandidateFormGroup.get('open_for_mission')?.setValue(1);
    this.addCandidateFormGroup.get('mobility')?.disable();
  }
  openModalBulk(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    this.addCandidateFormGroup.get('open_for_mission')?.setValue(1);
    this.fileExtentions = '';
  }
  closeModal() {
    this.modalRef!.hide();
    this.addCandidateFormGroup.reset();
    this.fileError = '';
    this.fileExtention = '';
    this.fileName = '';
    this.experience = false;
  }

  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
    }
  }


  /* create search form */
  createSearchForm(data?: CSearchCandidate) {
    return this.formBuilder.group({
      ID_user: ['', data?.ID_user],
      first_name: ['', data?.first_name],
      email: ['', data?.email],
      score: ['', data?.score],
      current_country: [null, data?.current_country],
      status: [null, data?.status],
      date_last_update: [
        data && data?.date_last_update
          ? formatDate(data?.date_last_update, 'dd-mm-yyyy', 'en')
          : '',
      ],
      skillsOr: [[], data?.skillsOr],
      skillsAnd: [[], data?.skillsAnd],
      availability: [[], data?.availability],
      mobility: [[], data?.mobility],
      destination_country: [null, data?.destination_country],
      desired_contract: [[], data?.desired_contract],
      min_experience_years: ['', data?.min_experience_years],
      max_experience_years: ['', data?.max_experience_years],
      tjm: ['', data?.tjm],
      tjm_range: ['', data?.tjm_range],
      desired_salary: ['', data?.desired_salary],
      desired_salary_range: ['', data?.desired_salary_range],
      desired_workstation: ['', data?.desired_workstation],
      search: [
        '',
        [Validators.pattern(/^("([^"]*"(AND|NOT|,|&&)[^"]*)*"|[^\s"']+)$/)],
      ],
    });
  }
  onSalaryRangeInput(event: any) {
    const value = event.target.value;
    if (value.toLowerCase().endsWith('k')) {
      this.searchFormGroup.controls['desired_salary_range'].setValue(
        this.convertKToNumber(value)
      );
    }
  }

  alreadyConverted = false;
  convertKToNumber(value: any) {
    if (typeof value === 'string' && value.toLowerCase().endsWith('k')) {
      // Si la valeur se termine par 'k'
      const numericValue = parseFloat(value.slice(0, -1));
      if (!isNaN(numericValue)) {
        // Convertir en multipliant par 1000
        this.alreadyConverted = true; // Marquer comme déjà convertie
        return numericValue * 1000;
      }
    } else if (!isNaN(value)) {
      // Si la valeur est déjà un nombre, et si elle n'a pas déjà été convertie, convertir en multipliant par 1000
      if (!this.alreadyConverted) {
        this.alreadyConverted = true; // Marquer comme déjà convertie
        return parseFloat(value) * 1000;
      } else {
        return parseFloat(value); // Déjà convertie, retourner sans multiplier à nouveau
      }
    }
  }
  /* create add candidat form */
  createAddForm(data?: CCreateCandidate) {
    return this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(patternEmail),
        ],
      ],
      phone_number: ['', this.phoneNumberValidator.bind(this)],
      destination_country: [null],
      mobility: [''],
      availability: [null],
      month_exp: [null],
      year_exp: [null],
      date_first_experience: [''],
      current_country: [null],
      desired_contract: [null],
      tjm: [''],
      desired_salary: [''],
      linkedin_link: ['', [Validators.pattern(linkedInRegExp)]],
      open_for_mission: [data ? data.open_for_mission : 1],
      cv: [''],
      diplome: this.formBuilder.array([this.createFormDiplome()]),
      certificat: this.formBuilder.array([this.createFormCertificate()]),
      skills: [[]],
      desired_workstation: [''],
    });
  }

  /* get form array diploma */
  get diplome() {
    return this.addCandidateFormGroup.get('diplome') as FormArray;
  }
  /* get form array diploma */
  get certificat() {
    return this.addCandidateFormGroup.get('certificat') as FormArray;
  }

  setValidatorRequirements() {
    const cvControl = this.addCandidateFormGroup.get('cv');
    const linkedinControl = this.addCandidateFormGroup.get('linkedin_link');

    if (!cvControl!.value && !linkedinControl!.value) {
      cvControl!.setValidators([Validators.required]);
      linkedinControl!.setValidators([
        Validators.required,
        Validators.pattern(linkedInRegExp)
      ]);
    } else if (cvControl!.value) {
      linkedinControl!.setValidators([Validators.pattern(linkedInRegExp)]);
    } else if (linkedinControl!.value) {
      cvControl!.clearValidators();
      linkedinControl!.setValidators([
        Validators.required,
        Validators.pattern(linkedInRegExp)
      ]);
    }

    cvControl!.updateValueAndValidity();
    linkedinControl!.updateValueAndValidity();
  }
  /* set validation for multiple form control */
  setValidations(name: string) {
    name === 'year_exp'
      ? this.addCandidateFormGroup
        .get(name)
        ?.setValidators(
          Validators.compose([Validators.required, Validators.maxLength(4)])
        )
      : this.addCandidateFormGroup
        .get(name)
        ?.setValidators(Validators.required);
    this.addCandidateFormGroup.get(name)!.updateValueAndValidity();
  }
  /* clearValidators */
  clearValidators(name: string) {
    this.addCandidateFormGroup.get(name)?.clearValidators();
    this.addCandidateFormGroup.get(name)?.updateValueAndValidity();
  }
  /* key inter number */
  keyPress(event: any) {
    let pattern = /^[\d]$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    return pattern;
  }
  /* create array form diplomes */
  createFormDiplome(data?: any) {
    return this.formBuilder.group({
      name: [data?.name ? data.name : ''],
    });
  }
  capitalizeFirstLetter(string: any) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  /* create array form certificats */
  createFormCertificate(data?: any) {
    return this.formBuilder.group({
      name: [data?.name ? data.name : ''],
    });
  }
  onCountryChange(mode: string): void {
    this.addCandidateFormGroup.get('mobility')?.enable();
    this.countries = this.addCandidateFormGroup.get(
      'destination_country'
    )?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.addCandidateFormGroup.get('mobility')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode);
      if (after.length == 0) after.push({ name: 'Tout le pays' });
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter(
        (item) => !differentItemsArray1.includes(item)
      );
      this.addCandidateFormGroup
        .get('mobility')
        ?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.addCandidateFormGroup.get('mobility')?.disable();
        this.addCandidateFormGroup.get('mobility')?.setValue(null);
      }
    }
  }
  onMobilityChangeSearch(mode: string) {
    this.mobilities = this.addCandidateFormGroup.get('mobility')?.value;
    let paysLists: any[] = [];
    this.countries = this.addCandidateFormGroup.get(
      'destination_country'
    )?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach((item) => {
        stateListAfter.push(item.name); ////  }
      });
    });
    console.log(
      'stateList in change mobilities mobilities : ',
      this.mobilities
    );
    console.log(
      'stateList in change mobilities stateListAfter  : ',
      stateListAfter
    );
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    console.log('stateList in change mobilities : ', this.stateList);
    this.mobilities = this.addCandidateFormGroup.get('mobility')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }
  /* change country select and delete the string Governorate from state name*/
  onCountryChangeSearch(mode: string): void {
    this.searchFormGroup.get('mobility')?.enable();
    this.countries = this.searchFormGroup.get('destination_country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.searchFormGroup.get('mobility')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode);
      if (after.length == 0) after.push({ name: 'Tout le pays' });
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter(
        (item) => !differentItemsArray1.includes(item)
      );
      this.searchFormGroup.get('mobility')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.searchFormGroup.get('mobility')?.disable();
        this.searchFormGroup.get('mobility')?.setValue(null);
      }
    }
  }
  onMobilityChange(mode: string) {
    this.mobilities = this.searchFormGroup.get('mobility')?.value;
    let paysLists: any[] = [];
    this.countries = this.searchFormGroup.get('destination_country')?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach((item) => {
        stateListAfter.push(item.name); ////  }
      });
    });
    console.log(
      'stateList in change mobilities mobilities : ',
      this.mobilities
    );
    console.log(
      'stateList in change mobilities stateListAfter  : ',
      stateListAfter
    );
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    console.log('stateList in change mobilities : ', this.stateList);
    this.mobilities = this.searchFormGroup.get('mobility')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }

  findDuplicates(array1: any, array2: any) {
    let occurrences: any[] = [];
    let duplicates: any = [];
    for (let element of array1) {
      occurrences[element] = (occurrences[element] || 0) + 1;
    }
    for (let element of array2) {
      if (occurrences[element] && !duplicates.includes(element)) {
        duplicates.push(element);
      }
    }
    return duplicates;
  }

  isArraySubset(subset: any[], superset: any[]) {
    for (let str of subset) {
      if (!superset.includes(str)) {
        return false;
      }
    }
    return true;
  }
  findCountryByName(name: any) {
    return State.getAllStates();
  }
  exportCvWegest(data: any) {
    this.spinner.show();
    let exportSuccess = false;
    this.candidatService.exportCvWegestCandidatList(data.id).subscribe({
      next: (res: any) => {
        this.spinner.hide();

        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'CvWegest.pdf');
        exportSuccess = true;
        if (exportSuccess) {
          this.toastr.success('Fichier exporté avec succès');
        }
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error(serverError);
      },
    });
  }

  /* change if experience exist or not */
  selectedDate: string | null = null;

  selectDate() {
    const today = new Date();
    const chosenDateString =
      this.addCandidateFormGroup.value.date_first_experience;
    const chosen = new Date(chosenDateString);

    if (isNaN(chosen.getTime())) {
      // Handle invalid date scenario
      this.isChosenDateValid = false;
      console.error('Invalid date chosen.');
    } else {
      this.isChosenDateValid = chosen > today;
    }
  }

  changeExperience(event: any) {
    this.experience = event.target.checked;

    if (this.experience) {
      this.selectedDate =
        this.addCandidateFormGroup.value.date_first_experience;
      this.addCandidateFormGroup.patchValue({ date_first_experience: '' });
      this.isChosenDateValid = false;
    } else {
      if (this.selectedDate) {
        const formattedDate = this.formatDateForInput(this.selectedDate);
        this.addCandidateFormGroup.patchValue({
          date_first_experience: formattedDate,
        });
        const chosen = new Date(formattedDate);
        const today = new Date();
        this.isChosenDateValid = chosen > today;
      } else {
        const chosenDateString =
          this.addCandidateFormGroup.value.date_first_experience;
        const chosen = new Date(chosenDateString);
        const today = new Date();
        this.isChosenDateValid = chosen > today;
      }
    }
  }

  formatDateForDisplay(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${day}/${month}/${year}`;
  }

  formatDateForInput(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  /* change open for mossion */
  changeMission(event: any) {
    this.addCandidateFormGroup
      .get('open_for_mission')
      ?.setValue(event.target.checked ? 1 : 0);
  }
  // changePhoneNumber(event: any) {
  //   const phoneNumberControl = this.addCandidateFormGroup.get('phone_number');

  //   if (event.phoneNumber && phoneNumberControl) {
  //     // Check if the input is a number and the country code is selected
  //     if (!isNaN(Number(event.phoneNumber)) && event.selectedCountry) {
  //       // Ignore the event, as it's likely an automatic conversion
  //       return;
  //     }

  //     phoneNumberControl.setValue(event.phoneNumber);
  //   }
  // }
  countryNotFound: boolean = true;

  selectedCountry: any

  ngAfterViewInit() {
    console.log('Phone Input Component:', this.phoneInput?.nativeElement);
  }

  handlePhoneNumberInput(event: any) {
    const phoneNumber = event.phoneNumber;
    this.validPhoneNumber = event.isNumberValid;
    this.addCandidateFormGroup.get('phone_number')?.setValue(phoneNumber);
    this.addCandidateFormGroup.get('phone_number')?.updateValueAndValidity(); // For triggering validation
  }

  handleCountryChange(event: any) {
    this.selectedCountry = event.selectedCountry;
    console.log('Selected Country:', this.selectedCountry);
    this.addCandidateFormGroup.get('phone_number')?.updateValueAndValidity(); // For triggering validation
  }

  handleCountrySearch(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    const countryList = this.getCountryList();
    this.countryNotFound = searchTerm && !countryList.some(country =>
      country.name.toLowerCase().includes(searchTerm) ||
      country.iso2.toLowerCase().includes(searchTerm) ||
      country.dialCode.includes(searchTerm)
    );
  }

  getCountryList(): any[] {
    return [
      { name: 'France', iso2: 'fr', dialCode: '+33' },
      { name: 'Tunisia', iso2: 'tn', dialCode: '+216' },
      // Ajoutez d'autres pays ici
    ];
  }

  phoneNumberValidator(control: any) {
    const phoneNumber = control.value;

    // Assurez-vous qu'il y a une sélection de pays
    if (this.selectedCountry && this.selectedCountry.iso2 === 'tn') {
      const tunisianPhoneNumberPattern = /^\+216\s[0-9]{2}\s[0-9]{3}\s[0-9]{3}$/;

      if (phoneNumber && !tunisianPhoneNumberPattern.test(phoneNumber)) {
        return { invalidPhoneNumber: true };
      }
    }

    return null;
  }


  countryInList(searchTerm: string): boolean {
    // Implémenter la logique pour vérifier si le pays existe dans la liste
    // Par exemple, comparer le terme de recherche avec les noms des pays disponibles
    // Retourner true si le pays est trouvé, sinon false
    return true; // Exemple simplifié, remplacer par la logique réelle
  }


  /* upload cv / image */
  uploadFile(event: any) {
    this.file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    this.fileName = this.file.name;
    console.log('file', this.file);
    if (this.file) {
      const allowedExtensions = ['.pdf', '.docx', '.doc'];
      const fileExtension = this.file.name
        .toLowerCase()
        .substr(this.file.name.lastIndexOf('.'));
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileExtention = fileTypeExtention;
      } else if (this.file.size > maxSize) {
        this.fileError = maxSizeFile;
      } else {
        this.fileError = '';
        this.fileExtention = '';
      }
    }
    this.addCandidateFormGroup.markAsDirty();
    this.setValidatorRequirements();
  }
  resetCV() {
    const inputFile = document.getElementById('cv') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = '';

      this.fileExtention = '';
      this.fileError = '';
    }
    this.fileName = '';
    this.fileExtention = '';
    this.addCandidateFormGroup.get('cv')!.setValue('');
    this.setValidatorRequirements();
  }
  appendSkills(formValue: any) {
    this.formData.delete('skills[]');
    return formValue.skills?.forEach((skill: any) => {
      this.formData.append('skills[]', skill);
    });
  }
  appendMobility(formValue: any) {
    this.formData.delete('mobility[]');
    if (formValue.mobility?.length) {
      return formValue?.mobility.forEach((mobility: any) => {
        this.formData.append('mobility[]', mobility);
      });
    }
  }
  appendDestinationCountry(formValue: any) {
    this.formData.delete('destination_country[]');
    if (formValue.destination_country?.length) {
      return formValue?.destination_country.forEach(
        (destination_country: any) => {
          this.formData.append('destination_country[]', destination_country);
        }
      );
    }
  }
  appendDesired_contract(formValue: any) {
    this.formData.delete('desired_contract[]');
    formValue.desired_contract = formValue.desired_contract;
    return formValue.desired_contract?.forEach((desired_contract: any) => {
      this.formData.append('desired_contract[]', desired_contract);
    });
  }
  appendDiplome(formValue: any) {
    this.formData.delete('diplomes[]')
    const diplome = formValue.diplome;
    if (diplome) {
      diplome.forEach((diplome: any) => {
        this.formData.append('diplomes[]', diplome.name);
      });
    }
  }

  appendCertificat(formValue: any) {
    this.formData.delete('certificats[]')
    const certificat = formValue.certificat;
    if (certificat) {
      certificat.forEach((certificat: any) => {
        this.formData.append('certificats[]', certificat.name);
      });
    }
  }
  /* append data */
  appendForm(formValue: any) {
    console.log('formValue', formValue);
    if (formValue.phone_number === null) {
      formValue.phone_number = '';
    }
    // Vérifier si le champ CV est rempli
    if (formValue.cv) {
      this.formData.append('cv', this.file);
    }

    // Vérifier si le champ LinkedIn est rempli
    if (formValue.linkedin_link) {
      this.formData.append('linkedin_link', formValue.linkedin_link);
    }

    // Ajouter les autres champs de formulaire
    this.appendCertificat(formValue);
    this.appendDiplome(formValue);
    this.appendDesired_contract(formValue);
    this.appendMobility(formValue);
    this.appendSkills(formValue);
    this.appendDestinationCountry(formValue);
    this.formData.append('first_name', formValue.first_name);
    this.formData.append('last_name', formValue.last_name);
    this.formData.append('email', formValue.email);
    this.formData.append('phone_number', formValue.phone_number);
    if (formValue.availability)
      this.formData.append('availability', formValue.availability);
    if (formValue.month_exp)
      this.formData.append('month_exp', formValue.month_exp);
    if (formValue.year_exp)
      this.formData.append('year_exp', formValue.year_exp);
    if (formValue.date_first_experience)
      this.formData.append(
        'date_first_experience',
        formValue.date_first_experience
      );
    if (formValue.current_country)
      this.formData.append('current_country', formValue.current_country);
    if (formValue.tjm) this.formData.append('tjm', formValue.tjm);
    if (formValue.desired_salary)
      this.formData.append('desired_salary', formValue.desired_salary);
    if (formValue.desired_workstation)
      this.formData.append(
        'desired_workstation',
        formValue.desired_workstation
      );
    this.formData.append('open_for_mission', formValue.open_for_mission);
  }

  addCandidat() {
    this.setValidatorRequirements();
    console.log("add form candidat", this.addCandidateFormGroup.value)
    this.addCandidateFormGroup.markAllAsTouched();
    this.addCandidateFormGroup.markAsDirty();


    if (this.experience) {
      this.addCandidateFormGroup.value.date_first_experience = null;
    } else {
      // Vérifie si une date est sélectionnée avant de la formater
      const dateFirstExperience =
        this.addCandidateFormGroup.value.date_first_experience;
      if (dateFirstExperience && dateFirstExperience !== '') {
        this.addCandidateFormGroup.value.date_first_experience =
          moment(dateFirstExperience).format('YYYY-MM-DD');
      } else {
        this.addCandidateFormGroup.value.date_first_experience = null;
      }
    }
    if (this.addCandidateFormGroup.value.phone_number === null) {
      this.addCandidateFormGroup.value.phone_number = '';
    }
    if (this.addCandidateFormGroup.valid) {
      this.showGlobalSpinner();
      this.appendForm(this.addCandidateFormGroup.value);
      this.candidatService
        .addCandidat(this.formData)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.getListCandidats();
              this.hideGlobalSpinner();
              this.closeModal();
              this.toastr.success(createdCandidate);
              this.addCandidateFormGroup.reset();
              this.experience = false;
              this.addCandidateFormGroup.reset()
            }
          },
          error: (err) => {
            this.hideGlobalSpinner();
            console.log('error add condidat : ', err);
            if (err?.error) {
              if (err.error?.message['email']) this.toastr.error(emailExist);
              else if (err.error?.message['phone_number'][0] == "The phone number has already been taken.")
                this.toastr.error(phoneNumberExist);
              else if (err.error?.message['phone_number'][0] == "Le numéro du téléphone n'est pas valide( exemple :33 1 23 45 67 89).")
                this.toastr.error(phoneNumberInvalid);
              else if (err.error?.message['linkedin_link'])
                this.toastr.error(linkdinExist);
              else if (
                err.error?.message['cv'][0] ==
                'The cv must be a file of type: pdf, doc, docx.'
              )
                this.toastr.error(iscvvalide);
              else if (
                err.error?.message['cv'][0] ==
                'The cv must be a file of type: pdf, doc, docx.'
              )
                this.toastr.error(iscvvalide);
              else this.toastr.error(serverError);
            }
          },
        });
    }
  }

  /* calculate experience */
  show_experience: boolean = false;
  show_error: boolean = false;
  experienceText!: string;
  calculateExperience() {
    // Current date
    let currentDate: any = new Date();

    // Start date
    let startDateObj: any = new Date(
      this.addCandidateFormGroup.value.date_first_experience
    );
    // Calculate the difference in milliseconds
    let timeDifference = currentDate - startDateObj;
    if (timeDifference > 0) this.show_experience = true;
    else this.show_experience = false;

    // Convert the difference to years and months
    let years = Math.floor(timeDifference / (365.25 * 24 * 60 * 60 * 1000));
    let months = Math.floor(
      (timeDifference % (365.25 * 24 * 60 * 60 * 1000)) /
      (30.44 * 24 * 60 * 60 * 1000)
    );
    this.experienceText =
      years > 0 && months > 0
        ? `${years} ans et ${months} mois`
        : years == 0
          ? `${months} mois`
          : months == 0
            ? `${years} ans`
            : '';
  }
  isChosenDateValid!: boolean;

  /* add new input response for diplome */
  addDiplome() {
    this.diplome.push(this.createFormDiplome());
  }
  /* remove input response for diplome */
  removeDiplome(index: number) {
    this.diplome.removeAt(index);
  }
  /* add new input response for certifications */
  addCertification() {
    this.certificat.push(this.createFormCertificate());
  }
  /* remove input response for certification */
  removeCertification(index: number) {
    this.certificat.removeAt(index);
  }
  /* get the list of candidats */
  getListCandidats() {
    this.spinner.show();
    // this.loadSpinner = true;
    let dataPyload =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };
    this.candidatService
      .getListCandidat(dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (response) => {
          this.isLoadSpinner = false;
          if (response.data.users.data.length) {
            // this.listCandidats = response.data.users.data.slice(0,this.endIndex);
            this.listCandidats = response.data.users.data

            console.log("listCandidateGetList", this.listCandidats);

            this.handleCheck();
          }
          this.totalItems = response.data.users.total;
          this.spinner.hide();
          // this.loadSpinner = false;
        },
        error: () => {
          this.spinner.hide();
          this.isRecherche = false;
          // this.loadSpinner = false;
        },
      });
  }
  cleanDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    return dateString.replace('admin', '').trim();
  }


  hasMoreThanThreeContracts(contracts: any | null): boolean {
    if (!contracts) {
      return false;
    }
    const validContracts = contracts.filter((contract: any) => contract !== null && contract !== undefined);
    return validContracts.length > 3;
  }
  getFirstThreeContracts(contracts: any | null) {
    if (Array.isArray(contracts)) {
      return contracts.slice(0, 3).join(', ');
    }
    return '---';
  }
  /* handle check candidates from selected list  after pagination */
  handleCheck() {
    for (const iterator of this.selectedCandidats) {
      for (const candidat of this.listCandidats) {
        if (iterator.id == candidat.id) {
          candidat.checked = iterator.checked;
        }
      }
    }
  }

  rowClick(candidat: ICandidat, index: number) {
    this.listCandidats[index].checked = !this.listCandidats[index].checked;

    // Check if all checkboxes are checked
    this.headerChecked = this.listCandidats.every((c) => c.checked);

    if (this.listCandidats[index].checked) {
      this.selectedCandidats.push(candidat);
    } else {
      const selectedIndex = this.selectedCandidats.findIndex(
        (item: any) => item === this.listCandidats[index]
      );

      if (selectedIndex !== -1) {
        this.selectedCandidats.splice(selectedIndex, 1);
      }
    }
  }
  toggleRowSelection(candidat: ICandidat): void {
    candidat.checked = !candidat.checked;

    // Mettre à jour la sélection globale en fonction des cases individuelles
    this.headerChecked = this.listCandidats.every((c) => c.checked);

    // Mettre à jour la liste des candidats sélectionnés
    if (candidat.checked) {
      if (
        !this.selectedCandidats.some((el: ICandidat) => el.id === candidat.id)
      ) {
        this.selectedCandidats.push({ ...candidat, checked: true });
      }
    } else {
      this.selectedCandidats = this.selectedCandidats.filter(
        (el: ICandidat) => el.id !== candidat.id
      );
    }
  }
  changeSelectCandidats(event: any, type: string, candidat?: ICandidat) {
    if (type === 'allCandidats') {
      this.fromSelection = event.target.checked ? true : false;
      this.headerChecked = event.target.checked;

      // Bascule la sélection/désélection de toutes les lignes
      this.listCandidats.forEach((candidat: ICandidat) => {
        candidat.checked = this.headerChecked;

        // Mettre à jour la liste des candidats sélectionnés
        if (this.headerChecked) {
          if (
            !this.selectedCandidats.some(
              (el: ICandidat) => el.id === candidat.id
            )
          ) {
            this.selectedCandidats.push({ ...candidat, checked: true });
          }
        } else {
          this.selectedCandidats = this.selectedCandidats.filter(
            (el: ICandidat) => el.id !== candidat.id
          );
        }
      });
    } else {
      if (candidat) {
        if (event.target.checked) {
          // Check if all individual checkboxes are checked
          this.headerChecked = this.listCandidats.every((c) => c.checked);

          // Vérifier si le candidat n'est pas déjà présent dans le tableau
          if (
            !this.selectedCandidats.some(
              (el: ICandidat) => el.id === candidat.id
            )
          ) {
            this.selectedCandidats.push({ ...candidat, checked: true });
            candidat.checked = true;
          }
        } else {
          // Filtrer les candidats qui ne correspondent pas à l'ID du candidat actuel
          this.selectedCandidats = this.selectedCandidats.filter(
            (el: ICandidat) => el.id !== candidat.id
          );
          candidat.checked = false;

          // Uncheck the header checkbox
          this.headerChecked = false;
        }
      } else {
        // Si le type n'est pas 'allCandidats' et aucun candidat n'est spécifié, cela signifie que
        // l'action provient du bouton "Décocher tout"
        this.listCandidats.forEach((el: ICandidat) => {
          el.checked = false;
        });

        // Mettre à jour le tableau des candidats sélectionnés
        this.selectedCandidats = [];

        // Mettre à jour la propriété headerChecked
        this.headerChecked = false;
      }
    }
  }

  dataCandidat(type: string, from: string, candidat?: ICandidat) {
    let data: any;
    
    if (from === 'button') {
      if (this.selectedCandidats.length) {
        data = type === 'test' 
          ? { user_ids: this.selectedCandidats.map((el: { id: number }) => el.id) }
          : { candidate_ids: this.selectedCandidats.map((el: { id: number }) => el.id) };
  
        type === 'test' ? this.sendRetestingMail(data) : this.reValidate(data);
      } else {
        this.toastr.info(obligationSelect);
      }
    } else if (candidat) {
      data = type === 'test' 
        ? { user_ids: [candidat.id] }
        : { candidate_ids: [candidat.id] };
  
      type === 'test' ? this.sendRetestingMail(data) : this.reValidate(data);
    }
  
    // Consolidated logic to avoid repetition
    if (this.selectedCandidats.length === 0 && candidat) {
      this.headerChecked = false;
    }
  }
  

  sendRetestingMail(data: { user: number[] | undefined; link_test?: string }) {
    this.showGlobalSpinner();
    this.candidatService
      .reTest(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (response) => {
          if (response.status == 200) {
            this.hideGlobalSpinner();
            this.toastr.success(emailSended);

            // Attendre 2 secondes avant de recharger la page
            setTimeout(() => {
              this.selectedCandidats.map((el: ICandidat) => {
                el.checked = false;
              });
              this.selectedCandidats = [];
              this.headerChecked = false;
              this.searchCandidate();
            }, 2000); // Délai en millisecondes (2 secondes dans cet exemple)
          }
        },
        error: () => {
          this.hideGlobalSpinner();
        },
      });
  }

  /* send email to candidat to revalid */
  // reValidate(data: any) {
  //   delete data.link_test
  //   this.showGlobalSpinner()
  //   this.candidatService.reValid(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
  //     next: (response) => {
  //       if (response.status == 200) {
  //         this.hideGlobalSpinner()
  //         this.toastr.success(emailSended)
  //         this.selectedCandidats.map((el: ICandidat) => {
  //           el.checked = false
  //         })
  //         this.selectedCandidats = []
  //         this.headerChecked = false
  //       }
  //     },
  //     error: () => {
  //       this.hideGlobalSpinner()
  //     }
  //   })
  // }

  // reValidate(data: any) {
  //   delete data.link_test;
  //   this.showGlobalSpinner();

  //   this.candidatService.reValid(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
  //     next: (response) => {
  //       if (response.status == 200) {
  //         this.hideGlobalSpinner();
  //         this.toastr.success(emailSended);
  //         this.selectedCandidats.map((el: ICandidat) => {
  //           el.checked = false;
  //         });
  //         this.selectedCandidats = [];
  //         this.headerChecked = false;

  //       }
  //     },
  //     error: (error) => {
  //       this.hideGlobalSpinner();

  //       if (error.status === 400 && error.error.message === 'mail already validated') {
  //         this.toastr.info('Mail déjà validé');
  //       }
  //     }
  //   });
  // }
  reValidate(data: any) {
    this.showGlobalSpinner();

    this.candidatService
      .reValid(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (response) => {
          if (response.status == 200) {
            console.log('response', response);
            this.toastr.success(emailSended);
            this.hideGlobalSpinner();
            // Attendre 2 secondes avant de recharger la page
            setTimeout(() => {
              this.selectedCandidats.map((el: ICandidat) => {
                el.checked = false;
              });
              this.selectedCandidats = [];
              this.headerChecked = false;

              this.searchCandidate();
            }, 1000); // Délai en millisecondes (2 secondes dans cet exemple)
          }
        },
        error: (error) => {
        

          if (
            error.status === 400 &&
            error.error.message === 'mail already validated'
           
          ) {
             
            
            this.toastr.info('Mail déjà validé');
            this.hideGlobalSpinner();
            setTimeout(() => {
              this.selectedCandidats.map((el: ICandidat) => {
                el.checked = false;
              });
              this.selectedCandidats = [];
              this.headerChecked = false;

              this.searchCandidate();
            }, 2000); // Délai en millisecondes (2 secondes dans cet exemple)
          }
            
          
        },
      });
  }

  /* get candidats selected */
  // changeSelectCandidats(event: any, type: string, candidat?: ICandidat) {
  //   if (type === 'allCandidats') {
  //     this.fromSelection = event.target.checked ? true : false;
  //     this.headerChecked = event.target.checked;
  //     this.selectedCandidats = event.target.checked
  //       ? [...this.listCandidats]
  //       : [];
  //     this.selectedCandidats.forEach((el: ICandidat) => {
  //       el.checked = event.target.checked;
  //     });
  //   } else {
  //     if (candidat) {
  //       if (event.target.checked) {
  //         this.selectedCandidats.push(candidat);
  //         candidat.checked = true; // Mettez à jour la propriété checked pour le candidat spécifique
  //       } else {
  //         let indexId = this.selectedCandidats.findIndex(
  //           (el: { id: number }) => {
  //             return el.id == candidat.id;
  //           }
  //         );
  //         if (indexId !== -1) {
  //           this.selectedCandidats.splice(indexId, 1);
  //           candidat.checked = false; // Mettez à jour la propriété checked pour le candidat spécifique
  //         }
  //       }
  //     }
  //   }
  // }

  /* download one file */
  downloadFiles(type: string, fileName?: any, typeFile?: string) {
    console.log('type', type);
    if (type == 'single' && fileName) {
      if (fileName.cv || fileName.file_test) {
        const fileUrl = !typeFile
          ? `${this.url}/User/file/gallery_cvs/${fileName.cv}`
          : `${this.url}/ResponseCandidate/GetFile/${fileName?.file_test}`;
        this.showGlobalSpinner();
        this.candidatService.downloadFile(fileUrl).subscribe(
          (data) => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = !typeFile
              ? `${fileName.ID_user}_${fileName.first_name}_${fileName.last_name
              }_CV.${fileName!.cv.split('.').pop()}`
              : `${fileName.ID_user}_${fileName.first_name}_${fileName.last_name
              }_QCM.${fileName!.file_test}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            this.hideGlobalSpinner();
          },
          () => {
            this.hideGlobalSpinner();
          }
        );
      } else {
        this.toastr.info(EmptyCV);
      }
    } else if (this.selectedCandidats.length) {
      console.log('selectedcandidat', this.selectedCandidats);
      console.log('typefile', typeFile);

      if (!typeFile) {
        let allCvNull = true;

        // Vérifie chaque candidat pour voir s'il a un CV valide
        for (let i = 0; i < this.selectedCandidats.length; i++) {
          const candidat = this.selectedCandidats[i];
          console.log('candidat', candidat);
          if (candidat.cv && candidat.cv !== 'null') {
            allCvNull = false; // S'il y a un CV valide, marquez allCvNull comme false et sortez de la boucle
            break;
          }
        }
        console.log('allCvNull', allCvNull);
        if (allCvNull) {
          this.toastr.info(noCvSelected);
        } else {
          this.downloadPdfFiles(typeFile);
        }
      } else {
        let datafileTest = this.selectedCandidats.map((el: any) => {
          return !el.file_test;
        });
        if (!datafileTest.includes(false)) this.toastr.info(noTestFileSelected);
        else if (
          this.selectedCandidats.length == 1 &&
          !this.selectedCandidats[0].file_test
        )
          this.toastr.info(emptyFileTest);
        else this.downloadPdfFiles(typeFile);
      }
    } else {
      this.toastr.info(obligationSelect);
    }
  }
  /* download files in zip folder */
  downloadPdfFiles(typeFile?: string) {
    const zip = new JSZip();
    const fileUrls = this.selectedCandidats.map(
      (el: {
        cv: string;
        file_test: string;
        first_name: string;
        last_name: string;
        ID_user: string;
      }) => {
        if (!typeFile) {
          return {
            url: `${this.url}/User/file/gallery_cvs/${el.cv}`,
            first_name: el.first_name,
            last_name: el.last_name,
            ID_user: el.ID_user,
            cv: el.cv,
          };
        } else {

          return {
            url: `${this.url}/ResponseCandidate/GetFile/${el?.file_test}`,
            first_name: el.first_name,
            last_name: el.last_name,
            ID_user: el.ID_user,
            response: el?.file_test
          };
        }
      }
    );
    // this.showGlobalSpinner();
    const downloadPromises = fileUrls.map((pdfUrl: any) => {
      return this.candidatService
        .getPdfContent(pdfUrl.url)
        .toPromise()
        .then(
          (pdfContent: any) => {
            this.hideGlobalSpinner();
            zip.file(
              !typeFile
                ? `${pdfUrl.ID_user}_${pdfUrl.first_name}_${pdfUrl.last_name
                }_CV.${pdfUrl.cv.split('.').pop()}`
                : `${pdfUrl.ID_user}_${pdfUrl.first_name}_${pdfUrl.last_name
                }_QCM.${pdfUrl.response.split('.').pop()}`,
              pdfContent
            );
          },
          () => {
            this.hideGlobalSpinner();
          }
        );
    });

    Promise.all(downloadPromises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        if (!typeFile) {
          saveAs(content, 'RH_Profiles_CVs.zip');
          this.selectedCandidats = [];
          this.headerChecked = false;
          this.searchCandidate();
        } else {
          saveAs(content, 'Réponse_qcm.zip');
          this.selectedCandidats = [];
          this.headerChecked = false;
          this.searchCandidate();
        }
      });
    });
    this.selectedCandidats.map((el: ICandidat) => {
      el.checked = false;
    });
    this.headerChecked = false;
    this.selectedCandidats = [];
  }
  navigateToProfilCandidat(idCandidat: number) {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  dateFormat(value: any) {
    const [day, month, year] = value?.split('/');
    return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }
  /* ************************************ Search ***************************** */
  /* set data to search */
  dataSearch() {
    let valueForm = this.searchFormGroup.value;
    valueForm.desired_salary_range = this.convertKToNumber(valueForm.desired_salary_range?.toString());
    let date_last = this.datePipe.transform(valueForm.date_last_update, 'yyyy-MM-dd');

    return {
      ID_user: valueForm.ID_user,
      date_last_update: date_last,
      status: valueForm.status ? valueForm.status : '',
      score: valueForm.score,
      name: valueForm?.first_name,
      skillsOr: valueForm.skillsOr,
      skillsAnd: valueForm.skillsAnd,
      availability: valueForm.availability,
      mobility: valueForm.mobility,
      desired_contract: valueForm.desired_contract,
      min_experience_years: valueForm.min_experience_years,
      max_experience_years: valueForm.max_experience_years,
      tjm: valueForm.tjm,
      tjm_range: valueForm.tjm_range,
      desired_salary: valueForm.desired_salary,
      desired_salary_range: valueForm.desired_salary_range,
      current_country: valueForm.current_country
        ? valueForm.current_country
        : '',
      destination_country: valueForm.destination_country
        ? valueForm.destination_country
        : '',
      desired_workstation: valueForm.desired_workstation
        ? valueForm.desired_workstation
        : '',
      page: this.currentPage,
      per_page: this.itemsPerPage,
      search: this.transformSearch(valueForm?.search),
    };
  }
  /* search */
  // transformSearch(search: string): string {
  //   search = search.replace(/\|\|/g, 'OR');
  //   search = search.replace(/&&/g, 'AND');
  //   search = search.replace(/!/g, 'NOT');
  //   search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
  //     if (p1) {

  //       return `"${p1}"`;

  //     } else {

  //       const escapedTerm = p2.replace(/"/g,'\\"');

  //       return `${escapedTerm}`;
  //     }
  //   });
  //   return search;
  // }
  transformSearch(search: string): string {
    // Remplace toutes les occurrences de '||' par 'OR'
    search = search.replace(/\|\|/g, ' OR ');

    // Remplace toutes les occurrences de '&&' par ' AND '
    search = search.replace(/&&/g, ' AND ');

    // Remplace toutes les occurrences de '!' par ' NOT '
    search = search.replace(/!/g, ' NOT ');

    // Ajoute des espaces autour des opérations logiques pour éviter des conflits avec les mots
    search = search.replace(/\b(AND|OR|NOT)\b/g, ' $1 ');

    // Remplace les termes entre guillemets doubles par les mêmes termes sans modification
    // et échappe les guillemets simples à l'intérieur des termes non compris entre guillemets
    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        return `"${p1}"`;
      } else {
        // Si le terme n'est pas entre guillemets, échappe les guillemets simples à l'intérieur du terme
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `${escapedTerm}`;
      }
    });

    // Supprime les espaces en double pour une meilleure lisibilité
    search = search.replace(/\s+/g, ' ');

    // Renvoie la chaîne de recherche transformée
    return search.trim();
  }

  isSimpleWord(value: string): boolean {
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }

  /* search */
  searchCandidate() {
    let dataPayload = this.dataSearch();
    this.spinner.show();
    // this.loadSpinner = true;
    this.candidatService
      .searchCandidat(dataPayload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (resSearch) => {
          console.log('resSearch of : ', resSearch);
          this.isRecherche = true;
          if (resSearch.status == 200) {

            if (!resSearch.data.data.length && this.currentPage > 1) {
              this.currentPage = 1;
              this.searchCandidate();
            } else {
              this.spinner.hide();
              // this.loadSpinner = false;
              this.listCandidats = resSearch.data.data.slice(0, this.endIndex);
              console.log("listCandidateSearch", this.listCandidats);

              this.totalItems = resSearch.data.total;
              // this.candidatService.setSearchResults(this.listCandidats);

            }
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.isRecherche = false;
          // this.loadSpinner = false;
        },
      });
  }

  selectedCandidates: any = [];

  // rowClick(candidat: ICandidat, index: number) {
  //   this.listCandidats[index].checked = !this.listCandidats[index].checked;
  //   if (this.listCandidats[index].checked) {
  //     this.selectedCandidats.push(candidat);
  //   } else {
  //     const selectedIndex = this.selectedCandidats.findIndex(
  //       (item: any) => item === this.listCandidats[index]
  //     );
  //     if (selectedIndex !== -1) {
  //       this.selectedCandidats.splice(selectedIndex, 0);
  //     }
  //   }
  // }
  fileNameexcel = 'excel.xlsx';
  allCandidates: any[] = [];

  toggleCandidateSelection(candidateID: string): void {
    const index = this.selectedCandidates.indexOf(candidateID);
    if (index === -1) {
      this.selectedCandidates.push(candidateID);
    } else {
      this.selectedCandidates.splice(index, 1);
    }
  }
  showGlobalSpinner() {
    this.spinner.show();
  }
  hideGlobalSpinner() {
    this.spinner.hide();
  }
  isRecherche: boolean = false;
  reset() {
    this.spinner.hide();
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.spinner.hide();
    this.typeDate = 'text';
    this.searchFormGroup = this.createSearchForm();

    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();

      this.getListCandidats();
    }
    return (this.isRecherche = false);
  }
  searchCandidatereset() {
    this.spinner.show();
    let dataPayload = this.dataSearch();

    // this.loadSpinner = true;
    this.candidatService
      .searchCandidat(dataPayload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (resSearch) => {
          console.log('resSearch of : ', resSearch);
          if (resSearch.status == 200) {
            if (!resSearch.data.data.length && this.currentPage > 1) {
              this.currentPage = 1;
              this.searchCandidate();
              this.spinner.hide();
            } else {
              this.spinner.hide();
              // this.loadSpinner = false;
              this.listCandidats = resSearch.data.data.slice(0, this.endIndex);
              console.log('listCandidateSearch', this.listCandidats);

              this.totalItems = resSearch.data.total;
              // this.candidatService.setSearchResults(this.listCandidats);
            }
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          // this.loadSpinner = false;
        },
      });
  }
  getListCandidatsreset() {
    this.spinner.hide();

    let dataPyload =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };
    this.candidatService
      .getListCandidat(dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response.data.users.data.length) {
            this.spinner.hide();

            this.listCandidats = response.data.users.data
            // .slice(
            //   0,
            //   this.endIndex
            // );
            console.log('listCandidateGetList', this.listCandidats);

            this.handleCheck();
          }
          this.spinner.hide();
          this.totalItems = response.data.users.total;
        },
        error: () => {
          this.spinner.hide();
        },
      });
    this.spinner.hide();
  }
  changeSelection(event: any, data: any, i: number) {
    this.listNames = [];
    data.checkAdmin = event.target.checked;
    for (const iterator of this.dataHeader) {
      if (iterator.checkAdmin) this.listNames.push(iterator.name);
    }
    this.changeSelectionHeadrElements(data);
  }
  changeSelectionHeadrElements(data: any) {
    let payload = {
      sub_page_id: data?.id,
    };
    this.candidatService
      .changeDelectedElementHeader(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: () => { },
        error: () => { },
      });
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable.removeEventListener('scroll', this.closeDropdownOnScroll.bind(this));

  }
}
