import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { CJobOffer } from '../../../../core/models/job-offer';
import { TenderService } from '../../../../core/services/tender/tender.service';
import * as moment from 'moment';
import { serverError } from '../../../../core/models/messages';
import { Router } from '@angular/router';
import { ISort, listContracts } from '../../../../core/models/candidat';
import { SortDataService } from '../../../../core/services/sort-data/sort-data.service';
import { Country, ICountry, State } from 'country-state-city';
import { CandidatService } from '../../../../core/services/candidat/candidat.service';
import { environment } from '../../../../../environments/environment';
import Swal from 'sweetalert2';
import { PaysService } from '../../../../shared/services/pays.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-list-appel',
  templateUrl: './list-appel.component.html',
  styleUrls: ['./list-appel.component.css'],
})
export class ListAppelComponent {
  sortAttr: string = '';
  url: string = environment.baseUrl + '/api';
  /* number */
  seeMore: boolean = false;
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 1;
  pagination: boolean = false;

  search: boolean = false;
  mobilities: any[] = [];
  countries: any[] = [];
  /* arrays */
  listRequestOffer: CJobOffer[] = [];
  skillsList: { name_FR: string }[] = [];
  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 2, name: '1 mois' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];
  postType = [
    { id: 1, name: 'A distance' },
    { id: 2, name: 'Hybride' },
    { id: 3, name: 'Sur site' },
  ]
  StateOffre = [
    { id: 0, name: 'Activé' },
    { id: 1, name: 'Désactivé' },
  ]
  dataHeaderSort = [
    { name: 'ID', code: 'ID_jobRequest' },
    { name: 'Nom entreprise', code: 'client_name' },
    { name: 'Poste', code: 'name' },
    { name: 'Date de publication', code: 'start_date' },
    { name: 'Années d’expériences', code: 'years_of_experience' },
    { name: 'Type de contrat', code: 'contract_type' },
    { name: 'Salaire', code: 'salary' },
    { name: 'TJM', code: 'tjm' },
    { name: 'Type de poste', code: 'post_type' },
    { name: 'Compétences', code: 'skills_job' },
    { name: 'Pays', code: 'country' },
    { name: 'Ville', code: 'city' },
    { name: 'Statut', code: 'state' },
  ]
  typePoste = [
    { id: 1, name: 'A distance' },
    { id: 2, name: 'Hybride' },
    { id: 3, name: 'Sur site' },
  ];
  profile = [
    { id: 1, name: 'Oui' },
    { id: 0, name: 'Non' },
  ];
  passeport = [
    { id: 1, name: 'Oui' },
    { id: 0, name: 'Non' },
  ];
  today = new Date();
  contratRequest = listContracts
  contratTypeOptions: { [key: string]: string } = {
    '1': 'Alternance',
    '2': 'Autres type de contrat',
    '3': 'CDD',
    '4': 'CDI',
    '5': 'Freelance',
    '6': 'Portage salarial',
    '7': 'Stage',
  };

  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      Afghanistan: 'Afghanistan',
      'South Africa': 'Afrique du Sud',
      Albania: 'Albanie',
      Algeria: 'Algérie',
      Germany: 'Allemagne',
      Andorra: 'Andorre',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctique',
      'Antigua and Barbuda': 'Antigua-et-Barbuda',
      'Saudi Arabia': 'Arabie saoudite',
      Argentina: 'Argentine',
      Armenia: 'Arménie',
      Aruba: 'Aruba',
      Australia: 'Australie',
      Austria: 'Autriche',
      Azerbaijan: 'Azerbaïdjan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahreïn',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbade',
      Belgium: 'Belgique',
      Belize: 'Belize',
      Benin: 'Bénin',
      Bermuda: 'Bermudes',
      Bhutan: 'Bhoutan',
      Belarus: 'Biélorussie',
      Bolivia: 'Bolivie',
      'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
      Botswana: 'Botswana',
      Brazil: 'Brésil',
      Brunei: 'Brunei',
      Bulgaria: 'Bulgarie',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      'Cape Verde': 'Cabo Verde',
      Cambodia: 'Cambodge',
      Cameroon: 'Cameroun',
      Canada: 'Canada',
      Chile: 'Chili',
      China: 'Chine',
      Cyprus: 'Chypre',
      Colombia: 'Colombie',
      Comoros: 'Comores',
      'Congo - Brazzaville': 'Congo-Brazzaville',
      'Congo - Kinshasa': 'Congo-Kinshasa',
      'North Korea': 'Corée du Nord',
      'South Korea': 'Corée du Sud',
      'Costa Rica': 'Costa Rica',
      'Ivory Coast': 'Côte d’Ivoire',
      Croatia: 'Croatie',
      Cuba: 'Cuba',
      Curaçao: 'Curaçao',
      Denmark: 'Danemark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominique',
      'Dominican Republic': 'République dominicaine',
      Ecuador: 'Équateur',
      Egypt: 'Égypte',
      'United Arab Emirates': 'Émirats arabes unis',
      Eritrea: 'Érythrée',
      Spain: 'Espagne',
      Eswatini: 'Eswatini',
      Estonia: 'Estonie',
      'United States': 'États-Unis',
      Ethiopia: 'Éthiopie',
      Fiji: 'Fidji',
      Finland: 'Finlande',
      France: 'France',
      Gabon: 'Gabon',
      Gambia: 'Gambie',
      Georgia: 'Géorgie',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Grèce',
      Grenada: 'Grenade',
      Greenland: 'Groenland',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernesey',
      Guinea: 'Guinée',
      'Guinea-Bissau': 'Guinée-Bissau',
      'Equatorial Guinea': 'Guinée équatoriale',
      Guyana: 'Guyana',
      'French Guiana': 'Guyane française',
      Haiti: 'Haïti',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Hongrie',
      Iceland: 'Islande',
      India: 'Inde',
      Indonesia: 'Indonésie',
      Iraq: 'Irak',
      Iran: 'Iran',
      Ireland: 'Irlande',
      'Isle of Man': 'Île de Man',
      Israel: 'Israël',
      Italy: 'Italie',
      Jamaica: 'Jamaïque',
      Japan: 'Japon',
      Jersey: 'Jersey',
      Jordan: 'Jordanie',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      Kuwait: 'Koweït',
      Kyrgyzstan: 'Kirghizistan',
      Laos: 'Laos',
      Latvia: 'Lettonie',
      Lebanon: 'Liban',
      Lesotho: 'Lesotho',
      Liberia: 'Libéria',
      Libya: 'Libye',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lituanie',
      Luxembourg: 'Luxembourg',
      Macao: 'Macao',
      'North Macedonia': 'Macédoine du Nord',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaisie',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malte',
      'Marshall Islands': 'Îles Marshall',
      Martinique: 'Martinique',
      Mauritania: 'Mauritanie',
      Mauritius: 'Maurice',
      Mayotte: 'Mayotte',
      Mexico: 'Mexique',
      Micronesia: 'Micronésie',
      Moldova: 'Moldavie',
      Monaco: 'Monaco',
      Mongolia: 'Mongolie',
      Montenegro: 'Monténégro',
      Montserrat: 'Montserrat',
      Morocco: 'Maroc',
      Mozambique: 'Mozambique',
      Myanmar: 'Myanmar',
      Namibia: 'Namibie',
      Nauru: 'Nauru',
      Nepal: 'Népal',
      Netherlands: 'Pays-Bas',
      'New Caledonia': 'Nouvelle-Calédonie',
      'New Zealand': 'Nouvelle-Zélande',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Île Norfolk',
      'Northern Mariana Islands': 'Îles Mariannes du Nord',
      Norway: 'Norvège',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palaos',
      Palestine: 'Palestine',
      Panama: 'Panama',
      'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
      Paraguay: 'Paraguay',
      Peru: 'Pérou',
      Philippines: 'Philippines',
      'Pitcairn Islands': 'Îles Pitcairn',
      Poland: 'Pologne',
      Portugal: 'Portugal',
      'Puerto Rico': 'Porto Rico',
      Qatar: 'Qatar',
      Romania: 'Roumanie',
      Russia: 'Russie',
      Rwanda: 'Rwanda',
      Réunion: 'Réunion',
      'Saint Barthélemy': 'Saint-Barthélemy',
      'Saint Helena': 'Sainte-Hélène',
      'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
      'Saint Lucia': 'Sainte-Lucie',
      'Saint Martin': 'Saint-Martin',
      'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
      'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
      Samoa: 'Samoa',
      'San Marino': 'Saint-Marin',
      'Sao Tome and Principe': 'Sao Tomé-et-Principe',
      Senegal: 'Sénégal',
      Serbia: 'Serbie',
      Seychelles: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapour',
      'Sint Maarten': 'Saint-Martin',
      Slovakia: 'Slovaquie',
      Slovenia: 'Slovénie',
      'Solomon Islands': 'Îles Salomon',
      Somalia: 'Somalie',
      'South Georgia and the South Sandwich Islands':
        'Géorgie du Sud-et-les Îles Sandwich du Sud',
      'South Sudan': 'Soudan du Sud',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Soudan',
      Suriname: 'Suriname',
      'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
      Swaziland: 'Eswatini',
      Sweden: 'Suède',
      Switzerland: 'Suisse',
      Syria: 'Syrie',
      Taiwan: 'Taïwan',
      Tajikistan: 'Tadjikistan',
      Tanzania: 'Tanzanie',
      Thailand: 'Thaïlande',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinité-et-Tobago',
      Tunisia: 'Tunisie',
      Turkey: 'Turquie',
      Turkmenistan: 'Turkménistan',
      Tuvalu: 'Tuvalu',
      Uganda: 'Ouganda',
      Ukraine: 'Ukraine',
      'United Kingdom': 'Royaume-Uni',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Ouzbékistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatican',
      Venezuela: 'Venezuela',
      Vietnam: 'Viêt Nam',
      'Western Sahara': 'Sahara occidental',
      Yemen: 'Yémen',
      Zambia: 'Zambie',
      Zimbabwe: 'Zimbabwe',
    };
    return translations[name] || name;
  }
  isLoadSpinner: boolean = true;

  stateList: any[] = [];
  dataHeader: any = [];
  listNames: string[] = [];
  /* object */
  requestOffer!: CJobOffer
  paysList: any[] = Country.getAllCountries().map((country) => ({
    ...country,
    name: this.translateCountryName(country.name),
  }));

  /* formGroup */
  requestJobForm: FormGroup = this.createRequestJobSearchForm(new CJobOffer());
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject()
  currentDate!: any;
  minDate!: string;
  constructor(private datePipe: DatePipe, private jobRequestService: TenderService, private toastrService: ToastrService, private elRef: ElementRef,
    private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private router: Router,
    private sortDataService: SortDataService, private candidatService: CandidatService, private paysServices: PaysService) {
    const day = String(this.today.getDate()).padStart(2, "0");
    const month = String(this.today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = this.today.getFullYear();
    this.currentDate = `${year}-${month}-${day}`;
    // Set the minimum date to 10 years ago, for example
    const minYear = 1990;
    this.minDate = `${minYear}-${month}-${day}`;
  }
  ngOnInit() {
    // this.paysList=this.paysServices.getAllCountries();

    this.getListElementHeader();
    this.getListAppel();
    this.getListSkills();

    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));



  }
  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
      console.log('HEYYYYYYYYYYYY');
    }
  }
  showDatePicker(id: string): void {
    const input = document.getElementById(id);
    if (input) {
      input.setAttribute('type', 'date');
      input.focus(); // refocus to show the date picker
    }
  }


  getListElementHeader() {
    this.candidatService
      .getHeaderElements(5)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.dataHeader = res.data;
          console.log('data header', this.dataHeader);
          for (const iterator of this.dataHeader) {
            if (
              (iterator.checked == 'oui' && !iterator.checked_user) ||
              (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
              (iterator.checked == 'oui' && iterator.checked_user == 'oui')
            )
              this.listNames.push(iterator.name);
          }
          this.dataHeader.map((el: any) => {
            if (
              (el.checked == 'oui' && !el.checked_user) ||
              (el.checked_user == 'oui' &&
                (el.checked == 'non' || el.checked == 'oui'))
            ) {
              return (el.checkAdmin = true);
            } else {
              return (el.checkAdmin = false);
            }
          });
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }
  updateInputType(type: 'text' | 'date', nameControl: string) {
    const inputElement =
      nameControl == 'end_date'
        ? (document.getElementById('dateInputEnd') as HTMLInputElement)
        : (document.getElementById('dateInput') as HTMLInputElement);
    inputElement.type = type;
    if (type == 'text') {
      if (
        this.requestJobForm.value[nameControl] &&
        this.requestJobForm.value[nameControl] != ''
      )
        this.requestJobForm
          .get(nameControl)
          ?.setValue(
            moment(this.requestJobForm.value[nameControl]).format('DD/MM/yyyy')
          );
    }
  }
  changeSelection(event: any, data: any, i: number) {
    this.listNames = [];
    data.checkAdmin = event.target.checked;
    for (const iterator of this.dataHeader) {
      if (iterator.checkAdmin) this.listNames.push(iterator.name);
    }
    this.changeSelectionHeadrElements(data);
  }
  changeSelectionHeadrElements(data: any) {
    let payload = {
      sub_page_id: data?.id,
    };
    this.candidatService
      .changeDelectedElementHeader(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          // this.skillsList = res.data
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }
  getListSkills() {
    this.jobRequestService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.requestJobForm.dirty) {
        if (this.search) this.searchOffer();
      } else if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getListAppel();
      }
    }
  }
  /* sort header */
  sortData(sortAttribute: string) {
    for (const iterator of this.dataHeaderSort) {
      if (sortAttribute == iterator.name) sortAttribute = iterator.code;
    }
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr = sortAttribute;

    this.currentPage = 1;
    this.endIndex = 5;

    this.getListAppel();
  }
  // sortData(sortAttribute:string) {
  //   for (const iterator of this.dataHeaderSort) {
  //     if(sortAttribute==iterator.name) sortAttribute=iterator.code
  //   }
  //   this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
  //   this.sortAttr=sortAttribute
  //   if(this.requestJobForm.dirty) this.listRequestOffer = this.sortDataService.sortArray(this.listRequestOffer, sortAttribute, this.currentSortDirection)
  // else this.getListAppel()
  // }
  /* get list appel d'offre */
  getListAppel(sort?: ISort) {
    this.spinner.show()
    let data = this.sortAttr == '' ? { per_page: this.itemsPerPage, page: this.currentPage } : { ...{ per_page: this.itemsPerPage, page: this.currentPage }, ...{ sort: this.currentSortDirection, sortAttribute: this.sortAttr } }
    this.jobRequestService.getListRequestOffer(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.isLoadSpinner = false;
        this.spinner.hide()
        // this.listRequestOffer = res.data.data.slice(0, this.endIndex)
        this.listRequestOffer = res.data.data
        this.totalItems = res.data.total
      },
      error: () => {
        this.spinner.hide()
      }
    })
  }
  /* create search form */
  createRequestJobSearchForm(data?: any) {
    return this.formBuilder.group({
      ID_jobRequest: ['', data && data.ID_jobRequest],
      name: ['', data && data.name],
      client_final_name: ['', data && data.client_final_name],
      years_of_experience: ['', data && data.years_of_experience],
      experience_range: ['', data && data.experience_range],
      contract_type: ['', data && data.contract_type],
      desired_salary: [data && data.desired_salary],
      desired_salary_range: [
        data && data.desired_salary_range
          ? this.convertKToNumber(data.desired_salary_range)
          : '',
      ],
      tjm: ['', data && data.tjm],
      tjm_range: ['', data && data.tjm_range],
      post_type: ['', data && data.post_type],
      skillsAnd: ['', data && data.skillsAnd],
      skillsOr: ['', data && data.skillsOr],
      availability: ['', data && data.availability],
      country: [null, data && data.country],
      city: ['', data && data.city],
      start_date: ['', data && data.start_date],
      end_date: ['', data && data.end_date],
      state: [null, data && data.state],
      search: [
        '',
        [Validators.pattern(/^("([^"]*"(AND|NOT|,|&&)[^"]*)*"|[^\s"']+)$/)],
      ],
    });
  }

  onSalaryRangeInput(event: any) {
    const value = event.target.value;
    if (value.toLowerCase().endsWith('k')) {
      this.requestJobForm.controls['desired_salary_range'].setValue(
        this.convertKToNumber(value)
      );
    }
  }
  alreadyConverted = false;
  convertKToNumber(value: any) {
    if (typeof value === 'string' && value.toLowerCase().endsWith('k')) {
      // Si la valeur se termine par 'k'
      const numericValue = parseFloat(value.slice(0, -1));
      if (!isNaN(numericValue)) {
        // Convertir en multipliant par 1000
        this.alreadyConverted = true; // Marquer comme déjà convertie
        return numericValue * 1000;
      }
    } else if (!isNaN(value)) {
      // Si la valeur est déjà un nombre, et si elle n'a pas déjà été convertie, convertir en multipliant par 1000
      if (!this.alreadyConverted) {
        this.alreadyConverted = true; // Marquer comme déjà convertie
        return parseFloat(value) * 1000;
      } else {
        return parseFloat(value); // Déjà convertie, retourner sans multiplier à nouveau
      }
    }
  }
  // convertKToNumber(value:any) {
  //   if (typeof value === 'string') {
  //     value = value.trim().toLowerCase();
  //     if (value.endsWith('k')) {
  //       return parseFloat(value.slice(0, -1)) ; // Convertit '10k' en 10000
  //     }
  //   } else if (!isNaN(value)) {
  //     return parseFloat(value)* 1000;
  //   }
  //   return NaN; // Retourne NaN si la valeur n'est pas un nombre valide
  // }

  /* key inter number */
  keyPress(event: any) {
    let pattern = /^[\d]$/
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    return pattern;
  }
  transformSearch(search: string): string {
    // Remplace toutes les occurrences de '||' par 'OR'
    search = search.replace(/\|\|/g, ' OR ');

    // Remplace toutes les occurrences de '&&' par ' AND '
    search = search.replace(/&&/g, ' AND ');

    // Remplace toutes les occurrences de '!' par ' NOT '
    search = search.replace(/!/g, ' NOT ');


    search = search.replace(/\b(AND|OR|NOT)\b/g, ' $1 ');


    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        return `"${p1}"`;
      } else {
        // Si le terme n'est pas entre guillemets, échappe les guillemets simples à l'intérieur du terme
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `${escapedTerm}`;
      }
    });

    search = search.replace(/\s+/g, ' ');

    return search.trim();
  }

  isSimpleWord(value: string): boolean {
    // Utilisez une expression régulière pour vérifier si la valeur est un mot simple ou deux mots avec espace
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }
  /* filter job offer */
  searchOffer() {
    this.spinner.show();

    const formValue = this.requestJobForm.value;

    formValue.years_of_experience = formValue.years_of_experience !== null ? formValue.years_of_experience.toString() : '';
    formValue.desired_salary = formValue.desired_salary !== null ? formValue.desired_salary.toString() : '';
    // formValue.desired_salary_range = formValue.desired_salary_range !== null 
    // ? (this.convertKToNumber(formValue.desired_salary_range)) ? '' : this.convertKToNumber(formValue.desired_salary_range)?.toString() 
    // : '';
    formValue.desired_salary_range = this.convertKToNumber(formValue.desired_salary_range?.toString());
    formValue.tjm = formValue.tjm ? formValue.tjm.toString() : '';
    formValue.tjm_range = formValue.tjm_range
      ? formValue.tjm_range.toString()
      : '';
    formValue.search = formValue.search ? formValue.search.toString() : '';
    formValue.end_date = formValue.end_date ? formValue.end_date : '';
    formValue.start_date = formValue.start_date ? formValue.start_date : '';



    let payload = { ...formValue, page: this.currentPage, per_page: this.itemsPerPage };

    this.jobRequestService.searchRequestOffer(payload).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res: any) => {
        this.isRecherche = true;
        this.spinner.hide();
        // this.listRequestOffer = res.data.data.slice(0, this.endIndex);
        // this.totalItems = res.data.total;
        if (!res.data.data.length && this.currentPage > 1) {
          this.currentPage = 1;
          this.searchOffer();
          this.spinner.hide();
        } else {
          this.listRequestOffer = res.data.data.slice(0, this.endIndex);
          this.totalItems = res.data.total;
        }
      },
      error: () => {
        this.spinner.hide();

      }
    });
  }

  dateFormat(value: any) {
    const [day, month, year] = value.split('/');
    return moment(`${year}-${month}-${day}`).format('YYYY-MM-DD');
  }
  isRecherche: boolean = false;
  reset() {
    this.spinner.hide();
    this.requestJobForm.reset();
    this.requestJobForm = this.createRequestJobSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
      console.log('gggg');
      console.log('gggg', this.isRecherche);
    } else {
      this.spinner.hide();
      this.getListAppel();
      console.log('hhhh', this.isRecherche);
      console.log('hhh');
    }
    return (this.isRecherche = false);
  }
  getListAppelreset(sort?: ISort) {
    let data =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };
    this.jobRequestService
      .getListRequestOffer(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listRequestOffer = res.data.data.slice(0, this.endIndex);
          this.totalItems = res.data.total;
        },
        error: () => {
          this.isRecherche = false;
        },
      });
  }
  goToDetails(id: string | undefined) {
    this.router.navigate([
      '/acceuil/appel-offre/details-appel-offre',
      { id: id },
    ]);
  }
  goToListCandidature(id: string | undefined) {
    this.router.navigate(['/acceuil/offre-emploi/candidatures', { id: id }]);
  }
  /* change country select and delete the string Governorate from state name*/
  onCountryChange(mode: string): void {

    this.requestJobForm.get('city')?.enable();
    this.countries = this.requestJobForm.get('country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.requestJobForm.get('city')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode)
      if (after.length == 0)
        after.push({ name: "Tout le pays" })
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter((item) => !differentItemsArray1.includes(item));
      this.requestJobForm.get('city')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.requestJobForm.get('city')?.disable();
        this.requestJobForm.get('city')?.setValue(null);
      }

    }
  }

  onCountryChangeSearch(mode: string): void {

    this.requestJobForm.get('city')?.enable();
    this.countries = this.requestJobForm.get('country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.requestJobForm.get('city')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode)
      if (after.length == 0)
        after.push({ name: "Tout le pays" })
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter((item) => !differentItemsArray1.includes(item));
      this.requestJobForm.get('city')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.requestJobForm.get('city')?.disable();
        this.requestJobForm.get('city')?.setValue(null);
      }

    }
  }
  onMobilityChange(mode: string) {
    this.mobilities = this.requestJobForm.get('city')?.value;
    let paysLists: any[] = [];
    this.countries = this.requestJobForm.get('country')?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach(item => {
        stateListAfter.push(item.name);    ////  }
      })
    });
    console.log('stateList in change mobilities mobilities : ', this.mobilities);
    console.log('stateList in change mobilities stateListAfter  : ', stateListAfter);
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    console.log('stateList in change mobilities : ', this.stateList);
    this.mobilities = this.requestJobForm.get('city')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable.removeEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }

  activerjobrequest(id: any) {
    Swal.fire({
      title: 'Voulez-vous vraiment activer cette appel d’offre ?',
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, activé !',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.jobRequestService.activedesactiveJobrequest(id).subscribe({
          next: (response: any) => {
            console.log('response', response);

            console.log('response.data', response.data);
            this.toastrService.success(
              'L’appel d’offre a été activé avec succès .'
            );
            this.searchOffer();
          },
          error: (error: any) => {
            console.error('Error fetching  job request: ', error);
          },
        });
      }
    });
  }

  desactiverjobrequest(id: any) {
    Swal.fire({
      title: 'Voulez-vous vraiment désactiver cette appel d’offre ?',
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, désactivé !',
      cancelButtonText: 'Annuler ',
    }).then((result) => {
      if (result.isConfirmed) {
        this.jobRequestService.activedesactiveJobrequest(id).subscribe({
          next: (response: any) => {
            console.log('response', response);

            console.log('response.data', response.data);
            this.toastrService.success(
              'L’appel d’offre a été désactivé avec succès.'
            );
            this.searchOffer();
          },
          error: (error: any) => {
            console.error('Error fetching  job request: ', error);
          },
        });
      }
    });
  }
}
