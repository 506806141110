<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div class="app-main flex-column flex-row-fluid" id="kt_app_main">
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">

        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <div class="d-flex align-items-center">
                        <h1 class="page-heading"> <svg width="32" height="29" viewBox="0 0 32 29" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_82_1744)">
                                    <path
                                        d="M21.795 7.11531C19.8257 7.11531 18.2199 5.51738 18.2199 3.55766C18.2199 1.59793 19.8257 0 21.795 0C23.7643 0 25.3701 1.59793 25.3701 3.55766C25.3701 5.51738 23.7643 7.11531 21.795 7.11531ZM16.3112 4.58274C16.3112 2.05017 14.2509 0.0301492 11.7363 0.0301492C9.19127 0.0301492 7.16135 2.08032 7.16135 4.58274C7.16135 7.11531 9.22157 9.13533 11.7363 9.13533C14.2812 9.13533 16.3112 7.08516 16.3112 4.58274ZM27.0667 15.9491C27.5212 15.9491 27.8848 15.5572 27.8242 15.105C27.3394 11.6076 25.8245 8.1404 21.795 8.1404C19.5227 8.1404 18.0381 9.25593 17.0989 10.8539C17.7654 11.4267 18.3714 12.09 18.8561 12.904C19.4015 13.7784 19.8863 14.8035 20.2498 15.9793H27.0667V15.9491ZM4.01041 19.2053C3.91952 19.8686 4.43457 20.5017 5.13142 20.5017H18.3411C19.0379 20.5017 19.553 19.8987 19.4621 19.2053C18.7956 14.8035 16.8565 10.4619 11.7363 10.4619C6.61599 10.4619 4.67695 14.8035 4.01041 19.2053Z"
                                        fill="#265D91" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_82_1744" x="0" y="0" width="31.8308" height="28.5017"
                                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="4" />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                            result="effect1_dropShadow_82_1744" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_82_1744"
                                            result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                            Candidature
                        </h1>
                        <!--begin::Menu-->
                    </div>
                    <!--end::Title-->
                    <!--begin::Breadcrumb-->
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a routerLink="/acceuil" class="text-muted ">Accueil</a>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted"><a class="text-muted"
                                routerLink="/acceuil/offre-emploi/list-offres">  Offres d'emploi</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li class="breadcrumb-item text-muted"><a class="text-muted"
                            [routerLink]="['/acceuil/offre-emploi/candidatures',idoffer]"> Candidature</a></li>

                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>

                        <li class="breadcrumb-item text-muted">Dossier Candidature</li>
                        <!--end::Item-->
                    </ul>
                    <!--end::Breadcrumb-->
                </div>
                <!--end::Page title-->
                <!--begin::Actions-->

                <!--end::Actions-->
            </div>
            <!--end::Toolbar container-->
        </div>


        <div id="kt_app_content" class="app-content flex-column-fluid mb-4">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container container-fluid">


                <div class="card p-10">
                    <div class="title mb-10">
                        <h4>
                            Dossier de Candidature
                        </h4>
                    </div>

                    <div class="details">
                        <div class="mb-10">
                            <label for="argument" class="mb-3">Lettre de motivation</label>
                            <textarea name="argument" id="argument" placeholder="Lettre de motivation" value="{{candidaturedetails?.letter}}" class="form-control" [readonly]="true"></textarea>
                        </div>
                        <div class="mb-10">
                            <label for="date" class="mb-3">Salaire</label>
                            <input type="number" class="form-control style-input"  value="{{candidaturedetails?.salary}}" placeholder="Salaire" [readonly]="true">
                            </div>
                        <div class="mb-10">
                            <label for="TJM" class="mb-3">TJM</label>
                            <input type="number" class="form-control style-input"  value="{{candidaturedetails?.tjm}}" placeholder="TJM" [readonly]="true">
                        </div>
                    </div>

                    <div class="btns">
                        <a  [routerLink]="['/acceuil/offre-emploi/candidatures',idoffer]" class="retour">Retour</a>
                        <a [routerLink]="['/acceuil/candidats/profil-candidat', { idCandidat: id }]"  class="profil">Profil candidat</a>
                    </div>
                </div>
                </div>
                </div>
        </div>
        </div>