import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './components/pages/pages.component';
import { RolesComponent } from './components/roles/roles.component';
import { DetailRoleComponent } from './components/roles/components/detail-role/detail-role.component';
import { PagesActorsComponent } from './components/pages-actors/pages-actors.component';
import { ListAbonnementComponent } from './components/list-abonnement/list-abonnement.component';
import { EvaluationDefaultComponent } from './components/evaluation/evaluation-default/evaluation-default.component';
import { EvaluationWegestuComponent } from './components/evaluation/evaluation-wegestu/evaluation-wegestu.component';
import { EvaluationSocieteComponent } from './components/evaluation/evaluation-societe/evaluation-societe.component';
import { ListeEvaluationComponent } from './components/evaluation/liste-evaluation/liste-evaluation.component';
import { RateListCompanyComponent } from './components/evaluation/rate-list-company/rate-list-company.component';
import { AddOfferComponent } from './components/subscription/add-subscription/add-subscription.component';
import { ListEvalWegestuComponent } from './components/evaluation/evaluation-default/list-eval-wegestu/list-eval-wegestu.component';
import { AddRoleComponent } from './components/roles/components/add-role/add-role.component';
import { EvaluationDetailsComponent } from './components/evaluation/evaluation-details/evaluation-details/evaluation-details.component';
import { EvaluationComponent } from './components/evaluation/evaluation.component';
import { OffreComponent } from './components/subscription/subscription.component';

  const routes: Routes = [
    {
      path: '',
      redirectTo: 'parametrage-acteur',
      pathMatch: 'full',
    },
    {
      path:'parametre-abonnement',
      component:OffreComponent,
    },
    {
      path:'ajout-abonnement',
      component:AddOfferComponent,
    },
    {
      path:'parametrage-acteur',
      component:PagesActorsComponent,
    },
    {
      path:'parametrage-profil',
      component:PagesComponent,
    },
    {
      path:'parametres-list-role',
      component:RolesComponent,
    },
    {
      path:'parametres-details-role',
      component:DetailRoleComponent,
    },
    {
      path:'abonnements',
      component:ListAbonnementComponent,
    },
    {
      path:'evaluation-default',
      component:EvaluationDefaultComponent
    },
    {
      path:'famille-evaluation',
      component:EvaluationComponent
    },
    {
      path:'evaluation-wegestu',
      component:EvaluationWegestuComponent
    },
   
    {
      path:'avis-societe',
      component:EvaluationSocieteComponent
    },
    {
      path:'avis-wegestu',
      component:ListeEvaluationComponent
    },
    {
      path:'detail-avis-wegestu',
      component:EvaluationDetailsComponent
    },
    {
      path:'list-avis-societe',
      component:RateListCompanyComponent
    },
    {
      path:'evaluation-par-defaut',
      component:ListEvalWegestuComponent
    },
    {
      path:'add-role',
      component:AddRoleComponent
    },
    {
      path: 'general',
      children: [
        {
          path: '',
          loadChildren: () =>
            import('./components/general/general.module').then((m) => m.GeneralModule),
        },

      ],
    },
  ];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
