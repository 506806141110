<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_content" class="app-content flex-column-fluid">
  <!--begin::Content container-->
  <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div
      id="kt_app_toolbar_container"
      class="app-container container-fluid d-flex flex-stack"
    >
      <!--begin::Page title-->
      <div
        class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
      >
        <!--begin::Title-->
        <div class="d-flex align-items-center">
          <h1 class="page-heading">
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_138_53)">
                <path
                  d="M8.93572 6.21891L4.29275 3.10518C4.23449 3.06604 4.16094 3.06357 4.10021 3.09527C4.03752 3.12846 4 3.19236 4 3.2627V8.92349C4 8.9864 4.03209 9.04485 4.08343 9.07854L8.7274 12.1943C8.758 12.2146 8.79601 12.226 8.83156 12.226C8.86217 12.226 8.89277 12.219 8.92042 12.2037C8.98164 12.17 9.02063 12.1061 9.02063 12.0367V6.37643C9.01915 6.31352 8.98855 6.25408 8.93572 6.21891Z"
                  fill="#265D91"
                />
                <path
                  d="M14.5163 16.9783L9.87331 13.8646C9.81506 13.8259 9.7415 13.8215 9.68078 13.8552C9.61957 13.8869 9.58057 13.9503 9.58057 14.0206V19.6829C9.58057 19.7458 9.61265 19.8042 9.664 19.8389L14.308 22.9541C14.3386 22.9744 14.3766 22.9853 14.4121 22.9853C14.4427 22.9853 14.4733 22.9769 14.501 22.9631C14.5622 22.9299 14.5997 22.866 14.5997 22.7961V17.1358C14.5997 17.0734 14.5691 17.013 14.5163 16.9783Z"
                  fill="#265D91"
                />
                <path
                  d="M25.6627 3.50144C25.6029 3.46825 25.5308 3.46973 25.4726 3.50441L20.8281 6.3358C20.7728 6.36998 20.7383 6.4314 20.7383 6.49679V12.4424C20.7383 12.5118 20.7768 12.5757 20.838 12.6069C20.8656 12.6222 20.8963 12.6301 20.9269 12.6301C20.9629 12.6301 21.0004 12.6197 21.0305 12.5989L25.675 9.48372C25.7263 9.44904 25.7584 9.39059 25.7584 9.32669V3.66589C25.7584 3.59704 25.7219 3.53463 25.6627 3.50144Z"
                  fill="#265D91"
                />
                <path
                  d="M14.1867 2.78963C14.1882 2.72177 14.1531 2.65737 14.0939 2.6227L9.73624 0.0265969C9.68243 -0.00708651 9.6143 -0.00807717 9.55704 0.0196621L4.69684 2.4414C4.63563 2.47261 4.59564 2.53403 4.59268 2.60189C4.58823 2.66827 4.6218 2.73415 4.67858 2.76982L9.0219 5.54275C9.0525 5.56207 9.08706 5.57248 9.1231 5.57248C9.15222 5.57248 9.18283 5.56504 9.21048 5.55018L14.0865 2.95309C14.1462 2.9199 14.1852 2.857 14.1867 2.78963Z"
                  fill="#265D91"
                />
                <path
                  d="M25.2596 2.99965C25.2581 2.93129 25.2211 2.86789 25.1599 2.83668L20.5707 0.379774C20.5154 0.350053 20.4488 0.350054 20.3935 0.38126L15.8487 2.85105C15.789 2.88374 15.7505 2.94665 15.7505 3.01699C15.7505 3.08485 15.7875 3.14925 15.8472 3.18243L20.4616 5.76566C20.4907 5.78101 20.5223 5.78943 20.5529 5.78943C20.586 5.78943 20.6181 5.78101 20.6472 5.76417L25.1653 3.16708C25.2255 3.13191 25.2611 3.069 25.2596 2.99965Z"
                  fill="#265D91"
                />
                <path
                  d="M20.0772 13.8551C20.0164 13.8215 19.9429 13.8254 19.8841 13.8645L15.2417 16.9783C15.1888 17.0135 15.1567 17.0734 15.1567 17.1358V22.7966C15.1567 22.8664 15.1957 22.9303 15.2565 22.9635C15.2846 22.9774 15.3147 22.9863 15.3453 22.9863C15.3819 22.9863 15.4189 22.9744 15.4495 22.9546L20.0939 19.8394C20.1453 19.8047 20.1774 19.7463 20.1774 19.6834V14.0216C20.1774 13.9512 20.1384 13.8873 20.0772 13.8551Z"
                  fill="#265D91"
                />
                <path
                  d="M19.7028 13.3603C19.7042 13.2924 19.6692 13.228 19.6099 13.1934L15.0845 10.2718C15.0306 10.2391 14.9625 10.2376 14.9057 10.2654L9.99273 13.1364C9.93201 13.1676 9.89153 13.229 9.88906 13.2969C9.88462 13.3638 9.91819 13.4292 9.97496 13.4653L14.7172 16.5815C14.7478 16.6008 14.7818 16.6108 14.8184 16.6108C14.8475 16.6108 14.8781 16.6038 14.9057 16.5885L19.6025 13.5233C19.6623 13.4911 19.7013 13.4282 19.7028 13.3603Z"
                  fill="#265D91"
                />
                <path
                  d="M14.6466 3.50144C14.5869 3.46825 14.5148 3.46973 14.4566 3.50441L9.66993 6.08862C9.61464 6.1228 9.58008 6.18373 9.58008 6.24961V12.1952C9.58008 12.2646 9.61859 12.3285 9.6798 12.3602C9.70745 12.375 9.73805 12.383 9.76866 12.383C9.8047 12.383 9.84173 12.3726 9.87233 12.3518L14.7538 9.68631C14.8051 9.65164 14.8372 9.59319 14.8372 9.52879L14.7429 3.66589C14.7429 3.59704 14.7069 3.53463 14.6466 3.50144Z"
                  fill="#265D91"
                />
                <path
                  d="M20.1305 6.18869L15.4885 3.46528C15.4303 3.42664 15.3572 3.42169 15.296 3.45537C15.2348 3.48757 15.1963 3.55098 15.1963 3.62082L15.2481 9.49263C15.2481 9.55554 15.2802 9.6135 15.3311 9.64817L19.98 12.4949C20.0106 12.5152 20.0486 12.5266 20.0841 12.5266C20.1147 12.5266 20.1453 12.5177 20.173 12.5043C20.2342 12.4706 20.2717 12.4072 20.2717 12.3369V6.46757C20.2717 6.40466 20.1834 6.22287 20.1305 6.18869Z"
                  fill="#265D91"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_138_53"
                  x="0"
                  y="0"
                  width="29.7583"
                  height="30.9863"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_138_53"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_138_53"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            Modules
          </h1>
        </div>
        <!--end::Title-->
        <!--begin::Breadcrumb-->
        <ul
          class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1"
        >
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <a class="text-muted" routerLink="/">Accueil</a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <a class="text-muted" routerLink="/acceuil/modules/list-category"
              >Modules</a
            >
          </li>
          <!--end::Item-->
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted cursor-pointer" (click)="navigateToDetails()">Détails module</li>
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">Questions de compétence</li>
          <!--end::Item-->
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->
      <!--begin::Actions-->

      <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
  </div>
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->
    <div class="card mb-5 pb-10 pt-10">
      <div class="title">
        <h4>Module : {{ subCategoryName }}</h4>
        <p>
          Ajouter les questions et leurs propositions puis cocher la ou les
          bonne(s) réponse(s).
        </p>
      </div>
    </div>
    <div class="card pb-5 pt-5">
      <!--begin::Card header-->
      <div class="card-header border-0 pt-6 mb-4">
        <!--begin::Card title-->

        <div class="card-title">
          <div class="input-group mb-3">
            <input
              type="text"
              data-kt-customer-table-filter="search"
              [(ngModel)]="searchElement"
              (keyup.enter)="applyFilter()"
              class="form-control form-control-solid ps-15"
              placeholder="Rechercher"
            />
            <div class="input-group-append">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                  fill="#7E7C7C"
                />
              </svg>

              <button
                class="input-group-text cursor-pointer"
                id="basic-addon2"
                (click)="applyFilter()"
              >
                <span>Rechercher</span>
              </button>
            </div>
          </div>
        </div>
        <!--begin::Card title-->
        <!--begin::Card toolbar-->
        <div class="card-toolbar">
          <!--begin::Toolbar-->
          <div class="card-tools" data-kt-customer-table-toolbar="base">
            <!--begin::Filter-->

            <!--end::Filter-->
            <!--begin::Export-->

            <!--end::Export-->
            <!--begin::Add customer-->
            <button
              type="button"
              (click)="openModalAdd(add_question, 'create_question')"
              class="btn btn-primary ajouter"
            >
              + Génération des questions par IA
            </button>

            <button
              type="button"
              (click)="openModal(add_update_question, 'create_question')"
              class="btn btn-primary ajouter"
            >
              + Ajouter Question
            </button>
            <!--end::Add customer-->
          </div>
          <!--end::Toolbar-->
          <!--begin::Group actions-->
          <div
            class="d-flex justify-content-end align-items-center d-none"
            data-kt-customer-table-toolbar="selected"
          >
            <div class="fw-bold me-5">
              <span
                class="me-2"
                data-kt-customer-table-select="selected_count"
              ></span
              >Selected
            </div>
            <button
              type="button"
              class="btn btn-danger"
              data-kt-customer-table-select="delete_selected"
            >
              Delete Selected
            </button>
          </div>
          <!--end::Group actions-->
        </div>
        <!--end::Card toolbar-->
      </div>
      <!--end::Card header-->
      <!--begin::Card body-->
     <div *ngIf="!isLoadSpinner">
      <div
        class="card-body pt-0"
        *ngFor="let question of questionList; let i = index"
      >
        <div  class="item mb-5">
          <div class="item-header">
            <div class="left">
              <span class="number">{{ offset + i + 1 }}</span>
              <h4>
                {{
                  question.question_FR
                    | slice : 0 : (showFullText[i] ? undefined : maxLength)
                }}
                <span *ngIf="question.question_FR.length > maxLength">
                  <a
                    (click)="showFullText[i] = !showFullText[i]"
                    class="show_more"
                  >
                    {{ showFullText[i] ? "Voir moins" : " Voir plus" }}
                  </a>
                </span>
                <span class="badge">{{ question.time + " (Secondes)" }}</span>
              </h4>
            </div>
            <div class="right">
              <button class="delete" (click)="openDeleteQuestionAlert(question)">
                <svg
                  width="28"
                  height="32"
                  viewBox="0 0 28 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_440_1235)">
                    <path
                      d="M21.3004 6.0961H22.1502C22.3756 6.0961 22.5917 6.00657 22.7511 5.8472C22.9105 5.68784 23 5.47169 23 5.24631C23 5.02093 22.9105 4.80478 22.7511 4.64541C22.5917 4.48604 22.3756 4.39651 22.1502 4.39651H17.9012V3.54671C17.8992 2.87119 17.63 2.22391 17.1523 1.74624C16.6746 1.26858 16.0273 0.999332 15.3518 0.997314H10.253C9.57751 0.999332 8.93023 1.26858 8.45256 1.74624C7.97489 2.22391 7.70565 2.87119 7.70363 3.54671V4.39651H3.8498C3.62442 4.39651 3.40827 4.48604 3.2489 4.64541C3.08953 4.80478 3 5.02093 3 5.24631C3 5.47169 3.08953 5.68784 3.2489 5.8472C3.40827 6.00657 3.62442 6.0961 3.8498 6.0961H4.30444V18.8431C4.30444 19.97 4.7521 21.0507 5.54894 21.8476C6.34578 22.6444 7.42653 23.0921 8.55343 23.0921H17.0514C18.1783 23.0921 19.2591 22.6444 20.0559 21.8476C20.8527 21.0507 21.3004 19.97 21.3004 18.8431V6.0961ZM11.1028 17.1435C11.1028 17.3689 11.0133 17.585 10.8539 17.7444C10.6946 17.9037 10.4784 17.9933 10.253 17.9933C10.0276 17.9933 9.8115 17.9037 9.65213 17.7444C9.49276 17.585 9.40323 17.3689 9.40323 17.1435V10.3451C9.40323 10.1197 9.49276 9.90356 9.65213 9.7442C9.8115 9.58483 10.0276 9.4953 10.253 9.4953C10.4784 9.4953 10.6946 9.58483 10.8539 9.7442C11.0133 9.90356 11.1028 10.1197 11.1028 10.3451V17.1435ZM16.2016 17.1435C16.2016 17.3689 16.1121 17.585 15.9527 17.7444C15.7933 17.9037 15.5772 17.9933 15.3518 17.9933C15.1264 17.9933 14.9103 17.9037 14.7509 17.7444C14.5915 17.585 14.502 17.3689 14.502 17.1435V10.3451C14.502 10.1197 14.5915 9.90356 14.7509 9.7442C14.9103 9.58483 15.1264 9.4953 15.3518 9.4953C15.5772 9.4953 15.7933 9.58483 15.9527 9.7442C16.1121 9.90356 16.2016 10.1197 16.2016 10.3451V17.1435ZM16.2016 4.39651H9.40323V3.54671C9.40323 3.32133 9.49276 3.10518 9.65213 2.94581C9.8115 2.78644 10.0276 2.69691 10.253 2.69691H15.3518C15.5772 2.69691 15.7933 2.78644 15.9527 2.94581C16.1121 3.10518 16.2016 3.32133 16.2016 3.54671V4.39651Z"
                      fill="#EE6352"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_440_1235"
                      x="0"
                      y="0.997314"
                      width="28"
                      height="30.0947"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="1" dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_440_1235"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_440_1235"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
              <button class="edit"(click)="openModal(add_update_question, 'edit_question', question)">
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_142_1063)">
                    <path
                      d="M4 15.6558V5.59137C4 4.9982 4.23707 4.42933 4.65906 4.00989C5.08106 3.59046 5.6534 3.35482 6.25019 3.35482H13.1301L10.9165 5.59137H6.25019V15.6558H16.376V11.0206L18.6262 8.82039V15.6558C18.6262 16.249 18.3891 16.8179 17.9671 17.2373C17.5451 17.6567 16.9728 17.8924 16.376 17.8924H6.25019C5.6534 17.8924 5.08106 17.6567 4.65906 17.2373C4.23707 16.8179 4 16.249 4 15.6558ZM20.3138 0C19.8664 0.000391512 19.4374 0.177375 19.1212 0.492041L11.1246 8.56878C10.8786 8.8174 10.6929 9.11861 10.5818 9.44942L9.77173 11.8677C9.76161 11.9064 9.76183 11.947 9.77238 11.9855C9.78293 12.0241 9.80343 12.0592 9.83185 12.0875C9.86028 12.1157 9.89564 12.1361 9.93443 12.1466C9.97322 12.1571 10.0141 12.1573 10.053 12.1473L12.4944 11.3505C12.8273 11.2401 13.1304 11.0556 13.3805 10.8109L21.5064 2.86278C21.7422 2.6282 21.9027 2.32944 21.9677 2.00425C22.0326 1.67906 21.9992 1.34202 21.8716 1.03569C21.744 0.729368 21.5279 0.4675 21.2507 0.283164C20.9734 0.098828 20.6474 0.000291618 20.3138 0Z"
                      fill="#265D91"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_142_1063"
                      x="0"
                      y="0"
                      width="26"
                      height="25.8924"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_142_1063"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_142_1063"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
          <div
            class="item-content"
            *ngFor="let res of question.response_question"
          >
          <div class="question">
            <div class="form-check form-check-custom form-check-solid">
              <!-- (change)="changeState($event, res, question.id)"  -->
                <input
                    class="form-check-input checkbox-border"
                    [checked]="res.state"
                    type="checkbox"
                    value="1"
                    id="flexCheckDefault"
                    [disabled]="true"
                   >
                <label class="form-check-label" for="flexCheckDefault">
                    {{ res.response_FR }}
                </label>
            </div>
        </div>
          </div>
        </div>
      </div>
   

      <div
        class="alert alert-warning text-center mt-5"
        role="alert"
        *ngIf="questionList && !questionList.length"
      >
        <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5" />
        <span class="empty-list"> La liste est vide </span>
      </div>

      <div class="pagination" *ngIf="questionList && questionList.length">
        <app-pagination
          [data]="questionList"
          [lengthData]="totalItems"
          [currentPageList]="currentPage"
          (setPages)="getItems($event)"
        >
        </app-pagination>
      </div>
    </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
    <!--begin::Modals-->
    <!--begin::Modal - Customers - Add-->

    <!--end::Modal - New Card-->
    <!--end::Modals-->
  </div>
  <!--end::Content container-->
</div>
<ng-template #add_update_question>
  <div
    class="modal-content"
    id="add_update_question"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="  ">
      <!--begin::Modal content-->
      <div class="">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2 class="">
            {{ mode == "create_question" ? "Ajouter " : "Modifier " }} Question
          </h2>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div
            id="kt_customers_export_close"
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <i class="ki-duotone ki-cross fs-1" (click)="closeModal()">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </div>
        </div>
        <div class="modal-body">
          <!--begin::Form-->
          <form
            id="kt_customers_export_form"
            class="form"
            [formGroup]="questionForm"
          >
            <!--begin::Input group-->
            <!--begin::Label-->
            <div class="mb-5">
              <label for="question" class="required form-label">
                {{
                  mode == "create_question" ? "Ajouter " : "Modifier "
                }}
                Question</label
              >
              <!-- (keypress)="keyPressTextArea($event)" -->
              <textarea
              type="text"
              (keydown.enter)="keyPressTextArea($event)"
              (keydown.tab)="keyPressTextArea($event)"
              id="textAreaInput"
              formControlName="question_FR"
              class="form-control"
              placeholder="Question"
          ></textarea>
          
              <app-feild-error
                [control]="questionForm.get('question_FR')"
              ></app-feild-error>
            </div>
            <div class="mb-5">
              <label for="question" class="required form-label">
                Temps (Secondes)</label
              >
              <input
                type="text"
                (keypress)="keyPress($event)"
                formControlName="time"
                class="form-control"
                placeholder="temps"
              />
              <app-feild-error
                [control]="questionForm.get('time')"
              ></app-feild-error>
            </div>

            <div formArrayName="responses">
              <div
                *ngFor="let diplome of responses.controls; let i = index"
                class="content-inside"
              >
                <div class="question mb-3" [formGroupName]="i">
                  <div class="new-question">
                    <div class="reponse mb-5">
                      <label
                        for="question"
                        class="required form-label correct-check"
                        >{{
                          mode == "create_question" ? "Ajouter " : "Modifier"
                        }}
                        une réponse ({{i+1}})
                        <div class="form-check">
                          <input  formControlName="state" 
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="correct" (change)="getstatevalue($event,i)"
                          />
                          </div
                      ></label>
                      <textarea
                        formControlName="response_FR"  
                        type="text"
                        class="form-control"
                        placeholder="Ajouter une réponse"
                        style="white-space: pre-line"
                      ></textarea>
                      <app-feild-error
                        [control]="diplome.get('response_FR')"
                      ></app-feild-error>
                      <!-- <div *ngIf="responses && responses.controls && responses.controls[i]?.value?.response_FR.trim() == responses.controls[i+1]?.value?.response_FR.trim()">
                      <span class="error-message" style="color: red;">Réponse en double ! Veuillez saisir une autre réponse.</span>
                    </div> -->
                      <div *ngIf="isResponseDuplicate(i)">
                        <span class="error-message" style="color: red" 
                          >Réponse en double ! Veuillez saisir une autre
                          réponse.</span
                        >
                   
                  
                      </div>
                    </div>
                  </div>
                  <div
                    class="delete-icon"
                    *ngIf="
                      (i > 0 && mode == 'create_question') ||
                      (i >= 0 && mode != 'create_question')
                    "
                  >
                    <button
                      class="delete-btn-response"
                      (click)="removeResponse(i, diplome)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="27"
                        viewBox="0 0 25 27"
                        fill="none"
                      >
                        <g filter="url(#filter0_d_1970_2873)">
                          <path
                            d="M17.9851 4.17508H18.6809C18.8655 4.17508 19.0425 4.10177 19.173 3.97127C19.3035 3.84078 19.3768 3.66379 19.3768 3.47924C19.3768 3.29469 19.3035 3.11769 19.173 2.9872C19.0425 2.8567 18.8655 2.78339 18.6809 2.78339H15.2017V2.08754C15.2 1.5344 14.9796 1.00438 14.5884 0.613252C14.1973 0.222119 13.6673 0.001652 13.1141 0H8.93906C8.38591 0.001652 7.8559 0.222119 7.46477 0.613252C7.07363 1.00438 6.85317 1.5344 6.85151 2.08754V2.78339H3.69585C3.5113 2.78339 3.33431 2.8567 3.20381 2.9872C3.07331 3.11769 3 3.29469 3 3.47924C3 3.66379 3.07331 3.84078 3.20381 3.97127C3.33431 4.10177 3.5113 4.17508 3.69585 4.17508H4.06813V14.6128C4.06813 15.5355 4.43469 16.4205 5.08717 17.073C5.73965 17.7255 6.62461 18.092 7.54736 18.092H14.5058C15.4286 18.092 16.3135 17.7255 16.966 17.073C17.6185 16.4205 17.9851 15.5355 17.9851 14.6128V4.17508ZM9.6349 13.2211C9.6349 13.4056 9.56159 13.5826 9.43109 13.7131C9.3006 13.8436 9.12361 13.9169 8.93906 13.9169C8.75451 13.9169 8.57751 13.8436 8.44702 13.7131C8.31652 13.5826 8.24321 13.4056 8.24321 13.2211V7.65432C8.24321 7.46977 8.31652 7.29278 8.44702 7.16228C8.57751 7.03178 8.75451 6.95847 8.93906 6.95847C9.12361 6.95847 9.3006 7.03178 9.43109 7.16228C9.56159 7.29278 9.6349 7.46977 9.6349 7.65432V13.2211ZM13.81 13.2211C13.81 13.4056 13.7367 13.5826 13.6062 13.7131C13.4757 13.8436 13.2987 13.9169 13.1141 13.9169C12.9296 13.9169 12.7526 13.8436 12.6221 13.7131C12.4916 13.5826 12.4183 13.4056 12.4183 13.2211V7.65432C12.4183 7.46977 12.4916 7.29278 12.6221 7.16228C12.7526 7.03178 12.9296 6.95847 13.1141 6.95847C13.2987 6.95847 13.4757 7.03178 13.6062 7.16228C13.7367 7.29278 13.81 7.46977 13.81 7.65432V13.2211ZM13.81 2.78339H8.24321V2.08754C8.24321 1.90299 8.31652 1.726 8.44702 1.5955C8.57751 1.46501 8.75451 1.39169 8.93906 1.39169H13.1141C13.2987 1.39169 13.4757 1.46501 13.6062 1.5955C13.7367 1.726 13.81 1.90299 13.81 2.08754V2.78339Z"
                            fill="#EE6352"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_1970_2873"
                            x="0"
                            y="0"
                            width="24.375"
                            height="26.0918"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dx="1" dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1970_2873"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1970_2873"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-3 mb-5">
                <button
                  class="btn add-reponse"
                  [disabled]="
                    responses.length >= 4 ||
                    responses.controls[0].value.response_FR == '' 
                  "
                  (click)="addResponse()"
                >
                  Ajouter une réponse
                </button>
              </div>
              <span class="requirements mt-5" *ngIf="responses.length < 2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_379_198)">
                    <path
                      d="M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346629 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87104 0 6.41775 0 8C0.00229405 10.121 0.845886 12.1545 2.34568 13.6543C3.84547 15.1541 5.87897 15.9977 8 16ZM8 3.33334C8.19778 3.33334 8.39112 3.39199 8.55557 3.50187C8.72002 3.61175 8.84819 3.76793 8.92388 3.95065C8.99957 4.13338 9.01937 4.33445 8.98079 4.52843C8.9422 4.72241 8.84696 4.90059 8.70711 5.04044C8.56726 5.1803 8.38907 5.27554 8.19509 5.31412C8.00111 5.35271 7.80004 5.3329 7.61732 5.25722C7.43459 5.18153 7.27841 5.05336 7.16853 4.88891C7.05865 4.72446 7 4.53112 7 4.33334C7 4.06812 7.10536 3.81377 7.29289 3.62623C7.48043 3.43869 7.73478 3.33334 8 3.33334ZM7.33333 6.66667H8C8.35362 6.66667 8.69276 6.80715 8.94281 7.0572C9.19286 7.30724 9.33333 7.64638 9.33333 8V12C9.33333 12.1768 9.2631 12.3464 9.13807 12.4714C9.01305 12.5964 8.84348 12.6667 8.66667 12.6667C8.48986 12.6667 8.32029 12.5964 8.19526 12.4714C8.07024 12.3464 8 12.1768 8 12V8H7.33333C7.15652 8 6.98695 7.92977 6.86193 7.80474C6.73691 7.67972 6.66667 7.51015 6.66667 7.33334C6.66667 7.15653 6.73691 6.98696 6.86193 6.86193C6.98695 6.73691 7.15652 6.66667 7.33333 6.66667Z"
                      fill="#EE6352"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_379_198">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                &nbsp;Vous devez ajouter au moins deux réponses.</span>
            </div>
          </form>
          <div class="modal-footer text-center">
            <button
              (click)="closeModal()"
              data-bs-dismiss="modal"
              id="kt_customers_export_cancel"
              class="footer-btn annuler me-3"
            >
              Annuler
            </button>
            <button
        [disabled]="
            !questionForm.valid ||
            responses.length < 2 ||
            hasDuplicateResponses()  || isDuplicateResponseErrorShown
        "
        (click)="confirmer()"
        type="submit"
        id="kt_customers_export_submit"
        class="footer-btn confirmer"
    >
        <span class="indicator-label">Confirmer</span>
        <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
    </button>
          </div>
          <!--end::Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
</ng-template>

<ng-template #add_question>
  <div
    class="modal-content"
    id="add_update_question"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="  ">
      <!--begin::Modal content-->
      <div class="">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2 class="">
            {{ mode == "create_question" ? "Ajouter " : "Modifier " }} Question
          </h2>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div
            id="kt_customers_export_close"
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <i class="ki-duotone ki-cross fs-1" (click)="closeModal()">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </div>
        </div>
        <div class="modal-body">
          <!--begin::Form-->
          <form
            id="kt_customers_export_form"
            class="form tools"
            [formGroup]="questionAddForm"
          >
            <!--begin::Input group-->
            <div class="mb-5">
              <label for="question" class="required form-label">
                {{
                  mode == "create_question" ? "Ajouter " : "Modifier "
                }}
                Nombre des questions</label
              >
              <input
                type="text"
                formControlName="numberOfQuestions"
                class="form-control"
                placeholder=" Nombre des questions"
              />
              <app-feild-error
                [control]="questionAddForm.get('numberOfQuestions')"
              ></app-feild-error>
              <div
                *ngIf="
                  questionAddForm.get('numberOfQuestions')?.hasError('pattern')
                "
                style="color: red"
              >
                Le nombre de questions doit être entre 1 et 10.
              </div>
            </div>
          </form>
          <div class="modal-footer text-center">
            <button
              (click)="closeModal()"
              data-bs-dismiss="modal"
              id="kt_customers_export_cancel"
              class="footer-btn annuler me-3"
            >
              Annuler
            </button>
            <button
              [disabled]="!questionAddForm.valid"
              (click)="addQuestionAi()"
              type="submit"
              id="kt_customers_export_submit"
              class="footer-btn confirmer"
            >
              <span class="indicator-label">Confirmer</span>
              <span class="indicator-progress"
                >Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span
              ></span>
            </button>
          </div>
          <!--end::Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
</ng-template>
