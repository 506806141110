import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedTuto,
  confirmDelete,
  deletedTuto,
  errorMessage,
  existedLinkTuto,
  existedTitleTuto,
  failedDelete,
  fileTypeExtention,
  maxSizeFile,
  serverError,
  updatedTuto,
} from '../../../../../core/models/messages';
import { youTubePattern } from '../../../../../core/models/pattern';
import { Ituto } from '../../../../../core/models/setting';
import { SettingsService } from '../../../../../core/services/settings/settings.service';
import { environment } from '../../../../../../environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-tuto',
  templateUrl: './tuto.component.html',
  styleUrls: ['./tuto.component.css'],
})
export class TutoComponent {
  showFullText: any;
  file: any;
  selectedImage: any;
  /* string */
  mode: string = 'addVideo';
  fileName!: string;
  loadSpinnerTable: boolean = false;
  fileExtention!: string;
  fileError!: string;
  searchFormGroup: FormGroup = this.dataSearch();
  pagination : boolean =false;

  url: string = environment.baseUrl + '/api/Tuto/file/tutosFiles/';
  /* numbers */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 1;
  idTuto!: number | undefined;
  currentPlayingVideo: number | null = null;
  maxLength = 160;
  /* array */
  companyType = [
    { id: '1', name: 'Candidat' },
    { id: '2', name: 'ESN' },
    { id: '3', name: 'Client final' },
  ];

  tutoList: Ituto[] = [];
  /* formGroup */
  tutoForm: FormGroup = this.createTutoForm();
  /* modal */
  modalRef?: BsModalRef;
  fileValid: boolean = true;
  /* form data */
  formData = new FormData();
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private settingService: SettingsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer
  ) {}

  /* init */
  ngOnInit() {
    this.loadSpinnerTable = true;
    this.getListTuto();
  }
  openModal(template: TemplateRef<any>, mode: string, data?: Ituto) {
    this.mode = mode;
    if (mode != 'addVideo') {
      this.idTuto = data?.id;
      this.tutoForm = this.createTutoForm(data);
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef?.hide();
    this.tutoForm.reset();
    this.file = null;
    this.selectedImage = null;
    this.fileExtention = '';
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchFormGroup.dirty) {
        this.searchTuto();
      } else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
        this.getListTuto();

      }
    }
  }
  sortData() {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.getListTuto();
  }

  getListTuto() {
    let data = { per_page: this.itemsPerPage, page: this.currentPage };
    this.spinner.show();
    this.settingService
      .getListTuto(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('res list tuto', res);
          this.spinner.hide();
          this.loadSpinnerTable = false;
          this.tutoList = res.data.items.data.slice(0, this.endIndex);
          this.totalItems = res.data.items.total;
          this.showFullText = new Array(this.tutoList.length).fill(false);
          if (this.currentPage > 1 && !res.data.items.data.length) {
            this.currentPage = 1;
            this.getListTuto();
          }
          this.tutoList = this.tutoList.map((item) => ({
            ...item,
            show_video: false,
          }));
        },
        error: () => {
          this.loadSpinnerTable = false;
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }

  /* create add tuto form */
  createTutoForm(data?: Ituto) {
    return this.formBuilder.group({
      title_FR: [
        data && data.title_FR ? data.title_FR : '',
        [Validators.required],
      ],
      description_FR: [
        data &&
        data.description_FR !== 'null' &&
        data.description_FR !== undefined
          ? data.description_FR
          : '',
      ],
      type: [
        data && data.type !== null ? data.type : '',
        [Validators.required],
      ],
      link: [
        data && data.link ? data.link : '',
        [Validators.required, Validators.pattern(youTubePattern)],
      ],
      cover: [data && data.cover ? data.cover : ''],
    });
  }

  /* set form data */
  setFormData() {
    this.formData.append('title_FR', this.tutoForm.value.title_FR);
    this.formData.append('description_FR', this.tutoForm.value.description_FR);
    let type = this.tutoForm.get('type')?.value;
    for (const mob of type) {
      this.formData.append('type[]', mob);
    }
    this.formData.append('link', this.tutoForm.value.link);
    if (this.file) this.formData.append('cover', this.file);
  }
  dataSearch(data?: any) {
    return this.formBuilder.group({
      type: [data ? data?.type : []],
      description: [data ? data?.description : ''],
      title: [data ? data?.title : ''],
    });
  }

  searchTuto() {
    this.spinner.show();
    let data = {
      type: this.searchFormGroup.value.type,
      description: this.searchFormGroup.value.description,
      title: this.searchFormGroup.value.title,
      page: this.currentPage,
      per_page: this.itemsPerPage,
      search: this.searchFormGroup.value?.search,
    };

    this.settingService
      .searchListTuto(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          if (!res.data.items.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.searchTuto();
          } else {
            console.log('response format:', res);
            this.tutoList = res.data.items.data.slice(0, this.endIndex);
            console.log('total', this.tutoList);
            this.totalItems = res.data.items.total;

            this.spinner.hide();
          }
        },
        error: () => {
          console.error('Error during tuto search');
          this.spinner.hide();
        },
      });
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.searchFormGroup = this.dataSearch();
    if (!this.isRecherche) {
      this.spinner.hide();
      console.log('gggg');
      console.log('gggg', this.isRecherche);
    } else {
      this.spinner.hide();
      console.log('hhh');
      this.getListTuto();
      console.log('hhhh', this.isRecherche);
    }
    return (this.isRecherche = false);
  }

  /* upload file */
  // uploadFile(event: any): void {
  //   const file = event.target.files[0];
  //   const maxSize = 2 * 1024 * 1024;
  //   this.file = file
  //   this.fileName = this.file.name
  //   const allowedExtensions = ['.jpeg', '.jpg', '.png', '.svg', '.gif'];
  //   const fileExtension = file.name.toLowerCase().substr(file.name.lastIndexOf('.'));
  //   if (file) {
  //     if (!allowedExtensions.includes(fileExtension)) {
  //       this.fileExtention = fileTypeExtention
  //     } else if (file.size > maxSize) {
  //       this.fileError = maxSizeFile
  //     }
  //     else {
  //       this.fileError = ''
  //       this.fileExtention = ''
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         this.selectedImage = reader.result;
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   }
  //   this.tutoForm.get('cover')?.setValue(file)
  // }
  uploadFile(event: any): void {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    this.file = file;
    this.fileName = this.file?.name;
    const allowedExtensions = [
      '.jpeg',
      '.jpg',
      '.png',
      '.gif',
      '.jfif',
      '.avif',
    ];
    const fileExtension = file?.name
      .toLowerCase()
      .substr(file.name.lastIndexOf('.'));
    console.log('file', file);
    if (file) {
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileExtention = fileTypeExtention;
        this.toastr.error(
          "Le format du fichier n'est pas accepté.Veuillez choisir une photo"
        );
        this.fileValid = false; // Set file validity to false
      } else if (file.size > maxSize) {
        this.fileError = maxSizeFile;
        this.fileValid = false; // Set file validity to false
      } else {
        this.fileError = '';
        this.fileExtention = '';
        this.fileValid = true; // Set file validity to true
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(file);
      }
    } else if (file === undefined) {
      this.fileValid = true;
      this.fileExtention = '';
    }
    console.log('filevalid', this.fileValid);
    // this.tutoForm.get('cover')?.setValue(file);
  }
  isImage(): boolean {
    const allowedExtensions = [
      '.jpeg',
      '.jpg',
      '.png',
      '.gif',
      '.jfif',
      '.avif',
    ];
    const fileExtension = (this.fileName || '')
      .toLowerCase()
      .substr((this.fileName || '').lastIndexOf('.'));
    return allowedExtensions.includes(fileExtension);
  }
  /* either to add or to update tuto */
  confirmSend() {
    if (this.mode == 'addVideo') {
      this.addtuto();
    } else {
      this.updatetuto();
    }
  }
  /* add tuto */
  addtuto() {
    if (this.tutoForm.valid) {
      this.setFormData();
      this.spinner.show();
      this.settingService
        .addTuto(this.formData)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            console.log('response', res);
            if (res.status == 200) {
              this.spinner.hide();
              this.getListTuto();
              this.closeModal();
              this.toastr.success(addedTuto);
              this.tutoForm.reset();
              this.formData = new FormData();
            }
          },
          error: (err) => {
            this.closeModal();
            this.formData = new FormData();
            if (err.error) {
              console.log(err.error);
              if (err.error?.message) {
                if (err.error.message['link'])
                  this.toastr.info(existedLinkTuto);
                // if (err.error.message.title_FR) {
                //   // const errorMessage = err.error.message.title_FR[0];
                //   this.toastr.info(errorMessage);}
                // The title  f r must not be greater than 255 characters.
                else if (
                  err.error?.message['title_FR'][0] ===
                  'The title  f r must not be greater than 255 characters.'
                )
                  this.toastr.info(errorMessage);
                else if (
                  err.error?.message['title_FR'][0] ===
                  'The title_FR has already been taken.'
                )
                  this.toastr.info(existedTitleTuto);

                // else if(!err.error.message['link']) this.toastr.error(serverError)
              } else this.toastr.error(serverError);
            }
            this.spinner.hide();
          },
        });
    }
  }

  /* update tuto */
  updatetuto() {
    this.spinner.show();
    this.setFormData();
    this.settingService
      .updateTuto(this.idTuto, this.formData)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListTuto();
            this.spinner.hide();
            this.closeModal();
            this.toastr.success(updatedTuto);
            this.tutoForm.reset();
            this.formData = new FormData();
          }
        },
        error: (err) => {
          this.closeModal();
          this.formData = new FormData();
          if (err.error) {
            console.log(err.error);
            if (err.error?.message) {
              if (err.error.message['link']) {
                this.toastr.info(existedLinkTuto);
              } else if (Array.isArray(err.error.message['title_FR'])) {
                if (
                  err.error.message['title_FR'][0] ===
                  'The title  f r must not be greater than 255 characters.'
                ) {
                  this.toastr.info(errorMessage);
                } else if (
                  err.error.message['title_FR'][0] ===
                    'The title_FR has already been taken.' ||
                  err.error.message['title_FR'][0] ===
                    'The title  f r has already been taken.'
                ) {
                  this.toastr.info(existedTitleTuto);
                }
              } else {
                this.toastr.error(serverError);
              }
            } else {
              this.toastr.error(serverError);
            }
          }
          this.spinner.hide();
        },
      });
  }

  /* open delete alert */
  openDeleteAlert(tuto: Ituto) {
    Swal.fire({
      title: `${confirmDelete} ce tutoriel?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteTuto(tuto.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  /* delete tuto */
  deleteTuto(id: number) {
    this.spinner.show();
    this.settingService
      .deleteTuto(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status === 200) {
            this.getListTuto();
            this.spinner.hide();
            this.toastr.success(deletedTuto);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  private getVideoIdFromURL(url: string): string {
    const match = url.match(/v=([a-zA-Z0-9_-]+)/);
    if (match && match[1]) {
      return match[1];
    }
    return '';
  }
  showVideo(link: any, index: any) {
    if (this.currentPlayingVideo !== null) {
      // Check if there's a currently playing video
      this.stopVideo(this.currentPlayingVideo);
    }
    const videoId = this.getVideoIdFromURL(link);
    const displayURL = `https://www.youtube.com/embed/${videoId}`;
    this.tutoList[index].displayURL =
      this.sanitizer.bypassSecurityTrustResourceUrl(displayURL);
    this.tutoList[index].show_video = true;
    this.currentPlayingVideo = index;
  }
  stopVideo(index: number) {
    // Check if the video at the specified index is playing and stop it
    if (this.tutoList[index].show_video) {
      this.tutoList[index].show_video = false;
      this.currentPlayingVideo = null;
    }
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
