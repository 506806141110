<head>
  <title>Client final appels d'offres</title>
  <meta name="description" content="Client final appels d'offres" />
</head>
<div id="kt_app_content" class="app-content flex-column-fluid">
  <!--begin::Content container-->
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->
    <div class="card">
      <div class="title">
        <h4>Liste des appels d'offres</h4>
      </div>
      <!--begin::Card header-->
      <div class="card-header border-0 pt-6 mb-4">
        <!--begin::Card title-->

        <div class="card-title">
          <div class="input-group mb-3">
            <input
              type="text"
              data-kt-customer-table-filter="search"
            
              [(ngModel)]="searchElement"
              class="form-control form-control-solid ps-15"
              placeholder="Rechercher" (keyup.enter)="applyFilter()"
            />
            <div class="input-group-append">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                  fill="#7E7C7C"
                />
              </svg>

              <button
                class="input-group-text cursor-pointer"
                id="basic-addon2"
                (click)="applyFilter()"
              >
                <span>Rechercher</span>
              </button>
            </div>
          </div>
        </div>
        <!--begin::Card title-->
        <!--begin::Card toolbar-->
        <div class="card-toolbar">
          <!--begin::Toolbar-->
          <div
            class="d-flex justify-content-end"
            data-kt-customer-table-toolbar="base"
          >
            <!--begin::Filter-->

            <!--end::Filter-->
            <!--begin::Export-->

            <!--end::Export-->
            <!--begin::Add customer-->
            <button
              type="button"
              class="btn btn-primary ajouter"
              (click)="openModal(addRequestJob)"
            >
              + Ajouter
            </button>
            <!--end::Add customer-->
          </div>
          <!--end::Toolbar-->
          <!--begin::Group actions-->

          <!--end::Group actions-->
        </div>
        <!--end::Card toolbar-->
      </div>
      <!--end::Card header-->
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <!--begin::Table-->
        <div class="table-responsive"  id="kt_customers_table">
          <table
            class="table align-middle fs-6 gy-5 mb-4"
           
          >
            <thead>
              <tr
                class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"
              >
                <th class="min-w-125px">
                  <span
                    >appel d’offre<i
                      (click)="sortData('name')"
                      class="fa-solid fa-sort cursor-pointer"
                    ></i
                  ></span>
                </th>
                <th class="min-w-125px">
                  <span
                    >date<i
                      (click)="sortData('start_date')"
                      class="fa-solid fa-sort cursor-pointer"
                    ></i
                  ></span>
                </th>
                <th class="min-w-125px"><span>mots clés</span></th>
                <th class="min-w-125px"><span>STATUT</span></th>
                <th class="min-w-125px"><span>ACTION</span></th>
              </tr>
            </thead>
            <tbody class="fw-semibold text-gray-600">
              <tr *ngFor="let call of listAppelOffer">
                <td>
                  <a
                    class="first-td"
                    [routerLink]="[
                      '/acceuil/appel-offre/details-appel-offre',
                      { id: call.id }
                    ]"
                    routerLinkActive="router-link-active"
                    >{{ call.name ? call.name : "---" }}</a
                  >
                </td>
                <td>
                  <span>{{
                    !call.start_date ? "---" : (call.start_date | formatDate)
                  }}</span>
                </td>
                <td class="cle" *ngIf="call.skills_job?.length">
                  <div *ngFor="let skill of call.skills_job">
                    <span class="key-word">
                      <div class="badge three">
                        {{
                          skill?.sub_category?.name_FR
                            ? skill?.sub_category?.name_FR
                            : "---"
                        }}
                      </div>
                    </span>
                  </div>
                </td>
                <td class="cle" *ngIf="!call.skills_job?.length">
                  <span class="key-word">
                    <div class="badge three">---
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    <div
                      [ngClass]="call.state ? 'badge-red' : 'badge-green'"
                      class="badge"
                    >
                      <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      ></svg>{{ call.state ? "Désactivé" : "Activé" }}
                    </div>
                  </span>
                </td>
                <td>
                  <!-- <div class="seemore cursor-pointer">
                                        <a (click)="goToDetails(call.id)">Voir
                                            détail</a>
                                    </div> -->
                                    <div class="dropdown">
                                        <button
                                          class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                          type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                          aria-expanded="false">
                                          <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                                              fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                              fill="#265D91" />
                                            <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                              fill="#265D91" />
                                          </svg>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        
                                            <a class="dropdown-item"  style="cursor: pointer;" (click)="goToDetails(call.id)">Voir détails</a>
                                            <a *ngIf="call.state=='1'" class="dropdown-item" style="cursor: pointer;"  (click)="activerjobrequest(call.id)">Activer</a>
                                            <a *ngIf="call.state=='0'" class="dropdown-item" style="cursor: pointer;" (click)="desactiverjobrequest(call.id)">Désactiver</a>
                  
                  
                                                        </div>
                                                    </div>
                                </td>

                            </tr>
                        </tbody>
                        <!--end::Table body-->
                    </table>
                    <!-- [endexPageList]="endIndex" -->
                    <div class="pagination mt-5" *ngIf="listAppelOffer && listAppelOffer.length">
                        <app-pagination [data]="listAppelOffer" [currentPageList]="currentPage" [endexPageList]="endIndex" [lengthData]="totalItems"  (setPages)="getItems($event)">
                        </app-pagination>
                    </div>
                   

                    <div class="alert alert-warning text-center mt-5" role="alert"  *ngIf="listAppelOffer&&!listAppelOffer.length ">
                        <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                        <span class="empty-list">
                         La liste est vide
                     </span>
                     </div>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Car body-->
        </div>
    </div>
</div>
<ng-template #addRequestJob>
  <div
    class="modal-dialog-centered"
    id="add-appel"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <!--begin::Modal content-->
    <div class="modal-content pt-5 pb-15">
      <!--begin::Modal header-->
      <div class="modal-header">
        <!--begin::Modal title-->
        <h2 class="fw-bold">Ajouter appel d'offre</h2>
        <!--end::Modal title-->
        <!--begin::Close-->
        <div
          id="kt_customers_export_close"
          class="btn btn-icon btn-sm btn-active-icon-primary"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="ki-duotone ki-cross fs-1" (click)="closeModal()">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <form [formGroup]="requestFobForm">
        <div class="modal-body mx-5 mx-xl-15 my-7">
          <!--begin::Form-->
          <div class="container">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6 tools mb-5">
                  <label for="poste" class="required form-label">Poste</label>

                  <input
                    type="text"
                    formControlName="name"
                    id="poste"
                    class="form-control mb-3"
                    placeholder="Poste"
                  />

                  <app-feild-error
                    [control]="requestFobForm.get('name')"
                  ></app-feild-error>
                </div>

                <div class="col-lg-6 mb-5">
                  <label for="poste" class="form-label">Type de poste</label>
                  <ng-select
                    [items]="postType"
                    bindValue="id"
                    bindLabel="name"
                    [notFoundText]="'Aucun élément trouvé'"
                    formControlName="post_type"
                    class="form-select mb-3"
                    placeholder="Type de poste"
                  >
                  </ng-select>
                  <app-feild-error
                    [control]="requestFobForm.get('post_type')"
                  ></app-feild-error>
                </div>

                <div class="col-lg-6 tools mb-5">
                  <label for="poste" class="form-label"
                    >Compétences</label
                  >
                  <ng-select
                    [items]="skillsList"
                    bindValue="id"
                    bindLabel="name_FR"
                    [notFoundText]="'Aucun élément trouvé'"
                    formControlName="skills"
                    class="form-select mb-3"
                    placeholder="Compétences"
                    [multiple]="true"
                  >
                  </ng-select>
                  <app-feild-error
                    [control]="requestFobForm.get('skills')"
                  ></app-feild-error>
                </div>
                <div class="col-lg-6 tools mb-5">
                  <label for="poste" class="form-label"
                    >Autres Compétences</label
                  >
                  <input
                    type="text"
                    formControlName="otherSkills"
                    id="poste"
                    class="form-control mb-3"
                    placeholder="Autres Compétences"
                  />
                  <!-- <div *ngIf="createform.get('otherSkills')?.hasError('required') && createform.get('otherSkills')?.touched"
                                                class="text-danger">
                                               <small> Le champ "Autres compétence" est obligatoire</small>
                                            </div> -->
                                    <app-feild-error [control]="requestFobForm.get('otherSkills')"></app-feild-error> 
                                </div>
                                <div class="col-lg-6 tools mb-5">
                                    <label for="poste" class="required form-label">Pays</label>
                                    <ng-select formControlName="country" (change)="onCountryChangeSearch($event)" [multiple]="true"
                                        [items]="paysList" bindLabel="name" bindValue="name" [notFoundText]="'Aucun élément trouvé'"
                                        [placeholder]="'Pays'" autoComplete>
                                    </ng-select>
                                    <app-feild-error [control]="requestFobForm.get('country')"></app-feild-error> 
                                </div>
                                <div class="col-lg-6 tools mb-5">
                                    <label for="poste" class="form-label">Ville</label>
                                    <ng-select formControlName="city" [items]="stateList" (change)="onMobilityChange('add')" [multiple]="true"
                                        bindLabel="name" bindValue="name" [placeholder]="'Ville'" [notFoundText]="'Aucun élément trouvé'"
                                        autoComplete>
                                    </ng-select>
                                    <app-feild-error [control]="requestFobForm.get('city')"></app-feild-error> 
                                </div>
                                <div class="col-lg-6 tools  mb-5">
                                    <label for="poste" class="form-label">Disponibilité</label>
                                    <ng-select formControlName="availability" bindValue="id" [items]="disponibilite" [notFoundText]="'Aucun élément trouvé'"
                                    bindLabel="name"
                                        bindValue="id" [placeholder]="'Disponibilité'" autoComplete>
                                    </ng-select>
                                    <app-feild-error [control]="requestFobForm.get('availability')"></app-feild-error> 
                                </div>
                                <div class="col-lg-6 tools mb-5">
                                    <label for="poste" class="form-label">Type de contrat</label>
                                    <ng-select formControlName="contract_type" [items]="contratRequest" [notFoundText]="'Aucun élément trouvé'" [multiple]="true"
                                        bindLabel="label" bindValue="id" [placeholder]="'Type de contrat'" autoComplete>
                                    </ng-select>
                                    <app-feild-error [control]="requestFobForm.get('contract_type')"></app-feild-error> 
                                </div>
                                <div class="col-lg-6 tools mb-5">
                                    <label for="poste" class="required form-label">Année d'éxperience</label>
                                    <input type="text" (keypress)="keyPress($event)" name="annee" id="annee" formControlName="years_of_experience"
                                        placeholder="Nombre d'années d'expérience " class="form-control mb-3">
                                    <app-feild-error [control]="requestFobForm.get('years_of_experience')"></app-feild-error> 
                                </div>
                                <div class="col-lg-6 tools mb-5">
                                    <label for="poste" class="form-label">TJM</label>
                                    <input type="text"  (keypress)="keyPress($event)" name="TJM" id="TJM" class="form-control mb-3"
                                        formControlName="tjm" placeholder="TJM">
                                    <app-feild-error [control]="requestFobForm.get('tjm')"></app-feild-error> 

                                </div>
                                <div class="col-lg-6 tools mb-5">
                                    <label for="poste" class="form-label">Salaire</label>
                                    <input type="text" name="salary" (keypress)="keyPress($event)" id="salary" formControlName="salary"
                                        class="form-control mb-3" placeholder="Salaire">
                                    <app-feild-error [control]="requestFobForm.get('salary')"></app-feild-error> 
                                </div>

                            </div>
                            <div class="row">
                                
                                    <div class="form-check">
                                       
                                            <input class="form-check-input ml-2" formControlName="profile_abroad" type="checkbox"
                                            id="flexCheckDefault2" />
                                            
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Possibilité profil à étranger ( intégration)
                                        </label>
    
                                    </div>
                                   
                                    &nbsp;&nbsp;
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value=""
                                            formControlName="passeport_talent" id="flexCheckChecked1" checked />
                                           
                                        <label class="form-check-label" for="flexCheckChecked1">
                                            Passeport talent
                                        </label>
                                    </div>
                                    
                                
                            </div>
                            &nbsp;&nbsp;
                            
                            <div class="row">
                                <div class="desc tools mb-5">

                                    <label for="poste" class="form-label" >Description</label>

                                    <quill-editor formControlName="description" [modules]="{ toolbar: toolbarOptions }"
                                   ></quill-editor>

                  <!-- <textarea name="desc" id="desc" placeholder="Description"
                                        formControlName="description" style="height:150px"
                                        class="form-control"></textarea> -->
                  <app-feild-error
                    [control]="requestFobForm.get('description')"
                  ></app-feild-error>
                </div>
              </div>

              <div class="row">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    [disabled]="true"
                  />
                  <label class="form-check-label" for="flexCheckDefault"
                    >Dédié au particulier</label
                  >
                </div>
                &nbsp;&nbsp;
                <div class="form-check">
                  <input
                    class="form-check-input ml-2"
                    formControlName="dedicated_to"
                    type="checkbox"
                    id="flexCheckDefault2"
                  />
                  <label class="form-check-label ml-2" for="flexCheckDefault2"
                    >Dédié aux ESN</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!--end::Form-->
        </div>

        <div class="modal-footer">
          <button
            type="reset"
            (click)="closeModal()"
            id="kt_customers_export_cancel"
            data-bs-dismiss="modal"
            class="footer-btn annuler me-3"
          >
            Annuler
          </button>
          <button
            [disabled]="!requestFobForm.valid"
            (click)="addRequest()"
            type="submit"
            id="kt_customers_export_submit"
            class="footer-btn confirmer"
          >
            <span class="indicator-label">Confirmer</span>
            <span class="indicator-progress"
              >Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span
            ></span>
          </button>
        </div>
      </form>

      <!--end::Modal body-->
    </div>
    <!--end::Modal content-->
    <!--end::Modal dialog-->
  </div>
</ng-template>
