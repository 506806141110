import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedRole,
  confirmDelete,
  deletedRole,
  existedRole,
  failedDelete,
  serverError,
} from '../../../../core/models/messages';
import { PermissionService } from '../../../../core/services/permission.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css'],
})
export class RolesComponent {
  /* string */
  searchElement: string = '';
  /* arrays */
  listRoles: any = [];
  listPermissions: any = [];
  /* formGroup */
  addRoleForm: FormGroup = this.createRoleForm();
  /* modal */
  modalRef?: BsModalRef;
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private permissionService: PermissionService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private router: Router
  ) {}
  ngOnInit() {
    this.getListRoles();
    // this.getListPermissions()
  }
  getListRoles() {
    this.spinner.show();
    this.permissionService
      .getListRole()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listRoles = Object.keys(res.data).map((key) => res.data[key]);
            console.log('list of roles', this.listRoles);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }

  // getListPermissions(){
  //     this.spinner.show()
  //     this.permissionService.getListPermissions().pipe(takeUntil(this.unsubscribeAll)).subscribe({
  //       next: (res) => {
  //         if (res.status == 200) {
  //           this.spinner.hide()
  //          this.listPermissions=res.data
  //         }
  //       },
  //       error: () => {
  //         this.spinner.hide()
  //         this.toastr.error(serverError)
  //       }
  //     })
  // }
  /* create add role form */
  createRoleForm(data?: any) {
    return this.formBuilder.group({
      name: [data ? data.name : '', [Validators.required]],
      permission_ids: [data ? data.permission_ids : [], [Validators.required]],
    });
  }
  addRole() {
    this.spinner.show();
    this.permissionService
      .addRole(this.addRoleForm.value)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.closeModal();
            this.toastr.success(addedRole);
          }
        },
        error: (err) => {
          this.spinner.hide();
          if (err?.error) {
            if (err.error?.message && err.error.message['name'])
              this.toastr.info(existedRole);
            else this.toastr.error(serverError);
          }
        },
      });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef!.hide();
    this.addRoleForm.reset();
  }
  /* open delete alert */
  openDeleteAlert(role: any) {
    Swal.fire({
      title: `${confirmDelete} le rôle ${role.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteRole(role.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  /* delete role */
  deleteRole(id: number) {
    this.spinner.show();
    this.permissionService
      .deleteRole(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.getListRoles();
            this.toastr.success(deletedRole);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* filter data on key up input */
  applyFilter() {
    let data = {
      search: this.searchElement.trim().toLowerCase(),
    };
    this.spinner.show();
    this.permissionService
      .searchRole(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listRoles = res.data;
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* go to details role */
  navigateToDetails(idRole: number) {
    this.router.navigate([
      '/acceuil/parametres/parametres-details-role',
      { idRole: idRole },
    ]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
