import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {

  iscategoriequestion:boolean=false
  @Output() setPages: EventEmitter<any> = new EventEmitter<any>();
  @Input() data: any[] = [/* Your data goes here */];
  pageSize: number = 5; // Number of items per page
  @Input() set currentPageList(value:number){
    this.currentPage=value
  } 
  @Input() set endexPageList(value:number){
    this.endItem=value
  } 
  currentPage: number = 1;
  pagedData: any[] = [];
  totalPages!: number;
  lastPage:boolean=false
@Input() set lengthData(value:number){
  console.log('valuaaaaaaaaaaa',value);
  console.log('endItem',this.endItem);
  
if(value){
  this.dataLength=value
  this.calculateTotalPages()
  if(this.lastPage || this.endItem>value) this.endItem=value
  else this.endItem=5
  // if(this.currentPage==1)this.endItem =5
}
} 
dataLength!:number
endItem:number=5
constructor(private cdRef: ChangeDetectorRef,private route:Router){}
  ngOnInit(): void {
    this.calculateTotalPages();
    this.updatePageData();
    this.iscategoriequestion = this.estCategorieQuestion();
  }
  ngAfterViewInit(): void {
    this.cdRef.detectChanges(); 
  }
  calculateTotalPages() {
    this.totalPages = Math.ceil(this.dataLength / this.pageSize);
  }

  updatePageData() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = +startIndex + +this.pageSize;
    this.pagedData = this.data.slice(startIndex, endIndex);
    this.setPages.emit({startIndex:startIndex,endIndex:endIndex,currentPage:this.currentPage,itemsPerPage:this.pageSize})
  }

  goToFirstPage() {
    this.lastPage=false
    this.currentPage = 1;
    this.endItem=this.pageSize
    this.updatePageData();
  }

  goToLastPage() {
    this.lastPage=true
    this.currentPage = this.totalPages;
    this.endItem = this.pageSize*this.currentPage
    if(this.endItem > this.dataLength) this.endItem=this.dataLength
    this.updatePageData();
  }

  prevPage() {
    if(this.lastPage){
      this.endItem=(this.currentPage*this.pageSize)-this.pageSize
    }
    if (this.currentPage > 1) {
      this.currentPage--;
      if(!this.lastPage)this.endItem =this.pageSize *this.currentPage
      this.updatePageData();
    }
  }
  
  nextPage() {
    this.lastPage=false
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.endItem = +this.endItem + +this.pageSize
      if(this.endItem > this.dataLength) this.endItem=this.dataLength
      this.updatePageData();
    }
  }

  onPageSizeChange() {
    this.lastPage=false
    this.calculateTotalPages();
    this.currentPage = 1; 
    this.endItem=this.pageSize
    if(this.endItem > this.dataLength) this.endItem=this.dataLength
    this.updatePageData();
  }
  calculateStartItem(): number {
    let startItem=(this.currentPage - 1) * this.pageSize + 1;
    // if(this.endItem < startItem) startItem=this.endItem
    return startItem
  }

  estCategorieQuestion(): boolean {
    const currentUrl = this.route.url;
    // Vérifier si l'URL commence par 'acceuil/modules/question-sous-categorie'
    return currentUrl.startsWith('/acceuil/modules/question-sous-categorie');
  }
}
