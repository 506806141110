<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_content" class="app-content flex-column-fluid">
  <!--begin::Content container-->
  <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div
      id="kt_app_toolbar_container"
      class="app-container container-fluid d-flex flex-stack"
    >
      <!--begin::Page title-->
      <div
        class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
      >
        <!--begin::Title-->
        <div class="d-flex align-items-center">
          <h1 class="page-heading">
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_138_53)">
                <path
                  d="M8.93572 6.21891L4.29275 3.10518C4.23449 3.06604 4.16094 3.06357 4.10021 3.09527C4.03752 3.12846 4 3.19236 4 3.2627V8.92349C4 8.9864 4.03209 9.04485 4.08343 9.07854L8.7274 12.1943C8.758 12.2146 8.79601 12.226 8.83156 12.226C8.86217 12.226 8.89277 12.219 8.92042 12.2037C8.98164 12.17 9.02063 12.1061 9.02063 12.0367V6.37643C9.01915 6.31352 8.98855 6.25408 8.93572 6.21891Z"
                  fill="#265D91"
                />
                <path
                  d="M14.5163 16.9783L9.87331 13.8646C9.81506 13.8259 9.7415 13.8215 9.68078 13.8552C9.61957 13.8869 9.58057 13.9503 9.58057 14.0206V19.6829C9.58057 19.7458 9.61265 19.8042 9.664 19.8389L14.308 22.9541C14.3386 22.9744 14.3766 22.9853 14.4121 22.9853C14.4427 22.9853 14.4733 22.9769 14.501 22.9631C14.5622 22.9299 14.5997 22.866 14.5997 22.7961V17.1358C14.5997 17.0734 14.5691 17.013 14.5163 16.9783Z"
                  fill="#265D91"
                />
                <path
                  d="M25.6627 3.50144C25.6029 3.46825 25.5308 3.46973 25.4726 3.50441L20.8281 6.3358C20.7728 6.36998 20.7383 6.4314 20.7383 6.49679V12.4424C20.7383 12.5118 20.7768 12.5757 20.838 12.6069C20.8656 12.6222 20.8963 12.6301 20.9269 12.6301C20.9629 12.6301 21.0004 12.6197 21.0305 12.5989L25.675 9.48372C25.7263 9.44904 25.7584 9.39059 25.7584 9.32669V3.66589C25.7584 3.59704 25.7219 3.53463 25.6627 3.50144Z"
                  fill="#265D91"
                />
                <path
                  d="M14.1867 2.78963C14.1882 2.72177 14.1531 2.65737 14.0939 2.6227L9.73624 0.0265969C9.68243 -0.00708651 9.6143 -0.00807717 9.55704 0.0196621L4.69684 2.4414C4.63563 2.47261 4.59564 2.53403 4.59268 2.60189C4.58823 2.66827 4.6218 2.73415 4.67858 2.76982L9.0219 5.54275C9.0525 5.56207 9.08706 5.57248 9.1231 5.57248C9.15222 5.57248 9.18283 5.56504 9.21048 5.55018L14.0865 2.95309C14.1462 2.9199 14.1852 2.857 14.1867 2.78963Z"
                  fill="#265D91"
                />
                <path
                  d="M25.2596 2.99965C25.2581 2.93129 25.2211 2.86789 25.1599 2.83668L20.5707 0.379774C20.5154 0.350053 20.4488 0.350054 20.3935 0.38126L15.8487 2.85105C15.789 2.88374 15.7505 2.94665 15.7505 3.01699C15.7505 3.08485 15.7875 3.14925 15.8472 3.18243L20.4616 5.76566C20.4907 5.78101 20.5223 5.78943 20.5529 5.78943C20.586 5.78943 20.6181 5.78101 20.6472 5.76417L25.1653 3.16708C25.2255 3.13191 25.2611 3.069 25.2596 2.99965Z"
                  fill="#265D91"
                />
                <path
                  d="M20.0772 13.8551C20.0164 13.8215 19.9429 13.8254 19.8841 13.8645L15.2417 16.9783C15.1888 17.0135 15.1567 17.0734 15.1567 17.1358V22.7966C15.1567 22.8664 15.1957 22.9303 15.2565 22.9635C15.2846 22.9774 15.3147 22.9863 15.3453 22.9863C15.3819 22.9863 15.4189 22.9744 15.4495 22.9546L20.0939 19.8394C20.1453 19.8047 20.1774 19.7463 20.1774 19.6834V14.0216C20.1774 13.9512 20.1384 13.8873 20.0772 13.8551Z"
                  fill="#265D91"
                />
                <path
                  d="M19.7028 13.3603C19.7042 13.2924 19.6692 13.228 19.6099 13.1934L15.0845 10.2718C15.0306 10.2391 14.9625 10.2376 14.9057 10.2654L9.99273 13.1364C9.93201 13.1676 9.89153 13.229 9.88906 13.2969C9.88462 13.3638 9.91819 13.4292 9.97496 13.4653L14.7172 16.5815C14.7478 16.6008 14.7818 16.6108 14.8184 16.6108C14.8475 16.6108 14.8781 16.6038 14.9057 16.5885L19.6025 13.5233C19.6623 13.4911 19.7013 13.4282 19.7028 13.3603Z"
                  fill="#265D91"
                />
                <path
                  d="M14.6466 3.50144C14.5869 3.46825 14.5148 3.46973 14.4566 3.50441L9.66993 6.08862C9.61464 6.1228 9.58008 6.18373 9.58008 6.24961V12.1952C9.58008 12.2646 9.61859 12.3285 9.6798 12.3602C9.70745 12.375 9.73805 12.383 9.76866 12.383C9.8047 12.383 9.84173 12.3726 9.87233 12.3518L14.7538 9.68631C14.8051 9.65164 14.8372 9.59319 14.8372 9.52879L14.7429 3.66589C14.7429 3.59704 14.7069 3.53463 14.6466 3.50144Z"
                  fill="#265D91"
                />
                <path
                  d="M20.1305 6.18869L15.4885 3.46528C15.4303 3.42664 15.3572 3.42169 15.296 3.45537C15.2348 3.48757 15.1963 3.55098 15.1963 3.62082L15.2481 9.49263C15.2481 9.55554 15.2802 9.6135 15.3311 9.64817L19.98 12.4949C20.0106 12.5152 20.0486 12.5266 20.0841 12.5266C20.1147 12.5266 20.1453 12.5177 20.173 12.5043C20.2342 12.4706 20.2717 12.4072 20.2717 12.3369V6.46757C20.2717 6.40466 20.1834 6.22287 20.1305 6.18869Z"
                  fill="#265D91"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_138_53"
                  x="0"
                  y="0"
                  width="29.7583"
                  height="30.9863"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_138_53"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_138_53"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>

            Modules
          </h1>
        </div>
        <!--end::Title-->
        <!--begin::Breadcrumb-->
        <ul
          class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1"
        >
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <a class="text-muted" routerLink="/">Accueil</a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">Modules</li>
          <!--end::Item-->
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->
      <!--begin::Actions-->

      <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
  </div>
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->
    <div class="card">
      <div class="title">
        <h4>Liste des Modules</h4>
      </div>
      <!--begin::Card header-->
      <div class="card-header border-0 pt-6 mb-4">
        <div class="card-title">
          <div class="input-group mb-3">
            <input
              type="text"
              data-kt-customer-table-filter="search"
              [(ngModel)]="searchElement" (keyup.enter)="applyFilter()"
              class="form-control form-control-solid ps-15"
              placeholder="Rechercher"
            />
            <div class="input-group-append">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                  fill="#7E7C7C"
                />
              </svg>

              <button
                class="input-group-text cursor-pointer"
                id="basic-addon2"
                (click)="applyFilter()"
              >
                <span>Rechercher</span>
              </button>
            </div>
          </div>
        </div>
        <!--begin::Card title-->
        <!--begin::Card toolbar-->
        <div class="card-toolbar">
          <!--begin::Toolbar-->
          <div
            class="d-flex justify-content-end"
            data-kt-customer-table-toolbar="base"
          >
            <!--begin::Filter-->

            <!--end::Filter-->
            <!--begin::Export-->

            <!--end::Export-->
            <!--begin::Add customer -->
            <button
              type="button"
              (click)="openModal(myModal); type = 'category'"
              class="btn ajouter"
            >
              + Ajouter
            </button>
            <!--end::Add customer-->
          </div>
        </div>
        <!--end::Card toolbar-->
      </div>
      <!--end::Card header-->
      <!--begin::Card body-->
      <div *ngIf="!isLoadSpinner" class="card-body pt-0">
        <!--begin::Table-->
        <div class="table-responsive" id="kt_customers_table">
          <table class="table align-middle gy-5" id="myTable">
            <thead>
              <tr
                class="text-start text-gray-400 fw-bold text-uppercase gs-0"
              >
                <th class="w-300px"><span>ICONE</span></th>
                <th class="w-300px">
                  <span
                    >NOM DU MODULE
                    <i
                      (click)="sortData('name_FR')"
                      class="fa-solid fa-sort cursor-pointer"
                    ></i
                  ></span>
                </th>
                <th class="w-300px"><span>LES SOUS MODULES</span></th>
                <th class="w-300px"><span>ACTION</span></th>
              </tr>
            </thead>
            <tbody class="fw-semibold text-gray-600">
              <tr *ngFor="let data of dataCategory">
                <td>
                  <img
                    *ngIf="data.icon && data.icon != ''"
                    src="{{ url + data.icon }} "
                    alt=""
                  />
                  <img
                    *ngIf="!data.icon || data.icon === ''"
                    src="/assets/media/logos/logo-avatar.jpg"
                    alt=""
                  />
                </td>
                <td>
                  <a
                    [routerLink]="[
                      '/acceuil/modules/details-categorie',
                      { idCat: data.id }
                    ]"
                    class="text-gray-600 text-hover-primary mb-1 cursor-pointer"
                    >{{ data.name_FR ? data.name_FR : "---" }}</a
                  >
                </td>
                <td class="">
                  <!--begin::Badges-->
                  <div class="badges">
                    <div
                      class="badge-modules"
                      *ngFor="let sub of data.sub_category"
                    >
                      <div class="badge badge-light-default">
                        {{ sub.name_FR ? sub.name_FR : "---" }}
                      </div>
                    </div>
                    <div class="add-module cursor-pointer">
                      <img
                        src="/assets/media/icons/icon-add.png"
                        (click)="
                          setModuleId(data, myModal); type = 'subCategory'
                        "
                        alt=""
                        width="30px"
                        href=""
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div class="tools">
                    <div class="dropdown">
                      <button
                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          width="19"
                          height="5"
                          viewBox="0 0 19 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 0 4.89478)"
                            fill="#265D91"
                          />
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 6.85254 4.89478)"
                            fill="#265D91"
                          />
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 13.7061 4.89478)"
                            fill="#265D91"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item"
                          (click)="navigateToDetails(data.id)"
                          >Voir détail</a
                        >
                        <a
                          class="dropdown-item"
                          (click)="
                            type = 'updateCategory'; setModuleId(data, myModal)
                          "
                          >Modifier</a
                        >
                        <a class="dropdown-item" (click)="swallDeleteCat(data)"
                          >Supprimer</a
                        >
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <!--end::Table body-->
          </table>
          <div
            class="alert alert-warning text-center mt-5"
            role="alert"
            *ngIf="dataCategory && !dataCategory.length"
          >
            <img
              src="/assets/media/logos/logo-no-data.png"
              alt=""
              class="mb-5"
            />
            <span class="empty-list"> La liste est vide </span>
          </div>
          <!-- <div class="spin" *ngIf="loadSpinnerTable &&!dataCategory.length">
                    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="ball-clip-rotate"
                      [fullScreen]="false">
                      <p style="color: white"> Chargement... </p>
                    </ngx-spinner>
                  </div> -->
                   
                </div>
                <!--end::Table-->
                <div class="pagination" *ngIf="dataCategory && dataCategory.length">
                    <app-pagination [data]="dataCategory" [currentPageList]="currentPage" [endexPageList]="endIndex"  [lengthData]="totalItems" (setPages)="getItems($event)">
                    </app-pagination>
                </div>
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->

    </div>
    <!--end::Content container-->
</div>
<ng-template #myModal let-modal>
  <!-- <div class="modal-dialog-centered"> -->
  <div
    class="modal-dialog-centred modal-content"
    id="addSubModule"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <!--begin::Modal content-->

    <form
      class="form"
      action="#"
      id="kt_modal_add_customer_form"
      data-kt-redirect="../../demo1/dist/apps/customers/list.html"
    >
      <!--begin::Modal header-->
      <div class="modal-header" id="kt_modal_add_customer_header">
        <!--begin::Modal title-->
        <h2 class="">
          {{
            type == "category"
              ? "Ajouter module"
              : type == "subCategory"
              ? "Ajouter sous module"
              : "Modifier module"
          }}
        </h2>
        <!--end::Modal title-->
        <!--begin::Close data-bs-dismiss="modal"-->
        <div
          id="kt_modal_add_customer_close"
          class="btn btn-icon btn-sm btn-active-icon-primary"
        >
          <i class="ki-duotone ki-cross fs-1" (click)="closeModal()">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body py-10 px-lg-17 tools"
        [formGroup]="subCatFormGroup"
      >
        <!--begin::Scroll-->
        <div
          class="me-n7 pe-7"
          id="kt_modal_add_customer_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_customer_header"
          data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
          data-kt-scroll-offset="300px"
        >
          <!--begin::Input group-->
          <!-- <div class="fv-row mb-7 img-update " *ngIf="type=='updateCategory'">
                            <img class="cursor-pointer img-profile" *ngIf="fileName==''" src="{{ url +icon }} " (click)="fileInput.click()" width="75px"
                               ><br>
                            <img class="cursor-pointer img-profile" *ngIf=" fileName!=''" [src]="selectedImage" alt=" " width="75px">
                            <br>
                            <small *ngIf="fileName==''" class="cursor-pointer">Cliquer sur l'image pour la modifier.</small>
                    
                            <input type="file" accept="image/*" accept=".jpeg, .jpg, .png" (change)="uploadFile($event)"
                                #fileInput style="display: none" formControlName="icon" id="inputFile">
                        </div> -->

          <div class="fv-row mb-7 img-update" *ngIf="type == 'updateCategory'">
            <!-- Affiche l'image si elle existe -->
            <img
              class="cursor-pointer image-preview"
              *ngIf="fileName == '' || fileExtention"
              src="{{ url + icon }}"
              (click)="fileInput.click()"
              alt="Select Image"
              width="70px"
              [style.width.px]="imageWidth"
              [style.height.px]="imageHeight"
            /><br />

            <!-- Affiche le bouton d'upload si le fichier n'est pas une image -->

            <!-- <div class="add-module cursor-pointer">
                                <img src="/assets/media/icons/icon-add.png"  *ngIf="fileName !== '' && !isImage()" (click)="fileInput.click()"
                                    alt=""
                                    width="30px" href="">
                            </div> -->

            <!-- Affiche l'image sélectionnée -->
            <img
              class="cursor-pointer image-preview"
              *ngIf="fileName !== '' && isImage()"
              [src]="selectedImage"
              alt="Select Image"
              (click)="fileInput.click()"
              [style.width.px]="imageWidth"
              [style.height.px]="imageHeight"
            />
            <!-- <img class="cursor-pointer image-preview" *ngIf="fileName && isImage()" [src]="selectedImage" alt="Select Image" (click)="fileInput.click()"> -->
            <!-- Affiche le message pour modifier l'image --><br />
            <small
              *ngIf="fileName == '' || fileName !== ''"
              class="cursor-pointer"
              >Cliquer sur l'image pour la modifier.</small
            >

            <!-- Input de type file caché -->
            <input
              type="file"
              accept="image/*"
              (change)="uploadFile($event)"
              #fileInput
              style="display: none"
              id="inputFile"
            />
            <span style="color: red" *ngIf="fileError"
              ><small>{{ fileError }} </small></span
            >
            <!-- <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}} </small></span> -->
          </div>

          <div class="fv-row mb-7">
            <!--begin::Label-->
            <label class="required fs-6 mb-2">{{
              type == "category" || type == "updateCategory"
                ? "Nom du module"
                : "Nom de sous module"
            }}</label>
            <!--end::Label-->
            <!--begin::Input-->
            <input
              type="text"
              class="form-control form-control-solid mb-3"
              formControlName="name_FR"
              [placeholder]="
                type == 'category' || type == 'updateCategory'
                  ? 'Nom du module'
                  : 'Nom de sous module'
              "
              name="module"
            />
            <app-feild-error
              [control]="subCatFormGroup.get('name_FR')"
            ></app-feild-error>
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <!--begin::-->

          <div class="fv-row mb-7" *ngIf="type != 'updateCategory'">
            <!--begin::Label-->
            <label class="fs-6 mb-2">Télécharger icone</label>
            <!--end::Label-->
            <!--begin::Input-->
            <input
              #inputFile
              type="file"
              name="cv"
              id="img-module"
              (change)="uploadFile($event)"
              class="form-control mb-3"
            />
            <!--end::Input-->
            <span style="color: red" *ngIf="fileError"
              ><small>{{ fileError }} </small></span
            >
            <span style="color: red" *ngIf="fileExtention"
              ><small>{{ fileExtention }} </small></span
            >
          </div>

          <!--end::Input group-->
          <!--begin::Input group-->
        </div>
        <!--end::Scroll-->
      </div>
      <!--end::Scroll-->
      <!--end::Modal body-->
      <!--begin::Modal footer-->
      <div class="modal-footer">
        <!--begin::Button-->
        <button
          type="reset"
          id="kt_modal_add_customer_cancel"
          (click)="closeModal()"
          class="me-3 footer-btn annuler"
        >
          Annuler
        </button>
        <!--end::Button-->
        <!--begin::Button-->
        <button
          type="submit"
          [disabled]="
            !subCatFormGroup.valid || fileError != '' || fileExtention != ''
          "
          (click)="confirm()"
          id="kt_modal_add_customer_submit"
          class="footer-btn confirmer"
        >
          <span class="indicator-label">Confirmer </span>
          <span class="indicator-progress"
            >Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span
          ></span>
        </button>
        <!--end::Button-->
      </div>
      <!--end::Modal footer-->
    </form>
  </div>
  <!--begin::Form-->

  <!--end::Form-->
  <!-- </div> -->
</ng-template>
<app-footer></app-footer>
