import { Injectable } from '@angular/core';
import { CrudService } from '../crud/crud.service';
import { ApiResponse } from '../../models/response-api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  private messageSentSubject: BehaviorSubject<void | null> =
    new BehaviorSubject<void | null>(null);

  getMessageSentObservable() {
    return this.messageSentSubject.asObservable();
  }

  sendMessage(id: any, message: string) {
    const formData = new FormData();
    formData.append('message', message);
    console.log('message of chat', message);
    return this._http
      .post<any>(`${this._base}/Chat/sendMessage?id=${id}`, formData)
      .pipe(tap(() => this.messageSentSubject.next(null)));
  }

  getMessagesEmitter(id: any) {
    return this._http.get<any>(
      `${this._base}/Chat/getMessagesBetweenUsers?id=${id}`
    );
  }

  getListUsers() {
    return this._http.get<any>(
      `${this._base}/Chat/listUsers?per_page=100+&page=1`
    );
  }

  deleteConversation(receiverId: number) {
    return this._http.delete(this._base + `/Chat/deleteDiscussion`, {
      params: {
        receiver_id: receiverId.toString(),
      },
    });
  }

  readMessage(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Chat/markMessageAsRead`,
      data
    );
  }

  searchListUser(data: any) {
    return this._http.post<any>(this._base + `/Chat/searchUser`, data);
  }
  searchListMessage(data: any) {
    return this._http.post<any>(
      this._base + `/Chat/searchInMessagesByUser`,
      data
    );
  }

  getListMessage() {
    return this._http.get<any>(`${this._base}/Chat/getMessagesByUser`);
  }
  getUsers() {
    return this._http.get<any>(`${this._base}/Chat/getUser`);
  }
  headerClass: string = '';

  setHeaderClass(className: string): void {
    this.headerClass = className;
  }

  getHeaderClass() {
    return this.headerClass;
  }
}
