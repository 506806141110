import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

function disableConsoleLogs() {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.info = () => {};
  console.debug = () => {};
}

disableConsoleLogs();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
