import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientFinalRoutingModule } from './client-final-routing.module';
import { ClientFinalComponent } from './client-final.component';
import { ListIntervenantComponent } from './components/list-intervenant/list-intervenant.component';
import { ListConsultantComponent } from './components/list-consultant/list-consultant.component';
import { DetailsClientComponent } from './components/details-client/details-client.component';
import { AppelOffreComponent } from './components/appel-offre/appel-offre.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationModule } from '../../../../shared/components/pagination/pagination.module';
import { PipesModuleModule } from '../../../../core/pipes/pipes-module.module';
import { FieldErrorModule } from '../../../../shared/components/shared-field-error/field-error.module';
import { UnitaryAddCompanyComponent } from '../unitary-add-company/unitary-add-company.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { ListEsnRoutingModule } from '../list-esn/list-esn-routing.module';
import { DetailClientReviewComponent } from './components/detail-client-review/detail-client-review.component';
import { AvisclientComponent } from './components/avisclient/avisclient.component';
import { QuillModule } from 'ngx-quill';
import { ListprojetsComponent } from './components/listprojets/listprojets.component';
import { ListsalariesComponent } from './components/listsalaries/listsalaries.component';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [
    ClientFinalComponent,
    ListIntervenantComponent,
    ListConsultantComponent,
    DetailsClientComponent,
    AppelOffreComponent,
    DetailClientReviewComponent,
    AvisclientComponent,
    ListprojetsComponent,
    ListsalariesComponent,
  ],
  imports: [
    CommonModule,
    ClientFinalRoutingModule,
    ListEsnRoutingModule,
    NgxSpinnerModule,
    PaginationModule,
    PipesModuleModule,
    FieldErrorModule,
    UnitaryAddCompanyComponent,
    CalendarModule, 
    RatingModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        syntax: true,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction
          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ['clean'], // remove formatting button
        ],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ClientFinalModule {}
