

<div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->
    <div class=" mb-5 pb-8">
        <div class=" pb-15 pb-10">
            <div class="tab-content" id="kt_tab_pane_1">
                <div class="tab-pane fade active show " id="kt_tab_pane_1" role="tabpanel">
                    <!-- <div class="add pt-10 pb-15">
                        <a class="cursor-pointer" (click)="openModal(add_update_question,'create')">+ Ajouter
                            évaluation</a>
                    </div> -->
                    <div class="container reviews">
                        <div class="review mb-5" *ngFor="let item of listQuestion">
                            <div class="row">
                                <div class="col-lg-5 col-12"> <div class="comment">
                                    <span>
                                        {{item.question}}
                                    </span>
                                </div></div>
                                <div class="col-12 col-lg-2"> <div class="stars">
                                    <rating [(ngModel)]="item.average_stars" [titles]="[' ',' ',' ',' ',' ']" [max]="max" [readonly]="true"
                                        class="rating-stars"></rating>
                                </div></div>
                               
                                <div class="col"> <div class="pppl">
                                   <div class="group">
                                    <div class="card-body d-flex  justify-content-end pe-0">
                                        <!--begin::Title-->
                                        <!--end::Title-->
                                        <!--begin::Users group-->
                                        <div class="symbol-group symbol-hover flex-nowrap"
                                            *ngFor="let res of listComments.slice(0, 5) ;let i =index">
                                            <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip"
                                                title="Alan Warden">
                                                <span
                                                    class="symbol-label bg-warning text-inverse-warning fw-bold">{{res?.user&&res?.user?.first_name&&res?.user?.first_name[0]}}</span>
                                            </div>
    
                                        </div>
                                        <a *ngIf="listComments.length && totalComments>5"
                                            class="symbol symbol-35px symbol-circle" data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_view_users">
                                            <span
                                                class="symbol-label bg-light text-gray-400 fs-8 fw-bold">{{totalComments>5?'+':''}}
                                                {{totalComments>5?totalComments-5:''}}</span>
                                        </a>
    
    
                                        <!--end::Users group-->
                                    </div>
                                    <div class="more" *ngIf="listComments.length">
                                        <a class="cursor-pointer" (click)="navigateToListComment()" >+ {{totalComments>5?
                                            totalComments-5:totalComments}} commentaires</a>
                                    </div>
                                   </div>
                                   
                                </div></div>
                                <div class="col-12 col-lg-1"> 
                                <div class="actions">
                                    <a class="cursor-pointer" (click)="openModal(add_update_question,'update',item)">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 18.5315V6.6184C0 5.91628 0.280618 5.24291 0.780122 4.74644C1.27963 4.24996 1.9571 3.97104 2.6635 3.97104H10.8072L8.18694 6.6184H2.6635V18.5315H14.6493V13.0449L17.3128 10.4405V18.5315C17.3128 19.2337 17.0322 19.907 16.5326 20.4035C16.0331 20.9 15.3557 21.1789 14.6493 21.1789H2.6635C1.9571 21.1789 1.27963 20.9 0.780122 20.4035C0.280618 19.907 0 19.2337 0 18.5315ZM19.3104 0C18.7808 0.000463426 18.273 0.209956 17.8987 0.58242L8.43332 10.1427C8.14205 10.437 7.92227 10.7935 7.79075 11.1851L6.83189 14.0476C6.81991 14.0933 6.82018 14.1414 6.83266 14.1871C6.84514 14.2327 6.86941 14.2743 6.90306 14.3077C6.93671 14.3412 6.97856 14.3653 7.02448 14.3777C7.07039 14.3901 7.11877 14.3904 7.16482 14.3785L10.0547 13.4354C10.4487 13.3047 10.8075 13.0863 11.1035 12.7967L20.7221 3.38862C21.0011 3.11095 21.191 2.75732 21.268 2.3724C21.3449 1.98747 21.3054 1.58852 21.1543 1.22593C21.0033 0.86334 20.7475 0.553372 20.4193 0.335176C20.0911 0.116981 19.7052 0.000345183 19.3104 0Z"
                                                fill="#265D91" />
                                        </svg>
                                    </a>
                                    <div class="delete-icon">
                                        <svg data-bs-toggle="modal" (click)="openDeleteAlert(item)"
                                            data-bs-target="#add_FAQ" width="17" height="19" viewBox="0 0 17 19" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.9851 4.17508H15.6809C15.8655 4.17508 16.0425 4.10177 16.173 3.97127C16.3035 3.84078 16.3768 3.66379 16.3768 3.47924C16.3768 3.29469 16.3035 3.11769 16.173 2.9872C16.0425 2.8567 15.8655 2.78339 15.6809 2.78339H12.2017V2.08754C12.2 1.5344 11.9796 1.00438 11.5884 0.613252C11.1973 0.222119 10.6673 0.001652 10.1141 0H5.93906C5.38591 0.001652 4.8559 0.222119 4.46477 0.613252C4.07363 1.00438 3.85317 1.5344 3.85151 2.08754V2.78339H0.695847C0.511297 2.78339 0.334305 2.8567 0.203809 2.9872C0.0733121 3.11769 0 3.29469 0 3.47924C0 3.66379 0.0733121 3.84078 0.203809 3.97127C0.334305 4.10177 0.511297 4.17508 0.695847 4.17508H1.06813V14.6128C1.06813 15.5355 1.43469 16.4205 2.08717 17.073C2.73965 17.7255 3.62461 18.092 4.54736 18.092H11.5058C12.4286 18.092 13.3135 17.7255 13.966 17.073C14.6185 16.4205 14.9851 15.5355 14.9851 14.6128V4.17508ZM6.6349 13.2211C6.6349 13.4056 6.56159 13.5826 6.43109 13.7131C6.3006 13.8436 6.12361 13.9169 5.93906 13.9169C5.75451 13.9169 5.57751 13.8436 5.44702 13.7131C5.31652 13.5826 5.24321 13.4056 5.24321 13.2211V7.65432C5.24321 7.46977 5.31652 7.29278 5.44702 7.16228C5.57751 7.03178 5.75451 6.95847 5.93906 6.95847C6.12361 6.95847 6.3006 7.03178 6.43109 7.16228C6.56159 7.29278 6.6349 7.46977 6.6349 7.65432V13.2211ZM10.81 13.2211C10.81 13.4056 10.7367 13.5826 10.6062 13.7131C10.4757 13.8436 10.2987 13.9169 10.1141 13.9169C9.92959 13.9169 9.7526 13.8436 9.6221 13.7131C9.4916 13.5826 9.41829 13.4056 9.41829 13.2211V7.65432C9.41829 7.46977 9.4916 7.29278 9.6221 7.16228C9.7526 7.03178 9.92959 6.95847 10.1141 6.95847C10.2987 6.95847 10.4757 7.03178 10.6062 7.16228C10.7367 7.29278 10.81 7.46977 10.81 7.65432V13.2211ZM10.81 2.78339H5.24321V2.08754C5.24321 1.90299 5.31652 1.726 5.44702 1.5955C5.57751 1.46501 5.75451 1.39169 5.93906 1.39169H10.1141C10.2987 1.39169 10.4757 1.46501 10.6062 1.5955C10.7367 1.726 10.81 1.90299 10.81 2.08754V2.78339Z"
                                                fill="#EE6352" />
                                        </svg>
    
                                    </div>
                                </div>
                            </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div class="alert alert-warning text-center mt-5" role="alert"  *ngIf="listQuestion&&!listQuestion.length ">
                    <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                    <span class="empty-list">
                     La liste est vide
                 </span>
                 </div>
                <!-- <div class="pagination" *ngIf="listQuestion && listQuestion.length">
                    <app-pagination [data]="listQuestion" [lengthData]="totalItems"
                        (setPages)="getItems($event)"> </app-pagination>
                </div> -->
            </div>
        </div>
    </div>
    <!--end::Table-->
</div>
<div class="modal fade" id="add_update_Activitie_Type" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
            <!--begin::Modal header-->
            <div class="modal-header">
                <!--begin::Modal title-->
                <h2 class="">Type d’activité</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div id="kt_customers_export_close" data-bs-dismiss="modal"
                    class="btn btn-icon btn-sm btn-active-icon-primary">
                    <i class="ki-duotone ki-cross fs-1">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                </div>
            </div>
            <div class="modal-body">
                <!--begin::Form-->
                <form id="kt_customers_export_form" class="form tools" action="#">
                    <!--begin::Input group-->
                    <!--begin::Label-->
                    <div class="mb-5">
                        <label for="question" class="form-label">Nom du type d’activité</label>
                        <input type="text" class="form-control" placeholder="Nom du type d’activité" />
                    </div>
                </form>
                <div class="text-end ">
                    <button type="reset" id="kt_customers_export_cancel" data-bs-dismiss="modal"
                        class="footer-btn annuler me-3">Annuler</button>
                    <button type="submit" id="kt_customers_export_submit" class="footer-btn confirmer ">
                        <span class="indicator-label">Confirmer</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
                <!--end::Form-->
            </div>

            <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
</div>
<ng-template #add_update_question>
    <div class="modal-dialog-centered" id="add-eval" tabindex="-1" aria-hidden="true">
        <!--begin::Modal dialog-->
        <!--begin::Modal content-->
        <div class="modal-content">
            <!--begin::Modal header-->
            <div class="modal-header">
                <!--begin::Modal title-->
                <h2 class="">{{mode=='create'?'Ajouter':'Modifier'}} évaluation</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div id="kt_customers_export_close" data-bs-dismiss="modal"
                    class="btn btn-icon btn-sm btn-active-icon-primary">
                    <i class="ki-duotone ki-cross fs-1" (click)="closeModal()">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                </div>
            </div>
            <div class="modal-body">
                <!--begin::Form-->
                <form id="kt_customers_export_form" class="form tools" action="#" [formGroup]="questionForm">
                    <!--begin::Input group-->
                    <!--begin::Label-->
                    <div class="mb-5">
                        <label for="question" class="form-label">Question</label>
                        <input type="text" formControlName="question" class="form-control" placeholder="Question" />
                    </div>
                </form>
                <div class="text-end ">
                    <button (click)="closeModal()" type="reset" id="kt_customers_export_cancel" data-bs-dismiss="modal"
                        class="footer-btn annuler me-3">Annuler</button>
                    <button [disabled]="!questionForm.valid" (click)="submitData()" type="submit"
                        id="kt_customers_export_submit" class="footer-btn confirmer ">
                        <span class="indicator-label">Confirmer</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
                <!--end::Form-->
            </div>

            <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
        <!--end::Modal dialog-->
    </div>
</ng-template>