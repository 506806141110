import { Injectable } from '@angular/core';
import { CrudService } from '../crud/crud.service';
import { ApiResponse } from '../../models/response-api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  getGeneralStatic() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/getStatistic`
    );
  }

  /*get and search Statistic candidat overTime */
  getCandidatOverTime() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/getCandidatOverTime`
    );
  }
  searchCandidatOverTime(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Statistic/searchCandidatOverTime`,
      data
    );
  }
  /*get Statistic candidates */
  getCandidatesByCountry() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/candidatesByCountry`
    );
  }
  getAddedByAdmin() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/addedByAdmin`
    );
  }
  getregistrationCandidats() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/registrationCandidats`
    );
  }

  /*get and search Statistic ESN overTime */
  getEsnOverTime() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/getEsnOverTime`
    );
  }
  searchEsnOverTime(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Statistic/searchEsnOverTime`,
      data
    );
  }
  /*get Statistic ESN */
  getEsnByCountry() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/EsnByCountry`
    );
  }
  getAddedEsnByAdmin() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/esnAddedByAdmin`
    );
  }
  getRegistrationEsn() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/registrationEsn`
    );
  }

  /*get and search Statistic Client overTime */
  getClientOverTime() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/getClientOverTime`
    );
  }
  searchClientOverTime(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Statistic/searchClientOverTime`,
      data
    );
  }
  /*get Statistic ESN */
  getClientByCountry() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/clientByCountry`
    );
  }
  getAddedClientByAdmin() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/clientAddedByAdmin`
    );
  }
  getRegistrationClient() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Statistic/registrationClient`
    );
  }
}
