import { Component, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedQuestion,
  confirmDelete,
  deletedQuestion,
  existedQuestion,
  existednamefamille,
  failedDelete,
  serverError,
  updatedQuestion,
} from '../../../../../core/models/messages';
import { IQuestion } from '../../../../../core/models/setting';
import { SettingsService } from '../../../../../core/services/settings/settings.service';
import Swal from 'sweetalert2';
import { PageEvent } from '@angular/material/paginator';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-evaluation-default',
  templateUrl: './evaluation-default.component.html',
  styleUrls: ['./evaluation-default.component.css'],
})
export class EvaluationDefaultComponent {
  expandedIndex: number | null = null;
  listSubject: subject[] = [];
  pageSize = 5;
  pageSizeQuestion = 5;
  currentPage = 0;
  currentPageQuestion = 0;
  totalElements = 0;
  totalElementsQuestion = 0;
  groupedArrayQuestion: { family_id: number; questions: any[] }[] = [];
  type = [
    { id: 1, name: 'Annuelle' },
    { id: 2, name: 'Suivi' },
  ];
  /* string */
  mode: string = 'create';
  /* numbers */
  idQuestion!: number | undefined;
  content : any;
  idEvaluation!: number;
  delet: boolean = false;
save: boolean = false;
chapitreIdNameMap: { [key: string]: string } = {};
  /* modal */
  modalRef?: BsModalRef;
  sortAttr: string = '';
  formchapitre: FormGroup = this.createchapitreForm();
  searchForm: FormGroup = this.createSearchForm();
  id!: number;
  max: number = 5;
  currentSortDirection: number = 2;
  itemsPerPage: number = 5
  totalItems!: number
  totalItems1!: number
  pagination : boolean =false;
  pageSizeOptions = [5, 10, 20];
  pageSizeOptionsQuestion = [5, 10];
  startIndex: number = 0
  endIndex: number = 5;
  listnote: any;
  name: any;
  listQuestion: any;
  qt: any;
  step_question : any;
  step_family: any;
  questionId: any;
  changesList : any[] = [];
  extractedQuestions :any [] =[];
  displayUpdatequestion : boolean =false;
  /* array */
  // listQuestion:IQuestion[]=[]
  /* formGroup */
  questionForm: FormGroup = this.createQuestionForm();
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  issujet:boolean=false;
  constructor(private settingService: SettingsService, private toastr: ToastrService,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private modalService: BsModalService, private activatedRoute: ActivatedRoute,
    private Familleservice: SettingsService,private router: Router) { }
    drop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.listQuestion, event.previousIndex, event.currentIndex);
      this.listQuestion.forEach((subject: any, index: number) => {
        subject.step_family = index + 1;
        const eventMock = {
            previousIndex: event.previousIndex,
            currentIndex: event.currentIndex
        } as CdkDragDrop<string[]>;
        this.dropQuestions(eventMock, index);
    });
    }
    dropQuestions(event: CdkDragDrop<string[]>, subjectIndex: number) {
      this.displayUpdatequestion = true;
      const subject = this.listQuestion[subjectIndex];
      if (!subject) return;
      moveItemInArray(subject.questions, event.previousIndex, event.currentIndex);
      subject.questions.forEach((question:any, index:any) => {
          question.step_question = index + 1; 
          question.step_family = subject.step_family;
      });
      const updatedSubject = {
          family_id: subject.family_id,
          family_name: subject.family_name,
          step_family: subject.step_family, 
          questions: subject.questions.map((q:any) => ({
              id: q.id,
              content: q.content,
              template_id: q.template_id,
              type_response: q.type_response,
              archived: q.archived,
              family_id: q.family_id,
              created_at: q.created_at,
              updated_at: q.updated_at,
              step_question: q.step_question,
              step_family: q.step_family,
              eval_family_esn: q.eval_family_esn
          }))
      };
      const existingIndex = this.changesList.findIndex(s => s.family_id === subject.family_id);
      if (existingIndex !== -1) {
          this.changesList[existingIndex] = updatedSubject;
      } else {
          this.changesList.push(updatedSubject);
      }
      this.extractedQuestions = this.changesList.flatMap(subject => 
        subject.questions.map((q:any) => ({
          question_id: q.id,
            step_question: q.step_question,
            step_family: q.step_family
        }))
    );
    }
  /* init */
  ngOnInit() {
    this.getListQuestion()
    this.applyFilter()
    this.getId()
    this.getSubjects()
  }

  /* get id evaluation from route */
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idEvaluation = params.params['idEvaluation'];
        this.getListQuestion();
      },
    });
  }
  openModalEval(template: TemplateRef<any>, data?: any) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    if (data) {

      this.id = data.id;
      this.formchapitre = this.createchapitreForm(data);
    }
  }
  openModal(template: TemplateRef<any>, mode: string, data?: IQuestion) {
    this.mode = mode;
    if (mode == 'update') {
      this.idQuestion = data?.id;
      this.questionForm = this.createQuestionForm(data);
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef?.hide();
    this.formchapitre.reset();
    this.questionForm.reset();
  }
  closeModalChapitre() {
    this.modalRef?.hide();
    this.formchapitre.reset();
  }
  
  getListQuestion() {
    this.spinner.show();
    this.settingService
      .listQuestion(this.idEvaluation)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listQuestion =  res.data ? res.data : [];
            this.totalElementsQuestion = this.listQuestion.length;
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  updateStep(){
    let data = {
      updates: this.extractedQuestions
    };
    this.spinner.show();
    this.settingService
        .updateMultipleSteps(data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {                
              this.getListQuestion();
            }
          },
          error: (err) => {
            this.spinner.hide();
            this.listQuestion = []
            this.totalElementsQuestion = 0
            if (err?.error) {
              this.toastr.error(serverError);
            }
          },
        });
}
resetupdate(){
  this.getListQuestion()
}
  getFamilyName(family_id: number): string {
    const subject = this.listSubject.find(item => item.id === family_id);
    return subject ? subject.name : '---';
  }

  chapitreList: { name: string; id: number }[] = [];
  updateIntervenant() {
    if (this.formchapitre.valid) {
      this.spinner.show();
      this.formchapitre.get('id')?.setValue(this.id);

      this.Familleservice.updateFamille(this.id, this.formchapitre.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.getSubjects();
            this.applyFilter();
            this.getListQuestion();
            this.toastr.success('La famille a été modifiée avec succès.');
            this.closeModal();
            this.formchapitre.reset();
          },
          error: (err) => {
            if (err.error.status==422) {
              this.toastr.error(existednamefamille);        
            } else {
              this.toastr.error(
                'Erreur du serveur lors de la modification de la famille.'
              );
            }
            this.spinner.hide();
          },
        });
    }
  }
  applyFilter(pageNumber : number = this.currentPage) {
    let data = {
      search: this.searchForm.value.name,
      per_page: this.pageSize,
      page: pageNumber + 1,
    };
    this.getSubjects();
    this.spinner.show();
    this.Familleservice.listFamille(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (!res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.applyFilter();
          } else {
            this.chapitreList = res.data.data;
            this.totalElements = res.data.total;
            const nameSubjectList = res.data?.data.map((item:any)=>({
              name: item.name,
              id: item.id,
            }))
            this.totalItems = res.data.total;
            this.spinner.hide();
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  reset() {
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    this.getSubjects();
    this.applyFilter();
  }
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      name: [data ? data.name : ''],
    });
  }
  createChapitre() {
    if (this.formchapitre.valid) {
      this.spinner.show();
      this.Familleservice.createfamilleevaluation(this.formchapitre.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            this.applyFilter();
            this.spinner.hide();
            this.toastr.success("Sujet d'évaluation a été ajouté avec succès.");
            this.closeModal();
            this.formchapitre.reset();
          },
          error: (err) => {
            if (err.error.status==422) {
              this.toastr.error(existednamefamille);
            } else {
              this.toastr.error(
                'Erreur du serveur lors de la création du chapitre.'
              );
            }
            this.spinner.hide();
          },
        });
    }
  }
  deletechapitre(id: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.Familleservice.deletefamille(id).subscribe({
          next: (res) => {
            if (res.status === 200) {
              this.toastr.success("Sujet d'évaluation  a été supprimé.");
              this.applyFilter();
            }
          },
          error: (err) => {
            this.spinner.hide();
            this.toastr.error('Cette famille a des questions associées et ne peut pas être supprimée');
          },
        });
      }
    });
  }
  /* create add question form */
  createQuestionForm(data?: any) {
    return this.formBuilder.group({
      question: [
        data ? data.content : '',
        [Validators.required, this.notOnlySpacesValidator()],
      ],
      typeResponse: [data ? data.type_response : '', [Validators.required]],
      family_id: [
        data ? data.family_id : null,
        [Validators.required],
      ],
      default_review_id: [''],
    });
  }
  notOnlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value as string;
      const isValid = value?.trim() !== '';
      return isValid ? null : { onlySpaces: true };
    };
  }
  /* change tabulation */
  // changeTabulation(typeCompany:number){
  // this.typeCompany=typeCompany
  // this.getListQuestion()
  // }
  /* confirm click either to add or to update question */
  submitData() {
    if (this.mode == 'create') {
      this.addQuestion();
    } else {
      console.log('ttt value radio selected',this.questionForm.get('typeResponse')?.value)
      this.updateQuestion();
    }
  }
  /* add question */

  addQuestion() {
    let data = {
      family_id:this.questionForm.value.family_id,
      type_response:this.questionForm.value.typeResponse,
      content:this.questionForm.value.question
    }
    if (this.questionForm.valid) {
      this.questionForm.get('default_review_id')?.setValue(this.idEvaluation);
      this.spinner.show();
      this.settingService
        .createQuestion(data,this.idEvaluation)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status === 200) {
              this.getListQuestion()
            }
          },
          error: (err) => {
            this.spinner.hide();
            this.handleError(err);
          },
        });
     }
    const question = this.questionForm.value;
    const subject = question.chapter_default_review_id;
    if (!this.questionsGroupedBySubject[subject]) {
      this.questionsGroupedBySubject[subject] = [];
    }
  
    this.questionsGroupedBySubject[subject].push(question);
    this.questionForm.reset();
    this.toastr.success('Question ajoutée temporairement');
    this.closeModal();
  }
  savelistQuestion() {
    const questionsToSave: any[] = [];
    for (const subjectId in this.questionsGroupedBySubject) {
      if (Object.prototype.hasOwnProperty.call(this.questionsGroupedBySubject, subjectId)) {
        const questions = this.questionsGroupedBySubject[subjectId];
        questions.forEach(question => {
          questionsToSave.push({
            chapter_default_review_id: subjectId,
            question: question.question,
            typeResponse:question.typeResponse
          });
        });
      }
    }
  
    // Prepare the payload to send to the server
    const payload = {
      default_review_id: this.idEvaluation,
      questions: questionsToSave
    };
    this.questionForm.get('default_review_id')?.setValue(this.idEvaluation);
    this.spinner.show();
    this.settingService
        .addQuestionEvaluation(payload)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status === 200) {
              this.handleSuccess();
            }
          },
          error: (err) => {
            this.spinner.hide();
            this.handleError(err);
          },
        });
  }
  getSubjects() {
    let data = {
      search: this.searchForm.value.name,
      per_page: this.itemsPerPage,
      page: 20,
    };
    this.spinner.show();
    this.Familleservice.getAllSubjects()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {          
            this.listSubject = res.data ? res.data : []
          }
        },
        error: (err) => {
          this.listSubject = []
          if (err?.error) {
            this.toastr.error("La liste des sujets n'a pas pu être renvoyée");
          }
        },
      });
  }
  questionsTempList: any[] = [];
  questionsGroupedBySubject: { [key: string]: any[] } = {};

  createoneQuestion() {
    const question = this.questionForm.value;
    const subject = question.chapter_eval_id; // Assuming 'subject' is a property in your form
  
    if (!this.questionsGroupedBySubject[subject]) {
      this.questionsGroupedBySubject[subject] = [];
    }
  
    this.questionsGroupedBySubject[subject].push(question);
    this.questionForm.reset();
    this.closeModal();
  }
  messageupdateavantenregister(){
    if(!this.save)
      {
  this.toastr.info("Enregistrer vos questions avant de le modifier");
      }
  }
  messagedeleteavantenregister(){
    if(!this.delet)
      {
  this.toastr.info("Enregistrer vos questions avant de le supprimer");
      }
  }


  createchapitreForm(data?: any) {
    return this.formBuilder.group({
      name: [data && data.name, [Validators.required]],
    });
  }
  private handleSuccess() {
    this.getListQuestion();
    this.closeModal();
    this.spinner.hide();
    this.toastr.success(addedQuestion);

    this.navigateToTemplate();

    this.questionsGroupedBySubject = {}; // Clear the grouped questions after saving
    this.save=true
    this.delet=true
  }
  navigateToTemplate() {
    this.router.navigate([
      '/acceuil/parametres/evaluation-par-defaut']);
  }
  private handleError(err: any) {
    if (
      err?.status === 422 &&
      err?.error?.message?.question?.[0] ===
        'The question has already been taken.'
    ) {
      this.toastr.info(existedQuestion);
    } else if (
      err?.status === 422 &&
      err?.error?.message?.question?.[0] ===
        'The question must not be greater than 4000 characters.'
    ) {
      this.toastr.error('La question ne doit pas dépasser 4000 caractères.');
    } else {
      this.toastr.error('Server error');
    }
  }
  updateQuestion() {
    if (this.questionForm.valid) {
      this.spinner.show();
      this.questionForm.get('default_review_id')?.setValue(this.idEvaluation);
      let data = {
        family_id:this.questionForm.value.family_id,
        type_response:this.questionForm.value.typeResponse,
        content:this.questionForm.value.question
      }
      this.settingService
        .updateQuestionEvaluation(this.idQuestion,data )
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.getListQuestion();
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(updatedQuestion);
            }
          },
          error: (err) => {
             console.log('err', err);

            this.spinner.hide();
            if (err?.error) {
              if (
                err.error?.message &&
                err.error?.message?.question?.[0] ===
                  'The question has already been taken.'
              )
                this.toastr.info(existedQuestion);
              if (
                err.error.message?.question?.[0] ===
                'The question must not be greater than 4000 characters.'
              ) {
                this.toastr.error(
                  'La question ne doit pas dépasser 4000 caractères.'
                );
              }
               else this.toastr.error(serverError)
            }
          },
        });
    }
  }
  /* open delete alert */
  openDeleteAlert(id: number) {
    Swal.fire({
      title: `${confirmDelete} cette question ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteQuestion(id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  navigateToTemplateEval() {
    this.router.navigate([
      '/acceuil/parametres/evaluation-par-defaut',
    ]);
  }
  /* delete question */
  deleteQuestion(id: any) {
    this.spinner.show();
    this.settingService
      .deleteQuestionEvaluation(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListQuestion();
            this.spinner.hide();
            this.toastr.success(deletedQuestion);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Empêche le menu contextuel par défaut
  }
   onPageChange(e: PageEvent): void {
      this.pageSize = e.pageSize;
      this.currentPage = e.pageIndex;
      this.applyFilter();
  }

  onPageChangeQuestion(e: PageEvent): void {
    this.pageSizeQuestion = e.pageSize;
    this.currentPageQuestion = e.pageIndex;
    this.getListQuestion();
  }
}
interface subject {
  id: number;
  name: string;
}