export const welcomeMessage = 'Bienvenue sur votre espace Wegestu.'
export const serverError = 'Une erreur est survenue.'
export const noAccountMail = 'Pas de compte associé à cette adresse e-mail.'
export const invalidMailPassword = 'Nous n\'avons pas trouvé de compte avec ces informations d\'identification. Veuillez réessayer.'
export const emailSended = 'Email envoyé avec succès.'
export const sessionExpired = 'session expirée.'
export const permissionDenied = 'Vous n\'avez pas le droit d\'exécuter cette action.'
export const logOutUser = 'Utilisateur déconnecté avec succès.'
export const confirmDelete = 'Voulez-vous vraiment supprimer'
export const successDelete = ' L\'intervenant a été supprimé avec succès.'
export const successDeletecategori = ' Module a été supprimé avec succès.'
export const successDeletesouscategori = ' Sous Module a été supprimé avec succès.'
export const successDeletequestion = ' Question a été supprimé avec succès.'
export const failedDelete = 'La suppression a échoué'
export const obligationSelect = 'Vous devez sélectionner au moins un candidat.'
export const noCvSelected = 'Les candidats sélectionnés ne disposent pas de CV.'
export const noTestFileSelected = 'Les candidats sélectionnés ne disposent pas des réponses.'
export const createdCandidate = 'Le candidat a été ajouté avec succès.'
export const emailExist = 'L\'adresse e-mail a déjà été utilisée.'
export const emailExistpro = 'L\'adresse e-mail professionnel a déjà été utilisée.'
export const personalEmailExist = 'L\'adresse e-mail personnel a déjà été utilisée.'
export const validEmail = 'Email non vérifier.'
export const logoutMessage = 'Voulez-vous vraiment vous déconnecter de votre espace Wegestu ?'
export const candidateLoginMessage = 'Pas de compte admin associé a cette adresse e-mail.'
export const changedPassword = 'Votre mot de passe a été changé avec succès.'
export const addedSubCategorie = 'Le sous module a été ajouté avec succès.'
export const addedCategorie = 'Le module a été ajouté avec succès.'
export const updatedCategorie = 'Le module a été modifié avec succès.'
export const updatedSubCategorie = 'Le sous module a été modifié avec succès.'
export const existedSubCategory = 'Un sous module comprenant ce nom existe déja.'
export const existedCategory = 'Un module comprenant ce nom existe déja.'
export const EmptyCV = 'Ce candidat ne disposant pas d\'un curriculum vitae.'
export const emptyFileTest = 'Ce candidat ne disposant pas d\'un fichier test.'
export const invalidCode = 'Code incorrecte.'
export const expiredCode = 'Code expiré.'
export const changePasswordSuccess = 'Le mot de passe a été changé avec succès.'
export const maxSizeFile = 'Le fichier à importer ne doit pas depasser 2 méga !'
export const fileTypeExtention = 'Format fichier non compatible !'
export const successEditProfile = 'Les informations du candidat ont été modifiées avec succès.'
export const successEditProfileadmin = 'Admin a été modifié avec succès.'
export const succesdisassociateConsultant = 'Consultant dissocier avec succés.'
export const passwordsCompare = "L’ancien et le nouveau mot de passe ne peuvent pas être les mêmes."
export const incorrectOldPassword = "L’ancien mot de passe est incorrect."
export const successAddSkills = 'Compétence a été ajouté avec succès.'
export const addedCompany = 'La société a été ajoutée avec succès.'
export const addedadmin = 'L’admin a été ajoutée avec succès.'
export const updatedCompany = 'La société a été modifiée avec succès.'
export const addedIntervenant = 'L\'intervenant a été ajouté avec succès.'
export const updatedIntervenant = 'L\'intervenant a été modifié avec succès.'
export const phoneNumberExist = 'Le numéro de téléphone a été déjà utilisé.'
export const phoneNumberInvalid = 'Le numéro de téléphone est invalide.'

export const linkdinExist = 'Le Linkedin a été déjà utilisé.'
export const iscv = 'Le document que vous essayez de sauvegarder est vide.'
export const iscvvalide = 'Le CV doit être un fichier de type : pdf, doc, docx.'
export const addQuestion = 'La question a été ajoutée avec succès.'
export const addmultipleQuestion = 'Les questions ont été ajoutées avec succès'
export const existedQuestion = 'La question existe déja'
export const existedResponse = 'Il n\'est pas possible d\'ajouter la même réponse à une question.'
export const addedFaq = 'FAQ a été ajouté avec succès.'
export const updatedFaq = 'FAQ a été modifié avec succès.'
export const deletedFaq = 'FAQ a été supprimé avec succès.'
export const addedTuto = 'Le tutoriel a été ajouté avec succès.'
export const updatedTuto = 'Le tutoriel a été modifié avec succès.'
export const deletedTuto = 'Le tutoriel a été supprimé avec succès.'
export const existedLinkTuto = 'Un tutoriel comprenant ce lien existe déja.'
export const existedTitleTuto = 'Un tutoriel comprenant ce titre existe déja.'
export const addedQuestion = 'La question a été ajoutée avec succès.'
export const updatedQuestion = 'La question a été modifiée avec succès.'
export const deletedQuestion = 'La question a été supprimée avec succès.'
export const deletedAvis = 'L\'avis a été supprimée avec succès.'
export const selectedResponse = 'Réponse choisi avec succès.'
export const existedJobRequest = 'Un appel d\'offre comprenant ce nom existe déja.'
export const addedJobRequest = 'L\'appel d\'offre a été ajouté avec succès.'
export const addedfamille = 'Sujet d\'évaluation a été ajouté avec succès.'
export const modiffamille = 'Sujet d\'évaluation a été modifiée avec succès.'
export const existednamefamille = 'Sujet d\'évaluation comprenant ce nom existe déja.'
export const existedFAQ = 'Un FAQ comprenant cette question existe déja.'
export const faqEmptyResponse = 'Vous devez ajouter une réponse valid.'
export const existedCompany = 'Une entreprise comprenant ce nom existe déja.'
export const existedAdmin = 'Le numéro de téléphone a été déjà utilisé.'
export const existedIntervenant = 'Un intervenant comprenant ce nom existe déja.'
export const existedLinkedIn = 'L\'url Linkedin existe déja.'
export const existedWebSite = 'Le site web existe déja.'
export const emailadmin = 'Email existe déja.'
export const existedWebSiteLinkedIn = 'Le site web et L\'url Linkedin existe déja.'
export const existedEvaluation = 'Évaluation existe déja.'
export const addedEvaluation = 'L\'évaluation a été ajoutée avec succès.'
export const updatedEvaluation = 'L\'évaluation a été modifiée avec succès.'
export const deletedEvaluation = 'L\'évaluation a été supprimée avec succès.'
export const existedIBAN = 'IBAN existe déja.'
export const existedCodeBic = 'Le code bic existe déja.'
export const existedName = 'Un compte bancaire portant ce nom existe déjà'
export const existedTva = 'Tva existe déja.'
export const createAccount = 'Le compte bancaire a été créé avec succès.'
export const updatedAccount = 'Le compte bancaire a été modifié avec succès.'
export const deletedAccount = 'Le compte bancaire a été supprimé avec succès.'
export const addedCra = 'L\'activité  a été ajoutée avec succès.'
export const updatedCra = 'L\'activité a été modifiée avec succès.'
export const deletedCra = 'L\'Activité a été supprimée avec succès.'
export const existedCra = 'L\'activité existe déjà.'
export const existedOffer = 'Abonnement existe déja.'
export const addedOffer = 'L\'abonnement a été ajouté avec succès.'
export const updatedOffer = 'L\'abonnement a été modifié avec succès.'
export const deletedOffer = 'L\'abonnement a été supprimé avec succès.'
export const activatedOffer = 'L\'abonnement a été activé avec succès.'
export const inactivatedOffer = 'L\'abonnement a été désactivé avec succès.'
export const addedRole = 'Le rôle a été ajouté avec succès.'
export const existedRole = 'Le rôle existe déja.'
export const deletedRole = 'Le rôle a été supprimé avec succès.'
export const existedManager = 'L\'entreprise a déjà un responsable.'
export const invalidSelectedDate = 'La date sélectionnée est dans le futur, veuillez choisir une autre date.'
export const checkRequiredInputs = 'Vous devez remplir les champs obligatoires !'
export const RequiredForm = 'Vous devez remplir tous les champs  !'
export const greaterTjm = 'TJM est supérieur au salaire brut souhaité.'
export const noThingChanged = 'Vous n\'avez effectué aucun changement.'
export const associatedIntervenant = 'L\'intervenant est déja associé à une autre entreprise.'
export const associatedIntervenant2 = 'L\'intervenant est déjà associé à cette entreprise.'
export const associatedIntervenant1 = 'cet e-mail existe déjà.'
export const SuccessAssign = 'Permission(s) assignée(s)/désassignée(s) avec succès.'
export const Successpage = 'le paramétrage de pages a été modifié avec succès.'
export const errorMessage = 'Le titre ne doit pas dépasser 255 caractères.'







