import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BankAccountsComponent } from './bank-accounts/bank-accounts.component';
import { TutoComponent } from './tuto/tuto.component';
import { ActivitieCraComponent } from './activitie-cra/activitie-cra.component';
import { FaqComponent } from './faq/faq-esn.component';
import { MailingContactComponent } from './mailing-contact/mailing-contact.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'accounts',
    pathMatch: 'full',
  },
  {
    path:'accounts',
    component:BankAccountsComponent
  },
  {
    path:'tutoriel',
    component:TutoComponent
  },
  {
    path:'faq',
    component:FaqComponent
  },
  {
    path:'activite-cra',
    component:ActivitieCraComponent
  },
  {
    path:'mailing',
    component:MailingContactComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeneralRoutingModule { }
