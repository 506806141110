export const patternEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
export const patternPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/
// export const urlRegExp = /^https?:\/\/(www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|(?!www\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/\S*)?$/i;
// const urlRegExp = /^(https?:\/\/)?(www\.)?[^\s/$.?#]+\.[^\s/.]{2,}(\/[^/]+)*$/i;
export const urlRegExp = /^(https?:\/\/)?(www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|(?!www\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/\S*)?$/i;


  export const linkedInRegExp = /^https:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;

export const linkedCompanyRegExp = /^https:\/\/(www\.)?linkedin\.com\/company\/[a-zA-Z0-9-]+\/?$/;

export const stringRegExp = /^[^0-9]*$/
export const youTubePattern = /^https:\/\/(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]+)(\S*)$/;
export const patternName = "[a-zA-Z ]*"
export const patternIBAN = /^FR[\dA-Z]{25}$/
export const patternBIC =  /^[A-Z]{6}[\dA-Z]{2}$/
// export const patternInfoUser = /^[0-9a-zA-ZÀ-ÖØ-öø-ÿ\s\-_\']+$/
export const patternInfoUser = /^[\s\S]{1,}$/;
export const patternMax255 = /^.{0,255}$/;



