import { Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
@Component({
  selector: 'app-historiqueabonnement',
  templateUrl: './historiqueabonnement.component.html',
  styleUrls: ['./historiqueabonnement.component.css']
})
export class HistoriqueabonnementComponent {
  company_id: any;
  listAbonnement: any;
  searchElement: string = '';
  cancelSubscriptionElement: string = '';
  totalItems!: number;
  subId: any;
  stripe_sub_id: any;
  modalRef?: BsModalRef;
  fileInvoice: any;
  private unsubscribeAll: Subject<void> = new Subject();
  startIndex: number = 0;
  endIndex: number = 5;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  pagination : boolean =false;

  constructor(
    private settingService: SettingsService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.getIdCompany();
   
  }

  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.company_id = params.params['id']
        this.getListAbonnement();
      }
    })
  }
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
        if( (this.startIndex !=0 || this.endIndex != 5) || this.pagination == true){
          this.pagination = true;
          this.getListAbonnement();
        }
    }
  }
  getListAbonnement() {
    this.spinner.show();
    this.settingService.getListAbonnement(this.company_id).subscribe({
      next: (response: any) => {
        if (response.data && response.data.data) {
          console.log('response.data', response.data);
          this.listAbonnement = response.data?.data;
          this.totalItems = response.data.total;
          this.spinner.hide();
        }
      },
      error: (error: any) => {
        console.error('Error fetching list abonnement: ', error);
        this.spinner.hide();
      },
    });
  }
  navigateToDetails(offerId: any) {
    this.router.navigate(['/offer-details', { abonnementId: offerId }]);
  }
  navigateToInvoiceHistory(offerId: any, subscription_id: any) {
    this.router.navigate([
      '/acceuil/entreprises/esn/factures',
      { abonnementId: offerId, subscription_id: subscription_id },
    ]);
  }
  applyFilter() {
    let data = {
      search: this.searchElement?.trim().toLowerCase(),
    };
    this.spinner.show();
    this.settingService
      .searchAbonnement(data, this.company_id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listAbonnement = res.data?.data;
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  SubId(subId: any, stripe_sub_id: any) {
    this.subId = subId;
    this.stripe_sub_id = stripe_sub_id;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef?.hide();
    this.cancelSubscriptionElement = '';
  }
  
    navigateToAddOffer(mode: string, id?: number) {
      let data = mode == 'create' ? { mode: mode } : { mode: mode, id: id };
      this.router.navigate(['/acceuil/parametres/ajout-abonnement', data]);
    }
}
