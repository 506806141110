import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralRoutingModule } from './general-routing.module';
import { BankAccountsComponent } from './bank-accounts/bank-accounts.component';
import { ActivitieCraComponent } from './activitie-cra/activitie-cra.component';
import { TutoComponent } from './tuto/tuto.component';
import { FieldErrorModule } from '../../../../shared/components/shared-field-error/field-error.module';
import { FaqComponent } from './faq/faq-esn.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationModule } from '../../../../shared/components/pagination/pagination.module';
import { MailingContactComponent } from './mailing-contact/mailing-contact.component';

@NgModule({
  declarations: [
    BankAccountsComponent,
    FaqComponent,
    ActivitieCraComponent,
    TutoComponent,
    MailingContactComponent,
  ],
  imports: [
    CommonModule,
    GeneralRoutingModule,
    FieldErrorModule,
    NgxSpinnerModule,
    PaginationModule,
  ],
})
export class GeneralModule {}
