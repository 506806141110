<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_1246_5488)">
                        <path d="M29.4039 6.27336C23.7017 5.61371 20.5709 3.99455 18.2138 0.504383C18.1038 0.348431 17.9583 0.221273 17.7895 0.133529C17.6207 0.0457841 17.4335 0 17.2436 0C17.0537 0 16.8665 0.0457841 16.6977 0.133529C16.529 0.221273 16.3835 0.348431 16.2734 0.504383C13.6544 3.94658 10.4284 5.63769 5.04761 6.27336C4.75725 6.30871 4.48997 6.45052 4.29676 6.67173C4.10354 6.89294 3.99792 7.17807 4.00003 7.47273V11.9704C4.00003 21.0016 9.10699 27.0105 16.5948 30.9564C16.784 31.0756 17.0026 31.1387 17.2257 31.1387C17.4489 31.1387 17.6675 31.0756 17.8567 30.9564C19.7614 29.6971 22.8565 28.054 24.4279 26.3988C26.3414 24.5316 27.8612 22.2944 28.8962 19.8214C29.9312 17.3483 30.4602 14.6903 30.4515 12.0064V7.46074C30.4507 7.16814 30.3437 6.88594 30.1508 6.66725C29.9578 6.44856 29.6922 6.30847 29.4039 6.27336ZM20.2375 26.6627L17.2257 28.5457C15.4231 27.5249 13.7035 26.3619 12.0831 25.0675C12.1958 24.3334 12.5164 23.6476 13.0062 23.0924C13.4961 22.5372 14.1345 22.1361 14.8449 21.9372C15.0544 21.8818 15.2449 21.7698 15.3959 21.6135C15.547 21.4571 15.6528 21.2621 15.702 21.0496C15.9639 20.0062 14.5116 19.5504 14.6663 18.5309V14.7769C14.6725 14.3081 14.8601 13.8602 15.1892 13.5286C15.5182 13.1971 15.9628 13.008 16.4282 13.0018H18.0472C18.5093 13.0112 18.9496 13.202 19.2742 13.5335C19.5988 13.8651 19.7822 14.3112 19.7852 14.7769V18.5309C19.9161 19.5624 18.4995 19.9942 18.7614 21.0376C18.8077 21.2509 18.9109 21.4473 19.0598 21.6058C19.2088 21.7643 19.3978 21.8789 19.6066 21.9372C20.321 22.1342 20.9623 22.5381 21.4511 23.0988C21.9399 23.6595 22.2547 24.3523 22.3565 25.0915C21.6976 25.6808 20.9885 26.2105 20.2375 26.6747V26.6627Z" fill="#265D91"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_1246_5488" x="0" y="0" width="34.4531" height="39.1388" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1246_5488"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1246_5488" result="shape"/>
                        </filter>
                        </defs>
                        </svg>
                        
                        Liste des factures
                </h1>

                <!--begin::Menu-->
              
            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a routerLink="/acceuil" class="text-muted ">Accueil</a>
                  </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted"> <a routerLink="/notre-compte" class="text-muted">Abonnements</a></li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <li class="breadcrumb-item text-muted">Liste des factures</li>
                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->
        
          <div class="card-body tools">
           <div class="title mb-15">
            <h4>Liste des factures </h4>
           </div>
          <div class="container">
            <div class="row mb-3">
                <div class="col-lg-3">
                    <form class="w-100 position-relative" autocomplete="off">
                        <!--begin::Icon-->
                        <i class="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </i>
                        <!--end::Icon-->
                        <!--begin::Input-->
                        <input type="text" class="form-control form-control-solid search px-13" name="search" value="" [(ngModel)]="searchElement"
                          placeholder="Rechercher " />
                        <!--end::Input-->
                    </form>
                </div>
                <div class="col-lg-3 ">
                      
                          </div> 
                          <div class="col-lg-3 ">
                      
                          </div> 
                <div class="col-lg-3">
                 
                   
                </div>
               
            </div>
         
            <div class="table-responsive mt-15">
                <table class="table align-middle  fs-6 gy-5 style-table" id="list_cra">
                    <thead>
                        <th class="min-w-125px">
                            <span>ID Facture
                            </span>
                        </th>
                        <th class="min-w-125px">
                            <span>Email client</span>
                        </th>
                        <th class="min-w-125px">
                           <span> Type</span>
                        </th>
                        <th class="min-w-125px">
                            <span>Status</span>
                        </th>
                        <th class="min-w-125px">
                            <span>Télécharger facture</span>
                        </th>
                    </thead>

                 <tbody>
                    <tr *ngFor="let invoice of listInvoice">

                        <td>
                            <span> {{invoice.id}} </span>
                        </td>

                    <td>
                        <span> {{invoice.customer_email}} </span>
                    </td>
                    <td>
                        <span>{{invoice.billing_reason}}</span>
                    </td>


<!--
    <td>
                        <span> {{invoice.abonnement_company.start_date}} </span>
                    </td>
                    <td>
                        <span>{{invoice.abonnement_company.end_date}}</span>
                    </td>
-->
                    <td>
                    <span class="badge encours">Active</span>
                    </td>
                    <td>
                        <a (click)="downloadInvoice(invoice.id)"><i class="fa-solid fa-cloud-arrow-down" style="color:#265D91;font-size: 20px;cursor: pointer;"></i></a>
                    </td>
                    </tr>

                 </tbody>
                </table>
             </div>
             <div class="alert alert-warning text-center mt-5" role="alert"
             *ngIf="listInvoice && !listInvoice.length">
             <img src="/assets/media/errors/no-data.png" alt="" class="mb-5">
             <span class="empty-list">
                 La liste est vide
             </span>
         </div>
          
          </div>
        
           
          </div>
        </div>
     
    </div>
    <!--end::Content container-->
</div>