import { Component, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../../../core/models/messages';
import { CompanyService } from '../../../../../../core/services/company/company.service';
import { environment } from '../../../../../../../environments/environment';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { CSearchCandidate } from '../../../../../../core/models/candidat';
import { SortDataService } from '../../../../../../core/services/sort-data/sort-data.service';
import { PermissionService } from '../../../../../../core/services/permission.service';

@Component({
  selector: 'app-listprojets',
  templateUrl: './listprojets.component.html',
  styleUrls: ['./listprojets.component.css'],
})
export class ListprojetsComponent {
  selectedIntervenant: any = {};
  /* string */
  searchElement: string = '';
  mode: string = 'create';
  url: string = environment.baseUrl + '/api';
  sortAttr: string = '';
  /* number */
  idCompany!: number;
  currentSortDirection: number = 2;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  clickCount: number = 0;
  /* any */
  listprojet: any = [];
  @Input() clientid!: any;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  separateDialCode: boolean = false;
  pagination : boolean =false;

  selectedCountryCode: string = 'FR';
  listRole: { id: number; name: string }[] = [];
  /* formGroup */
  phoneForm!: FormGroup;
  /* modal */
  modalRef?: BsModalRef;
  /* form group */
  searchFormGroup: FormGroup = this.createSearchForm(new CSearchCandidate());
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private sortDataService: SortDataService,
    private permissionService: PermissionService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}
  ngOnInit() {
    this.getIdCompany();
    this.getListRole();
    this.phoneForm = new FormGroup({
      phone: new FormControl(undefined),
    });
  }
  getListRole() {
    this.permissionService
      .getListRoleByCompany(this.idCompany)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listRole = res.data;
        },
        error: () => {},
      });
  }
  /* get company id from route */
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCompany = params.params['id'];
        this.getListprojet();
      },
    });
  }
  openModal(template: TemplateRef<any>, data: any) {
    this.selectedIntervenant = data;
    // this.selectedIntervenant.name=`${this.selectedIntervenant?.first_name} ${this.selectedIntervenant?.last_name}`
    console.log('selectedIntervenant', this.selectedIntervenant);
    // this.phoneForm.get('phone')?.setValue(data.phone_number)
    this.phoneForm.disable();
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef!.hide();
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') this.applyFilter();
      else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
        this.getListprojet();
      }
    }
  }
  /* sort data  */
  sortData(sortAttribute: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr=sortAttribute
    this.currentPage = 1;
    this.endIndex=5;
    if(this.searchElement !='') this.listprojet = this.sortDataService.sortArray(this.listprojet, sortAttribute, this.currentSortDirection)
    else this.getListprojet()
  }
  /* list list esn companies */
  getListprojet() {
    let dataPyload =
      this.sortAttr == ''
        ? {
            id: this.clientid,
            per_page: this.itemsPerPage,
            page: this.currentPage,
          }
        : {
            ...{
              id: this.clientid,
              per_page: this.itemsPerPage,
              page: this.currentPage,
            },
            ...{
              sort: this.currentSortDirection,
              sortAttribute: this.sortAttr,
            },
          };
    console.log('data', dataPyload);
    this.spinner.show();
    this.companyService.getListprojet(dataPyload).subscribe({
      next: (response) => {
        console.log('response projet', response);
        if (response.data && response.data.data) {
          this.listprojet=response.data.data.slice(0,this.endIndex)
          this.totalItems=response.data.total
          if(this.currentPage>1 && !response.data.data.length){
            this.currentPage=1
            this.getListprojet()
          }
          this.spinner.hide()
          console.log("list projet",this.listprojet)
        }
      },
      error: (error) => {
        this.spinner.hide();
        console.error('Error fetching projet: ', error);
      },
    });
  }
  /* search intervenant on input */
  applyFilter() {
    let data = {
      id: this.clientid,
      name: this.searchFormGroup.value.name,
      membre_name: this.searchFormGroup.value.membre_name,
      per_page: this.itemsPerPage,
      page: this.currentPage,
    };
    this.spinner.show();
    this.companyService
      .searchprojet(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          if (res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.applyFilter();
          } else {
            this.listprojet = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* create search form */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      name: ['', data?.name],
      membre_name: ['', data?.membre_name],
    });
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.searchFormGroup = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getListprojet();
    }
    return (this.isRecherche = false);
  }
  getRoleName(employeeRoleId: number): string {
    const role = this.listRole.find((role) => role.id === employeeRoleId);
    return role ? role.name : '---';
  }
  navigateToProfilCandidat(idCandidat: number) {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
