import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListCategoriesComponent } from './components/list-categories/list-categories.component';
import { QuestionCategoriesComponent } from './components/question-categories/question-categories.component';
import { DetailsCategoriesComponent } from './components/details-categories/details-categories.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'list-category',
    pathMatch: 'full',
  },
  {
    path:'list-category',
    component:ListCategoriesComponent,
  },
  {
    path:'question-sous-categorie',
    component:QuestionCategoriesComponent,
  },
  {
    path:'details-categorie',
    component:DetailsCategoriesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModulesRoutingModule { }
