import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main.component';
import { ProfilAdminComponent } from './profil-admin/profil-admin.component';
import { MessagesComponent } from './messages/messages.component';
import { ContactWegestuComponent } from './contact-wegestu/contact-wegestu.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ListAdminComponent } from './list-admin/list-admin.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NetworkStatusComponent } from './network-status/network-status.component';
const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      { path: 'reconnect', component: NetworkStatusComponent },

      {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'modules',
        loadChildren: () =>
          import('./modules/modules.module').then((m) => m.ModulesModule),
      },
      {
        path: 'parametres',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'offre-emploi',
        loadChildren: () =>
          import('./job-offer/job-offer.module').then((m) => m.JobOfferModule),
      },
      {
        path: 'entreprises',
        loadChildren: () =>
          import('./companies/companies.module').then((m) => m.CompaniesModule),
      },
      {
        path: 'candidats',
        loadChildren: () =>
          import('./candidat/candidat.module').then((m) => m.CandidatModule),
      },
      {
        path: 'appel-offre',
        loadChildren: () =>
          import('./tender/tender.module').then((m) => m.TenderModule),
      },
      {
        path: 'inbox',
        loadChildren: () =>
          import('./inbox/inbox.module').then((m) => m.InboxModule),
      },
      {
        path: 'profil',
        component: ProfilAdminComponent,
      },
      {
        path: 'chat',
        component: MessagesComponent,
      },
      {
        path: 'contact-wegestu',
        component: ContactWegestuComponent,
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
      },
      {
        path: 'list-admin',
        component: ListAdminComponent,
      },
      {
        path: '404',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
