export class CSubCategory{
  name_FR :string|undefined
  category_id  :string|undefined
  icon :string|undefined
  constructor(data?:CSubCategory){
    this.name_FR = data && data.name_FR ||""
    this.category_id = data && data.category_id ||""
    this.icon = data && data.icon ||""
}
}
export interface IQuestion{
  id:number,
  response_FR:string,
  question_FR:string,
  sub_category_id:number,
  time:string,
  response_question:{response_FR:string,state:number}[]
}
