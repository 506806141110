import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListOffreComponent } from './components/list-offre/list-offre.component';
import { CandidaturesComponent } from './components/list-offre/candidatures/candidatures.component';
import { DetailsOfferComponent } from './components/details-offer/details-offer.component';
import { AddbaremeComponent } from './components/add-bareme/addbareme.component';
import { VoirTestComponent } from './components/voir-test/voir-test.component';
import { ContactManagerComponent } from './components/contact-manager/contact-manager.component';
import { DossiercandidatureComponent } from './components/list-offre/dossiercandidature/dossiercandidature.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list-offres',
    pathMatch: 'full',
  },
  {
    path:'list-offres',
    component:ListOffreComponent,
  },
  {
    path:'candidatures/:id',
    component:CandidaturesComponent
  },
  {
    path:'dossier-candidature/:id/:id_offer',
    component:DossiercandidatureComponent
  },
  {
    path:'details-offer',
    component:DetailsOfferComponent
  },
  {
    path:'get-bareme/:jobId',
    component:AddbaremeComponent
  },
  {
    path:'get-test/:id',
    component:VoirTestComponent
  },
  {
    path:'contact-manager',
    component:ContactManagerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobOfferRoutingModule { }
