import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../core/services/authentication/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit{
  queryParams: any;

  constructor(private route: ActivatedRoute,private authService:AuthService){
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
      console.log('Query Parameters 1 :', this.queryParams);
      if(this.queryParams.login==='true'){
        console.log('Query Parameters 2 :', this.queryParams);
         localStorage.clear()
        this.authService.logOut()
      }
    });
  }
ngOnInit(): void {

}
}
