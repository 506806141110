import { Component, ElementRef, TemplateRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { CompanyService } from '../../../../core/services/company/company.service';
import { environment } from '../../../../../environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  addedCompany,
  existedCompany,
  existedLinkedIn,
  existedWebSite,
  existedWebSiteLinkedIn,
  fileTypeExtention,
  serverError,
} from '../../../../core/models/messages';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ICountry } from 'country-state-city';
import {
  linkedCompanyRegExp,
  patternEmail,
  patternPassword,
  urlRegExp,
} from '../../../../core/models/pattern';
import { SortDataService } from '../../../../core/services/sort-data/sort-data.service';
import { CandidatService } from '../../../../core/services/candidat/candidat.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { PaysService } from '../../../../shared/services/pays.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-list-societe',
  templateUrl: './list-societe.component.html',
  styleUrls: ['./list-societe.component.css'],
})
export class ListSocieteComponent {
  seeMore: boolean = false;
  star = 0;
  starOne = 0;
  startwo = 0;
  /* any */
  file: any;
  selectedImage: any;
  /* modal */
  modalRef?: BsModalRef;
  /* object */
  responsableCompany: any;
  /* arrays */
  listCampany: any = [];
  typesCompanies = [
    // { id: 1, name: 'Entreprise' },
    { id: 2, name: 'ESN' },
    { id: 3, name: 'Client Final' },
    { id: 4, name: 'Cabinet de Recrutement' },
  ]
  isLoadSpinner: boolean = true;

  today = new Date();
  currentDate!: string;
  minDate!: string;
  headerTable: { name: string, code: string }[] = this.companyService.headerCompanies()
  /* number */
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;

  /* string */
  mode: string = 'create';
  url: string = environment.baseUrl + '/api';
  fileError: string = '';
  fileExtention: string = '';
  fileName: string = '';
  sortAttr: string = '';
  selectedFile: File | null = null;
  fileBulkError: any;
  isDeleteDisabled: boolean = true;
  /* formGroup */
  companyForm: FormGroup = this.createCompanyForm();
  inscricompanyForm: FormGroup = this.inscricreateCompanyForm();
  searchForm: FormGroup = this.createSearchForm();
  companyType = new FormControl();
  /* form data */
  formData = new FormData();
  ispagination: boolean = true;
  pagination: boolean = false;

  search: boolean = false;
  paysList: ICountry[] = [];

  dataHeaderSort = [
    { name: 'ID', code: 'ID_company' },
    { name: 'Entreprise', code: 'name' },
    { name: 'Type', code: 'type' },
    { name: 'Admin', code: 'admin' },
    { name: 'Avis', code: 'average_note' },
    { name: 'Pays', code: 'country' },
    { name: 'Ville', code: 'city' },
    { name: 'Email', code: 'ContactEmail' },
    { name: 'N° Tel', code: 'ContactPhone' },
    { name: 'Nbre AO', code: 'nb_job_request' },
    { name: 'Nbre OE', code: 'nb_job_offer' },
    { name: 'Nbre CRA', code: 'nb_cra' },
    { name: 'Dernière connexion', code: 'last_connection' },
  ]

  data: any[] = [
    {
      competences: [
        'nom',
        'site_web',
        'linkedin',
        'taille_entreprise',
        'pays',
        'location',
        'domain',
        'annee_creation',
        'logo',
        'a_propos',
      ],
    },
  ];
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(private companyService: CompanyService, private elRef: ElementRef, private primengConfig: PrimeNGConfig,
    private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private modalService: BsModalService,
    private toastr: ToastrService, private router: Router, private sortDataService: SortDataService, private candidatService: CandidatService,
    private paysServices: PaysService) {
    const day = String(this.today.getDate()).padStart(2, "0");
    const month = String(this.today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = this.today.getFullYear();
    this.currentDate = `${year}-${month}-${day}`;
    // Set the minimum date to 10 years ago, for example
    const minYear = 1990;
    this.minDate = `${minYear}-${month}-${day}`;
  }

  ngOnInit() {
    this.isRecherche = false;
    this.paysList = this.paysServices.getAllCountries();
    console.log('headerTable', this.headerTable);
    this.getListElementHeader();
    this.getListCompanies();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
    this.primengConfig.setTranslation({
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer'
    });
  }

  getBackSearch() {
    this.spinner.show();
    this.companyService.searchResults$.subscribe((results) => {
      if (results && results.length > 0) {
        this.listCampany = results;
        this.searchForm = this.createSearchForm(this.listCampany.company);
        this.spinner.hide();
        console.log('listCampanyFromNgOnInit', this.listCampany);
      } else this.getListCompanies();
      this.spinner.hide();
    });
  }
  dataHeader: any = [];
  listNames: string[] = [];
  getListElementHeader() {
    this.candidatService
      .getHeaderElements(2)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.dataHeader = res.data;
          for (const iterator of this.dataHeader) {
            if (
              (iterator.checked == 'oui' && !iterator.checked_user) ||
              (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
              (iterator.checked == 'oui' && iterator.checked_user == 'oui')
            )
              this.listNames.push(iterator.name);
          }
          this.dataHeader.map((el: any) => {
            if (
              (el.checked == 'oui' && !el.checked_user) ||
              (el.checked_user == 'oui' &&
                (el.checked == 'non' || el.checked == 'oui'))
            ) {
              return (el.checkAdmin = true);
            } else {
              return (el.checkAdmin = false);
            }
          });
        },
        error: () => {
          this.toastr.error(serverError);
        },
      });
  }
  isHalfStar(index: number, averageNote: number): boolean {
    return index + 0.5 === averageNote && !Number.isInteger(averageNote);
  }
  deleteCompany(id: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.companyService.deletecompany(id).subscribe((res: any) => {
          console.log('response', res);
          this.toastr.success(
            'Entreprise a été supprimée avec succès.',
            'Supprimé !',
            {
              closeButton: true,
              progressBar: true,
              timeOut: 5000,
              extendedTimeOut: 2000,
            }
          );
          this.getListCompanies();
        });
      }
    });
  }

  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      Afghanistan: 'Afghanistan',
      'South Africa': 'Afrique du Sud',
      Albania: 'Albanie',
      Algeria: 'Algérie',
      Germany: 'Allemagne',
      Andorra: 'Andorre',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctique',
      'Antigua and Barbuda': 'Antigua-et-Barbuda',
      'Saudi Arabia': 'Arabie saoudite',
      Argentina: 'Argentine',
      Armenia: 'Arménie',
      Aruba: 'Aruba',
      Australia: 'Australie',
      Austria: 'Autriche',
      Azerbaijan: 'Azerbaïdjan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahreïn',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbade',
      Belgium: 'Belgique',
      Belize: 'Belize',
      Benin: 'Bénin',
      Bermuda: 'Bermudes',
      Bhutan: 'Bhoutan',
      Belarus: 'Biélorussie',
      Bolivia: 'Bolivie',
      'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
      Botswana: 'Botswana',
      Brazil: 'Brésil',
      Brunei: 'Brunei',
      Bulgaria: 'Bulgarie',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      'Cape Verde': 'Cabo Verde',
      Cambodia: 'Cambodge',
      Cameroon: 'Cameroun',
      Canada: 'Canada',
      Chile: 'Chili',
      China: 'Chine',
      Cyprus: 'Chypre',
      Colombia: 'Colombie',
      Comoros: 'Comores',
      'Congo - Brazzaville': 'Congo-Brazzaville',
      'Congo - Kinshasa': 'Congo-Kinshasa',
      'North Korea': 'Corée du Nord',
      'South Korea': 'Corée du Sud',
      'Costa Rica': 'Costa Rica',
      'Ivory Coast': 'Côte d’Ivoire',
      Croatia: 'Croatie',
      Cuba: 'Cuba',
      Curaçao: 'Curaçao',
      Denmark: 'Danemark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominique',
      'Dominican Republic': 'République dominicaine',
      Ecuador: 'Équateur',
      Egypt: 'Égypte',
      'United Arab Emirates': 'Émirats arabes unis',
      Eritrea: 'Érythrée',
      Spain: 'Espagne',
      Eswatini: 'Eswatini',
      Estonia: 'Estonie',
      'United States': 'États-Unis',
      Ethiopia: 'Éthiopie',
      Fiji: 'Fidji',
      Finland: 'Finlande',
      France: 'France',
      Gabon: 'Gabon',
      Gambia: 'Gambie',
      Georgia: 'Géorgie',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Grèce',
      Grenada: 'Grenade',
      Greenland: 'Groenland',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernesey',
      Guinea: 'Guinée',
      'Guinea-Bissau': 'Guinée-Bissau',
      'Equatorial Guinea': 'Guinée équatoriale',
      Guyana: 'Guyana',
      'French Guiana': 'Guyane française',
      Haiti: 'Haïti',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Hongrie',
      Iceland: 'Islande',
      India: 'Inde',
      Indonesia: 'Indonésie',
      Iraq: 'Irak',
      Iran: 'Iran',
      Ireland: 'Irlande',
      'Isle of Man': 'Île de Man',
      Israel: 'Israël',
      Italy: 'Italie',
      Jamaica: 'Jamaïque',
      Japan: 'Japon',
      Jersey: 'Jersey',
      Jordan: 'Jordanie',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      Kuwait: 'Koweït',
      Kyrgyzstan: 'Kirghizistan',
      Laos: 'Laos',
      Latvia: 'Lettonie',
      Lebanon: 'Liban',
      Lesotho: 'Lesotho',
      Liberia: 'Libéria',
      Libya: 'Libye',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lituanie',
      Luxembourg: 'Luxembourg',
      Macao: 'Macao',
      'North Macedonia': 'Macédoine du Nord',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaisie',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malte',
      'Marshall Islands': 'Îles Marshall',
      Martinique: 'Martinique',
      Mauritania: 'Mauritanie',
      Mauritius: 'Maurice',
      Mayotte: 'Mayotte',
      Mexico: 'Mexique',
      Micronesia: 'Micronésie',
      Moldova: 'Moldavie',
      Monaco: 'Monaco',
      Mongolia: 'Mongolie',
      Montenegro: 'Monténégro',
      Montserrat: 'Montserrat',
      Morocco: 'Maroc',
      Mozambique: 'Mozambique',
      Myanmar: 'Myanmar',
      Namibia: 'Namibie',
      Nauru: 'Nauru',
      Nepal: 'Népal',
      Netherlands: 'Pays-Bas',
      'New Caledonia': 'Nouvelle-Calédonie',
      'New Zealand': 'Nouvelle-Zélande',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Île Norfolk',
      'Northern Mariana Islands': 'Îles Mariannes du Nord',
      Norway: 'Norvège',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palaos',
      Palestine: 'Palestine',
      Panama: 'Panama',
      'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
      Paraguay: 'Paraguay',
      Peru: 'Pérou',
      Philippines: 'Philippines',
      'Pitcairn Islands': 'Îles Pitcairn',
      Poland: 'Pologne',
      Portugal: 'Portugal',
      'Puerto Rico': 'Porto Rico',
      Qatar: 'Qatar',
      Romania: 'Roumanie',
      Russia: 'Russie',
      Rwanda: 'Rwanda',
      Réunion: 'Réunion',
      'Saint Barthélemy': 'Saint-Barthélemy',
      'Saint Helena': 'Sainte-Hélène',
      'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
      'Saint Lucia': 'Sainte-Lucie',
      'Saint Martin': 'Saint-Martin',
      'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
      'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
      Samoa: 'Samoa',
      'San Marino': 'Saint-Marin',
      'Sao Tome and Principe': 'Sao Tomé-et-Principe',
      Senegal: 'Sénégal',
      Serbia: 'Serbie',
      Seychelles: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapour',
      'Sint Maarten': 'Saint-Martin',
      Slovakia: 'Slovaquie',
      Slovenia: 'Slovénie',
      'Solomon Islands': 'Îles Salomon',
      Somalia: 'Somalie',
      'South Georgia and the South Sandwich Islands':
        'Géorgie du Sud-et-les Îles Sandwich du Sud',
      'South Sudan': 'Soudan du Sud',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Soudan',
      Suriname: 'Suriname',
      'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
      Swaziland: 'Eswatini',
      Sweden: 'Suède',
      Switzerland: 'Suisse',
      Syria: 'Syrie',
      Taiwan: 'Taïwan',
      Tajikistan: 'Tadjikistan',
      Tanzania: 'Tanzanie',
      Thailand: 'Thaïlande',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinité-et-Tobago',
      Tunisia: 'Tunisie',
      Turkey: 'Turquie',
      Turkmenistan: 'Turkménistan',
      Tuvalu: 'Tuvalu',
      Uganda: 'Ouganda',
      Ukraine: 'Ukraine',
      'United Kingdom': 'Royaume-Uni',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Ouzbékistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatican',
      Venezuela: 'Venezuela',
      Vietnam: 'Viêt Nam',
      'Western Sahara': 'Sahara occidental',
      Yemen: 'Yémen',
      Zambia: 'Zambie',
      Zimbabwe: 'Zimbabwe',
    };
    return translations[name] || name;
  }

  /* get indexes for pagination */
  getItems(event?: any) {

    if (event) {
      this.startIndex = event.startIndex
      this.endIndex = event.endIndex
      this.itemsPerPage = event.itemsPerPage,
        this.currentPage = event.currentPage

      if (this.searchForm.dirty) {
        if (this.search) this.searchCompnies();
      } else if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getListCompanies();
      }
    }
  }
  fileErr: boolean = false;
  fileExtentions: string = '';

  /*select file and add Bulk Candidates */
  onFileSelected(event: any) {
    this.fileErr = false;
    this.fileExtentions = '';
    this.selectedFile = event.target.files[0];

    if (this.selectedFile) {
      console.log('Fichier sélectionné :', this.selectedFile);
      const fileExtension = this.selectedFile.name
        .toLowerCase()
        .substr(this.selectedFile.name.lastIndexOf('.'));
      const allowedExtensions = [
        '.jpeg',
        '.jpg',
        '.png',
        '.gif',
        '.jfif',
        '.avif',
        '.csv',
        '.xlsx',
        '.xls',
      ];
      if (!allowedExtensions.includes(fileExtension)) {
        console.log('selectedFile', fileExtension);
        this.fileExtentions = fileTypeExtention;
        this.fileErr = true;
      }
    }
  }

  addBulkCompany() {
    this.spinner.show();
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      this.companyService.addBulkCompany(formData).subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.fileBulkError = res.export_file;
          console.log('file bluk error', this.fileBulkError);
          this.getbulkCompany(this.fileBulkError);
          this.toastr.success('Fichier importé avec succès');

          this.closeModalBulk();
        },
        error: (err) => {
          this.spinner.hide();
          if (err?.error) {
            if (err.error?.message) {
              if (err.error.message === 'File contains only headers.') {
                this.toastr.error('Le fichier est vide');
              } else if (
                err.error.message ===
                'File headers do not match the expected headers.'
              ) {
                this.toastr.error(
                  'Les en-têtes de fichiers ne correspondent pas aux en-têtes attendus.'
                );
              }
              // else {
              //   this.toastr.error(serverError);
              // }
            }
            //  else {
            //   this.toastr.error(serverError);
            // }
          }
        },
      });
    }
  }

  getbulkCompany(file: File) {
    let exportSuccess = false;
    this.companyService.getbulkCompany(file).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        const blob = new Blob([res], { type: 'application/octet-stream' });
        saveAs(blob, this.fileBulkError);
        exportSuccess = true;
        if (exportSuccess) {
          this.toastr.success(
            'Résultat de fichier importé a été exporté avec succès'
          );
        }
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error(serverError);
      },
    });
  }

  /*download file add bulk */
  downloadCsv() {
    this.companyService.downloadcsvfileAddBulkCompany().subscribe({
      next: (res: Blob) => {
        console.log("res", res);
        const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'ajout en masse admin entreprise.csv');
      },
      error: (err) => {
        console.log('error', err);
        // Handle the error appropriately
      },
    });
  }
  // downloadCsv() {
  //   const fileSaver = saveAs;
  //   if (Array.isArray(this.data)) {
  //     const headers = Object.keys(this.data[0]);
  //     const csvContent = this.data.map(item => {
  //       return headers.map(header => {
  //         if (Array.isArray(item[header])) {
  //           return item[header].join(';');
  //         } else if (typeof item[header] === 'object' && item[header] !== null) {
  //           return JSON.stringify(item[header]);
  //         } else {
  //           return item[header];
  //         }
  //       }).join(',');
  //     }).join('\n');

  //     fileSaver(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }), 'ajout en masse admin entreprise.csv');
  //   } else {
  //     console.error('Invalid data format. Expected an array.');
  //   }
  // }

  /* sort data */
  sortData(name?: string) {
    for (const iterator of this.dataHeaderSort) {
      if (iterator.name == name) this.sortAttr = iterator.code;
    }
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.currentPage = 1;
    this.endIndex = 5;


    if (this.searchForm.dirty) {
      this.listCampany = this.sortDataService.sortArray(
        this.listCampany,
        name,
        this.currentSortDirection
      );
    } else {
      this.getListCompanies();
    }
    this.getListCompanies();
  }
  /* get all companies */
  getListCompanies() {
    let dataPyload = this.sortAttr == '' ? { per_page: this.itemsPerPage, page: this.currentPage } : { ...{ per_page: this.itemsPerPage, page: this.currentPage }, ...{ sort: this.currentSortDirection, sortAttribute: this.sortAttr } }
    this.spinner.show()
    this.companyService.listCompany(dataPyload).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (response) => {
        this.isLoadSpinner = false;
        this.spinner.hide()
        if (response.data.data.length) {
          // this.listCampany = response.data.data.slice(0, this.endIndex)
          this.listCampany = response.data.data

          this.totalItems = response.data.total
          this.listCampany.forEach((company: any) => this.convertAverageNoteToNumber(company));
          console.log('listCampanyfromGet', this.listCampany);

          // if (this.currentPage > 1 && !response.data.data.length) {
          //   this.currentPage = 1
          //   this.getListCompanies()
          // }
        }
      },
      error: () => {
        this.spinner.hide()

      },
    })
  }
  private convertAverageNoteToNumber(company: any): void {
    company.average_note = parseFloat(company.average_note);
  }
  getStarCount(averageNote: number): number {
    return averageNote !== null ? averageNote : 0;
  }
  openModal(template: TemplateRef<any>, mode: string, company?: any) {
    console.log('company', company);
    this.mode = mode;
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    if (company) {
      this.companyForm = this.createCompanyForm(company);
    }
  }
  closeModal() {
    this.modalRef?.hide();
    this.companyForm.reset();
    this.formData = new FormData();
    this.file = null;
    this.fileError = '';
    this.fileExtention = '';
  }
  /* create add subCategory form */
  createCompanyForm(data?: any) {
    return this.formBuilder.group({
      name: [data ? data.name : '', [Validators.required]],
      type: [data ? data.type : null],
      linkedin_link: [
        data ? data.linkedin_link : '',
        [Validators.pattern(linkedCompanyRegExp)],
      ],
      about: [data && data.about != 'null' ? data.about : ''],
      website_link: [
        data ? data.website_link : '',
        [Validators.pattern(urlRegExp)],
      ],
      logo: [data ? data.logo : null],
    });
  }
  /* create entreprise form */
  inscricreateCompanyForm(data?: any) {
    return this.formBuilder.group({
      name: [data ? data.name : '', [Validators.required]],
      type: [null, Validators.required],
      email: [
        data?.email,
        [Validators.email, Validators.pattern(patternEmail)],
      ],
      password: [data?.password, [Validators.pattern(patternPassword)]],
      idCompany: [data ? data.idCompany : ''],
    });
  }

  signUp() {
    // Perform signup logic
    this.companyService
      .register(this.inscricompanyForm.value)
      .pipe()
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.toastr.success('Inscription réussie');
            this.closeModal();
          }
        },
        error: (err) => {
          console.log('Signup error:', err);
          if (err?.error) {
            this.closeModal();
            if (err.error?.message && err.error?.message['email']) {
              this.toastr.error('Email existe déjà');
            } else if (
              err.error?.message &&
              err.error?.message['linkedin_link']
            ) {
              this.toastr.error('Le lien LinkedIn existe déjà');
            } else if (
              err.error?.message &&
              err.error?.message['website_link']
            ) {
              this.toastr.error('Le lien du site web existe déjà');
            } else if (err.error?.message && err.error?.message['name']) {
              this.toastr.error("le nom  d'entreprise existe déjà");
            } else {
              this.toastr.error(serverError);
            }
          }
        },
      });
  }
  resetImage() {
    const inputFile = document.getElementById('logo') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = '';
    }
    this.file = null;
    this.fileExtention = '';
    this.fileError = '';
    this.fileName = '';
  }

  /* set form data */
  setFormData() {
    if (this.companyForm.value.type === null) {
      this.companyForm.patchValue({
        type: 1,
      });
    }
    this.formData.append('name', this.companyForm.value.name);
    this.formData.append('about', this.companyForm.value.about);
    this.formData.append('type', this.companyForm.value.type);
    if (this.companyForm.value.linkedin_link)
      this.formData.append(
        'linkedin_link',
        this.companyForm.value.linkedin_link
      );
    if (this.companyForm.value.website_link)
      this.formData.append('website_link', this.companyForm.value.website_link);
    if (this.file) this.formData.append('logo', this.file);
  }
  /* upload file */
  uploadFile(event: any): void {
    // Reset error messages
    this.fileExtention = '';
    this.fileError = '';

    const fileInput = event.target;
    const file = fileInput.files[0];
    const maxSize = 2 * 1024 * 1024;
    const allowedExtensions = [
      '.jpeg',
      '.jpg',
      '.png',
      '.gif',
      '.jfif',
      '.avif',
    ];

    // Check if the file input is empty (user canceled selection)
    if (!file) {
      // Clear file input and related variables
      fileInput.value = '';
      this.file = null;
      this.fileName = '';
      this.selectedImage = null;
      return;
    }

    // Set file and file name
    this.file = file;
    this.fileName = this.file.name;

    const fileExtension = file.name
      .toLowerCase()
      .substr(file.name.lastIndexOf('.'));

    if (!allowedExtensions.includes(fileExtension)) {
      this.fileExtention = 'Format fichier non compatible !';
      // Clear file input and related variables
      // fileInput.value = '';
      // this.file = null;
      // this.fileName = '';
      // this.selectedImage = null;
    } else if (file.size > maxSize) {
      this.fileError = 'File size exceeds the maximum allowed size (2MB).';
      // Clear file input and related variables
      fileInput.value = '';
      this.file = null;
      this.fileName = '';
      this.selectedImage = null;
    } else {
      // Reset other error messages
      this.fileError = '';

      const reader = new FileReader();
      reader.onload = () => {
        this.selectedImage = reader.result;
      };
      reader.readAsDataURL(file);
    }

    // Set the file in the form group
    this.companyForm.get('logo')?.setValue(this.file);
  }
  /* ************************* navigation esn ********************* */
  profilEsnNavigation(id: string, idRole: string) {
    this.router.navigate([
      '/acceuil/entreprises/esn/profil-esn',
      { id: id, idRole: idRole },
    ]);
  }
  /* ************************** navigation client final ****************************** */
  detailsClientFinalNavigation(id: string, idRole: string) {
    this.router.navigate([
      '/acceuil/entreprises/clients-finaux/details-client-final',
      { id: id, idRole: idRole },
    ]);
  }
  /* Custom validator function : requirement CV or Linkedin  */
  setValidatorRequirements() {
    if (
      !this.companyForm.get('linkedin_link')?.value ||
      this.companyForm.get('linkedin_link')?.value == ''
    ) {
      this.companyForm
        .get('website_link')
        ?.setValidators([Validators.required, Validators.pattern(urlRegExp)]);
      this.companyForm.get('website_link')!.updateValueAndValidity();
      this.companyForm.get('linkedin_link')?.clearValidators();
      this.companyForm.get('linkedin_link')?.updateValueAndValidity();
    }
    if (this.companyForm.get('website_link')?.value == '') {
      this.companyForm
        .get('linkedin_link')
        ?.setValidators([
          Validators.required,
          Validators.pattern(linkedCompanyRegExp),
        ]);
      this.companyForm.get('linkedin_link')!.updateValueAndValidity();
      this.companyForm.get('website_link')?.clearValidators();
      this.companyForm
        .get('website_link')
        ?.setValidators(Validators.pattern(urlRegExp));
      this.companyForm.get('website_link')?.updateValueAndValidity();
    }
  }
  /*  add new company */
  addCompany() {
    this.setValidatorRequirements();
    if (this.companyForm.valid) {
      this.setFormData()
      this.spinner.show()
      console.log("add entreprise", this.companyForm.value)
      console.log("formdata", this.formData)

      this.companyService.addCompany(this.formData).pipe(takeUntil(this.unsubscribeAll)).subscribe({
        next: () => {
          this.toastr.success(addedCompany)
          this.spinner.hide()
          this.closeModal()
          this.getListCompanies()
        },
        error: (err) => {
          this.closeModal()
          if (err.error?.message?.name) this.toastr.error(existedCompany)
          else if (err.error?.message?.linkedin_link && !err.error?.message?.website_link) this.toastr.error(existedLinkedIn)
          else if (err.error?.message?.website_link && !err.error?.message?.linkedin_link) this.toastr.error(existedWebSite)
          else if (err.error?.message?.website_link && err.error?.message?.linkedin_link) this.toastr.error(existedWebSiteLinkedIn)
          // else this.toastr.error(serverError)
          this.spinner.hide()
        },
      })
    }
  }
  /* search form */
  /* create add subCategory form */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      ID_company: [data ? data.ID_company : ''],
      name: [data ? data.name : ''],
      typeComp: [data ? data.typeComp : null],
      responsable_name: [data ? data.responsable_name : null],
      country: [data ? data.country : null],
      date_last_conx: [data ? data.date_last_conx : null],
      note: [data ? data.note : 0],
      search: [
        '',
        [Validators.pattern(/^("([^"]*"(AND|NOT|,|&&)[^"]*)*"|[^\s"']+)$/)],
      ],
    });
  }
  isRecherche: boolean = false;
  reset() {
    this.spinner.hide();
    this.currentPage = 1;
    this.spinner.hide();
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();

      this.getListCompanies();
    }
    return (this.isRecherche = false);
  }
  searchCompniesreset() {
    let data = {
      name: this.searchForm.value?.name,
      typeComp: !this.searchForm.value.typeComp
        ? ''
        : this.searchForm.value.typeComp.toString(),
      country: !this.searchForm.value.country
        ? []
        : this.searchForm.value.country,
      note: this.searchForm.value.note,
      ID_company: this.searchForm.value.ID_company,
      responsable_name: this.searchForm.value?.responsable_name,
      date_last_conx: this.searchForm.value.date_last_conx
        ? this.dateFormat(this.searchForm.value.date_last_conx)
        : '',
      page: this.currentPage,
      per_page: this.itemsPerPage,
      search: this.transformSearch(this.searchForm.value?.search),
    };

    this.spinner.show();
    if (data.note === 0) {
      console.log('note');
      data.note = '';
    }

    console.log('data search', data);
    this.companyService
      .searchEntreprise(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('response search', res);
          console.log('is pagination', this.ispagination);
          console.log('page', data.page);
          //
          console.log('current page', this.currentPage);
          if (this.currentPage > 1 && !this.ispagination) {
            console.log('ifffff');
            this.currentPage = 1;
            this.searchCompnies();
            this.spinner.hide();
          } else {
            console.log('else');
            this.listCampany = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
            this.listCampany.forEach((company: any) =>
              this.convertAverageNoteToNumber(company)
            );
            this.ispagination = true;
            this.spinner.hide();
          }
        },
        error: () => {
          this.isRecherche = false;
        },
      });
  }
  getListCompaniesreset() {
    let dataPyload =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };

    this.companyService
      .listCompany(dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (response) => {
          if (response.data.data.length) {
            this.listCampany = response.data.data.slice(0, this.endIndex);
            this.totalItems = response.data.total;
            this.listCampany.forEach((company: any) =>
              this.convertAverageNoteToNumber(company)
            );
            console.log('listCampanyfromGet', this.listCampany);

            // if (this.currentPage > 1 && !response.data.data.length) {
            //   this.currentPage = 1
            //   this.getListCompanies()
            // }
          }
        },
        error: () => { },
      });
  }
  count!: number;
  setNote(value: number) {
    this.searchForm.get('note')?.setValue(value);
    this.star = value;
  }
  handleRatingClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('bs-rating-star')) {
      // Check if the "active" class is present
      const isActive = target.classList.contains('active');
      // Toggle the "active" class
      target.classList.toggle('active', !isActive);
      this.ispagination = false;
    }
  }
  updateInputType(type: 'text' | 'date') {
    const inputElement = document.getElementById(
      'dateInput'
    ) as HTMLInputElement;
    inputElement.type = type;
    if (type == 'text') {
      console.log('this.searchForm.value', this.searchForm.value);

      if (
        this.searchForm.value.date_last_conx &&
        this.searchForm.value.date_last_conx != ''
      )
        this.searchForm
          .get('date_last_conx')
          ?.setValue(
            moment(this.searchForm.value.date_last_conx).format('DD/MM/yyyy')
          );
    }
  }
  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
      console.log('HEYYYYYYYYYYYY');
    }
  }
  dateFormat(value: any) {
    const [day, month, year] = value.split('/');
    return moment(`${year}-${month}-${day}`).format('YYYY-MM-DD');
  }
  /* search */
  transformSearch(search: string): string {
    // Remplace toutes les occurrences de '||' par 'OR'
    search = search.replace(/\|\|/g, ' OR ');

    // Remplace toutes les occurrences de '&&' par ' AND '
    search = search.replace(/&&/g, ' AND ');

    // Remplace toutes les occurrences de '!' par ' NOT '
    search = search.replace(/!/g, ' NOT ');

    search = search.replace(/\b(AND|OR|NOT)\b/g, ' $1 ');

    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        return `"${p1}"`;
      } else {
        // Si le terme n'est pas entre guillemets, échappe les guillemets simples à l'intérieur du terme
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `${escapedTerm}`;
      }
    });

    search = search.replace(/\s+/g, ' ');

    return search.trim();
  }

  isSimpleWord(value: string): boolean {
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }
  searchCompnies() {
    let data = {
      name: this.searchForm.value?.name,
      typeComp: !this.searchForm.value.typeComp
        ? ''
        : this.searchForm.value.typeComp.toString(),
      country: !this.searchForm.value.country
        ? []
        : this.searchForm.value.country,
      note: this.searchForm.value.note,
      ID_company: this.searchForm.value.ID_company,
      responsable_name: this.searchForm.value?.responsable_name,
      date_last_conx: this.searchForm.value.date_last_conx
        ? this.dateFormat(this.searchForm.value.date_last_conx)
        : '',
      page: this.currentPage,
      per_page: this.itemsPerPage,
      search: this.transformSearch(this.searchForm.value?.search),
    };
    this.spinner.show();

    if (data.note === 0) {
      console.log('note');
      data.note = '';
    }
    console.log('data search', data);
    this.companyService
      .searchEntreprise(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          console.log('this.isRecherche', this.isRecherche);
          console.log('response search', res);
          console.log('is pagination', this.ispagination);
          console.log('page', data.page);
          //
          console.log('current page', this.currentPage);
          if (this.currentPage > 1 && !this.ispagination) {
            console.log('ifffff');
            this.currentPage = 1;
            this.searchCompnies();
          } else {
            console.log('else');
            this.listCampany = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
            this.listCampany.forEach((company: any) =>
              this.convertAverageNoteToNumber(company)
            );
            this.spinner.hide();
            this.ispagination = true;
            // this.companyService.setSearchResults(this.listCampany);
          }
          this.spinner.hide();
          //  this.search=false;
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  changeSelection(event: any, data: any, i: number) {
    this.listNames = [];
    data.checkAdmin = event.target.checked;
    for (const iterator of this.dataHeader) {
      if (iterator.checkAdmin) this.listNames.push(iterator.name);
    }
    this.changeSelectionHeadrElements(data);
  }
  changeSelectionHeadrElements(data: any) {
    let payload = {
      sub_page_id: data?.id,
    };
    this.candidatService
      .changeDelectedElementHeader(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => { },
        error: () => {
          this.toastr.error(serverError);
        },
      });
  }

  updateCompany() {
    console.log('companyType', this.companyType);
  }

  showEvaluateCompany(idCompany: number, type: string) {
    this.router.navigate([
      '/acceuil/parametres/list-avis-societe',
      { id: idCompany, type: type },
    ]);
  }
  checkedEsn: boolean = false;
  checkedClient: boolean = false;
  // changeType(){
  //   checkedEsn=
  //   checkedClient=
  // }

  openModalBulk(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModalBulk() {
    this.selectedFile = null;
    this.modalRef!.hide();
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable.removeEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
}
