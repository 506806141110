import { Injectable } from '@angular/core';
import { CrudService } from '../crud/crud.service';
import { ApiResponse } from '../../models/response-api';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobOfferService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get list oofre d'emploi */
  listJobOffer(data?: {
    per_page?: number;
    page?: number;
    sort?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/JobOffer/getByEsn?per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
  }

  /* search */
  findDoubleQuotes(str: string): number[] {
    const indices: number[] = [];
    for (let i = 0; i < str?.length; i++) {
      if (str[i] === '"') {
        indices.push(i);
      }
    }
    return indices;
  }
  searchJobOffer(data: any) {
    let tabs = this.findDoubleQuotes(data.search);

    if (tabs.length <= 2) data.search = '"' + data.search + '"';
    console.log(
      'data of searchCandidat',
      'count = ' + tabs.length,
      data.search
    );
    return this._http.post<ApiResponse<any>>(
      this._base + `/JobOffer/search`,
      data
    );
  }
  /* get candidature list by job offer id */
  getCandidature(data: {
    id: string;
    per_page?: number;
    page?: number;
    sort?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/CandidateJobOffer/getByJobOffer?job_offer_id=${data.id}&per_page=${data.per_page}&page=${data.page}${sort}`
    );
  }
  /* search */
  searchCandidateJobOffer(id: string, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/CandidateJobOffer/search/${id}`,
      data
    );
  }

  /* Activer */
  activedesactiveJobOffer(jobOffer_id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/JobOffer/ActivateDesactivate?jobOffer_id=${jobOffer_id}`
    );
  }

  getBarem(job_offer_id: number) {
    console.log(
      'url',
      this._base + `/ScoreJob/getScoreDetails?job_offer_id=${job_offer_id}`
    );
    return this._http.get<ApiResponse<any>>(
      this._base + `/ScoreJob/getScoreDetails?job_offer_id=${job_offer_id}`
    );
  }

  getSkillsJobs() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/SubCategory/getListSubCateg`
    );
  }

  gettest(data?: { per_page?: number; page?: number; id: any }) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Question_test_jobOffer/getQuestionWithResponses/${data?.id}?per_page=${data?.per_page}&page=${data?.page}`
    );
  }
  getcandidatureById(user_id : any,job_offer_id:any): Observable<ApiResponse<any>> {
    return this._http.get<ApiResponse<any>>(
      this._base + `/CandidateJobOffer/getDetailsCandidaturesByjobOfferESN?user_id=${user_id}&job_offer_id=${job_offer_id}`
    );
  }
}
