import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { ModulesModule } from './modules/modules.module';
import { SettingsModule } from './settings/settings.module';
import { JobOfferModule } from './job-offer/job-offer.module';
import { CompaniesModule } from './companies/companies.module';
import { CandidatModule } from './candidat/candidat.module';
import { TenderModule } from './tender/tender.module';
import { ProfilAdminComponent } from './profil-admin/profil-admin.component';
import { MessagesComponent } from './messages/messages.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ContactWegestuComponent } from './contact-wegestu/contact-wegestu.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ListAdminComponent } from './list-admin/list-admin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxIntlTelephoneInputModule } from 'ngx-intl-telephone-input';
import { PaginationModule } from '../shared/components/pagination/pagination.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { HeaderComponent } from '../components/header/header.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { FooterComponent } from '../components/footer/footer.component';
import { NetworkStatusComponent } from './network-status/network-status.component';
@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    ProfilAdminComponent,
    MessagesComponent,
    ContactWegestuComponent,
    NotificationsComponent,
    ListAdminComponent,
    NotFoundComponent,
    NetworkStatusComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MainRoutingModule,
    DashboardModule,
    ModulesModule,
    SettingsModule,
    JobOfferModule,
    CompaniesModule,
    CandidatModule,
    TenderModule,
    NgxSpinnerModule,
    PaginationModule,
    NgSelectModule,
    NgxIntlTelInputModule,
    NgxIntlTelephoneInputModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainModule {}
