import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../models/response-api';
import { CrudService } from '../crud/crud.service';
import { IRequstJob } from '../../models/job-offer';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends CrudService<ApiResponse<any>, number> {
  private searchResultsSubject = new BehaviorSubject<any[]>([]);
  searchResults$ = this.searchResultsSubject.asObservable();

  private searchResultsSubjectEsn = new BehaviorSubject<any[]>([]);
  searchResultsEsn$ = this.searchResultsSubjectEsn.asObservable();

  private searchResultsSubjectClient = new BehaviorSubject<any[]>([]);
  searchResultsClient$ = this.searchResultsSubjectClient.asObservable();

  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  setSearchResultsClient(resultsClient: any[]) {
    this.searchResultsSubjectClient.next(resultsClient);
  }

  setSearchResultsEsn(resultsEsn: any[]) {
    this.searchResultsSubjectEsn.next(resultsEsn);
  }

  setSearchResults(results: any[]) {
    this.searchResultsSubject.next(results);
  }
  /* get companies */
  listCompany(data?: {
    per_page?: number;
    page?: number;
    sort?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Company/getListEntreprise?per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
  }

  /* add company */
  addCompany(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/UnitaryAdd`,
      data
    );
  }
  /////////*delete company*/////////////////////////////////////////////////////////
  deletecompany(id: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/Company/delete/${id}`
    );
  }
  /************************************************************ */
  /* register */
  register(body: any) {
    console.log('hello', this._base + '/Company/signUpCompany', body);
    return this._http.post<ApiResponse<any>>(
      this._base + '/Company/signUpCompany',
      body
    );
  }

  /* get candidat rating  wegestu by id*/
  getCandidatReviewById(idUser: number, company_id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseSpecificReview/getAvisCompanyWithResponses?user_id=${idUser}&company_id=${company_id}`
    );
  }

  /* get esn by id */
  getEsn(id: string) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Company/getCompanyByIdWithDetails?company_id=${id}`
    );
  }
  /* get review candidat */
  getReviewCandidat(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseSpecificReview/getUsersAvisCompany?company_id=${id}`
    );
  }
  /* get review company */
  getReviewCompany(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/ResponseSpecificReview/getCompanyWithAvis?company_id=${id}`
    );
  }
  /* get esn list */
  getCompaniesList(data?: any) {
    console.log('data', data);
    let type: any = data?.type;
    if (type?.length && type?.length > 1) {
      type = `&type[]=${data?.type[0]}&type[]=${data?.type[1]}`;
    } else {
      type = `&type[]=${data?.type[0]}`;
    }
    let sort = '';
    if (data?.sort) sort = `&sort=${data.sort}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Company/getListESN?per_page=${data?.per_page}&page=${data?.page}${sort}${type}&sortAttribute=${data?.sortAttribute}`
    );
  }
  // /Company/getListWithDetailsByType

  /* get esn list */
  getCompaniesListClient(data?: any) {
    console.log('data', data);
    let type: any = data?.type;
    if (type?.length && type?.length > 1) {
      type = `&type[]=${data?.type[0]}&type[]=${data?.type[1]}`;
    } else {
      type = `&type[]=${data?.type[0]}`;
    }
    let sort = '';
    if (data?.sort) sort = `&sort=${data.sort}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Company/getListClient?per_page=${data?.per_page}&page=${data?.page}${sort}${type}&sortAttribute=${data?.sortAttribute}`
    );
  }
  /* get esn list */
  getEsnClientList(data?: {
    per_page?: number;
    page?: number;
    sort?: number;
    type?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    let sortAttr = '';
    if (data?.sort) {
      sort = `&sort=${data.sort}`;
      sortAttr = `&sortAttribute=${data?.sortAttribute}`;
    }
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Company/getListRegistredCompanies?per_page=${data?.per_page}&page=${data?.page}${sort}&type=${data?.type}${sortAttr}`
    );
  }
  /*get list offer */
  getListOfOffer() {
    return this._http.get<ApiResponse<any>>(this._base + `/Offer/getAllOffer`);
  }
  /* get list intervenant by company */
  getIntervenantList(
    id: number,
    data?: {
      per_page?: number;
      page?: number;
      sort?: number;
      type?: number;
      sortAttribute?: string;
    }
  ) {
    let sort = '';
    let sortAttr = '';
    if (data?.sort) {
      sort = `&sort=${data.sort}`;
      sortAttr = `&sortAttribute=${data?.sortAttribute}`;
    }
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Company/getCompanyWithEmployees/${id}?per_page=${data?.per_page}&page=${data?.page}${sort}&type=${data?.type}${sortAttr}`
    );
  }

  getListsalarie(data: {
    clientFinal_id: number;
    per_page?: number;
    page?: number;
    sort?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data.sortAttribute}`;

    console.log(
      'url',
      `${this._base}/ConsultantClientFinal/getSalariesByClientFinal?
  clientFinal_id=${data.clientFinal_id}&per_page=${data.per_page}&page=${data.page}${sort}`
    );
    return this._http.get<
      ApiResponse<any>
    >(`${this._base}/ConsultantClientFinal/getSalariesByClientFinal?
    clientFinal_id=${data.clientFinal_id}&per_page=${data.per_page}&page=${data.page}${sort}`);
  }

  /* get list projet */
  getListprojet(data: {
    id: number;
    per_page?: number;
    page?: number;
    sort?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data.sortAttribute}`;
    console.log(
      'url',
      `${this._base}/Project/getByClientId?id=${data.id}&per_page=${data.per_page}&page=${data.page}${sort}`
    );
    return this._http.get<
      ApiResponse<any>
    >(`${this._base}/Project/getByClientId?
    id=${data.id}&per_page=${data.per_page}&page=${data.page}${sort}`);
  }

  getprojets(id: number): Observable<ApiResponse<any>> {
    return this._http
      .get<ApiResponse<any>>(`${this._base}/Project/getByClient?id=${id}`)
      .pipe(
        map((res: any) => {
          if (res) {
            console.log(res);
            return res;
          }
        })
      );
  }

  /* get list consultant by esn */
  getConsultantListESN(
    id: number,
    data?: {
      per_page?: number;
      page?: number;
      sort?: number;
      sortAttribute?: string;
    }
  ) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Company/getEsnWithConsultants/${id}?per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
  }
  /* get list consultant by client final */
  getConsultantClientFinal(
    id: number,
    data?: {
      per_page?: number;
      page?: number;
      sort?: number;
      sortAttribute?: string;
    }
  ) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Company/getClientFinalWithConsultants/${id}?per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
  }
  /* get list job offer by esn */
  getJobOfferList(
    id: number,
    data?: {
      per_page?: number;
      page?: number;
      sort?: number;
      type?: number;
      sortAttribute?: string;
    }
  ) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/JobOffer/getByEsn?esn_id=${id}&per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
  }
  /* delete */
  deleteIntervenant(idUser: number, idCompany: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base +
        `/Employee/deleteEmployee?user_id=${idUser}&company_id=${idCompany}`
    );
  }
  /* add intervenant */
  createIntervenant(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Employee/create`,
      data
    );
  }
  /* edit intervenant */
  updateIntervenant(idCompany: number, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Employee/updateEmployee/${idCompany}`,
      data
    );
  }
  /* edit company */
  updateCompany(idCompany: number, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/updateCompany/${idCompany}`,
      data
    );
  }
  /* search company */
  findDoubleQuotes(str: string): number[] {
    const indices: number[] = [];
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '"') {
        indices.push(i);
      }
    }
    return indices;
}
searchEntreprise(data: any) {
  let tabs = this.findDoubleQuotes(data.search);
  console.log("bnnb");
  if (tabs.length <= 2) {
    data.search = '"' + data.search + '"';
  }
  console.log("data of searchCandidat", "count = " + tabs.length, data.search);
  console.log("urllllllllllll", this._base + `/Company/search?page=${data.page}&per_page=${data.per_page}`, data);
  return this._http.post<ApiResponse<any>>(
    this._base + `/Company/search?page=${data.page}&per_page=${data.per_page}`,
    data
  );
}
  /* search company */
  searchClientFinal(data: any) {
    let tabs = this.findDoubleQuotes(data.search);

    if (tabs.length <= 2) data.search = '"' + data.search + '"';
    console.log(
      'data of searchCandidat',
      'count = ' + tabs.length,
      data.search
    );
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/searchRegistredCompany`,
      data
    );
  }
  /* search company */
  searchESN(data: any) {
    let tabs = this.findDoubleQuotes(data.search);

    if (tabs.length <= 2) data.search = '"' + data.search + '"';
    console.log(
      'data of searchCandidat',
      'count = ' + tabs.length,
      data.search
    );
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/searchInListEsn`,
      data
    );
  }
  /* get evaluation */
  evaluationEsn(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseDefaultReview/getResponsesbyCompanywithAVGReviews?company_id=${id}`
    );
  }
  /* search intervenant esn */
  searchInterveanant(id: number, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/searchIntervenant/${id}`,
      data
    );
  }

  // search salarie//////
  searchsalarie(data: any): Observable<ApiResponse<any>> {
    console.log(
      'url search salarie',
      this._base + `/ConsultantClientFinal/searchSalariesForClientFinal`,
      data
    );
    return this._http
      .post<ApiResponse<any>>(
        this._base + `/ConsultantClientFinal/searchSalariesForClientFinal`,
        data
      )
      .pipe(
        map((res: any) => {
          if (res) {
            console.log(res);
            return res;
          }
        })
      );
  }

  searchprojet(data: any): Observable<ApiResponse<any>> {
    console.log('data', data);

    return this._http
      .post<ApiResponse<any>>(
        this._base + `/Project/searchInListProjects`,
        data
      )
      .pipe(
        map((res: any) => {
          if (res) {
            console.log(res);
            return res;
          }
        })
      );
  }

  /* search consultant esn */
  searchConsultant(id: number, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/searchConsultant/${id}`,
      data
    );
  }
  /* get list appel d'offre by client final */
  getRequestJobClientFinal(
    id: number,
    data?: {
      per_page?: number;
      page?: number;
      sort?: number;
      sortAttribute?: string;
    }
  ) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data?.sort}&sortAttribute=${data.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/JobRequest/getByClientFinal?client_id=${id}&per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
  }
  /* create request job by final customer */
  createRequestJob(body: any) {
    console.log('url', this._base + '/JobRequest/create', body);
    return this._http.post<ApiResponse<IRequstJob>>(
      this._base + '/JobRequest/create',
      body
    );
  }
  /*add Bulk Client*/
  addBulkClient(data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/addBulkClient`,
      data
    );
  }
  /*get bulk Client */
  getbulkClient(data: any) {
    return this._http.get(this._base + `/Company/exportClient/${data}`, {
      responseType: 'arraybuffer',
    });
  }
  /*add Bulk ESN*/
  addBulkEsn(data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/addBulkESN`,
      data
    );
  }
  /*get bulk ESN */
  getbulkEsn(data: any) {
    return this._http.get(this._base + `/Company/exportESN/${data}`, {
      responseType: 'arraybuffer',
    });
  }
  /*add Bulk company*/
  addBulkCompany(data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/addBulk`,
      data
    );
  }
  /*get bulk company */
  getbulkCompany(data: any) {
    return this._http.get(this._base + `/Company/export/${data}`, {
      responseType: 'arraybuffer',
    });
  }
  downloadcsvfileAddBulkCompany() {
    const url = `${this._base}/Company/uploadEmptyFileAddBulkCompanies`;
    console.log('url', url);
    return this._http.get(url, {
      responseType: 'blob', // Changer 'arraybuffer' en 'blob'
      // observe: 'response', // Permet d'obtenir la réponse complète incluant les en-têtes
    });
  }

  downloadcsvfileAddBulkEsn() {
    const url = `${this._base}/Company/uploadEmptyFileAddBulkESN`;
    console.log('url', url);
    return this._http.get(url, {
      responseType: 'blob', // Changer 'arraybuffer' en 'blob'
      // observe: 'response', // Permet d'obtenir la réponse complète incluant les en-têtes
    });
  }
  downloadcsvfileAddBulkClient() {
    const url = `${this._base}/Company/uploadEmptyFileAddBulkClient`;
    console.log('url', url);
    return this._http.get(url, {
      responseType: 'blob', // Changer 'arraybuffer' en 'blob'
      // observe: 'response', // Permet d'obtenir la réponse complète incluant les en-têtes
    });
  }
  headerCompanies() {
    return [
      { name: 'Entreprise', code: 'name' },
      { name: 'RESPONSABLE', code: 'first_name' },
      { name: 'Type', code: 'type' },
      { name: 'Note', code: 'average_stars' },
      { name: 'N° TéL', code: 'phone_number' },
      { name: 'MODIFIé LE', code: 'updated_at' },
      { name: 'EMAIL', code: 'email' },
      { name: 'PAYS', code: 'country' },
      { name: 'VILLE', code: 'city' },
      { name: 'Nombre des appels d’offre', code: 'nb_job_request' },
      { name: 'Nombre des offres d’emploi', code: 'nb_job_offer' },
      { name: 'Nombre des CRA', code: 'phone_number' },
      { name: 'Derniére Connexion', code: 'nb_cra' },
      { name: 'ABONNEMENT', code: 'abonnement' },
      { name: 'ACTION', code: 'action' },
    ];
  }
  // dissocier consultant esn
  dissocierconsultant(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/admin/ConsultantEsn/disassociateConsultantFromEsnByAdmin`,
      data
    );
  }

  // dissocier consultant client final
  dissocierconsultantclientfinal(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/ConsultantClientFinal/disassociateSalarieFromClientFinal`,
      data
    );
  }
}
