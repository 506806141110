<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>

<!--begin::Main-->
<div class="app-main flex-column flex-row-fluid" id="kt_app_main">
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">

        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <div class="d-flex align-items-center">
                        <h1 class="page-heading"> <svg width="32" height="29" viewBox="0 0 32 29" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_82_1744)">
                                    <path
                                        d="M21.795 7.11531C19.8257 7.11531 18.2199 5.51738 18.2199 3.55766C18.2199 1.59793 19.8257 0 21.795 0C23.7643 0 25.3701 1.59793 25.3701 3.55766C25.3701 5.51738 23.7643 7.11531 21.795 7.11531ZM16.3112 4.58274C16.3112 2.05017 14.2509 0.0301492 11.7363 0.0301492C9.19127 0.0301492 7.16135 2.08032 7.16135 4.58274C7.16135 7.11531 9.22157 9.13533 11.7363 9.13533C14.2812 9.13533 16.3112 7.08516 16.3112 4.58274ZM27.0667 15.9491C27.5212 15.9491 27.8848 15.5572 27.8242 15.105C27.3394 11.6076 25.8245 8.1404 21.795 8.1404C19.5227 8.1404 18.0381 9.25593 17.0989 10.8539C17.7654 11.4267 18.3714 12.09 18.8561 12.904C19.4015 13.7784 19.8863 14.8035 20.2498 15.9793H27.0667V15.9491ZM4.01041 19.2053C3.91952 19.8686 4.43457 20.5017 5.13142 20.5017H18.3411C19.0379 20.5017 19.553 19.8987 19.4621 19.2053C18.7956 14.8035 16.8565 10.4619 11.7363 10.4619C6.61599 10.4619 4.67695 14.8035 4.01041 19.2053Z"
                                        fill="#265D91" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_82_1744" x="0" y="0" width="31.8308" height="28.5017"
                                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="4" />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                            result="effect1_dropShadow_82_1744" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_82_1744"
                                            result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                            Nos Candidatures
                        </h1>
                        <!--begin::Menu-->
                    </div>
                    <!--end::Title-->
                    <!--begin::Breadcrumb-->
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a routerLink="/acceuil" class="text-muted ">Accueil</a>
                          </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted cursor-pointer" routerLink="/acceuil/appel-offre/list-appel-offre">Appels d’offres</li>
                        <!-- <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li class="breadcrumb-item text-muted cursor-pointer" (click)="goToDetails()">Détails appel d'offre</li> -->
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li class="breadcrumb-item text-muted">Liste des candidatures</li>

                        <!--end::Item-->
                    </ul>
                    <!--end::Breadcrumb-->
                </div>
                <!--end::Page title-->
                <!--begin::Actions-->

                <!--end::Actions-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->
        <div id="kt_app_content" class="app-content flex-column-fluid mb-15">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container container-fluid">
                <!--begin::Card-->
                <div class="card">
                    <!--begin::Card header-->
                   <form [formGroup]="searchForm">
                    <div class="card-body ">
                        <div class="title mb-10">
                            <h4>Recherche</h4>
                        </div>
                        <div class="row mb-5 ">
                            <div class="col-lg-6">
                              <div class="input-group search-libre">
                                <span class="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                        fill="none">
                                        <path
                                            d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                            fill="#7E7C7C" />
                                    </svg>
                                </span>
                    
                                <input type="text" data-kt-customer-table-filter="search" 
                                    class="form-control ps-5" placeholder="Rechercher" formControlName="search" />
                            </div>
                             </div>
                          </div>
                          <div class="tools">
                           
                            <div class="row mb-3">
                                
                                <div class="col-lg-3">
                                    <input type="text"  id="nom" class="form-control form-control-solid mb-3" formControlName="name"
                                        placeholder="nom candidat">
                                </div>
                                <div class="col-lg-3">
                                    <div class="combined-input mb-3">
                                    
                                        <input type="number" formControlName="years_of_experience"
                                            oninput="validity.valid||(value='0');" min="0" class="form-control mb-3"
                                            placeholder="Années d’expériences" aria-label="Amount (to the nearest dollar)"
                                            class="nb1" />
                                       

                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <ng-select [items]="contrat" [bindValue]="'id'" [bindLabel]="'name'" [multiple]="true" id="contrat" formControlName="desired_contract" [notFoundText]="'Aucun élément trouvé'"
                                        placeholder="Type de contrat">
                                        </ng-select>
                                </div>
                                <div class="col-lg-3">
                                    <div class="combined-input">
                                        <input type="number"  oninput="validity.valid||(value='0');" min="0" formControlName="desired_salary" class="nb1" placeholder="Salaire" id="Salaire">
                                        <div class="euro">
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.889 8.739C6.435 8.739 6.91167 8.62633 7.319 8.401C7.735 8.167 8.06 7.82467 8.294 7.374H10.075C9.737 8.24067 9.19533 8.908 8.45 9.376C7.70467 9.844 6.851 10.078 5.889 10.078C4.875 10.078 3.97367 9.805 3.185 9.259C2.39633 8.70433 1.846 7.96767 1.534 7.049H0.338V6.035H1.3C1.28267 5.79233 1.274 5.60167 1.274 5.463C1.274 5.32433 1.28267 5.13367 1.3 4.891H0.338V3.877H1.534C1.846 2.95833 2.39633 2.226 3.185 1.68C3.97367 1.12533 4.875 0.847999 5.889 0.847999C6.84233 0.847999 7.69167 1.08633 8.437 1.563C9.191 2.031 9.737 2.69833 10.075 3.565H8.294C8.06 3.11433 7.735 2.77633 7.319 2.551C6.91167 2.317 6.435 2.2 5.889 2.2C5.25633 2.2 4.69733 2.34733 4.212 2.642C3.73533 2.93667 3.37133 3.34833 3.12 3.877H5.889V4.891H2.834C2.808 5.073 2.795 5.26367 2.795 5.463C2.795 5.66233 2.808 5.853 2.834 6.035H5.889V7.049H3.12C3.37133 7.58633 3.73533 8.00233 4.212 8.297C4.69733 8.59167 5.25633 8.739 5.889 8.739Z" fill="#4B4B4B"/>
                                                </svg>
                                                
                                        </div>
                                        <input type="number"  oninput="validity.valid||(value='0');" min="0"  formControlName="desired_salary_range" class="nb2" value="" id="marge_tjm">
                                       <div class="icon">
                                        <svg width="20" height="15" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.37 10L1.352 5.548V10H0.0780001V0.242H1.352V4.764L5.384 0.242H6.994L2.57 5.128L7.036 10H5.37Z" fill="#4B4B4B"/>
                                            <path d="M13.889 8.739C14.435 8.739 14.9117 8.62633 15.319 8.401C15.735 8.167 16.06 7.82467 16.294 7.374H18.075C17.737 8.24067 17.1953 8.908 16.45 9.376C15.7047 9.844 14.851 10.078 13.889 10.078C12.875 10.078 11.9737 9.805 11.185 9.259C10.3963 8.70433 9.846 7.96767 9.534 7.049H8.338V6.035H9.3C9.28267 5.79233 9.274 5.60167 9.274 5.463C9.274 5.32433 9.28267 5.13367 9.3 4.891H8.338V3.877H9.534C9.846 2.95833 10.3963 2.226 11.185 1.68C11.9737 1.12533 12.875 0.847999 13.889 0.847999C14.8423 0.847999 15.6917 1.08633 16.437 1.563C17.191 2.031 17.737 2.69833 18.075 3.565H16.294C16.06 3.11433 15.735 2.77633 15.319 2.551C14.9117 2.317 14.435 2.2 13.889 2.2C13.2563 2.2 12.6973 2.34733 12.212 2.642C11.7353 2.93667 11.3713 3.34833 11.12 3.877H13.889V4.891H10.834C10.808 5.073 10.795 5.26367 10.795 5.463C10.795 5.66233 10.808 5.853 10.834 6.035H13.889V7.049H11.12C11.3713 7.58633 11.7353 8.00233 12.212 8.297C12.6973 8.59167 13.2563 8.739 13.889 8.739Z" fill="#4B4B4B"/>
                                            </svg>
                                       </div>
                                       <div class="plusetmois">
                                        <i class="fa-solid fa-plus-minus"></i>
                                       </div>
                                            
                                    </div>
                                </div>

                            </div>
                              
                           
                            <div class="row mb-3" *ngIf="!isCollapsed">
                               
                               
                              
                                <div class="col-lg-3">
                                    <div class="combined-input">
                                        <input type="number" oninput="validity.valid||(value='0');" min="0"  formControlName="tjm" class="nb1" placeholder="TJM" id="tjm">
                                        <div class="euro">
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.889 8.739C6.435 8.739 6.91167 8.62633 7.319 8.401C7.735 8.167 8.06 7.82467 8.294 7.374H10.075C9.737 8.24067 9.19533 8.908 8.45 9.376C7.70467 9.844 6.851 10.078 5.889 10.078C4.875 10.078 3.97367 9.805 3.185 9.259C2.39633 8.70433 1.846 7.96767 1.534 7.049H0.338V6.035H1.3C1.28267 5.79233 1.274 5.60167 1.274 5.463C1.274 5.32433 1.28267 5.13367 1.3 4.891H0.338V3.877H1.534C1.846 2.95833 2.39633 2.226 3.185 1.68C3.97367 1.12533 4.875 0.847999 5.889 0.847999C6.84233 0.847999 7.69167 1.08633 8.437 1.563C9.191 2.031 9.737 2.69833 10.075 3.565H8.294C8.06 3.11433 7.735 2.77633 7.319 2.551C6.91167 2.317 6.435 2.2 5.889 2.2C5.25633 2.2 4.69733 2.34733 4.212 2.642C3.73533 2.93667 3.37133 3.34833 3.12 3.877H5.889V4.891H2.834C2.808 5.073 2.795 5.26367 2.795 5.463C2.795 5.66233 2.808 5.853 2.834 6.035H5.889V7.049H3.12C3.37133 7.58633 3.73533 8.00233 4.212 8.297C4.69733 8.59167 5.25633 8.739 5.889 8.739Z" fill="#4B4B4B"/>
                                                </svg>
                                                
                                        </div>
                                        <input type="number" oninput="validity.valid||(value='0');" min="0" formControlName="tjm_range" class="nb2" value="" id="marge_tjm">
                                       <div class="icon">
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.889 8.739C6.435 8.739 6.91167 8.62633 7.319 8.401C7.735 8.167 8.06 7.82467 8.294 7.374H10.075C9.737 8.24067 9.19533 8.908 8.45 9.376C7.70467 9.844 6.851 10.078 5.889 10.078C4.875 10.078 3.97367 9.805 3.185 9.259C2.39633 8.70433 1.846 7.96767 1.534 7.049H0.338V6.035H1.3C1.28267 5.79233 1.274 5.60167 1.274 5.463C1.274 5.32433 1.28267 5.13367 1.3 4.891H0.338V3.877H1.534C1.846 2.95833 2.39633 2.226 3.185 1.68C3.97367 1.12533 4.875 0.847999 5.889 0.847999C6.84233 0.847999 7.69167 1.08633 8.437 1.563C9.191 2.031 9.737 2.69833 10.075 3.565H8.294C8.06 3.11433 7.735 2.77633 7.319 2.551C6.91167 2.317 6.435 2.2 5.889 2.2C5.25633 2.2 4.69733 2.34733 4.212 2.642C3.73533 2.93667 3.37133 3.34833 3.12 3.877H5.889V4.891H2.834C2.808 5.073 2.795 5.26367 2.795 5.463C2.795 5.66233 2.808 5.853 2.834 6.035H5.889V7.049H3.12C3.37133 7.58633 3.73533 8.00233 4.212 8.297C4.69733 8.59167 5.25633 8.739 5.889 8.739Z" fill="#4B4B4B"/>
                                            </svg>
                                            
                                       </div>
                                       <div class="plusetmois">
                                        <i class="fa-solid fa-plus-minus"></i>
                                       </div>
                                            
                                    </div>
                                </div>

                
                               
                                <div class="col-lg-3">
                                    <ng-select [items]="skillsList"  formControlName="skillsAnd" [multiple]="true" id="compt"   bindValue="name_FR"
                                    placeholder="Compétences (et)" bindLabel="name_FR" [notFoundText]="'Aucun élément trouvé'">
                                    </ng-select>
                                </div>
                                <div class="col-lg-3">
                                    <ng-select [items]="skillsList"  bindLabel="name_FR" formControlName="skillsOr"
                                 bindValue="name_FR" [multiple]="true"
                                  [placeholder]="'Compétences (ou)'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                                 </ng-select>
                                </div>
                                <div class="col-lg-3">
                                   
                                    <ng-select [items]="disponibilite" [multiple]="true"   formControlName="availability" bindLabel="name" bindValue="id"  [notFoundText]="'Aucun élément trouvé'"
                                      [placeholder]="'Disponibilité'" autoComplete>
                                     </ng-select>
  
                                  </div>
                            </div>
                            <div class="row mb-3" *ngIf="!isCollapsed">
                           
            
                              
                                <div class="col-lg-3">

                                    <ng-select formControlName="current_country" (change)="onCountryChangeSearch('add')"  [multiple]="true"  [items]="paysList" bindLabel="name" 
                                bindValue="name" [placeholder]="'Pays'" [notFoundText]="'Aucun élément trouvé'"  autoComplete>
                              </ng-select>
                                </div>
                                <div class="col-lg-3">
                                    <ng-select  (change)="onMobilityChange('add')" [items]="stateList"  [multiple]="true"
                                   formControlName="mobility"
                                  bindLabel="name" bindValue="name"
                                    [placeholder]="'Ville'" autoComplete [notFoundText]="'Aucun élément trouvé'">
                                  </ng-select>

                                </div>
                                <div class="col-lg-3">
                                    <div class="position-relative d-flex align-items-center">
                                        <!--begin::Datepicker-->
                                        <input type="text" placeholder="Date de début de candidature"
                                            formControlName="date_start_candidature"
                                            class="form-control form-control-solid mb-3"
                                            onfocus="(this.type='date')">
                                        <!--end::Datepicker-->
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="position-relative d-flex align-items-center">
                                        <!--begin::Datepicker-->
                                        <input type="text" placeholder="Date de fin de candidature"
                                            formControlName="date_end_candidature"
                                            class="form-control form-control-solid mb-3"
                                            onfocus="(this.type='date')">
                                        <!--end::Datepicker-->
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3" *ngIf="!isCollapsed">      
                           </div>
                            <div class="row mb-3" *ngIf="!isCollapsed">
                           
                            </div>

                            <div class="row mb-3">
                                <div class="col-lg-3 mb-3"></div>
                                <div class="col-lg-3 mb-3"></div>
                                <div class="col-lg-3 mb-3"></div>
                                <div class="col-lg-3 mb-3 see-more">
                                    <a [ngClass]="{'show-more-button': !isCollapsed, 'emphasized-button': isCollapsed}"
                                        (click)="toggleCollapsible()" class="show-more-button">
                                        {{ isCollapsed ? 'Voir plus' : 'Voir moins' }} de critères de recherche
                                    </a>
                                </div>
                                <div class="mb-3 search-btn d-flex justify-content-end">
                                    <div class="align-items-center recherche">
                                      <button type="submit" class="me-5" (click)="searchCandidature()">
                                        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                            fill="white" />
                                        </svg>
                                        Rechercher</button>
                                    </div>
                                    <div class="resett-btn">
                                        <div class="reset-icon cursor-pointer">
                                            <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                                          </div>
                                      </div>
                                   
                                  </div>
                            </div>
                        </div>


                    </div>
                </form>
                </div>

            </div>
            <!--end::Content container-->
        </div>
        <!--begin::Content-->

        <div id="kt_app_content" class="app-content flex-column-fluid mb-4">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container container-fluid">
                <!--begin::Card-->
                <div class="card">
                    <!--begin::Card header-->
                    <div class="d-flex flex-wrap my-1 header-grid align-items-center">
                        <div class="section_title"> <h1 class="section-heading my-10">{{listcandidats[0]?.offre_poste }} <span *ngIf="listcandidats[0]?.offre_poste"> : </span> Liste des candidatures</h1>
                           
                        </div>

                        <!--begin::Tab nav-->
                        <ul class="nav nav-pills me-5">
                            <li class="nav-item m-0">
                                <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary  me-3"
                                    data-bs-toggle="tab" href="#kt_project_targets_card_pane">
                                    <i class="ki-duotone ki-element-plus fs-1">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                        <span class="path3"></span>
                                        <span class="path4"></span>
                                        <span class="path5"></span>
                                    </i>
                                </a>
                            </li>
                            <li class="nav-item m-0">
                                <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active"
                                    data-bs-toggle="tab" href="#kt_project_targets_table_pane">
                                    <i class="ki-duotone ki-row-horizontal fs-2">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                    </i>
                                </a>
                            </li>
                        </ul>
                        <!--end::Tab nav-->
                        <!--begin::Wrapper-->

                        <!--end::Wrapper-->
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body">
                        <div class="tab-content">
                            <!--begin::Tab pane-->
                            <div id="kt_project_targets_card_pane" class="tab-pane fade ">
                                <!--begin::Row-->
                                <div class="row g-6 g-xl-9">
                                    <!--begin::Col-->
                                    <div class="col-md-6 col-xxl-4" *ngFor="let c of listcandidats">
                                        <!--begin::Card-->
                                        <div class="card pt-5 p-5">
                                            <div class="tools">
                                                <td class="actions">
                                                    <div class="tools">
                                                        <div class="dropdown">
                                                            <button
                                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                type="button" id="dropdownMenuButton"
                                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <svg width="19" height="5" viewBox="0 0 19 5"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 0 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                        fill="#265D91" />
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a  [routerLink]="['/acceuil/candidats/profil-candidat', { idCandidat: c.id }]" class="dropdown-item">Voir
                                                                    profil</a>
                                                                <!-- <a class="dropdown-item">Postuler</a>
                                                                <a class="dropdown-item">Ne jamais voir cette c</a> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </div>
                                            <!--begin::Card body-->
                                            <div class="card-body d-flex flex-center flex-column">
                                                <!--begin::Avatar-->
                                                <div class="symbol symbol-65px symbol-circle mb-5">
                                                    <!-- <img src="assets/media//avatars/300-2.jpg" alt="image" /> -->
                                                    <img *ngIf="c.image_url && c.image_url!='avatar.png'" class="table-img" src="{{url+'/User/file/gallery_users/'+c.image_url}}" alt="" width="50px">
                                                    <img *ngIf="c.image_url && c.image_url=='avatar.png'" class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt="" width="50px">
                                                   
                                                    <div
                                                        class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3">
                                                    </div>
                                                </div>
                                                <!--end::Avatar-->
                                                <!--begin::Name-->
                                                <a  class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"><span>{{c.first_name}} {{c.last_name}}</span></a>
                                                <!--end::Name-->
                                                <!--begin::Position-->
                                                <!--end::Position-->
                                                <!--begin::Info-->
                                                <div class="card-body d-flex flex-column px-9 pt-6 pb-8">
                                                    <!--begin::Heading-->
                                                    <div class="fw-bold mb-3 mail text-center"> <svg width="15" height="10" viewBox="0 0 15 10"
                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                          d="M1.51185 0H13.4193C13.8339 0 14.212 0.169447 14.4868 0.443242C14.7611 0.717038 14.9307 1.09327 14.9307 1.50635V8.12962C14.9307 8.54414 14.7606 8.92085 14.4868 9.19369L14.4724 9.20709C14.199 9.47227 13.8272 9.63597 13.4188 9.63597H1.51137C1.0963 9.63597 0.718214 9.46653 0.44438 9.19321C0.170065 8.92085 0 8.54414 0 8.12962V1.50635C0 1.09327 0.170065 0.716559 0.44486 0.442764C0.719655 0.169447 1.09726 0.000478766 1.51185 0.000478766V0ZM1.81691 1.2742L1.55989 1.67772L7.46558 5.42038L13.3708 1.67772L13.1138 1.2742L7.4651 4.85365L1.81643 1.2742H1.81691Z"
                                                          fill="#265D91" />
                                                      </svg>
                                                      {{c.email}}</div>
                                                    <div class="fw-bold mb-3 phone text-center"><svg width="13" height="16" viewBox="0 0 13 16"
                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                          d="M12.3933 12.9149C12.0867 11.986 11.5346 11.1928 10.7542 10.5586C10.4535 10.3129 10.1549 10.1686 9.84222 10.1178C9.7207 10.0981 9.59605 10.093 9.4714 10.102C9.05836 10.1338 8.64624 10.2571 8.2118 10.4795C8.07498 10.5503 7.91751 10.5713 7.74455 10.5433C7.5336 10.5091 7.31749 10.4 7.1803 10.2584C6.18421 9.23037 5.37914 8.1136 4.71883 6.84499C4.58938 6.59524 4.53609 6.09005 4.83555 5.79797C5.18516 5.45768 5.43317 5.10671 5.59451 4.72519C5.76766 4.31403 5.75899 3.86957 5.56778 3.36769C5.21263 2.42649 4.63419 1.65278 3.84923 1.06715C3.59495 0.877219 3.32278 0.757591 3.03937 0.711397C2.56621 0.634467 2.08974 0.767714 1.66176 1.09623C1.26845 1.39769 0.910722 1.72658 0.494545 2.12742C0.113218 2.4944 0.0604813 2.9812 0.0136452 3.4102L0.0123545 3.41922C-0.0368787 3.96564 0.0606656 4.63703 0.311626 5.47222C0.735916 6.88548 1.43864 8.27611 2.46111 9.72397C2.67113 10.0218 3.5116 11.1873 3.72789 11.4807C4.77912 12.9079 5.87737 14.0151 7.08516 14.8647C7.79913 15.3673 8.40597 15.673 8.94053 15.7996L8.94956 15.8013C9.05983 15.8252 9.17803 15.8504 9.29383 15.8692C9.57945 15.9157 9.95414 15.9444 10.3211 15.7536C10.8343 15.4867 11.2604 15.2524 11.6703 14.9766C12.3909 14.4934 12.6609 13.7225 12.3933 12.9149ZM3.06647 3.48805C2.52196 2.73292 2.36227 1.91835 2.70985 1.66879C3.05744 1.41904 3.78063 1.8289 4.32515 2.58403C4.86966 3.33916 5.02935 4.15373 4.68195 4.4033C4.33418 4.65304 3.61099 4.24318 3.06647 3.48805ZM11.5305 13.9649C11.1829 14.2146 10.4598 13.8048 9.91523 13.0496C9.37072 12.2945 9.21103 11.4799 9.55843 11.2304C9.90583 10.9808 10.6292 11.3905 11.1737 12.1456C11.7184 12.9007 11.8781 13.7153 11.5305 13.9649Z"
                                                          fill="#265D91" />
                                                      </svg>
                                                      {{c.phone_number}}</div>
                                                    <!--end::Heading-->
                                                    <!--begin::Stats-->
                            
                                                    <!--end::Stats-->
                                                    <!--begin::Indicator-->
                                                
                                                    <!--end::Indicator-->
                                                  </div>
                                                

                                                <!--end::Info-->
                                            </div>
                                           
                                            <!--end::Card body-->
                                        </div>
                                        <!--end::Card-->
                                    </div>
                                    <!--end::Col-->
                                    <!--begin::Col-->
                                    <div class="alert alert-warning text-center mt-5" role="alert"
                                    *ngIf="listcandidats && !listcandidats.length">
                                    <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                                    <span class="empty-list">
                                        La liste est vide
                                    </span>
                                </div>
                                    <!--end::Col-->
                                </div>
                                <!--end::Row-->
                            </div>
                            <!--end::Tab pane-->
                            <!--begin::Tab pane-->
                            <div id="kt_project_targets_table_pane" class="tab-pane fade show active">
                                <div class="content">
                                    <!--begin::Card header-->
                                    <div class="card-header border-0 ">

                                        <!--begin::Card title-->
                                        <div class="card-title">
                                            <!--begin::Search-->
                                            <div class="d-flex align-items-center position-relative my-1">

                                            </div>
                                            <!--end::Search-->
                                        </div>
                                        <!--begin::Card title-->
                                        <!--begin::Card toolbar-->
                                       
                                        <!--end::Card toolbar-->
                                    </div>
                                    <!--end::Card header-->
                                    <!--begin::Card body-->
                                    <div class="card-body pt-15 padding-s">
                                        <!--begin::Table-->
                                        <div class="table-responsive">
                                            <table class="table align-middle fs-6 style-table"
                                                id="kt_customers_table">
                                                <thead>
                                                    <tr class="text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                                      
                                                       <th class="min-w-125px"><span>Image</span></th>
                                                        <!-- <th class="min-w-125px">ID User <i (click)="sortData('ID_user')" class="fa-solid fa-sort"></i></th> -->
                                                        <th class="min-w-125px th-fixed">NOM <i (click)="sortData('first_name')" class="fa-solid fa-sort"></i></th>
                                                        <th class="min-w-175px">Années d’expériences <i (click)="sortData('years_of_experience')" class="fa-solid fa-sort"></i></th>
                                                        <th class="min-w-175px">Date de candidature <i (click)="sortData('date_postule')" class="fa-solid fa-sort"></i> </th>
                                                        <!-- <th class="min-w-125px"><span>Score ESN</span></th>
                                                        <th class="min-w-125px"><span>Réponse ESN</span></th> -->
                                                        <!-- <th class="min-w-125px">NOM entreprise<i (click)="sortData('company_name')" class="fa-solid fa-sort"></i></th> -->
                                                        <!-- <th class="min-w-125px">type entreprise<i (click)="sortData('company_type')" class="fa-solid fa-sort"></i></th> -->
                                                        <!-- <th class="min-w-175px">TYPE DE CONTRAT <i
                                                            (click)="sortData('contract_type')"
                                                            class="fa-solid fa-sort"></i>
                                                        </th> -->

                                                            <!-- <th class="min-w-125px">Salaire demandé <i (click)="sortData('salary')"
                                                                class="fa-solid fa-sort"></i></th> -->

                                                            <!-- <th class="min-w-125px">TJM demandé <i (click)="sortData('tjm')"
                                                                class="fa-solid fa-sort"></i></th> -->
      
                                                        <!-- <th class="min-w-125px">TYPE DE POSTE <i
                                                                    (click)="sortData('post_type')"
                                                                    class="fa-solid fa-sort"></i>
                                                        </th> -->
                                                        <!-- <th class="min-w-125px">COMPéTENCES </th> -->
                                                        <!-- <th class="min-w-125px">Disponibilité <i
                                                            (click)="sortData('availability')"
                                                            class="fa-solid fa-sort"></i></th> -->
                                                       
                                                        <!-- <th class="min-w-125px">Note <i (click)="sortData('note')" class="fa-solid fa-sort"></i></th> -->
                                                        <!-- <th class="min-w-125px">SCORE  <i (click)="sortData('score')" class="fa-solid fa-sort"></i></th> -->
                                                    
                                                        <th class="min-w-125px">Actions</th>
                                                        <!-- <th class="min-w-90px">
                                                            <div class="dropdown" id="table-filter">
                                                                <button
                                                                    class="btn btn-sm btn-light btn-flex btn-centerdropdown-toggle"
                                                                    type="button" id="dropdownMenuButton"
                                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <svg width="28" height="28" viewBox="0 0 28 28"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g filter="url(#filter0_d_444_2002)">
                                                                            <rect x="4" width="20" height="20" rx="4"
                                                                                fill="#63C3E8" fill-opacity="0.12"
                                                                                shape-rendering="crispEdges" />
                                                                        </g>
                                                                        <path
                                                                            d="M17.5 10.6H14.6V13.5C14.6 13.8314 14.3314 14.1 14 14.1C13.6686 14.1 13.4 13.8314 13.4 13.5V10.6H10.5C10.1686 10.6 9.9 10.3314 9.9 10C9.9 9.66863 10.1686 9.4 10.5 9.4H13.4V6.5C13.4 6.16863 13.6686 5.9 14 5.9C14.3314 5.9 14.6 6.16863 14.6 6.5V9.4H17.5C17.8314 9.4 18.1 9.66863 18.1 10C18.1 10.3314 17.8314 10.6 17.5 10.6Z"
                                                                            fill="#265D91" stroke="#265D91"
                                                                            stroke-width="0.2" />
                                                                        <defs>
                                                                            <filter id="filter0_d_444_2002" x="0" y="0"
                                                                                width="28" height="28"
                                                                                filterUnits="userSpaceOnUse"
                                                                                color-interpolation-filters="sRGB">
                                                                                <feFlood flood-opacity="0"
                                                                                    result="BackgroundImageFix" />
                                                                                <feColorMatrix in="SourceAlpha"
                                                                                    type="matrix"
                                                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                                    result="hardAlpha" />
                                                                                <feOffset dy="4" />
                                                                                <feGaussianBlur stdDeviation="2" />
                                                                                <feComposite in2="hardAlpha"
                                                                                    operator="out" />
                                                                                <feColorMatrix type="matrix"
                                                                                    values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.13 0" />
                                                                                <feBlend mode="normal"
                                                                                    in2="BackgroundImageFix"
                                                                                    result="effect1_dropShadow_444_2002" />
                                                                                <feBlend mode="normal"
                                                                                    in="SourceGraphic"
                                                                                    in2="effect1_dropShadow_444_2002"
                                                                                    result="shape" />
                                                                            </filter>
                                                                        </defs>
                                                                    </svg>


                                                                </button>
                                                                <div class="dropdown-menu"
                                                                    aria-labelledby="dropdownMenuButton">
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            ID
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            NOM
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            N° TéL
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            EMAIL
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            MODIFIé
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            PAYS DE RéSIDENCE ACTUEL
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            ANS.EXP
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            CV
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            SCORE QCM
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            SCORE OFFRE DE L’EMPLOI
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        class="form-check form-check-custom form-check-solid mb-3">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            value="1" id="flexCheckDefault" checked />
                                                                        <label class="form-check-label"
                                                                            for="flexCheckDefault">
                                                                            RéPONSES
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </th> -->
                                                    </tr>
                                                </thead>
                                                <tbody class="fw-semibold text-gray-600">
                                                    <tr *ngFor="let c of listcandidats">

                                                        <td  class="wrapping">
                                                            <img *ngIf="c?.image_url&& c?.image_url!='avatar.png'" src="{{ url+'/User/file/gallery_users/' + c?.image_url }} " alt="" width="50px">
                                                            <img *ngIf="c?.image_url&& c?.image_url=='avatar.png'" class="table-img"
                                                             src="/assets/media/logos/logo-avatar.jpg" alt="" width="50px">
                                                          </td>
                                                        <td class="td-fixed">
                                                            <a class="text-gray-600 text-hover-primary mb-1"  [routerLink]="['/acceuil/candidats/profil-candidat', { idCandidat: c.id }]">{{c.first_name}} {{c.last_name}}</a>
                                                        </td>
                                                        <td>{{c.years_of_experience ? c.years_of_experience :'---'}} </td>
                                                        <td>  <span>
                                                            {{!c.date_postule  ? '---' : c.date_postule.split(' ')[0].split('-').reverse().join('/') }}
                                                        </span>
                                                        </td>
                                                        <!-- <td>{{c.company_name ? c.company_name :'---'}} </td> -->
                                                        <!-- <td> <span class="wrapping" [ngClass]="c?.company_type=='1'?'type-data':c.company_type=='2'?
                                                            'esn-type':c.company_type=='3'?'client-type':'centre-type'">
                                                              {{c?.company_type=='1'?'Entrepeise':c.company_type=='2'?
                                                              'ESN':c.company_type=='3'?'Client Final':'Cabinet de Recrutement'}}</span></td> -->
                                                       
                                                       
         
             
                                                        <!-- <td>
                                                            <ng-container
                                                                *ngFor="let contract of c?.desired_contract">
                                                                <div class="list-type" class="aligment-center">
                                                                    <span class="color-blue"
                                                                    *ngIf="contract==1">Alternance</span>
                                                                    <span class="color-blue" *ngIf="contract==2">Autres type
                                                                        de contrat</span>
                                                                <span class="color-blue"
                                                                    *ngIf="contract==3">CDD</span>&nbsp;&nbsp;
                                                                <span class="color-blue"
                                                                    *ngIf="contract==4">CDI</span>&nbsp;&nbsp;
                                                                    <span class="color-blue"
                                                                    *ngIf="contract==5">Freelance</span>
                                                                <span class="color-blue" *ngIf="contract==6">Portage
                                                                    salarial</span>&nbsp;&nbsp;
                                                                <span class="color-blue"
                                                                    *ngIf="contract==7">Stage</span>
                                                              
                                                                
                                                                    </div>
                                                            </ng-container>
                                                            <span class="color-blue"
                                                                *ngIf="c.desired_contract.length==0">---</span>

                                                        </td> -->
                                                        <!-- <td>
                                                            <span *ngIf="c.desired_salary!=null">{{c.desired_salary ? c.desired_salary + '&euro;' : '---'}} </span>
                                                            <span *ngIf="c.desired_salary==='null'">---</span>
                                                           

                                                        </td> -->
                                                        <!-- <td>
                                                            <span *ngIf="c.tjm!=null">{{c.tjm ? c.tjm + '&euro;' : '---'}} </span>
                                                            <span *ngIf="c.tjm==='null'">---</span>
                                                        </td> -->
                                                        <!-- <td>
                                                            <span *ngIf="c.post_type==1"
                                                            class="badge remote">À distance</span>
                                                        <span *ngIf="c.post_type==2"
                                                            class="badge onsite">hybrid</span>
                                                        <span *ngIf="c.post_type==3"
                                                            class="badge hybrid">sur site</span>
                                                        </td> -->

                                                        <!-- <td>
                                                            <ng-container *ngIf="c.skills">
                                                                <div class="skills">
                                                                    <span [ngClass]="backGroundColors[i]" class="badge"
                                                                        *ngFor="let skill of c.skills.slice(0,2) let i = index">
                                                                        {{ skill.sub_category.name_FR }} {{backGroundColors[i]}}
                                                                    </span>
                                                                </div>
                                                            </ng-container>
                                                            <span    *ngIf="c.skills?.length && c.skills?.length>2"><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.34144 4.86849H4.51344V7.76649H3.02944V4.86849H0.201438V3.52449H3.02944V0.626487H4.51344V3.52449H7.34144V4.86849Z" fill="#ACA7A7"/>
                                                                </svg>
                                                                </span>
                                                            <span *ngIf="c.skills.length==0">---</span>
                                                           
                                                        </td> -->
              
                                                        <!-- <td>
                                                            <span *ngIf="c.availability==1"
                                                            class="badge remote">Immédiate</span>
                                                        <span *ngIf="c.availability==2"
                                                            class="badge remote"> 1 mois</span>
                                                        <span *ngIf="c.availability==3"
                                                            class="badge remote">2
                                                            mois</span>
                                                        <span *ngIf="c.availability==4"
                                                            class="badge remote">3
                                                            mois</span>
                                                        <span *ngIf="c.availability==5"
                                                            class="badge remote">4
                                                            mois</span>
                                                        <span *ngIf="c.availability==6"
                                                            class="badge remote">5 mois</span>
                                                            <span class="color-blue"
                                                            *ngIf="c.availability.length==0">---</span>
                                                            <span *ngIf="c.availability==7"
                                                            class="color-blue">---</span>
                                                        </td> -->

                                                       <!-- <td>{{!c.note || c.note==="null" ?'---':c.note }}</td> -->
                                                        <!-- <td>
                                                            <div 
                                                                class="d-flex align-items-center flex-column mt-3 w-100">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                    <span  class="fw-bold fs-6 text-gray-400">{{ c.score ? c.score + '%' : '---' }}</span>
                                                                   

                                                                </div>
                                                                <div class="h-8px mx-3 w-100 score-light  rounded" *ngIf="c.score ">
                                                                    <div class="score-qcm rounded h-8px"
                                                                        role="progressbar"  [style.width.%]="c.score ? c.score : 0"
                                                                        aria-valuenow="50" aria-valuemin="0"
                                                                        aria-valuemax="100"></div>
                                                                </div>
                                                            </div>
                                                           
                                                        </td> -->
                                                        <!-- <td>
                                                            <a >
                                                              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M25.4273 4.05958V21.4387C25.4273 22.515 24.9783 23.5483 24.1801 24.3092C23.382 25.0701 22.2981 25.4982 21.1692 25.4982H4.25806C3.12915 25.4982 2.04528 25.0701 1.24716 24.3092C0.449034 23.5483 0 22.515 0 21.4387V4.05958C0 2.98329 0.449034 1.94995 1.24716 1.18903C2.04528 0.428104 3.12915 0 4.25806 0H21.1692C22.2981 0 23.382 0.428104 24.1801 1.18903C24.9783 1.94995 25.4273 2.98329 25.4273 4.05958ZM11.7058 19.7678C12.1182 20.1369 12.7193 20.2295 13.2288 20.0201C13.3766 19.9584 13.5118 19.8739 13.6314 19.7678L17.8543 15.9928C18.4216 15.4868 18.4497 14.636 17.919 14.0965C17.387 13.5557 16.4959 13.5275 15.9287 14.0348L14.0762 15.6908V6.71006C14.0762 5.96927 13.4456 5.36805 12.6686 5.36805C11.8916 5.36805 11.261 5.96927 11.261 6.71006V15.6908L9.40855 14.0348C8.84127 13.5275 7.95025 13.5557 7.41817 14.0965C6.88749 14.636 6.91565 15.4868 7.48292 15.9928L11.7058 19.7678Z"
                                                                  fill="#F58F29" />
                                                              </svg>
                                                            </a>
                                                          </td> -->
                                                        <!-- <td>
                                                            <div
                                                                class="d-flex align-items-center flex-column mt-3 w-100 w-125px">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                    <span class="fw-bold fs-6 text-gray-400">35%</span>
                                                                </div>
                                                                <div class="h-8px mx-3 w-100 score-light  rounded">
                                                                    <div class="score-empl rounded h-8px"
                                                                        role="progressbar" style="width: 35%;"
                                                                        aria-valuenow="50" aria-valuemin="0"
                                                                        aria-valuemax="100"></div>
                                                                </div>
                                                            </div>

                                                        </td> -->
                                                       
                                                        <td class="">
                                                            <!-- <button type="button"   [routerLink]="['/acceuil/candidats/profil-candidat', { idCandidat: c.id }]" class="btn btn-details"> Voir
                                                            </button> -->
                                                            <div class="tools">
                                                                <div class="dropdown">
                                                                    <button
                                                                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">
                                                                        <svg width="19" height="5" viewBox="0 0 19 5"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="2.44739" cy="2.44739"
                                                                                r="2.44739"
                                                                                transform="matrix(1 0 0 -1 0 4.89478)"
                                                                                fill="#265D91" />
                                                                            <circle cx="2.44739" cy="2.44739"
                                                                                r="2.44739"
                                                                                transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                                fill="#265D91" />
                                                                            <circle cx="2.44739" cy="2.44739"
                                                                                r="2.44739"
                                                                                transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                                fill="#265D91" />
                                                                        </svg>
                                                                    </button>
                                                                    <div class="dropdown-menu"
                                                                        aria-labelledby="dropdownMenuButton">
                                                                        <a class="dropdown-item cursor-pointer"
                                                                        [routerLink]="['/acceuil/candidats/profil-candidat', { idCandidat: c.id }]" >Voir profil
                                                                        </a>
                                                                        <!-- (click)="openModal(send,c)" -->
                                                                        <a class="dropdown-item cursor-pointer" [routerLink]="['/acceuil/appel-offre/dossier-candidature',c.id,c.offre_id ]">voir dossier candidature </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                               
                                                </tbody>
                                            </table>
                                        
                                        </div>
                                        <!--end::Table-->
                                        <div class="pagination" *ngIf="listcandidats && listcandidats.length">
                                            <app-pagination [data]="listcandidats" [lengthData]="totalItems"  [currentPageList]="currentPage" (setPages)="getItems($event)"> </app-pagination>
                                          </div>
                                       
                                    </div>
                                    <!--end::Card body-->
                                  

                                      <div class="alert alert-warning text-center mt-5" role="alert"
                                      *ngIf="listcandidats && !listcandidats.length">
                                      <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                                      <span class="empty-list">
                                          La liste est vide
                                      </span>
                                  </div>

                                </div>
                            </div>
                            <!--end::Tab pane-->
                        </div>
                        <!--end::Table-->
                    </div>
                    <!--end::Card body-->
                </div>
                <!--end::Card-->

                <!--begin::Modals-->
                <!--begin::Modal - Customers - Add-->

                <!--end::Modal - Customers - Add-->
                <!--begin::Modal - Adjust Balance-->

                <!--end::Modal - New Card-->
                <!--end::Modals-->
            </div>
            <!--end::Content container-->
        </div>

        <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
    <!--begin::Footer-->

    <!--end::Footer-->
    <!--end:::Main-->
</div>


<ng-template #send>
    <!--begin::Modal content-->
    <div class="modal-content" >
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
            <!--begin::Close-->
            <div class="btn btn-sm btn-icon btn-active-color-primary" (click)="closeModal()" >
                <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                </i>
            </div>
            <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0" [formGroup]="applyForm" >
            <!--begin::Heading-->
            <div class="text-center mb-13">
                <!--begin::Title-->
                <h1 class="mb-3">Dossier de candidature </h1>
            </div>
            <!--end::Heading-->
            
        
            <div class="mb-10">
              <label for="argument ">Argument Commercial</label>
              <textarea name="argument" id="argument" placeholder="Argument Commercial" formControlName="commercial_argument" class="form-control" readonly></textarea>
          </div>
          <div class="mb-10">
              <label for="date"  >Date de disponibilite </label>
                  <input type="date" formControlName="availibility_date" class="form-control style-input" readonly>
              </div>
          <div class="mb-10">
              <label for="prix"  >Prix de la proposition</label>
              <input type="text" class="form-control style-input" formControlName="tjm_vente"  placeholder="TJM (vente)" readonly>
          </div>
            <!--begin::Users-->
        
            <!--end::Users-->
            <!--begin::Notice-->
        
            <!--end::Notice-->
        </div>
        
        <div class="modal-footer">
            <!-- <a  class="annuler" (click)="closeModal()" style="cursor: pointer;">Annuler</a>
            <a  class="confirmer"  style="cursor: pointer;" (click)="applyCandidateJob(idJobs, state, postuled,'apply')"> Postuler <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.3014 6.93489L1.8289 0.125525C1.60951 0.0223123 1.36294 -0.0185918 1.11926 0.00780132C0.875591 0.0341944 0.645346 0.126744 0.456611 0.274162C0.267876 0.42158 0.128808 0.617495 0.0563695 0.838012C-0.0160691 1.05853 -0.0187478 1.29412 0.04866 1.51604L1.89651 7.60228H8.49911C8.61083 7.60228 8.71798 7.64404 8.79698 7.71838C8.87598 7.79272 8.92036 7.89355 8.92036 7.99868C8.92036 8.10382 8.87598 8.20464 8.79698 8.27899C8.71798 8.35333 8.61083 8.39509 8.49911 8.39509H1.89651L0.04866 14.4813C-0.00455989 14.6581 -0.0136708 14.8441 0.0220375 15.0248C0.0577458 15.2055 0.137309 15.3759 0.254525 15.5229C0.371741 15.6699 0.523444 15.7894 0.69781 15.8722C0.872177 15.9549 1.0645 15.9987 1.25978 16C1.45757 15.9996 1.65252 15.9557 1.82911 15.8718L16.3014 9.06248C16.5114 8.96368 16.6879 8.81182 16.8113 8.62394C16.9347 8.43605 17 8.21955 17 7.99868C17 7.77782 16.9347 7.56132 16.8113 7.37343C16.6879 7.18554 16.5114 7.03369 16.3014 6.93489Z" fill="white"/>
                </svg>
            </a>
             -->
  
        </div>
        <!--end::Modal body-->
    </div>
    <!--end::Modal content-->
  </ng-template>