import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard.component';
import { GeneralComponent } from './components/general/general.component';
import { DashboardCandidatComponent } from './components/dashboard-candidat/dashboard-candidat.component';
import { DashboardEsnComponent } from './components/dashboard-esn/dashboard-esn.component';
import { DashboardClientFinalComponent } from './components/dashboard-client-final/dashboard-client-final.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path:'general',
    component:GeneralComponent,
  },
  {
    path:'candidat',
    component:DashboardCandidatComponent,
  },
  {
    path:'esn',
    component:DashboardEsnComponent,
  },
  {
    path:'client-final',
    component:DashboardClientFinalComponent,
  },
  /* test */
  {
    path:'dash',
    component:DashboardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
