import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortDataService {

  
  sortArray(array: any[], column: string | undefined, direction: number): any[] {
    const compareFn = (a: any, b: any): any => {
      if (column) {
        const aValue = this.getValue(a, column);
        const bValue = this.getValue(b, column);
  
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return direction * (aValue - bValue);
        } else {
          const aString = String(aValue).toLowerCase();
          const bString = String(bValue).toLowerCase();
          return direction * aString.localeCompare(bString);
        }
      }
      return 0;
    };
  
    return array.sort(compareFn);
  }
  
  getValue(obj: any, column: string): any {
    return column.includes('.')
      ? column.split('.').reduce((o, i) => o[i], obj)
      : obj[column];
  }
  
}
