import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { listContracts } from '../../../../core/models/candidat';
import { serverError } from '../../../../core/models/messages';
import { TenderService } from '../../../../core/services/tender/tender.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-detail-job-request',
  templateUrl: './detail-job-request.component.html',
  styleUrls: ['./detail-job-request.component.css'],
})
export class DetailJobRequestComponent {
  colorsHeader: string[] = [
    '#FBF2E9',
    '#E8F6FC',
    '#FDEFEE',
    '#FFF3D7',
    '#FBF2E9',
  ];
  /* number */
  idRequestJob!: number;
  /* object */
  jobRequest!: any;
  listContrat: any = listContracts;
  /* string */
url:string=environment.baseUrl+'/api'
/* any */
jobSkills:any
safeDescription!: SafeHtml;

  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject()
constructor(private activatedRoute:ActivatedRoute,private spinner :NgxSpinnerService,private sanitizer: DomSanitizer,private jobRequestService:TenderService ,private toastrService:ToastrService){}
ngOnInit() {
  this.getIdRequestJob()
  this.getSkillsByJobRequest()
}
   /* get request job id from route */
 getIdRequestJob() {
  this.activatedRoute.paramMap.subscribe({
    next: (params: any) => {
      this.idRequestJob = params.params['id']
      this.getRequestJob()
    }
  })
}
/* get request job by id */
getRequestJob(){
this.spinner.show()
this.jobRequestService.getRequestJobById(this.idRequestJob).subscribe({
  next: (res) => {
    this.spinner.hide()
    this.jobRequest = res.data[0]
    this.updateDescription(this.jobRequest.description);
    console.log("jobrequest", this.jobRequest)
  },
  error: () => {
    this.spinner.hide()
    this.toastrService.error(serverError)
  }
})
}
updateDescription(description: string) {
  this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(description);
}

getSkillsByJobRequest() {
  this.spinner.show()
  let job_offer_id = this.idRequestJob
  this.jobRequestService.getskillsByJobRequest(job_offer_id).subscribe({
    next: (res: any) => {
      this.spinner.hide()
      this.jobSkills = res.data
      console.log("jobskills",this.jobSkills)
    },
    error: () => {
      this.spinner.hide()
      this.toastrService.error(serverError)
    }
  })
}
 /* unsubscribe from api */
 ngOnDestroy() {
  this.unsubscribeAll.next();
  this.unsubscribeAll.complete();
}
}
