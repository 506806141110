<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div class="app-main flex-column flex-row-fluid" id="kt_app_main">
  <!--begin::Content wrapper-->
  <div class="d-flex flex-column flex-column-fluid">
    <!--begin::Toolbar-->
    <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
      <!--begin::Toolbar container-->
      <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <!--begin::Title-->
        <div class="title">
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_61_103)">
            <path d="M22.2955 13.5729C22.2955 16.065 21.3318 18.4551 19.6162 20.2171C17.9006 21.9793 15.5738 22.9693 13.1478 22.9693C10.7216 22.9693 8.39481 21.9793 6.67936 20.2171C4.96379 18.4549 4 16.0649 4 13.5729C4 11.0809 4.96379 8.69083 6.67936 6.92877C8.39494 5.16659 10.7217 4.17661 13.1478 4.17661C13.4175 4.17661 13.676 4.28654 13.8665 4.4823C14.0571 4.67806 14.1643 4.94371 14.1643 5.22059V12.5288H21.2792C21.5487 12.5288 21.8073 12.6389 21.9979 12.8347C22.1885 13.0304 22.2955 13.2959 22.2955 13.5729Z" fill="#265D91"/>
            <path d="M17.4169 0C17.1532 0 16.9005 0.107665 16.7141 0.299071C16.5278 0.490478 16.423 0.750091 16.423 1.02095V9.18778V9.18761C16.423 9.45849 16.5278 9.71808 16.7141 9.90949C16.9005 10.1009 17.1532 10.2086 17.4169 10.2086H25.3677H25.3675C25.6312 10.2086 25.884 10.1009 26.0703 9.90949C26.2567 9.71808 26.3615 9.45847 26.3615 9.18761C26.3615 6.75095 25.4191 4.41404 23.7417 2.69116C22.0643 0.968146 19.7892 0.000174737 17.4171 0.000174737L17.4169 0Z" fill="#265D91"/>
            </g>
            <defs>
            <filter id="filter0_d_61_103" x="0" y="0" width="30.3615" height="30.9693" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_61_103"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_61_103" result="shape"/>
            </filter>
            </defs>
            </svg>

          <h1 class="page-heading d-flex flex-column justify-content-center my-0">
            Analytique</h1>
        </div>
          <!--end::Title-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
      </div>
      <!--end::Toolbar container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
      <!--begin::Content container-->
      <div id="kt_app_content_container" class="app-container container-fluid">
        <div class="row charts gy-5 g-xl-10 mt-5">
          <!--begin::Col-->
          <div class="col-lg-3 col-12  mb-xl-10">
            <!--begin::Card widget 2-->
            <div class="card height bg1">
              <!--begin::Body-->
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <!--begin::Icon-->
                <div class="m-0">
                  <svg width="42" height="30" viewBox="0 0 42 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34.8345 12.8032C37.0851 12.8032 38.9096 10.9722 38.9096 8.71362C38.9096 6.45502 37.0851 4.62405 34.8345 4.62405C32.5839 4.62405 30.7595 6.45502 30.7595 8.71362C30.7595 10.9722 32.5839 12.8032 34.8345 12.8032Z" fill="#2C2C2C"/>
                    <path d="M40.427 16.5304C38.6024 16.1286 36.7214 15.9269 34.839 15.9269C34.1094 15.9269 33.3794 15.9579 32.6523 16.0185C32.2923 16.0485 32.0405 16.3723 32.0604 16.7341C32.0679 16.8757 32.0679 17.0168 32.0589 17.158C31.8949 19.3689 31.7308 21.6208 31.5673 23.9173C31.4815 25.0884 31.0606 26.2289 30.4198 27.2157C30.6422 27.3173 30.8701 27.3934 31.1005 27.4319C32.5676 27.6451 34.078 27.7307 35.5785 27.6906C40.1966 27.3949 41.7509 22.4806 41.9973 18.6307C42.0516 17.7645 41.2892 16.7401 40.427 16.5304Z" fill="#2C2C2C"/>
                    <path d="M7.16415 12.8032C9.41474 12.8032 11.2392 10.9722 11.2392 8.71362C11.2392 6.45502 9.41474 4.62405 7.16415 4.62405C4.91355 4.62405 3.08908 6.45502 3.08908 8.71362C3.08908 10.9722 4.91355 12.8032 7.16415 12.8032Z" fill="#2C2C2C"/>
                    <path d="M0.00293717 18.6387C0.164504 20.8341 0.34851 22.7838 1.64104 24.638C2.74508 26.2213 4.44701 27.57 6.43269 27.6921C7.90972 27.7342 9.42216 27.6476 10.9067 27.4314C11.1351 27.3924 11.3605 27.3168 11.5809 27.2162C10.9396 26.2294 10.5187 25.0894 10.433 23.9193C10.2689 21.6213 10.1048 19.3694 9.94177 17.1659C9.9328 17.0218 9.9323 16.8777 9.94028 16.7336C9.96022 16.3718 9.7084 16.0485 9.34836 16.0184C8.62431 15.9579 7.89676 15.9274 7.16921 15.9274C5.28227 15.9274 3.39384 16.1295 1.55777 16.5339C0.709541 16.7401 -0.0534116 17.7645 0.00293717 18.6387Z" fill="#2C2C2C"/>
                    <path d="M20.9996 10.2559C23.8217 10.2559 26.1094 7.96007 26.1094 5.12797C26.1094 2.29587 23.8217 0 20.9996 0C18.1776 0 15.8898 2.29587 15.8898 5.12797C15.8898 7.96007 18.1776 10.2559 20.9996 10.2559Z" fill="#2C2C2C"/>
                    <path d="M28.3569 14.0737C25.9549 13.5443 23.48 13.2795 21.0032 13.2795C18.5223 13.2795 16.0395 13.5453 13.626 14.0772C12.3973 14.3755 11.3381 15.8007 11.4189 17.0633C11.582 19.2608 11.7455 21.5142 11.9096 23.8117C12.0806 26.1428 13.992 28.4092 16.0829 28.7595C19.3366 29.233 22.6522 29.233 25.922 28.7585C28.0104 28.4042 29.9173 26.1403 30.0883 23.8107C30.2524 21.5142 30.4159 19.2608 30.5795 17.0558C30.6608 15.8002 29.6011 14.3755 28.3569 14.0737Z" fill="#2C2C2C"/>
                    </svg>
                </div>
                <!--end::Icon-->
                <!--begin::Section-->
                <div class="d-flex flex-column my-7">
                  <div class="mb-3">
                    <span class="fw-semibold title-card fs-6 mb-3">Nombre des ESN active</span>
                  </div>
                  <!--begin::Number-->
                  <span class="score mb-3  lh-1 ls-n2">{{statistic?.nb_ESN_active?statistic?.nb_ESN_active:'---'}}</span>
                  <!--end::Number-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card widget 2-->
          </div>
          <div class="col-lg-3 col-12  mb-xl-10">
            <!--begin::Card widget 2-->
            <div class="card height bg1">
              <!--begin::Body-->
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <!--begin::Icon-->
                <div class="m-0">
                  <svg width="42" height="30" viewBox="0 0 42 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34.8345 12.8032C37.0851 12.8032 38.9096 10.9722 38.9096 8.71362C38.9096 6.45502 37.0851 4.62405 34.8345 4.62405C32.5839 4.62405 30.7595 6.45502 30.7595 8.71362C30.7595 10.9722 32.5839 12.8032 34.8345 12.8032Z" fill="#2C2C2C"/>
                    <path d="M40.427 16.5304C38.6024 16.1286 36.7214 15.9269 34.839 15.9269C34.1094 15.9269 33.3794 15.9579 32.6523 16.0185C32.2923 16.0485 32.0405 16.3723 32.0604 16.7341C32.0679 16.8757 32.0679 17.0168 32.0589 17.158C31.8949 19.3689 31.7308 21.6208 31.5673 23.9173C31.4815 25.0884 31.0606 26.2289 30.4198 27.2157C30.6422 27.3173 30.8701 27.3934 31.1005 27.4319C32.5676 27.6451 34.078 27.7307 35.5785 27.6906C40.1966 27.3949 41.7509 22.4806 41.9973 18.6307C42.0516 17.7645 41.2892 16.7401 40.427 16.5304Z" fill="#2C2C2C"/>
                    <path d="M7.16415 12.8032C9.41474 12.8032 11.2392 10.9722 11.2392 8.71362C11.2392 6.45502 9.41474 4.62405 7.16415 4.62405C4.91355 4.62405 3.08908 6.45502 3.08908 8.71362C3.08908 10.9722 4.91355 12.8032 7.16415 12.8032Z" fill="#2C2C2C"/>
                    <path d="M0.00293717 18.6387C0.164504 20.8341 0.34851 22.7838 1.64104 24.638C2.74508 26.2213 4.44701 27.57 6.43269 27.6921C7.90972 27.7342 9.42216 27.6476 10.9067 27.4314C11.1351 27.3924 11.3605 27.3168 11.5809 27.2162C10.9396 26.2294 10.5187 25.0894 10.433 23.9193C10.2689 21.6213 10.1048 19.3694 9.94177 17.1659C9.9328 17.0218 9.9323 16.8777 9.94028 16.7336C9.96022 16.3718 9.7084 16.0485 9.34836 16.0184C8.62431 15.9579 7.89676 15.9274 7.16921 15.9274C5.28227 15.9274 3.39384 16.1295 1.55777 16.5339C0.709541 16.7401 -0.0534116 17.7645 0.00293717 18.6387Z" fill="#2C2C2C"/>
                    <path d="M20.9996 10.2559C23.8217 10.2559 26.1094 7.96007 26.1094 5.12797C26.1094 2.29587 23.8217 0 20.9996 0C18.1776 0 15.8898 2.29587 15.8898 5.12797C15.8898 7.96007 18.1776 10.2559 20.9996 10.2559Z" fill="#2C2C2C"/>
                    <path d="M28.3569 14.0737C25.9549 13.5443 23.48 13.2795 21.0032 13.2795C18.5223 13.2795 16.0395 13.5453 13.626 14.0772C12.3973 14.3755 11.3381 15.8007 11.4189 17.0633C11.582 19.2608 11.7455 21.5142 11.9096 23.8117C12.0806 26.1428 13.992 28.4092 16.0829 28.7595C19.3366 29.233 22.6522 29.233 25.922 28.7585C28.0104 28.4042 29.9173 26.1403 30.0883 23.8107C30.2524 21.5142 30.4159 19.2608 30.5795 17.0558C30.6608 15.8002 29.6011 14.3755 28.3569 14.0737Z" fill="#2C2C2C"/>
                    </svg>
                </div>
                <!--end::Icon-->
                <!--begin::Section-->
                <div class="d-flex flex-column my-7">
                  <div class="mb-3">
                    <span class="fw-semibold title-card fs-6 mb-3">Nombre des cabinets de recrutement active</span>
                  </div>
                  <!--begin::Number-->
                  <span class="score mb-3  lh-1 ls-n2">{{statistic?.nb_Cabinet_active?statistic?.nb_Cabinet_active:'---'}}</span>
                  <!--end::Number-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card widget 2-->
          </div>
          <div class="col-lg-3 col-12 mb-xl-10">
            <!--begin::Card widget 2-->
            <div class="card height bg2">
              <!--begin::Body-->
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <!--begin::Icon-->
                <div class="m-0">
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M38.8781 36.8166C39.8823 36.0263 40.5413 34.8001 40.5413 33.4091C40.5413 31.0281 38.6427 29.0909 36.309 29.0909C34.2041 29.0909 32.4673 30.6712 32.1442 32.7273H22.7209V23.1818H27.3988H32.7449C33.1138 23.1818 33.4131 22.8764 33.4131 22.5C33.4131 17.2172 29.9274 12.7576 25.1821 11.3666C26.9087 10.287 28.067 8.34966 28.067 6.13636C28.067 2.75257 25.3688 0 22.0527 0C18.7366 0 16.0383 2.75257 16.0383 6.13636C16.0383 8.34966 17.1966 10.287 18.9233 11.3666C14.178 12.7576 10.6922 17.2172 10.6922 22.5C10.6922 22.8764 10.9915 23.1818 11.3605 23.1818H16.7066H21.3844V32.7273H11.9611C11.638 30.6712 9.90125 29.0909 7.7964 29.0909C5.4627 29.0909 3.56407 31.0281 3.56407 33.4091C3.56407 34.8001 4.22303 36.0263 5.22722 36.8166C2.18844 37.9034 0 40.8514 0 44.3182C0 44.6946 0.299326 45 0.668263 45H4.23233H11.3605H14.9245C15.2935 45 15.5928 44.6946 15.5928 44.3182C15.5928 40.8514 13.4044 37.9034 10.3656 36.8166C11.2012 36.159 11.787 35.1991 11.9611 34.0909H22.0527H32.1442C32.3184 35.1991 32.9041 36.159 33.7398 36.8166C30.701 37.9034 28.5126 40.8514 28.5126 44.3182C28.5126 44.6946 28.8119 45 29.1808 45H32.7449H39.873H43.4371C43.806 45 44.1054 44.6946 44.1054 44.3182C44.1054 40.8514 41.9169 37.9034 38.8781 36.8166Z" fill="#2C2C2C"/>
                    </svg>
                </div>
                <!--end::Icon-->
                <!--begin::Section-->
                <div class="d-flex flex-column my-7">
                  <div class="mb-3">
                    <span class="fw-semibold title-card fs-6">Nombre Total ESN</span>
                  </div>
                  <!--begin::Number-->
                  <span class=" score2 mb-3  lh-1 ls-n2">{{statistic?.nb_ESN?statistic?.nb_ESN:'---'}}</span>
                  <!--end::Number-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card widget 2-->
          </div>
          <div class="col-lg-3 col-12 height mb-xl-10">
            <!--begin::Card widget 2-->
            <div class="card h-lg-100 bg3">
              <!--begin::Body-->
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <!--begin::Icon-->
                <div class="m-0">
                  <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.5701 42.9267C20.5543 42.9267 20.5385 42.9274 20.5234 42.9281C20.485 42.4901 20.297 42.0839 19.984 41.7694C19.6312 41.4149 19.1624 41.2197 18.6634 41.2197C18.6477 41.2197 18.6319 41.2204 18.6168 41.2211C18.5783 40.7831 18.3903 40.3769 18.0773 40.0624C17.7245 39.7079 17.255 39.5127 16.7568 39.5127C16.741 39.5127 16.7252 39.5134 16.7101 39.5141C16.6716 39.0762 16.4836 38.6699 16.1706 38.3554C15.4424 37.6237 14.2571 37.6237 13.5288 38.3554L12.2625 39.6279C11.5343 40.3597 11.5343 41.5508 12.2625 42.2825C12.627 42.6481 13.1054 42.8315 13.5831 42.8315C13.5989 42.8315 13.614 42.8295 13.6297 42.8295C13.6682 43.2681 13.8556 43.675 14.1692 43.9902C14.522 44.3447 14.9915 44.5399 15.4898 44.5399C15.5055 44.5399 15.522 44.5392 15.5378 44.5385C15.5755 44.9613 15.7547 45.3737 16.0759 45.6972C16.4403 46.0634 16.918 46.2462 17.3964 46.2462C17.4122 46.2462 17.4273 46.2441 17.4431 46.2441C17.4815 46.6828 17.6696 47.0897 17.9826 47.4049C18.3354 47.7594 18.8048 47.9545 19.3031 47.9545C19.8014 47.9545 20.2709 47.7594 20.6237 47.4049L21.89 46.1324C22.2428 45.7779 22.437 45.3061 22.437 44.8054C22.437 44.3047 22.2428 43.833 21.89 43.4785C21.5379 43.1219 21.0691 42.9267 20.5701 42.9267Z" fill="#2C2C2C"/>
                    <path d="M31.7536 27.0562L26.1997 26.0079C25.1098 25.8024 23.9745 26.0651 23.0836 26.7293L17.6594 30.7737C17.1474 31.1551 16.8523 31.7413 16.8495 32.3813C16.8468 33.0214 17.1364 33.6104 17.6443 33.9973C18.5949 34.7201 19.9113 34.778 20.9216 34.1421L23.5682 32.4744C23.8764 33.091 24.4509 33.9283 25.4495 34.4442C26.6067 35.0421 28.0137 35.0573 29.6355 34.4904L34.5862 38.0733C34.6953 38.1527 34.8244 38.1927 34.9548 38.1927C35.0495 38.1927 35.1442 38.1713 35.2321 38.1278L38.6384 36.4519L34.4393 25.7748L31.7536 27.0562Z" fill="#2C2C2C"/>
                    <path d="M46.9541 34.7814L41.8175 21.2849C41.6836 20.9338 41.2958 20.7545 40.943 20.88L39.0988 21.5408L41.8257 29.106C41.9554 29.4653 41.7701 29.8619 41.4132 29.9922C41.3356 30.0205 41.2567 30.0336 41.1785 30.0336C40.8971 30.0336 40.6328 29.8584 40.5312 29.577L37.8016 22.0049L35.5229 22.8215C35.3472 22.8843 35.2045 23.0167 35.1269 23.1871C35.05 23.3581 35.0452 23.5526 35.1139 23.7271L40.5333 37.5078C40.6019 37.6816 40.7371 37.8209 40.9094 37.8926C40.9938 37.9278 41.0837 37.9457 41.1737 37.9457C41.2663 37.9457 41.3597 37.9271 41.4461 37.8892L46.5828 35.6635C46.9225 35.5166 47.0866 35.1277 46.9541 34.7814Z" fill="#2C2C2C"/>
                    <path d="M11.8728 23.1877C11.7959 23.0167 11.6524 22.885 11.4767 22.8222L9.19805 22.0056L6.46843 29.5777C6.36685 29.8591 6.1026 30.0343 5.8212 30.0343C5.74296 30.0343 5.66403 30.0212 5.58647 29.9929C5.22888 29.8626 5.04425 29.466 5.17397 29.1067L7.90084 21.5414L6.05662 20.8807C5.70452 20.7545 5.31605 20.9338 5.18221 21.2856L0.0455588 34.7821C-0.0862205 35.1283 0.0771309 35.5173 0.416188 35.6642L5.55284 37.8899C5.64 37.9278 5.73266 37.9464 5.82532 37.9464C5.91523 37.9464 6.00514 37.9285 6.08956 37.8933C6.26184 37.8216 6.39705 37.683 6.46568 37.5085L11.8851 23.7278C11.9544 23.5533 11.9496 23.3581 11.8728 23.1877Z" fill="#2C2C2C"/>
                    <path d="M34.9548 39.5762C34.5313 39.5762 34.1257 39.4445 33.7819 39.1962L29.3858 36.0146C28.7056 36.1967 28.0481 36.2884 27.4242 36.2884C26.4839 36.2884 25.6081 36.0822 24.8209 35.6753C24.1228 35.3146 23.5841 34.8422 23.1716 34.3587L21.6547 35.3146C20.978 35.7408 20.1955 35.9664 19.3911 35.9664C18.4639 35.9664 17.549 35.6594 16.8153 35.1015C15.958 34.449 15.4693 33.4559 15.4741 32.3758C15.4796 31.2958 15.9772 30.3068 16.8407 29.6633L20.7721 26.7314L20.619 26.6211C19.5305 25.8334 18.1125 25.5927 16.8262 25.9769L14.4988 26.6735L12.6464 25.561L8.1947 36.8774L11.3004 38.6362L12.5537 37.3761C13.1666 36.7609 13.9813 36.4222 14.8482 36.4222C15.7157 36.4222 16.5304 36.7609 17.1427 37.3761C17.4096 37.6444 17.6265 37.9561 17.7851 38.2948C18.2566 38.4527 18.6883 38.7196 19.05 39.0831C19.317 39.3514 19.5339 39.6631 19.6924 40.0024C20.164 40.1604 20.5957 40.4273 20.9574 40.7907C21.2244 41.059 21.4413 41.3708 21.5998 41.7094C22.0713 41.8673 22.5031 42.1343 22.8648 42.4977C23.4777 43.1136 23.8154 43.9323 23.8154 44.8034C23.8154 45.6744 23.4777 46.4931 22.8654 47.109L22.8476 47.1269L23.1153 47.329C23.5456 47.6532 24.0556 47.8118 24.5628 47.8118C25.1929 47.8118 25.8209 47.567 26.289 47.089L26.3672 47.0097C26.7166 46.6531 26.9465 46.1945 27.0282 45.7062C27.847 45.9379 28.7612 45.7282 29.3981 45.0875L29.4551 45.0296C29.8449 44.6378 30.0859 44.1302 30.1476 43.5902C30.9781 43.8392 31.8992 43.6247 32.5437 42.9771L32.5821 42.9384C32.9582 42.5605 33.1978 42.0681 33.2685 41.5446C34.0626 41.7377 34.9246 41.517 35.5423 40.8963C36.0008 40.4356 36.2527 39.8141 36.25 39.1679L35.8375 39.3707C35.565 39.5052 35.2596 39.5762 34.9548 39.5762Z" fill="#2C2C2C"/>
                    <path d="M37.3562 11.563C37.3562 11.9451 37.6644 12.2548 38.0446 12.2548H41.8594C42.2396 12.2548 42.5478 11.9451 42.5478 11.563C42.5478 11.1809 42.2396 10.8713 41.8594 10.8713H38.0446C37.6644 10.8713 37.3562 11.1809 37.3562 11.563Z" fill="#2C2C2C"/>
                    <path d="M36.6144 8.40906C36.7331 8.40906 36.8525 8.37802 36.9624 8.31388L39.8237 6.62552C40.1518 6.4324 40.261 6.00824 40.0688 5.67857C39.8759 5.3489 39.4545 5.23855 39.1264 5.43235L36.265 7.12071C35.9369 7.31383 35.8271 7.73799 36.02 8.06766C36.1483 8.28698 36.3783 8.40906 36.6144 8.40906Z" fill="#2C2C2C"/>
                    <path d="M36.2656 16.006L39.127 17.6944C39.2368 17.7592 39.3562 17.7896 39.4749 17.7896C39.7111 17.7896 39.941 17.6675 40.0693 17.4482C40.2622 17.1185 40.1524 16.695 39.8243 16.5012L36.9629 14.8128C36.6348 14.619 36.2134 14.7294 36.0205 15.0591C35.8277 15.388 35.9375 15.8122 36.2656 16.006Z" fill="#2C2C2C"/>
                    <path d="M5.14105 12.2548H8.95578C9.33602 12.2548 9.64419 11.9451 9.64419 11.563C9.64419 11.1809 9.33602 10.8713 8.95578 10.8713H5.14105C4.76081 10.8713 4.45264 11.1809 4.45264 11.563C4.45264 11.9451 4.76081 12.2548 5.14105 12.2548Z" fill="#2C2C2C"/>
                    <path d="M7.17678 6.62488L10.0382 8.31324C10.148 8.37807 10.2674 8.40842 10.3862 8.40842C10.6223 8.40842 10.8522 8.28634 10.9805 8.06702C11.1734 7.73735 11.0636 7.31388 10.7355 7.12007L7.87411 5.43171C7.54604 5.23791 7.12462 5.34826 6.93175 5.67793C6.73889 6.0076 6.8487 6.43176 7.17678 6.62488Z" fill="#2C2C2C"/>
                    <path d="M10.0374 14.8128L7.17597 16.5012C6.8479 16.6943 6.73808 17.1185 6.93094 17.4482C7.05929 17.6675 7.28922 17.7896 7.52532 17.7896C7.64406 17.7896 7.76349 17.7585 7.8733 17.6944L10.7347 16.006C11.0628 15.8129 11.1726 15.3887 10.9797 15.0591C10.7875 14.7294 10.3654 14.619 10.0374 14.8128Z" fill="#2C2C2C"/>
                    <path d="M23.4999 18.251C27.1698 18.251 30.1555 15.2508 30.1555 11.563C30.1555 7.87527 27.1698 4.87512 23.4999 4.87512C19.83 4.87512 16.8444 7.87527 16.8444 11.563C16.8444 15.2508 19.83 18.251 23.4999 18.251ZM19.3585 10.7623C19.6365 10.5016 20.0723 10.5168 20.3317 10.7961L22.4587 13.0859L26.6688 8.55393C26.9283 8.27461 27.3641 8.25943 27.6421 8.52013C27.92 8.78084 27.9351 9.21879 27.6757 9.49812L22.9625 14.5715C22.8321 14.7115 22.6502 14.7915 22.4594 14.7915C22.2686 14.7915 22.086 14.7122 21.9563 14.5715L19.3255 11.7396C19.0654 11.461 19.0805 11.023 19.3585 10.7623Z" fill="#2C2C2C"/>
                    <path d="M23.5002 22.4015C29.4474 22.4015 34.2862 17.5392 34.2862 11.563C34.2862 5.58686 29.4474 0.724548 23.5002 0.724548C17.5529 0.724548 12.7142 5.58686 12.7142 11.563C12.7142 17.5399 17.5529 22.4015 23.5002 22.4015ZM23.5002 3.49159C27.9292 3.49159 31.5325 7.11246 31.5325 11.563C31.5325 16.0136 27.9292 19.6345 23.5002 19.6345C19.0712 19.6345 15.4678 16.0136 15.4678 11.563C15.4678 7.11246 19.0712 3.49159 23.5002 3.49159Z" fill="#2C2C2C"/>
                    </svg>
                </div>
                <!--end::Icon-->
                <!--begin::Section-->
                <div class="d-flex flex-column my-7">
                  <div class="mb-3">
                    <span class="fw-semibold title-card fs-6">Nombre Total de clients finaux  </span>
                  </div>
                  <!--begin::Number-->
                  <span class=" score3 mb-3  lh-1 ls-n2">{{statistic?.nb_Client?statistic?.nb_Client:'---'}}</span>
                  <!--end::Number-->
                  <!--begin::Follower-->

                  <!--end::Follower-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card widget 2-->
          </div>
          <div class="col-lg-3 col-12 height mb-xl-10">
            <!--begin::Card widget 2-->
            <div class="card h-lg-100 bg3">
              <!--begin::Body-->
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <!--begin::Icon-->
                <div class="m-0">
                  <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.5701 42.9267C20.5543 42.9267 20.5385 42.9274 20.5234 42.9281C20.485 42.4901 20.297 42.0839 19.984 41.7694C19.6312 41.4149 19.1624 41.2197 18.6634 41.2197C18.6477 41.2197 18.6319 41.2204 18.6168 41.2211C18.5783 40.7831 18.3903 40.3769 18.0773 40.0624C17.7245 39.7079 17.255 39.5127 16.7568 39.5127C16.741 39.5127 16.7252 39.5134 16.7101 39.5141C16.6716 39.0762 16.4836 38.6699 16.1706 38.3554C15.4424 37.6237 14.2571 37.6237 13.5288 38.3554L12.2625 39.6279C11.5343 40.3597 11.5343 41.5508 12.2625 42.2825C12.627 42.6481 13.1054 42.8315 13.5831 42.8315C13.5989 42.8315 13.614 42.8295 13.6297 42.8295C13.6682 43.2681 13.8556 43.675 14.1692 43.9902C14.522 44.3447 14.9915 44.5399 15.4898 44.5399C15.5055 44.5399 15.522 44.5392 15.5378 44.5385C15.5755 44.9613 15.7547 45.3737 16.0759 45.6972C16.4403 46.0634 16.918 46.2462 17.3964 46.2462C17.4122 46.2462 17.4273 46.2441 17.4431 46.2441C17.4815 46.6828 17.6696 47.0897 17.9826 47.4049C18.3354 47.7594 18.8048 47.9545 19.3031 47.9545C19.8014 47.9545 20.2709 47.7594 20.6237 47.4049L21.89 46.1324C22.2428 45.7779 22.437 45.3061 22.437 44.8054C22.437 44.3047 22.2428 43.833 21.89 43.4785C21.5379 43.1219 21.0691 42.9267 20.5701 42.9267Z" fill="#2C2C2C"/>
                    <path d="M31.7536 27.0562L26.1997 26.0079C25.1098 25.8024 23.9745 26.0651 23.0836 26.7293L17.6594 30.7737C17.1474 31.1551 16.8523 31.7413 16.8495 32.3813C16.8468 33.0214 17.1364 33.6104 17.6443 33.9973C18.5949 34.7201 19.9113 34.778 20.9216 34.1421L23.5682 32.4744C23.8764 33.091 24.4509 33.9283 25.4495 34.4442C26.6067 35.0421 28.0137 35.0573 29.6355 34.4904L34.5862 38.0733C34.6953 38.1527 34.8244 38.1927 34.9548 38.1927C35.0495 38.1927 35.1442 38.1713 35.2321 38.1278L38.6384 36.4519L34.4393 25.7748L31.7536 27.0562Z" fill="#2C2C2C"/>
                    <path d="M46.9541 34.7814L41.8175 21.2849C41.6836 20.9338 41.2958 20.7545 40.943 20.88L39.0988 21.5408L41.8257 29.106C41.9554 29.4653 41.7701 29.8619 41.4132 29.9922C41.3356 30.0205 41.2567 30.0336 41.1785 30.0336C40.8971 30.0336 40.6328 29.8584 40.5312 29.577L37.8016 22.0049L35.5229 22.8215C35.3472 22.8843 35.2045 23.0167 35.1269 23.1871C35.05 23.3581 35.0452 23.5526 35.1139 23.7271L40.5333 37.5078C40.6019 37.6816 40.7371 37.8209 40.9094 37.8926C40.9938 37.9278 41.0837 37.9457 41.1737 37.9457C41.2663 37.9457 41.3597 37.9271 41.4461 37.8892L46.5828 35.6635C46.9225 35.5166 47.0866 35.1277 46.9541 34.7814Z" fill="#2C2C2C"/>
                    <path d="M11.8728 23.1877C11.7959 23.0167 11.6524 22.885 11.4767 22.8222L9.19805 22.0056L6.46843 29.5777C6.36685 29.8591 6.1026 30.0343 5.8212 30.0343C5.74296 30.0343 5.66403 30.0212 5.58647 29.9929C5.22888 29.8626 5.04425 29.466 5.17397 29.1067L7.90084 21.5414L6.05662 20.8807C5.70452 20.7545 5.31605 20.9338 5.18221 21.2856L0.0455588 34.7821C-0.0862205 35.1283 0.0771309 35.5173 0.416188 35.6642L5.55284 37.8899C5.64 37.9278 5.73266 37.9464 5.82532 37.9464C5.91523 37.9464 6.00514 37.9285 6.08956 37.8933C6.26184 37.8216 6.39705 37.683 6.46568 37.5085L11.8851 23.7278C11.9544 23.5533 11.9496 23.3581 11.8728 23.1877Z" fill="#2C2C2C"/>
                    <path d="M34.9548 39.5762C34.5313 39.5762 34.1257 39.4445 33.7819 39.1962L29.3858 36.0146C28.7056 36.1967 28.0481 36.2884 27.4242 36.2884C26.4839 36.2884 25.6081 36.0822 24.8209 35.6753C24.1228 35.3146 23.5841 34.8422 23.1716 34.3587L21.6547 35.3146C20.978 35.7408 20.1955 35.9664 19.3911 35.9664C18.4639 35.9664 17.549 35.6594 16.8153 35.1015C15.958 34.449 15.4693 33.4559 15.4741 32.3758C15.4796 31.2958 15.9772 30.3068 16.8407 29.6633L20.7721 26.7314L20.619 26.6211C19.5305 25.8334 18.1125 25.5927 16.8262 25.9769L14.4988 26.6735L12.6464 25.561L8.1947 36.8774L11.3004 38.6362L12.5537 37.3761C13.1666 36.7609 13.9813 36.4222 14.8482 36.4222C15.7157 36.4222 16.5304 36.7609 17.1427 37.3761C17.4096 37.6444 17.6265 37.9561 17.7851 38.2948C18.2566 38.4527 18.6883 38.7196 19.05 39.0831C19.317 39.3514 19.5339 39.6631 19.6924 40.0024C20.164 40.1604 20.5957 40.4273 20.9574 40.7907C21.2244 41.059 21.4413 41.3708 21.5998 41.7094C22.0713 41.8673 22.5031 42.1343 22.8648 42.4977C23.4777 43.1136 23.8154 43.9323 23.8154 44.8034C23.8154 45.6744 23.4777 46.4931 22.8654 47.109L22.8476 47.1269L23.1153 47.329C23.5456 47.6532 24.0556 47.8118 24.5628 47.8118C25.1929 47.8118 25.8209 47.567 26.289 47.089L26.3672 47.0097C26.7166 46.6531 26.9465 46.1945 27.0282 45.7062C27.847 45.9379 28.7612 45.7282 29.3981 45.0875L29.4551 45.0296C29.8449 44.6378 30.0859 44.1302 30.1476 43.5902C30.9781 43.8392 31.8992 43.6247 32.5437 42.9771L32.5821 42.9384C32.9582 42.5605 33.1978 42.0681 33.2685 41.5446C34.0626 41.7377 34.9246 41.517 35.5423 40.8963C36.0008 40.4356 36.2527 39.8141 36.25 39.1679L35.8375 39.3707C35.565 39.5052 35.2596 39.5762 34.9548 39.5762Z" fill="#2C2C2C"/>
                    <path d="M37.3562 11.563C37.3562 11.9451 37.6644 12.2548 38.0446 12.2548H41.8594C42.2396 12.2548 42.5478 11.9451 42.5478 11.563C42.5478 11.1809 42.2396 10.8713 41.8594 10.8713H38.0446C37.6644 10.8713 37.3562 11.1809 37.3562 11.563Z" fill="#2C2C2C"/>
                    <path d="M36.6144 8.40906C36.7331 8.40906 36.8525 8.37802 36.9624 8.31388L39.8237 6.62552C40.1518 6.4324 40.261 6.00824 40.0688 5.67857C39.8759 5.3489 39.4545 5.23855 39.1264 5.43235L36.265 7.12071C35.9369 7.31383 35.8271 7.73799 36.02 8.06766C36.1483 8.28698 36.3783 8.40906 36.6144 8.40906Z" fill="#2C2C2C"/>
                    <path d="M36.2656 16.006L39.127 17.6944C39.2368 17.7592 39.3562 17.7896 39.4749 17.7896C39.7111 17.7896 39.941 17.6675 40.0693 17.4482C40.2622 17.1185 40.1524 16.695 39.8243 16.5012L36.9629 14.8128C36.6348 14.619 36.2134 14.7294 36.0205 15.0591C35.8277 15.388 35.9375 15.8122 36.2656 16.006Z" fill="#2C2C2C"/>
                    <path d="M5.14105 12.2548H8.95578C9.33602 12.2548 9.64419 11.9451 9.64419 11.563C9.64419 11.1809 9.33602 10.8713 8.95578 10.8713H5.14105C4.76081 10.8713 4.45264 11.1809 4.45264 11.563C4.45264 11.9451 4.76081 12.2548 5.14105 12.2548Z" fill="#2C2C2C"/>
                    <path d="M7.17678 6.62488L10.0382 8.31324C10.148 8.37807 10.2674 8.40842 10.3862 8.40842C10.6223 8.40842 10.8522 8.28634 10.9805 8.06702C11.1734 7.73735 11.0636 7.31388 10.7355 7.12007L7.87411 5.43171C7.54604 5.23791 7.12462 5.34826 6.93175 5.67793C6.73889 6.0076 6.8487 6.43176 7.17678 6.62488Z" fill="#2C2C2C"/>
                    <path d="M10.0374 14.8128L7.17597 16.5012C6.8479 16.6943 6.73808 17.1185 6.93094 17.4482C7.05929 17.6675 7.28922 17.7896 7.52532 17.7896C7.64406 17.7896 7.76349 17.7585 7.8733 17.6944L10.7347 16.006C11.0628 15.8129 11.1726 15.3887 10.9797 15.0591C10.7875 14.7294 10.3654 14.619 10.0374 14.8128Z" fill="#2C2C2C"/>
                    <path d="M23.4999 18.251C27.1698 18.251 30.1555 15.2508 30.1555 11.563C30.1555 7.87527 27.1698 4.87512 23.4999 4.87512C19.83 4.87512 16.8444 7.87527 16.8444 11.563C16.8444 15.2508 19.83 18.251 23.4999 18.251ZM19.3585 10.7623C19.6365 10.5016 20.0723 10.5168 20.3317 10.7961L22.4587 13.0859L26.6688 8.55393C26.9283 8.27461 27.3641 8.25943 27.6421 8.52013C27.92 8.78084 27.9351 9.21879 27.6757 9.49812L22.9625 14.5715C22.8321 14.7115 22.6502 14.7915 22.4594 14.7915C22.2686 14.7915 22.086 14.7122 21.9563 14.5715L19.3255 11.7396C19.0654 11.461 19.0805 11.023 19.3585 10.7623Z" fill="#2C2C2C"/>
                    <path d="M23.5002 22.4015C29.4474 22.4015 34.2862 17.5392 34.2862 11.563C34.2862 5.58686 29.4474 0.724548 23.5002 0.724548C17.5529 0.724548 12.7142 5.58686 12.7142 11.563C12.7142 17.5399 17.5529 22.4015 23.5002 22.4015ZM23.5002 3.49159C27.9292 3.49159 31.5325 7.11246 31.5325 11.563C31.5325 16.0136 27.9292 19.6345 23.5002 19.6345C19.0712 19.6345 15.4678 16.0136 15.4678 11.563C15.4678 7.11246 19.0712 3.49159 23.5002 3.49159Z" fill="#2C2C2C"/>
                    </svg>
                </div>
                <!--end::Icon-->
                <!--begin::Section-->
                <div class="d-flex flex-column my-7">
                  <div class="mb-3">
                    <span class="fw-semibold title-card fs-6">Nombre des clients finaux actives  </span>
                  </div>
                  <!--begin::Number-->
                  <span class=" score3 mb-3  lh-1 ls-n2">{{statistic?.nb_candidat_active
                    ?statistic?.nb_candidat_active:'---'}}</span>
                  <!--end::Number-->
                  <!--begin::Follower-->

                  <!--end::Follower-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card widget 2-->
          </div>
          <div class="col-lg-3 col-12 height mb-xl-10">
            <!--begin::Card widget 2-->
            <div class="card h-lg-100 bg4">
              <!--begin::Body-->
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <!--begin::Icon-->
                <div class="m-0">
                  <svg width="43" height="37" viewBox="0 0 43 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.817 12.8093C28.2959 12.8093 25.4248 9.93261 25.4248 6.40463C25.4248 2.87666 28.2959 0 31.817 0C35.3381 0 38.2092 2.87666 38.2092 6.40463C38.2092 9.93261 35.3381 12.8093 31.817 12.8093ZM22.0121 8.25003C22.0121 3.6908 18.3284 0.0542757 13.8322 0.0542757C9.28186 0.0542757 5.65241 3.74508 5.65241 8.25003C5.65241 12.8093 9.33603 16.4458 13.8322 16.4458C18.3826 16.4458 22.0121 12.755 22.0121 8.25003ZM41.2428 28.7123C42.0553 28.7123 42.7054 28.0067 42.597 27.1925C41.7303 20.8965 39.0218 14.6547 31.817 14.6547C27.7542 14.6547 25.0998 16.6629 23.4205 19.5396C24.6123 20.5708 25.6957 21.7649 26.5624 23.2304C27.5375 24.8044 28.4042 26.6498 29.0543 28.7666H41.2428V28.7123ZM0.018616 34.5742C-0.143897 35.7682 0.777009 36.9081 2.02294 36.9081H25.6415C26.8874 36.9081 27.8084 35.8225 27.6458 34.5742C26.4541 26.6498 22.9871 18.834 13.8322 18.834C4.67732 18.834 1.21038 26.6498 0.018616 34.5742Z" fill="#2C2C2C"/>
                    </svg>
                </div>
                <!--end::Icon-->
                <!--begin::Section-->
                <div class="d-flex flex-column my-7">
                  <div class="mb-3">
                    <span class="fw-semibold title-card fs-6">Nombre Total des candidats  </span>
                  </div>
                  <!--begin::Number-->
                  <span class=" score4 mb-3  lh-1 ls-n2">{{statistic?.nb_candidat?statistic?.nb_candidat:'---'}}</span>
                  <!--end::Number-->

                </div>
                <!--end::Section-->

              </div>
              <!--end::Body-->
            </div>
            <!--end::Card widget 2-->
          </div>
          <div class="col-lg-3 col-12 height mb-xl-10">
            <!--begin::Card widget 2-->
            <div class="card h-lg-100 bg4">
              <!--begin::Body-->
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <!--begin::Icon-->
                <div class="m-0">
                  <svg width="43" height="37" viewBox="0 0 43 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.817 12.8093C28.2959 12.8093 25.4248 9.93261 25.4248 6.40463C25.4248 2.87666 28.2959 0 31.817 0C35.3381 0 38.2092 2.87666 38.2092 6.40463C38.2092 9.93261 35.3381 12.8093 31.817 12.8093ZM22.0121 8.25003C22.0121 3.6908 18.3284 0.0542757 13.8322 0.0542757C9.28186 0.0542757 5.65241 3.74508 5.65241 8.25003C5.65241 12.8093 9.33603 16.4458 13.8322 16.4458C18.3826 16.4458 22.0121 12.755 22.0121 8.25003ZM41.2428 28.7123C42.0553 28.7123 42.7054 28.0067 42.597 27.1925C41.7303 20.8965 39.0218 14.6547 31.817 14.6547C27.7542 14.6547 25.0998 16.6629 23.4205 19.5396C24.6123 20.5708 25.6957 21.7649 26.5624 23.2304C27.5375 24.8044 28.4042 26.6498 29.0543 28.7666H41.2428V28.7123ZM0.018616 34.5742C-0.143897 35.7682 0.777009 36.9081 2.02294 36.9081H25.6415C26.8874 36.9081 27.8084 35.8225 27.6458 34.5742C26.4541 26.6498 22.9871 18.834 13.8322 18.834C4.67732 18.834 1.21038 26.6498 0.018616 34.5742Z" fill="#2C2C2C"/>
                    </svg>
                </div>
                <!--end::Icon-->
                <!--begin::Section-->
                <div class="d-flex flex-column my-7">
                  <div class="mb-3">
                    <span class="fw-semibold title-card fs-6">Nombre des candidats actifs </span>
                  </div>
                  <!--begin::Number-->
                  <span class=" score4 mb-3  lh-1 ls-n2">{{statistic?.nb_candidat_active
                    ?statistic?.nb_candidat_active
:'---'}}</span>
                  <!--end::Number-->

                </div>
                <!--end::Section-->

              </div>
              <!--end::Body-->
            </div>
            <!--end::Card widget 2-->
          </div>
          <div class="col-lg-3 col-12 height mb-xl-10">
            <!--begin::Card widget 2-->
            <div class="card h-lg-100 bg3">
              <!--begin::Body-->
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <!--begin::Icon-->
                <div class="m-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="32" viewBox="0 0 38 32" fill="none">
                    <path d="M28.4253 25.5601C28.0653 25.5201 27.7053 25.6401 27.4253 25.8801C25.0653 27.8801 22.0653 28.9601 18.9853 28.9601C11.7853 28.9601 5.90534 23.0801 5.90534 15.8801V14.8801H7.86534C8.30534 14.8801 8.70534 14.6401 8.90534 14.2801C9.10534 13.9201 9.10534 13.4401 8.86534 13.0801L5.50534 7.84007C5.30534 7.52007 4.94534 7.32007 4.50534 7.32007C4.10534 7.32007 3.70534 7.52007 3.50534 7.84007L0.185341 13.0801C-0.0546587 13.4401 -0.0546586 13.8801 0.145341 14.2801C0.345341 14.6401 0.745341 14.8801 1.18534 14.8801H3.14534V15.8801C3.14534 24.6401 10.2653 31.7201 19.0253 31.7201C22.7853 31.7201 26.4253 30.4001 29.2653 27.9601C29.8653 27.4801 29.9453 26.6001 29.4253 26.0001C29.1453 25.7601 28.8253 25.5601 28.4253 25.5601Z" fill="#2C2C2C"/>
                    <path d="M37.8253 17.48C37.6253 17.12 37.2253 16.88 36.7853 16.88H34.8253V15.88C34.8253 7.12 27.7053 0 18.9453 0C15.1853 0 11.5453 1.32 8.70533 3.76C8.10533 4.24 8.06533 5.12 8.54533 5.72C9.02533 6.32 9.90533 6.36 10.5053 5.88C12.8653 3.88 15.8653 2.8 18.9453 2.8C26.1853 2.8 32.0653 8.68 32.0653 15.88V16.88H30.1053C29.6653 16.88 29.2653 17.12 29.0653 17.48C28.8653 17.84 28.8653 18.32 29.1053 18.68L32.4653 23.92C32.6653 24.24 33.0653 24.44 33.4653 24.44C33.8653 24.44 34.2253 24.24 34.4653 23.92L37.7853 18.68C38.0253 18.32 38.0653 17.84 37.8253 17.48Z" fill="#2C2C2C"/>
                    <path d="M11.9053 17V18.28C11.9053 18.52 12.1053 18.72 12.3453 18.72H13.8653C14.9053 21.72 17.5053 23.76 20.4253 23.76C22.1853 23.76 23.8653 23.04 25.1453 21.72C25.2253 21.64 25.2653 21.52 25.2653 21.4C25.2653 21.28 25.2253 21.16 25.1453 21.08L23.7053 19.76C23.5453 19.6 23.2653 19.6 23.1053 19.76C22.3453 20.52 21.3853 20.92 20.3853 20.92C18.9853 20.92 17.6653 20.08 16.8653 18.68H20.5053C20.7453 18.68 20.9453 18.48 20.9453 18.24V16.96C20.9453 16.72 20.7453 16.52 20.5053 16.52H16.1853C16.1453 16.28 16.1453 16.04 16.1453 15.84C16.1453 15.64 16.1453 15.4 16.1853 15.16H20.5053C20.7453 15.16 20.9453 14.96 20.9453 14.72V13.48C20.9453 13.24 20.7453 13.04 20.5053 13.04H16.8653C17.6653 11.64 18.9853 10.8 20.3853 10.8C21.3453 10.8 22.3453 11.2 23.1053 11.96C23.2653 12.12 23.5053 12.12 23.7053 11.96L25.1453 10.64C25.2253 10.56 25.2653 10.44 25.2653 10.32C25.2653 10.2 25.2253 10.08 25.1453 9.99996C23.8253 8.67996 22.1453 7.95996 20.4253 7.95996C17.5453 7.95996 14.9453 9.95996 13.8653 13H12.3453C12.1053 13 11.9053 13.2 11.9053 13.44V14.72C11.9053 14.96 12.1053 15.16 12.3453 15.16H13.3853C13.3853 15.44 13.3853 15.68 13.3853 15.88C13.3853 16.08 13.3853 16.32 13.4253 16.56H12.3453C12.0653 16.56 11.9053 16.76 11.9053 17Z" fill="#2C2C2C"/>
                  </svg>
                </div>
                <!--end::Icon-->
                <!--begin::Section-->
                <div class="d-flex flex-column my-7">
                  <div class="mb-3">
                    <span class="fw-semibold title-card fs-6">Total chiffre d’affaires  </span>
                  </div>
                  <!--begin::Number-->
                  <span class=" score4 mb-3  lh-1 ls-n2"> 41
                    <!-- {{statistic?.nb_ca?statistic?.nb_ca:---}} --> 
                  </span>
                  <!--end::Number-->

                </div>
                <!--end::Section-->

              </div>
              <!--end::Body-->
            </div>
            <!--end::Card widget 2-->
          </div>
        </div>     
      </div>
      <!--end::Content container-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Content wrapper-->
  <!--begin::Footer-->
  <!--end::Footer-->
</div>
