<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
        <!--begin::Toolbar container-->
        <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <!--begin::Title-->
                <div class="d-flex align-items-center">
                    <h1 class="page-heading"> <svg width="32" height="29" viewBox="0 0 32 29" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_82_1744)">
                                <path
                                    d="M21.795 7.11531C19.8257 7.11531 18.2199 5.51738 18.2199 3.55766C18.2199 1.59793 19.8257 0 21.795 0C23.7643 0 25.3701 1.59793 25.3701 3.55766C25.3701 5.51738 23.7643 7.11531 21.795 7.11531ZM16.3112 4.58274C16.3112 2.05017 14.2509 0.0301492 11.7363 0.0301492C9.19127 0.0301492 7.16135 2.08032 7.16135 4.58274C7.16135 7.11531 9.22157 9.13533 11.7363 9.13533C14.2812 9.13533 16.3112 7.08516 16.3112 4.58274ZM27.0667 15.9491C27.5212 15.9491 27.8848 15.5572 27.8242 15.105C27.3394 11.6076 25.8245 8.1404 21.795 8.1404C19.5227 8.1404 18.0381 9.25593 17.0989 10.8539C17.7654 11.4267 18.3714 12.09 18.8561 12.904C19.4015 13.7784 19.8863 14.8035 20.2498 15.9793H27.0667V15.9491ZM4.01041 19.2053C3.91952 19.8686 4.43457 20.5017 5.13142 20.5017H18.3411C19.0379 20.5017 19.553 19.8987 19.4621 19.2053C18.7956 14.8035 16.8565 10.4619 11.7363 10.4619C6.61599 10.4619 4.67695 14.8035 4.01041 19.2053Z"
                                    fill="#265D91" />
                            </g>
                            <defs>
                                <filter id="filter0_d_82_1744" x="0" y="0" width="31.8308" height="28.5017"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix"
                                        result="effect1_dropShadow_82_1744" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_82_1744"
                                        result="shape" />
                                </filter>
                            </defs>
                        </svg>
                        Offres d'emploi
                    </h1>
                    <!--begin::Menu-->
                </div>
                <!--end::Title-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted">
                        <a routerLink="/acceuil" class="text-muted ">Accueil</a>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item">
                        <span class="bg-gray-400 w-5px h-2px"></span>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted"> <a class="text-muted "
                            routerLink="/acceuil/offre-emploi/list-offres"> Offres d'emploi</a></li>
                    <li class="breadcrumb-item">
                        <span class="bg-gray-400 w-5px h-2px"></span>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted">Voir Test</li>
                    <!--end::Item-->
                </ul>
                <!--end::Breadcrumb-->
            </div>
            <!--end::Page title-->
            <!--begin::Actions-->

            <!--end::Actions-->
        </div>
        <!--end::Toolbar container-->
    </div>
    <div id="kt_app_content_container" class="app-container container-fluid">
      <!--begin::Card-->
     
      <div class="card pb-5 pt-5">
        <!--begin::Card header-->
        <div class="card-header border-0 pt-6 mb-4">
          <!--begin::Card title-->
  
          <!-- <div class="card-title">
            <div class="input-group mb-3">
              <input
                type="text"
                data-kt-customer-table-filter="search"
                class="form-control form-control-solid ps-15"
                placeholder="Rechercher"
              />
              <div class="input-group-append">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                    fill="#7E7C7C"
                  />
                </svg>
  
                <button
                  class="input-group-text cursor-pointer"
                  id="basic-addon2"
                 
                >
                  <span>Rechercher</span>
                </button>
              </div>
            </div>
          </div> -->
          <!--begin::Card title-->
          <!--begin::Card toolbar-->
        
          <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div
          class="card-body pt-0"
         
        >
          <div class="item mb-5" *ngFor="let t of test;let i=index">
            <div class="item-header">
              <div class="left">
                <span class="number">{{i+1}}</span>
                <h4>
              
                  <span>
                    <a>
                      {{t.question_FR}}
                    </a>
                  </span>
                  <span class="badge">{{t.time}} (Secondes)</span>
                </h4>
              </div>
              <!-- <div class="right">
                <button class="edit" >
                  <svg
                    width="28"
                    height="32"
                    viewBox="0 0 28 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_440_1235)">
                      <path
                        d="M21.3004 6.0961H22.1502C22.3756 6.0961 22.5917 6.00657 22.7511 5.8472C22.9105 5.68784 23 5.47169 23 5.24631C23 5.02093 22.9105 4.80478 22.7511 4.64541C22.5917 4.48604 22.3756 4.39651 22.1502 4.39651H17.9012V3.54671C17.8992 2.87119 17.63 2.22391 17.1523 1.74624C16.6746 1.26858 16.0273 0.999332 15.3518 0.997314H10.253C9.57751 0.999332 8.93023 1.26858 8.45256 1.74624C7.97489 2.22391 7.70565 2.87119 7.70363 3.54671V4.39651H3.8498C3.62442 4.39651 3.40827 4.48604 3.2489 4.64541C3.08953 4.80478 3 5.02093 3 5.24631C3 5.47169 3.08953 5.68784 3.2489 5.8472C3.40827 6.00657 3.62442 6.0961 3.8498 6.0961H4.30444V18.8431C4.30444 19.97 4.7521 21.0507 5.54894 21.8476C6.34578 22.6444 7.42653 23.0921 8.55343 23.0921H17.0514C18.1783 23.0921 19.2591 22.6444 20.0559 21.8476C20.8527 21.0507 21.3004 19.97 21.3004 18.8431V6.0961ZM11.1028 17.1435C11.1028 17.3689 11.0133 17.585 10.8539 17.7444C10.6946 17.9037 10.4784 17.9933 10.253 17.9933C10.0276 17.9933 9.8115 17.9037 9.65213 17.7444C9.49276 17.585 9.40323 17.3689 9.40323 17.1435V10.3451C9.40323 10.1197 9.49276 9.90356 9.65213 9.7442C9.8115 9.58483 10.0276 9.4953 10.253 9.4953C10.4784 9.4953 10.6946 9.58483 10.8539 9.7442C11.0133 9.90356 11.1028 10.1197 11.1028 10.3451V17.1435ZM16.2016 17.1435C16.2016 17.3689 16.1121 17.585 15.9527 17.7444C15.7933 17.9037 15.5772 17.9933 15.3518 17.9933C15.1264 17.9933 14.9103 17.9037 14.7509 17.7444C14.5915 17.585 14.502 17.3689 14.502 17.1435V10.3451C14.502 10.1197 14.5915 9.90356 14.7509 9.7442C14.9103 9.58483 15.1264 9.4953 15.3518 9.4953C15.5772 9.4953 15.7933 9.58483 15.9527 9.7442C16.1121 9.90356 16.2016 10.1197 16.2016 10.3451V17.1435ZM16.2016 4.39651H9.40323V3.54671C9.40323 3.32133 9.49276 3.10518 9.65213 2.94581C9.8115 2.78644 10.0276 2.69691 10.253 2.69691H15.3518C15.5772 2.69691 15.7933 2.78644 15.9527 2.94581C16.1121 3.10518 16.2016 3.32133 16.2016 3.54671V4.39651Z"
                        fill="#EE6352"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_440_1235"
                        x="0"
                        y="0.997314"
                        width="28"
                        height="30.0947"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="1" dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_440_1235"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_440_1235"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </button>
                <button
                  class="edit">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_142_1063)">
                      <path
                        d="M4 15.6558V5.59137C4 4.9982 4.23707 4.42933 4.65906 4.00989C5.08106 3.59046 5.6534 3.35482 6.25019 3.35482H13.1301L10.9165 5.59137H6.25019V15.6558H16.376V11.0206L18.6262 8.82039V15.6558C18.6262 16.249 18.3891 16.8179 17.9671 17.2373C17.5451 17.6567 16.9728 17.8924 16.376 17.8924H6.25019C5.6534 17.8924 5.08106 17.6567 4.65906 17.2373C4.23707 16.8179 4 16.249 4 15.6558ZM20.3138 0C19.8664 0.000391512 19.4374 0.177375 19.1212 0.492041L11.1246 8.56878C10.8786 8.8174 10.6929 9.11861 10.5818 9.44942L9.77173 11.8677C9.76161 11.9064 9.76183 11.947 9.77238 11.9855C9.78293 12.0241 9.80343 12.0592 9.83185 12.0875C9.86028 12.1157 9.89564 12.1361 9.93443 12.1466C9.97322 12.1571 10.0141 12.1573 10.053 12.1473L12.4944 11.3505C12.8273 11.2401 13.1304 11.0556 13.3805 10.8109L21.5064 2.86278C21.7422 2.6282 21.9027 2.32944 21.9677 2.00425C22.0326 1.67906 21.9992 1.34202 21.8716 1.03569C21.744 0.729368 21.5279 0.4675 21.2507 0.283164C20.9734 0.098828 20.6474 0.000291618 20.3138 0Z"
                        fill="#265D91"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_142_1063"
                        x="0"
                        y="0"
                        width="26"
                        height="25.8924"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_142_1063"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_142_1063"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </button>
              </div> -->
            </div>
            <div
              class="item-content"
              
            >
              <div class="question" *ngFor="let question of t.response_question ">
                <div class="form-check form-check-custom form-check-solid">
                  <input *ngIf="question.response_FR"
                    class="form-check-input checkbox-border"
                 
                  
                    type="checkbox"
                    value="1"
                    id="flexCheckDefault" [checked]="t.response_state==question.state" [disabled]="true"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                  {{question.response_FR}}
                  </label>
                </div>
              </div>
         
            </div>
           
          </div>
        
        </div>
  
        <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="test&&!test.length">
          <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
          <span class="empty-list">
           La liste est vide
       </span>
       </div>

        <div class="pagination" *ngIf="test && test.length">
          <app-pagination [data]="test" [lengthData]="totalItems" [currentPageList]="currentPage" (setPages)="getItems($event)">
          </app-pagination>
        </div>
      
        <!--end::Card body-->
      </div>
      <!--end::Card-->
      <!--begin::Modals-->
      <!--begin::Modal - Customers - Add-->
  
      <!--end::Modal - New Card-->
      <!--end::Modals-->
    </div>
    <!--end::Content container-->
  </div>
