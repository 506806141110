export class CSearchCandidate{
  ID_user:string|undefined
  first_name:string|undefined
  last_name:string|undefined
  email:string|undefined
  score:string|undefined
  current_country:string|undefined
  status:string|undefined
  date_last_update:any
  skillsOr:string|undefined
  skillsAnd:string|undefined
  availability:string|undefined
  mobility:string|undefined
  destination_country:string|undefined
  desired_contract:string|undefined
  min_experience_years:string|undefined
  max_experience_years:string|undefined
  tjm:string|undefined
  tjm_range:string|undefined
  desired_salary:string|undefined
  desired_salary_range:string|undefined
  desired_workstation:string|undefined
  client_name:string|undefined
  manager_name:string|undefined
  name:string|undefined
  search:string|undefined
  role:string|undefined
  project_name:string|undefined
  logic:string|undefined

  constructor(data?:CSearchCandidate){
    this.ID_user = data && data.ID_user ||""
    this.first_name = data && data.first_name ||""
    this.last_name = data && data.last_name ||""
    this.email = data && data.email ||""
    this.score = data && data.score ||""
    this.current_country = data && data.current_country ||""
    this.status = data && data.status ||""
    this.date_last_update = data && data.date_last_update ||""
    this.skillsOr = data && data.skillsOr ||""
    this.skillsAnd = data && data.skillsAnd ||""
    this.availability = data && data.availability ||""
    this.mobility = data && data.mobility ||""
    this.destination_country = data && data.mobility ||""
    this.desired_contract = data && data.desired_contract ||""
    this.min_experience_years = data && data.min_experience_years ||""
    this.max_experience_years = data && data.max_experience_years ||""
    this.tjm = data && data.tjm ||""
    this.tjm_range = data && data.tjm_range ||""
    this.desired_salary = data && data.desired_salary ||""
    this.desired_salary_range = data && data.desired_salary_range ||""
    this.client_name = data && data.client_name ||""
    this.logic = data && data.logic ||""
  }
}
export class CCreateCandidate{
  first_name:string|undefined
  last_name:string|undefined
  email:string|undefined
  personal_email:string|undefined
  phone_number:string|undefined
  desired_contrat:string|undefined
  mobility:string|undefined
  skills_text:any
  availability:string|undefined
  month_exp:string|undefined
  year_exp:string|undefined
  date_first_experience:string|undefined
  current_country:string|undefined
  desired_contract:string|undefined
  desired_salary:string|undefined
  linkedin_link:string|undefined
  open_for_mission:number|undefined
  tjm:string|undefined
  certificates:[]|undefined
  sub_category_id:[]|undefined
  skill:[]|undefined
  diplomas:[]|undefined
  diplome:[]|undefined
  skills:[]|undefined
  certificat:[]|undefined
  prof_experience:[]|undefined
  langue_user:[]|undefined
  cv:string|undefined
  savoir_faire:string|undefined
  destination_country:string|undefined
  english_level:string|undefined
  frensh_level:string|undefined
  yearControl:string|undefined
  monthControl:string|undefined
  desired_workstation:string|undefined
  availibility:string|undefined
  emailProESN:string|undefined
  emailProClient:string|undefined
  image_url:string|undefined
  softSkills:[]|undefined
  constructor(data?:CCreateCandidate){
    this.first_name = data && data.first_name||""
    this.last_name = data && data.last_name ||""
    this.email = data && data.email ||""
    this.phone_number = data && data.phone_number ||""
    this.current_country = data && data.current_country ||""
    this.desired_contrat = data && data.desired_contrat ||""
    this.mobility = data && data.mobility ||""
    this.availibility = data && data.availibility ||""
    this.month_exp = data && data.month_exp ||""
    this.year_exp = data && data.year_exp ||""
    this.availability = data && data.availability ||""
    this.date_first_experience = data && data.date_first_experience ||""
    this.desired_contract = data && data.desired_contract ||""
    this.desired_salary = data && data.desired_salary ||""
    this.linkedin_link = data && data.linkedin_link ||""
    this.open_for_mission = data && data.open_for_mission ||1
    this.tjm = data && data.tjm ||""
    this.certificates = data && data.certificates ||[]
    this.skill = data && data.skill ||[]
    this.diplomas = data && data.diplomas ||[]
    this.cv = data && data.cv ||""
    this.destination_country = data && data.destination_country ||''
    this.frensh_level = data && data.frensh_level ||''
    this.english_level = data && data.english_level ||''
    this.yearControl = data && data.yearControl ||''
    this.monthControl = data && data.monthControl ||''
    this.softSkills = data && data.softSkills || []
    this.desired_workstation = data && data.desired_workstation ||''
  }
}
export interface ICandidat{
  adress:string|null
  country:string|null
  email:string
  personal_email:string
  enabled:number
  first_name:string
  ID_user:number
  image_url:string
  last_name:string
  phone_number:string
  role_id:string
  years_of_experience:string|null
  status:number|null
  score:string|null
  mobility:string|null
  desired_contract:string|null
  last_connection:string|null
  created_at:any|null
  current_country:string|null
  destination_country:string|null
  cv:string
  cv_wegestu:string
  reponse:string
  id:number
  date_last_update:string
  ESN_name:string
  client_final_name:string
  date_test:string
  ClientFinal_names:string
  file_test:string
  checked:boolean
  tjm:string|undefined
  desired_salary:string|undefined
  desired_workstation:string|undefined
  availability:string|undefined
}
export const headerColumns=[
  { checked: true, name: 'ID', code: 'ID_user' },
  { checked: true, name: 'Image', code: 'image_url' },
  { checked: true, name: 'Nom', code: 'first_name' },
  { checked: true, name: 'Poste', code: 'desired_workstation' },
  { checked: true, name: 'Disponibilité', code: 'availability' },
  { checked: true, name: 'Expérience', code: 'years_of_experience' },
  { checked: true, name: 'TJM', code: 'tjm' },
  { checked: true, name: 'Salaire', code: 'desired_salary' },
  { checked: true, name: 'N°Tel', code: 'phone_number' },
  { checked: true, name: 'Email', code: 'email' },
  {checked:true,name:'ESN',code: 'esn_name'},
  {checked:true,name:'Client Final',code: 'client_final_name'},
  { checked: true, name: 'Pays de résidence', code: 'current_country' },
  { checked: true, name: 'Pays Destinataire', code:'destination_country' },
  { checked: true, name: 'Modifié', code: 'date_last_update' },
  { checked: true, name: 'Dernière connexion', code: 'last_connection' },
  { checked: true, name: 'CV', code: 'CV' },
  { checked: true, name: 'Score', code: 'score' },
  {checked:true,name:'Réponses',code: 'reponse'},
  { checked: true, name: 'Statut', code: 'status' },
 
];
export const headerColumns2=[
  { checked: true, name: 'ID', code: 'ID_user' },
  { checked: true, name: 'Image', code: 'image_url' },
  { checked: true, name: 'Nom', code: 'first_name' },
  { checked: true, name: 'Poste', code: 'desired_workstation' },
  { checked: true, name: 'Disponibilité', code: 'availability' },
  { checked: true, name: 'Expérience', code: 'years_of_experience' },
  { checked: true, name: 'TJM', code: 'tjm' },
  { checked: true, name: 'Salaire', code: 'desired_salary' },
  {checked:true,name:'Contrat demandé',code: 'desired_contract'},
  { checked: true, name: 'N°Tel', code: 'phone_number' },
  { checked: true, name: 'Email', code: 'email' },
  {checked:true,name:'ESN',code: 'ESN_name'},
  {checked:true,name:'Client',code: 'ClientFinal_names'},
  { checked: true, name: 'Pays de résidence', code: 'current_country' },
  { checked: true, name: 'Pays Destinataire', code:'destination_country' },
  { checked: true, name: 'Mobilité', code: 'mobility' },
  { checked: true, name: 'Dernière connexion', code: 'last_connection' },
  { checked: true, name: 'Score', code: 'score' },
  {checked:true,name:'Réponses',code: 'file_test'},
  { checked: true, name: 'Statut', code: 'status' },
 
];

export const statusCandidate=[
  { id: '1', name: 'Non Réclamé' },
  { id: 2, name: 'Réclamé' },
  { id: 3, name: 'Validé et non notifié' },
  { id: 4, name: 'Validé et notifié' },
  { id: 5, name: 'Validé et testé' },
]
export const months = [
  { id: 0, name: 'mois' },
  { id: 1, name: 'Janvier' },
  { id: 2, name: 'Février' },
  { id: 3, name: 'Mars' },
  { id: 4, name: 'Avril' },
  { id: 5, name: 'Mai' },
  { id: 6, name: 'Juin' },
  { id: 7, name: 'Juillet' },
  { id: 8, name: 'Août' },
  { id: 9, name: 'Septembre' },
  { id: 10, name: 'Octobre' },
  { id: 11, name: 'Novembre' },
  { id: 12, name: 'Décembre' },
];
export const listContracts = [
  { id: 1, value: 'Alternance', label: 'Alternance', checked: false },
  { id: 2, value: 'Autres type de contrat', label: 'Autres type de contrat', checked: false },
  { id: 3, value: 'cdd', label: 'CDD', checked: false },
  { id: 4, value: 'cdi', label: 'CDI', checked: false },
  { id: 5, value: 'Freelance', label: 'Freelance', checked: false },
  { id: 6, value: 'Portage_salarial', label: 'Portage salarial', checked: false },
  { id: 7, value: 'Stage', label: 'Stage', checked: false },

];


export interface ISort{
  sort:number,
  sortAttribute:string|undefined
}