import { Component, TemplateRef, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { EmptyCV } from '../../../../../core/models/messages';
import { CandidatService } from '../../../../../core/services/candidat/candidat.service';
import { JobOfferService } from '../../../../../core/services/job-offer/job-offer.service';
import { SortDataService } from '../../../../../core/services/sort-data/sort-data.service';
import { environment } from '../../../../../../environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-candidatures',
  templateUrl: './candidatures.component.html',
  styleUrls: ['./candidatures.component.css'],
})
export class CandidaturesComponent {
  /* number */
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 1;
  pagination: boolean = false;

  /* string */
  idOffre!: string;
  nameOffre!: string;
  url: string = environment.baseUrl + '/api';
  sortAttr: string = '';
  /* array */
  listCandidatures: any = [];
  modalRef?: BsModalRef;
  /* formGroup */
  candidateForm: FormGroup = this.createCandidateSearchForm();
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  applyForm: FormGroup = this.createapplyForm();

  constructor(
    private activatedRoute: ActivatedRoute,
    private jobOfferService: JobOfferService,
    private candidatService: CandidatService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private sortDataService: SortDataService,
    private modalService: BsModalService,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    this.getId();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }

  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
    }
  }
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idOffre = params.params['id'];
        this.nameOffre = params.params['offre'];
        this.getCandidatureList();
      },
    });
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getCandidatureList();
      }
    }
  }
  /* sort data */
  sortData(sortAttribute: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr = sortAttribute;
    if (this.candidateForm.dirty)
      this.listCandidatures = this.sortDataService.sortArray(
        this.listCandidatures,
        sortAttribute,
        this.currentSortDirection
      );
    else this.getCandidatureList();
  }
  getCandidatureList() {
    let data =
      this.sortAttr == ''
        ? {
          id: this.idOffre,
          per_page: this.itemsPerPage,
          page: this.currentPage,
        }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            id: this.idOffre,
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };
    this.spinner.show();
    this.jobOfferService
      .getCandidature(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.listCandidatures = res.data.data.slice(
            this.startIndex,
            this.endIndex
          );
          console.log("list candidature", this.listCandidatures)
          this.totalItems = res.data.total;
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  /* download cv */
  downloadFiles(user: any) {
    // if (user.cv) {
    const fileUrl = `${this.url}/TestJob/GetFileESNTest/${user.esn_file_test}`; // Replace with your file URL
    this.spinner.show();
    this.candidatService.downloadreponse(fileUrl).subscribe(
      (data) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${user.ID_user}_${user.first_name}_${user.last_name
          }_TestEsn.${user.esn_file_test.split('.').pop()}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
    // } 
    // else {
    //   this.toaster.info(EmptyCV);
    // }
  }
  navigateToProfilCandidat(idCandidat: number) {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  /* create search form */
  createCandidateSearchForm(data?: { name: string; email: string; score: string; date_start_candidature: string; date_end_candidature: string }) {
    return this.formBuilder.group({
      name: ['', data && data.name],
      email: ['', data && data.email],
      score: ['', data && data.score],
      date_start_candidature: ['', data && data.date_start_candidature],
      date_end_candidature: ['', data && data.date_end_candidature]
    });
  }
  /* filter list candidature */
  search() {
    let nameArray = this.candidateForm.value?.name.split(' ');
    let data = {
      name: [nameArray[0], nameArray[1] ? nameArray[1] : ''],
      email: this.candidateForm.value?.email,
      score: this.candidateForm.value?.score,
      date_end_candidature: this.candidateForm.value?.date_end_candidature,
      date_start_candidature: this.candidateForm.value?.date_start_candidature,
    };
    this.spinner.show();
    this.jobOfferService
      .searchCandidateJobOffer(this.idOffre, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();
          // this.listCandidatures=res.data.data.slice(this.startIndex,this.endIndex)
          this.listCandidatures = res.data.data;
          console.log('this.listCandidatures', this.listCandidatures);
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  reset() {
    this.candidateForm.reset();
    this.candidateForm = this.createCandidateSearchForm();

    this.spinner.hide();


    this.getCandidatureList();



  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));

  }
  createapplyForm(data?: any) {
    return this.formBuilder.group({
      letter: [data ? data.letter : ''],
      salary: [data ? data.salary : ''],
      tjm: [data ? data.tjm : ''],
    });
  }
  openModal(template: TemplateRef<any>, data?: any, mode?: string) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    console.log('Identifianddddt:', data);
    // this.id = data?.id;

    if (data) {
      // this.id = data?.id;
      this.applyForm = this.createapplyForm(data);
      this.applyForm.get('letter')?.setValue(data.lettre_de_motivation);
      this.applyForm.get('salary')?.setValue(data.salaire_candidature);
      this.applyForm.get('tjm')?.setValue(data.tjm_candidature);
      // console.log('Identifiant:', this.id);
    }
  }
  closeModal() {
    this.modalRef!.hide();
    this.applyForm.reset();
  }



}
