import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../../../core/models/messages';
import { CompanyService } from '../../../../../../core/services/company/company.service';
import { JobOfferService } from '../../../../../../core/services/job-offer/job-offer.service';
import { SortDataService } from '../../../../../../core/services/sort-data/sort-data.service';
import { environment } from '../../../../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-offre-emploi',
  templateUrl: './offre-emploi.component.html',
  styleUrls: ['./offre-emploi.component.css'],
})
export class OffreEmploiComponent {
  /* string */
  sortAttr: string = '';
  mode: string = 'create';
  url: string = environment.baseUrl + '/api';
  searchElement:  boolean = false;
  /* number */
  idCompany!: number;
  currentSortDirection: number = 2;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  clickCount: number = 0;
  pagination : boolean =false;

  /* any */
  listJobOffer: any = [];
  /* object */
  selectedOffer: any;
  /* modal */
  modalRef?: BsModalRef;
  /* formGroup */
  searchFormGroup: FormGroup = this.createSearchForm();
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  StateOffre=[
    {id:0,name:'Activé'},
    {id:1,name:'Désactivé'},
  ]
  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private jobOfferService: JobOfferService,
    private router: Router,
    private sortDataService: SortDataService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService
  ) {}
  ngOnInit() {
    this.getIdCompany();
  }
  /* create search form */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      start_date: ['', data?.start_date],
      name: ['', data?.name],
      state: [null, data?.state],
      search: ['', data?.state],

    });
  }
  updateInputType(type: 'text' | 'date') {
    const inputElement = document.getElementById(
      'dateInput'
    ) as HTMLInputElement;
    inputElement.type = type;
    if (type == 'text') {
      if (
        this.searchFormGroup.value.start_date &&
        this.searchFormGroup.value.start_date != ''
      )
        this.searchFormGroup
          .get('start_date')
          ?.setValue(
            moment(this.searchFormGroup.value.start_date).format('DD/MM/yyyy')
          );
    }
  }
  searchOffer() {}
  openModal(template: TemplateRef<any>, offer: any) {
    this.selectedOffer = offer;
    this.selectedOffer.tjm = this.selectedOffer.tjm.split(' ')[0];
    this.selectedOffer.salary = this.selectedOffer.salary.split(' ')[0];
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef?.hide();
    this.selectedOffer = null;
  }
  /* get company id from route */
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCompany = params.params['id'];
        this.getListOffre();
      },
    });
  }
  /* get indexes for pagination */


  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchFormGroup.dirty) { 
        if (this.searchElement) this.applyFilter();
      } else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true;
        this.getListOffre();
      }
   
    }
  }

  /* sort data  */
  sortData(sortAttribute: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr = sortAttribute;
    this.currentPage = 1;
    this.endIndex=5;
    
    if (this.searchFormGroup.dirty)
      this.listJobOffer = this.sortDataService.sortArray(
        this.listJobOffer,
        sortAttribute,
        this.currentSortDirection
      );
    else this.getListOffre();
  }
  /* list list esn companies */
  getListOffre() {
    this.spinner.show();
    let dataPyload =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage, type: 2 }
        : {
            ...{ per_page: this.itemsPerPage, page: this.currentPage },
            ...{
              sort: this.currentSortDirection,
              sortAttribute: this.sortAttr,
              type: 2,
            },
          };
    this.companyService
      .getJobOfferList(this.idCompany, dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.listJobOffer = res.data.data
          console.log("list job offer",this.listJobOffer)
          // .slice(0, this.endIndex);
          this.totalItems = res.data.total;
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  /* search job offer esn */
  applyFilter() {
    this.searchFormGroup.patchValue({
      search:"",

    })
    let data={
    ...this.searchFormGroup.value,
      page: this.currentPage,
      per_page: this.itemsPerPage,
      esn_id:this.idCompany
    }
   
    console.log("form search",this.searchFormGroup.value)
    this.spinner.show();
    this.jobOfferService
      .searchJobOffer(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          if (!res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.applyFilter();
          } else {
            this.listJobOffer = res.data.data;
            this.totalItems = res.data.total;
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.searchFormGroup = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getListOffre();
    }
    return (this.isRecherche = false);
  }
  goToDetails(id: string | undefined) {
    this.router.navigate(['/acceuil/offre-emploi/details-offer', { id: id }]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  activerjoboffer(id: any) {
    Swal.fire({
      title: 'Voulez-vous vraiment activer cette offre d’emploi ?',
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, activé !',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.jobOfferService.activedesactiveJobOffer(id).subscribe({
          next: (response: any) => {
            console.log('response', response);

            console.log('response.data', response.data);
            this.toastrService.success(
              'L’offre d’emploi a été activé avec succès .'
            );
            this.getListOffre();
          },
          error: (error: any) => {
            console.error('Error fetching  job offer: ', error);
          },
        });
      }
    });
  }

  desactiverjoboffer(id: any) {
    Swal.fire({
      title: 'Voulez-vous vraiment désactiver cette offre d’emploi ?',
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, désactivé !',
      cancelButtonText: 'Annuler ',
    }).then((result) => {
      if (result.isConfirmed) {
        this.jobOfferService.activedesactiveJobOffer(id).subscribe({
          next: (response: any) => {
            console.log('response', response);

            console.log('response.data', response.data);
            this.toastrService.success(
              'L’offre d’emploi a été désactivé avec succès.'
            );
            this.getListOffre();
          },
          error: (error: any) => {
            console.error('Error fetching  job offer: ', error);
          },
        });
      }
    });
  }
}
