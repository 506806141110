<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_content" class="app-content flex-column-fluid">
  <!--begin::Content container-->
  <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <div class="d-flex align-items-center">
          <h1 class="page-heading">
            <svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
              <g filter="url(#filter0_d_2093_798)">
                  <path
                      d="M32.8286 12.3011C32.8286 12.0526 32.7289 11.8142 32.5514 11.6385C32.374 11.4627 32.1334 11.364 31.8824 11.364H22.0522C21.8013 11.364 21.5607 11.4627 21.3832 11.6385C21.2058 11.8142 21.1061 12.0526 21.1061 12.3011V26.7139H19.4062V28.5881H34.2761V26.7139H32.8286V12.3011ZM22.9984 13.2382H30.9363V26.7139H22.9984V13.2382Z"
                      fill="#265D91" />
                  <path d="M25.4293 14.191H23.5371V17.0211H25.4293V14.191Z" fill="#265D91" />
                  <path d="M27.9508 14.191H26.0586V17.0211H27.9508V14.191Z" fill="#265D91" />
                  <path d="M30.4684 14.191H28.5762V17.0211H30.4684V14.191Z" fill="#265D91" />
                  <path d="M25.4293 18.5611H23.5371V21.3912H25.4293V18.5611Z" fill="#265D91" />
                  <path d="M27.9508 18.5611H26.0586V21.3912H27.9508V18.5611Z" fill="#265D91" />
                  <path d="M30.4684 18.5611H28.5762V21.3912H30.4684V18.5611Z" fill="#265D91" />
                  <path d="M25.4293 22.928H23.5371V25.7581H25.4293V22.928Z" fill="#265D91" />
                  <path d="M27.9508 22.928H26.0586V25.7581H27.9508V22.928Z" fill="#265D91" />
                  <path d="M30.4684 22.928H28.5762V25.7581H30.4684V22.928Z" fill="#265D91" />
                  <path
                      d="M17.4223 2.3491C17.4223 2.10056 17.3226 1.8622 17.1452 1.68646C16.9678 1.51072 16.7271 1.41199 16.4762 1.41199H6.64599C6.39506 1.41199 6.15441 1.51072 5.97698 1.68646C5.79954 1.8622 5.69986 2.10056 5.69986 2.3491V16.7619H4V18.6361H18.8699V16.7619H17.4223V2.3491ZM7.59211 3.28621H15.5301V16.7619H7.59211V3.28621Z"
                      fill="#265D91" />
                  <path d="M10.0231 4.24194H8.13086V7.07202H10.0231V4.24194Z" fill="#265D91" />
                  <path d="M12.5465 4.24194H10.6543V7.07202H12.5465V4.24194Z" fill="#265D91" />
                  <path d="M15.0661 4.24194H13.1738V7.07202H15.0661V4.24194Z" fill="#265D91" />
                  <path d="M10.0231 8.60901H8.13086V11.4391H10.0231V8.60901Z" fill="#265D91" />
                  <path d="M12.5465 8.60901H10.6543V11.4391H12.5465V8.60901Z" fill="#265D91" />
                  <path d="M15.0661 8.60901H13.1738V11.4391H15.0661V8.60901Z" fill="#265D91" />
                  <path d="M10.0231 12.9758H8.13086V15.8059H10.0231V12.9758Z" fill="#265D91" />
                  <path d="M12.5465 12.9758H10.6543V15.8059H12.5465V12.9758Z" fill="#265D91" />
                  <path d="M15.0661 12.9758H13.1738V15.8059H15.0661V12.9758Z" fill="#265D91" />
                  <path
                      d="M30.3343 8.19659H32.2266V2.93003H25.9727V0L20.3086 3.82341L25.9853 7.61871V4.80738H30.3469L30.3343 8.19659ZM23.6768 3.82341L24.0742 3.55477V4.08892L23.6768 3.82341Z"
                      fill="#265D91" />
                  <path
                      d="M7.94107 21.8035H6.04883V27.07H12.3027V30.0001L17.9668 26.1766L12.2901 22.3814V25.1927H7.92845L7.94107 21.8035ZM14.5986 26.1766L14.2012 26.4453V25.9174L14.5986 26.1766Z"
                      fill="#265D91" />
              </g>
              <defs>
                  <filter id="filter0_d_2093_798" x="0" y="0" width="38.2754" height="38.0001"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2093_798" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2093_798"
                          result="shape" />
                  </filter>
              </defs>
          </svg>

            Entreprises
          </h1>
          <div class="tools">
            <div class="dropdown">
              <button class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_588_1703)">
                    <circle cx="20" cy="18" r="13" fill="white" />
                  </g>
                  <path d="M19.6667 13V23" stroke="#265D91" stroke-width="2" stroke-linecap="round" />
                  <path d="M15 18.3333H25" stroke="#265D91" stroke-width="2" stroke-linecap="round" />
                  <defs>
                    <filter id="filter0_d_588_1703" x="0" y="0" width="42" height="42" filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha" />
                      <feOffset dx="1" dy="3" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_588_1703" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_588_1703" result="shape" />
                    </filter>
                  </defs>
                </svg>
              </button>
              <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item cursor-pointer" (click)="openModal(details_add_societe,'create')">Ajout
                  unitaire</a>
                <a class="dropdown-item cursor-pointer" (click)="downloadCsv()">Télécharger un modèle d'ajout en masse</a>
                <a class="dropdown-item cursor-pointer" (click)="openModalBulk(add_masse)">Ajout en masse</a>
              </div>
            </div>
          </div>
        </div>
        <!--end::Title-->
        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <a class="text-muted"  routerLink="/">Accueil</a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">Entreprises</li>
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <li class="breadcrumb-item text-muted">Liste des entreprises</li>
          <!--end::Item-->
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->
      <!--begin::Actions-->

      <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
  </div>
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->
    <div class="card mb-5 pt-5 pb-5">
      <!-- (keyup.enter)="searchCompnies()" -->
      <div class="container-fluid" [formGroup]="searchForm" >
        <div class="title">
          <h4>Recherche</h4>
        </div>
       
          <div class="row">
            <div class="col-lg-6">
              <div class="input-group search-libre">
                <span class="input-group-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                      fill="#7E7C7C" />
                  </svg>
                </span>
                <!-- (change)="searchCompnies()" -->
                <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid ps-5"
                placeholder="Rechercher" formControlName="search" (keyup.enter)="searchCompnies()"/>
                  <div class="tooltip-container tooltip-info">
                    <div class="tooltip-trigger"><i class="fa-solid fa-circle-info" style="font-size: 20px;"></i></div>
                    <div class="tooltip-content">
                      <div class="content">
                        <h4>Opérateur AND :</h4>
                        <p>Utilisez l'opérateur AND pour spécifier que les résultats doivent inclure plusieurs termes.
                        </p>
                        <p>Exemple : software AND engineer renverra des profils qui mentionnent à la fois "software" et "engineer".
                        </p>
                        <h4>Opérateur OR :</h4>
                        <p>Utilisez l'opérateur OR pour spécifier que les résultats peuvent inclure l'un des termes.
                        </p>
                        <p>Exemple : sales OR marketing renverra des profils qui mentionnent soit "sales" soit "marketing".
                        </p>
                        <h4>Opérateur NOT :</h4>
                        <p>Utilisez l'opérateur NOT pour exclure des termes spécifiques des résultats.
                        </p>
                        <p>Exemple : developer NOT manager renverra des profils qui mentionnent "developer" mais pas "manager".
                        </p>
                        <h4>Guillemets pour une expression exacte :</h4>
                        <p>Utilisez des guillemets pour rechercher une expression exacte.
                        </p>
                        <p>Exemple : "product manager" renverra des profils qui mentionnent exactement "product manager".
                        </p>
                        <h4>Parenthèses pour grouper des opérations :</h4>
                        <p>Utilisez des parenthèses pour définir l'ordre d'évaluation des opérations.
                        </p>
                        <p>Exemple : (sales OR marketing) AND manager renverra des profils qui mentionnent "sales" ou "marketing" et "manager".
                        </p>
                        <h4>Recherche standard :</h4>
                        <p>Si aucun opérateur n'est spécifié dans la recherche, le texte sera interprété comme s'il était entre guillemets.
                        </p>
                        <p>Exemple : La recherche product manager sera traitée comme "product manager", suivant la règle 4.
                        </p>
                      </div>
                    </div>
                </div>
              </div>
             
            </div>
            <!-- <div class="text-danger mt-2"
                *ngIf="searchForm.get('search')?.hasError('pattern') && searchForm.get('search')?.touched && isSimpleWord(searchForm.get('search')?.value)">
                Le modèle de recherche est invalide. Utilisez le format correct (par exemple "hello" OR "hello").
            </div> -->
          </div>
       
        <div class="filtrage mt-5">
          <div class="row">
            <div class="col-lg-3">
              <div class=" me-md-2 mb-3">
                <input type="text" (change)="search=false;ispagination=false" formControlName="ID_company" class="form-control form-control-solid ps-5" name="ID"
                  placeholder="ID" />
              </div>
            </div>

            <div class="col-lg-3">
              <div class=" me-md-2 mb-3">
                <input type="text" (change)="search=false;ispagination=false" formControlName="name" class="form-control form-control-solid ps-5" name="société"
                  placeholder="Nom entreprise" />
              </div>
            </div>

            <div class="col-lg-3">
              <div class=" me-md-2 mb-3">
                <!-- ;ispagination=false; -->
                <ng-select (change)="search=false;ispagination=false"formControlName="typeComp" [items]="typesCompanies" bindLabel="name" bindValue="id"
                  [placeholder]="'Type'" [notFoundText]="'Aucun élément trouvé'" autoComplete >
                </ng-select>
                
              </div>
            </div>
            <div class="col-lg-3">
              <div class=" me-md-2 mb-3">
                <input type="text" (change)="search=false;ispagination=false" formControlName="responsable_name" class="form-control form-control-solid ps-5"
                  name="Note" placeholder="Nom admin" />
              </div>
            </div>
          </div>
          <div class="row" *ngIf="seeMore">
            <div class="col-lg-3">
              <div class=" me-md-2 mb-3">
                <!-- (change)="ispagination=false;" -->
                <ng-select (change)="search=false;ispagination=false" formControlName="country" [items]="paysList" bindLabel="name" bindValue="name"
                  [placeholder]="'Pays'" [multiple]="true" autoComplete [notFoundText]="'Aucun élément trouvé'"  >
                </ng-select>
              </div>
            </div>
            <div class="col-lg-3">
              <div class=" me-md-2 mb-3 calenderDate">
                <!-- (change)="ispagination=false;" -->
               

                  <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy"  placeholder="Date de dernière connexion" 
                  inputId="yearpicker"
                  formControlName="date_last_conx"  class="form-control" 
                    ></p-calendar>
              </div>
            </div>
      <div class="col-lg-3">
  <div class="me-md-2 mb-5 mt-1">
    <label for="">Avis</label>
    <div class="stars">
      <rating formControlName="note" [titles]="[' ', ' ', ' ', ' ', ' ']" [max]="5" class="rating-stars">
        <span class="bs-rating-star" (click)="handleRatingClick($event)">&#9733;</span>
        <span class="bs-rating-star" (click)="handleRatingClick($event)">&#9733;</span>
        <span class="bs-rating-star" (click)="handleRatingClick($event)">&#9733;</span>
        <span class="bs-rating-star" (click)="handleRatingClick($event)">&#9733;</span>
        <span class="bs-rating-star" (click)="handleRatingClick($event)">&#9733;</span>
      </rating>
    </div>
  </div>
</div>
 
            
            <div class="col-lg-3"></div>
          </div>
          <div class=" row">
            <div class="voir-plus mb-5">
              <a class="voir-plus-btn" (click)="seeMore=!seeMore">Voir {{!seeMore?'plus':'moins'}} de critères de
                recherche</a>
            </div>
          </div>
          <div class="mb-3 search-btn d-flex justify-content-end">
            <div class="align-items-center recherche">
                <button type="submit" class="me-5" (click)="search=true; searchCompnies()">
                    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                            fill="white" />
                    </svg>
                    Rechercher
                </button>
            </div>
        
            <div class="resett-btn">
                <div class="reset-icon cursor-pointer">
                    <a (click)="reset()">
                        <img src="/assets/media/icons/icon-reset.png" alt="" width="30px">
                    </a>
                </div>
            </div>
        </div>
        
        
        </div>
      </div>

    </div>
    <div  *ngIf="!isLoadSpinner" class="card for-web">
      <div class="container-fluid">
        <div class="d-flex flex-wrap flex-stack pt-10 pb-8 title">
          <!--begin::Heading-->
          <h4 class="my-2">Liste des entreprises</h4>
          <!--end::Heading-->
          <!--begin::Controls-->
          <div class="d-flex flex-wrap my-1">
            <!--begin::Tab nav-->
            <ul class="nav nav-pills me-5">
              <li class="nav-item m-0">
                <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3" data-bs-toggle="tab"
                  href="#grid">
                  <i class="ki-duotone ki-element-plus fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                  </i>
                </a>
              </li>
              <li class="nav-item m-0">
                <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active" data-bs-toggle="tab"
                  href="#list">
                  <i class="ki-duotone ki-row-horizontal fs-2">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </a>
              </li>
            </ul>
            <!--end::Tab nav-->
          </div>
          <!--end::Controls-->
        </div>
    
        <div class="tab-content">
          <!--begin::Tab pane-->
          <div id="grid" class="tab-pane fade">
            <!--begin::Row-->
            <div class="container">
              <div class="row g-6 g-xl-9 cards" style="justify-content: center;">
                <!--begin::Col-->
                <div class="col-sm-6 col-xl-4" *ngFor="let data of listCampany">
                  <!--begin::Card-->
                  <div class="card h-100">
                    <div class="header-grid">
                      <div class="card-toolbar1">
                        <span  *ngIf="data?.company?.type!=='1'" [ngClass]="data?.company?.type=='2'?
                  'esn-type':data?.company?.type=='3'?'client-type':'centre-type'"
                          class="type-esn text-uppercase wrapping fw-bold">
                          {{data?.company?.type=='1'?'Entreprise':data?.company?.type=='2'?
                          'ESN':data?.company?.type=='3'?'Client Final':'Cabinet de Recrutement'}}</span>
                            <span *ngIf="data?.company?.type=='1'">---</span>
                      </div>
                      <div class="card-toolbar m-0">
                        <!-- begin menu action -->

                        <div class="tools" *ngIf="data?.company?.type =='1'">
                          <div class="dropdown">
                            <button
                              class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                              type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false">
                              <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                                  fill="#265D91" />
                                <circle cx="2.44739" cy="2.44739" r="2.44739"
                                  transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                <circle cx="2.44739" cy="2.44739" r="2.44739"
                                  transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                              </svg>
                            </button>
                          <div class="dropdown-table">
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <ng-container>
                                <a class="dropdown-item cursor-pointer"
                                  (click)="openModal(details_add_societe,'details')">Voir
                                  profil</a>
                                <a class="dropdown-item cursor-pointer" data-bs-toggle="modal"
                                  data-bs-target="#keep_sign_up" href="">Continuer l'inscrition</a>
                              </ng-container>

                            </div>
                          </div>
                          </div>
                        </div>
                        <!-- end menu action -->
                      </div>
                    </div>
                    <!--begin::Card header-->
                    <div class="card-header flex-nowrap border-0 pt-9">
                      <!--begin::Card title-->
                      <div class="card-title m-0">
                        <!--begin::Icon-->
                        <div class="symbol">
                          <img *ngIf="data?.company?.logo" class="table-img"
                            src="{{url+'/Company/file/gallery_company/'+ data?.company?.logo}}" alt="" width="75px">
                          <img *ngIf="!data?.company?.logo" class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt=""
                            width="75px">
                        </div>

                        <!--end::Icon-->
                        <!--begin::Title-->
                        <a class="name m-3">{{data?.company?.name?data?.company?.name:'---'}}</a>
                        <!-- <p>
                          <span class="wrapping" [ngClass]="data?.company?.type=='1'?'entreprise':data.company?.type=='2'?
                          'esn-type':data.company?.type=='3'?'client-type':'centre-type'">
                            {{data?.company?.type=='1'?'Entreprise':data.company?.type=='2'||data.company?.type=='5'?
                            'ESN':data.company?.type=='3'?'Client Final':data.company?.type=='4'?'Cabinet de Recrutement':'Particulier'}}</span>
                        </p> -->
                       
                          <!-- <div class="stars" *ngIf="data.average_note" >
                            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
                              <i class="fa fa-stars" [class.fa-star]="i < data.average_note" [class.fa-star-half]="isHalfStar(i, data.average_note)" [class.fa-star-o]="i >= data.average_note"></i>
                            </ng-container>
                          </div>
                          <div class="stars" *ngIf="!data.average_note">
                            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
                              <i class="fa fa-stars" [class.fa-star]="i < 0" [class.fa-star-half]="isHalfStar(i, 0)" [class.fa-star-o]="i >= 0"></i>
                            </ng-container>
                          </div> -->
                          <div class="stars">
                          <rating [(ngModel)]="data?.company.average_note" [titles]="[' ',' ',' ',' ',' ']" [max]="5"
                                                        [readonly]="true" class="rating-stars disable-hover">
                                                      </rating>
                          </div>
                        


                        <!--end::Title-->
                      </div>
                      <!--end::Card title-->
                      <!--begin::Card toolbar-->

                      <!--end::Card toolbar-->
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body d-flex flex-column px-5 pt-6 pb-8">
                      <!--begin::Heading-->


                      <!--end::Heading-->
                      <!--begin::Stats-->

                      <!--end::Stats-->
                      <!--begin::Indicator-->
                      <div class="footer d-flex fw-semibold">
                        <div class="website">
                          <p><span class="line-break"><a target="_blank"
                                href="{{data?.company?.website_link?data?.company?.website_link:'---'}}"><svg width="18"
                                  height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="0.0410156" y="0.0407715" width="17.9592" height="17.9592" rx="3"
                                    fill="#265D91" />
                                  <path
                                    d="M8.72077 14.4082C5.4083 14.4082 2.73438 12.0017 2.73438 9.02045C2.73438 6.03922 5.4083 3.63269 8.72077 3.63269C12.0332 3.63269 14.7072 6.03922 14.7072 9.02045C14.7072 12.0017 12.0332 14.4082 8.72077 14.4082ZM8.72077 4.35106C5.8473 4.35106 3.53256 6.43432 3.53256 9.02045C3.53256 11.6066 5.8473 13.6898 8.72077 13.6898C11.5942 13.6898 13.909 11.6066 13.909 9.02045C13.909 6.43432 11.5942 4.35106 8.72077 4.35106Z"
                                    fill="white" />
                                  <path
                                    d="M11.833 10.3494C11.9128 9.91841 11.9128 9.48738 11.9128 9.02045C11.9128 8.55351 11.8729 8.12249 11.833 7.69147C11.7532 7.11677 11.5936 6.578 11.4339 6.07514C10.8353 4.60249 9.87746 3.63269 8.72009 3.63269C7.36317 3.63269 6.24571 4.96167 5.7668 6.90126C5.72689 7.08085 5.68698 7.22453 5.64707 7.40412C5.56725 7.90698 5.52734 8.44575 5.52734 9.02045C5.52734 9.48738 5.56725 9.91841 5.60716 10.3494C5.68698 10.9241 5.84662 11.4629 6.00626 11.9658C6.56499 13.4384 7.56272 14.4082 8.68018 14.4082C9.79764 14.4082 10.7954 13.4384 11.3541 11.9658C11.0747 12.0376 10.7555 12.0735 10.4362 12.1453C10.0371 13.1151 9.35864 13.6898 8.72009 13.6898C8.08154 13.6898 7.40308 13.1151 6.96408 12.1453C6.76453 11.7143 6.60489 11.1755 6.48517 10.6009C6.36544 10.098 6.32553 9.59514 6.32553 9.02045C6.32553 8.44575 6.36544 7.90698 6.48517 7.44004C6.52508 7.26045 6.56499 7.11677 6.60489 6.93718V6.90126C6.6448 6.72167 6.68471 6.578 6.76453 6.43432C6.80444 6.32657 6.84435 6.21881 6.88426 6.14698C6.88426 6.14698 6.88426 6.14698 6.88426 6.11106L6.96408 5.96738C6.96408 5.96738 6.96408 5.93147 7.00399 5.89555C7.40308 4.92575 8.08154 4.35106 8.72009 4.35106C9.35864 4.35106 10.0371 4.92575 10.4761 5.89555C10.6756 6.32657 10.8353 6.86534 10.955 7.44004C11.0747 7.94289 11.1146 8.44575 11.1146 9.02045C11.1146 9.59514 11.0747 10.098 10.9949 10.6009C11.2743 10.529 11.5536 10.4572 11.833 10.3494Z"
                                    fill="white" />
                                  <path
                                    d="M11.9933 6.61416C11.4745 6.43456 10.836 6.32681 10.1974 6.25497C9.7185 6.18314 9.23959 6.14722 8.72077 6.14722C8.20195 6.14722 7.72304 6.18314 7.24412 6.21905C7.16431 6.43456 7.04458 6.68599 6.96476 6.97334C7.52349 6.9015 8.08222 6.86558 8.72077 6.86558C9.35932 6.86558 9.91805 6.9015 10.4768 6.97334C11.1153 7.08109 11.714 7.22477 12.1929 7.40436C13.2704 7.83538 13.909 8.41007 13.909 8.98477C13.909 9.55946 13.2704 10.1701 12.1929 10.5652C11.714 10.7448 11.1153 10.8884 10.4768 10.9962C9.91805 11.1399 9.35932 11.1758 8.72077 11.1758C8.08222 11.1758 7.52349 11.1399 6.96476 11.0321C6.32621 10.9603 5.72757 10.8166 5.24866 10.6011C4.17111 10.206 3.53256 9.59538 3.53256 9.02069C3.53256 8.44599 4.17111 7.83538 5.24866 7.44028C5.28857 7.15293 5.36839 6.86558 5.44821 6.61416C3.81193 7.11701 2.73438 7.97905 2.73438 9.02069C2.73438 10.0623 3.81193 10.9244 5.44821 11.4272C5.96703 11.6068 6.60558 11.7146 7.24412 11.7864C7.72304 11.8582 8.20195 11.8582 8.72077 11.8582C9.23959 11.8582 9.7185 11.8223 10.1974 11.7864C10.836 11.7146 11.4745 11.5709 11.9933 11.4272C13.6296 10.9244 14.7072 10.0264 14.7072 9.02069C14.7072 8.01497 13.6296 7.11701 11.9933 6.61416Z"
                                    fill="white" />
                                </svg>

                              </a></span></p>
                        </div>
                        <div class="details cursor-pointer">
                          <a (click)="detailsClientFinalNavigation(data.company?.id,data.company?.user?.role_user_id[0])">Voir détail</a>
                        </div>
                      </div>
                      <!--end::Indicator-->
                    </div>
                    <!--end::Card body-->
                  </div>
                  <!--end::Card-->
                </div>
                <!--end::Col-->


              </div>
              <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listCampany&&!listCampany.length ">
                <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                <span class="empty-list">
                 La liste est vide
             </span>
             </div>
            </div>
            <!--end::Row-->
          </div>
          <!--end::Tab pane-->
          <!--begin::Tab pane-->
          <div id="list" class="tab-pane fade active show">
            <div class="card-flush">
              <div class="card-body pt-3">
                <!--begin::Table-->

                <div  class="table-responsive" id="kt_customers_table">
                  <table class="table align-middle align-middle fw-bold mb-4 " *ngIf="listCampany.length">
                    <thead class="fs-7 text-gray-400 text-uppercase">
                      <tr>
                        <ng-container *ngFor="let item of listNames ;let i =index">
                          <th class="min-w-175px" [ngClass]="{'th-fixed': item === 'Entreprise'}">
                            <span>{{item}}</span>
                            <i *ngIf="!['Logo'].includes(item) && !['Type'].includes(item)" (click)="sortData(item)"
                              class="fa-solid fa-sort cursor-pointer"></i>
                          </th>
                        </ng-container>
                        <th *ngIf="listNames.length" class="min-w-90px"><span>ACTIONS</span></th>
                        <th class="min-w-70px">
                          <div class="tools-column">
                            <div class="dropdown">
                              <button class="dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                  fill="none">
                                  <g filter="url(#filter0_d_588_1699)">
                                    <rect x="4" width="20" height="20" rx="4" fill="#63C3E8" fill-opacity="0.12"
                                      shape-rendering="crispEdges" />
                                  </g>
                                  <path
                                    d="M17.5 10.6H14.6V13.5C14.6 13.8314 14.3314 14.1 14 14.1C13.6686 14.1 13.4 13.8314 13.4 13.5V10.6H10.5C10.1686 10.6 9.9 10.3314 9.9 10C9.9 9.66863 10.1686 9.4 10.5 9.4H13.4V6.5C13.4 6.16863 13.6686 5.9 14 5.9C14.3314 5.9 14.6 6.16863 14.6 6.5V9.4H17.5C17.8314 9.4 18.1 9.66863 18.1 10C18.1 10.3314 17.8314 10.6 17.5 10.6Z"
                                    fill="#265D91" stroke="#265D91" stroke-width="0.2" />
                                  <defs>
                                    <filter id="filter0_d_588_1699" x="0" y="0" width="28" height="28"
                                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                      <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                      <feOffset dy="4" />
                                      <feGaussianBlur stdDeviation="2" />
                                      <feComposite in2="hardAlpha" operator="out" />
                                      <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.13 0" />
                                      <feBlend mode="normal" in2="BackgroundImageFix"
                                        result="effect1_dropShadow_588_1699" />
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_588_1699"
                                        result="shape" />
                                    </filter>
                                  </defs>
                                </svg>
                              </button>
                            <div class="list-actions">
                              <div class="dropdown-menu drop-height" aria-labelledby="dropdownMenuButton">
                                <div class="d-flex checkbox mb-2 menu" *ngFor="let item of dataHeader;let i =index">
                                  <div class="form-check form-check-sm form-check-custom form-check-solid"
                                    *ngIf="item.name!='checkbox' && item.name!='Actions' ">

                                    <input class="form-check-input cursor-pointer "
                                      [checked]="(item.checked=='oui'&&!item.checked_user)||item.checked_user=='oui'&&(item.checked=='non'||item.checked=='oui')"
                                      (change)="changeSelection($event,item,i)" type="checkbox" value="1"
                                      [disabled]="item.name==='Entreprise' || item.name==='Logo'" />
                                    <a class="dropdown-item">{{item.name}}</a>
                                  </div>
                                </div>

                              </div>
                            </div>
                            </div>
                          </div>

                        </th>
                      </tr>
                    </thead>
                    <tbody class="fw-semibold text-gray-600">
                      <tr *ngFor="let data of listCampany">
                          <td *ngIf="listNames?.includes('ID')">
                                <a *ngIf="data.company?.type=='2' || data.company?.type=='4'" [routerLink]="['/acceuil/entreprises/esn/profil-esn', { id: data.company?.id, idRole: data.company?.user?.role_user_id[0] }]"><span class="first-td"> {{ data.company?.ID_company ? data.company?.ID_company : '---' }}</span></a>
                                <a *ngIf="data.company?.type=='3'" [routerLink]="['/acceuil/entreprises/clients-finaux/details-client-final', { id: data.company?.id, idRole: data.company?.user?.role_user_id[0] }]"> <span class="first-td">{{ data.company?.ID_company ? data.company?.ID_company : '---' }}</span></a>
                                <a *ngIf="data.company?.type=='1'" [routerLink]="['/acceuil/entreprises/Detailsociete', { id: data.company?.id, idRole: data.company?.user?.role_user_id[0] }]"> <span class="first-td">{{ data.company?.ID_company ? data.company?.ID_company : '---' }}</span></a>
                        </td>
                        
                        
                          <td *ngIf="listNames?.includes('Logo')">
                          <img
                            *ngIf="data.company?.logo&&data.company?.logo!='avatar.png'&&data.company?.logo!='undefined'"
                            class="table-img" src="{{url+'/Company/file/gallery_company/' + data.company?.logo}}" alt=""
                            width="75px">
                          <img
                            *ngIf="!data.company?.logo||data.company?.logo&&(data.company?.logo=='avatar.png'||data.company?.logo=='undefined')"
                            class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt="" width="75px">
                        </td>
                        <td class="fw-bold" *ngIf="listNames?.includes('Entreprise')" class="wrapping td-fixed">
                          <a *ngIf="data.company?.type=='2' || data.company?.type=='4'" [routerLink]="['/acceuil/entreprises/esn/profil-esn', { id: data.company?.id, idRole: data.company?.user?.role_user_id[0] }]"><span class="first-td">{{ data.company?.name ? data.company?.name : '---' }}</span></a>
                          <a *ngIf="data.company?.type=='3'" [routerLink]="['/acceuil/entreprises/clients-finaux/details-client-final', { id: data.company?.id, idRole: data.company?.user?.role_user_id[0] }]"><span class="first-td">{{ data.company?.name ? data.company?.name : '---' }}</span></a>
                          <a *ngIf="data.company?.type=='1'" [routerLink]="['/acceuil/entreprises/Detailsociete', { id: data.company?.id, idRole: data.company?.user?.role_user_id[0] }]"> <span class="first-td">{{ data.company?.name ? data.company?.name : '---' }}</span></a>
                      </td>
                        <td *ngIf="listNames?.includes('Type')">
                          <span class="wrapping" *ngIf="data?.company?.type!=='1'" [ngClass]="data.company?.type=='2'?
                          'esn-type':data.company?.type=='3'?'client-type':'centre-type'">
                            {{data.company?.type=='2'||data.company?.type=='5'?
                            'ESN':data.company?.type=='3'?'Client Final':data.company?.type=='4'?'Cabinet de Recrutement':'Particulier'}}</span>
                            <span *ngIf="data?.company?.type=='1'">---</span>
                        </td>
                        <td *ngIf="listNames?.includes('Admin')">
                          <span
                            class="wrapping">{{data?.company?.employee[0]?.user?.first_name?data?.company?.employee[0]?.user?.first_name:'---'}}
                            {{data?.company?.employee[0]?.user?.last_name?data?.company?.employee[0]?.user?.last_name:'---'}}</span>
                        </td>

                        <td *ngIf="listNames?.includes('Avis')">
                          <div class="stars">
                            <rating [(ngModel)]="data?.company.average_note" [titles]="[' ',' ',' ',' ',' ']" [max]="5"
                                                          [readonly]="true" class="rating-stars disable-hover">
                                                        </rating>
                            </div>
                          <!-- <div class="stars" *ngIf="data.average_note" >
                            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
                              <i class="fa fa-stars" [class.fa-star]="i < data.average_note" [class.fa-star-half]="isHalfStar(i, data.average_note)" [class.fa-star-o]="i >= data.average_note"></i>
                            </ng-container>
                          </div>
                          <div class="stars" *ngIf="!data.average_note">
                            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
                              <i class="fa fa-stars" [class.fa-star]="i < 0" [class.fa-star-half]="isHalfStar(i, 0)" [class.fa-star-o]="i >= 0"></i>
                            </ng-container>
                          </div> -->
                        </td>
                        <td *ngIf="listNames?.includes('Pays')">
                          <span>{{data?.company?.country&&data?.company?.country!='null'?data?.company?.country:'---'}}</span>
                        </td>
                        <td *ngIf="listNames?.includes('Ville')">
                          <span>{{data?.company?.city&&data?.company?.city!='null' &&data?.company?.city!='undefined'?data?.company?.city:'---'}}</span>
                        </td>
                        <td *ngIf="listNames?.includes('Email')">
                          <span
                            class="wrapping">{{data?.company?.ContactEmail && data?.company?.ContactEmail!='undefined'?data?.company?.ContactEmail:'---'}}</span>
                        </td>
                        <td class="wrapping" *ngIf="listNames?.includes('N° Tel')">
                          <span> {{data?.company?.ContactPhone?data?.company?.ContactPhone:'---'}}</span>
                        </td>
                        <td *ngIf="listNames?.includes('Nbre AO')">
                          <span>{{data?.company?.nb_job_request?data?.company?.nb_job_request:'---'}}</span></td>
                        <td *ngIf="listNames?.includes('Nbre OE')">
                          <span>{{data?.company?.nb_job_offer?data?.company?.nb_job_offer:'---'}}</span></td>
                        <td *ngIf="listNames?.includes('Nbre CRA')">
                          <span>{{data?.company?.nb_cra?data.company.nb_cra:'---'}}</span></td>
                        <td *ngIf="listNames?.includes('Dernière connexion')">
                          <span>{{!data?.company?.user?.last_connection?'---':data?.company?.user?.last_connection
                            | formatDate}}</span>
                        </td>
                        <td *ngIf="listNames.length">
                          <div class="tools">
                            <!-- esn navigation  -->
                            <div *ngIf="data.company?.type=='4'||data.company?.type=='2'">
                              <div class="dropdown">
                                <button
                                  class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                  type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                  aria-expanded="false">
                                  <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                                      fill="#265D91" />
                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                      transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                      transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                  </svg>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <div>
                                    <!-- *ngIf="data.company?.type=='2'" -->
                                    <a class="dropdown-item cursor-pointer" 
                                      (click)="profilEsnNavigation(data.company?.id,data.company?.user?.role_user_id[0])" >Voir details</a>
                                    <!-- <a class="dropdown-item cursor-pointer" *ngIf="!data?.company?.user"
                                    data-bs-toggle="modal"
                                    data-bs-target="#keep_sign_up">
                                    (click)="deleteCompany(data.company?.id)"
                                    Continuer l'inscription</a> -->
                                    <a [class.disabled]="true"
                                    
                                      class="start dropdown-item cursor-pointer" >Supprimer
                                    </a>

                                  </div>

                                </div>
                              </div>

                            </div>
                            <!-- client final navigation -->
                            <div *ngIf="data.company?.type=='3'">
                              <div class="dropdown">
                                <button
                                  class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                  type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                  aria-expanded="false">
                                  <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                                      fill="#265D91" />
                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                      transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                      transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                  </svg>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <div>

                                    <a class="dropdown-item cursor-pointer"
                                      (click)="detailsClientFinalNavigation(data.company?.id,data.company?.user?.role_user_id[0])">Voir détails
                                    </a>
                                    <!-- <a class="dropdown-item cursor-pointer" *ngIf="!data?.company?.user"
                                    data-bs-toggle="modal"
                                    data-bs-target="#keep_sign_up" >
                                    (click)="deleteCompany(data.company?.id)"Continuer l'inscription</a> -->
                                    <a [class.disabled]="true"
                                    
                                    class="start dropdown-item cursor-pointer" >Supprimer
                                  </a>

                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>

                          <div class="tools" *ngIf="data.company?.type=='1'">
                            <div class="dropdown">
                              <button
                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                                    fill="#265D91" />
                                  <circle cx="2.44739" cy="2.44739" r="2.44739"
                                    transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                  <circle cx="2.44739" cy="2.44739" r="2.44739"
                                    transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                </svg>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div>
                                  <a class="dropdown-item cursor-pointer"
                                    (click)="openModal(details_add_societe,'details',data.company)">Voir profil</a>
                                  <!-- <a class="dropdown-item cursor-pointer" data-bs-toggle="modal"
                                    data-bs-target="#keep_sign_up"
                                    (click)="deleteCompany(data.company?.id)" href="">Continuer l'inscription</a> -->
                                    <a [class.disabled]="true"
                                    
                                    class="start dropdown-item cursor-pointer" >Supprimer
                                  </a>
                                </div>

                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                <!--end::Table-->

                <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listCampany&&!listCampany.length ">
                  <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                  <span class="empty-list">
                   La liste est vide
               </span>
               </div>
              </div>
            </div>
          </div>
          <!--end::Tab pane-->
        </div>
      </div>
      <!-- [endexPageList]="endIndex" -->
      <div class="pagination" *ngIf="listCampany && listCampany.length">
        <app-pagination [data]="listCampany" [lengthData]="totalItems"  [currentPageList]="currentPage" (setPages)="getItems($event)" [endexPageList]="endIndex"> </app-pagination>
      </div>
    </div>
    <div class="card for-mobile">
      <div class="container-fluid">
        <div class="d-flex flex-wrap flex-stack pt-10 pb-8 title">
          <!--begin::Heading-->
          <h4 class="my-2">Liste des entreprises</h4>
          <!--end::Heading-->
          <!--begin::Controls-->
        
          <!--end::Controls-->
        </div>

        <div class="tab-content">
          <!--begin::Tab pane-->
          <div id="grid" class="tab-pane fade  active show">
            <!--begin::Row-->
            <div class="container">
              <div class="row g-6 g-xl-9 cards" style="justify-content: center;">
                <!--begin::Col-->
                <div class="col-sm-6 col-xl-4" *ngFor="let data of listCampany">
                  <!--begin::Card-->
                  <div class="card h-100">
                    <div class="header-grid">
                      <div class="card-toolbar1">
                        <span [ngClass]="data?.company?.type=='1'?'entreprise':data?.company?.type=='2'?
                  'esn-type':data?.company?.type=='3'?'client-type':'centre-type'"
                          class="type-esn text-uppercase wrapping-company fw-bold">
                          {{data?.company?.type=='1'?'Entreprise':data?.company?.type=='2'?
                          'ESN':data?.company?.type=='3'?'Client Final':'Cabinet de Recrutement'}}</span>
                      </div>
                      <div class="card-toolbar m-0">
                        <!-- begin menu action -->

                        <div class="tools" *ngIf="data?.company?.type =='1'">
                          <div class="dropdown">
                            <button
                              class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                              type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false">
                              <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                                  fill="#265D91" />
                                <circle cx="2.44739" cy="2.44739" r="2.44739"
                                  transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                <circle cx="2.44739" cy="2.44739" r="2.44739"
                                  transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                              </svg>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <ng-container>
                                <a class="dropdown-item cursor-pointer"
                                  (click)="openModal(details_add_societe,'details')">Voir
                                  profil</a>
                                <a class="dropdown-item cursor-pointer" data-bs-toggle="modal"
                                  data-bs-target="#keep_sign_up" href="">Continuer l'inscrition</a>
                              </ng-container>

                            </div>
                          </div>
                        </div>
                        <!-- end menu action -->
                      </div>
                    </div>
                    <!--begin::Card header-->
                    <div class="card-header flex-nowrap border-0 pt-9">
                      <!--begin::Card title-->
                      <div class="card-title m-0">
                        <!--begin::Icon-->
                        <div class="symbol">
                          <img *ngIf="data?.company?.logo" class="table-img"
                            src="{{url+'/Company/file/gallery_company/'+ data?.company?.logo}}" alt="" width="75px">
                          <img *ngIf="!data?.company?.logo" class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt=""
                            width="75px">
                        </div>

                        <!--end::Icon-->
                        <!--begin::Title-->
                        <a class="name m-3">{{data?.company?.name?data?.company?.name:'---'}}</a>
                        <!-- <p>
                          <span class="wrapping" [ngClass]="data?.company?.type=='1'?'entreprise':data.company?.type=='2'?
                          'esn-type':data.company?.type=='3'?'client-type':'centre-type'">
                            {{data?.company?.type=='1'?'Entreprise':data.company?.type=='2'||data.company?.type=='5'?
                            'ESN':data.company?.type=='3'?'Client Final':data.company?.type=='4'?'Cabinet de Recrutement':'Particulier'}}</span>
                        </p> -->
                        <div class="stars">
                          <rating [(ngModel)]="data.average_note" [titles]="[' ',' ',' ',' ',' ']" [max]="5"
                            [readonly]="true" class="rating-stars disable-hover">
                          </rating>
                        </div>


                        <!--end::Title-->
                      </div>
                      <!--end::Card title-->
                      <!--begin::Card toolbar-->

                      <!--end::Card toolbar-->
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body d-flex flex-column px-5 pt-6 pb-8">
                      <!--begin::Heading-->


                      <!--end::Heading-->
                      <!--begin::Stats-->

                      <!--end::Stats-->
                      <!--begin::Indicator-->
                      <div class="footer d-flex fw-semibold">
                        <div class="website">
                          <p><span class="line-break"><a target="_blank"
                                href="{{data?.company?.website_link?data?.company?.website_link:'---'}}"><svg width="18"
                                  height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="0.0410156" y="0.0407715" width="17.9592" height="17.9592" rx="3"
                                    fill="#265D91" />
                                  <path
                                    d="M8.72077 14.4082C5.4083 14.4082 2.73438 12.0017 2.73438 9.02045C2.73438 6.03922 5.4083 3.63269 8.72077 3.63269C12.0332 3.63269 14.7072 6.03922 14.7072 9.02045C14.7072 12.0017 12.0332 14.4082 8.72077 14.4082ZM8.72077 4.35106C5.8473 4.35106 3.53256 6.43432 3.53256 9.02045C3.53256 11.6066 5.8473 13.6898 8.72077 13.6898C11.5942 13.6898 13.909 11.6066 13.909 9.02045C13.909 6.43432 11.5942 4.35106 8.72077 4.35106Z"
                                    fill="white" />
                                  <path
                                    d="M11.833 10.3494C11.9128 9.91841 11.9128 9.48738 11.9128 9.02045C11.9128 8.55351 11.8729 8.12249 11.833 7.69147C11.7532 7.11677 11.5936 6.578 11.4339 6.07514C10.8353 4.60249 9.87746 3.63269 8.72009 3.63269C7.36317 3.63269 6.24571 4.96167 5.7668 6.90126C5.72689 7.08085 5.68698 7.22453 5.64707 7.40412C5.56725 7.90698 5.52734 8.44575 5.52734 9.02045C5.52734 9.48738 5.56725 9.91841 5.60716 10.3494C5.68698 10.9241 5.84662 11.4629 6.00626 11.9658C6.56499 13.4384 7.56272 14.4082 8.68018 14.4082C9.79764 14.4082 10.7954 13.4384 11.3541 11.9658C11.0747 12.0376 10.7555 12.0735 10.4362 12.1453C10.0371 13.1151 9.35864 13.6898 8.72009 13.6898C8.08154 13.6898 7.40308 13.1151 6.96408 12.1453C6.76453 11.7143 6.60489 11.1755 6.48517 10.6009C6.36544 10.098 6.32553 9.59514 6.32553 9.02045C6.32553 8.44575 6.36544 7.90698 6.48517 7.44004C6.52508 7.26045 6.56499 7.11677 6.60489 6.93718V6.90126C6.6448 6.72167 6.68471 6.578 6.76453 6.43432C6.80444 6.32657 6.84435 6.21881 6.88426 6.14698C6.88426 6.14698 6.88426 6.14698 6.88426 6.11106L6.96408 5.96738C6.96408 5.96738 6.96408 5.93147 7.00399 5.89555C7.40308 4.92575 8.08154 4.35106 8.72009 4.35106C9.35864 4.35106 10.0371 4.92575 10.4761 5.89555C10.6756 6.32657 10.8353 6.86534 10.955 7.44004C11.0747 7.94289 11.1146 8.44575 11.1146 9.02045C11.1146 9.59514 11.0747 10.098 10.9949 10.6009C11.2743 10.529 11.5536 10.4572 11.833 10.3494Z"
                                    fill="white" />
                                  <path
                                    d="M11.9933 6.61416C11.4745 6.43456 10.836 6.32681 10.1974 6.25497C9.7185 6.18314 9.23959 6.14722 8.72077 6.14722C8.20195 6.14722 7.72304 6.18314 7.24412 6.21905C7.16431 6.43456 7.04458 6.68599 6.96476 6.97334C7.52349 6.9015 8.08222 6.86558 8.72077 6.86558C9.35932 6.86558 9.91805 6.9015 10.4768 6.97334C11.1153 7.08109 11.714 7.22477 12.1929 7.40436C13.2704 7.83538 13.909 8.41007 13.909 8.98477C13.909 9.55946 13.2704 10.1701 12.1929 10.5652C11.714 10.7448 11.1153 10.8884 10.4768 10.9962C9.91805 11.1399 9.35932 11.1758 8.72077 11.1758C8.08222 11.1758 7.52349 11.1399 6.96476 11.0321C6.32621 10.9603 5.72757 10.8166 5.24866 10.6011C4.17111 10.206 3.53256 9.59538 3.53256 9.02069C3.53256 8.44599 4.17111 7.83538 5.24866 7.44028C5.28857 7.15293 5.36839 6.86558 5.44821 6.61416C3.81193 7.11701 2.73438 7.97905 2.73438 9.02069C2.73438 10.0623 3.81193 10.9244 5.44821 11.4272C5.96703 11.6068 6.60558 11.7146 7.24412 11.7864C7.72304 11.8582 8.20195 11.8582 8.72077 11.8582C9.23959 11.8582 9.7185 11.8223 10.1974 11.7864C10.836 11.7146 11.4745 11.5709 11.9933 11.4272C13.6296 10.9244 14.7072 10.0264 14.7072 9.02069C14.7072 8.01497 13.6296 7.11701 11.9933 6.61416Z"
                                    fill="white" />
                                </svg>

                              </a></span></p>
                        </div>
                        <div class="details cursor-pointer">
                          <a (click)="detailsClientFinalNavigation(data.company?.id,data.company?.user?.role_user_id[0])">Voir détail</a>
                        </div>
                      </div>
                      <!--end::Indicator-->
                    </div>
                    <!--end::Card body-->
                  </div>
                  <!--end::Card-->
                </div>
                <!--end::Col-->


              </div>
              <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listCampany && !listCampany.length ">
                <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                <span class="empty-list">
                 La liste est vide
             </span>
             </div>
            </div>
            <!--end::Row-->
          </div>
          <!--end::Tab pane-->
          <!--begin::Tab pane-->
       
          <!--end::Tab pane-->
        </div>
      </div>
      <div class="pagination" *ngIf="listCampany && listCampany.length">
        <app-pagination [data]="listCampany" [lengthData]="totalItems" (setPages)="getItems($event)"> </app-pagination>
      </div>
    </div>
  
  </div>
    <!--begin Modal deatils or add company  -->
    <ng-template #details_add_societe>
      <div class="modal-content" id="details_add_societe" tabindex="-1" aria-hidden="true">
        <!--begin::Modal dialog-->
        <div class="width-content">
          <!--begin::Modal content-->
          <!--begin::Modal header-->
          <div class="modal-header">
            <!--begin::Modal title-->
            <h2>{{ mode == "details" ? "Détails " : "Ajouter " }} société</h2>
            <!--end::Modal title-->
            <!--begin::Close-->
            <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <i class="ki-duotone ki-cross fs-1" (click)="closeModal()">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->
          <!--begin::Modal body-->
          <div class="modal-body scroll-y mx-5 tools">
            <!--begin::Form-->
            <form id="kt_modal_new_card_form " class="form" action="#" [formGroup]="companyForm">
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-7 fv-row">
                <!--begin::Label-->
                <label class="d-flex align-items-center form-label mb-2">
                  <span class="required">Nom société </span>
                </label>
                <!--end::Label-->
                <input type="text" [disabled]="mode=='details'" formControlName="name"
                  class="form-control form-control-solid" placeholder="Nom société" name="Nom société" />
                <app-feild-error class="mt-3" [control]="companyForm.get('name')"></app-feild-error>
              </div>
              <div class="d-flex flex-column mb-7 fv-row">
                <!--begin::Label-->
                <label class="d-flex align-items-center form-label mb-2">
                  <span >À propos </span>
                </label>
                <!--end::Label-->
                <textarea type="text" [disabled]="mode=='details'" formControlName="about"
                  class="form-control form-control-solid details-entr" placeholder="À propos" name="about">
                </textarea>
                  
              </div>

              <div class="d-flex flex-column mb-7 fv-row">
                <!--begin::Label-->
                <label class="d-flex align-items-center form-label mb-2">
                  <span class="">Linkedin</span>
                </label>
                <!--end::Label-->
                
                  <!-- <div class="combined-input-linkedin mb-5"> -->
                    <!-- <input type="text" name="linkedin" class="form-control linkedin1 input-placeholder"
                      placeholder="https://www.linkedin.com/company/" id="linkedin" disabled /> -->
                    <input type="text" name="linkedin" class="form-control linkedin2" id="linkedin"
                      placeholder="https://www.linkedin.com/company/" (change)="setValidatorRequirements()"
                      formControlName="linkedin_link" />
                      <app-feild-error [control]="companyForm.get('linkedin_link')"></app-feild-error>
                  </div>
               
           
              <div class="d-flex flex-column mb-7 fv-row">
                <!--begin::Label-->
                <label class="d-flex align-items-center form-label mb-2">
                  <span class="
                  ">Site web</span>
                </label>
                <!--end::Label-->
                <input type="text" [disabled]="mode=='details'" (change)="setValidatorRequirements()"
                  formControlName="website_link" class="form-control form-control-solid" placeholder="https://www.exemple.com ou www.exemple.com ou https://exemple.com"
                  name="Site web" />
                <app-feild-error class="mt-3" [control]="companyForm.get('website_link')"></app-feild-error>
                <div *ngIf="!companyForm.get('website_link')?.hasError('required')">
                  <span class="requirements mb-3" *ngIf="mode!='details'">
                    <svg width="16" height="16"
              viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_379_198)">
                  <path
                      d="M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346629 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87104 0 6.41775 0 8C0.00229405 10.121 0.845886 12.1545 2.34568 13.6543C3.84547 15.1541 5.87897 15.9977 8 16ZM8 3.33334C8.19778 3.33334 8.39112 3.39199 8.55557 3.50187C8.72002 3.61175 8.84819 3.76793 8.92388 3.95065C8.99957 4.13338 9.01937 4.33445 8.98079 4.52843C8.9422 4.72241 8.84696 4.90059 8.70711 5.04044C8.56726 5.1803 8.38907 5.27554 8.19509 5.31412C8.00111 5.35271 7.80004 5.3329 7.61732 5.25722C7.43459 5.18153 7.27841 5.05336 7.16853 4.88891C7.05865 4.72446 7 4.53112 7 4.33334C7 4.06812 7.10536 3.81377 7.29289 3.62623C7.48043 3.43869 7.73478 3.33334 8 3.33334ZM7.33333 6.66667H8C8.35362 6.66667 8.69276 6.80715 8.94281 7.0572C9.19286 7.30724 9.33333 7.64638 9.33333 8V12C9.33333 12.1768 9.2631 12.3464 9.13807 12.4714C9.01305 12.5964 8.84348 12.6667 8.66667 12.6667C8.48986 12.6667 8.32029 12.5964 8.19526 12.4714C8.07024 12.3464 8 12.1768 8 12V8H7.33333C7.15652 8 6.98695 7.92977 6.86193 7.80474C6.73691 7.67972 6.66667 7.51015 6.66667 7.33334C6.66667 7.15653 6.73691 6.98696 6.86193 6.86193C6.98695 6.73691 7.15652 6.66667 7.33333 6.66667Z"
                      fill="#2b5d8f" />
              </g>
              <defs>
                  <clipPath id="clip0_379_198">
                      <rect width="16" height="16" fill="white" />
                  </clipPath>
              </defs>
          </svg>
                      &nbsp;Au moins l'un des champs Site web ou LinkedIn est requis.
                  </span>
              </div>
              </div>
              <!-- <span class="requirements mt-5 mb-10" *ngIf="mode!='details'"> <svg width="16" height="16"
                  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_379_198)">
                    <path
                      d="M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346629 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87104 0 6.41775 0 8C0.00229405 10.121 0.845886 12.1545 2.34568 13.6543C3.84547 15.1541 5.87897 15.9977 8 16ZM8 3.33334C8.19778 3.33334 8.39112 3.39199 8.55557 3.50187C8.72002 3.61175 8.84819 3.76793 8.92388 3.95065C8.99957 4.13338 9.01937 4.33445 8.98079 4.52843C8.9422 4.72241 8.84696 4.90059 8.70711 5.04044C8.56726 5.1803 8.38907 5.27554 8.19509 5.31412C8.00111 5.35271 7.80004 5.3329 7.61732 5.25722C7.43459 5.18153 7.27841 5.05336 7.16853 4.88891C7.05865 4.72446 7 4.53112 7 4.33334C7 4.06812 7.10536 3.81377 7.29289 3.62623C7.48043 3.43869 7.73478 3.33334 8 3.33334ZM7.33333 6.66667H8C8.35362 6.66667 8.69276 6.80715 8.94281 7.0572C9.19286 7.30724 9.33333 7.64638 9.33333 8V12C9.33333 12.1768 9.2631 12.3464 9.13807 12.4714C9.01305 12.5964 8.84348 12.6667 8.66667 12.6667C8.48986 12.6667 8.32029 12.5964 8.19526 12.4714C8.07024 12.3464 8 12.1768 8 12V8H7.33333C7.15652 8 6.98695 7.92977 6.86193 7.80474C6.73691 7.67972 6.66667 7.51015 6.66667 7.33334C6.66667 7.15653 6.73691 6.98696 6.86193 6.86193C6.98695 6.73691 7.15652 6.66667 7.33333 6.66667Z"
                      fill="#EE6352" />
                  </g>
                  <defs>
                    <clipPath id="clip0_379_198">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg> &nbsp;Au moins l'un des champs Site web ou LinkedIn est requis.</span> -->
              <div class="d-flex flex-column mb-7 mt-7 fv-row" *ngIf="mode == 'create'">
                <!--begin::Label-->
                <label for="Type" class="form-label">Type</label>
                <ng-select formControlName="type" [items]="typesCompanies" bindLabel="name" bindValue="id"
                  [placeholder]="'Type société'" [notFoundText]="'Aucun élément trouvé'"  autoComplete></ng-select>
                <app-feild-error class="mt-3"  [control]="companyForm.get('type')"></app-feild-error>
              </div>
              <div class="d-flex flex-column mb-7 fv-row" *ngIf="mode == 'create'" id="reseticone">
              <div class="w-100">
                <div class="labels">
                    <label for="logo" >Importer Logo </label>
                    <button *ngIf="fileName!=''" class="more-label deletePhoto" (click)="resetImage()" >
                        <img src="assets/media/icons/icon-delete.png" alt="" width="30px"
                            class="cursor-pointer">
                    </button>
                </div>
            </div>
                <!--end::Label-->
                <input formControlName="logo" #inputFile type="file" name="logo" id="logo" (change)="uploadFile($event)"
                  class="form-control mb-3">

                <span style="color:red" *ngIf="fileError"><small>{{fileError}} </small></span>
                <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}} </small></span>
              </div>

            </form>
            <div class="modal-footer" *ngIf="mode!='details'">
              <button (click)="closeModal()" type="reset" id="kt_modal_new_card_cancel" class="footer-btn me-3 annuler"
                data-bs-dismiss="modal">
                Annuler
              </button>
              <button (click)="addCompany()" [disabled]="!companyForm.valid  || fileError!='' || fileExtention!=''"
                type="submit" id="kt_modal_new_card_submit" class="footer-btn confirmer">
                <span class="indicator-label">Confirmer</span>
                <span class="indicator-progress">Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </button>
            </div>
            <!--end::Form-->
          </div>
          <!--end::Modal body-->
          <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
      </div>
    </ng-template>

</div>

<ng-template #add_masse>
  <div class="modal-content" id="add_masse" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <!--begin::Modal content-->
    <div class="">
      <!--begin::Modal content-->
      <div class="content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2 class="">Ajouter en masse</h2>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div id="kt_customers_export_close" data-bs-dismiss="modal"
            class="btn btn-icon btn-sm btn-active-icon-primary">
            <i class="ki-duotone ki-cross fs-1" (click)="closeModalBulk()">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
          <!--begin::Form (keyup.enter)="login()"-->
          <label for="masse">Votre ficher CSV</label>
        <input type="file" name="masse" id="masse" class="form-control" (change)="onFileSelected($event)">
        <span class="error mt-5">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_379_198)">
              <path
                d="M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346629 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87104 0 6.41775 0 8C0.00229405 10.121 0.845886 12.1545 2.34568 13.6543C3.84547 15.1541 5.87897 15.9977 8 16ZM8 3.33334C8.19778 3.33334 8.39112 3.39199 8.55557 3.50187C8.72002 3.61175 8.84819 3.76793 8.92388 3.95065C8.99957 4.13338 9.01937 4.33445 8.98079 4.52843C8.9422 4.72241 8.84696 4.90059 8.70711 5.04044C8.56726 5.1803 8.38907 5.27554 8.19509 5.31412C8.00111 5.35271 7.80004 5.3329 7.61732 5.25722C7.43459 5.18153 7.27841 5.05336 7.16853 4.88891C7.05865 4.72446 7 4.53112 7 4.33334C7 4.06812 7.10536 3.81377 7.29289 3.62623C7.48043 3.43869 7.73478 3.33334 8 3.33334ZM7.33333 6.66667H8C8.35362 6.66667 8.69276 6.80715 8.94281 7.0572C9.19286 7.30724 9.33333 7.64638 9.33333 8V12C9.33333 12.1768 9.2631 12.3464 9.13807 12.4714C9.01305 12.5964 8.84348 12.6667 8.66667 12.6667C8.48986 12.6667 8.32029 12.5964 8.19526 12.4714C8.07024 12.3464 8 12.1768 8 12V8H7.33333C7.15652 8 6.98695 7.92977 6.86193 7.80474C6.73691 7.67972 6.66667 7.51015 6.66667 7.33334C6.66667 7.15653 6.73691 6.98696 6.86193 6.86193C6.98695 6.73691 7.15652 6.66667 7.33333 6.66667Z"
                fill="#2b5d8f" />
            </g>
            <defs>
              <clipPath id="clip0_379_198">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Le champ "nom" est obligatoire. Fournir un lien LinkedIn ou un site web.
        </span>
        <span style="color:red" *ngIf="fileExtentions"><small>{{fileExtentions}} </small></span>

          <div class="modal-footer">
            <button type="reset" (click)="closeModalBulk()" id="kt_customers_export_cancel" data-bs-dismiss="modal"
              class="footer-btn annuler me-3">Annuler</button>
            <button [disabled]="fileExtentions!='' || !selectedFile" type="submit" id="kt_customers_export_submit"
            (click)="addBulkCompany()"  class="footer-btn confirmer ">
              <span class="indicator-label">Confirmer</span>
              <span class="indicator-progress">Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
          </div>
          <!--end::Form-->
        </div>
        <!--end::Modal body-->
      </div>
    </div>
  </div>
</ng-template>
<ng-template #details_societe>
  <div class="modal-content" id="details_add_societe" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="width-content">
      <!--begin::Modal content-->
      <!--begin::Modal header-->
      <div class="modal-header">
        <!--begin::Modal title-->
        <h2>{{ mode == "details" ? "Détails " : "Ajouter " }} société</h2>
        <!--end::Modal title-->
        <!--begin::Close-->
        <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
          <i class="ki-duotone ki-cross fs-1" (click)="closeModal()">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body scroll-y mx-5 tools">
        <!--begin::Form-->
        <form id="kt_modal_new_card_form " class="form" action="#" [formGroup]="companyForm">
          <!--begin::Input group-->
          <div class="d-flex flex-column mb-7 fv-row">
            <!--begin::Label-->
            <label class="d-flex align-items-center form-label mb-2">
              <span class="required">Nom société </span>
            </label>
            <!--end::Label-->
            <input type="text" [disabled]="mode=='details'" formControlName="name"
              class="form-control form-control-solid" placeholder="Nom société" name="Nom société" readonly />
            <app-feild-error class="mt-3" [control]="companyForm.get('name')"></app-feild-error>
          </div>
          <div class="d-flex flex-column mb-7 fv-row">
            <!--begin::Label-->
            <label class="d-flex align-items-center form-label mb-2">
              <span >À propos </span>
            </label>
            <!--end::Label-->
            <textarea type="text" [disabled]="mode=='details'" formControlName="about"
              class="form-control form-control-solid details-entr" placeholder="À propos" name="about" readonly>
            </textarea>
              
          </div>

          <div class="d-flex flex-column mb-7 fv-row">
            <!--begin::Label-->
            <label class="d-flex align-items-center form-label mb-2">
              <span class="">Linkedin</span>
            </label>
            <!--end::Label-->
            
              <div class="combined-input-linkedin mb-5">
                <!-- <input type="text" name="linkedin" class="form-control linkedin1 input-placeholder"
                  placeholder="https://www.linkedin.com/company/" id="linkedin" disabled /> -->
                <input type="text" name="linkedin" class="form-control linkedin2" id="linkedin"
                  placeholder="Nom de linkedin" (change)="setValidatorRequirements()"
                  formControlName="linkedin_link" readonly />
              </div>
            <!-- <app-feild-error class="mt-3" [control]="companyForm.get('linkedin_link')"></app-feild-error> -->
          </div>
          <div class="d-flex flex-column mb-7 fv-row">
            <!--begin::Label-->
            <label class="d-flex align-items-center form-label mb-2">
              <span class="
              ">Site web</span>
            </label>
            <!--end::Label-->
            <input type="text" [disabled]="mode=='details'" (change)="setValidatorRequirements()"
              formControlName="website_link" class="form-control form-control-solid" placeholder="https://www.exemple.com ou www.exemple.com ou https://exemple.com"
              name="Site web" readonly/>
            <app-feild-error class="mt-3" [control]="companyForm.get('website_link')"></app-feild-error>
            <div *ngIf="!companyForm.get('website_link')?.hasError('required')">
              <span class="requirements mb-3" *ngIf="mode!='details'">
                <svg width="16" height="16"
          viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_379_198)">
              <path
                  d="M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346629 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87104 0 6.41775 0 8C0.00229405 10.121 0.845886 12.1545 2.34568 13.6543C3.84547 15.1541 5.87897 15.9977 8 16ZM8 3.33334C8.19778 3.33334 8.39112 3.39199 8.55557 3.50187C8.72002 3.61175 8.84819 3.76793 8.92388 3.95065C8.99957 4.13338 9.01937 4.33445 8.98079 4.52843C8.9422 4.72241 8.84696 4.90059 8.70711 5.04044C8.56726 5.1803 8.38907 5.27554 8.19509 5.31412C8.00111 5.35271 7.80004 5.3329 7.61732 5.25722C7.43459 5.18153 7.27841 5.05336 7.16853 4.88891C7.05865 4.72446 7 4.53112 7 4.33334C7 4.06812 7.10536 3.81377 7.29289 3.62623C7.48043 3.43869 7.73478 3.33334 8 3.33334ZM7.33333 6.66667H8C8.35362 6.66667 8.69276 6.80715 8.94281 7.0572C9.19286 7.30724 9.33333 7.64638 9.33333 8V12C9.33333 12.1768 9.2631 12.3464 9.13807 12.4714C9.01305 12.5964 8.84348 12.6667 8.66667 12.6667C8.48986 12.6667 8.32029 12.5964 8.19526 12.4714C8.07024 12.3464 8 12.1768 8 12V8H7.33333C7.15652 8 6.98695 7.92977 6.86193 7.80474C6.73691 7.67972 6.66667 7.51015 6.66667 7.33334C6.66667 7.15653 6.73691 6.98696 6.86193 6.86193C6.98695 6.73691 7.15652 6.66667 7.33333 6.66667Z"
                  fill="#2b5d8f" />
          </g>
          <defs>
              <clipPath id="clip0_379_198">
                  <rect width="16" height="16" fill="white" />
              </clipPath>
          </defs>
      </svg>
                  &nbsp;Au moins l'un des champs Site web ou LinkedIn est requis.
              </span>
          </div>
          </div>
          <div class="d-flex flex-column mb-7 mt-7 fv-row" *ngIf="mode == 'create'">
            <!--begin::Label-->
            <label for="Type" class="form-label">Type</label>
            <ng-select formControlName="type" [items]="typesCompanies" bindLabel="name" bindValue="id"
              [placeholder]="'Type société'" [notFoundText]="'Aucun élément trouvé'"  autoComplete></ng-select>
            <app-feild-error class="mt-3"  [control]="companyForm.get('type')"></app-feild-error>
          </div>
          <div class="d-flex flex-column mb-7 fv-row" *ngIf="mode == 'create'" id="reseticone">
          <div class="w-100">
            <div class="labels">
                <label for="logo" >Importer Logo </label>
                <button *ngIf="fileName!=''" class="more-label deletePhoto" (click)="resetImage()" >
                    <img src="assets/media/icons/icon-delete.png" alt="" width="30px"
                        class="cursor-pointer">
                </button>
            </div>
        </div>
            <input formControlName="logo" #inputFile type="file" name="logo" id="logo" (change)="uploadFile($event)"
              class="form-control mb-3">

            <span style="color:red" *ngIf="fileError"><small>{{fileError}} </small></span>
            <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}} </small></span>
          </div>
        </form>
        <div class="modal-footer" *ngIf="mode!='details'">
          <button (click)="closeModal()" type="reset" id="kt_modal_new_card_cancel" class="footer-btn me-3 annuler"
            data-bs-dismiss="modal">
            Annuler
          </button>
          <button (click)="addCompany()" [disabled]="!companyForm.valid  || fileError!='' || fileExtention!=''"
            type="submit" id="kt_modal_new_card_submit" class="footer-btn confirmer">
            <span class="indicator-label">Confirmer</span>
            <span class="indicator-progress">Please wait...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

