import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../core/models/messages';
import { SettingsService } from '../../../../core/services/settings/settings.service';

@Component({
  selector: 'app-list-abonnement',
  templateUrl: './list-abonnement.component.html',
  styleUrls: ['./list-abonnement.component.css'],
})
export class ListAbonnementComponent {
  /* string */
  nameOffer!: string;
  searchElement: string = '';
  /* number */
  idOffer!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  pagination : boolean =false;

  /*  */
  listAbonnement: any;
  fileInvoice: any;
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private settingService: SettingsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    this.getIdOffre();
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') this.applyFilter();
      else   if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
        this.getListCandidaturesByOffer();
      }
    }
  }
  getIdOffre() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idOffer = params.params['id'];
        this.nameOffer = params.params['abonnement'];
        if (this.idOffer) this.getListCandidaturesByOffer();
      },
    });
  }
  /* get list offer */
  getListCandidaturesByOffer() {
    this.spinner.show();
    this.settingService
      .listCandidatureByOffer(this.idOffer)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listAbonnement = res.data.data.slice(0, this.endIndex);
          this.totalItems = res.data.total;
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  profilEsnNavigation(id: string) {
    this.router.navigate(['/acceuil/entreprises/esn/profil-esn', { id: id }]);
  }
  /* filter data on key up input */
  clickCount: number = 0;
  applyFilter() {
    if (this.searchElement != '' || this.clickCount) {
      this.clickCount++;
      if (this.clickCount == 1) this.currentPage = 1;
      let data = {
        // sort: this.currentSortDirection,
        // sortAttribute: 'name',
        search: this.searchElement.trim().toLowerCase(),
        page: this.currentPage,
        per_page: this.itemsPerPage,
        offer_id: this.idOffer,
      };
      this.spinner.show();
      this.settingService
        .searchCandidature(data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (!res.data.data.length && this.currentPage > 1) {
              this.currentPage = 1;
              this.applyFilter();
            } else {
              this.listAbonnement = res.data.data.slice(0, this.endIndex);
              this.totalItems = res.data.total;
            }
            this.spinner.hide();
          },
          error: () => {
            this.spinner.hide();
            this.toastr.error(serverError);
          },
        });
    }
  }

  exportInvoice(id: any,file?:File) {
    this.spinner.show();

    this.settingService
      .exportInvoice(file,id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();
          const defaultFileName = 'facture';

          const fileName =
            this.fileInvoice && this.fileInvoice.endsWith('.pdf')
              ? this.fileInvoice
              : defaultFileName + '.pdf';

          const blob = new Blob([res], { type: 'application/octet-stream' });
          saveAs(blob, fileName);

          this.toastr.success('Facture exportée avec succès');
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  clearData() {
    window.location.reload();
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
