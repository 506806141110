import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { CSearchCandidate } from '../../../../../../core/models/candidat';
import { serverError } from '../../../../../../core/models/messages';
import { CompanyService } from '../../../../../../core/services/company/company.service';
import { SortDataService } from '../../../../../../core/services/sort-data/sort-data.service';
import { environment } from '../../../../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-consult',
  templateUrl: './list-consult.component.html',
  styleUrls: ['./list-consult.component.css'],
})
export class ListConsultComponent {
  /* string */
  sortAttr: string = '';
  mode: string = 'create';
  searchElement: string = '';
  url: string = environment.baseUrl + '/api';
  idCompany!: any;
  typeCompany!: number;
  /* number */
  currentSortDirection: number = 2;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  clickCount: number = 0;
  pagination : boolean =false;

  /* form group */
  searchFormGroup: FormGroup = this.createSearchForm(new CSearchCandidate());
  /* any */
  listConsultant: any = [];
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sortDataService: SortDataService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit() {
    this.getIdCompany();
  }
  /* get company id from route */
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCompany = params.params['id'];
        this.typeCompany = params.params['type'];
        this.getListIntervenant();
      },
    });
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') this.applyFilter();
      else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
      this.getListIntervenant();
      }
    }
  }
  /* sort data  */
  sortData(sortAttribute: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr = sortAttribute;
    this.currentPage = 1;
    this.endIndex=5;
    if (this.searchElement != '')
      this.listConsultant = this.sortDataService.sortArray(
        this.listConsultant,
        sortAttribute,
        this.currentSortDirection
      );
    else this.getListIntervenant();
  }
  /* list list esn companies */
  getListIntervenant() {
    this.spinner.show();
    let dataPyload =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage, type: 2 }
        : {
            ...{ per_page: this.itemsPerPage, page: this.currentPage },
            ...{
              sort: this.currentSortDirection,
              sortAttribute: this.sortAttr,
              type: 2,
            },
          };
    this.companyService
      .getConsultantListESN(this.idCompany, dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.listConsultant = res.data.data;
          console.log('listconsultant', this.listConsultant);
          this.totalItems = res.data.total;
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  navigateToProfilCandidat(idCandidat: number) {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  /* search intervenant on input */
  applyFilter() {
    let data = {
      name: this.searchFormGroup.value.name,
      client_name: this.searchFormGroup.value.client_name,
      type: '2',
      manager_name: this.searchFormGroup.value.manager_name,
    };
    this.spinner.show();
    this.companyService
      .searchConsultant(this.idCompany, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          if (!res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.applyFilter();
          } else {
            this.spinner.hide();
            this.listConsultant = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }

  goToDetails(id: number) {
    this.router.navigate([
      '/acceuil/entreprises/esn/details-consultant-esn',
      { id: id },
    ]);
  }
  /* create search form */
  createSearchForm(data?: CSearchCandidate) {
    return this.formBuilder.group({
      name: ['', data?.name],
      client_name: ['', data?.client_name],
      manager_name: ['', data?.manager_name],
    });
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.searchFormGroup = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
      console.log('gggg');
      console.log('gggg', this.isRecherche);
    } else {
      this.spinner.hide();
      console.log('hhh');
      this.getListIntervenant();
      console.log('hhhh', this.isRecherche);
    }
    return (this.isRecherche = false);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  dissocierconsultant(id: any) {
    let formdata = new FormData();
    formdata.append('esn_id', this.idCompany);
    formdata.append('candidate_id', id);
    console.log('formdata', formdata);
    Swal.fire({
      title: 'Voulez-vous vraiment dissocier ce consultant ?',
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, dissocier !',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.companyService
          .dissocierconsultant(formdata)
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe({
            next: (res: any) => {
              console.log('response', res);
              this.getListIntervenant();
            },
            error: () => {
              this.spinner.hide();
            },
          });
      }
    });
  }
  cleanLastConnection(dateString: string): string {
    return dateString.replace('admin', '').trim();
  }
}
