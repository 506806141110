import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ListSocieteComponent } from './compnents/list-societe/list-societe.component';
import { CompaniesRoutingModule } from './companies-routing.module';
import { ListEsnModule } from './compnents/list-esn/list-esn.module';
import { ClientFinalModule } from './compnents/client-final/client-final.module';
import { PipesModuleModule } from '../../core/pipes/pipes-module.module';
import { FieldErrorModule } from '../../shared/components/shared-field-error/field-error.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { RatingModule } from 'ngx-bootstrap/rating';
import { FormsModule } from '@angular/forms';
import { DetailsocieteComponent } from './compnents/list-societe/detailsociete/detailsociete/detailsociete.component';
import { DetailsocieteReviewComponent } from './compnents/list-societe/detailsociete-review/detailsociete-review/detailsociete-review.component';
import { CalendarModule } from 'primeng/calendar';
import localeFr from '@angular/common/locales/fr';


@NgModule({
  declarations: [
    ListSocieteComponent,
    DetailsocieteComponent,
    DetailsocieteReviewComponent],
  imports: [
    CommonModule,
    CompaniesRoutingModule,
    ListEsnModule,
    ClientFinalModule,
    PipesModuleModule,
    NgxSpinnerModule,
    PaginationModule,
    FieldErrorModule,
    FormsModule,
    RatingModule.forRoot(),
    CalendarModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }]






})
export class CompaniesModule {
  constructor() {
    registerLocaleData(localeFr);
  }
}
