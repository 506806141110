<div
  id="kt_app_header"
  class="app-header"
  data-kt-sticky="true"
  data-kt-sticky-activate="{default: true, lg: true}"
  data-kt-sticky-name="app-header-minimize"
  data-kt-sticky-offset="{default: '200px', lg: '0'}"
  data-kt-sticky-animation="false"
>
  <!--begin::Header container-->
  <div
    class="app-container container-fluid d-flex align-items-stretch justify-content-between"
    id="kt_app_header_container"
  >
    <!--begin::Sidebar mobile toggle-->
    <div
      class="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
      title="Show sidebar menu"
    >
      <div
        class="btn btn-icon btn-active-color-primary w-35px h-35px"
        id="kt_app_sidebar_mobile_toggle"
      >
        <i class="ki-duotone ki-abstract-14 fs-2 fs-md-1">
          <span class="path1"></span>
          <span class="path2"></span>
        </i>
      </div>
    </div>
    <!--end::Sidebar mobile toggle-->
    <!--begin::Mobile logo-->
    <div class="logo d-flex align-items-center flex-grow-1 flex-lg-grow-0">
      <a href="/" class="d-lg-none">
        <img
          alt="Logo"
          src="/assets/media/logos/logo-wegestu-color.png"
          class="h-30px"
        />
      </a>
    </div>
    <!--end::Mobile logo-->
    <!--begin::Header wrapper-->
    <div
      class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
      id="kt_app_header_wrapper"
    >
      <!--begin::Menu wrapper-->
      <div
        class="app-header-menu app-header-mobile-drawer align-items-stretch"
        data-kt-drawer="true"
        data-kt-drawer-name="app-header-menu"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="250px"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_app_header_menu_toggle"
        data-kt-swapper="true"
        data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
        data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
      >
        <!--begin::Menu-->
        <div
          class="left-menu menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
          id="kt_app_header_menu"
          data-kt-menu="true"
        >
          <a
            id="kt_app_sidebar_toggle"
            href="#"
            class="menu-link app-sidebar-toggle mr-2"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="app-sidebar-minimize"
          >
            <svg
              width="21"
              height="16"
              viewBox="0 0 21 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 14.7368C0 15.4358 0.58625 16 1.3125 16H15.3125C16.0387 16 16.625 15.4358 16.625 14.7368C16.625 14.0379 16.0387 13.4737 15.3125 13.4737H1.3125C0.58625 13.4737 0 14.0379 0 14.7368ZM15.3125 2.52632H1.3125C0.58625 2.52632 0 1.9621 0 1.26316C0 0.564211 0.58625 0 1.3125 0H15.3125C16.0387 0 16.625 0.564211 16.625 1.26316C16.625 1.9621 16.0387 2.52632 15.3125 2.52632ZM19.6875 9.26316H5.6875C4.96125 9.26316 4.375 8.69895 4.375 8C4.375 7.30105 4.96125 6.73684 5.6875 6.73684H19.6875C20.4137 6.73684 21 7.30105 21 8C21 8.69895 20.4137 9.26316 19.6875 9.26316Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
        <!--end::Menu-->
      </div>
      <!--end::Menu wrapper-->
      <!--begin::Navbar-->

      <div class="app-navbar flex-shrink-0">
        <!--begin::Search-->

        <!--end::Search-->
        <!--begin::Activities-->
        <div class="app-navbar-item ms-1 ms-md-4   ">
          <!--begin::Menu- wrapper-->
          <div
            class="cursor-pointer text-center"
            data-kt-menu-trigger="{default: 'click', lg: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            id="kt_menu_item_wow"
          >
            <svg
              width="35"
              height="35"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.0"
              x="0px"
              y="0px"
              viewBox="0 0 100 125"
              enable-background="new 0 0 100 100"
              xml:space="preserve"
            >
              <path
                fill="#fff"
                d="M90,34.385L50,57.718L10,34.385v38.948C10,76.999,13.001,80,16.667,80h66.666C86.999,80,90,76.999,90,73.333V34.385z"
              />
              <path
                fill="#fff"
                d="M10,26.667L50,50l40-23.333C90,23.001,86.999,20,83.333,20H16.667C13.001,20,10,23.001,10,26.667z"
              />
            </svg>
          </div>
          <div *ngIf="unreadMessageCount > 0" class="message-icon">
            {{ unreadMessageCount }}
          </div>
          <!--begin::Menu-->
          <div
            class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
            data-kt-menu="true"
            id="kt_menu_notifications"
          >
            <!--begin::Heading-->
            <div class="d-flex flex-column bgi-no-repeat rounded-top">
              <!--begin::Title-->
              <div class="msg-header d-flex justify-content-between">
                <h3 class="notification px-9 mt-10 mb-6">Messages</h3>
              </div>

              <!--end::Title-->
              <!--begin::Tabs-->
              <ul class="">
                <div class="">
                  <!--begin::Tab panel-->
                  <div class="">
                    <!--begin::Items-->
                    <div
                      class="scroll-y mh-325px px-8"
                      *ngFor="let message of messages?.slice(0, 5)"
                    >
                      <div
                        class="d-flex flex-stack py-4"
                        (click)="readMessage(message?.chats[0]?.id)"
                      >
                        <!--begin::Section-->
                        <div
                          class="d-flex align-items-center"
                          (click)="setHeaderClass(message?.user)"
                        >
                          <!--begin::Symbol-->
                          <div class="symbol symbol-35px me-4">
                            <img
                              *ngIf="!message?.user?.image_url"
                              src="../../../assets/media/logos/logo-avatar.jpg"
                              class="user-img"
                              alt="user"
                            />
                            <img
                              *ngIf="message?.user?.image_url"
                              src="{{
                                base_url_img + message?.user?.image_url
                              }}"
                              class="user-img"
                              alt="user"
                            />
                          </div>
                          <!--end::Symbol-->
                          <!--begin::Title-->
                          <div class="mb-0 me-2">
                            <div class="msgg">
                              <div class="notif-username fw-bold">
                                {{ message?.user?.full_name }}
                              </div>
                              <div class="notif-body">
                                {{
                                  message?.chats
                                    ? message.chats[0]?.message
                                    : "No message available"
                                }}
                              </div>
                            </div>
                            <div *ngIf="message?.chats?.length > 0">
                              <div class="text-gray-400 fs-7 notif-date">
                                {{
                                  message.chats[0].created_at
                                    | date : "yyyy-MM-dd HH:mm:ss"
                                }}
                              </div>
                            </div>
                          </div>
                          <!--end::Title-->
                        </div>
                        <!--end::Section-->
                      </div>
                    </div>
                    <div class="py-3 text-center border-top">
                      <a class="msg-see" routerLink="/acceuil/inbox/list-msg"
                        >Voir Tout
                      </a>
                    </div>
                    <!--end::View more-->
                  </div>
                </div>
              </ul>
              <!--end::Tabs-->
            </div>
            <!--end::Heading-->
            <!--begin::Tab content-->
          </div>
          <!--end::Menu-->
          <!--end::Menu wrapper-->
        </div>
        <!--end::Activities-->
        <!--begin::Notifications-->
        <div class="app-navbar-item ms-1 ms-md-4">
          <!--begin::Menu- wrapper-->
          <div
            class="cursor-pointer text-center"
            data-kt-menu-trigger="{default: 'click', lg: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            id="kt_menu_item_wow" (click)="toggleDropdown()"
          >
            <svg
              width="35"
              height="35"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 125"
              fill="none"
              x="0px"
              y="0px"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M46 10.5C46 8.01472 48.0147 6 50.5 6C52.9853 6 55 8.01472 55 10.5V15H50.5H46V10.5ZM29.6327 13.9627C22.4133 19.4869 17.75 28.1993 17.75 38H22.25C22.25 29.6597 26.2127 22.2459 32.3673 17.5365L29.6327 13.9627ZM83.25 38C83.25 28.1993 78.5867 19.4869 71.3673 13.9627L68.6327 17.5365C74.7873 22.2459 78.75 29.6597 78.75 38H83.25ZM48 18C36.9543 18 28 26.9543 28 38V49.3475C28 58.9404 22.5801 67.7099 14 72V80H87V72C78.4199 67.7099 73 58.9404 73 49.3475V38C73 26.9543 64.0457 18 53 18H48ZM52 94C58.0751 94 63 89.0751 63 83H38C38 89.0751 42.9249 94 49 94H52Z"
                fill="white"
              />
            </svg>
          </div>
          <div *ngIf="unreadNotificationCount > 0" class="notification-icon">
            {{ unreadNotificationCount }}
          </div>
          <!--begin::Menu-->
       
          <div [ngClass]="{'menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px': true, 'show': showDropdown, 'hide': !showDropdown}"
          data-kt-menu="true"
          id="kt_menu_notifications" #notificationDropdown>
            <!--begin::Heading-->
            <div class="d-flex flex-column bgi-no-repeat rounded-top">
              <!--begin::Title-->
              <div class="notif-header d-flex justify-content-between">
                <h3 class="px-9 mt-10 mb-6">Notifications({{unreadNotificationCount}})</h3>
              </div>

              <!--end::Title-->
              <!--begin::Tabs-->
              <ul class="">
                <div class="all-notif">
                  <!--begin::Tab panel-->
                  <div class="notif">
                    <!--begin::Items-->
                    <div class="one-notif mh-325px px-8" *ngFor="let notification  of notifications?.slice(0,5); let i = index" [ngStyle]="{'background-color': !notification.read ? 'rgba(232, 246, 252, 0.53)' : ''}">
                      <div class="d-flex flex-stack py-3" >
                        <!--begin::Section-->
                        <div class="d-flex align-items-center blockNotification" (click)="notificationRedirection(notification,i)">
                          <!--begin::Symbol-->
                          <!-- <div class="symbol symbol-35px me-4 mt-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="41" viewBox="0 0 24 41"
                              fill="none">
                              <path
                                d="M11.9075 1.86353C9.82766 1.8623 7.79417 2.48401 6.06448 3.64997C4.33478 4.81592 2.98664 6.47369 2.19071 8.41345C1.39478 10.3532 1.18683 12.4877 1.59319 14.5469C1.99956 16.606 3.00196 18.4971 4.47352 19.9808C5.94508 21.4646 7.81964 22.4743 9.85991 22.8821C11.9002 23.2899 14.0144 23.0775 15.9351 22.2717C17.8557 21.466 19.4963 20.1031 20.6493 18.3557C21.8023 16.6082 22.4158 14.5548 22.4122 12.4552C22.404 9.64648 21.2943 6.95534 19.3257 4.97043C17.3571 2.98553 14.6899 1.86844 11.9075 1.86353ZM11.9075 21.8417C10.0727 21.8429 8.27859 21.295 6.75209 20.2672C5.2256 19.2395 4.03519 17.7779 3.33133 16.0674C2.62747 14.3568 2.44174 12.474 2.79762 10.6569C3.1535 8.83981 4.03502 7.17001 5.33076 5.85853C6.6265 4.54705 8.2783 3.65276 10.0774 3.28869C11.8765 2.92462 13.7421 3.10712 15.4385 3.81311C17.1349 4.5191 18.5858 5.7169 19.608 7.25512C20.6302 8.79334 21.1777 10.6029 21.1814 12.4552C21.1781 14.9376 20.2002 17.3175 18.4619 19.0735C16.7236 20.8294 14.3667 21.8181 11.9075 21.8231V21.8417Z"
                                fill="#4AD257" />
                              <path
                                d="M15.631 7.77757C15.5157 7.66187 15.3597 7.59692 15.1972 7.59692C15.0346 7.59692 14.8786 7.66187 14.7633 7.77757L11.9818 10.5606C11.9244 10.6185 11.8789 10.6873 11.8478 10.763C11.8168 10.8386 11.8008 10.9197 11.8008 11.0017C11.8008 11.0836 11.8168 11.1647 11.8478 11.2404C11.8789 11.316 11.9244 11.3848 11.9818 11.4427C12.0392 11.5006 12.1073 11.5466 12.1822 11.5779C12.2572 11.6093 12.3376 11.6254 12.4187 11.6254C12.4998 11.6254 12.5802 11.6093 12.6552 11.5779C12.7301 11.5466 12.7982 11.5006 12.8556 11.4427L15.2002 9.0759L16.3325 10.2189L10.9049 15.7167L9.46485 14.2879C9.43498 14.2332 9.3977 14.183 9.35408 14.1388L7.55716 12.3L8.68947 11.1818L10.4618 12.9709C10.519 13.0291 10.587 13.0753 10.662 13.1069C10.737 13.1384 10.8175 13.1547 10.8987 13.1547C10.9799 13.1547 11.0604 13.1384 11.1354 13.1069C11.2103 13.0753 11.2784 13.0291 11.3356 12.9709C11.4502 12.8545 11.5146 12.6971 11.5146 12.5329C11.5146 12.3688 11.4502 12.2114 11.3356 12.095L9.10177 9.83999C8.98647 9.72429 8.8305 9.65935 8.66793 9.65935C8.50535 9.65935 8.34938 9.72429 8.23408 9.83999L6.22793 11.8589C6.17025 11.9167 6.12447 11.9854 6.09323 12.0611C6.06198 12.1368 6.0459 12.218 6.0459 12.3C6.0459 12.382 6.06198 12.4632 6.09323 12.5389C6.12447 12.6146 6.17025 12.6833 6.22793 12.7411L8.33254 14.8656C8.36547 14.9182 8.40251 14.9681 8.44331 15.0147L10.4618 17.0336C10.5193 17.0912 10.5875 17.1368 10.6624 17.1677C10.7374 17.1986 10.8177 17.2143 10.8987 17.2138C10.9797 17.2143 11.06 17.1986 11.1349 17.1677C11.2099 17.1368 11.2781 17.0912 11.3356 17.0336L17.6372 10.6786C17.6948 10.6209 17.7406 10.5522 17.7719 10.4765C17.8031 10.4008 17.8192 10.3196 17.8192 10.2376C17.8192 10.1556 17.8031 10.0744 17.7719 9.99866C17.7406 9.92296 17.6948 9.85426 17.6372 9.79651L15.631 7.77757Z"
                                fill="#4AD257" />
                            </svg>
                          </div> -->
                          <div class="symbol notif-img me-4">
                            <img
                              *ngIf="!notification?.sender?.image_url"
                              src="/assets/media/logos/logo-avatar.jpg"
                              class="user-img"
                              alt="user"
                            />
                            <img
                              *ngIf="notification?.sender?.image_url"
                              src="{{
                                url +
                                  '/User/file/gallery_users/' +
                                  notification?.sender?.image_url
                              }}"
                              class="user-img"
                              alt="user"
                            />
                          </div>
                          <!--end::Symbol-->
                          <!--begin::Title-->
                          <div class="mb-0 me-2">
                            <div class="name-notif">
                              <div class="notif-username fw-bold">
                                {{ notification?.sender?.last_name }}
                                {{ notification?.sender?.first_name }}
                              </div>
                              <div class="notif-body">
                                {{ notification?.message }}
                              </div>
                            </div>
                            <div class="text-gray-400 notif-date"><i class="fa-regular fa-clock"></i> {{notification?.created_at | date : 'dd/MM/yyyy  HH:mm'}}</div>

                          </div>
                          <!--end::Title-->
                        </div>
                        <!--end::Section-->
                      </div>
                    </div>
                    <div
                      *ngIf="!notifications?.length"
                      class="py-3 text-center border-top"
                    >
                      <a class="notif-see" routerLink="/acceuil/notifications"
                        >liste de notifications est actuellement vide
                      </a>
                    </div>
                    <div *ngIf="notifications?.length" class="py-3 text-center border-top" (click)="viewAllNotifications()">
                      <a class="notif-see" data-bs-dismiss="modal" aria-label="Close" (click)="toggleDropdown()">Voir Tout
                      </a>
                    </div>
                    <!--end::View more-->
                  </div>
                </div>
              </ul>
              <!--end::Tabs-->
            </div>
            <!--end::Heading-->
            <!--begin::Tab content-->
          </div>
          <!--end::Menu-->
          <!--end::Menu wrapper-->
        </div>
        <div class="d-column info-user">
          <div class="name">
            <span> {{first_name | titlecase}} {{last_name | titlecase}}</span>
          </div>
          <div class="role">
            <span>
              {{
                roleuser == "2"
                  ? "Admin"
                  : roleuser == "1"
                  ? "Super Admin"
                  : "---"
              }}</span
            >
          </div>
        </div>
        <!--end::Notifications-->
        <!--begin::Chat-->

        <!--end::Chat-->
        <!--begin::My apps links-->

        <!--end::My apps links-->
        <!--begin::User menu-->
        <div
          class="app-navbar-item ms-1 ms-md-4"
          id="kt_header_user_menu_toggle"
        >
          <div class="d-flex align-items-center">
            <div class="symbol symbol-35px image-profil mr-2">
              <img
                *ngIf="image_url"
                src="{{ url + '/User/file/gallery_users/' + image_url }}"
                alt=""
                width="200px"
                class="user-img"
              />
              <img
                *ngIf="!image_url || image_url === ''"
                src="/assets/media/logos/logo-avatar.jpg"
                alt=""
                width="200px"
                class="user-img"
              />
            </div>
            <div
              class="cursor-pointer symbol symbol-35px text-center"
              data-kt-menu-trigger="{default: 'click', lg: 'click'}"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
            >
              <svg
                class="for-web"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
              >
                <path
                  d="M1 1L6 6L11 1"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
              <svg
                class="for-mobile"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="35"
                height="35"
                viewBox="0,0,256,256"
                style="fill: #000000"
              >
                <g
                  fill="#ffffff"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g transform="scale(4,4)">
                    <path
                      d="M7,29v5l50,1v-7zM7,11v6h50v-6zM7,46v7l50,-1v-5z"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
              data-kt-menu="true"
            >
              <!--end::Menu separator-->
              <!--begin::Menu item-->
              <div class="menu-item px-5 d-flex profile-drop-menu">
                <div class="mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M12.8352 7.4285C12.7644 7.42951 12.6988 7.45765 12.65 7.50616C12.3338 7.82076 12.0181 8.13587 11.702 8.45057L13.9741 10.714C14.29 10.3992 14.6061 10.0845 14.922 9.76967C15.0256 9.66565 15.0256 9.49791 14.922 9.3939L13.0272 7.50616C12.9758 7.45523 12.9059 7.42729 12.8352 7.4285ZM11.3232 8.82788C10.0604 10.0855 8.79763 11.3432 7.53505 12.6012C7.4845 12.6513 7.45605 12.7194 7.45605 12.7904V14.6776C7.45661 14.8242 7.57575 14.9429 7.72286 14.9434H9.61722C9.68821 14.944 9.75652 14.9164 9.80712 14.8668C11.0702 13.6085 12.3326 12.3497 13.5953 11.0913L11.3232 8.82788Z"
                      fill="#BAB8B8"
                    />
                    <path
                      d="M5.62513 0C4.0009 0 2.67871 1.31721 2.67871 2.93531C2.67871 4.55341 4.0009 5.8706 5.62513 5.8706C7.24937 5.8706 8.57156 4.55341 8.57156 2.93531C8.57156 1.31721 7.24937 0 5.62513 0Z"
                      fill="#BAB8B8"
                    />
                    <path
                      d="M4.55356 6.4043C2.0357 6.4043 2.18074e-06 8.4317 2.18074e-06 10.9396V13.0744C-0.000139764 13.1094 0.00664899 13.1442 0.0199809 13.1766C0.0333129 13.209 0.0529268 13.2385 0.0777027 13.2634C0.102479 13.2883 0.131931 13.308 0.164379 13.3215C0.196826 13.3351 0.231633 13.3421 0.266811 13.3423H6.92032V12.7893C6.92032 12.5771 7.00627 12.3745 7.15731 12.2243C8.32817 11.0577 9.49862 9.89077 10.6693 8.72407C9.89035 7.33981 8.40585 6.4043 6.69693 6.4043H4.55356Z"
                      fill="#BAB8B8"
                    />
                  </svg>
                </div>
                <div class="mt-2   ">
                  <a routerLink="/acceuil/profil" class="profile-drop-menu px-5"
                    >Modifier le Profil</a
                  >
                </div>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu item-->
              <div class="menu-item px-5 d-flex profile-drop-menu">
                <div class="mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M11.6815 8.43177V5.87578C11.6815 4.8069 10.8008 3.92392 9.73462 3.92392H8.76116V3.04094C8.80752 1.55381 7.69499 0.252578 6.21162 0.0202146C4.63553 -0.165676 3.15216 0.949669 2.96674 2.57621C2.96674 2.71563 2.96674 2.80858 2.96674 2.94799V3.92392H1.94692C0.880752 3.92392 0 4.8069 0 5.87578V10.7554C0 11.8243 0.880752 12.7073 1.94692 12.7073H7.46321C7.69499 10.4301 9.45649 8.66414 11.6815 8.43177ZM8.25125 7.78116C8.52938 7.78116 8.71481 8.01352 8.66845 8.24588C8.6221 8.47825 8.43667 8.71061 8.2049 8.66414C7.97312 8.66414 7.7877 8.43177 7.7877 8.19941C7.74134 8.01352 7.97312 7.78116 8.25125 7.78116ZM3.38394 8.75708C3.10581 8.75708 2.87403 8.52472 2.87403 8.29236C2.87403 8.05999 3.10581 7.78116 3.33759 7.78116C3.56936 7.78116 3.84749 8.01352 3.84749 8.24588C3.84749 8.52472 3.66207 8.71061 3.4303 8.71061C3.4303 8.75708 3.38394 8.75708 3.38394 8.75708ZM3.89385 2.90152C3.89385 1.83265 4.7746 0.949669 5.84077 0.949669C5.97984 0.949669 6.11891 0.949668 6.25797 0.996141C7.18508 1.2285 7.83405 2.01854 7.7877 2.99447V3.92392H3.89385V2.90152ZM5.79442 8.75708C5.51629 8.75708 5.28451 8.52472 5.28451 8.29236C5.28451 8.05999 5.51629 7.78116 5.74806 7.78116C5.97984 7.78116 6.25797 8.01352 6.25797 8.24588C6.25797 8.52472 6.07255 8.71061 5.84077 8.75708C5.84077 8.75708 5.84077 8.75708 5.79442 8.75708Z"
                      fill="#BAB8B8"
                    />
                    <path
                      d="M16.1779 10.9877L15.4362 11.2665C14.3701 9.45408 12.0523 8.84993 10.2444 9.91881C8.43658 10.9877 7.83396 13.3113 8.90014 15.1237C9.96631 16.9362 12.2841 17.5403 14.0919 16.4715C14.6482 16.1461 15.1118 15.6814 15.4362 15.1237C15.529 14.8914 15.3899 14.6125 15.1118 14.5196C14.9263 14.4731 14.6946 14.5196 14.5555 14.659C13.7674 16.0067 12.0059 16.4715 10.6616 15.6814C9.31734 14.8914 8.85378 13.1254 9.64182 11.7777C10.4299 10.43 12.145 9.96528 13.4893 10.7553C13.8602 10.9877 14.1846 11.2665 14.4164 11.6383L13.7211 11.8707C13.5357 11.9636 13.443 12.1495 13.4893 12.3354C13.5357 12.4283 13.582 12.5213 13.6747 12.5677L15.2972 13.3578C15.4826 13.4507 15.7144 13.3578 15.8071 13.1254L16.5488 11.4989C16.6415 11.313 16.5951 11.0806 16.4097 10.9877C16.4097 10.9412 16.2706 10.9412 16.1779 10.9877Z"
                      fill="#BAB8B8"
                    />
                  </svg>
                </div>
                <div class="mt-2">
                  <a
                    [routerLink]="['/acceuil/candidats/change-mot-de-passe']"
                    class="profile-drop-menu px-5"
                    >Changer mot de passe</a
                  >
                </div>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu item-->
              <div class="menu-item px-5 d-flex">
                <div class="mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.49467 5.25H4.5C4.08578 5.25 3.75 5.58577 3.75 6C3.75 6.41423 4.08578 6.75 4.5 6.75H7.49467V11.25C7.49467 11.6642 7.1589 12 6.74467 12H0.75C0.335782 12 0 11.6642 0 11.25V0.75C0 0.33579 0.335782 0 0.75 0H6.74467C7.1589 0 7.49467 0.33579 7.49467 0.75V5.25ZM7.5 5.25H12.1307L10.1924 3.31028C9.89985 3.01754 9.89985 2.54312 10.1924 2.25038C10.4852 1.95734 10.9602 1.95734 11.2531 2.25038L15 6L11.2531 9.74963C10.9602 10.0427 10.4852 10.0427 10.1924 9.74963C9.89985 9.4569 9.89985 8.98245 10.1924 8.68972L12.1307 6.75H7.5V5.25Z"
                      fill="#BAB8B8"
                    />
                  </svg>
                </div>
                <div class="mt-2">
                  <a (click)="logOut()" class="profile-drop-menu px-5 logout"
                    >Se Déconnecter</a
                  >
                </div>
              </div>
              <!--end::Menu item-->
            </div>
          </div>

          <!--begin::User account menu-->

          <!--end::User account menu-->
          <!--end::Menu wrapper-->
        </div>
        <!--end::User menu-->
        <!--begin::Header menu toggle-->

        <!--end::Header menu toggle-->
        <!--begin::Aside toggle-->
        <!--end::Header menu toggle-->
      </div>

      <!--end::Navbar-->
    </div>
    <!--end::Header wrapper-->
  </div>
  <!--end::Header container-->
</div>
