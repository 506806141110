import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, sortBy: string): any[] {
    if (!items) return [];

    // Filter items
    if (searchText) {
      searchText = searchText.toLowerCase();
      items = items.filter(item => !item.isFiltered && item.name.toLowerCase().includes(searchText));
    }

    // Sort items by priority: startsWith first, then by proximity, then by sortBy property
    items = items.sort((a, b) => {
      const aLower = a.name.toLowerCase();
      const bLower = b.name.toLowerCase();
      const aStartsWith = aLower.startsWith(searchText);
      const bStartsWith = bLower.startsWith(searchText);

      // Prioritize items that start with searchText
      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;

      // If both start with searchText or both do not start with searchText, sort by proximity
      const aIndex = aLower.indexOf(searchText);
      const bIndex = bLower.indexOf(searchText);
      if (aIndex !== bIndex) return aIndex - bIndex;

      // If proximity is the same, sort by the specified property
      if (sortBy) {
        if (a[sortBy] < b[sortBy]) return -1;
        if (a[sortBy] > b[sortBy]) return 1;
      }

      return 0;
    });

    return items;
  }
}
