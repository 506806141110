import { AfterViewInit, Component, ElementRef, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, delay, takeUntil } from 'rxjs';
import {
  activatedOffer,
  addedOffer,
  confirmDelete,
  deletedOffer,
  existedOffer,
  failedDelete,
  inactivatedOffer,
  serverError,
  updatedOffer,
} from '../../../../core/models/messages';
import { IOffer } from '../../../../core/models/setting';
import { SettingsService } from '../../../../core/services/settings/settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
})
export class OffreComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scrollTable') scrollTable!: ElementRef;

  /* string */
  mode: string = 'edit';
  searchElement: string = '';
  /* numbers */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  idOffer!: number | undefined;
  pagination: boolean = false;
  isLoadSpinner: boolean = true;
  

  /* array */
  permissions: { id: number; name: string }[] = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'SuperAdmin' },
    { id: 3, name: 'ESN' },
    { id: 4, name: 'Client final' },
  ];
  trialPeriod: { id: number; name: string }[] = [
    { id: 1, name: '10 jours' },
    { id: 2, name: '30 jours' },
    { id: 3, name: '40 jours' },
  ];
  bankAccounts!: { id: number; name: string }[];
  companyType = [
    { id: '1', name: 'Candidat' },
    { id: '2', name: 'ESN' },
    { id: '3', name: 'Client final' },
  ];
  listOffer: IOffer[] = [];
  sortAttr: string = '';
  /* modal */
  modalRef?: BsModalRef;
  /* formGroup */
  formOffer: FormGroup = this.createFormOffer();
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private settingService: SettingsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private elRef: ElementRef
  ) { }

  ngAfterViewInit() {
    this.scrollTable.nativeElement.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
    window.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
  ngOnInit() {
  
    this.getListOffer();
    window.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
    this.scrollTable.nativeElement.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
    
  }

  closeDropdownOnScroll(event: Event): void {
    console.log('Scroll event triggered on:'); // Debug log
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');
    if (dropdown) {
      dropdown.classList.remove('show');
      console.log('Dropdown closed'); // Debug log
    }
  }
  

  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') this.applyFilter();
      else if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getListOffer();
      }
    }
  }
  /* get list offer */
  getListOffer() {
    this.spinner.show();
    let dataPyload = this.sortAttr == '' ? { per_page: this.itemsPerPage, page: this.currentPage } : { ...{ per_page: this.itemsPerPage, page: this.currentPage }, ...{ sort: this.currentSortDirection, sortAttribute: this.sortAttr } }
    this.settingService
      .getListOffer(dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isLoadSpinner = false;
          this.listOffer = res.data.items.data;
          this.totalItems = res.data.items.total;
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /* open modal */
  openModal(template: TemplateRef<any>, mode: string, data?: IOffer) {
    this.mode = mode;
    if (mode != 'create') {
      this.idOffer = data?.id;
      this.formOffer = this.createFormOffer(data);
      for (const iterator of this.permissions) {
        if (iterator.id == data?.permission)
          this.formOffer.get('permission')?.setValue(iterator.id);
      }
      for (const iterator of this.trialPeriod) {
        if (iterator.id == data?.trial_period)
          this.formOffer.get('trial_period')?.setValue(iterator.id);
      }
    }
    if (mode == 'detail') {
      this.formOffer
        .get('annual_price')
        ?.setValue(this.formOffer.value.annual_price + ' €');
      this.formOffer
        .get('monthly_price')
        ?.setValue(this.formOffer.value.monthly_price + ' €');
      this.formOffer.disable();
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  /* close modal */
  closeModal() {
    this.modalRef?.hide();
  }
  /* create offer form */
  createFormOffer(data?: IOffer) {
    return this.formBuilder.group({
      name: [data ? data.name : '', [Validators.required]],
      start_date: [data ? data.start_date : '', [Validators.required]],
      end_date: [data ? data.end_date : '', [Validators.required]],
      trial_period: [data ? data.trial_period : null, [Validators.required]],
      price: [data ? data.price : '', [Validators.required]],
      dedicated_to: [data ? data.dedicated_to : null, [Validators.required]],
      nbr_intervenant: [
        data ? data.nbr_intervenant : '',
        [Validators.required],
      ],
      nbr_consultant: [data ? data.nbr_consultant : '', [Validators.required]],
      permission: [data ? data.permission : null, [Validators.required]],
      bank_account_id: [
        data ? data.bank_account_id : null,
        [Validators.required],
      ],
      nbr_cv_to_upload: [
        data ? data.nbr_cv_to_upload : '',
        [Validators.required],
      ],
      nbr_profiles_to_export: [
        data ? data.nbr_profiles_to_export : '',
        [Validators.required],
      ],
      nbr_job_offers: [data ? data.nbr_job_offers : '', [Validators.required]],
      annual_price: [data ? data.annual_price : '', [Validators.required]],
      monthly_price: [data ? data.monthly_price : '', [Validators.required]],
    });
  }
  onStartDateChange(): void {
    const startDate = this.formOffer.get('start_date')?.value;
    const endDate = this.formOffer.get('end_date')?.value;
    this.checkDateValidity(startDate, endDate);
  }

  onEndDateChange(): void {
    const startDate = this.formOffer.get('start_date')?.value;
    const endDate = this.formOffer.get('end_date')?.value;
    this.checkDateValidity(startDate, endDate);
  }
  checkDateValidity(startDate: Date, endDate: Date): void {
    if (startDate && endDate && startDate > endDate) {
      this.formOffer.get('start_date')?.setErrors({ invalidDateRange: true });
    } else {
      this.formOffer.get('start_date')?.setErrors(null);
    }
  }
  /* send data either to add or to update */
  submitData() {
    if (this.mode == 'create') this.addOffer();
    else this.updateOffer();
  }
  /* add offer */
  addOffer() {
    if (this.formOffer.valid) {
      this.formOffer
        .get('start_date')
        ?.setValue(
          this.formOffer.value.start_date
            ? moment(this.formOffer.value.start_date).format('YYYY-MM-DD')
            : ''
        );
      this.formOffer
        .get('end_date')
        ?.setValue(
          this.formOffer.value.end_date
            ? moment(this.formOffer.value.end_date).format('YYYY-MM-DD')
            : ''
        );
      this.spinner.show();
      this.settingService
        .addOffer(this.formOffer.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(addedOffer);
              this.getListOffer();
              this.formOffer.reset();
            }
          },
          error: (err) => {
            if (err.error) {
              if (err.error?.message) {
                if (err.error.message['name']) this.toastr.info(existedOffer);
                else this.toastr.error(serverError);
              }
            }
            this.spinner.hide();
          },
        });
    }
  }
  /* update offer */
  updateOffer() {
    if (this.formOffer.valid) {
      if (typeof this.formOffer.value.trial_period == 'object')
        this.formOffer.value.trial_period =
          this.formOffer.value.trial_period.id;
      if (typeof this.formOffer.value.permission == 'object')
        this.formOffer.value.permission = this.formOffer.value.permission.id;
      this.formOffer
        .get('start_date')
        ?.setValue(
          this.formOffer.value.start_date
            ? moment(this.formOffer.value.start_date).format('YYYY-MM-DD')
            : ''
        );
      this.formOffer
        .get('end_date')
        ?.setValue(
          this.formOffer.value.end_date
            ? moment(this.formOffer.value.end_date).format('YYYY-MM-DD')
            : ''
        );
      this.spinner.show();
      this.settingService
        .updateOffer(this.idOffer, this.formOffer.value)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.closeModal();
              this.spinner.hide();
              this.toastr.success(updatedOffer);
              this.getListOffer();
              this.formOffer.reset();
            }
          },
          error: (err) => {
            if (err.error) {
              if (err.error?.message) {
                if (err.error.message['name']) this.toastr.info(existedOffer);
                else this.toastr.error(serverError);
              }
            }
            this.spinner.hide();
          },
        });
    }
  }
  /* open delete alert */
  openDeleteAlert(offer: IOffer) {
    Swal.fire({
      title: `${confirmDelete} l'offre ${offer.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteOffer(offer.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  /* delete offer */
  deleteOffer(id: number) {
    this.spinner.show();
    this.settingService
      .deleteOffer(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.getListOffer();
            this.spinner.hide();
            this.toastr.success(deletedOffer);
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  // changeStatutOffer(event:any,id: number) {
  //   console.log('event',event.target.checked);

  //   this.spinner.show()
  //   this.settingService.activeDesactiveOffer(id).pipe(takeUntil(this.unsubscribeAll)).subscribe({
  //     next: (res) => {
  //       this.spinner.hide()
  //       this.toastr.success(event.target.checked?activatedOffer:inactivatedOffer)
  //     },
  //     error: () => {
  //       this.spinner.hide()
  //       this.toastr.error(serverError)
  //     }
  //   })
  // }
  preventClick(event: MouseEvent): void {
    event.preventDefault();
  }
  changeStatutOffer(id: number, state: any) {
    console.log("state", state)
    let title: any
    let title2: any
    // console.log('event', event.target.checked);
    if (state === 0) {
      title = "Voulez-vous vraiment désactiver cet abonnement?"
      title2 = 'Oui, désactivé !'
    } else {
      title = "Voulez-vous vraiment activer cet abonnement?"
      title2 = 'Oui, activé !'
    }

    Swal.fire({
      title: title,
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: title2,
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.settingService
          .activeDesactiveOffer(id)
          .pipe(
            takeUntil(this.unsubscribeAll),
            delay(1000) // Choisissez un délai approprié en millisecondes
          )
          .subscribe({
            next: (res) => {
              this.spinner.hide();

              const newStatus = res.status;

              if (res.data.state === '0') {
                this.toastr.info(activatedOffer);
              } else {
                this.toastr.info(inactivatedOffer);
              }
              this.getListOffer()
            },
            error: () => {
              this.spinner.hide();
              this.toastr.error(serverError);
            },
          });
      }
    });
  }
  /* filter data on key up input */
  clickCount: number = 0;
  applyFilter() {
    if (this.searchElement != '' || this.clickCount) {
      this.clickCount++;
      if (this.clickCount == 1) this.currentPage = 1;
      let data = {
        // sort: this.currentSortDirection,
        // sortAttribute: 'name',
        search: [this.searchElement.trim().toLowerCase()],
        page: this.currentPage,
        per_page: this.itemsPerPage,
      };
      this.spinner.show();
      this.settingService
        .searchOffre(data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (!res.data.data.length && this.currentPage > 1) {
              this.currentPage = 1;
              this.applyFilter();
            } else {
              this.listOffer = res.data.data.slice(0, this.endIndex);
              this.totalItems = res.data.total;
            }
            this.spinner.hide();
          },
          error: () => {
            this.spinner.hide();
            this.toastr.error(serverError);
          },
        });
    }
  }
  /* key inter number */
  keyPress(event: any) {
    let pattern = /^[\d]$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    return pattern;
  }
  navigateToAddOffer(mode: string, id?: number) {
    let data = mode == 'create' ? { mode: mode } : { mode: mode, id: id };
    this.router.navigate(['/acceuil/parametres/ajout-abonnement', data]);
  }
  navigateToListCandudature(data: any) {
    this.router.navigate([
      '/acceuil/parametres/abonnements',
      { id: data.id, abonnement: data.name },
    ]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.scrollTable.nativeElement.removeEventListener('scroll', this.closeDropdownOnScroll.bind(this));
    window.removeEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  
  }
}
