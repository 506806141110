import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, forkJoin, Observable, of, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { logoutMessage } from '../../core/models/messages';
import { LocalStorageService } from '../../core/services/Local-storage/local-storage.service';
import { AuthService } from '../../core/services/authentication/auth.service';
import { CandidatService } from '../../core/services/candidat/candidat.service';
import { ChatService } from '../../core/services/chat/chat.service';
import { PusherService } from '../../core/services/chat/pusher.service';
import { NotificationService } from '../../core/services/notification/notification.service';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { filter } from 'jszip';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  roleuser: any;
  currentUser: any;
  profil_id: any;
  profil_image: any;
  last_name: any;
  first_name: any;
  selectedUser: any;
  email: any;
  city: any;
  phone: any;
  url: string = environment.baseUrl + '/api';
  messages: any;
  notifications: any;
  unreadNotificationCount: number = 0;
  unreadMessageCount: number = 0;
  elapsedTime: any
  id:any
  role_user_id:any
  image_url:any
  itemsPerPage: number = 5
  currentPage: number = 1
  startIndex: number = 0
  endIndex: number = 5
  totalItems:any;
  pagination : boolean =false;
  showDropdown = false;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  base_url_img = environment.baseUrlGalery;
  notificationsSubscription!: Subscription;
  messagesSubscription!: Subscription;
  private routerEventsSubscription!: Subscription;
  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService,
    private chatService: ChatService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private pusherService: PusherService,
    private changeDetectorRef: ChangeDetectorRef,
    private candidatService: CandidatService,
    private router: Router
  ) {
    this.currentUser = this.localStorageService.getData('AdminInfo');

    if (this.currentUser) {
      try {

        this.id = JSON.parse(this.currentUser).id;

      } catch (error) {}
    } else {
    }
  }

  ngOnInit(): void {
    this.subscribeToProfileUpdates();
  
    this.notificationsSubscription =
      this.notificationService.messages$.subscribe((notification) => {
        console.log('notification', notification);
        this.unreadNotificationCount++;
        this.notifications = this.notifications?.concat(notification).reverse();
        this.getNotification()
        console.log('notifications2', this.notifications);
        this.changeDetectorRef.detectChanges();
      });


    this.messagesSubscription = this.pusherService.messages$.subscribe(
      (message) => {
        if (message?.user_id === this.id) {
          this.unreadMessageCount++;
        }

        this.messages = this.messages?.concat(message);
        this.changeDetectorRef.detectChanges();
      }
    );
    this.getNotification();
    this.getMessage();
    this.getUser();
  }

  subscribeToProfileUpdates() {
    this.candidatService.profileUpdated.subscribe(() => {
      this.getUser();
    });
  }
  // getItems(event?: any) {
  //   if (event) {
  //     this.startIndex = event.startIndex
  //     this.endIndex = event.endIndex
  //     this.itemsPerPage = event.itemsPerPage,
  //       this.currentPage = event.currentPage
  //        if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
  //         this.pagination = true
  //       this.getNotification()
  //       }
  //   }
  // }
  getNotification() {
    this.notificationService.getListOfNotification(this.itemsPerPage, this.currentPage).subscribe(
      {
        next: (res) => {
          this.notifications = res.data.data.data;
          console.log('user notification ********** ', this.notifications);

          this.unreadNotificationCount = this.notifications.filter((notification: any) => notification.read === 0).length;
        },
        error: (e) => {
          console.log('get notification error ===== ', e);
        }
      }
    );
  }

  getUser() {
    this.spinner.show()
    this.candidatService.getById(this.id).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res:any) => {
        this.spinner.hide()
        this.currentUser=res.body?.data
        this.first_name=res.body?.data?.first_name
        this.last_name=res.body?.data?.last_name
        this.email=res.body?.data?.email
        this.phone=res.body?.data?.phone_number
        this.city=res.body?.data?.city
        this.image_url=res.body?.data?.image_url
  this.roleuser=res.body?.data?.role_user_id[0]
  console.log("role user",this.roleuser)
  this.spinner.hide()
      },
      error: () => {
        this.spinner.hide()
      },
    })
  }

  readNotification(id: any, index: number): Observable<any> {
    this.spinner.show();
    const data = { id };
  
    return this.notificationService.readNotification(data).pipe(
      catchError(err => {
        console.error('Failed to mark notification as read', err);
        return of(null); // Return an empty observable on error
      }),
      finalize(() => {
        this.spinner.hide();
        // Update the notification count and refresh notifications
        this.unreadNotificationCount = this.notifications.filter((notification: any) => !notification.read).length;
      }),
      tap(() => {
        // Mark the notification as read locally
        if (index >= 0 && this.notifications[index]) {
          this.notifications[index].read = true;
        }
      })
    );
  }
  notificationRedirection(notification: any, index: number) {
    // Marquer la notification comme lue
    this.readNotification(notification.id, index).subscribe(() => {
      this.closeDropdown(); // Fermer le dropdown après avoir marqué la notification comme lue
  
      const targetUrl = this.getTargetUrl(notification);
  
      // Vérifie si l'utilisateur est déjà sur la page cible
      if (this.router.url !== targetUrl) {
        // Si ce n'est pas la même URL, navigue vers la nouvelle URL
        this.router.navigate([targetUrl]).then(() => {
          this.closeDropdown(); // Fermer le dropdown après la navigation
        });
      } else {
        // Si c'est la même URL, juste fermer le dropdown
        this.closeDropdown(); 
        // Optionnel : actualiser la page si nécessaire pour refléter les changements
        this.router.navigate([targetUrl]).then(() => window.location.reload());
      }
    });
  }
  
  
  // Méthode pour déterminer l'URL cible en fonction de l'état de la notification
  getTargetUrl(notification: any): string {
    if (notification.state == '4' || notification.state == '5' || notification.state == '6') {
      return '/acceuil/general';
    } else if (notification.state == '16') {
      return '/acceuil/candidats/list-candidat';
    } else {
      return '/acceuil/notifications';
    }
  }
  
  


  startConversation(user: any) {
    this.router.navigate(['/acceuil/inbox/list-msg', { user: user }]);
  }
  navigateToNotification(){
    this.router.navigate(['/acceuil/notifications']);
   this.unreadNotificationCount = 0
  } 
  setHeaderClass(className: any): void {
    this.chatService.setHeaderClass(className);
    this.router.navigate(['/acceuil/inbox/list-msg', { user: className.id }]);
  }
  getMessage() {
    this.chatService.getListMessage().subscribe({
      next: (res) => {
        this.messages = res.data;

        this.unreadMessageCount = this.messages?.reduce(
          (count: any, user: any) => {
            return (
              count +
              user?.chats?.filter((message: any) => message.read === 0).length
            );
          },
          0
        );

        // Assuming that the created_at property is in the first chat entry
        this.elapsedTime = this.formatElapsedTime(
          this.messages[0]?.chats[0]?.created_at
        );
      },
      error: (e) => {
        console.log('get messages error ===== ', e);
      },
    });
  }
  readMessage(id: any) {
    this.spinner.show();
    let data = { id: id };
    this.chatService
      .readMessage(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
        },
        error: (err: any) => {
          this.spinner.hide();
        },
      });
  }
  formatElapsedTime(created_at: string): string {
    const currentTime = moment();
    const messageTime = moment(created_at);
    const duration = moment.duration(currentTime.diff(messageTime));

    if (duration.asMinutes() <= 59) {
      const minutes = Math.floor(duration.asMinutes());
      if (minutes < 1) {
        return "à l'instant";
      } else if (minutes === 1) {
        return '1 minute';
      } else {
        return `${minutes} minutes`;
      }
    } else if (duration.asHours() <= 23) {
      const hours = Math.floor(duration.asHours());
      if (hours === 1) {
        return '1 heure';
      } else {
        return `${hours} heures`;
      }
    } else if (duration.asDays() <= 6) {
      const days = Math.floor(duration.asDays());
      if (days === 1) {
        return '1 jour';
      } else {
        return `${days} jours`;
      }
    } else {
      const weeks = Math.floor(duration.asDays() / 7);
      if (weeks === 1) {
        return '1 semaine';
      } else {
        return `${weeks} semaines`;
      }
    }
  }

  logOut() {
    Swal.fire({
      title: `${logoutMessage}`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Se déconnecter',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.authService.logOut();
      } else if (result.isDismissed) {
        // Swal.fire(failedDelete, '', 'error')
      }
    });
  }
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const isDropdown = target.closest('#kt_menu_notifications');
    const isMenuTrigger = target.closest('#kt_menu_item_wow');

    if (!isDropdown && !isMenuTrigger) {
      this.closeDropdown();
    }
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  closeDropdown(): void {
    this.showDropdown = false;
  }
  viewAllNotifications(): void {
    // Create a list of observables to mark notifications as read
    const readRequests = this.notifications
      .filter((notification: any) => !notification.read)
      .map((notification: any) => this.readNotification(notification.id, this.notifications.indexOf(notification)));
  
    // Handle the case when there are no unread notifications
    if (readRequests.length === 0) {
      this.closeDropdown();
      this.router.navigate(['/acceuil/notifications']).then(() => {
        this.unreadNotificationCount = 0;
      });
      return;

    }
  
    if (this.router.url === '/acceuil/notifications') {
      // If already on the notifications page, reload to reflect the read status
      forkJoin(readRequests).pipe(
        finalize(() => {
          this.closeDropdown();
          window.location.reload(); // Reload the page to show updated notifications
        })
      ).subscribe();
    } else {
      // Navigate to the notifications page and update the read status
      forkJoin(readRequests).pipe(
        finalize(() => {
          this.closeDropdown();
          this.router.navigate(['/acceuil/notifications']).then(() => {
            this.unreadNotificationCount = 0;
          });
        })
      ).subscribe();
    }
  }
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
