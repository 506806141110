import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './components/dashboard.component';
import { DashboardCandidatComponent } from './components/dashboard-candidat/dashboard-candidat.component';
import { DashboardEsnComponent } from './components/dashboard-esn/dashboard-esn.component';
import { DashboardClientFinalComponent } from './components/dashboard-client-final/dashboard-client-final.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GeneralComponent } from './components/general/general.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({
  declarations: [DashboardComponent, DashboardCandidatComponent, DashboardEsnComponent, DashboardClientFinalComponent,GeneralComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardRoutingModule,
    NgApexchartsModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
  ]
})
export class DashboardModule { }
