export interface IPerson{
  first_name?:string ;
  last_name ?:string ;
  phone_number  ?:string ;
  username?:string ;
  password?:string ;
}
export class CLogin implements IPerson {
  username: string | undefined;
  password: string | undefined;
  constructor(model?: CLogin) {
      this.username = model && model.username || "";
      this.password = model && model.password || "";
  }
}
export class CPassoword{
  newPassword: string | undefined;
  email: string | undefined;
  confirmPassword: string | undefined;
  constructor(model?: CPassoword) {
      this.newPassword = model && model.newPassword || "";
      this.email = model && model.email || "";
      this.confirmPassword = model && model.confirmPassword || "";
  }
}
export class CChangePassoword{
  newPassword: string | undefined;
  email: string | undefined;
  confirmNewPassword: string | undefined;
  oldPassword:string|undefined
  constructor(model?: CChangePassoword) {
      this.newPassword = model && model.newPassword || "";
      this.email = model && model.email || "";
      this.oldPassword = model && model.oldPassword || "";
      this.confirmNewPassword = model && model.confirmNewPassword || "";
  }
}