import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxTranslateModule } from './shared/translate/translate.module';
import { TranslateModule } from '@ngx-translate/core';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { AppInterceptor } from './core/interceptors/app.interceptor';

import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { LoeaderComponent } from './components/loader/loader.component';
import { NothingToShowComponent } from './components/nothing-to-show/nothing-to-show.component';
import { MainModule } from './main/main.module';
import { HashLocationStrategy } from '@angular/common';
@NgModule({
  declarations: [AppComponent, LoeaderComponent, NothingToShowComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxTranslateModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
    }),
    NoopAnimationsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      timeOut: 2000,
    }),
    MainModule,
    FormsModule,
    ModalModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService, // required animations providers
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
