import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../core/models/messages';
import { PermissionService } from '../../../../core/services/permission.service';

@Component({
  selector: 'app-pages-actors',
  templateUrl: './pages-actors.component.html',
  styleUrls: ['./pages-actors.component.css'],
})
export class PagesActorsComponent {
  /* arrays */
  listRoles: any = [];
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private permissionService: PermissionService,
    private router: Router
  ) {}
  ngOnInit() {
    this.getListRoles();
  }
  getListRoles() {
    this.spinner.show();
    this.permissionService
      .getListPages()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listRoles = res.data;
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  navigateToPages(role: any) {
    this.router.navigate([
      '/acceuil/parametres/parametrage-profil',
      { id: role.id },
    ]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
