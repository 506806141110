<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>

<div id="kt_app_content" class="app-content flex-column-fluid">

  <!--begin::Content container-->
  <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <div class="d-flex align-items-center">
          <h1 class="page-heading"><svg width="23" height="23" viewBox="0 0 23 23" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.52877 12.4931L10.4368 11.5848C10.6743 11.3472 10.8049 11.0319 10.8049 10.6959C10.8049 10.3599 10.6743 10.0446 10.4368 9.80708C9.96208 9.3324 9.13416 9.33202 8.6591 9.80708L7.66797 10.6204C7.94458 10.9794 8.24801 11.3292 8.58132 11.6625C8.88667 11.9679 9.20581 12.2387 9.52877 12.4931Z"
                fill="#265D91" />
              <path
                d="M10.1334 3.72392C8.72121 3.35459 7.52627 3.56837 6.76808 4.32695C6.00989 5.08514 5.79534 6.28047 6.16505 7.69188C6.37998 8.51328 6.78187 9.35346 7.3305 10.1561L8.27488 9.38219C8.59977 9.05539 9.05989 8.8646 9.54913 8.8646C10.0384 8.8646 10.4985 9.05539 10.8441 9.40097C11.19 9.74692 11.3804 10.207 11.3804 10.6959C11.3804 11.1848 11.1896 11.6453 10.8441 11.9908L9.99355 12.8414C11.2973 13.7497 12.6731 14.244 13.8416 14.244C14.6726 14.244 15.399 13.9965 15.9189 13.477C17.4414 11.9544 16.6272 8.66346 14.1044 6.14063C12.901 4.93725 11.4908 4.07907 10.1334 3.72354V3.72392Z"
                fill="#265D91" />
              <path
                d="M7.13319 17.6384C7.13319 17.5622 7.10292 17.489 7.0489 17.4353L2.80932 13.1958C2.69706 13.0835 2.51508 13.0835 2.40283 13.1958L0.636269 14.9623C0.0274938 15.5711 0.0274938 16.5618 0.636269 17.1706L3.07405 19.6084C3.68283 20.2172 4.67357 20.2172 5.28234 19.6084L7.0489 17.8418C7.10292 17.7878 7.13319 17.7143 7.13319 17.6384Z"
                fill="#265D91" />
              <path
                d="M22.8823 1.20722C22.8712 1.04861 22.7291 0.927547 22.5755 0.940957L21.266 1.03329C21.1077 1.0444 20.9882 1.18194 20.9997 1.34017C21.0108 1.49878 21.1484 1.61678 21.3066 1.60643L21.9364 1.56199L19.6661 3.72508L18.565 2.83471C18.4535 2.74429 18.293 2.75004 18.1884 2.84774L15.9353 4.94531C15.8192 5.05335 15.8127 5.23533 15.9207 5.35141C16.0291 5.46788 16.2111 5.47363 16.3268 5.36597L18.3972 3.4385L19.5006 4.3304C19.6128 4.4212 19.7749 4.41507 19.8795 4.31508L22.3586 1.95277L22.4011 2.55733C22.4119 2.71594 22.5517 2.83548 22.708 2.8236C22.8663 2.81249 22.9858 2.67495 22.9743 2.51672L22.8823 1.20722Z"
                fill="#265D91" />
              <path
                d="M7.65809 18.0449L5.91797 19.785L8.3048 22.1719C8.78906 22.6596 9.58977 22.6274 10.0449 22.1167C10.2698 21.8918 10.3939 21.5922 10.3939 21.2742C10.3939 20.9562 10.2698 20.6566 10.0449 20.4318L7.65809 18.0449Z"
                fill="#265D91" />
              <path
                d="M5.61004 7.83706C5.5315 7.53708 5.48476 7.24783 5.45334 6.96509L2.79297 12.3709C2.79833 12.3755 7.87082 17.4315 7.87503 17.4526L13.2736 14.796C11.5959 14.6117 9.73124 13.6237 8.17693 12.069C6.90268 10.7944 5.99086 9.29138 5.61004 7.83706Z"
                fill="#265D91" />
            </svg>


            Appels d’offres
          </h1>

        </div>
        <!--end::Title-->
        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <a class="text-muted" routerLink="/">Accueil</a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->

          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <li class="breadcrumb-item text-muted">Appels d’offres</li>

          <!--end::Item-->
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->
      <!--begin::Actions-->

      <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
  </div>
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->
    <div class="card mb-10 pb-8">
      <div class="container-fluid">
        <div class="title">
          <h4>Recherche</h4>
        </div>
        <div [formGroup]="requestJobForm" >
          <div class="card-title">
            <div class="row">
              <div class="col-lg-6">
                <div class="input-group search-libre">
                  <span class="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                        fill="#7E7C7C" />
                    </svg>
                  </span>

                  <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid ps-5"
                    placeholder="Rechercher" formControlName="search" (keyup.enter)="searchOffer()" />
                  <div class="tooltip-container tooltip-info">
                    <div class="tooltip-trigger"><i class="fa-solid fa-circle-info" style="font-size: 20px;"></i></div>
                    <div class="tooltip-content">
                      <div class="content">
                        <h4>Opérateur AND :</h4>
                        <p>Utilisez l'opérateur AND pour spécifier que les résultats doivent inclure plusieurs termes.
                        </p>
                        <p>Exemple : software AND engineer renverra des profils qui mentionnent à la fois "software" et
                          "engineer".
                        </p>
                        <h4>Opérateur OR :</h4>
                        <p>Utilisez l'opérateur OR pour spécifier que les résultats peuvent inclure l'un des termes.
                        </p>
                        <p>Exemple : sales OR marketing renverra des profils qui mentionnent soit "sales" soit
                          "marketing".
                        </p>
                        <h4>Opérateur NOT :</h4>
                        <p>Utilisez l'opérateur NOT pour exclure des termes spécifiques des résultats.
                        </p>
                        <p>Exemple : developer NOT manager renverra des profils qui mentionnent "developer" mais pas
                          "manager".
                        </p>
                        <h4>Guillemets pour une expression exacte :</h4>
                        <p>Utilisez des guillemets pour rechercher une expression exacte.
                        </p>
                        <p>Exemple : "product manager" renverra des profils qui mentionnent exactement "product
                          manager".
                        </p>
                        <h4>Parenthèses pour grouper des opérations :</h4>
                        <p>Utilisez des parenthèses pour définir l'ordre d'évaluation des opérations.
                        </p>
                        <p>Exemple : (sales OR marketing) AND manager renverra des profils qui mentionnent "sales" ou
                          "marketing" et "manager".
                        </p>
                        <h4>Recherche standard :</h4>
                        <p>Si aucun opérateur n'est spécifié dans la recherche, le texte sera interprété comme s'il
                          était entre guillemets.
                        </p>
                        <p>Exemple : La recherche product manager sera traitée comme "product manager", suivant la règle
                          4.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <!-- <div class="text-danger mt-2"
                *ngIf="requestJobForm.get('search')?.hasError('pattern') && requestJobForm.get('search')?.touched && isSimpleWord(requestJobForm.get('search')?.value)">
                Le modèle de recherche est invalide. Utilisez le format correct (par exemple "hello" OR "hello").
              </div> -->
            </div>
          </div><br><br>
          <div class="row tools">
            <div class="row">
              <div class="col-lg-6 col-xl-3">
                <div class="position-relative   mb-3">
                  <input type="text" class="form-control form-control-solid ps-5" formControlName="ID_jobRequest"
                    name="société" value="" placeholder="ID" />
                </div>
              </div>
              <div class="col-lg-6 col-xl-3">
                <div class="position-relative   mb-3">
                  <input type="text" class="form-control form-control-solid ps-5" formControlName="name" name="société"
                    value="" placeholder="Poste" />
                </div>
              </div>
              <div class="col-lg-6 col-xl-3">
                <div class="position-relative   mb-3">
                  <input type="text" class="form-control form-control-solid ps-5 " formControlName="client_final_name"
                    name="société" value="" placeholder="Nom entreprise" />
                </div>
              </div>
              <div class="col-lg-6 col-xl-3">
                <div class="combined-input mb-3">
                  <input type="number" name="number1" min="0" formControlName="years_of_experience"
                    (keypress)="keyPress($event)" class="nb1" placeholder="Années d’expériences" id="tjm">

                  <input type="number" min="0" formControlName="experience_range" (keypress)="keyPress($event)"
                    name="number2" class="nb2" value="1" id="marge_tjm">
                  <div class="plusetmois">
                    <i class="fa-solid fa-plus-minus"></i>
                  </div>
                </div>
              </div>

            </div>
            <div class="row" *ngIf="seeMore">
              <div class="col-lg-6 col-xl-3">
                <div class="position-relative   mb-3">
                  <ng-select formControlName="contract_type"    [items]="contratRequest"
                    bindLabel="label" [notFoundText]="'Aucun élément trouvé'" [multiple]="true" bindValue="id" [placeholder]="'Type de contrat'" autoComplete>
                  </ng-select>
                </div>
              </div>
              <div class="col-lg-6 col-xl-3">
                <div class="combined-input mb-3">
                  <input type="number" min="0" name="number1" formControlName="desired_salary" (change)="alreadyConverted=false" class="nb1" placeholder="Salaire" id="Salaire">
                  <div class="euro">
                    <span class="input-group-text">€</span>
                  </div>
                  <input type="number" min="0" name="number2" formControlName="desired_salary_range" class="nb2" (change)="alreadyConverted=false"  (input)="onSalaryRangeInput($event)"  id="marge_salaire">
                  <div class="icon">
                    <span class="input-group-text">K€</span>
                  </div>
                  <div class="plusetmois">
                    <i class="fa-solid fa-plus-minus">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                  </div>
                </div>
              </div>
              
              <div class="col-lg-6 col-xl-3">
                <div class="combined-input  mb-3">
                  <input type="number" min="0" name="number1" class="nb1" formControlName="tjm" placeholder="TJM"
                    id="tjm">
                  <div class="euro">
                    <span class="input-group-text">€</span>
                  </div>
                  <input type="number" min="0" name="number2" formControlName="tjm_range" class="nb2" value="1"
                    id="marge_tjm">
                  <div class="icon">
                    <span class="input-group-text">€</span>
                  </div>
                  <div class="plusetmois">
                    <i class="fa-solid fa-plus-minus"> &nbsp;&nbsp;&nbsp;&nbsp; </i>
                  </div>
                </div>

              </div>
              <div class="col-lg-6 col-xl-3">
                <div class="position-relative   mb-3">
                  <ng-select formControlName="post_type"    [items]="typePoste" bindLabel="name"
                    [multiple]="true" bindValue="id" [placeholder]="'Type de poste'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                  </ng-select>
                </div>
              </div>

            </div>


            <div class="row" *ngIf="seeMore">
              <div class="col-lg-6 col-xl-3  mb-3">
                <ng-select formControlName="skillsAnd"    [items]="skillsList" bindLabel="name_FR"
                  bindValue="name_FR" [multiple]="true" [placeholder]="'Compétences (Et)'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                </ng-select>
              </div>
              <div class="col-lg-6 col-xl-3  mb-3">
                <ng-select formControlName="skillsOr"    [items]="skillsList" bindLabel="name_FR"
                  bindValue="name_FR" [multiple]="true" [placeholder]="'Compétences (Ou)'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                </ng-select>
              </div>
              <div class="col-lg-6 col-xl-3">
                <div class="position-relative   mb-3">
                  <ng-select formControlName="availability"    [items]="disponibilite"
                    [multiple]="true" bindLabel="name" bindValue="id" [placeholder]="'Disponibilité'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                  </ng-select>
                </div>
              </div>
              <div class="col-lg-6 col-xl-3 mb-3">
                <ng-select (change)="onCountryChangeSearch('add')"    [items]="paysList"  [multiple]="true"
                  formControlName="country" bindLabel="name" bindValue="name" [placeholder]="'Pays'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                </ng-select>

              </div>


            </div>


            <div class="row" *ngIf="seeMore">
              <div class="col-lg-6 col-xl-3  mb-3">
                <ng-select [items]="stateList" bindLabel="name"  (change)="onMobilityChange('add')"  formControlName="city"
                  bindValue="name" [multiple]="true" [placeholder]="'Ville'" autoComplete
                  [notFoundText]="'Aucun élément trouvé'">
                </ng-select>
              </div>
              <!-- <div class="col-lg-3">
                <div class="position-relative   mb-3">
                  <input type="text" id="dateInput" class="form-control form-control-solid ps-5"
                    (focus)="updateInputType('date','start_date')" (blur)="updateInputType('text','start_date')"
                    formControlName="start_date" name="nom" value="" placeholder="Date de début de publication" />
                </div>
              </div> -->
              <div class="col-lg-6 col-xl-3">
                <div class="position-relative  mb-3">
               

                    <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy"  placeholder="Date de publication"
                     inputId="yearpicker"
                        formControlName="start_date" class="form-control" [maxDate]="today" 
                       ></p-calendar>



                </div>
              </div>
              <div class="col-lg-6 col-xl-3">
                <div class="position-relative   mb-3">
               

                    <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy" 
                     placeholder="Date de fin de publication" inputId="yearpicker"  [minDate]="requestJobForm.get('start_date')?.value"
                    formControlName="end_date"  class="form-control"
                       ></p-calendar>

                </div>
              </div>

              <div class="col-lg-6 col-xl-3">
                <div class="position-relative   mb-3">
                  <ng-select formControlName="state"    [items]="StateOffre" bindLabel="name"
                    bindValue="id" [placeholder]="'Statut'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="voir-plus mb-5">
                <a class="voir-plus-btn" (click)="seeMore=!seeMore">Voir {{!seeMore?'plus':'moins'}} de critères de
                  recherche</a>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-3"></div>
              <div class="col-lg-3"></div>
              <div class="col-lg-3">
                <div class="mb-3 search-btn d-flex justify-content-end">
                  <div class="align-items-center recherche">
                    <button type="submit" class="me-5" (click)="search=true;searchOffer()">
                      <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                          fill="white" />
                      </svg>
                      Rechercher</button>
                  </div>
                  <div class="resett-btn">

                    <div class="reset-icon cursor-pointer">
                      <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                    </div>
                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end::Card-->
    <!--begin::Modals-->
    <!--begin::Modal - Customers - Add-->

    <!--end::Modal - Customers - Add-->
    <!--begin::Modal - Adjust Balance-->

    <!--end::Modal - New Card-->
    <!--end::Modals-->
  </div>
  <!--end::Content container-->
  <div *ngIf="!isLoadSpinner" id="kt_app_content_container" class="app-container container-fluid">
    <div class="card">
      <div class="container-fluid">
        <div class="card-body pt-0">
          <!--begin::Table-->
          <div class="title">
            <h4>Liste des Appels d’offres </h4>
          </div>
          <div class="table-responsive" id="kt_customers_table">
            <table *ngIf="listRequestOffer.length" class="table align-middle gy-5 mb-4" >
              <thead>
                <!-- [ngClass]="i==0?'one':i==listNames.length?'four':i % 2 === 0?'two':'three'" -->
                <tr class="text-start text-gray-400 fw-bold  text-uppercase gs-0">
                  <ng-container *ngFor="let item of listNames ;let i =index">
                    <th class="min-w-175px" *ngIf="item!='checkbox'" [ngClass]="{'th-fixed-2': item === 'Poste', 'th-fixed': item === 'Nom entreprise'}">
                      <span>{{item}}</span>
                      <i *ngIf="!['Liste de candidatures','Logo entreprise','Compétences'].includes(item)"
                        (click)="sortData(item)" class="fa-solid fa-sort cursor-pointer"></i>
                    </th>

                  </ng-container>
                  <th *ngIf="listNames.length" class="min-w-90px" style="z-index: 9;"><span>ACTIONS</span></th>
                  <th class="min-w-70px" style="z-index: 9;">
                    <div class="tools-column">
                      <div class="dropdown">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                            fill="none">
                            <g filter="url(#filter0_d_588_1699)">
                              <rect x="4" width="20" height="20" rx="4" fill="#63C3E8" fill-opacity="0.12"
                                shape-rendering="crispEdges" />
                            </g>
                            <path
                              d="M17.5 10.6H14.6V13.5C14.6 13.8314 14.3314 14.1 14 14.1C13.6686 14.1 13.4 13.8314 13.4 13.5V10.6H10.5C10.1686 10.6 9.9 10.3314 9.9 10C9.9 9.66863 10.1686 9.4 10.5 9.4H13.4V6.5C13.4 6.16863 13.6686 5.9 14 5.9C14.3314 5.9 14.6 6.16863 14.6 6.5V9.4H17.5C17.8314 9.4 18.1 9.66863 18.1 10C18.1 10.3314 17.8314 10.6 17.5 10.6Z"
                              fill="#265D91" stroke="#265D91" stroke-width="0.2" />
                            <defs>
                              <filter id="filter0_d_588_1699" x="0" y="0" width="28" height="28"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix"
                                  values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.13 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_588_1699" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_588_1699"
                                  result="shape" />
                              </filter>
                            </defs>
                          </svg>
                        </button>
                       <div class="list-actions">
                        <div class="dropdown-menu drop-height" aria-labelledby="dropdownMenuButton">
                          <div class="d-flex checkbox mb-2 menu" *ngFor="let item of dataHeader;let i =index">
                            <div class="form-check form-check-sm form-check-custom form-check-solid"
                              *ngIf="item.name!='checkbox' && item.name!='Actions' ">

                              <input class="form-check-input cursor-pointer checkbox-border"
                                [checked]="(item.checked=='oui'&&!item.checked_user)||item.checked_user=='oui'&&(item.checked=='non'||item.checked=='oui')"
                                (change)="changeSelection($event,item,i)" type="checkbox" value="1" [disabled]="item.name==='Nom entreprise' || item.name==='Logo entreprise'"/>
                              <a class="dropdown-item">{{item.name}}</a>
                            </div>
                          </div>
                        </div>
                       </div>
                      </div>
                    </div>

                  </th>
                </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
                <tr *ngFor="let offre of listRequestOffer ;let i =index">
                  <td *ngIf="listNames?.includes('ID')"><a 
                    [routerLink]="['/acceuil/appel-offre/details-appel-offre', { id: offre.id }]"
                      class="first-td">{{offre.ID_jobRequest?offre.ID_jobRequest:'---'}} </a>
                  </td>
                  <td *ngIf="listNames?.includes('Logo entreprise')">
                    <img
                      *ngIf="offre?.client_final?.logo&&offre?.client_final?.logo!='avatar.png'&&offre?.client_final?.logo!='undefined'"
                      class="table-img" src="{{url+'/Company/file/gallery_company/' + offre?.client_final?.logo}}"
                      alt="" width="50px" (click)="goToDetails(offre.id)">
                    <img
                      *ngIf="!offre?.client_final?.logo||offre?.client_final?.logo&&(offre?.client_final?.logo=='avatar.png'||offre?.client_final?.logo=='undefined')"
                      class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt="" width="50px">

                  </td>
                  <td *ngIf="listNames?.includes('Nom entreprise')" class="wrapping td-fixed">
                    <span>{{offre.client_final?.name && offre.client_final?.name!=='null' ? offre.client_final?.name:'---'}}</span>
                  </td>
                  <td *ngIf="listNames?.includes('Poste')" class="wrapping td-fixed-2">
                    <a 
                    [routerLink]="['/acceuil/appel-offre/details-appel-offre', { id: offre.id }]"
                    ><span class="first-td">{{offre.name?offre.name:'---'}}</span></a>
                  </td>
                  <td *ngIf="listNames?.includes('Date de publication')">
                    <span>{{!offre.start_date?'---':offre.start_date | formatDate}}</span>
                  </td>
                  <td *ngIf="listNames?.includes('Années d’expériences')">
                    <span>{{!offre.years_of_experience?'---':offre.years_of_experience }}</span>
                  </td>
                  <td *ngIf="listNames?.includes('Type de contrat')">
                    <!-- <span class="badge" *ngFor="let contrat of offre.contract_type let j=index">
                      {{contratRequest[contrat]?contratRequest[contrat].label:''}}</span> -->
                    <div class="type-contract">
                      <div>
                        <ng-container *ngFor="let contractType of offre.contract_type; let last = last">
                          <span class="color-blue">{{ contratTypeOptions[contractType] }}</span>
                          <span *ngIf="!last">, </span>
                        </ng-container>
                      </div>
                    <span *ngIf="offre.contract_type===null">---</span>
                    
                  </div>
                  </td>
                  <td *ngIf="listNames?.includes('Salaire')">
                    <span>{{offre.salary && offre.salary!= '0' ? offre.salary:'---' }}</span>
                  </td>
                  <td *ngIf="listNames?.includes('TJM')">
                    <span>{{offre.tjm && offre.tjm!= '0' ? offre.tjm:'---' }}</span>
                  </td>
                  <td *ngIf="listNames?.includes('Type de poste')">
                    <!-- <span>{{offre.post_type=='1'?'Sur site':offre.post_type=='2'?'A distance':'Hybride'}}</span> -->
                    <span *ngIf="offre.post_type=='1'"
                    >A distance</span>
                <span *ngIf="offre.post_type=='2'"
                    >Hybride</span>
                <span *ngIf="offre.post_type=='3'"
                    >Sur site</span>
                    <span *ngIf="offre.post_type===null">---</span>

                  </td>

                  <td *ngIf="listNames?.includes('Compétences')">
                    <div class="skills" *ngIf="offre.skills_job?.length">
                      <div class=" key" *ngFor="let skill of offre.skills_job.slice(0,2)">
                        <span class="key-word">
                          <div class="badge three ">{{skill?.sub_category?.name_FR?skill?.sub_category?.name_FR:'---'}}</div>
                        </span>
                       
                      </div>
                      <span *ngIf="offre.skills_job?.length&&offre.skills_job?.length>2"><svg width="8" height="8"
                        viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.34144 4.86849H4.51344V7.76649H3.02944V4.86849H0.201438V3.52449H3.02944V0.626487H4.51344V3.52449H7.34144V4.86849Z"
                          fill="#ACA7A7" />
                      </svg>
                    </span>
                    </div>
                    <div class="none" *ngIf="!offre.skills_job?.length">
                      <span class="key-word">
                        <div class="badge three ">---</div></span>
                    </div>

                  </td>




                  <td *ngIf="listNames?.includes('Liste de candidatures')">
                    <span class="name cursor-pointer" [routerLink]="['/acceuil/appel-offre/candidature-appel-offre',offre?.id]">Liste des candidatures</span>
                  </td>
                  <td *ngIf="listNames?.includes('Pays')">
                    <span>{{!offre.country?'---':offre.country }}</span>
                  </td>
                  <td *ngIf="listNames?.includes('Ville')">
                    <span>{{!offre.city || offre.city.length==0 ?'---':offre.city }}</span>
                  </td>
                  <td *ngIf="listNames?.includes('Statut')">
                    <div [ngClass]="offre.state?'badge-red':'badge-green'" class="badge ">
                      {{!offre.state?'Activé':'Désactivé'}}
                    </div>
                  </td>


                  <td *ngIf="listNames.length">
                    <!-- <div class="seemore">
                      <a (click)="goToDetails(offre.id)">Voir détail</a>
                    </div> -->

                   <div class="tools">
                    <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)"
                            fill="#265D91" />
                          <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)"
                            fill="#265D91" />
                          <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)"
                            fill="#265D91" />
                        </svg>
                      </button>
                     <div class="dropdown-table">
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      
                        <a class="dropdown-item"  style="cursor: pointer;" (click)="goToDetails(offre.id)">Voir détail</a>
                        <a class="dropdown-item"  style="cursor: pointer;" routerLink="/acceuil/appel-offre/contact-manager">Contacter manager</a>
                        <a class="dropdown-item"  style="cursor: pointer;" [routerLink]="['/acceuil/appel-offre/candidature-appel-offre',offre?.id]">Liste des candidatures</a>
                        <a class="dropdown-item"  style="cursor: pointer;"  [routerLink]="['/acceuil/appel-offre/get-bareme',offre.id]" [ngClass]="{ 'disabled-link': offre?.score === 'no' }">Voir Barème</a>
                        <a *ngIf="offre.state=='1'" class="dropdown-item" style="cursor: pointer;"  (click)="activerjobrequest(offre.id)">Activer</a>
                        <a *ngIf="offre.state=='0'" class="dropdown-item" style="cursor: pointer;" (click)="desactiverjobrequest(offre.id)">Désactiver</a>


                                    </div>
                     </div>
                                  </div>
                   </div>
                  </td>
                </tr>
              </tbody>
              <!--end::Table body-->
            </table>

          </div>

          <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="!listRequestOffer.length ">
            <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
            <span class="empty-list">
              La liste est vide
            </span>
          </div>
          <!--end::Table-->
        </div>
        <div class="pagination" *ngIf="listRequestOffer && listRequestOffer.length">
          <app-pagination [data]="listRequestOffer" [lengthData]="totalItems" [currentPageList]="currentPage" [endexPageList]="endIndex" (setPages)="getItems($event)">
          </app-pagination>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>

</div>