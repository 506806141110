import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../../../core/models/messages';
import { SettingsService } from '../../../../../../core/services/settings/settings.service';

@Component({
  selector: 'app-actual-offer',
  templateUrl: './actual-offer.component.html',
  styleUrls: ['./actual-offer.component.css'],
})
export class ActualOfferComponent {
  /* string */
  nameOffer!: string;
  /* number */
  idOffer!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  pagination : boolean =false;

  idEsn!: number;
  fileInvoice: any;
  /*  */
  listAbonnement: any;
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private settingService: SettingsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    this.getId();
  }
  /* get id esn from route */
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idEsn = params.params['id'];
        this.getListCandidaturesByOffer();
      },
    });
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
         if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
      this.getListCandidaturesByOffer();
        }
    }
  }
  /* get list offer */
  getListCandidaturesByOffer() {
    this.spinner.show();
    this.settingService
      .getListAbonnementActuelByESN(this.idEsn)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listAbonnement = res.data.data
          // .slice(0, this.endIndex);
          this.totalItems = res.data.total;
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  navigateToInvoiceHistory(offerId: any, subscription_id: any) {
    this.router.navigate([
      '/acceuil/entreprises/esn/factures',
      { abonnementId: offerId, subscription_id: subscription_id },
    ]);
  }
  profilEsnNavigation(id: string) {
    this.router.navigate(['/acceuil/entreprises/esn/profil-esn', { id: id }]);
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
