import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICountry, State } from 'country-state-city';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ISort, listContracts } from '../../../../core/models/candidat';
import { CJobOffer } from '../../../../core/models/job-offer';
import { serverError } from '../../../../core/models/messages';
import { CandidatService } from '../../../../core/services/candidat/candidat.service';
import { JobOfferService } from '../../../../core/services/job-offer/job-offer.service';
import { SortDataService } from '../../../../core/services/sort-data/sort-data.service';
import { TenderService } from '../../../../core/services/tender/tender.service';
import { PaysService } from '../../../../shared/services/pays.service';
import { environment } from '../../../../../environments/environment';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-list-offre',
  templateUrl: './list-offre.component.html',
  styleUrls: ['./list-offre.component.css'],
})
export class ListOffreComponent {
  sortAttr: string = '';
  url: string = environment.baseUrl + '/api';
  /* number */
  seeMore: boolean = false;
  pagination: boolean = false;
  today = new Date();
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  /* objects */
  jobOffer!: CJobOffer;
  isLoadSpinner: boolean = true;

  /* arrays */
  search: boolean = false;
  listJobOffer: any[] = [];
  skillsList: { name_FR: string }[] = [];
  countries: any[] = [];
  mobilities: any[] = [];
  test: any
  currentDate!: any;
  minDate!: string;
  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 2, name: '1 mois' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];
  postType = [
    { id: 1, name: 'A distance' },
    { id: 2, name: 'Hybride' },
    { id: 3, name: 'Sur site' },
  ]
  typesCompanies = [
    { id: 2, name: 'ESN' },
    { id: 4, name: 'Cabinet de Recrutement' },
  ]
  StateOffre = [
    { id: 0, name: 'Activé' },
    { id: 1, name: 'Désactivé' },
  ]
  Statepassport = [
    { id: 0, name: 'Non' },
    { id: 1, name: 'Oui' },
  ]
  profile = [
    { id: 0, name: 'Non' },
    { id: 1, name: 'Oui' },
  ]
  dataHeaderSort = [
    { name: 'ID', code: 'ID_jobOffer' },
    { name: 'Nom entreprise', code: 'esn_name' },
    { name: 'Poste', code: 'name' },
    { name: 'Type', code: 'type' },
    { name: 'Date de publication', code: 'start_date' },
    { name: 'Années d’expériences', code: 'years_of_experience' },
    { name: 'Type de contrat', code: 'contract_type' },
    { name: 'Salaire', code: 'salary' },
    { name: 'TJM', code: 'tjm' },
    { name: 'Type de poste', code: 'post_type' },
    { name: 'Compétences', code: 'skills_job' },
    { name: 'Pays', code: 'country' },
    { name: 'Ville', code: 'city' },
    { name: 'Statut', code: 'state' },
  ]
  contratRequest = listContracts
  contratTypeOptions: { [key: string]: string } = {
    '1': 'Alternance',
    '2': 'Autres type de contrat',
    '3': 'CDD',
    '4': 'CDI',
    '5': 'Freelance',
    '6': 'Portage salarial',
    '7': 'Stage',
  };

  stateList: any[] = []
  dataHeader: any = []
  listNames: string[] = []
  paysList: ICountry[] = [];
  /* formGroup */
  jobOfferForm: FormGroup = this.createJobOfferSearchForm(new CJobOffer());
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject()
  constructor(private jobOfferService: JobOfferService, private router: Router, private elRef: ElementRef, private datePipe: DatePipe,
    private primengConfig: PrimeNGConfig,
    private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private toastrService: ToastrService,
    private jobRequestService: TenderService, private sortDataService: SortDataService, private candidatService: CandidatService, private paysServices: PaysService) {
    const day = String(this.today.getDate()).padStart(2, "0");
    const month = String(this.today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = this.today.getFullYear();
    this.currentDate = `${year}-${month}-${day}`;
    // Set the minimum date to 10 years ago, for example
    const minYear = 1990;
    this.minDate = `${minYear}-${month}-${day}`;
  }
  ngOnInit() {
    this.paysList = this.paysServices.getAllCountries();

    this.jobOfferForm.get('city')?.disable();
    this.getListElementHeader();
    this.getListJobOffer();
    this.getListSkills();

    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
    this.primengConfig.setTranslation({
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer'
    });

  }

  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
      console.log('HEYYYYYYYYYYYY');
    }
  }
  getListElementHeader() {
    this.candidatService
      .getHeaderElements(6)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.dataHeader = res.data;
          for (const iterator of this.dataHeader) {
            if (
              (iterator.checked == 'oui' && !iterator.checked_user) ||
              (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
              (iterator.checked == 'oui' && iterator.checked_user == 'oui')
            )
              this.listNames.push(iterator.name);
          }
          this.dataHeader.map((el: any) => {
            if (
              (el.checked == 'oui' && !el.checked_user) ||
              (el.checked_user == 'oui' &&
                (el.checked == 'non' || el.checked == 'oui'))
            ) {
              return (el.checkAdmin = true);
            } else {
              return (el.checkAdmin = false);
            }
          });
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }
  updateInputType(type: 'text' | 'date', nameControl: string) {
    const inputElement =
      nameControl == 'end_date'
        ? (document.getElementById('dateInputEnd') as HTMLInputElement)
        : (document.getElementById('dateInput') as HTMLInputElement);
    inputElement.type = type;
    if (type == 'text') {
      if (
        this.jobOfferForm.value[nameControl] &&
        this.jobOfferForm.value[nameControl] != ''
      )
        this.jobOfferForm
          .get(nameControl)
          ?.setValue(
            moment(this.jobOfferForm.value[nameControl]).format('DD/MM/yyyy')
          );
    }
  }
  changeSelection(event: any, data: any, i: number) {
    this.listNames = [];
    data.checkAdmin = event.target.checked;
    for (const iterator of this.dataHeader) {
      if (iterator.checkAdmin) this.listNames.push(iterator.name);
    }
    this.changeSelectionHeadrElements(data);
  }
  changeSelectionHeadrElements(data: any) {
    let payload = {
      sub_page_id: data?.id,
    };
    this.candidatService
      .changeDelectedElementHeader(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          // this.skillsList = res.data
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }
  getListSkills() {
    this.jobRequestService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }
  /* get indexes for pagination */

  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex
      this.endIndex = event.endIndex
      this.itemsPerPage = event.itemsPerPage,
        this.currentPage = event.currentPage

      if (this.jobOfferForm.dirty) {
        if (this.search) this.searchOffer();
      } else if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getListJobOffer();
      }
    }
  }

  /* sort data */
  sortData(sortAttribute: string) {
    for (const iterator of this.dataHeaderSort) {
      if (sortAttribute == iterator.name) sortAttribute = iterator.code;
    }
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr = sortAttribute;

    this.currentPage = 1;
    this.endIndex = 5;

    this.getListJobOffer();
  }

  /* get list appel d'offre */
  type: any;
  getListJobOffer() {
    this.spinner.show();

    const data = this.sortAttr === ''
      ? { per_page: this.itemsPerPage, page: this.currentPage }
      : {
        per_page: this.itemsPerPage,
        page: this.currentPage,
        sort: this.currentSortDirection,
        sortAttribute: this.sortAttr
      };

    this.jobOfferService.listJobOffer(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res: any) => {
        this.isLoadSpinner = false;
        console.log("listejoboffer", res);
        this.spinner.hide();
        this.listJobOffer = res.data.data
        // this.listJobOffer = res.data.data.slice(0, this.endIndex);
        // this.type = res?.data?.data[0]?.esn?.type;
        // console.log("type", this.type);
        this.totalItems = res.data.total;
        console.log("total", this.totalItems);
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  /* create search form */
  createJobOfferSearchForm(data?: CJobOffer) {
    return this.formBuilder.group({
      ID_jobOffer: ['', data && data.ID_jobOffer],
      name: ['', data && data.name],
      esn_name: ['', data && data.esn_name],
      company_nature: [null, data && data.company_nature],
      years_of_experience: ['', data && data.years_of_experience],
      experience_range: ['', data && data.experience_range],
      contract_type: ['', data && data.contract_type],
      desired_salary: ['', data && data.desired_salary],
      desired_salary_range: ['', data && data.desired_salary_range],
      tjm: ['', data && data.tjm],
      tjm_range: ['', data && data.tjm_range],
      post_type: ['', data && data.post_type],
      skillsAnd: ['', data && data.skillsAnd],
      skillsOr: ['', data && data.skillsOr],
      availability: ['', data && data.availability],
      country: [null, data && data.country],
      city: ['', data && data.city],
      start_date: ['', data && data.start_date],
      end_date: ['', data && data.end_date],
      profile_abroad: [null, data && data.profile_abroad],
      passeport_talent: [null, data && data.passeport_talent],
      state: [null, data && data.state],
      search: [
        '',
        [Validators.pattern(/^("([^"]*"(AND|NOT|,|&&)[^"]*)*"|[^\s"']+)$/)],
      ],
    });
  }
  /* key inter number */
  keyPress(event: any) {
    let pattern = /^[\d]$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    return pattern;
  }
  onSalaryRangeInput(event: any) {
    const value = event.target.value;
    if (value.toLowerCase().endsWith('k')) {
      this.jobOfferForm.controls['desired_salary_range'].setValue(
        this.convertKToNumber(value)
      );
    }
  }
  alreadyConverted = false;
  convertKToNumber(value: any) {
    if (typeof value === 'string' && value.toLowerCase().endsWith('k')) {
      // Si la valeur se termine par 'k'
      const numericValue = parseFloat(value.slice(0, -1));
      if (!isNaN(numericValue)) {
        // Convertir en multipliant par 1000
        this.alreadyConverted = true; // Marquer comme déjà convertie
        return numericValue * 1000;
      }
    } else if (!isNaN(value)) {
      // Si la valeur est déjà un nombre, et si elle n'a pas déjà été convertie, convertir en multipliant par 1000
      if (!this.alreadyConverted) {
        this.alreadyConverted = true; // Marquer comme déjà convertie
        return parseFloat(value) * 1000;
      } else {
        return parseFloat(value); // Déjà convertie, retourner sans multiplier à nouveau
      }
    }
  }
  // convertKToNumber(value: any) {
  //   if (typeof value === 'string' && value.toLowerCase().endsWith('k')) {
  //     return parseFloat(value);
  //   } else if (!isNaN(value)) {
  //     return parseFloat(value)* 1000;
  //   }
  //   return parseFloat(value);
  // }
  /* filter job offer */
  /* search */
  transformSearch(search: string): string {
    // Remplace toutes les occurrences de '||' par 'OR'
    search = search.replace(/\|\|/g, ' OR ');

    // Remplace toutes les occurrences de '&&' par ' AND '
    search = search.replace(/&&/g, ' AND ');

    // Remplace toutes les occurrences de '!' par ' NOT '
    search = search.replace(/!/g, ' NOT ');

    search = search.replace(/\b(AND|OR|NOT)\b/g, ' $1 ');

    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        return `"${p1}"`;
      } else {
        // Si le terme n'est pas entre guillemets, échappe les guillemets simples à l'intérieur du terme
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `${escapedTerm}`;
      }
    });

    search = search.replace(/\s+/g, ' ');

    return search.trim();
  }

  isSimpleWord(value: string): boolean {
    // Utilisez une expression régulière pour vérifier si la valeur est un mot simple ou deux mots avec espace
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }
  // searchOffer() {
  //   this.spinner.show()
  //   this.jobOfferForm.value.years_of_experience= this.jobOfferForm.value.years_of_experience !== null ? this.jobOfferForm.value.years_of_experience.toString() : '',
  //   this.jobOfferForm.value.desired_salary = this.jobOfferForm.value.desired_salary !== null ? this.jobOfferForm.value.desired_salary.toString() : '';
  //   valueForm.desired_salary_range = valueForm.desired_salary_range !== null ? this.convertKToNumber(valueForm.desired_salary_range.toString()).toString() : '';
  //   this.jobOfferForm.value.tjm= this.jobOfferForm.value.tjm !== null ? this.jobOfferForm.value.tjm.toString() : '',
  //   this.jobOfferForm.value.tjm_range= this.jobOfferForm.value.tjm_range!== null ? this.jobOfferForm.value.tjm_range.toString() : ''
  //   // this.jobOfferForm.value.search= this.jobOfferForm.value.search ? this.jobOfferForm.value.search.toString() : ''
  //   this.jobOfferForm.value.search=this.transformSearch(this.jobOfferForm.value.search ? this.jobOfferForm.value.search.toString() : '')

  //   let payload = {...this.jobOfferForm.value, page:this.currentPage,
  //     per_page:this.itemsPerPage,}
  //   this.jobOfferService.searchJobOffer(payload).pipe(takeUntil(this.unsubscribeAll)).subscribe({
  //     next: (res: any) => {
  //        if(!res.data.data.length && this.currentPage>1){
  //         this.currentPage=1
  //         this.searchOffer()
  //       }else{
  //         this.listJobOffer = res.data.data.slice(0,this.endIndex)
  //       this.totalItems = res.data.total
  //       }
  //       this.spinner.hide()
  //     },
  //     error: () => {
  //       this.spinner.hide()
  //     }
  //   })
  // }
  searchOffer() {
    this.spinner.show();

    // Récupérer les valeurs du formulaire
    const valueForm = this.jobOfferForm.value;
    valueForm.end_date = valueForm.end_date ? this.datePipe.transform(valueForm.end_date, 'yyyy-MM-dd') : '';
    valueForm.start_date = valueForm.start_date ? this.datePipe.transform(valueForm.start_date, 'yyyy-MM-dd') : '';
    // Convertir les champs en chaînes et traiter desired_salary_range avec convertKToNumber
    valueForm.years_of_experience = valueForm.years_of_experience !== null ? valueForm.years_of_experience.toString() : '';
    valueForm.desired_salary = valueForm.desired_salary !== null ? valueForm.desired_salary.toString() : '';
    // valueForm.desired_salary_range = valueForm.desired_salary_range !== null ? valueForm.desired_salary_range.toString() : '';
    valueForm.desired_salary_range = this.convertKToNumber(valueForm.desired_salary_range?.toString());
    // ? (isNaN(this.convertKToNumber(valueForm.desired_salary_range)) ? '' : this.convertKToNumber(valueForm.desired_salary_range).toString()) 
    // : '';
    valueForm.tjm = valueForm.tjm !== null ? valueForm.tjm.toString() : '';
    valueForm.tjm_range =
      valueForm.tjm_range !== null ? valueForm.tjm_range.toString() : '';
    valueForm.search = this.transformSearch(
      valueForm.search ? valueForm.search.toString() : ''
    );

    // Préparer le payload et le convertir en chaîne JSON
    let payload = {
      ...valueForm,
      page: this.currentPage,
      per_page: this.itemsPerPage,
    };

    // Envoyer le payload en tant que chaîne JSON
    this.jobOfferService
      .searchJobOffer(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.isRecherche = true;
          if (!res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.searchOffer();
            this.spinner.hide();
          } else {
            this.listJobOffer = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  goToListCandidature(id: string | undefined, offre: string | undefined) {
    this.router.navigate([
      '/acceuil/offre-emploi/candidatures',
      { id: id, offre: offre },
    ]);
  }
  goToDetails(id: string | undefined) {
    this.router.navigate(['/acceuil/offre-emploi/details-offer', { id: id }]);
  }
  isRecherche: boolean = false;
  reset() {
    this.jobOfferForm.reset();
    this.jobOfferForm = this.createJobOfferSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
      console.log('gggg');
      console.log('gggg', this.isRecherche);
    } else {
      this.spinner.hide();
      console.log('hhh');
      this.getListJobOffer();
      console.log('hhhh', this.isRecherche);
    }
    return (this.isRecherche = false);
  }
  searchOffereset() {
    // Récupérer les valeurs du formulaire
    const valueForm = this.jobOfferForm.value;

    // Convertir les champs en chaînes et traiter desired_salary_range avec convertKToNumber
    valueForm.years_of_experience = valueForm.years_of_experience !== null ? valueForm.years_of_experience.toString() : '';
    valueForm.desired_salary = valueForm.desired_salary !== null ? valueForm.desired_salary.toString() : '';
    // valueForm.desired_salary_range = valueForm.desired_salary_range !== null ? this.convertKToNumber(valueForm.desired_salary_range).toString() : '';
    valueForm.desired_salary_range = this.convertKToNumber(valueForm.desired_salary_range.toString());
    valueForm.tjm = valueForm.tjm !== null ? valueForm.tjm.toString() : '';
    valueForm.tjm_range = valueForm.tjm_range !== null ? valueForm.tjm_range.toString() : '';
    valueForm.search = this.transformSearch(valueForm.search ? valueForm.search.toString() : '');

    // Préparer le payload et le convertir en chaîne JSON
    let payload = {
      ...valueForm,
      page: this.currentPage,
      per_page: this.itemsPerPage,
    };
    this.spinner.show();

    // Envoyer le payload en tant que chaîne JSON
    this.jobOfferService
      .searchJobOffer(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          if (this.currentPage > 1) {
            this.spinner.hide();
            this.currentPage = 1;
            this.searchOffer();
          } else {
            this.listJobOffer = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  getListJobOfferest() {

    let data = this.sortAttr == '' ? { per_page: this.itemsPerPage, page: this.currentPage } : { ...{ per_page: this.itemsPerPage, page: this.currentPage }, ...{ sort: this.currentSortDirection, sortAttribute: this.sortAttr } }
    this.jobOfferService.listJobOffer(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res: any) => {
        console.log("listejoboffer", res)


        this.listJobOffer = res.data.data.slice(0, this.endIndex)

        this.type = res?.data?.data[0]?.esn?.type
        console.log("type", this.type)
        this.totalItems = res.data.total
        console.log("total", this.totalItems)

      },
      error: () => {

      }
    })
  }

  /* change country select and delete the string Governorate from state name*/

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable.removeEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
  onCountryChange(mode: string): void {
    this.jobOfferForm.get('city')?.enable();
    this.countries = this.jobOfferForm.get('country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.jobOfferForm.get('city')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode);
      if (after.length == 0) after.push({ name: 'Tout le pays' });
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter(
        (item) => !differentItemsArray1.includes(item)
      );
      this.jobOfferForm.get('city')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.jobOfferForm.get('city')?.disable();
        this.jobOfferForm.get('city')?.setValue(null);
      }
    }
  }

  onMobilityChange(mode: string) {
    this.mobilities = this.jobOfferForm.get('city')?.value;
    let paysLists: any[] = [];
    this.countries = this.jobOfferForm.get('country')?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach((item) => {
        stateListAfter.push(item.name); ////  }
      });
    });
    console.log(
      'stateList in change mobilities mobilities : ',
      this.mobilities
    );
    console.log(
      'stateList in change mobilities stateListAfter  : ',
      stateListAfter
    );
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    console.log('stateList in change mobilities : ', this.stateList);
    this.mobilities = this.jobOfferForm.get('city')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }
  findDuplicates(array1: any, array2: any) {
    // Create an empty object to store occurrences of elements
    let occurrences: any[] = [];

    // Array to store duplicate elements
    let duplicates: any = [];

    // Iterate over the first array and count occurrences
    for (let element of array1) {
      occurrences[element] = (occurrences[element] || 0) + 1;
    }

    // Iterate over the second array and check for duplicates
    for (let element of array2) {
      // If the element exists in occurrences and is not already in duplicates
      if (occurrences[element] && !duplicates.includes(element)) {
        duplicates.push(element);
      }
    }

    return duplicates;
  }

  activerjoboffer(id: any) {
    Swal.fire({
      title: 'Voulez-vous vraiment activer cette offre d’emploi ?',
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, activé !',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.jobOfferService.activedesactiveJobOffer(id).subscribe({
          next: (response: any) => {
            console.log('response', response);

            console.log('response.data', response.data);
            this.toastrService.success(
              'L’offre d’emploi a été activé avec succès .'
            );
            this.searchOffer();
          },
          error: (error: any) => {
            console.error('Error fetching  job offer: ', error);
          },
        });
      }
    });
  }

  desactiverjoboffer(id: any) {
    Swal.fire({
      title: 'Voulez-vous vraiment désactiver cette offre d’emploi ?',
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, désactivé !',
      cancelButtonText: 'Annuler ',
    }).then((result) => {
      if (result.isConfirmed) {
        this.jobOfferService.activedesactiveJobOffer(id).subscribe({
          next: (response: any) => {
            console.log('response', response);

            console.log('response.data', response.data);
            this.toastrService.success(
              'L’offre d’emploi a été désactivé avec succès.'
            );
            this.searchOffer();
          },
          error: (error: any) => {
            console.error('Error fetching  job offer: ', error);
          },
        });
      }
    });
  }
}
