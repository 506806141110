import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ListOffreComponent } from './components/list-offre/list-offre.component';
import { JobOfferRoutingModule } from './job-offer-routing.module';
import { CandidaturesComponent } from './components/list-offre/candidatures/candidatures.component';
import { FieldErrorModule } from '../../shared/components/shared-field-error/field-error.module';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { PipesModuleModule } from '../../core/pipes/pipes-module.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DetailsOfferComponent } from './components/details-offer/details-offer.component';
import { AddbaremeComponent } from './components/add-bareme/addbareme.component';
import { VoirTestComponent } from './components/voir-test/voir-test.component';
import { ContactManagerComponent } from './components/contact-manager/contact-manager.component';
import { DossiercandidatureComponent } from './components/list-offre/dossiercandidature/dossiercandidature.component';
import { CalendarModule } from 'primeng/calendar';
import localeFr from '@angular/common/locales/fr';

@NgModule({
  declarations: [
    ListOffreComponent,
    CandidaturesComponent,
    DetailsOfferComponent,
    AddbaremeComponent,
    VoirTestComponent,
    ContactManagerComponent,
    DossiercandidatureComponent,
  ],
  imports: [
    CommonModule,
    JobOfferRoutingModule,
    FieldErrorModule,
    PaginationModule,
    PipesModuleModule,
    NgxSpinnerModule,
    NgxSpinnerModule,
    CalendarModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }]

})
export class JobOfferModule {
  constructor() {
    registerLocaleData(localeFr);
  }
}
