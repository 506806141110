import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {
  linkedCompanyRegExp,
  linkedInRegExp,
  patternBIC,
  patternIBAN,
  stringRegExp,
  urlRegExp,
  youTubePattern,
} from '../../../core/models/pattern';

@Component({
  selector: 'app-feild-error',
  templateUrl: './feild-error.component.html',
  styleUrls: ['./feild-error.component.css'],
})
export class FeildErrorComponent implements OnInit {
  /* formControl */
  _control: AbstractControl | null = new FormControl();
  /* string */
  linkedInRegExp = linkedInRegExp;
  linkedCompanyRegExp = linkedCompanyRegExp;
  urlRegExp = urlRegExp;
  stringRegExp = stringRegExp;
  youTubePattern = youTubePattern;
  patternIBAN = patternIBAN;
  patternBIC = patternBIC;
  /* Input */
  @Input() set control(value: AbstractControl | null | undefined) {
    if (value) {
      this._control = value;
    }
  }
  @Input() controlName!: string;
  ngOnInit(): void {}
}
