<!--begin::Footer-->
<div id="kt_app_footer" class="app-footer">
  <!--begin::Footer container-->
  <div class="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
      <span class="year me-1">2024&copy;</span>
      <a   class="copy">Copyright wegestu All rights reserved.</a>
    </div>
    <!--end::Copyright-->
    <!--begin::Menu-->
    <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
      <li class="menu-item">
        <a  class="version px-2"></a>
      </li>
     
    </ul>
    <!--end::Menu-->
  </div>
  <!--end::Footer container-->
</div>