import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICountry, State } from 'country-state-city';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ISort } from '../../../../../../core/models/candidat';
import {
  addedJobRequest,
  existedJobRequest,
  serverError,
} from '../../../../../../core/models/messages';
import { CompanyService } from '../../../../../../core/services/company/company.service';
import { SortDataService } from '../../../../../../core/services/sort-data/sort-data.service';
import { TenderService } from '../../../../../../core/services/tender/tender.service';
import { PaysService } from '../../../../../../shared/services/pays.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-appel-offre',
  templateUrl: './appel-offre.component.html',
  styleUrls: ['./appel-offre.component.css'],
})
export class AppelOffreComponent {
  /* number */
  toolbarOptions = [
    ['bold', 'italic', 'underline'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    ['clean'],
  ];

  currentSortDirection: number = 2;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  idCompany!: number;
  clickCount: number = 0;
  /* string */
  searchElement: string = '';
  sortAttr: string = '';
  isDedicatedToParticulier: boolean = false;
  isDedicatedToESN: boolean = false;
  pagination : boolean =false;


  /*  */
  listAppelOffer: any = [];
  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 2, name: '1 mois' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];
  contratRequest = [
    { id: '1', label: 'Alternance', },
    { id: '2', label: 'Autres type de contrat' },
    { id: '3',  label: 'CDD' },
    { id: '4', label: 'CDI' },
    { id: '5',  label: 'Freelance' },
    { id: '6', label: 'Portage salarial'},
    { id: '7', vallabel: 'Stage' },
  
  ]; 
  postType = [
    { id: 1, name: 'A distance' },
    { id: 2, name: 'Hybride' },
    { id: 3, name: 'Sur site' },
  ]
  

  stateList:any[]=[]
  skillsList:{name_FR:string}[]=[]
  paysList: ICountry[] =[];
  mobilities: any[] = [];
  countries: any[] = [];

  /* formGroup */
  requestFobForm: FormGroup = this.createRequestFobForm();
  /* modal */
  modalRef?: BsModalRef;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private jobRequestService: TenderService,
    private toastr: ToastrService,
    private router: Router,
    private sortDataService: SortDataService,
    private toastrService: ToastrService,
    private paysServices: PaysService
  ) {}

  ngOnInit() {
    this.paysList = this.paysServices.getAllCountries();
    this.getIdCompany();
    this.getListSkills();
  }
  getListSkills() {
    this.jobRequestService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => {
          this.toastr.error(serverError);
        },
      });
  }
  translateCountryName(name: string): string {
    const translations: { [key: string]: string } = {
      Afghanistan: 'Afghanistan',
      'South Africa': 'Afrique du Sud',
      Albania: 'Albanie',
      Algeria: 'Algérie',
      Germany: 'Allemagne',
      Andorra: 'Andorre',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctique',
      'Antigua and Barbuda': 'Antigua-et-Barbuda',
      'Saudi Arabia': 'Arabie saoudite',
      Argentina: 'Argentine',
      Armenia: 'Arménie',
      Aruba: 'Aruba',
      Australia: 'Australie',
      Austria: 'Autriche',
      Azerbaijan: 'Azerbaïdjan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahreïn',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbade',
      Belgium: 'Belgique',
      Belize: 'Belize',
      Benin: 'Bénin',
      Bermuda: 'Bermudes',
      Bhutan: 'Bhoutan',
      Belarus: 'Biélorussie',
      Bolivia: 'Bolivie',
      'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
      Botswana: 'Botswana',
      Brazil: 'Brésil',
      Brunei: 'Brunei',
      Bulgaria: 'Bulgarie',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      'Cape Verde': 'Cabo Verde',
      Cambodia: 'Cambodge',
      Cameroon: 'Cameroun',
      Canada: 'Canada',
      Chile: 'Chili',
      China: 'Chine',
      Cyprus: 'Chypre',
      Colombia: 'Colombie',
      Comoros: 'Comores',
      'Congo - Brazzaville': 'Congo-Brazzaville',
      'Congo - Kinshasa': 'Congo-Kinshasa',
      'North Korea': 'Corée du Nord',
      'South Korea': 'Corée du Sud',
      'Costa Rica': 'Costa Rica',
      'Ivory Coast': 'Côte d’Ivoire',
      Croatia: 'Croatie',
      Cuba: 'Cuba',
      Curaçao: 'Curaçao',
      Denmark: 'Danemark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominique',
      'Dominican Republic': 'République dominicaine',
      Ecuador: 'Équateur',
      Egypt: 'Égypte',
      'United Arab Emirates': 'Émirats arabes unis',
      Eritrea: 'Érythrée',
      Spain: 'Espagne',
      Eswatini: 'Eswatini',
      Estonia: 'Estonie',
      'United States': 'États-Unis',
      Ethiopia: 'Éthiopie',
      Fiji: 'Fidji',
      Finland: 'Finlande',
      France: 'France',
      Gabon: 'Gabon',
      Gambia: 'Gambie',
      Georgia: 'Géorgie',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Grèce',
      Grenada: 'Grenade',
      Greenland: 'Groenland',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernesey',
      Guinea: 'Guinée',
      'Guinea-Bissau': 'Guinée-Bissau',
      'Equatorial Guinea': 'Guinée équatoriale',
      Guyana: 'Guyana',
      'French Guiana': 'Guyane française',
      Haiti: 'Haïti',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Hongrie',
      Iceland: 'Islande',
      India: 'Inde',
      Indonesia: 'Indonésie',
      Iraq: 'Irak',
      Iran: 'Iran',
      Ireland: 'Irlande',
      'Isle of Man': 'Île de Man',
      Israel: 'Israël',
      Italy: 'Italie',
      Jamaica: 'Jamaïque',
      Japan: 'Japon',
      Jersey: 'Jersey',
      Jordan: 'Jordanie',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      Kuwait: 'Koweït',
      Kyrgyzstan: 'Kirghizistan',
      Laos: 'Laos',
      Latvia: 'Lettonie',
      Lebanon: 'Liban',
      Lesotho: 'Lesotho',
      Liberia: 'Libéria',
      Libya: 'Libye',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lituanie',
      Luxembourg: 'Luxembourg',
      Macao: 'Macao',
      'North Macedonia': 'Macédoine du Nord',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaisie',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malte',
      'Marshall Islands': 'Îles Marshall',
      Martinique: 'Martinique',
      Mauritania: 'Mauritanie',
      Mauritius: 'Maurice',
      Mayotte: 'Mayotte',
      Mexico: 'Mexique',
      Micronesia: 'Micronésie',
      Moldova: 'Moldavie',
      Monaco: 'Monaco',
      Mongolia: 'Mongolie',
      Montenegro: 'Monténégro',
      Montserrat: 'Montserrat',
      Morocco: 'Maroc',
      Mozambique: 'Mozambique',
      Myanmar: 'Myanmar',
      Namibia: 'Namibie',
      Nauru: 'Nauru',
      Nepal: 'Népal',
      Netherlands: 'Pays-Bas',
      'New Caledonia': 'Nouvelle-Calédonie',
      'New Zealand': 'Nouvelle-Zélande',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Île Norfolk',
      'Northern Mariana Islands': 'Îles Mariannes du Nord',
      Norway: 'Norvège',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palaos',
      Palestine: 'Palestine',
      Panama: 'Panama',
      'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
      Paraguay: 'Paraguay',
      Peru: 'Pérou',
      Philippines: 'Philippines',
      'Pitcairn Islands': 'Îles Pitcairn',
      Poland: 'Pologne',
      Portugal: 'Portugal',
      'Puerto Rico': 'Porto Rico',
      Qatar: 'Qatar',
      Romania: 'Roumanie',
      Russia: 'Russie',
      Rwanda: 'Rwanda',
      Réunion: 'Réunion',
      'Saint Barthélemy': 'Saint-Barthélemy',
      'Saint Helena': 'Sainte-Hélène',
      'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
      'Saint Lucia': 'Sainte-Lucie',
      'Saint Martin': 'Saint-Martin',
      'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
      'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
      Samoa: 'Samoa',
      'San Marino': 'Saint-Marin',
      'Sao Tome and Principe': 'Sao Tomé-et-Principe',
      Senegal: 'Sénégal',
      Serbia: 'Serbie',
      Seychelles: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapour',
      'Sint Maarten': 'Saint-Martin',
      Slovakia: 'Slovaquie',
      Slovenia: 'Slovénie',
      'Solomon Islands': 'Îles Salomon',
      Somalia: 'Somalie',
      'South Georgia and the South Sandwich Islands':
        'Géorgie du Sud-et-les Îles Sandwich du Sud',
      'South Sudan': 'Soudan du Sud',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Soudan',
      Suriname: 'Suriname',
      'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
      Swaziland: 'Eswatini',
      Sweden: 'Suède',
      Switzerland: 'Suisse',
      Syria: 'Syrie',
      Taiwan: 'Taïwan',
      Tajikistan: 'Tadjikistan',
      Tanzania: 'Tanzanie',
      Thailand: 'Thaïlande',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinité-et-Tobago',
      Tunisia: 'Tunisie',
      Turkey: 'Turquie',
      Turkmenistan: 'Turkménistan',
      Tuvalu: 'Tuvalu',
      Uganda: 'Ouganda',
      Ukraine: 'Ukraine',
      'United Kingdom': 'Royaume-Uni',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Ouzbékistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatican',
      Venezuela: 'Venezuela',
      Vietnam: 'Viêt Nam',
      'Western Sahara': 'Sahara occidental',
      Yemen: 'Yémen',
      Zambia: 'Zambie',
      Zimbabwe: 'Zimbabwe',
    };
    return translations[name] || name;
  }

  /* get company id from route */
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCompany = params.params['id'];
        this.getListRequestJob();
      },
    });
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') this.applyFilter();
      
      else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
        this.getListRequestJob();
      }
    }
  }
  /* sort data  */
  sortData(sortAttribute:string){
   this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
   this.sortAttr=sortAttribute
   this.currentPage = 1;
   this.endIndex=5;
   if(this.searchElement !='') this.listAppelOffer = this.sortDataService.sortArray(this.listAppelOffer, sortAttribute, this.currentSortDirection)
   else this.getListRequestJob()
  }
/* get all request job by final customer */
getListRequestJob(sort?:ISort){
  this.spinner.show()
  let dataPyload =this.sortAttr==''? {per_page: this.itemsPerPage, page: this.currentPage}:{ ...{per_page: this.itemsPerPage, page: this.currentPage}, ...{sort:this.currentSortDirection,sortAttribute:this.sortAttr} }
  this.companyService.getRequestJobClientFinal(this.idCompany,dataPyload).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next:(res)=>{

      this.spinner.hide()
      this.listAppelOffer=res.data.data
      console.log("list appel offre", this.listAppelOffer)
      this.totalItems=res.data.total
      if(this.currentPage>1 && !res.data.data.length){
        this.currentPage=1
        this.getListRequestJob()
      }
    },
    error:()=>{
      this.spinner.hide()
    }
  })
}
// /* create form to add request job */
createRequestFobForm(data?:any){
  return this.formBuilder.group({
      name: [data?data.name:'',[ Validators.required]],
      post_type: [data?data.post_type:null],
      country: [data?data.country:null, Validators.required],
      city: [data?data.city:null],
      availability: [data?data.availability:null],
      salary: [data?data.salary:''],
      years_of_experience: [data?data.years_of_experience:'', Validators.required],
      contract_type:[data?data.contract_type:null],
      tjm: [data?data.tjm:''],
      dedicated_to:[],
      profile_abroad: [data ? data.profile_abroad : ''],
      passeport_talent: [data ? data.passeport_talent : ''],
      otherSkills: [data ? data.otherSkills : []],
      description: [data ? data.description : ''],
      skills: [data ? data.skills : ''],
      client_id: [''],
    });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef!.hide();
    this.requestFobForm.reset();
  }
  /* add request job */
  addRequest() {
    console.log('requestFobForm', this.requestFobForm);
    this.requestFobForm.markAllAsTouched();
    this.requestFobForm.get('client_id')?.setValue(this.idCompany);
    console.log('dedicatedto', this.requestFobForm.value.dedicated_to);

    // Mettre à jour la valeur de dedicated_to si elle est true
    if (this.requestFobForm.value.dedicated_to === true) {
      this.requestFobForm.patchValue({
        dedicated_to: 1,
      });
    }

    let otherSkills = this.requestFobForm.get('otherSkills')?.value;
    console.log('otherSkills', otherSkills);
    const allSkills = [otherSkills];
    this.requestFobForm.patchValue({ otherSkills: allSkills });

    // Créer un objet contenant tous les attributs requis
    let requestFormData: any = {
      name: this.requestFobForm.value.name,
      country: this.requestFobForm.value.country,
      city: this.requestFobForm.value.city,
      years_of_experience: this.requestFobForm.value.years_of_experience,
      skills: this.requestFobForm.value.skills,
      client_id: this.requestFobForm.value.client_id,
      post_type: this.requestFobForm.value.post_type ? [] : null,
      availability: this.requestFobForm.value.availability || null,
      salary: this.requestFobForm.value.salary || null,
      contract_type: this.requestFobForm.value.contract_type || null,
      tjm: this.requestFobForm.value.tjm || null,
      profile_abroad: this.requestFobForm.value.profile_abroad || null,
      passeport_talent: this.requestFobForm.value.passeport_talent || null,
      description: this.requestFobForm.value.description || null,
      otherSkills: this.requestFobForm.value.otherSkills || null,
      dedicated_to: this.requestFobForm.value.dedicated_to || null,
    };
    console.log('other skills', requestFormData.otherSkills);
    if (requestFormData.otherSkills[0].length === 0) {
      requestFormData.otherSkills = null;
    }
    // Supprimer les attributs avec des valeurs nulles
    for (let key in requestFormData) {
      if (requestFormData[key] === null || requestFormData[key].length == 0) {
        delete requestFormData[key];
      }
    }

    console.log('form', requestFormData);

  if (this.requestFobForm.valid) {
    this.spinner.show();
    this.companyService.createRequestJob(requestFormData)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: () => {
          this.spinner.hide();
          this.toastr.success(addedJobRequest);
          this.closeModal();
          this.getListRequestJob();
        },
        error: (err) => {
          console.log("err", err);
          if (err?.error) {
            if (err.error?.message?.name) this.toastr.info(existedJobRequest);
            else this.toastr.error(serverError);
          }
          this.spinner.hide();
        }
      });
  }
}


/* key inter number */
keyPress(event:any){
  let pattern= /^[\d]$/
  let inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
    event.preventDefault();
  }
  return pattern;
}
/* change country select and delete the string Governorate from state name*/
onCountryChange(mode: string): void {
    
  this.requestFobForm.get('city')?.enable();
  this.countries = this.requestFobForm.get('country')?.value;
  this.countries = this.countries.map(
    (item) => this.paysList.filter((it) => it.name === item)[0]
  );
  this.stateList.length = 0;
  this.mobilities = this.requestFobForm.get('city')?.value;

  this.countries.forEach((item) => {
    var after:any[]=State.getStatesOfCountry(item?.isoCode)
    if(after.length==0)
    after.push({name:"Tout le pays"})
    this.stateList.push(after);
  });
  this.stateList = this.stateList.flat();
  for (const iterator of this.stateList) {
    iterator.name = iterator.name.replace(' Governorate', '');
  }
  this.stateList = this.stateList.map((item: any) => item.name);
  if (this.mobilities != null && this.mobilities.length > 0) {
    let differentItemsArray1: any[] = [];
    differentItemsArray1 = this.mobilities.filter(
      (item) => !this.stateList.includes(item)
    );
    var differentItemsArray2:any[]=[];
    differentItemsArray2= this.mobilities.filter((item) => !differentItemsArray1.includes(item));
    this.requestFobForm.get('city')?.setValue(differentItemsArray2);
  }
 
  if (this.stateList.length == 0) {
    this.stateList = [];
    mode == 'add';
    if(this.countries.length==0) {
      this.requestFobForm.get('city')?.disable();
      this.requestFobForm.get('city')?.setValue(null);
      }
  
  }
}
onCountryChangeSearch(mode: string): void {
    
  this.requestFobForm.get('city')?.enable();
  this.countries = this.requestFobForm.get('country')?.value;
  this.countries = this.countries.map(
    (item) => this.paysList.filter((it) => it.name === item)[0]
  );
  this.stateList.length = 0;
  this.mobilities = this.requestFobForm.get('city')?.value;

  this.countries.forEach((item) => {
    var after:any[]=State.getStatesOfCountry(item?.isoCode)
    if(after.length==0)
    after.push({name:"Tout le pays"})
    this.stateList.push(after);
  });
  this.stateList = this.stateList.flat();
  for (const iterator of this.stateList) {
    iterator.name = iterator.name.replace(' Governorate', '');
  }
  this.stateList = this.stateList.map((item: any) => item.name);
  if (this.mobilities != null && this.mobilities.length > 0) {
    let differentItemsArray1: any[] = [];
    differentItemsArray1 = this.mobilities.filter(
      (item) => !this.stateList.includes(item)
    );
    var differentItemsArray2:any[]=[];
    differentItemsArray2= this.mobilities.filter((item) => !differentItemsArray1.includes(item));
    this.requestFobForm.get('city')?.setValue(differentItemsArray2);
  }
 
  if (this.stateList.length == 0) {
    this.stateList = [];
    mode == 'add';
    if(this.countries.length==0) {
      this.requestFobForm.get('city')?.disable();
      this.requestFobForm.get('city')?.setValue(null);
      }
  
  }
}
onMobilityChange(mode: string) {
  this.mobilities = this.requestFobForm.get('city')?.value;
  let paysLists: any[] = [];
  this.countries = this.requestFobForm.get('country')?.value;
  this.countries = this.countries.filter(async (item: any) => {
    paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
  });
  let stateListAfter: any[] = [];
  paysLists.forEach((item) => {
    State.getStatesOfCountry(item.isoCode).forEach(item=> {
      stateListAfter.push(item.name);    ////  }
    })
  });
  console.log('stateList in change mobilities mobilities : ', this.mobilities);
  console.log('stateList in change mobilities stateListAfter  : ', stateListAfter);
  this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
  console.log('stateList in change mobilities : ', this.stateList);
  this.mobilities = this.requestFobForm.get('city')?.value;

  if (this.countries.length != 0) {
    if (this.mobilities.length === 0 && this.stateList.length === 0) {
      this.onCountryChange('add');
    }
  }
}


findDuplicates(array1: any, array2: any) {
  let occurrences: any[] = [];
  let duplicates: any = [];
  for (let element of array1) {
    occurrences[element] = (occurrences[element] || 0) + 1;
  }
  for (let element of array2) {
    if (occurrences[element] && !duplicates.includes(element)) {
      duplicates.push(element);
    }
  }
  return duplicates;
}
 /* search resquest job esn */
 applyFilter() {
  console.log("this.searchElement",this.searchElement)
  if(this.searchElement!='' || this.clickCount){
    this.clickCount++
  if(this.clickCount==1) this.currentPage=1
  let data ={
      name:this.searchElement,
      client_id:this.idCompany,
      // sort: this.currentSortDirection,
      // sortAttribute:'name',
      page:this.currentPage,
      per_page:this.itemsPerPage,
      search:"",
  }
  this.spinner.show()
  console.log("data",data)
  this.jobRequestService.searchRequestOffer(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next:(res)=>{
      console.log("res",res)
      if(!res.data.data.length && this.currentPage>1){
        this.currentPage=1
        this.applyFilter()
      }else{
        this.listAppelOffer = res.data.data.slice(0,this.endIndex)
      this.totalItems = res.data.total
      }
      this.spinner.hide()
    },
    error:()=>{
      this.spinner.hide()
this.toastr.error(serverError)
    }
  })
}
}

    goToDetails(id:string|undefined){
      this.router.navigate(['/acceuil/appel-offre/details-appel-offre', { id: id }])
    }
     /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  activerjobrequest(id: any) {
    Swal.fire({
      title: 'Voulez-vous vraiment activer cette appel d’offre ?',
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, activé !',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.jobRequestService.activedesactiveJobrequest(id).subscribe({
          next: (response: any) => {
            console.log('response', response);

            console.log('response.data', response.data);
            this.toastrService.success(
              'L’appel d’offre a été activé avec succès .'
            );
            this.getListRequestJob();
          },
          error: (error: any) => {
            console.error('Error fetching  job request: ', error);
          },
        });
      }
    });
  }

  desactiverjobrequest(id: any) {
    Swal.fire({
      title: 'Voulez-vous vraiment désactiver cette appel d’offre ?',
      // text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, désactivé !',
      cancelButtonText: 'Annuler ',
    }).then((result) => {
      if (result.isConfirmed) {
        this.jobRequestService.activedesactiveJobrequest(id).subscribe({
          next: (response: any) => {
            console.log('response', response);

            console.log('response.data', response.data);
            this.toastrService.success(
              'L’appel d’offre a été désactivé avec succès.'
            );
            this.getListRequestJob();
          },
          error: (error: any) => {
            console.error('Error fetching  job request: ', error);
          },
        });
      }
    });
  }
}
