import { Injectable } from '@angular/core';
import { CrudService } from '../crud/crud.service';
import { ApiResponse } from '../../models/response-api';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IFaq, IOffer, IQuestion } from '../../models/setting';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }
  fromCompany = new BehaviorSubject<boolean>(false);
  setFromCompany(value: boolean) {
    this.fromCompany.next(value);
  }
  getFromCompany(): Observable<boolean> {
    return this.fromCompany.asObservable();
  }
  /* *********************************** faq ******************************************* */
  /* get list of faq */
  getListFaq(data?: { per_page?: number; page?: number }) {
    console.log(
      'faq url',
      this._base +
        `/FAQ/getAllFAQswithResponse?per_page=${data?.per_page}&page=${data?.page}`
    );
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/FAQ/getAllFAQswithResponse?per_page=${data?.per_page}&page=${data?.page}`
    );
  }
  /* add faq */
  addFaq(data: IFaq) {
    return this._http.post<ApiResponse<any>>(this._base + `/FAQ/addFAQ`, data);
  }
  /* update faq */
  updateFaq(id: number | undefined, data: IFaq) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/FAQ/update/${id}`,
      data
    );
  }
  /* update faq */
  deleteFaq(id: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/FAQ/delete/${id}`
    );
  }
  /* search list FAQ*/
  searchListFAQ(data: any) {
    console.log('search faq', this._base + `/FAQ/filterFAQ`, data);
    return this._http.post<ApiResponse<any>>(
      this._base + `/FAQ/filterFAQ`,
      data
    );
  }

  /* *********************************** tuto ******************************************* */
  /* get list of tuto */
  getListTuto(data?: { per_page?: number; page?: number }) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Tuto/getAll?per_page=${data?.per_page}&page=${data?.page}`
    );
  }

  /* search list tuto*/
  searchListTuto(data: any) {
    return this._http.post<ApiResponse<any>>(this._base + `/Tuto/filter`, data);
  }

/* add tuto */
addTuto(data:any){
  return this._http.post<ApiResponse<any>>(this._base+`/Tuto/add`,data);
}
/* update tuto */
updateTuto(id:number|undefined,data:any){
  return this._http.post<ApiResponse<any>>(this._base+`/Tuto/update/${id}`,data);
}
/* update tuto */
deleteTuto(id:number){
  return this._http.delete<ApiResponse<any>>(this._base+`/Tuto/delete/${id}`);
}
/* ************************************** rate wegestu ***************************************** */
/* get list of question wegestu*/
getListQuestion(idCompany:number,data?:{per_page?:number,page?:number}){
  console.log("url question",this._base+`/ResponseEvaluateWegestu/getResponsesEvaluateWithStarsWithUsers?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`)
  return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getResponsesEvaluateWithStarsWithUsers?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
}

getListQuestioncompany(idCompany:number,data?:{per_page?:number,page?:number}){
  return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getResponsesEvaluateWithStarsWithUsersByCompany?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
}

getListQuestioncandidat(idCompany:number,data?:{per_page?:number,page?:number}){
  return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getResponsesEvaluateWithStarsWithUsersByCandidat?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
}
/* get list comment rating  wegestu*/
getListComment(idCompany:number,data?:{per_page?:number,page?:number}){
  return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getResponsesEvaluateWithCommentWithUsers?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
}
/* get list candidat rating  wegestu*/
getListCandidatReview(idCompany:number,data?:{per_page?:number,page?:number}){
  console.log("url",this._base+`/ResponseEvaluateWegestu/getUsersAvisWegestu?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`)
  return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getUsersAvisWegestu?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
}


getListusersReviewCandidat(idCompany:number,data?:{per_page?:number,page?:number}){
  return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getUsersAvisWegestuByCandidate?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
}

getListusersReviewCompany(idCompany:number,data?:{per_page?:number,page?:number}){
  return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getUsersAvisWegestuByCompany?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
}



  /* get candidat rating  wegestu by id*/
  getCandidatReviewById(
    idCompany: number,
    idUser: number,
    data?: { per_page?: number; page?: number }
  ) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseEvaluateWegestu/getUsersAvisWegestuWithResponses?type=${idCompany}&user_id=${idUser}&per_page=${data?.per_page}&page=${data?.page}`
    );
  }

   /* get candidat rating  wegestu by id*/
   getCompanyReviewById(
    idCompany: number,
    idUser: number,
    data?: { per_page?: number; page?: number }
  ) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseEvaluateWegestu/getResponsesEvaluateWithStarsWithUsersByCompany?type=${idCompany}&user_id=${idUser}&per_page=${data?.per_page}&page=${data?.page}`
    );
  } 

  /* add question wegestu */
  addQuestion(data: IQuestion) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/EvaluateWegestu/add`,
      data
    );
  }
  /* update question wegestu */
  updateQuestion(id: number | undefined, data: IQuestion) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/EvaluateWegestu/update/${id}`,
      data
    );
  }
  /* delete question wegestu */
  deleteQuestion(id: number | undefined) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/EvaluateWegestu/delete/${id}`
    );
  }
  /* ************************************** default rate  ***************************************** */
  /* get list of evaluation Default */
  getListEvaluationDefault() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/DefaultReview/listDefaultReviewswithQuestion`
    );
  }
  /* add evaluation Default  */
  addEvaluationDefault(data: IQuestion) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/DefaultReview/add`,
      data
    );
  }
  /* update evaluation Default   */
  updateEvaluationDefault(id: number | undefined, data: IQuestion) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/DefaultReview/update/${id}`,
      data
    );
  }
  /* delete evaluation Default  */
  deleteEvaluationDefault(id: number | undefined) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/DefaultReview/delete/${id}`
    );
  }
  /* get list question of evaluation Default */
  getListQuestionEvaluationDefault(idEvaluation: number) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/QuestionDefaultEvaluation/getAllQuestionsByEvaluation?id=${idEvaluation}`
    );
  }
  /* add question to evaluation Default */
  addQuestionEvaluation(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/QuestionDefaultEvaluation/add`,
      data
    );
  }
  /* update question to evaluation Default */
  updateQuestionEvaluation(data: any, idQuestion: number | undefined) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/QuestionDefaultEvaluation/update/${idQuestion}`,
      data
    );
  }
  /* delete question to evaluation Default */
  deleteQuestionEvaluation(idQuestion: number | undefined) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/QuestionDefaultEvaluation/delete/${idQuestion}`
    );
  }
  /* **************************************  rate company  ***************************************** */
  /* get list of question Default rate*/
  getListQuestionCompany(type: number) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/SpecificReview/getbyCompany/${type}`
    );
  }
  /* add question Company rate */
  addQuestionCompany(data: IQuestion) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/SpecificReview/add`,
      data
    );
  }
  /* update question Company rate  */
  updateQuestionCompany(id: number | undefined, data: IQuestion) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/SpecificReview/update/${id}`,
      data
    );
  }
  /* delete question Company rate */
  deleteQuestionCompany(id: number | undefined) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/SpecificReview/delete/${id}`
    );
  }
  /* ****************************************************** get companies ************* */
  /* get company by type */
  getListCompany(data?: {
    per_page?: number;
    page?: number;
    sort?: number;
    type?: number;
  }) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Company/getListWithDetailsByType?per_page=${data?.per_page}&page=${data?.page}&sort=${data?.sort}&type=${data?.type}&sortAttribute=name`
    );
  }
  /* get specific review list question */
  getSpecificReview(data: any) {
    return this._http.get<any>(
      this._base +
        `/ResponseSpecificReview/getResponseSpecificReviewWithStarsWithUsers`
    );
  }
  /* get specific review list comments */
  getSpecificReviewComments(
    idCompany: number,
    data?: { per_page?: number; page?: number }
  ) {
    return this._http.get<any>(
      this._base +
        `/ResponseSpecificReview/getResponseSpecificReviewsWithComment?company_id=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`
    );
  }
  /* **************************** offer ************************* */
  /* get list offer */
  getListOffer(data?: { per_page?: number; page?: number }) {
    return this._http.get<ApiResponse<any>>(this._base + `/Offer/getAll?per_page=${data?.per_page}&page=${data?.page}`);
  }
  /* get offer by id */
  getOffer(id: number | undefined) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Offer/getById/${id}`
    );
  }

  /* add offer */
  addOffer(data: IOffer) {
    return this._http.post<ApiResponse<any>>(this._base + `/Offer/add`, data);
  }
  /* update offer */
  updateOffer(idOffer: number | undefined, data: IOffer) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Offer/update/${idOffer}`,
      data
    );
  }
  /* delete offer */
  deleteOffer(idOffer: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/Offer/delete/${idOffer}`
    );
  }
  /* search offer */
  searchOffre(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Offer/searchOffer`,
      data
    );
  }

  /* active / inactive offer */
  activeDesactiveOffer(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Offer/ActivateDesactivate?offer_id=${id}`
    );
  }
  /* get list candidature by offer */
  listCandidatureByOffer(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/AbonnementCompany/getListAbonnementParOffer?offer_id=${id}`
    );
  }

  /* search Candidature */
  searchCandidature(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/AbonnementCompany/searchInListAbonnementParOffer`,
      data
    );
  }
  /* get list candidature by esn */
  getListAbonnementActuelByESN(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/AbonnementCompany/getListAbonnementActuelByESNForAdmin?esn_id=${id}`
    );
  }
  /*Export invoice */
  // exportInvoice(id: number | undefined) {
  //   return this._http.get(
  //     this._base + `/AbonnementCompany/export-facture/${id}`,
  //     {
  //       responseType: 'arraybuffer',
  //     }
  //   );
  // }
  exportInvoice(file: any, data: any) {
    return this._http.get(
      this._base + `/Payment/invoiceDownload?invoice_id=${data}`,
      {
        responseType: 'arraybuffer',
      }
    );
  }
  searchInvoice(data: any, idEsn: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Payment/searchIninvoicesStripe?subscription_id=${idEsn}`,
      data
    );
  }
  getAllDetailsOfInvoice(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Payment/invoices?subscription_id=${id}`
    );
  }
  /* get famille */
  listFamille(data?: {
    per_page?: number;
    page?: number;
    sort?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/FamilleDefaultEvaluation/getAll?per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
  }
  listFamilleaddquestion()
  {
  return this._http.get<ApiResponse<any>>(this._base + `/FamilleDefaultEvaluation/getAllSansPaginate`);
  }

  /* create fournisseur */
  createfamilleevaluation(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/FamilleDefaultEvaluation/add`,
      data
    );
  }

  deletefamille(id: any): Observable<any> {
    const url = `${this._base}/FamilleDefaultEvaluation/delete/${id}`; // Assurez-vous d'utiliser la route correcte de mise à jour
    return this._http.delete(url);
  }
  updateFamille(id: number, updatedData: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/FamilleDefaultEvaluation/update/${id}`,
      updatedData
    );
  }

  /* searchfamille */
  searchfamille(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/FamilleDefaultEvaluation/search`,
      data
    );
  }



  /////abonnement///////////////
  getListAbonnement(companyId: any) {
    return this._http.get<ApiResponse<any>>(
      `${this._base}/AbonnementCompany/getListSubscriptionHistoryByESN?esn_id=${companyId}`
    );
  }
  searchAbonnement(data: any, idEsn: any) {
    return this._http.post<ApiResponse<any>>(
      this._base +
        `/AbonnementCompany/searchInListAbonnementActuelByESN?esn_id=${idEsn}`,
      data
    );
  }

  
}
