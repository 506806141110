import { LocalStorageService } from './../../../../core/services/Local-storage/local-storage.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { CChangePassoword } from './../../../../core/models/login';
import {
  changePasswordSuccess,
  incorrectOldPassword,
  passwordsCompare,
  serverError,
} from './../../../../core/models/messages';
import { MustMatch } from './../../../../core/models/must-match-validator';
import { patternPassword } from './../../../../core/models/pattern';
import { AuthService } from './../../../../core/services/authentication/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent {
  /* boolean */
  loadSpinner: boolean = false;
  /* formGroup */
  changePasswordForm: FormGroup = this.createChangePasswordForm(
    new CChangePassoword()
  );
  userEmail!: string;
  showPassword: string = 'password';
  showNewPassword: string = 'password';
  showConfirmPassword: string = 'password';
  passwordsCompareMessage: string = '';
  currentUser!: any;
  email_user!: string | null;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    console.log('i am in change password');
    this.email_user = this.localStorageService.getData('email');
    console.log('all email', this.email_user);
  }
  /* create resetPassword form */
  createChangePasswordForm(data: CChangePassoword) {
    return this.formBuilder.group(
      {
        oldPassword: [
          data.oldPassword,
          [Validators.required, Validators.pattern(patternPassword)],
        ],
        newPassword: [
          data.newPassword,
          [Validators.required, Validators.pattern(patternPassword)],
        ],
        confirmNewPassword: [
          data.confirmNewPassword,
          [Validators.required, Validators.pattern(patternPassword)],
        ],
      },
      {
        validator: MustMatch('newPassword', 'confirmNewPassword'),
      }
    );
  }
  /* reset password */
  changePassword() {
    console.log('i am here');
    this.passwordsCompareMessage = '';
    if (this.changePasswordForm.valid) {
      if (
        this.changePasswordForm.get('oldPassword')?.value ==
        this.changePasswordForm.get('newPassword')?.value
      ) {
        this.passwordsCompareMessage = passwordsCompare;
        this.toastrService.error(passwordsCompare);
      } else {
        const data = {
          oldPassword: this.changePasswordForm.get('oldPassword')?.value,
          newPassword: this.changePasswordForm.get('newPassword')?.value,
          email: this.email_user,
        };
        this.loadSpinner = true;
        this.authService
          .changePassword(data)
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe({
            next: (res) => {
              if (res.status == 200) {
                this.loadSpinner = false;
                this.toastrService.success(changePasswordSuccess);
                this.authService.logOut();
              }
            },
            error: (err) => {
              this.loadSpinner = false;
              if (err?.error) {
                if (err.error.message == 'Old password is incorrect')
                  this.toastrService.error(incorrectOldPassword);
                else this.toastrService.error(serverError);
              }
            },
          });
      }
    }
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
