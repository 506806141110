<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading"><svg width="36" height="38" viewBox="0 0 36 38" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_373_934)">
                            <path
                                d="M4.60489 6.04201H8.77521C9.1081 6.04201 9.38048 5.76959 9.38048 5.43673V1.26638C9.38048 1.02433 9.23522 0.800305 9.00522 0.709573C8.78127 0.612708 8.521 0.667162 8.34549 0.836657L4.18119 5.00701C4.00564 5.18249 3.95118 5.44279 4.04801 5.66674C4.13882 5.89675 4.36276 6.04201 4.60489 6.04201Z"
                                fill="#265D91" />
                            <path
                                d="M12.2618 21.9129V18.9894C12.2618 17.9881 13.0763 17.1736 14.0776 17.1736H14.8236L14.2934 16.6434C13.9505 16.3017 13.7614 15.846 13.7614 15.3607C13.7614 14.8754 13.9505 14.4197 14.2946 14.0769L16.3634 12.008C16.6719 11.68 17.1472 11.4778 17.6431 11.4778C18.1301 11.4778 18.587 11.6664 18.9287 12.008L19.4589 12.5383V11.7923C19.4589 10.791 20.2734 9.97647 21.2747 9.97647H23.3385V1.26677C23.3385 0.933843 23.0722 0.661499 22.7332 0.661499H10.486C10.5522 0.851977 10.5914 1.05502 10.5914 1.26677V5.43691C10.5914 6.43821 9.77686 7.25273 8.77556 7.25273H4.60542C4.39378 7.25273 4.19081 7.21343 4 7.14648V25.6473C4 25.9802 4.27234 26.2526 4.60528 26.2526H13.9076C13.8127 26.0304 13.7614 25.7902 13.7614 25.5416C13.7614 25.0563 13.9505 24.6006 14.2946 24.2578L14.823 23.7287H14.0776C13.0763 23.7287 12.2618 22.9142 12.2618 21.9129ZM10.6599 21.5943H7.63357C7.29902 21.5943 7.0283 21.3236 7.0283 20.989C7.0283 20.6545 7.29902 20.3838 7.63357 20.3838H10.6599C10.9945 20.3838 11.2652 20.6545 11.2652 20.989C11.2652 21.3236 10.9945 21.5943 10.6599 21.5943ZM10.6599 17.9627H7.63357C7.29902 17.9627 7.0283 17.692 7.0283 17.3574C7.0283 17.0228 7.29902 16.7521 7.63357 16.7521H10.6599C10.9945 16.7521 11.2652 17.0228 11.2652 17.3574C11.2652 17.692 10.9945 17.9627 10.6599 17.9627ZM10.6599 14.331H7.63357C7.29902 14.331 7.0283 14.0603 7.0283 13.7257C7.0283 13.3912 7.29902 13.1205 7.63357 13.1205H10.6599C10.9945 13.1205 11.2652 13.3912 11.2652 13.7257C11.2652 14.0603 10.9945 14.331 10.6599 14.331ZM10.6599 10.6994H7.63357C7.29902 10.6994 7.0283 10.4287 7.0283 10.0941C7.0283 9.75954 7.29902 9.48882 7.63357 9.48882H10.6599C10.9945 9.48882 11.2652 9.75954 11.2652 10.0941C11.2652 10.4287 10.9945 10.6994 10.6599 10.6994Z"
                                fill="#265D91" />
                            <path
                                d="M31.3948 18.384H29.0706C28.9617 18.0572 28.8285 17.7364 28.6772 17.4337L30.3175 15.7874C30.5596 15.5514 30.5596 15.17 30.3175 14.934L28.2535 12.8639C28.0174 12.6279 27.6361 12.6279 27.4 12.8639L25.7537 14.5103C25.445 14.3529 25.1303 14.2258 24.8034 14.1168V11.7926C24.8034 11.4537 24.531 11.1873 24.1982 11.1873H21.2747C20.9418 11.1873 20.6694 11.4537 20.6694 11.7926V14.1168C20.3426 14.2258 20.0278 14.3529 19.7191 14.5103L18.0728 12.8639C17.9578 12.7489 17.8064 12.6884 17.643 12.6884C17.4856 12.6884 17.3283 12.7489 17.2193 12.8639L15.1493 14.934C14.9132 15.17 14.9132 15.5514 15.1493 15.7874L16.7956 17.4337C16.6382 17.7364 16.5111 18.0572 16.4022 18.384H14.0779C13.739 18.384 13.4727 18.6564 13.4727 18.9893V21.9128C13.4727 22.2457 13.739 22.5181 14.0779 22.5181H16.4022C16.5111 22.8449 16.6382 23.1597 16.7956 23.4684L15.1493 25.1147C14.9132 25.3508 14.9132 25.7321 15.1493 25.9682L17.2193 28.0321C17.4554 28.2742 17.8367 28.2742 18.0728 28.0321L19.7191 26.3918C20.0278 26.5431 20.3426 26.6763 20.6694 26.7853V29.1095C20.6694 29.4424 20.9418 29.7148 21.2747 29.7148H24.1982C24.531 29.7148 24.8034 29.4424 24.8034 29.1095V26.7853C25.1303 26.6763 25.445 26.5431 25.7537 26.3918L27.4 28.0382C27.509 28.1471 27.6664 28.2137 27.8237 28.2137C27.9871 28.2137 28.1385 28.1471 28.2535 28.0382L30.3175 25.9682C30.5596 25.7321 30.5596 25.3508 30.3175 25.1147L28.6772 23.4684C28.8285 23.1597 28.9617 22.8449 29.0706 22.5181H31.3948C31.7278 22.5181 32.0001 22.2457 32.0001 21.9128V18.9893C32.0001 18.6564 31.7278 18.384 31.3948 18.384ZM22.7334 24.3097C20.6088 24.3097 18.8778 22.5786 18.8778 20.448C18.8778 18.3235 20.6088 16.5925 22.7334 16.5925C24.8639 16.5925 26.595 18.3235 26.595 20.448C26.595 22.5786 24.8639 24.3097 22.7334 24.3097Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_373_934" x="0" y="0.661499" width="36" height="37.0533"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_373_934" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_373_934"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>

                    Profil 
                </h1>
              
      
                <!--begin::Menu-->
                <div class="menu menu-sub menu-sub-dropdown menu-column style-menu menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                    data-kt-menu="true">
                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                        <a href="#" data-bs-toggle="modal" data-bs-target="#kt_customers_export_modal"
                            class="menu-link px-3">Ajout unitaire</a>
                    </div>
                    <!--end::Menu item-->
                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3" data-kt-customer-table-filter="delete_row">Ajout en masse</a>
                    </div>
                    <!--end::Menu item-->
                </div>
            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a  class="text-muted"  routerLink="/">Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Profil</li>
                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>

    
    <div id="kt_app_content" class="app-content flex-column-fluid mb-4">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container container-fluid">
            <!--begin::Card-->
    
            
    
            <div class="cardds mb-10 pt-15">
    <div class="edit cursor-pointer"(click)="openModal(modifier,currentUser)">
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 20.0047V7.14453C0 6.38659 0.302926 5.6597 0.842137 5.12375C1.38135 4.58781 2.11268 4.28672 2.87524 4.28672H11.6663L8.83776 7.14453H2.87524V20.0047H15.8138V14.0819L18.689 11.2705V20.0047C18.689 20.7626 18.3861 21.4895 17.8469 22.0255C17.3077 22.5614 16.5764 22.8625 15.8138 22.8625H2.87524C2.11268 22.8625 1.38135 22.5614 0.842137 22.0255C0.302926 21.4895 0 20.7626 0 20.0047ZM20.8455 0C20.2737 0.000500266 19.7256 0.226646 19.3216 0.628719L9.10372 10.949C8.7893 11.2667 8.55205 11.6516 8.41007 12.0743L7.37498 15.1643C7.36205 15.2137 7.36234 15.2656 7.37582 15.3148C7.38929 15.3641 7.41549 15.409 7.45181 15.4451C7.48813 15.4812 7.53332 15.5073 7.58288 15.5207C7.63245 15.5341 7.68468 15.5343 7.73439 15.5215L10.854 14.5034C11.2794 14.3624 11.6666 14.1266 11.9861 13.8139L22.3693 3.658C22.6705 3.35825 22.8756 2.97651 22.9587 2.56099C23.0417 2.14547 22.999 1.7148 22.836 1.32339C22.6729 0.93197 22.3968 0.597361 22.0425 0.361821C21.6882 0.12628 21.2717 0.000372623 20.8455 0Z" fill="#265D91"/>
            </svg>
            
        </div>
                <!--begin::Card header-->
                <div class="text-center" >
                    <div *ngIf="image_url" >

                        <img  src="{{ url+'/User/file/gallery_users/' + userForm.get('image_url')!.value }}" alt="" width="250px" class="admin-pic"  >
                    </div>
                
                    <!-- <img *ngIf="!image_url || image_url ===  ''" src="/assets/media/logos/logo-avatar.jpg" alt="" width="250px" class="admin-pic"> -->
                </div>
                <div class="nom text-center mt-10">
                    <span>{{first_name}}  {{last_name}}</span>
                </div>
                <div class="card-body pt-15 ">
                    <div class="content" id="info-bloc">
                        <div class="card-body pt-3">
                            <!--begin::Table-->
                            <div class="contact-info pb-6 fs-6 inputs">

                                <div class="row mb-10">
                                    <div class="col-lg-6 col-3" style="margin: auto;">
                                        <div class="contact-info-name "><svg class="mr-2" width="26" height="17"
                                            viewBox="0 0 26 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.00143 6.82488C7.89531 6.82488 9.4306 5.29708 9.4306 3.41244C9.4306 1.5278 7.89531 0 6.00143 0C4.10756 0 2.57227 1.5278 2.57227 3.41244C2.57227 5.29708 4.10756 6.82488 6.00143 6.82488Z"
                                                fill="#265D91" />
                                            <path
                                                d="M7.71563 7.67798H4.28646C3.72355 7.67798 3.16616 7.78831 2.6461 8.00267C2.12604 8.21704 1.65351 8.53124 1.25547 8.92733C0.451608 9.72727 0 10.8122 0 11.9435V15.356C0 15.5822 0.0903215 15.7992 0.251095 15.9592C0.411868 16.1192 0.629924 16.2091 0.857292 16.2091H11.1448C11.3722 16.2091 11.5902 16.1192 11.751 15.9592C11.9118 15.7992 12.0021 15.5822 12.0021 15.356V11.9435C12.0021 10.8122 11.5505 9.72727 10.7466 8.92733C9.94274 8.12738 8.85246 7.67798 7.71563 7.67798Z"
                                                fill="#265D91" />
                                            <path
                                                d="M24.8616 6.82487H14.5741C14.3467 6.82487 14.1287 6.73499 13.9679 6.575C13.8071 6.41501 13.7168 6.19802 13.7168 5.97176C13.7168 5.7455 13.8071 5.52851 13.9679 5.36852C14.1287 5.20853 14.3467 5.11865 14.5741 5.11865H24.8616C25.089 5.11865 25.307 5.20853 25.4678 5.36852C25.6286 5.52851 25.7189 5.7455 25.7189 5.97176C25.7189 6.19802 25.6286 6.41501 25.4678 6.575C25.307 6.73499 25.089 6.82487 24.8616 6.82487Z"
                                                fill="#265D91" />
                                            <path
                                                d="M24.8616 10.2372H14.5741C14.3467 10.2372 14.1287 10.1473 13.9679 9.98736C13.8071 9.82737 13.7168 9.61037 13.7168 9.38412C13.7168 9.15786 13.8071 8.94086 13.9679 8.78088C14.1287 8.62089 14.3467 8.53101 14.5741 8.53101H24.8616C25.089 8.53101 25.307 8.62089 25.4678 8.78088C25.6286 8.94086 25.7189 9.15786 25.7189 9.38412C25.7189 9.61037 25.6286 9.82737 25.4678 9.98736C25.307 10.1473 25.089 10.2372 24.8616 10.2372Z"
                                                fill="#265D91" />
                                            <path
                                                d="M19.7178 13.6496H14.5741C14.3467 13.6496 14.1287 13.5597 13.9679 13.3997C13.8071 13.2397 13.7168 13.0227 13.7168 12.7965C13.7168 12.5702 13.8071 12.3532 13.9679 12.1932C14.1287 12.0332 14.3467 11.9434 14.5741 11.9434H19.7178C19.9452 11.9434 20.1633 12.0332 20.324 12.1932C20.4848 12.3532 20.5751 12.5702 20.5751 12.7965C20.5751 13.0227 20.4848 13.2397 20.324 13.3997C20.1633 13.5597 19.9452 13.6496 19.7178 13.6496Z"
                                                fill="#265D91" />
                                        </svg>
                                        Prénom</div>
                                        <div class="contact-info-name "><svg class="mr-2" width="26" height="17"
                                            viewBox="0 0 26 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.00143 6.82488C7.89531 6.82488 9.4306 5.29708 9.4306 3.41244C9.4306 1.5278 7.89531 0 6.00143 0C4.10756 0 2.57227 1.5278 2.57227 3.41244C2.57227 5.29708 4.10756 6.82488 6.00143 6.82488Z"
                                                fill="#265D91" />
                                            <path
                                                d="M7.71563 7.67798H4.28646C3.72355 7.67798 3.16616 7.78831 2.6461 8.00267C2.12604 8.21704 1.65351 8.53124 1.25547 8.92733C0.451608 9.72727 0 10.8122 0 11.9435V15.356C0 15.5822 0.0903215 15.7992 0.251095 15.9592C0.411868 16.1192 0.629924 16.2091 0.857292 16.2091H11.1448C11.3722 16.2091 11.5902 16.1192 11.751 15.9592C11.9118 15.7992 12.0021 15.5822 12.0021 15.356V11.9435C12.0021 10.8122 11.5505 9.72727 10.7466 8.92733C9.94274 8.12738 8.85246 7.67798 7.71563 7.67798Z"
                                                fill="#265D91" />
                                            <path
                                                d="M24.8616 6.82487H14.5741C14.3467 6.82487 14.1287 6.73499 13.9679 6.575C13.8071 6.41501 13.7168 6.19802 13.7168 5.97176C13.7168 5.7455 13.8071 5.52851 13.9679 5.36852C14.1287 5.20853 14.3467 5.11865 14.5741 5.11865H24.8616C25.089 5.11865 25.307 5.20853 25.4678 5.36852C25.6286 5.52851 25.7189 5.7455 25.7189 5.97176C25.7189 6.19802 25.6286 6.41501 25.4678 6.575C25.307 6.73499 25.089 6.82487 24.8616 6.82487Z"
                                                fill="#265D91" />
                                            <path
                                                d="M24.8616 10.2372H14.5741C14.3467 10.2372 14.1287 10.1473 13.9679 9.98736C13.8071 9.82737 13.7168 9.61037 13.7168 9.38412C13.7168 9.15786 13.8071 8.94086 13.9679 8.78088C14.1287 8.62089 14.3467 8.53101 14.5741 8.53101H24.8616C25.089 8.53101 25.307 8.62089 25.4678 8.78088C25.6286 8.94086 25.7189 9.15786 25.7189 9.38412C25.7189 9.61037 25.6286 9.82737 25.4678 9.98736C25.307 10.1473 25.089 10.2372 24.8616 10.2372Z"
                                                fill="#265D91" />
                                            <path
                                                d="M19.7178 13.6496H14.5741C14.3467 13.6496 14.1287 13.5597 13.9679 13.3997C13.8071 13.2397 13.7168 13.0227 13.7168 12.7965C13.7168 12.5702 13.8071 12.3532 13.9679 12.1932C14.1287 12.0332 14.3467 11.9434 14.5741 11.9434H19.7178C19.9452 11.9434 20.1633 12.0332 20.324 12.1932C20.4848 12.3532 20.5751 12.5702 20.5751 12.7965C20.5751 13.0227 20.4848 13.2397 20.324 13.3997C20.1633 13.5597 19.9452 13.6496 19.7178 13.6496Z"
                                                fill="#265D91" />
                                        </svg>
                                        Nom</div>
                                        <div class="contact-info-name "><svg width="26" height="17" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.07977 0H24.1674C24.9016 0 25.5712 0.300084 26.0579 0.784965C26.5437 1.26985 26.844 1.93613 26.844 2.66769V14.3972C26.844 15.1313 26.5428 15.7985 26.0579 16.2817L26.0323 16.3054C25.5482 16.775 24.8897 17.0649 24.1666 17.0649H3.07892C2.34384 17.0649 1.67427 16.7648 1.18932 16.2808C0.703523 15.7985 0.402344 15.1313 0.402344 14.3972V2.66769C0.402344 1.93613 0.703523 1.269 1.19017 0.784117C1.67682 0.300084 2.34554 0.000847875 3.07977 0.000847875V0ZM3.62002 2.25656L3.16485 2.97117L13.6236 9.59929L24.0815 2.97117L23.6263 2.25656L13.6227 8.59562L3.61917 2.25656H3.62002Z" fill="#265D91"/>
                                            </svg>
                                            
                                            Email</div>
                                            <div class="contact-info-name "><svg width="26" height="17" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.4097 18.348C18.9494 16.9539 18.1208 15.7634 16.9496 14.8115C16.4982 14.4427 16.0501 14.2261 15.5807 14.1499C15.3983 14.1203 15.2113 14.1126 15.0242 14.1261C14.4042 14.1739 13.7857 14.359 13.1337 14.6927C12.9283 14.799 12.6919 14.8305 12.4324 14.7885C12.1157 14.7372 11.7914 14.5734 11.5855 14.3609C10.0904 12.8179 8.88212 11.1418 7.89106 9.23772C7.69678 8.86288 7.61679 8.10463 8.06625 7.66625C8.59098 7.15551 8.96321 6.62874 9.20537 6.05611C9.46525 5.43902 9.45224 4.77192 9.16525 4.01865C8.63221 2.60601 7.76403 1.44475 6.58588 0.565786C6.20423 0.280718 5.79574 0.101169 5.37037 0.0318354C4.66021 -0.0836283 3.94507 0.116361 3.30272 0.60943C2.7124 1.06189 2.17549 1.55551 1.55085 2.15714C0.978522 2.70794 0.89937 3.43857 0.829074 4.08246L0.827137 4.09599C0.753243 4.91612 0.899646 5.9238 1.27631 7.17733C1.91313 9.29849 2.96784 11.3857 4.50246 13.5588C4.81768 14.0057 6.07914 15.7551 6.40377 16.1954C7.98156 18.3375 9.62992 19.9993 11.4427 21.2744C12.5143 22.0288 13.4251 22.4876 14.2274 22.6776L14.241 22.6801C14.4065 22.716 14.5839 22.7539 14.7577 22.7821C15.1864 22.8519 15.7487 22.895 16.2995 22.6086C17.0697 22.2081 17.7093 21.8564 18.3245 21.4423C19.4061 20.7172 19.8112 19.5601 19.4097 18.348ZM5.41105 4.1993C4.59379 3.06593 4.35412 1.84335 4.8758 1.46878C5.39749 1.09394 6.48292 1.7091 7.30019 2.84247C8.11745 3.97583 8.35712 5.19842 7.83571 5.57299C7.31375 5.94783 6.22831 5.33267 5.41105 4.1993ZM18.1147 19.9239C17.593 20.2988 16.5076 19.6836 15.6903 18.5502C14.8731 17.4169 14.6334 16.1943 15.1548 15.8197C15.6762 15.4451 16.7619 16.06 17.5792 17.1934C18.3967 18.3268 18.6364 19.5493 18.1147 19.9239Z" fill="#265D91"/>
                                                </svg>
                                                
                                                Téléphone</div>
                                                <div class="contact-info-name "><svg width="26" height="17" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.0255 6.30374V2.11006H2.00658V1.51096C2.00658 1.18146 1.73615 0.911865 1.40563 0.911865C1.07511 0.911865 0.804688 1.18146 0.804688 1.51096V30.2676C0.804688 30.5971 1.07511 30.8667 1.40563 30.8667C1.73615 30.8667 2.00658 30.5971 2.00658 30.2676V14.092H7.4151H14.0255V6.30374ZM5.43798 9.12549C5.3238 9.23932 5.17357 9.29923 5.01131 9.29923C4.84906 9.29923 4.69882 9.23333 4.58464 9.12549C4.47046 9.01166 4.41037 8.86189 4.41037 8.70013C4.41037 8.53837 4.47647 8.3886 4.58464 8.27477C4.69281 8.16094 4.84906 8.10103 5.01131 8.10103C5.17357 8.10103 5.3238 8.16693 5.43798 8.27477C5.55216 8.38261 5.61226 8.53837 5.61226 8.70013C5.61226 8.86189 5.54615 9.01166 5.43798 9.12549ZM5.43798 5.53091C5.3238 5.64473 5.17357 5.70464 5.01131 5.70464C4.84906 5.70464 4.69882 5.63874 4.58464 5.53091C4.47046 5.42307 4.41037 5.26131 4.41037 5.10555C4.41037 4.94978 4.47647 4.79402 4.58464 4.68019C4.69281 4.56636 4.84906 4.50645 5.01131 4.50645C5.17357 4.50645 5.3238 4.57235 5.43798 4.68019C5.55216 4.78802 5.61226 4.94379 5.61226 5.10555C5.61226 5.2673 5.54615 5.41708 5.43798 5.53091Z" fill="#265D91"/>
                                                    <path d="M8.01562 15.2903V18.3637L12.6429 15.2903H8.01562Z" fill="#265D91"/>
                                                    <path d="M15.2237 6.90283V14.6911V14.6971C15.2237 14.7151 15.2237 14.727 15.2237 14.745C15.2237 14.751 15.2237 14.751 15.2237 14.751C15.2237 14.769 15.2177 14.787 15.2177 14.8049C15.2177 14.8109 15.2117 14.8169 15.2117 14.8229C15.2117 14.8349 15.2057 14.8409 15.2057 14.8529C15.2057 14.8588 15.1997 14.8708 15.1997 14.8768C15.1937 14.8888 15.1937 14.8948 15.1877 14.9068C15.1816 14.9128 15.1816 14.9247 15.1756 14.9307C15.1696 14.9427 15.1636 14.9487 15.1636 14.9607C15.1636 14.9667 15.1576 14.9727 15.1576 14.9787C15.1516 14.9966 15.1396 15.0086 15.1336 15.0206C15.1336 15.0266 15.1276 15.0266 15.1215 15.0326C15.1155 15.0446 15.1095 15.0506 15.0975 15.0625C15.0915 15.0685 15.0915 15.0745 15.0855 15.0745C15.0795 15.0865 15.0675 15.0925 15.0615 15.0985C15.0554 15.1045 15.0554 15.1045 15.0494 15.1105C15.0434 15.1165 15.0314 15.1284 15.0254 15.1344C15.0194 15.1404 15.0134 15.1404 15.0134 15.1464C15.0014 15.1524 14.9954 15.1644 14.9833 15.1704C14.9773 15.1764 14.9773 15.1764 14.9713 15.1824L9.39453 18.8848H27.2426V6.90283H15.2237ZM24.6646 16.3147C24.5504 16.4285 24.4002 16.4884 24.2379 16.4884C24.0757 16.4884 23.9254 16.4225 23.8112 16.3147C23.6971 16.2008 23.637 16.051 23.637 15.8893C23.637 15.7275 23.7031 15.5778 23.8112 15.4639C23.9254 15.3501 24.0757 15.2902 24.2379 15.2902C24.4002 15.2902 24.5504 15.3561 24.6646 15.4639C24.7788 15.5778 24.8389 15.7275 24.8389 15.8893C24.8389 16.051 24.7727 16.2008 24.6646 16.3147ZM24.6646 12.7201C24.5504 12.8339 24.4002 12.8938 24.2379 12.8938C24.0757 12.8938 23.9254 12.8279 23.8112 12.7201C23.6971 12.6122 23.637 12.4565 23.637 12.2947C23.637 12.133 23.7031 11.9832 23.8112 11.8693C23.9254 11.7555 24.0757 11.6956 24.2379 11.6956C24.4002 11.6956 24.5504 11.7615 24.6646 11.8693C24.7788 11.9832 24.8389 12.133 24.8389 12.2947C24.8389 12.4565 24.7727 12.6062 24.6646 12.7201Z" fill="#265D91"/>
                                                    </svg>
                                                    
                                                    
                                                    Pays</div>
                                                    <div class="contact-info-name "><svg class="mr-2" width="26" height="17"
                                                        viewBox="0 0 26 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M6.00143 6.82488C7.89531 6.82488 9.4306 5.29708 9.4306 3.41244C9.4306 1.5278 7.89531 0 6.00143 0C4.10756 0 2.57227 1.5278 2.57227 3.41244C2.57227 5.29708 4.10756 6.82488 6.00143 6.82488Z"
                                                            fill="#265D91" />
                                                        <path
                                                            d="M7.71563 7.67798H4.28646C3.72355 7.67798 3.16616 7.78831 2.6461 8.00267C2.12604 8.21704 1.65351 8.53124 1.25547 8.92733C0.451608 9.72727 0 10.8122 0 11.9435V15.356C0 15.5822 0.0903215 15.7992 0.251095 15.9592C0.411868 16.1192 0.629924 16.2091 0.857292 16.2091H11.1448C11.3722 16.2091 11.5902 16.1192 11.751 15.9592C11.9118 15.7992 12.0021 15.5822 12.0021 15.356V11.9435C12.0021 10.8122 11.5505 9.72727 10.7466 8.92733C9.94274 8.12738 8.85246 7.67798 7.71563 7.67798Z"
                                                            fill="#265D91" />
                                                        <path
                                                            d="M24.8616 6.82487H14.5741C14.3467 6.82487 14.1287 6.73499 13.9679 6.575C13.8071 6.41501 13.7168 6.19802 13.7168 5.97176C13.7168 5.7455 13.8071 5.52851 13.9679 5.36852C14.1287 5.20853 14.3467 5.11865 14.5741 5.11865H24.8616C25.089 5.11865 25.307 5.20853 25.4678 5.36852C25.6286 5.52851 25.7189 5.7455 25.7189 5.97176C25.7189 6.19802 25.6286 6.41501 25.4678 6.575C25.307 6.73499 25.089 6.82487 24.8616 6.82487Z"
                                                            fill="#265D91" />
                                                        <path
                                                            d="M24.8616 10.2372H14.5741C14.3467 10.2372 14.1287 10.1473 13.9679 9.98736C13.8071 9.82737 13.7168 9.61037 13.7168 9.38412C13.7168 9.15786 13.8071 8.94086 13.9679 8.78088C14.1287 8.62089 14.3467 8.53101 14.5741 8.53101H24.8616C25.089 8.53101 25.307 8.62089 25.4678 8.78088C25.6286 8.94086 25.7189 9.15786 25.7189 9.38412C25.7189 9.61037 25.6286 9.82737 25.4678 9.98736C25.307 10.1473 25.089 10.2372 24.8616 10.2372Z"
                                                            fill="#265D91" />
                                                        <path
                                                            d="M19.7178 13.6496H14.5741C14.3467 13.6496 14.1287 13.5597 13.9679 13.3997C13.8071 13.2397 13.7168 13.0227 13.7168 12.7965C13.7168 12.5702 13.8071 12.3532 13.9679 12.1932C14.1287 12.0332 14.3467 11.9434 14.5741 11.9434H19.7178C19.9452 11.9434 20.1633 12.0332 20.324 12.1932C20.4848 12.3532 20.5751 12.5702 20.5751 12.7965C20.5751 13.0227 20.4848 13.2397 20.324 13.3997C20.1633 13.5597 19.9452 13.6496 19.7178 13.6496Z"
                                                            fill="#265D91" />
                                                    </svg>
                                                        
                                                        
                                                        Rôle </div>
                                    </div>
                                    <div class="col-lg-6 col-9" style="text-align: center;">
                                        <div class="value-input"><input type="text" [value]="first_name ? first_name : '---'" disabled></div>
                                        <div class="value-input"> <input type="text" value="{{last_name}}" disabled></div>
                                        <div class="value-input"><input type="text" value="{{email}}" disabled></div>
                                        <div class="value-input"><input type="text" [value]="phone ? phone : '---'" disabled></div>
                                        <div class="value-input">  <input class="value-input" type="text" [value]="city &&  city!='null' ? city : '---'" disabled></div>
                                        <div class="value-input">   <input class="value-input" type="text" [value]="roleuser=='2'?'Admin' : roleuser =='1'?'Super Admin': '---'" disabled></div>
                                    </div>
                                </div>
                           

                            
                               
                                    </div>
                                 
                                <!--begin::Details item-->
                            </div>
                          
                        </div>
                    </div>
    
    
    
                    <!--end::Table-->
                </div>
            </div>
    
            <!--end::Card header-->
            <!--begin::Card body-->
      
            <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Customers - Add-->
     
        <!--end::Modal - Customers - Add-->
        <!--begin::Modal - Adjust Balance-->
        <ng-template #modifier>
            <div class="modal-dialog-centered" id="modifier" tabindex="-1" aria-hidden="true">
                <!--begin::Modal dialog-->
                    <!--begin::Modal content-->
                 <div class="modal-content" [formGroup]="userForm">
                    <div class="modal-header" >
                        <h3 class="modal-title">Modifier Profil</h3>
                       
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
                            <i class="ki-duotone ki-cross fs-1"><span class="path1"></span><span class="path2"></span></i>
                        </div>
                        <!--end::Close-->
                    </div>
        
                    <div class="modal-body">
                        <div class="row mb-5">
                            <div class="section_img">
                                <div class="photo_img ">
                                    <!-- <input id="fileInput" type="file" (change)="uploadFile($event)" hidden
                                        accept=".png, .jpg, .jpeg" class="select-profile-picture" #fileInput /> -->
                                        <input id="fileInput" type="file" (change)="uploadFile($event)" hidden accept=".png, .jpg, .jpeg, .jfif,.avif" class="select-profile-picture" #fileInput />
                                        <div *ngIf="image_url" >
                                            <img *ngIf="(!fileName||fileName=='') || fileExtention || fileError"
                                            src="{{ url+'/User/file/gallery_users/'+ image_url }}"
                                            class="img-profile" alt="" />

                                         </div>
                                    <img *ngIf="fileName!='' && !fileExtention && !fileError" [src]="selectedImage" class="img-profile" alt="" />
              
                                    <!-- <img *ngIf="!selectedImage&&!image_url "
                                        class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt="" width="75px"> -->
              
                                </div>
              
                                <div class="btns">
                                    <button  class="changer btn_photo cursor-pointer"
                                        (click)="fileInput.click()">Changer La Photo</button>
                                        <button [disabled]=" image_url==='avatar.png' && !isImageChanged" class="supprimer btn_photo cursor-pointer" (click)="clearImage()">
                                        <svg width="17" height="19" viewBox="0 0 17 19" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.9851 4.17508H15.6809C15.8655 4.17508 16.0425 4.10177 16.173 3.97127C16.3035 3.84078 16.3768 3.66379 16.3768 3.47924C16.3768 3.29469 16.3035 3.11769 16.173 2.9872C16.0425 2.8567 15.8655 2.78339 15.6809 2.78339H12.2017V2.08754C12.2 1.5344 11.9796 1.00438 11.5884 0.613252C11.1973 0.222119 10.6673 0.001652 10.1141 0H5.93906C5.38591 0.001652 4.8559 0.222119 4.46477 0.613252C4.07363 1.00438 3.85317 1.5344 3.85151 2.08754V2.78339H0.695847C0.511297 2.78339 0.334305 2.8567 0.203809 2.9872C0.0733121 3.11769 0 3.29469 0 3.47924C0 3.66379 0.0733121 3.84078 0.203809 3.97127C0.334305 4.10177 0.511297 4.17508 0.695847 4.17508H1.06813V14.6128C1.06813 15.5355 1.43469 16.4205 2.08717 17.073C2.73965 17.7255 3.62461 18.092 4.54736 18.092H11.5058C12.4286 18.092 13.3135 17.7255 13.966 17.073C14.6185 16.4205 14.9851 15.5355 14.9851 14.6128V4.17508ZM6.6349 13.2211C6.6349 13.4056 6.56159 13.5826 6.43109 13.7131C6.3006 13.8436 6.12361 13.9169 5.93906 13.9169C5.75451 13.9169 5.57751 13.8436 5.44702 13.7131C5.31652 13.5826 5.24321 13.4056 5.24321 13.2211V7.65432C5.24321 7.46977 5.31652 7.29278 5.44702 7.16228C5.57751 7.03178 5.75451 6.95847 5.93906 6.95847C6.12361 6.95847 6.3006 7.03178 6.43109 7.16228C6.56159 7.29278 6.6349 7.46977 6.6349 7.65432V13.2211ZM10.81 13.2211C10.81 13.4056 10.7367 13.5826 10.6062 13.7131C10.4757 13.8436 10.2987 13.9169 10.1141 13.9169C9.92959 13.9169 9.7526 13.8436 9.6221 13.7131C9.4916 13.5826 9.41829 13.4056 9.41829 13.2211V7.65432C9.41829 7.46977 9.4916 7.29278 9.6221 7.16228C9.7526 7.03178 9.92959 6.95847 10.1141 6.95847C10.2987 6.95847 10.4757 7.03178 10.6062 7.16228C10.7367 7.29278 10.81 7.46977 10.81 7.65432V13.2211ZM10.81 2.78339H5.24321V2.08754C5.24321 1.90299 5.31652 1.726 5.44702 1.5955C5.57751 1.46501 5.75451 1.39169 5.93906 1.39169H10.1141C10.2987 1.39169 10.4757 1.46501 10.6062 1.5955C10.7367 1.726 10.81 1.90299 10.81 2.08754V2.78339Z"
                                                fill="#EE6352" />
                                        </svg>
                                        Supprimer
                                    </button>
                                </div>
                            </div>
                            <!-- <span style="color:red" *ngIf="fileExtention"><small>{{fileExtention}} </small></span> -->
                        </div>
                    
                        <div class="mb-5">
                            <label for="name" class="required form-label">Prénom</label>
                            <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="{{ first_name }}" formControlName="first_name">
                            <div *ngIf="userForm.get('first_name')?.hasError('required') && userForm.get('first_name')?.touched" class="text-danger">
                                Le champ "Nom" est obligatoire.
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="name" class="required form-label">Nom</label>
                            <!-- <input type="text" name="nom" id="nom" class="form-control" [(ngModel)]="userForm.value.first_name"> -->
                            <!-- <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="{{first_name}}" formControlName="first_name"> -->
                          
                            <input type="text" name="Prénom" id="Prénom" class="form-control" placeholder="{{last_name}}" formControlName="last_name">
                            <div *ngIf="userForm.get('last_name')?.hasError('required') && userForm.get('last_name')?.touched" class="text-danger">
                                Le champ "Prénom" est obligatoire.
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="email" class="required form-label">Email</label>
                       
                            <input type="email" name="email" id="email" class="form-control" placeholder="email" formControlName="email" readonly>
                            <div *ngIf="userForm.get('email')?.hasError('required') && userForm.get('email')?.touched" class="text-danger">
                                Le champ "email" est obligatoire.
                            </div>
                            <div *ngIf="userForm.get('email')?.hasError('pattern') && userForm.get('email')?.touched" class="text-danger">
                                Le format l’adresse mail   n'est pas valide.
                            </div>
                        </div>

                        <div class="mb-5">
                            <label for="Tel">Téléphone</label>
                            <form [formGroup]="phoneForm">
                           <div class="wrapper">
                                <ngx-intl-tel-input [cssClass]="'custom'"
                                [preferredCountries]="preferredCountries"
                                [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
                                [selectFirstCountry]="false"
                                [selectedCountryISO]="CountryISO.France"
                                [maxLength]="20" [phoneValidation]="true"
                                [separateDialCode]="separateDialCode"
                                [numberFormat]="PhoneNumberFormat.National"
                                name="phone" formControlName="phone"
                                >
                            </ngx-intl-tel-input>
                            <div
                            *ngIf="phoneForm.controls['phone'].invalid && phoneForm.controls['phone'].touched">
                            <span class="text-danger">Numéro de téléphone est
                                invalide</span>
                        </div>
                              </div>
                            </form>
                        </div>
                        <div class="mb-5">
                            <!-- <label for="Tel">Rôle</label> -->
                            <label for="poste" >Pays</label>
                                    <ng-select (change)="onCountryChange($event)" [items]="paysList"
                                    formControlName="city"
                                   bindLabel="name" bindValue="name"
                                     [placeholder]="'Pays destinataire'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                                   </ng-select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="annuler" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">Annuler</button>
                        <button class="confirmer"   (click)="onSubmit()"
         [disabled]="fileExtention!='' || fileError!='' || phoneForm.controls['phone'].invalid && phoneForm.controls['phone'].touched"
         >Confirmer</button>
                    </div>
                </div>
            
                <!--end::Modal dialog-->
            </div>
            
       
    </ng-template> 
     

        <!--end::Modal - New Card-->
        <!--end::Modals-->

    <!--end::Content container-->
    
    <!--end::Content container-->