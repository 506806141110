
<div class="d-flex flex-column flex-root" id="kt_app_root">
    <!--begin::Page bg image-->
    <!--end::Page bg image-->
    <!--begin::Authentication - Signup Welcome Message -->
    <div class="d-flex flex-column flex-center flex-column-fluid">
      <div class="login-page">
        <!--begin::Content-->
        <div class="d-flex flex-column flex-center p-10">
          <!--begin::Wrapper-->
          <div class="card-warp w-md-650px">
            <div class="content">
              <!--begin::Logo-->
              <div class="mb-15 text-center">
                <a href="/" class="">
                  <img alt="Logo" src="/assets/media/logos/logo-wegestu-color.png" class="h-40px" />
                </a>
              </div>
              <!--end::Logo-->
              <!--begin::Title-->
  
              <!--end::Text-->
              <!--begin::Illustration-->
              <div class="mb-10">
                <!--begin::Input group-->
                

                <div class="fv-row mb-8">
                  <!--begin::Password-->
                 <div class="image-no mb-10">
                    <img src="/assets/media/errors/no-internet.jpg" alt="" width="50">

                   <h4 style="padding-top: 7px;">Pas de connexion Internet</h4>
                 </div>
                  <p>
                    On ne peut pas afficher cette page car votre ordinateur n'est pas connecté à Internet.
                  </p>
                 <div class="solutions mb-15">
                    <ul>
                        <li>
                            <span>Contactez votre administrateur système</span>
                        </li>
                        <li>
                            <span>Réinitialiser votre modem Internet</span>
                        </li>
                        <li>
                            <span>Exécuter les diagnostics Internet de votre système</span>
                        </li>
                      </ul>
                 </div>
                    
  
                </div>
               
                <!--begin::Input group-->
              </div>
              <!--end::Section-->
              <!--begin::Submit-->
             
  
              <!--end::Input group=-->
              <!--begin::Wrapper-->
              <!--end::Illustration-->
              <!--begin::Link-->
  
              <!--end::Link-->
            </div>
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Content-->
      </div>
  
    </div>
    <!--end::Authentication - Signup Welcome Message-->
  </div>
