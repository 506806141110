import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
  addedCategorie,
  addedSubCategorie,
  confirmDelete,
  existedCategory,
  existedSubCategory,
  failedDelete,
  serverError,
  successDeletecategori,
  updatedCategorie,
} from '../../../../core/models/messages';
import { CSubCategory } from '../../../../core/models/module';
import { ModuleService } from '../../../../core/services/modules/module.service';
import { environment } from '../../../../../environments/environment';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SortDataService } from '../../../../core/services/sort-data/sort-data.service';

@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.html',
  styleUrls: ['./list-categories.component.css'],
})
export class ListCategoriesComponent {
  file!: any;
  fileName: string = '';
  selectedImage: any;
  /* formGroup */
  subCatFormGroup: FormGroup = this.createSubCatForm(new CSubCategory());
  /* numbers */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  totalPages!: number;
  /* string */
  type: string = 'category';
  icon: string = 'ajout';
  url: string = environment.baseUrl + '/api/Category/file/galleryCategories/';
  currentSortDirection: number = 2;
  pagination: boolean = false;
  isLoadSpinner: boolean = true;

  fileError: string = '';
  fileExtention: string = '';
  searchValue: string = '';
  searchElement: string = '';
  sortAttr: string = '';
  /* form data */
  formData = new FormData();
  /* arrays */
  /* {name_FR:string,sub_category:{name_FR:string}[]}[] */
  dataCategory: any = [];
  listCategory: any = [];
  /* ViewChild */
  @ViewChild('fileInput') fileInput: any;
  @ViewChild('modal') modal: any;
  /* modal */
  modalRef?: BsModalRef;
  /* subscriprion */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(private moduleService: ModuleService, private toastr: ToastrService,
    private formBuilder: FormBuilder, private router: Router, private spinner: NgxSpinnerService, private elRef: ElementRef,
    private cdRef: ChangeDetectorRef, private modalService: BsModalService, private sortDataService: SortDataService) {

  }
  /* init */
  ngOnInit() {
    this.getListGategories();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }

  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
    }
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }
  closeModal() {
    this.modalRef?.hide();
    this.subCatFormGroup.reset();
    this.formData = new FormData();
    this.fileName = '';
    this.fileError = '';
    this.fileExtention = '';
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
  page: boolean = false;
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') this.applyFilter();
      else if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true

        this.getListGategories();

      }
    }
  }

  // sortData() {
  //   this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
  //   this.sortAttr = 'name_FR'
  //   if (this.searchValue != '') this.dataCategory = this.sortDataService.sortArray(this.dataCategory, 'name_FR', this.currentSortDirection)
  //   else this.getListGategories()
  // }
  sortData(item: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    let sort = { sort: this.currentSortDirection, sortAttribute: item };
    this.sortAttr = item;
    console.log('sort', sort);
    this.currentPage = 1;
    this.endIndex = 5;

    if (this.searchValue != '') {
      this.dataCategory = this.sortDataService.sortArray(
        this.dataCategory,
        'name_FR',
        this.currentSortDirection
      );
    } else {
      this.getListGategories();
    }
    this.getListGategories();
  }
  /* get list of categories */
  getListGategories() {
    let dataPyload =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };
    this.spinner.show();
    this.moduleService
      .getListCategories(dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.isLoadSpinner = false;
            this.closeModal();
            this.dataCategory = res.data.data.slice(0, this.itemsPerPage);
            console.log('list category', this.dataCategory);
            // this.listCategory = res.data.data
            this.totalItems = res.data.total;
            this.totalPages = res.data.last_page;
            this.currentPage = res.data.current_page;
          }
          this.spinner.hide();
        },
        error: () => {
          this.closeModal();
          this.spinner.hide();
        },
      });
  }
  /* get id of category  */
  setModuleId(data: any, template: TemplateRef<any>) {
    this.subCatFormGroup.get('category_id')?.setValue(data.id);
    if (this.type == 'updateCategory') {
      this.subCatFormGroup.get('name_FR')?.setValue(data.name_FR);
      this.subCatFormGroup.get('icon')?.setValue(data.icon);
      this.icon = data.icon;
    }
    this.openModal(template);
  }

  /* upload file */
  // uploadFile(event: any): void {
  //   const file = event.target.files[0];
  //   const maxSize = 2 * 1024 * 1024;
  //   this.file = file
  //   this.fileName = this.file.name

  //   const allowedExtensions = ['.jpeg', '.jpg', '.png'];
  //   const fileExtension = file.name.toLowerCase().substr(file.name.lastIndexOf('.'));
  //   if (file) {
  //     if (!allowedExtensions.includes(fileExtension)) {
  //       this.fileExtention = fileTypeExtention
  //     } else if (file.size > maxSize) {
  //       this.fileError = maxSizeFile
  //     }
  //     else {
  //       this.fileError = ''
  //       this.fileExtention = ''
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         this.selectedImage = reader.result;
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //     this.subCatFormGroup.get('icon')?.setValue(file)
  //   }
  // }
  // uploadFile(event: any): void {
  //   // Reset error messages
  //   this.fileExtention = '';
  //   this.fileError = '';

  //   const file = event.target.files[0];
  //   const maxSize = 2 * 1024 * 1024;
  //   const allowedExtensions = ['.jpeg', '.jpg', '.png'];

  //   // Check if the file input is empty (user canceled selection)
  //   if (!file) {
  //     // Handle the case where the user canceled file selection
  //     // You can show a message or perform any other action
  //     return;
  //   }

  //   // Set file and file name
  //   this.file = file;
  //   this.fileName = this.file.name;

  //   const fileExtension = file.name.toLowerCase().substr(file.name.lastIndexOf('.'));

  //   if (!allowedExtensions.includes(fileExtension)) {

  //     this.fileExtention = 'Format fichier non compatible !';

  //   } else if (file.size > maxSize) {
  //     this.fileError = 'File size exceeds the maximum allowed size (2MB).';
  //   } else {
  //     // Reset other error messages
  //     this.fileError = '';

  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       this.selectedImage = reader.result;
  //     };
  //     reader.readAsDataURL(file);
  //   }

  //   // Set the file in the form group
  //   this.subCatFormGroup.get('icon')?.setValue(file);
  // }
  uploadFile(event: any): void {
    // Reset error messages
    this.fileExtention = '';
    this.fileError = '';

    const fileInput = event.target;
    const file = fileInput.files[0];
    const maxSize = 2 * 1024 * 1024;
    const allowedExtensions = [
      '.jpeg',
      '.jpg',
      '.png',
      '.gif',
      '.jfif',
      '.avif',
    ];

    // Check if the file input is empty (user canceled selection)
    if (!file) {
      // Clear file input and related variables
      fileInput.value = '';
      this.file = null;
      this.fileName = '';
      this.selectedImage = null;
      return;
    }

    // Set file and file name
    this.file = file;
    this.fileName = this.file.name;
    const fileExtension = file.name
      .toLowerCase()
      .substr(file.name.lastIndexOf('.'));

    if (!allowedExtensions.includes(fileExtension)) {
      this.fileExtention = 'Format fichier non compatible !';
      this.toastr.error(
        "Le format du fichier n'est pas accepté.Veuillez choisir une photo"
      );
      // Clear file input and related variables
      // fileInput.value = '';
      // this.file = null;
      // this.fileName = '';
      // this.selectedImage = null;
    } else if (file.size > maxSize) {
      this.fileError = 'File size exceeds the maximum allowed size (2MB).';
      // Clear file input and related variables
      fileInput.value = '';
      this.file = null;
      this.fileName = '';
      this.selectedImage = null;
    } else {
      // Reset other error messages
      this.fileError = '';

      const reader = new FileReader();
      reader.onload = () => {
        this.selectedImage = reader.result;
      };
      reader.readAsDataURL(file);
    }

    // Set the file in the form group
    this.subCatFormGroup.get('icon')?.setValue(this.file);
  }
  isImage(): boolean {
    const allowedExtensions = [
      '.jpeg',
      '.jpg',
      '.png',
      '.gif',
      '.jfif',
      '.avif',
    ];
    const fileExtension = (this.fileName || '')
      .toLowerCase()
      .substr((this.fileName || '').lastIndexOf('.'));
    return allowedExtensions.includes(fileExtension);
  }
  imageWidth: number = 100;
  imageHeight: number = 100;
  /* create add subCategory form */
  createSubCatForm(data?: any) {
    return this.formBuilder.group({
      name_FR: [
        data ? data.name_FR : '',
        [Validators.required, this.notOnlySpacesValidator()],
      ],
      category_id: [''],
      icon: [data ? data.icon : ''],
    });
  }
  notOnlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value as string;
      const isValid = value?.trim() !== '';
      return isValid ? null : { onlySpaces: true };
    };
  }
  /* click on confirm modal button */
  confirm() {
    if (this.type == 'subCategory') {
      this.addSubCategorie();
    } else if (this.type == 'category') {
      this.addCategorie();
    } else {
      this.updateCategorie();
    }
  }
  /* set form data */
  setFormData() {
    this.formData.append('name_FR', this.subCatFormGroup.value.name_FR);
    this.formData.append('category_id', this.subCatFormGroup.value.category_id);
    if (this.file) this.formData.append('icon', this.file);
  }
  /* add subcategory */
  addSubCategorie() {
    if (this.subCatFormGroup.valid) {
      this.setFormData();
      this.spinner.show();
      this.moduleService
        .addSubCategory(this.formData)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.getListGategories();
              this.spinner.hide();
              this.toastr.success(addedSubCategorie);
              this.subCatFormGroup.reset();
              this.formData = new FormData();
              this.file = null;
            }
          },
          error: (err) => {
            this.subCatFormGroup.reset();
            this.formData = new FormData();
            this.file = null;
            this.spinner.hide();
            err.error &&
              (err.error.message['name_FR'] ||
                err.error.message === 'Validation error')
              ? this.toastr.info(existedSubCategory)
              : this.toastr.error(serverError);
          },
        });
    }
  }
  /* add category */
  addCategorie() {
    this.formData.append('name_FR', this.subCatFormGroup.value.name_FR);
    this.formData.append('icon', this.file ? this.file : null);
    this.spinner.show();
    this.moduleService
      .addCategory(this.formData)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.closeModal();
            this.spinner.hide();
            this.toastr.success(addedCategorie);
            this.getListGategories();
            this.subCatFormGroup.reset();
            this.formData = new FormData();
            this.file = null;
          }
        },
        error: (err) => {
          this.closeModal();
          this.subCatFormGroup.reset();
          this.formData = new FormData();
          this.file = null;
          this.spinner.hide();
          if (
            err.error &&
            (err.error.message['name_FR'] ||
              err.error.message === 'Validation error')
          ) {
            this.toastr.info(existedCategory);
          } else {
            // If none of the conditions are met, you can display a default message or choose not to show any message
            // Example:
            // this.toastr.info('Some default message');
          }
        },
      });
  }
  /* update category */
  updateCategorie() {
    this.setFormData();
    this.spinner.show();
    this.moduleService
      .updateCategory(
        this.subCatFormGroup.get('category_id')!.value,
        this.formData
      )
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.closeModal();
            this.spinner.hide();
            this.getListGategories();
            this.toastr.success(updatedCategorie);
            this.subCatFormGroup.reset();
            this.formData = new FormData();
            this.file = null;
          }
        },
        error: (err) => {
          this.closeModal();
          this.subCatFormGroup.reset();
          this.formData = new FormData();
          this.file = null;
          this.spinner.hide();
          if (err.status != 403) {
            if (err?.error) {
              console.log('here', err.error);
              if (err?.error?.data['name_FR'])
                this.toastr.info(existedCategory);
              else this.toastr.error(serverError);
            }
          }
        },
      });
  }

  /* go to details category */
  navigateToDetails(idCategory: number) {
    this.router.navigate([
      '/acceuil/modules/details-categorie',
      { idCat: idCategory },
    ]);
  }
  /* delete category pop up confirm or cancel */
  swallDeleteCat(category: { name_FR: string; id: number }) {
    Swal.fire({
      title: `${confirmDelete} ${category.name_FR}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteCategory(category.id);
      } else if (result.isDismissed) {
        Swal.fire(failedDelete, '', 'error');
      }
    });
  }
  /* delete category */
  deleteCategory(idCat: number) {
    this.spinner.show();
    this.moduleService
      .deleteCategory(idCat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.applyFilter();
            this.spinner.hide();
            this.toastr.success(successDeletecategori);
          }
        },
        error: (error) => {
          console.log('error', error);
          this.spinner.hide();
          // this.toastr.error(serverError)
        },
      });
  }

  clickCount: number = 0;
  /* filter data on key up input */
  applyFilter() {
    if (this.searchElement != '' || this.clickCount) {
      this.clickCount++;
      if (this.clickCount == 1) this.currentPage = 1;
      let data = {
        search: this.searchElement.trim().toLowerCase(),
        // sort:this.currentSortDirection,
        // sortAttribute:'name_FR',
        page: this.currentPage,
        per_page: this.itemsPerPage,
      };
      this.spinner.show();
      this.moduleService
        .searchCategory(data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (!res.data.data.length && this.currentPage > 1) {
              this.currentPage = 1;
              this.applyFilter();
            } else {
              this.dataCategory = res.data.data.slice(0, this.endIndex);
              this.totalItems = res.data.total;
            }
            this.spinner.hide();
          },
          error: () => {
            this.toastr.error(serverError);
          },
        });
    }
  }
  selectImage() {
    this.fileInput.nativeElement.click();
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
}
