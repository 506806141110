import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/authentication/auth.service';
import { inject } from '@angular/core';
import { NetworkService } from '../../services/NetworkService';

export namespace authenticationGuard {
  export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => {
    const authService = inject(AuthService);
    const networkService = inject(NetworkService);
    const router = inject(Router);

    if (!networkService.getOnlineStatus()) {
      router.navigate(['/reconnect']);
      return false;
    }
    
    // Allow access to the login page
    if (state.url.includes('auth') && !authService.isAuthenticated()) {
      return true;
    }
    if (state.url.includes('auth') && authService.isAuthenticated()) {
      router.navigate(['/acceuil/general']);
      return false;
    }
    if (authService.isAuthenticated()) {
      return true;
    }
    router.navigate(['/auth/login']);
    return false;
  }

  export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivate(route, state);
}
