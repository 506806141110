// import { Injectable } from '@angular/core';
// import {
//   HttpRequest,
//   HttpHandler,
//   HttpEvent,
//   HttpInterceptor,
//   HttpErrorResponse,
// } from '@angular/common/http';
// import { Observable, Subject, throwError } from 'rxjs';
// import { catchError, switchMap, tap } from 'rxjs/operators';
// import { AuthService } from '../services/authentication/auth.service';
// import { environment } from '../../../environments/environment';
// import { ToastrService } from 'ngx-toastr';
// import {
//   permissionDenied,
//   serverError,
//   sessionExpired,
// } from '../models/messages';
// import { NetworkService } from '../services/NetworkService';
// import { Router } from '@angular/router';

// @Injectable()
// export class AppInterceptor implements HttpInterceptor {
//   AUTH_HEADER: string = 'authorization';
//   refreshTokenInProgress: boolean = false;
//   tokenRefreshedSource = new Subject<void>();
//   tokenRefreshed = this.tokenRefreshedSource.asObservable();

//   constructor(
//     private authService: AuthService,
//     private toastrService: ToastrService,
//     private networkService: NetworkService,
//     private router:Router
//   ) {}

//   intercept(
//     request: HttpRequest<unknown>,
//     next: HttpHandler
//   ): Observable<HttpEvent<unknown>> {
//     if (!request.headers.has('Accept')) {
//       request = request.clone({
//         headers: request.headers.set('Accept', 'application/json'),
//       });
//     }

//     request = this.addAuthHeader(request);
//     return next.handle(request).pipe(
//       catchError((error: HttpErrorResponse) => {
//         return this.handleResponseError(error, request, next);
//       })
//     ) as any;
//   }

//   addAuthHeader(request: HttpRequest<any>): HttpRequest<unknown> {
//     const token = this.authService.getToken();
//     if (!token) {
//       return request;
//     }
//     if (
//       !request.url.match(environment.baseUrl + '/api') ||
//       request.url.includes('/auth/')
//     ) {
//       return request;
//     } else {
//       return request.clone({
//         headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + token),
//       });
//     }
//   }

//   handleResponseError(error: any, request?: any, next?: any): any {
//     if (!this.networkService.getOnlineStatus()) {
//       this.toastrService.error(
//         'Vous êtes hors ligne. Veuillez vérifier votre connexion.'
//       );
//       return throwError(() => new Error('Offline'));
//     }

//     if (error.status == 401 && error.error.message === 'Expired JWT Token') {
//       return this.refreshToken().pipe(
//         switchMap(() => {
//           request = this.addAuthHeader(request);
//           return next.handle(request);
//         }),
//         catchError((e) => {

//           if (e.status !== 401) {
//             return this.handleResponseError(e);
//           } else {
//             this.authService.logOut();
//           }
//         })
//       );
//     } else if (error.status == 401 && error.error.message == 'Unauthorized') {
//       this.authService.logOut();
//     } else if (error.status == 500) {
//       this.toastrService.error(serverError);
//     } else if (
//       error.status == 403 &&
//       error.error.message == 'session expirée'
//     ) {
//       this.toastrService.info(sessionExpired);
//     } else if (
//       error.status == 403 &&
//       error.error?.message == 'This action is unauthorized.'
//     ) {
//       this.toastrService.info(permissionDenied);
 
//     } 

//     console.log("okkk errr",error)
//       /*   
//     else {
//       this.authService.logOut(); 
//     }*/
    
//     return throwError(() => 
      
//       error
    
//     );
//   }

//   refreshToken(): Observable<any> {
//     if (this.refreshTokenInProgress) {
//       return new Observable((observer) => {
//         this.tokenRefreshed.subscribe({
//           next: () => {
//             observer.next();
//             observer.complete();
//           },
//           error: () => {},
//         });
//       });
//     } else {
//       this.refreshTokenInProgress = true;
//       return this.authService
//         .refreshAccessToken({
//           refreshToken: this.authService.getRefreshToken(),
//         })
//         .pipe(
//           tap((resp: { token: string; refreshToken: string }) => {
//             this.authService.setCookies('token', resp.token);
//             this.authService.setCookies('refresh_token', resp.refreshToken);
//             this.refreshTokenInProgress = false;
//             this.tokenRefreshedSource.next();
//           }),
//           catchError((err) => {
//             this.refreshTokenInProgress = false;
//             console.log("Error refreshToken",err)
//             return throwError(() => new Error(err));
//           })
//         );
//     }
//   }
// }



// import { environment } from './../../../environment/environment';
// import { AuthService } from './../services/auth-service/auth.service';
// import { Injectable } from '@angular/core';
// import {
//   HttpRequest,
//   HttpHandler,
//   HttpEvent,
//   HttpInterceptor,
//   HttpErrorResponse,
// } from '@angular/common/http';
// import {
//   Observable,
//   Subject,
//   Subscription,
//   catchError,
//   switchMap,
//   tap,
//   throwError,
// } from 'rxjs';
// import { ToastrService } from 'ngx-toastr';
// import {
//   permissionDenied,
//   serverError,
//   sessionExpired,
// } from '../models/messages';
// import { Router } from '@angular/router';

// @Injectable()
// export class AppInterceptor implements HttpInterceptor {
//   AUTH_HEADER: string = 'authorization';
//   refreshTokenInProgress: boolean = false;
//   tokenRefreshedSource = new Subject();
//   tokenRefreshed = this.tokenRefreshedSource.asObservable();
//   networkStatus: boolean = false;
//   networkStatus$: Subscription = Subscription.EMPTY;
//   constructor(
//     private authService: AuthService,
//     private toastrService: ToastrService,
//     private router: Router
//   ) {
//     this.checkNetworkStatus();
//   }

//   intercept(
//     request: HttpRequest<unknown>,
//     next: HttpHandler
//   ): Observable<HttpEvent<unknown>> {
//     if (!request.headers.has('Accept')) {
//       request = request.clone({
//         headers: request.headers.set('Accept', 'application/json'),
//       });
//     }

//     // Handle request
//     request = this.addAuthHeader(request);
//     // Handle response
//     return next.handle(request).pipe(
//       catchError((error: HttpErrorResponse) => {
//         return this.handleResponseError(error, request, next);
//       })
//     ) as any;
//   }
//   /* Add header auth to apis */
//   addAuthHeader(request: HttpRequest<any>): HttpRequest<unknown> {
//     const token = this.authService.getToken();
//     if (!token) {
//       return request;
//     }
//     if (
//       !request.url.match(environment.baseUrl + '/api') ||
//       request.url.includes('/auth/')
//     ) {
//       return request;
//     } else
//       return request.clone({
//         headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + token),
//       });
//   }
//   /* handling response api errors using status code*/
//   handleResponseError(error: any, request?: any, next?: any): any {

//     /**Unauthorized Api (token invalid/jwt expired) */
//     if ((error.status == 401 && error.error.error === 'Unauthorized') || error.status == 401 && error.error.message === 'Unauthorized') {

//       return this.refreshToken().pipe(
//         switchMap(() => {
//           request = this.addAuthHeader(request);
//           return next.handle(request);
//         }),
//         catchError((e) => {
//           console.log('i ama in error',e)

//           if (e.status !== 401) {
//             return this.handleResponseError(e);
//           } else {
//             this.authService.logOut();
//           }
//         })
//       );
//     } 
//     // else if (error.status == 401 && error.error.message == 'Invalid or expired refresh token') {
//     //   console.log('i ama hereeeeeeeeee')

//     //   this.authService.logOut();
//     // } 
//     else if (error.status == 500) {
//       /* server error */
//       this.toastrService.error(serverError);
//     } else if (
//       /***Expired Session with code 403  */
//       error.status == 403 &&
//       error.error.message == 'session expirée'
//     ) {
//       this.toastrService.info(sessionExpired);
//     } else if (
//       error.status == 403 &&
//       error.error?.message == 'This action is unauthorized.'
//     ) {
//       this.toastrService.info(permissionDenied);
//     } else {
//       /*** Other clientSide Error responses  */
//     }
//     return throwError(() => error);
//   }
//   /* refresh token after it expiration and call again apis */
//   refreshToken(): Observable<any> {
//     console.log('i ama in refresh token',this.refreshTokenInProgress)

//     if (this.refreshTokenInProgress) {
//       return new Observable((observer) => {
//         this.tokenRefreshed.subscribe({
//           next: (res) => {
//             if (!res) {
//               observer.next();
//               observer.complete();
//             }
//           },
//           error: () => {},
//         });
//       });
//     } else {
//       this.refreshTokenInProgress = true;
//       return this.authService
//         .refreshAccessToken({
//           refresh_token: this.authService.getRefreshToken(),
//         })
//         .pipe(
//           tap((resp) => {
//             this.authService.setCookies('token', resp.refresh_token['access_token']);
//             this.authService.setCookies('refresh_token', resp.refresh_token['refresh_token']);
//             this.refreshTokenInProgress = false;
//             this.tokenRefreshedSource.next(true);
//           }),
//           catchError((err) => {
//             this.refreshTokenInProgress = false;
//             return throwError(() => new Error(err));
//           })
//         );
//     }
//   }
//   status: any;
//   checkNetworkStatus() {
//     this.status = navigator.onLine ? 'true' : 'false';
//     localStorage.setItem('status', this.status);

//     window.addEventListener('online', () => {
//       this.status = 'true';
//       localStorage.setItem('status', 'true');
//       this.toastrService.success('Connexion réseau rétablie');
//     });

//     window.addEventListener('offline', () => {
//       this.status = 'false';
//       localStorage.setItem('status', 'false');
//       this.toastrService.error('Connexion réseau perdue');
//       this.router.navigate(['/no-internet']);
//     });
//   }
// }
import { AuthService } from '../services/authentication/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  Subject,
  catchError,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
  permissionDenied,
  serverError,
  sessionExpired,
} from '../models/messages';
import { Router } from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  AUTH_HEADER: string = 'authorization';
  refreshTokenInProgress: boolean = false;
  tokenRefreshedSource = new Subject<boolean>();
  tokenRefreshed = this.tokenRefreshedSource.asObservable();
  pendingRequests: HttpRequest<any>[] = [];

  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,

  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.has('Accept')) {
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json'),
      });
    }

    request = this.addAuthHeader(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
       
    
        if (error.status === 401) {
          return this.handle401Error(request, next);
        } else if (error.status === 500) {
          this.toastrService.error(serverError);
        } else if (error.status === 403) {
          if (error.error.message === 'session expirée') {
            this.toastrService.info(sessionExpired);
            this.authService.logOut();
          } else if (error.error?.message === 'This action is unauthorized.') {
            this.toastrService.info(permissionDenied);
          }
        } 
        // else {
        //   this.toastrService.error('Une erreur s’est produite, veuillez réessayer');
        // }
        return throwError(() => error);
      })
    );
  }

  addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.getToken();
    if (token) {
      return request.clone({
        headers: request.headers.set(this.AUTH_HEADER, `Bearer ${token}`),
      });
    }
    return request;
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      return this.authService.refreshAccessToken({
        refresh_token: this.authService.getRefreshToken(),
      }).pipe(
        tap((resp) => {
          this.authService.setCookies('token', resp.refresh_token['access_token']);
          this.authService.setCookies('refresh_token', resp.refresh_token['refresh_token']);
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next(true);
        }),
        switchMap(() => {
          this.pendingRequests.forEach((pendingRequest) => {
            next.handle(this.addAuthHeader(pendingRequest)).subscribe();
          });
          this.pendingRequests = [];
          return next.handle(this.addAuthHeader(request));
        }),
        catchError((error) => {
          this.authService.logOut();
          return throwError(() => error);
        })
      );
    } else {
      // Queue the request while refresh token is in progress
      return new Observable((observer) => {
        this.tokenRefreshed.subscribe({
          next: (isTokenRefreshed) => {
            if (isTokenRefreshed) {
              this.pendingRequests.push(request);
              this.pendingRequests.forEach((pendingRequest) => {
                next.handle(this.addAuthHeader(pendingRequest)).subscribe(
                  (res) => observer.next(res),
                  (err) => observer.error(err),
                  () => observer.complete()
                );
              });
              this.pendingRequests = [];
            }
          },
          error: (err) => observer.error(err),
        });
      });
    }
  }
  status: any;
  checkNetworkStatus() {
    this.status = navigator.onLine ? 'true' : 'false';
    localStorage.setItem('status', this.status);

    window.addEventListener('online', () => {
      this.status = 'true';
      localStorage.setItem('status', 'true');
      this.toastrService.success('Connexion réseau rétablie');
    });

    window.addEventListener('offline', () => {
      this.status = 'false';
      localStorage.setItem('status', 'false');
      this.toastrService.error('Connexion réseau perdue');
      this.router.navigate(['/no-internet']);
    });
  }
}

